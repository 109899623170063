import { useCallback, useContext, useEffect, useState } from "react";

// Icons
import { CheckCircleIcon, ClockIcon } from "@heroicons/react/24/outline";

// Context
import SnackbarContext from "../../../context/psikotest/SnackbarContext";

// Handler
import errorHandler from "../../../helper/psikotest/errorHandler";

// Controller
import ParticipantSession from "../../../controller/psikotest/psikotest_participant/session";

function SubtestRac(props: { assessment_participant_session: TPPAssessmentParticipantSession, closeSession: () => void }) {
    // Context
    const { setNotif } = useContext(SnackbarContext);

    // state
    const [rac, setRac] = useState<TPSRac>()
    const [rac_questionary, setRacQuestionary] = useState<TPSRacQuestionary[]>([])
    const [rac_questionary_answer, setRacQuestionaryAnswer] = useState<TPSAssessmentParticipantRacSessionAnswer>()
    const [answer_questionary, setAnswerQuestionary] = useState<{ number: number, answer: string }[]>([])

    // get doc
    const getDoc = useCallback(async () => {
        try {
            // get all question in subtest id
            const psc = new ParticipantSession();
            let resRac = await psc.getRac();
            setRac(resRac.data.row)
            let resQuestionaryRac = await psc.getQuestionaryRac();
            setRacQuestionary(resQuestionaryRac.data.list)
            let resQuestionaryAnswerRac = await psc.getQuestionaryAnswerRac(props.assessment_participant_session.id)
            setRacQuestionaryAnswer(resQuestionaryAnswerRac.data.row)
            // set answer questionary
            if ((JSON.parse(resQuestionaryAnswerRac.data.row.answer)).length === 0) {
                // create empty object with same length of rac questionary
                setAnswerQuestionary(resQuestionaryRac.data.list.map((rac_q: TPSRacQuestionary) => { return { number: rac_q.number, answer: "" } }))
            } else {
                setAnswerQuestionary(JSON.parse(resQuestionaryAnswerRac.data.row.answer))
            }
        } catch (error) {
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }, [setNotif, props.assessment_participant_session.id]);

    useEffect(() => {
        getDoc();
    }, [getDoc]);

    // track rac_questionary
    useEffect(() => {
        //console.log(rac_questionary)
    }, [rac_questionary]);

    // track answer_questionary
    useEffect(() => {
        //console.log(answer_questionary)
    }, [answer_questionary]);

    // handleAnswer
    const handleAnswer = async (index: number, answer_selected: string, rac_q_number: number) => {
        try {
            //console.log(index, answer, point)
            // set answer
            // copy answer
            const c_answer = [...answer_questionary]
            c_answer.splice(index, 1, { number: rac_q_number, answer: answer_selected })
            // save answer and answer different
            if (rac_questionary_answer && answer_questionary.find(aq => aq.number === rac_q_number)?.answer !== answer_selected) {
                const psc = new ParticipantSession();
                await psc.saveQuestionaryAnswerRac(props.assessment_participant_session.id, {
                    ...rac_questionary_answer,
                    answer: JSON.stringify(c_answer),
                    // param for result
                    number: rac_q_number,
                    answer_selected: answer_selected,
                    update: answer_questionary.find(aq => aq.number === rac_q_number)?.answer !== ""
                });
            }
            setAnswerQuestionary(c_answer)
        } catch (error) {
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }

    return (
        <div className="h-[94%] overflow-auto">
            <div className="flex flex-col items-center">
                <div className="text-sm md:text-base max-w-5xl text-center py-3">
                    {rac?.mdi}
                </div>
            </div>
            <div className="text-sm md:text-base border-b flex flex-col items-center pb-20">
                <div className="w-full max-w-5xl">
                    <div className="">
                        <div className="flex w-full bg-slate-800 text-white font-semibold">
                            <div className="w-1/12 text-center flex justify-center items-center border p-1">No</div>
                            <div className="w-10/12 md:w-5/12 text-center flex justify-center items-center border p-1">Question</div>
                            <div className={`hidden md:flex w-2/12 text-center justify-center items-center border p-1 cursor-pointer`}>
                                Sama
                            </div>
                            <div className={`hidden md:flex w-3/12 text-center justify-center items-center border p-1 cursor-pointer`}>
                                Tidak Sama
                            </div>
                            <div className="w-1/12 text-center flex justify-center items-center border p-1">
                                <CheckCircleIcon className="w-5 text-white" />
                            </div>
                        </div>
                    </div>
                    {rac_questionary.map((rac_q, index) => (
                        <div key={index} className="w-full">
                            <div className="flex w-full">
                                <div className="w-1/12 text-center flex justify-center items-center border p-1">{index + 1}</div>
                                <div className="w-10/12 md:w-5/12 text-center flex flex-col justify-center items-center border p-1">
                                    <img
                                        className="h-10"
                                        src={`/upload/psikotest_participant/get_rac/${rac_q.question_image}`}
                                        alt={`${rac_q.id}`}
                                    />
                                    <div className="w-full flex md:hidden">
                                        <div
                                            className={`
                                                flex w-6/12 text-center justify-center items-center border p-1 cursor-pointer hover:bg-blue-200 hover:font-semibold hover:text-black
                                                ${answer_questionary.find(aq => aq.number === rac_q.number)?.answer === "sama" ? "bg-green-200" : ""}
                                                ${answer_questionary.find(aq => aq.number === rac_q.number)?.answer !== "sama" && answer_questionary.find(aq => aq.number === rac_q.number)?.answer === "tidak sama" ? "bg-slate-100 text-gray-400" : ""}
                                            `}
                                            onClick={() => handleAnswer(index, "sama", rac_q.number)}>
                                            Sama
                                        </div>
                                        <div
                                            className={`
                                                flex w-6/12 text-center justify-center items-center border p-1 cursor-pointer hover:bg-blue-200 hover:font-semibold hover:text-black
                                                ${answer_questionary.find(aq => aq.number === rac_q.number)?.answer === "tidak sama" ? "bg-green-200" : ""}
                                                ${answer_questionary.find(aq => aq.number === rac_q.number)?.answer !== "tidak sama" && answer_questionary.find(aq => aq.number === rac_q.number)?.answer === "sama" ? "bg-slate-100 text-gray-400" : ""}
                                            `}
                                            onClick={() => handleAnswer(index, "tidak sama", rac_q.number)}>
                                            Tidak Sama
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`
                                        hidden md:flex w-2/12 text-center justify-center items-center border p-1 cursor-pointer hover:bg-blue-200 hover:font-semibold hover:text-black
                                        ${answer_questionary.find(aq => aq.number === rac_q.number)?.answer === "sama" ? "bg-green-200" : ""}
                                        ${answer_questionary.find(aq => aq.number === rac_q.number)?.answer !== "sama" && answer_questionary.find(aq => aq.number === rac_q.number)?.answer === "tidak sama" ? "bg-slate-100 text-gray-400" : ""}
                                    `}
                                    onClick={() => handleAnswer(index, "sama", rac_q.number)}>
                                    Sama
                                </div>
                                <div
                                    className={`
                                        hidden md:flex w-3/12 text-center justify-center items-center border p-1 cursor-pointer hover:bg-blue-200 hover:font-semibold hover:text-black
                                        ${answer_questionary.find(aq => aq.number === rac_q.number)?.answer === "tidak sama" ? "bg-green-200" : ""}
                                        ${answer_questionary.find(aq => aq.number === rac_q.number)?.answer !== "tidak sama" && answer_questionary.find(aq => aq.number === rac_q.number)?.answer === "sama" ? "bg-slate-100 text-gray-400" : ""}
                                    `}
                                    onClick={() => handleAnswer(index, "tidak sama", rac_q.number)}>
                                    Tidak Sama
                                </div>
                                <div className={`w-1/12 text-center flex justify-center items-center border p-1 ${answer_questionary.find(aq => aq.number === rac_q.number)?.answer !== "" ? "bg-green-100" : ""}`}>
                                    {answer_questionary.find(aq => aq.number === rac_q.number)?.answer !== ""
                                        ? <CheckCircleIcon className="w-5 text-green-600" />
                                        : <ClockIcon className="w-5 text-gray-400" />
                                    }
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="max-w-5xl mt-3 flex justify-end">
                        {answer_questionary.filter(aq => aq.answer !== "").length !== rac_questionary.length &&
                            <div className="px-3 text-red-500">Tombol <b>Finish</b> tersedia saat semua pernyataan telah dipilih</div>
                        }
                        <button
                            type="button"
                            className={`mx-3 md:mx-0 py-1 px-6 min-w-[80px] text-center cursor-pointer rounded text-white bg-red-500 hover:bg-red-600 disabled:bg-gray-300`}
                            onClick={() => props.closeSession()}
                            disabled={answer_questionary.filter(aq => aq.answer !== "").length !== rac_questionary.length}>
                            Finish
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SubtestRac;
