import * as yup from "yup";
import React, { Fragment } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState, useContext, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import Select from "react-select";
import Swal from "sweetalert2";

// Icons
import {
  ChevronUpIcon,
  ArrowDownOnSquareIcon,
  TrashIcon,
  DocumentTextIcon,
  ClockIcon,
  ClipboardDocumentCheckIcon,
  CheckBadgeIcon,
  ArrowsRightLeftIcon
} from "@heroicons/react/24/outline";

// Contexts
import PSAuthContext from "../../../context/psikotest/AuthContext";
import LoaderContext from "../../../context/psikotest/LoaderContext";
import SnackbarContext from "../../../context/psikotest/SnackbarContext";

// Helpers
import errorHandler from "../../../helper/psikotest/errorHandler";
import logRender from "../../../helper/psikotest/logRender";

// Controllers
import Assessment from "../../../controller/psikotest/psikotest_system/assessment";
import Company from "../../../controller/psikotest/psikotest_system/company";
import Assessor from "../../../controller/psikotest/psikotest_system/assessor";
import Psikolog from "../../../controller/psikotest/psikotest_system/psikolog";

// Components
import BackButton from "../../../components/psikotest/psikotest_system/BackButton";

// Local Components
import ListIntelligence from "./ListIntelligence";
import ListParticipant from "./ListParticipant";
import ListPersonality from "./ListPersonality";
import ListBehavioural from "./ListBehavioural";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = {
  id: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required(),
  assessment_title: yup
    .string()
    .label("Assessment Name")
    .transform((value) => (value === null ? "" : value))
    .required(),
  company_id: yup
    .number()
    .label("Company")
    .transform((value) => (isNaN(value) ? undefined : value))
    .required(),
  date: yup
    .string()
    .label("Assessment Date")
    .transform((value) => (value === null ? "" : value))
    .required(),
  start_time: yup
    .string()
    .label("Start Time")
    .transform((value) => (value === null ? "" : value))
    .required(),
  link_zoom: yup.string().url().label("Link Zoom").required(),
  wa_number: yup.string().matches(phoneRegExp, "Phone number is not valid").label("WA Number").required(),
};

const stepper = [
  {
    label: "Draft",
    icon: (props: React.ComponentProps<'svg'> & {
      title?: string;
      titleId?: string;
    }) => <DocumentTextIcon {...props} />
  },
  {
    label: "On Progress",
    icon: (props: React.ComponentProps<'svg'> & {
      title?: string;
      titleId?: string;
    }) => <ClockIcon {...props} />
  },
  {
    label: "Scoring",
    icon: (props: React.ComponentProps<'svg'> & {
      title?: string;
      titleId?: string;
    }) => <ClipboardDocumentCheckIcon {...props} />
  },
  {
    label: "Completed",
    icon: (props: React.ComponentProps<'svg'> & {
      title?: string;
      titleId?: string;
    }) => <CheckBadgeIcon {...props} />
  },
];

function Form() {
  const { user } = useContext(PSAuthContext);
  const { setMessage } = useContext(LoaderContext);
  const { setNotif } = useContext(SnackbarContext);
  const navigate = useNavigate();
  // is admin
  const isAdmin = user.roles === "1";
  // parse param id
  const location = useLocation();
  // remove trailing slash in production when reload/refresh
  const arrPath = location.pathname.replace(/\/$/, "").split("/");
  const paramId = arrPath[arrPath.length - 1];
  // state required value
  // is pic 
  const [isPic, setIsPic] = useState<boolean>(false);
  const [isPsikolog, setIsPsikolog] = useState<boolean>(false);
  const [companyOptions, setCompanyOptions] = useState<TSelect[]>([]);
  const [assessorOptions, setAssessorOptions] = useState<TSelect[]>([]);
  const [psikologOptions, setPsikologOptions] = useState<TSelect[]>([]);
  // is create
  const isCreate = paramId === "create"
  // schema
  const useSchema = yup.object().shape(schema)
  // react hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch
  } = useForm<TPSAssessment>({ resolver: yupResolver(useSchema) });
  // watch
  const doc = watch();
  const company_id: number = watch("company_id");
  const pic_id: number | null = watch("pic_id");
  //const psikolog_id: number | null = watch("psikolog_id");
  const status: number = parseInt(watch("status"));

  useEffect(() => {
    logRender({ type: "page", name: "assessment/Form" });
  }, []);

  const getDoc = useCallback(
    async function getDoc() {
      try {
        // fetch all required data
        // fetch company
        setMessage("Fetch Company");
        const cc = new Company();
        const resCompany = await cc.list();
        const toSelectCompany: TSelect[] = resCompany.data.list
          .map((v: TPSCompany) => { return { id: v.id, value: v.id, label: v.company_name, }; });
        setCompanyOptions(toSelectCompany);
        // fetch assessor
        setMessage("Fetch Assessor");
        const picc = new Assessor();
        const resAssessor = await picc.list();
        const toSelectAssessor: TSelect[] = resAssessor.data.list.map((v: TPSAssessorDetail) => {
          return { id: v.id, value: v.id, label: v.full_name };
        });
        setAssessorOptions(toSelectAssessor);
        // fetch psikolog
        setMessage("Fetch Psikolog");
        const psic = new Psikolog();
        const resPsikolog = await psic.list();
        const toSelectPsikolog: TSelect[] = resPsikolog.data.list.map((v: TPSPsikologDetail) => {
          return { id: v.id, value: v.id, label: v.full_name };
        });
        setPsikologOptions(toSelectPsikolog);
        // init document
        if (isCreate) {
          setValue("id", 0);
          setValue("status", "0");
          setValue("psikologs", []);
        } else {
          setMessage("Fetch Assessment");
          const ac = new Assessment();
          const res = await ac.get(paramId);
          const row: TPSAssessment = res.data.row;
          setValue("id", row.id);
          setValue("assessment_title", row.assessment_title);
          setValue("company_id", row.company_id);
          setValue("pic_id", row.pic_id);
          setValue("psikolog_id", row.psikolog_id);
          setValue("date", row.date);
          setValue("start_time", row.start_time);
          setValue("link_zoom", row.link_zoom);
          setValue("wa_number", row.wa_number);
          setValue("status", row.status);
          setValue("psikologs", row.psikologs);
          setMessage("Fetch Company");
          // get company
          if (row.company_id) {
            const getCompany = await cc.get(row.company_id.toString());
            setValue("company", getCompany.data.row);
          }
          // check pic
          setIsPic(row.pic_id === parseInt(user.assessor_id))
          // check psikolog
          setIsPsikolog(row.psikolog_id === parseInt(user.psikolog_id))
        }
        setMessage("");
      } catch (error) {
        setMessage("");
        const errorMessage = errorHandler(error);
        setNotif({ type: "error", message: errorMessage });
        navigate(`../`, { replace: true });
      }
    },
    [setValue, paramId, isCreate, setNotif, setMessage, navigate, user.assessor_id, user.psikolog_id]
  );

  useEffect(() => {
    getDoc();
  }, [getDoc]);

  const onSubmit: SubmitHandler<TPSAssessment> = async (data) => {
    try {
      setMessage("Save Assessment");
      const ac = new Assessment();
      let res;
      if (isCreate) {
        res = await ac.create(data);
      } else {
        res = await ac.update(data);
      }
      setNotif({ type: "success", message: res.data.message });
      setMessage("");
      // when create new navigate to result form
      if (isCreate) {
        navigate(`../${res.data.result.saved_id}`, { replace: true });
      } else {
        navigate(`../`, { replace: true });
      }
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  const handleChangeStatus = async (index: number) => {
    try {
      if (index.toString() !== doc.status) {
        setMessage("Update Status");
        const ac = new Assessment();
        let res = await ac.update({ ...doc, status: index.toString() });
        setValue("status", index.toString())
        setNotif({ type: "success", message: res.data.message });
        setMessage("");
      }
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  }

  const confirmDelete = async (assessment: TPSAssessment) => {
    try {
      const confirm = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (confirm.isConfirmed) {
        const ac = new Assessment();
        await ac.delete(assessment);
        setNotif({ type: "success", message: "assessor deleted" });
        navigate(`../`, { replace: true });
      }
    } catch (error) {
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  return (
    <div className="w-full flex flex-col space-y-0.5 pb-48">
      {/** Action Button */}
      <div className="flex">
        <BackButton />
        {/** Admin Only */}
        {isAdmin && (
          <>
            <div className="flex w-full space-x-2 mx-2">
              <button
                onClick={handleSubmit(onSubmit)}
                className="block w-auto px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded font-semibold text-sm"
              >
                <div className="flex">
                  <ArrowDownOnSquareIcon className="w-5 h-5 stroke-white fill-tranparent" aria-hidden="true" />
                  <span className="hidden md:block ml-2">Save</span>
                </div>
              </button>
              {!isCreate && (
                <Menu as="div" className="relative items-center border-l border-slate-200">
                  <Menu.Button className="flex w-auto px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded font-semibold text-sm">
                    <ArrowsRightLeftIcon className="w-5 h-5 stroke-white fill-tranparent" aria-hidden="true" />
                    <span className="hidden md:block ml-2">Status</span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="z-10 absolute left-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="px-1 py-1">
                        {stepper.map((step, i) =>
                          <Menu.Item key={`status_btn_${i}`}>
                            {({ active }) => (
                              <button
                                type="button"
                                className={`
                                  group flex rounded-md items-center w-full px-2 py-2 text-sm
                                  ${status === i ? "bg-blue-300 text-white" : status > i ? "" : ""}
                                  ${active ? "bg-blue-400 text-white" : "text-gray-900"} 
                                `}
                                onClick={() => handleChangeStatus(i)}
                              >
                                {step.icon({
                                  className: `w-5 h-5`,
                                  "aria-hidden": true
                                })}
                                <span className="ml-2">{step.label}</span>
                              </button>
                            )}
                          </Menu.Item>
                        )}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              )}
            </div>
            {!isCreate && (
              <button
                onClick={() => confirmDelete(getValues())}
                className="ml-auto block w-auto px-4 py-2 bg-red-500 hover:bg-red-700 text-white rounded font-semibold text-sm"
              >
                <div className="flex">
                  <TrashIcon className="w-5 h-5 stroke-white fill-tranparent" aria-hidden="true" />
                  <span className="hidden md:block ml-2">Delete</span>
                </div>
              </button>
            )}
          </>
        )}
      </div>
      {/** Action Button */}
      <div className="flex"></div>
      <div className="pt-4">
        {/** Stepper */}
        <div className="w-full">
          <div className="mx-4 mb-8 p-4">
            <div className="flex items-center">
              {stepper.map((step, i) =>
                <React.Fragment key={`stepper_${i}`}>
                  <div className={`flex items-center relative`}>
                    <div
                      className={`
                          flex justify-center rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 
                          ${status === i ? "bg-blue-700 border-blue-700" : status > i ? "border-blue-700" : ""}
                        `}
                    >
                      {step.icon({
                        className: `w-5 h-5 ${status === i ? "stroke-white" : status > i ? "stroke-blue-700" : ""}`,
                        "aria-hidden": true
                      })}
                    </div>
                    <div
                      className={`absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase 
                      ${status === i ? "text-blue-700" : status > i ? "text-blue-700" : ""}`}>
                      {step.label}
                    </div>
                  </div>
                  {i + 1 < stepper.length &&
                    <div
                      className={`flex-auto border-t-2 transition duration-500 ease-in-out"
                      ${status === i ? " " : status > i ? "border-blue-700" : ""}`}></div>
                  }
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
        {/** Main Form */}
        <form className="w-full" >
          <div className="flex flex-wrap -mx-3">
            <input {...register("id")} type="number" className="hidden" />
            {/** Assessment Title */}
            <div className="w-full px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Assessment Title</label>
              <input
                className={
                  (errors?.assessment_title ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                  " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                }
                disabled={!isAdmin}
                {...register("assessment_title")}
              />
              {errors.assessment_title && <p className="text-red-500 text-xs italic">{errors.assessment_title.message}</p>}
            </div>
            {/* Company ID */}
            <div className="w-full md:w-full px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Company Name
              </label>
              <div>
                <Select
                  placeholder="Select Company"
                  styles={{
                    control: (provided: Record<string, unknown>, state: any) => {
                      return ({
                        ...provided,
                        borderColor: errors.company_id ? "rgb(239 68 68 / 1)" : "rgb(229 231 235 / 1)",
                        background: state.menuIsOpen ? "white" : "rgb(229 231 235 / 1)"
                      })
                    },
                  }}
                  isDisabled={!isAdmin}
                  value={companyOptions.find(v => v.value === company_id)}
                  options={companyOptions}
                  onChange={(v, _) => {
                    if (v?.value !== undefined && typeof v.value === "number") {
                      setValue("company_id", v.value);
                    }
                  }}
                />
                {errors.company_id && <p className="text-red-500 text-xs italic">{errors.company_id.message}</p>}
              </div>
            </div>
            {/* PIC Assessor */}
            <div className="w-full md:w-1/2 px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                PIC <i className="font-semibold normal-case">(Assessor)</i>
              </label>
              <div>
                <Select
                  placeholder="Select PIC"
                  styles={{
                    control: (provided: Record<string, unknown>, state: any) => {
                      return ({
                        ...provided,
                        borderColor: errors.pic_id ? "rgb(239 68 68 / 1)" : "rgb(229 231 235 / 1)",
                        background: state.menuIsOpen ? "white" : "rgb(229 231 235 / 1)"
                      })
                    },
                  }}
                  isDisabled={!isAdmin}
                  value={assessorOptions.find(v => v.value === pic_id)}
                  options={assessorOptions}
                  onChange={(v, _) => {
                    if (v?.value !== undefined && typeof v.value === "number") {
                      setValue("pic_id", v.value);
                    }
                  }}
                />
              </div>
              {errors.pic_id && <p className="text-red-500 text-xs italic">{errors.pic_id.message}</p>}
            </div>
            {/* Assign Psikolog */}
            <div className="w-full md:w-1/2 px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Psikolog <i className="font-semibold normal-case">(Assigned)</i>
              </label>
              <div>
                <Select
                  placeholder="Select Psikolog"
                  styles={{
                    control: (provided: Record<string, unknown>, state: any) => {
                      return ({
                        ...provided,
                        borderColor: errors.psikolog_id ? "rgb(239 68 68 / 1)" : "rgb(229 231 235 / 1)",
                        background: state.menuIsOpen ? "white" : "rgb(229 231 235 / 1)"
                      })
                    },
                  }}
                  isMulti
                  isDisabled={!isAdmin}
                  value={psikologOptions.filter(v => {
                    return doc.psikologs?.find(ps => ps.psikolog_id === v.value)
                  })}
                  options={psikologOptions}
                  onChange={(v, am) => {
                    //console.log(am);
                    const psikologs: TPSAssessmentPsikolog[] = doc.psikologs
                    if (am.action === "select-option") {
                      v.forEach(value => {
                        if (value.value && typeof value.value === "number") {
                          if (!psikologs.find(ps => ps.psikolog_id === value.value)) {
                            psikologs.push({
                              id: 0,
                              assessment_id: doc ? doc.id : 0,
                              psikolog_id: value.value
                            })
                          }
                        }
                      })
                    }
                    if (am.action === "remove-value") {
                      const indexRemoved = doc.psikologs.findIndex(ps => ps.psikolog_id === am.removedValue.value)
                      psikologs.splice(indexRemoved, 1)
                    }
                    if (am.action === "clear") {
                      psikologs.length = 0
                    }
                    //console.log(psikologs)
                    setValue("psikologs", psikologs);
                  }}
                />
              </div>
              {errors.psikolog_id && <p className="text-red-500 text-xs italic">{errors.psikolog_id.message}</p>}
            </div>
            {/* Date */}
            <div className="w-full md:w-1/2 px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Date
              </label>
              <input
                type="date"
                className={
                  (errors.date ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                  " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                }
                disabled={!isAdmin}
                {...register("date")}
              />
              {errors.date && <p className="text-red-500 text-xs italic">{errors.date.message}</p>}
            </div>
            {/* Start Time */}
            <div className="w-full md:w-1/2 px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Start Time
              </label>
              <input
                type="time"
                className={
                  (errors.start_time ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                  " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                }
                disabled={!isAdmin}
                {...register("start_time")}
              />
              {errors.start_time && (
                <p className="text-red-500 text-xs italic">{errors.start_time.message}</p>
              )}
            </div>
            {/* Link Zoom */}
            <div className="w-full px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Link Zoom
              </label>
              <input
                className={
                  (errors?.link_zoom ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                  " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                }
                disabled={!isAdmin}
                {...register("link_zoom")}
              />
              {errors.link_zoom && <p className="text-red-500 text-xs italic">{errors.link_zoom.message}</p>}
            </div>
            {/* WA Number */}
            <div className="w-full px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                WA Number (Admin)
                <br />
                <i className="font-normal normal-case">Start With Country Code, ex : 62 (Indonesia)</i>
              </label>
              <input
                className={
                  (errors?.wa_number ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                  " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                }
                disabled={!isAdmin}
                {...register("wa_number")}
              />
              {errors.wa_number && <p className="text-red-500 text-xs italic">{errors.wa_number.message}</p>}
            </div>
          </div>
        </form>
        {/** Participant */}
        {!isCreate && getValues("id") > 0 && (
          <Disclosure defaultOpen={isCreate} as="div" className="pb-1">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between w-full px-4 py-4 text-sm font-medium text-left text-gray-100 bg-gray-700 rounded-t hover:bg-gray-800 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                  <span>PARTICIPANT</span>
                  <ChevronUpIcon className={`${open ? "transform rotate-180" : ""} w-5 h-5 text-gray-100`} />
                </Disclosure.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="pb-2 text-sm">
                    <ListParticipant assessment={doc} isPic={isPic} />
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        )}
        {/** Intelligence */}
        {!isCreate && getValues("id") > 0 &&
          <Disclosure defaultOpen={isCreate} as="div" className="pb-1">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between w-full px-4 py-4 text-sm font-medium text-left text-gray-100 bg-blue-900 rounded-t hover:bg-blue-800 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                  <span>INTELLIGENCE</span>
                  <ChevronUpIcon className={`${open ? "transform rotate-180" : ""} w-5 h-5 text-gray-100`} />
                </Disclosure.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                    <ListIntelligence assessment_id={getValues("id")} editable={isAdmin} doc={doc} isPsikolog={isPsikolog} />
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        }
        {/** Personality */}
        {!isCreate && getValues("id") > 0 &&
          <Disclosure defaultOpen={isCreate} as="div" className="pb-1">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between w-full px-4 py-4 text-sm font-medium text-left text-gray-100 bg-red-900 rounded-t hover:bg-red-800 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                  <span>PERSONALITY</span>
                  <ChevronUpIcon className={`${open ? "transform rotate-180" : ""} w-5 h-5 text-gray-100`} />
                </Disclosure.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                    <ListPersonality assessment_id={getValues("id")} editable={isAdmin} doc={doc} isPsikolog={isPsikolog} />
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        }
        {/** Behavioural  */}
        {!isCreate && getValues("id") > 0 &&
          <Disclosure defaultOpen={isCreate} as="div" className="pb-1">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between w-full px-4 py-4 text-sm font-medium text-left text-gray-100 bg-yellow-900 rounded-t hover:bg-yellow-800 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                  <span>BEHAVIOURAL</span>
                  <ChevronUpIcon className={`${open ? "transform rotate-180" : ""} w-5 h-5 text-gray-100`} />
                </Disclosure.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                    <ListBehavioural assessment_id={getValues("id")} editable={isAdmin} doc={doc} isPsikolog={isPsikolog} />
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        }
      </div>
    </div >
  );
}

export default Form;
