
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import Swal from "sweetalert2";

// Icons
import { PlusIcon } from "@heroicons/react/24/outline";

// Context
import SnackbarContext from "../../../context/psikotest/SnackbarContext";
import LoaderContext from "../../../context/psikotest/LoaderContext";

// Helper
import errorHandler from "../../../helper/psikotest/errorHandler";

// Controller
import Ist from "../../../controller/psikotest/psikotest_system/ist";
import IstQuestionary from "../../../controller/psikotest/psikotest_system/ist_questionary";

// Modal
import FormQuestionary from "./modal/FormQuestionary";

const maxQuestionary = 40;

function ListQuestionary(props: { isCreate: boolean; ist: TPSIst; editable: boolean }) {
    const { setMessage } = useContext(LoaderContext);
    const { setNotif } = useContext(SnackbarContext);
    // state list questionary
    const [questionary, setQuestionary] = useState<TPSIstQuestionary[]>([])
    // state modal
    const [isOpenFormQuestionary, setIsOpenFormQuestionary] = useState<boolean>(false);
    // state other
    const [selected_ist_questionary, setSelectedIstQuestionary] = useState<TPSIstQuestionary | undefined>(undefined);
    // state list questionary
    const [questionaryImage, setQuestionaryImage] = useState<TPSIstQuestionaryImage[]>([])

    const getDoc = useCallback(
        async function getDoc() {
            try {
                setMessage("Fetch IST Questionary Image");
                const ic = new Ist();
                const res = await ic.questionary_image(props.ist.id);
                const list: TPSIstQuestionaryImage[] = res.data.list;
                setQuestionaryImage(list);
                if (!props.isCreate) {
                    if (props.ist.id) {
                        // fetch ist_qestionary list
                        setMessage("Fetch Ist Questionary");
                        const ic = new Ist();
                        const res = await ic.questionary(props.ist.id);
                        const list: TPSIstQuestionary[] = res.data.list;
                        setQuestionary(list);
                    }
                }
                setMessage("");
            } catch (error) {
                setMessage("");
                const errorMessage = errorHandler(error);
                setNotif({ type: "error", message: errorMessage });
            }
        },
        [setNotif, setMessage, props.ist.id, props.isCreate]
    );

    // init effect get all questionary
    useEffect(() => {
        getDoc();
    }, [getDoc]);

    // when selected_ist_questionary changes and not undefined open Cfit Questionary form to update
    useEffect(() => {
        if (selected_ist_questionary !== undefined) {
            setIsOpenFormQuestionary(true)
        }
    }, [selected_ist_questionary]);

    // when isOpenFormQuestionary changes and false reset selected_ist_questionary
    useEffect(() => {
        if (!isOpenFormQuestionary) {
            setSelectedIstQuestionary(undefined)
            // re-fetch data
            getDoc();
        }
    }, [isOpenFormQuestionary, getDoc]);

    // handle delete
    const confirmDelete = useCallback(
        async (ist_questionary: TPSIstQuestionary) => {
            try {
                const confirm = await Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                });
                if (confirm.isConfirmed) {
                    const iqc = new IstQuestionary();
                    await iqc.delete(ist_questionary);
                    setNotif({ type: "success", message: "ist deleted" });
                    // re-fetch data
                    getDoc();
                }
            } catch (error) {
                const errorMessage = errorHandler(error);
                setNotif({ type: "error", message: errorMessage });
            }
        },
        [setNotif, getDoc]
    );

    return (
        <div className="pb-4">
            {/* Questionary Form */}
            <Transition
                as={Fragment}
                show={isOpenFormQuestionary}
                enter="ease-out duration-50"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-50"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div>
                    <FormQuestionary data={selected_ist_questionary} ist={props.ist} handleClose={() => setIsOpenFormQuestionary(false)} />
                </div>
            </Transition>
            {questionary.length < maxQuestionary && props.editable && (
                <div className="w-full md:w-full mb-2">
                    <button
                        type="button"
                        onClick={() => { setIsOpenFormQuestionary(true) }}
                        className="block w-auto px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded font-semibold text-sm"
                    >
                        <div className="flex">
                            <PlusIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
                            Questionary
                        </div>
                    </button>
                </div>
            )}
            {props.ist.with_mdi_question_image === 1 ?
                <div className="w-full flex flex-wrap flex-row justify-evenly">
                    {questionary.map((q, i) => {
                        return (
                            <div key={`questionary_${i}`}
                                className="w-full lg:w-52 flex flex-col justify-between m-2 border"
                            >
                                <div className="relative flex flex-grow items-center">
                                    {(questionaryImage.findIndex(qi => qi.id === q.ist_questionary_image_id) > -1) &&
                                        <div className="absolute top-1 right-1 bg-green-600 py-1 px-2 rounded text-white opacity-90">
                                            Question {questionaryImage.findIndex(qi => qi.id === q.ist_questionary_image_id) + 1}
                                        </div>
                                    }
                                    <img className="w-full p-2" src={`${process.env.REACT_APP_URL}/upload/psikotest_system/get_ist_questionary/${q.question}`} alt={q.question ?? "-"}></img>
                                </div>
                                <div className="px-2 py-4 text-center bg-slate-50 border-t">
                                    <span className="block text-xs mb-2">Options</span>
                                    <div className="text-sm mb-2 space-x-1">
                                        {[q.answer_a, q.answer_b, q.answer_c, q.answer_d, q.answer_e].map((v, i) =>
                                            <span key={`option_${i}`}>
                                                {v !== "" ?
                                                    <span className={`py-1 px-2 rounded text-white ${q.correct_answer === v ? "bg-green-700" : "bg-slate-600"}`}>
                                                        {v?.replace("Gambar ", "")}
                                                    </span> :
                                                    "N/A"
                                                }
                                            </span>
                                        )}
                                    </div>
                                    <span className="block text-xs mb-1">Action</span>
                                    <div className="space-x-1">
                                        <button type="button" className="px-2 py-1 rounded bg-blue-500 text-white" onClick={() => setSelectedIstQuestionary(q)}>
                                            Edit
                                        </button>
                                        <button type="button" className="px-2 py-1 rounded bg-red-500 text-white" onClick={() => confirmDelete(q)}>
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div> :
                <div className="w-full">
                    <div className="pt-2">
                        <table className="w-full border">
                            <thead className="bg-slate-600 text-white">
                                <tr>
                                    <th className="py-1 px-2 w-8 border border-slate-500">No</th>
                                    {props.ist.with_question === 1 &&
                                        <th className="py-1 px-2 text-left border border-slate-500">Question</th>
                                    }
                                    {props.ist.answer_type === "pilihan"
                                        ? <th className="py-1 px-2 text-left border border-slate-500">Answer</th>
                                        : <th className="py-1 px-2 text-left border border-slate-500">Correct Answer</th>
                                    }
                                    <th className="py-1 px-2 w-20 border border-slate-500">Edit</th>
                                    <th className="py-1 px-2 w-20 border border-slate-500">Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {questionary.map((q, i) => {
                                    const alphabet = ["A", "B", "C", "D", "E"];
                                    return (
                                        <tr key={`questionary_${i}`}>
                                            <td className="py-1 px-2 text-center border">{i + 1}</td>
                                            {props.ist.with_question === 1 &&
                                                <td className="py-1 px-2 text-left border">{q.question}</td>
                                            }
                                            {props.ist.answer_type === "pilihan"
                                                ? <td className="py-1 px-2 text-left border">
                                                    <div className="flex">
                                                        {[q.answer_a, q.answer_b, q.answer_c, q.answer_d, q.answer_e].map((v, i) => {
                                                            return <div
                                                                key={`answer_${i}`}
                                                                className={`mx-1 px-2 text-white whitespace-nowrap rounded ${q.correct_answer === v ? "bg-green-500" : "bg-slate-500"}`}>
                                                                {`${alphabet[i]}. ${v}`}
                                                            </div>
                                                        })}
                                                    </div>
                                                </td>
                                                : <td className="py-1 px-2 text-left border">{q.correct_answer}</td>
                                            }
                                            <td className="py-1 px-2 text-center border">
                                                <button
                                                    type="button"
                                                    className="px-2 rounded bg-blue-500 text-white"
                                                    onClick={() => setSelectedIstQuestionary(q)}>
                                                    Edit
                                                </button>
                                            </td>
                                            <td className="py-1 px-2 text-center border">
                                                <button
                                                    type="button"
                                                    className="px-2 rounded bg-red-500 text-white"
                                                    onClick={() => confirmDelete(q)}>
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </div>
    );
}

export default ListQuestionary;
