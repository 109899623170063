import { useCallback, useContext, useEffect, useState } from "react";

// Component
import BreadCrumbNavigation from "../../../components/psikotest/psikotest_system/BreadCrumbNavigation";

// Controller
import IndexPage from "../../../controller/psikotest/psikotest_system/index_page";

// Context
import LoaderContext from "../../../context/psikotest/LoaderContext";
import SnackbarContext from "../../../context/psikotest/SnackbarContext";

// Helper
import logRender from "../../../helper/psikotest/logRender";
import errorHandler from "../../../helper/psikotest/errorHandler";

function UserProfile() {
  // call message setter
  const { setMessage } = useContext(LoaderContext);
  // call notif setter
  const { setNotif } = useContext(SnackbarContext);
  // state
  const [user, setUser] = useState<{ id: number; username: string }>({ id: 0, username: "" });

  useEffect(() => {
    logRender({ type: "page", name: "/Me" });
  }, []);

  const getDoc = useCallback(
    async function getDoc() {
      try {
        // show message and loader
        setMessage("Fetch User Detail");
        // create object constructor Category controller
        const ipc = new IndexPage();
        const resUserProfile = await ipc.user_profile();
        setUser(resUserProfile.data.row);
        setMessage("");
      } catch (error) {
        setMessage("");
        const errorMessage = errorHandler(error);
        setNotif({ type: "error", message: errorMessage });
      }
    },
    [setNotif, setMessage]
  );

  useEffect(() => {
    getDoc();
  }, [getDoc]);

  return (
    <div className="w-full flex flex-col space-y-0.5">
      <BreadCrumbNavigation />
      <div className="w-full flex flex-col space-y-0.5">
        <div className="pt-4">
          <div className="flex flex-wrap -mx-3">
            <div className="w-full md:w-full px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Username</label>
              <input className="block w-full bg-white text-gray-700 py-2 px-3" value={user.username} disabled />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
