import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { useCallback, useContext, useEffect, useState } from "react";

// Icons
import { XMarkIcon, ArrowDownOnSquareIcon } from "@heroicons/react/24/outline";

// Contexts
import LoaderContext from "../../../../context/psikotest/LoaderContext";
import SnackbarContext from "../../../../context/psikotest/SnackbarContext";

// Helpers
import errorHandler from "../../../../helper/psikotest/errorHandler";

// Components
import FileUpload from "../../../../components/psikotest/psikotest_system/FileUpload";

// Controller
import CfitQuestionary from "../../../../controller/psikotest/psikotest_system/cfit_questionary";

// Helper Value
const answerListOption = [
    { id: "A,B", label: "A - B" },
    { id: "A,B,C", label: "A - C" },
    { id: "A,B,C,D", label: "A - D" },
    { id: "A,B,C,D,E", label: "A - E" },
    { id: "A,B,C,D,E,F", label: "A - F" },
];

const schema = yup.object().shape({
    id: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required(),
    cfit_id: yup
        .number()
        .label("Cfit ID")
        .transform((value) => (isNaN(value) ? undefined : value))
        .required(),
    question_image: yup.string().label("Question Image").required(),
    answer_list: yup
        .string()
        .label("Answer List")
        .transform((value) => (value === null ? "" : value))
        .required(),
    correct_answer: yup
        .string()
        .label("Correct Answer")
        .transform((value) => (value === null ? "" : value))
        .required(),
});

function FormQuestionary(props: { data?: TPSCfitQuestionary, cfit: TPSCfit, handleClose: () => void }) {
    const { setMessage } = useContext(LoaderContext)
    const { setNotif } = useContext(SnackbarContext)
    // is create
    const isCreate = props.data === undefined
    const [id, setId] = useState<number>(0)
    const [file_path, setFilePath] = useState<string>("")
    const [correct_answer_list, setCorrectAnswerList] = useState<string[]>([])

    // react hook form
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch
    } = useForm<TPSCfitQuestionary>({ resolver: yupResolver(schema) });
    // watch all field
    const doc = watch()

    const getDoc = useCallback(
        async function getDoc() {
            try {
                // fetch document
                if (isCreate) {
                    // update cfit_id
                    setValue("cfit_id", props.cfit.id);
                    // sementara test
                    setValue("answer_list", "A,B,C,D,E,F");
                } else {
                    setValue("id", props.data?.id ?? 0);
                    setValue("cfit_id", props.cfit?.id ?? props.cfit.id);
                    setValue("question_image", props.data?.question_image ?? "");
                    setValue("answer_list", props.data?.answer_list ?? "");
                    setValue("correct_answer", props.data?.correct_answer ?? "");
                    // helper state
                    setCorrectAnswerList((props.data?.correct_answer ?? "").split(","))
                    // additional field
                    setId(props.data?.id ?? 0);
                    setFilePath(props.data?.question_image ?? "");
                }
                setMessage("");
            } catch (error) {
                setMessage("");
                const errorMessage = errorHandler(error);
                setNotif({ type: "error", message: errorMessage });
            }
        },
        [setValue, isCreate, setNotif, setMessage, props.cfit.id, props.data]
    );

    useEffect(() => {
        getDoc();
    }, [getDoc]);

    useEffect(() => {
        setValue("id", id);
    }, [setValue, id]);

    useEffect(() => {
        setValue("question_image", file_path);
    }, [setValue, file_path]);

    useEffect(() => {
        setValue("correct_answer", correct_answer_list.join(","));
    }, [setValue, correct_answer_list]);

    const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        // let copy value with spread to make sure not reference the variable
        let answer = [...correct_answer_list]
        if (event.target.checked) {
            answer.push(event.target.value)
        } else {
            answer = answer.filter(v => v !== event.target.value)
        }
        // limit answer by minimum of selected answer
        // if more than minimum of selected answer remove first answer
        if (answer.length > props.cfit.minimum_selected_answer) {
            answer.shift();
        }
        setCorrectAnswerList(answer)
    }

    const onSubmit: SubmitHandler<TPSCfitQuestionary> = async (data) => {
        try {
            setMessage("Save Cfit Questionary");
            const ccq = new CfitQuestionary();
            const res = await ccq.update(data);
            setNotif({ type: "success", message: res.data.message });
            setMessage("");
            props.handleClose();
        } catch (error) {
            setMessage("");
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    };

    return <div>
        <div className="fixed z-20 top-0 left-0 w-screen h-screen bg-black opacity-20"></div>
        <div className="fixed z-30 top-0 left-0 w-screen h-screen bg-transparent flex items-center justify-center">
            <div className="bg-white w-screen md:w-[50%] h-auto md:rounded overflow-hidden">
                <div className="p-4 bg-green-800 md:rounded-t text-white flex justify-between">
                    <h3>Form Questionary</h3>
                    <XMarkIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent cursor-pointer" aria-hidden="true" onClick={() => props.handleClose()} />
                </div>
                <div className="p-4">
                    {/** File Question */}
                    <div className="w-full md:w-full mb-6">
                        <FileUpload
                            type="cfit_questionary"
                            editable={true}
                            document_id={id}
                            filepath={file_path}
                            error_field={errors?.question_image}
                            otherRequiredData={{ cfit_id: props.cfit.id }}
                            setDocumentId={(id) => {
                                setId(id);
                            }}
                            setFilePath={(filepath) => setFilePath(filepath)}
                            key={id}
                        />
                    </div>
                    <form className="w-full">
                        <div className="flex flex-wrap -mx-3">
                            <input {...register("id")} type="number" className="hidden" />
                            {errors.id && (
                                <p className="text-red-500 text-xs italic">{errors.id.message}</p>
                            )}
                            <input {...register("cfit_id")} type="number" className="hidden" />
                            {errors.cfit_id && (
                                <p className="text-red-500 text-xs italic">{errors.cfit_id.message}</p>
                            )}
                            <input {...register("question_image")} type="text" className="hidden" />
                            {/** Answer List - Range */}
                            <div className="w-full md:w-full px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Answer List - Range
                                </label>
                                <div className="relative after:content-['↓'] after:absolute after:right-4 after:top-3 after:pointer-events-none">
                                    <select
                                        className={
                                            (errors?.answer_list
                                                ? "border-red-500"
                                                : "border-gray-200 focus:border-gray-500") +
                                            " appearance-none first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-800"
                                        }
                                        disabled={false}
                                        defaultValue=""
                                        {...register("answer_list")}
                                    >
                                        <option value="" disabled>
                                            Please Select
                                        </option>
                                        {answerListOption.map((v, i) => (
                                            <option key={i} value={v.id}>
                                                {v.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                {errors.answer_list && (
                                    <p className="text-red-500 text-xs italic">{errors.answer_list.message}</p>
                                )}
                            </div>
                            {/** Correct Answer */}
                            <div className="w-full md:w-full px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Correct Answer
                                </label>
                                <div className="flex space-x-2">
                                    {(doc.answer_list?.split(",") ?? []).map((v, i) =>
                                        <div key={`answer_list_${i}`} className="flex flex-row">
                                            <input
                                                id={`label${v}`} type="checkbox"
                                                value={v}
                                                checked={correct_answer_list.includes(v)}
                                                onChange={(event) => handleCheckbox(event)}
                                            />
                                            <label htmlFor={`label${v}`} className="pl-2 pr-4 font-semibold">{v}</label>
                                        </div>
                                    )}
                                </div>
                                {errors.correct_answer && (
                                    <p className="text-red-500 text-xs italic">{errors.correct_answer.message}</p>
                                )}
                            </div>
                        </div>
                    </form>
                    <div className="flex">
                        <button
                            onClick={handleSubmit(onSubmit)}
                            className="ml-auto block w-auto px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded font-semibold text-sm"
                        >
                            <div className="flex">
                                <ArrowDownOnSquareIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
                                Save
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
}


export default FormQuestionary;