import { useCallback, useContext, useEffect, useState } from "react";
import * as XLSX from 'sheetjs-style';

// Icons
import { PrinterIcon, XMarkIcon } from "@heroicons/react/24/outline";

// Contexts
import LoaderContext from "../../../../context/psikotest/LoaderContext";
import SnackbarContext from "../../../../context/psikotest/SnackbarContext";

// Helpers
import errorHandler from "../../../../helper/psikotest/errorHandler";

// Controller
import Assessment from "../../../../controller/psikotest/psikotest_system/assessment"
import Srq20Questionary from "../../../../controller/psikotest/psikotest_system/srq20_questionary";
import MmpiQuestionary from "../../../../controller/psikotest/psikotest_system/mmpi_questionary";
import BrsQuestionary from "../../../../controller/psikotest/psikotest_system/brs_questionary";

// Variables
const paramMbti = ["i", "e", "s", "n", "t", "f", "j", "p"]
const paramPapi = ['N', 'G', 'A', 'L', 'P', 'I', 'T', 'V', 'X', 'S', 'B', 'O', 'R', 'D', 'C', 'Z', 'E', 'K', 'F', 'W']
const listPa = ['G', 'L', 'I', 'T', 'V', 'S', 'R', 'D', 'C', 'E']
const listPi = ['N', 'A', 'P', 'X', 'B', 'O', 'Z', 'K', 'F', 'W']
const paramEpps = ['ACH', 'DEF', 'ORD', 'EXH', 'AUT', 'AFF', 'INT', 'SUC', 'DOM', 'ABA', 'NUR', 'CHG', 'END', 'HET', 'AGG'];
const paramSrq20 = ['yes', 'no'];

function ModalPersonalityScore(
    props: {
        doc: TPSAssessment;
        assessment_id: number;
        assessment_personality_subtest: TPSAssessmentPersonalitySubtest[];
        handleClose: () => void;
    }) {
    // Contexts
    const { setMessage } = useContext(LoaderContext);
    const { setNotif } = useContext(SnackbarContext);
    // Variables
    const use_mbti = props.assessment_personality_subtest.some(aps => aps.personality_subtest_name === "MBTI")
    const use_papi = props.assessment_personality_subtest.some(aps => aps.personality_subtest_name === "PAPI")
    const use_epps = props.assessment_personality_subtest.some(aps => aps.personality_subtest_name === "EPPS")
    const use_srq20 = props.assessment_personality_subtest.some(aps => aps.personality_subtest_name === "SRQ-20")
    const use_mmpi = props.assessment_personality_subtest.some(aps => aps.personality_subtest_name === "MMPI")
    const use_brs = props.assessment_personality_subtest.some(aps => aps.personality_subtest_name === "BRS")
    // state
    const [personality_score, setPersonalityScore] = useState<TPSAssessmentPersonalityScore[]>([])
    const [srq20_questionaries, setSrq20Questionaries] = useState<TPSSrq20Questionary[]>([])
    const [mmpi_questionaries, setMmpiQuestionaries] = useState<TPSMmpiQuestionary[]>([])
    const [brs_questionaries, setBrsQuestionaries] = useState<TPSBrsQuestionary[]>([])

    const getDoc = useCallback(
        async function getDoc() {
            try {
                // fetch personality score
                setMessage("Fetch Personality Score");
                const ca = new Assessment()
                let res = await ca.personality_score(props.assessment_id)
                setPersonalityScore(res.data.list)
                // get SRQ-20 questionaries
                setMessage("Fetch SRQ-20 Questionaries Score");
                const srq20_qc = new Srq20Questionary();
                const res_qc = await srq20_qc.list();
                setSrq20Questionaries(res_qc.data.list);
                // get MMPI questionaries
                setMessage("Fetch MMPI Questionaries Score");
                const mmpi_qc = new MmpiQuestionary();
                const res_mmpi_qc = await mmpi_qc.list();
                setMmpiQuestionaries(res_mmpi_qc.data.list);
                // get BRS questionaries
                setMessage("Fetch BRS Questionaries Score");
                const brs_qc = new BrsQuestionary();
                const res_brs_qc = await brs_qc.list();
                setBrsQuestionaries(res_brs_qc.data.list);
                setMessage("");
            } catch (error) {
                setMessage("");
                const errorMessage = errorHandler(error);
                setNotif({ type: "error", message: errorMessage });
            }
        },
        [setNotif, setMessage, props.assessment_id]
    );

    // initial use effect
    useEffect(() => {
        getDoc();
    }, [getDoc]);

    // Function Helper
    const calculatePapi = (papi_score_json: string): { pa: number; pi: number } => {
        let papi_score = JSON.parse(papi_score_json)
        let result = { pa: 0, pi: 0 }
        listPa.forEach(pa => { result.pa += (papi_score?.[pa] ?? 0) })
        listPi.forEach(pi => { result.pi += (papi_score?.[pi] ?? 0) })
        return result
    }
    const calculateEppsConsistency = (epps_consistency_json: string): number => {
        let epps_consistency: { [key: string]: number } = JSON.parse(epps_consistency_json)
        let result = 0
        Object.keys(epps_consistency ?? []).forEach(ec_k => { result += epps_consistency[ec_k] })
        return result
    }

    const letterFromNumber = (n: number): string => {
        const ordA = 'a'.charCodeAt(0);
        const ordZ = 'z'.charCodeAt(0);
        const len = ordZ - ordA + 1;

        let s = "";
        while (n >= 0) {
            s = String.fromCharCode(n % len + ordA) + s;
            n = Math.floor(n / len) - 1;
        }
        return s.toUpperCase();
    };

    const downloadExcel = () => {
        // start create excel report
        const workbook = XLSX.utils.book_new()

        let alphabet = "A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z"
        // generated alphabet up to 100 column
        for (let index = 0; index <= 1000; index++) {
            const column = alphabet.split(",")[index];
            if (column === undefined) {
                alphabet += `,${letterFromNumber(index)}`
            }
        }

        // MBTI
        if (use_mbti) {
            // start generate data_mbti
            let data_mbti = []
            // header
            data_mbti.push({})
            // actual data_mbti
            personality_score.forEach((ps, i) => {
                let composedData: { [key: string]: any } = {
                    No: i + 1,
                    Participant: ps.participant.full_name,
                    Age: ps.age,
                    Gender: ps.participant.gender,
                }
                const ps_result = JSON.parse(ps.mbti_score.result)
                paramMbti.forEach(mbti => {
                    composedData[`${mbti.toUpperCase()}`] = ps_result?.[mbti] ? (ps_result?.[mbti] / 15 * 100).toFixed(0) + "%" : ""
                })
                composedData["Progress"] = `${(JSON.parse(ps.mbti_score.answer) ?? []).filter((a: any) => a.value !== "").length}/60`
                composedData["Result"] = `${ps_result?.i === ps_result?.e ? "-" : ps_result?.i > ps_result?.e ? "I" : "E"}${ps_result?.s === ps_result?.n ? "-" : ps_result?.s > ps_result?.n ? "S" : "N"}${ps_result?.t === ps_result?.f ? "-" : ps_result?.t > ps_result?.f ? "T" : "F"}${ps_result?.j === ps_result?.p ? "-" : ps_result?.j > ps_result?.p ? "J" : "P"}`
                data_mbti.push(composedData)
            })

            // generate worksheet
            const worksheet = XLSX.utils.json_to_sheet(data_mbti)

            // start generated excel
            XLSX.utils.book_append_sheet(workbook, worksheet, "MBTI")

            // fix headers
            // main header
            let header: string[] = ["No", "Participant", "Age", "Gender"];
            let subheader: string[] = header
            const mbti_subtest: string[] = []
            const mbti_subtest_subheader: string[] = []
            // mbti header
            paramMbti.forEach(mbti => {
                mbti_subtest.push("MBTI")
                mbti_subtest_subheader.push(mbti.toUpperCase() ?? "-")
            })
            header = [...header, ...mbti_subtest]
            subheader = [...subheader, ...mbti_subtest_subheader, "Progress", "Result"]

            XLSX.utils.sheet_add_aoa(worksheet, [header, subheader], { origin: "A1" });

            // merge column 
            worksheet["!merges"] = []
            // No
            worksheet["!merges"].push(XLSX.utils.decode_range("A1:A2"));
            // Participant
            worksheet["!merges"].push(XLSX.utils.decode_range("B1:B2"));
            // Age
            worksheet["!merges"].push(XLSX.utils.decode_range("C1:C2"));
            // Gender
            worksheet["!merges"].push(XLSX.utils.decode_range("D1:D2"));
            // MBTI Row 1 Header 
            let colStart = paramMbti.length + 3
            worksheet["!merges"].push(XLSX.utils.decode_range(`E1:${letterFromNumber(colStart)}1`));
            // Progress
            colStart++
            let currAlphabet = alphabet.split(",")[colStart]
            worksheet["!merges"].push(XLSX.utils.decode_range(`${currAlphabet}1:${currAlphabet}2`));
            // Result
            colStart++
            currAlphabet = alphabet.split(",")[colStart]
            worksheet["!merges"].push(XLSX.utils.decode_range(`${currAlphabet}1:${currAlphabet}2`));

            // set width
            let wscols = [
                { width: 10 },
                { width: 30 }
            ];
            worksheet["!cols"] = wscols;
        }

        // PAPI
        if (use_papi) {
            // start generate data_papi
            let data_papi: any = []
            // actual data_papi
            personality_score.forEach((ps, i) => {
                let composedData: { [key: string]: any } = {
                    No: i + 1,
                    Participant: ps.participant.full_name,
                    Age: ps.age,
                    Gender: ps.participant.gender,
                }
                const ps_result = JSON.parse(ps.papi_score.result)
                paramPapi.forEach(papi => {
                    composedData[`${papi.toUpperCase()}`] = ps_result?.[papi]
                })
                composedData["Progress"] = `${(JSON.parse(ps.papi_score.answer) ?? []).filter((a: any) => a.value !== "").length}/90`
                const papi_control = calculatePapi(ps.papi_score.result)
                composedData["PA"] = papi_control.pa
                composedData["PI"] = papi_control.pi
                data_papi.push(composedData)
            })

            // generate worksheet
            const worksheet = XLSX.utils.json_to_sheet(data_papi)

            // start generated excel
            XLSX.utils.book_append_sheet(workbook, worksheet, "PAPI")

            // fix headers
            // main header
            let header: string[] = ["No", "Participant", "Age", "Gender"];
            const papi_subtest: string[] = []
            // papi header
            paramPapi.forEach(papi => {
                papi_subtest.push(papi.toUpperCase() ?? "-")
            })
            header = [...header, ...papi_subtest, "Progress", "PA", "PI"]

            // append sheet
            XLSX.utils.sheet_add_aoa(worksheet, [header], { origin: "A1" });

            // set width
            let wscols = [
                { width: 10 },
                { width: 30 }
            ];
            worksheet["!cols"] = wscols;
        }

        // EPPS
        if (use_epps) {
            // start generate data_epps
            let data_epps: any = []
            // actual data_epps
            personality_score.forEach((ps, i) => {
                let composedData: { [key: string]: any } = {
                    No: i + 1,
                    Participant: ps.participant.full_name,
                    Age: ps.age,
                    Gender: ps.participant.gender,
                }
                const ps_result = JSON.parse(ps.epps_score.result)
                paramEpps.forEach(p => {
                    const score = ps_result?.[`${p}_SCORE`]
                    const strScore = score < 0
                        ? "-".repeat(Math.abs(score))
                        : score === 0 ? '0' : "+".repeat(score)
                    composedData[p] = strScore
                })
                composedData["Progress"] = `${(JSON.parse(ps.epps_score.answer) ?? []).filter((a: any) => a.value !== "").length}/210`
                const epps_consistency = calculateEppsConsistency(ps.epps_score.consistency)
                composedData["Consistency"] = `${epps_consistency}/15`
                data_epps.push(composedData)
            })

            // generate worksheet
            const worksheet = XLSX.utils.json_to_sheet(data_epps)

            // start generated excel
            XLSX.utils.book_append_sheet(workbook, worksheet, "EPPS")

            // fix headers
            // main header
            let header: string[] = ["No", "Participant", "Age", "Gender"];
            const epps_subtest: string[] = []
            // epps header
            paramEpps.forEach(p => {
                epps_subtest.push(p ?? "-")
            })
            header = [...header, ...epps_subtest, "Progress", "Consistency"]

            // append sheet
            XLSX.utils.sheet_add_aoa(worksheet, [header], { origin: "A1" });

            // set width
            let wscols = [
                { width: 10 },
                { width: 30 }
            ];
            worksheet["!cols"] = wscols;
        }

        // SRQ-20
        if (use_srq20) {
            // start generate data_srq20
            let data_srq20 = []
            // header
            data_srq20.push({})
            // actual data_srq20
            personality_score.forEach((ps, i) => {
                let composedData: { [key: string]: any } = {
                    No: i + 1,
                    Participant: ps.participant.full_name,
                    Age: ps.age,
                    Gender: ps.participant.gender,
                }
                const ps_answer: { number: number, answer: 'yes' | 'no' }[] = JSON.parse(ps.srq20_score.answer)
                const ps_result: { yes: number, no: number } = JSON.parse(ps.srq20_score.result)
                srq20_questionaries.forEach(srq20_q => {
                    composedData['a' + srq20_q.number] = ps_answer.find(a => a.number === srq20_q.number)?.answer ?? '-'
                })
                composedData["Answer (Yes)"] = ps_result.yes
                composedData["Answer (No)"] = ps_result.no
                composedData["Total"] = `${ps_answer.filter((a: any) => a.answer !== "").length}/20`
                composedData["Result"] = ps_result.yes >= 5 ? "PERLU PEMERIKSAAN LEBIH LANJUT" : "TIDAK PERLU PEMERIKSAAN LEBIH LANJUT"
                data_srq20.push(composedData)
            })

            // generate worksheet
            const worksheet = XLSX.utils.json_to_sheet(data_srq20)

            // start generated excel
            XLSX.utils.book_append_sheet(workbook, worksheet, "SRQ-20")

            // fix headers
            // main header
            let header: string[] = ["No", "Participant", "Age", "Gender"];
            let subheader: string[] = header
            const srq20_subtest: string[] = []
            const srq20_subtest_subheader: string[] = []
            // mbti header
            srq20_questionaries.forEach((srq20_q, i) => {
                srq20_subtest.push((i + 1).toString())
                srq20_subtest_subheader.push(srq20_q.question)
            })
            header = [...header, ...srq20_subtest]
            subheader = [...subheader, ...srq20_subtest_subheader, "Answer (Yes)", "Answer (No)", "Total", "Result"]

            XLSX.utils.sheet_add_aoa(worksheet, [header, subheader], { origin: "A1" });

            // merge column 
            worksheet["!merges"] = []
            // No
            worksheet["!merges"].push(XLSX.utils.decode_range("A1:A2"));
            // Participant
            worksheet["!merges"].push(XLSX.utils.decode_range("B1:B2"));
            // Age
            worksheet["!merges"].push(XLSX.utils.decode_range("C1:C2"));
            // Gender
            worksheet["!merges"].push(XLSX.utils.decode_range("D1:D2"));
            // SRQ-20 Row 1 Header (Numeric)
            let colStart = srq20_questionaries.length + 3
            // worksheet["!merges"].push(XLSX.utils.decode_range(`E1:${letterFromNumber(colStart)}1`));
            // Yes
            colStart++
            let currAlphabet = alphabet.split(",")[colStart]
            worksheet["!merges"].push(XLSX.utils.decode_range(`${currAlphabet}1:${currAlphabet}2`));
            // No
            colStart++
            currAlphabet = alphabet.split(",")[colStart]
            worksheet["!merges"].push(XLSX.utils.decode_range(`${currAlphabet}1:${currAlphabet}2`));
            // Total
            colStart++
            currAlphabet = alphabet.split(",")[colStart]
            worksheet["!merges"].push(XLSX.utils.decode_range(`${currAlphabet}1:${currAlphabet}2`));
            // Result
            colStart++
            currAlphabet = alphabet.split(",")[colStart]
            worksheet["!merges"].push(XLSX.utils.decode_range(`${currAlphabet}1:${currAlphabet}2`));

            // set width
            let wscols = [
                { width: 10 },
                { width: 30 },
                { width: 10 },
                { width: 10 },
            ];
            // width each question
            srq20_questionaries.forEach(_ => {
                wscols.push({ width: 10 })
            });
            // width answer
            wscols.push({ width: 14 })
            wscols.push({ width: 14 })
            wscols.push({ width: 14 })
            wscols.push({ width: 36.5 })
            worksheet["!cols"] = wscols;
            // set height
            let wsrows = [
                { hpt: 15.6 },
                { hpt: 146 },
            ];
            worksheet["!rows"] = wsrows;
            // style
            // participant detail header, start from A = 0, length = 4 (no, participant, age, gender)
            for (let index = 0; index < 4; index++) {
                const letter = letterFromNumber(index)
                // participant detail style
                const style = {
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center'
                    }
                }
                if (worksheet[`${letter}1`]) {
                    worksheet[`${letter}1`].s = style
                }
            }
            // questionary result header, start from E = 4, length = 20
            for (let index = 4; index < 24; index++) {
                const letter = letterFromNumber(index)
                // questionary number style
                const styleQN = {
                    alignment: {
                        horizontal: 'center',
                    },
                    font: {
                        bold: true
                    }
                }
                // questionary string style
                const styleQ = {
                    alignment: {
                        wrapText: '1',
                    }
                }
                if (worksheet[`${letter}1`]) {
                    worksheet[`${letter}1`].s = styleQN
                }
                if (worksheet[`${letter}2`]) {
                    worksheet[`${letter}2`].s = styleQ
                }
            }
            // other result header, start from Y = 24, length = 4 (answer yes, answer no, total, result)
            for (let index = 24; index < 28; index++) {
                const letter = letterFromNumber(index)
                // participant detail style
                const style = {
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center'
                    }
                }
                if (worksheet[`${letter}1`]) {
                    worksheet[`${letter}1`].s = style
                }
            }
            // style base on value
            // result style, data start from, column 27, start row 3, length = personality_score.length
            personality_score.forEach((ps, i) => {
                const ps_result: { yes: number, no: number } = JSON.parse(ps.srq20_score.result)
                // get letter from number
                const letter = letterFromNumber(27)
                // result detail style
                const style = {
                    font: {
                        color: { rgb: ps_result.yes >= 5 ? 'FFFF0000' : 'FF00B050' }
                    }
                }
                if (worksheet[`${letter}${i + 3}`]) {
                    worksheet[`${letter}${i + 3}`].s = style
                }
            })
        }

        // MMPI
        if (use_mmpi) {
            // start generate data_mmpi
            let data_mmpi = []
            // header
            data_mmpi.push({})
            // actual data_mmpi
            personality_score.forEach((ps, i) => {
                let composedData: { [key: string]: any } = {
                    No: i + 1,
                    Participant: ps.participant.full_name,
                    Age: ps.age,
                    Gender: ps.participant.gender,
                }
                const ps_answer: { number: number, answer: 'yes' | 'no' }[] = JSON.parse(ps.mmpi_score.answer)
                //const ps_result: { yes: number, no: number } = JSON.parse(ps.mmpi_score.result)
                mmpi_questionaries.forEach(mmpi_q => {
                    composedData['a' + mmpi_q.number] = (ps_answer ?? []).find(a => a.number === mmpi_q.number)?.answer ?? '-'
                })
                composedData["Total"] = `${(ps_answer ?? []).filter((a: any) => a.answer !== "").length}/567`
                composedData["Answer (Empty)"] = `${(ps_answer ?? []).filter((a: any) => a.answer === "").length}`
                composedData["Validity"] = (JSON.parse(ps.mmpi_score.answer) ?? []).filter((a: any) => a.answer === "").length >= 30
                    ? "Mungkin Tidak Valid"
                    : (JSON.parse(ps.mmpi_score.answer) ?? []).filter((a: any) => a.answer === "").length <= 10
                        ? "Kemungkinan Valid"
                        : "Validitas Dipertanyakan";
                data_mmpi.push(composedData)
            })

            // generate worksheet
            const worksheet = XLSX.utils.json_to_sheet(data_mmpi)

            // start generated excel
            XLSX.utils.book_append_sheet(workbook, worksheet, "MMPI")

            // fix headers
            // main header
            let header: string[] = ["No", "Participant", "Age", "Gender"];
            let subheader: string[] = header
            const mmpi_subtest: string[] = []
            const mmpi_subtest_subheader: string[] = []
            // mmpi header
            mmpi_questionaries.forEach((mmpi_q, i) => {
                mmpi_subtest.push((i + 1).toString())
                mmpi_subtest_subheader.push(mmpi_q.question)
            })
            header = [...header, ...mmpi_subtest]
            subheader = [...subheader, ...mmpi_subtest_subheader, "Total", "Answer (Empty)", "Validity"]

            XLSX.utils.sheet_add_aoa(worksheet, [header, subheader], { origin: "A1" });

            // merge column 
            worksheet["!merges"] = []
            // No
            worksheet["!merges"].push(XLSX.utils.decode_range("A1:A2"));
            // Participant
            worksheet["!merges"].push(XLSX.utils.decode_range("B1:B2"));
            // Age
            worksheet["!merges"].push(XLSX.utils.decode_range("C1:C2"));
            // Gender
            worksheet["!merges"].push(XLSX.utils.decode_range("D1:D2"));
            // MMPI Row 1 Header (Numeric)
            let colStart = mmpi_questionaries.length + 3
            // worksheet["!merges"].push(XLSX.utils.decode_range(`E1:${letterFromNumber(colStart)}1`));
            // Total
            colStart++
            let currAlphabet = alphabet.split(",")[colStart]
            console.log(currAlphabet)
            worksheet["!merges"].push(XLSX.utils.decode_range(`${currAlphabet}1:${currAlphabet}2`));
            // Answer (Empty)
            colStart++
            currAlphabet = alphabet.split(",")[colStart]
            worksheet["!merges"].push(XLSX.utils.decode_range(`${currAlphabet}1:${currAlphabet}2`));
            // Result
            colStart++
            currAlphabet = alphabet.split(",")[colStart]
            worksheet["!merges"].push(XLSX.utils.decode_range(`${currAlphabet}1:${currAlphabet}2`));

            // set width
            let wscols = [
                { width: 10 },
                { width: 30 },
                { width: 10 },
                { width: 10 },
            ];
            // width each question
            mmpi_questionaries.forEach(_ => {
                wscols.push({ width: 10 })
            });
            // width answer
            wscols.push({ width: 14 })
            wscols.push({ width: 14 })
            wscols.push({ width: 14 })
            wscols.push({ width: 36.5 })
            worksheet["!cols"] = wscols;
            // set height
            let wsrows = [
                { hpt: 15.6 },
                { hpt: 146 },
            ];
            worksheet["!rows"] = wsrows;
            // style
            // participant detail header, start from A = 0, length = 4 (no, participant, age, gender)
            for (let index = 0; index < 4; index++) {
                const letter = letterFromNumber(index)
                // participant detail style
                const style = {
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center'
                    }
                }
                if (worksheet[`${letter}1`]) {
                    worksheet[`${letter}1`].s = style
                }
            }
            // questionary result header, start from E = 4, length = mmpi_questionaries.length
            for (let index = 4; index < (4 + mmpi_questionaries.length); index++) {
                const letter = letterFromNumber(index)
                // questionary number style
                const styleQN = {
                    alignment: {
                        horizontal: 'center',
                    },
                    font: {
                        bold: true
                    }
                }
                // questionary string style
                const styleQ = {
                    alignment: {
                        vertical: 'top',
                        wrapText: '1',
                    }
                }
                if (worksheet[`${letter}1`]) {
                    worksheet[`${letter}1`].s = styleQN
                }
                if (worksheet[`${letter}2`]) {
                    worksheet[`${letter}2`].s = styleQ
                }
            }
            // other result header, start from Y = (4 + mmpi_questionaries.length), length = (4 + mmpi_questionaries.length + 3) (total, answer empty, result)
            for (let index = (4 + mmpi_questionaries.length); index < (4 + mmpi_questionaries.length + 3); index++) {
                const letter = letterFromNumber(index)
                // participant detail style
                const style = {
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center'
                    }
                }
                if (worksheet[`${letter}1`]) {
                    worksheet[`${letter}1`].s = style
                }
            }
            // style base on value
            // result style, data start from, column (4 + mmpi_questionaries.length + 3), start row 3, length = personality_score.length
            personality_score.forEach((ps, i) => {
                //const ps_result: { yes: number, no: number } = JSON.parse(ps.mmpi_score.result)
                // get letter from number
                const letter = letterFromNumber((4 + mmpi_questionaries.length + 2))
                // result detail style
                const style = {
                    font: {
                        color: {
                            rgb: (JSON.parse(ps.mmpi_score.answer) ?? []).filter((a: any) => a.answer === "").length >= 30
                                ? "DC262600"
                                : (JSON.parse(ps.mmpi_score.answer) ?? []).filter((a: any) => a.answer === "").length <= 10
                                    ? "15803D00"
                                    : "854D0E00"
                        }
                    }
                }
                if (worksheet[`${letter}${i + 3}`]) {
                    worksheet[`${letter}${i + 3}`].s = style
                }
            })
        }

        // BRS
        if (use_brs) {
            // start generate data_brs
            let data_brs = []
            // header
            data_brs.push({})
            // actual data_mmpi
            personality_score.forEach((ps, i) => {
                let composedData: { [key: string]: any } = {
                    No: i + 1,
                    Participant: ps.participant.full_name,
                    Age: ps.age,
                    Gender: ps.participant.gender,
                }
                const ps_answer: { number: number, answer: string }[] = JSON.parse(ps.brs_score.answer)
                brs_questionaries.forEach(brs_q => {
                    composedData['a' + brs_q.number] = (ps_answer ?? []).find(a => a.number === brs_q.number)?.answer ?? '-'
                })
                composedData["Total"] = `${(ps_answer ?? []).filter((a: any) => a.answer !== "").length}/${brs_questionaries.length}`
                composedData["Score"] = ps.brs_score.score_result
                composedData["Interpretation"] = ps.brs_score.interpretation;
                data_brs.push(composedData)
            })

            // generate worksheet
            const worksheet = XLSX.utils.json_to_sheet(data_brs)

            // start generated excel
            XLSX.utils.book_append_sheet(workbook, worksheet, "BRS")

            // fix headers
            // main header
            let header: string[] = ["No", "Participant", "Age", "Gender"];
            let subheader: string[] = header
            const brs_subtest: string[] = []
            const brs_subtest_subheader: string[] = []
            // mmpi header
            brs_questionaries.forEach((brs_q, i) => {
                brs_subtest.push((i + 1).toString())
                brs_subtest_subheader.push(brs_q.question)
            })
            header = [...header, ...brs_subtest]
            subheader = [...subheader, ...brs_subtest_subheader, "Total", "Score", "Interpretation"]

            XLSX.utils.sheet_add_aoa(worksheet, [header, subheader], { origin: "A1" });

            // merge column 
            worksheet["!merges"] = []
            // No
            worksheet["!merges"].push(XLSX.utils.decode_range("A1:A2"));
            // Participant
            worksheet["!merges"].push(XLSX.utils.decode_range("B1:B2"));
            // Age
            worksheet["!merges"].push(XLSX.utils.decode_range("C1:C2"));
            // Gender
            worksheet["!merges"].push(XLSX.utils.decode_range("D1:D2"));
            // BRS Row 1 Header (Numeric)
            let colStart = brs_questionaries.length + 3
            console.log(colStart)
            // worksheet["!merges"].push(XLSX.utils.decode_range(`E1:${letterFromNumber(colStart)}1`));
            // Total
            colStart++
            let currAlphabet = alphabet.split(",")[colStart]
            console.log(currAlphabet)
            worksheet["!merges"].push(XLSX.utils.decode_range(`${currAlphabet}1:${currAlphabet}2`));
            // Score
            colStart++
            currAlphabet = alphabet.split(",")[colStart]
            worksheet["!merges"].push(XLSX.utils.decode_range(`${currAlphabet}1:${currAlphabet}2`));
            // Interpretation
            colStart++
            currAlphabet = alphabet.split(",")[colStart]
            worksheet["!merges"].push(XLSX.utils.decode_range(`${currAlphabet}1:${currAlphabet}2`));

            // set width
            let wscols = [
                { width: 10 },
                { width: 30 },
                { width: 10 },
                { width: 10 },
            ];
            // width each question
            brs_questionaries.forEach(_ => {
                wscols.push({ width: 10 })
            });
            // width answer
            wscols.push({ width: 14 })
            wscols.push({ width: 14 })
            wscols.push({ width: 14 })
            wscols.push({ width: 36.5 })
            worksheet["!cols"] = wscols;
            // set height
            let wsrows = [
                { hpt: 15.6 },
                { hpt: 146 },
            ];
            worksheet["!rows"] = wsrows;
            // style
            // participant detail header, start from A = 0, length = 4 (no, participant, age, gender)
            for (let index = 0; index < 4; index++) {
                const letter = letterFromNumber(index)
                // participant detail style
                const style = {
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center'
                    }
                }
                if (worksheet[`${letter}1`]) {
                    worksheet[`${letter}1`].s = style
                }
            }
            // questionary result header, start from E = 4, length = mmpi_questionaries.length
            for (let index = 4; index < (4 + mmpi_questionaries.length); index++) {
                const letter = letterFromNumber(index)
                // questionary number style
                const styleQN = {
                    alignment: {
                        horizontal: 'center',
                    },
                    font: {
                        bold: true
                    }
                }
                // questionary string style
                const styleQ = {
                    alignment: {
                        vertical: 'top',
                        wrapText: '1',
                    }
                }
                if (worksheet[`${letter}1`]) {
                    worksheet[`${letter}1`].s = styleQN
                }
                if (worksheet[`${letter}2`]) {
                    worksheet[`${letter}2`].s = styleQ
                }
            }
            // other result header, start from Y = (4 + mmpi_questionaries.length), length = (4 + mmpi_questionaries.length + 3) (total, answer empty, result)
            for (let index = (4 + brs_questionaries.length); index < (4 + brs_questionaries.length + 3); index++) {
                const letter = letterFromNumber(index)
                // participant detail style
                const style = {
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center'
                    }
                }
                if (worksheet[`${letter}1`]) {
                    worksheet[`${letter}1`].s = style
                }
            }
            // style base on value
            // result style, data start from, column (4 + mmpi_questionaries.length + 3), start row 3, length = personality_score.length
            personality_score.forEach((ps, i) => {
                //const ps_result: { yes: number, no: number } = JSON.parse(ps.mmpi_score.result)
                // get letter from number
                const letter = letterFromNumber((4 + brs_questionaries.length + 2))
                // result detail style
                const style = {
                    font: {
                        color: {
                            rgb: ps.brs_score.interpretation === "Resiliensi rendah"
                                ? "DC262600"
                                : ps.brs_score.interpretation === "Resiliensi tinggi"
                                    ? "15803D00"
                                    : "854D0E00"
                        }
                    }
                }
                if (worksheet[`${letter}${i + 3}`]) {
                    worksheet[`${letter}${i + 3}`].s = style
                }
            })
        }
        // create an XLSX file and try to save to .xlsx
        XLSX.writeFile(workbook, `${props.doc.assessment_title} - Personality Result Report.xlsx`)
    };

    return <div>
        <div className="fixed z-20 top-0 left-0 w-screen h-screen bg-black opacity-20"></div>
        <div className="fixed z-30 top-0 left-0 w-screen h-screen bg-transparent flex items-center justify-center">
            <div className="flex flex-col bg-white w-screen md:w-[90%] h-[90%] md:rounded">
                <div className="p-4 bg-blue-800 md:rounded-t text-white flex justify-between">
                    <h3>Personality Result Report</h3>
                    <XMarkIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent cursor-pointer" aria-hidden="true" onClick={() => props.handleClose()} />
                </div>
                <div className="flex p-2">
                    <button
                        type="button"
                        onClick={() => downloadExcel()}
                        className="ml-auto flex items-center bg-green-600 py-1 px-2 text-white rounded">
                        <PrinterIcon className="w-4" />
                        <span className="block pl-1">Print</span>
                    </button>
                </div>
                <div className="h-full p-2 pb-10">
                    <div className="h-[87%] overflow-auto">
                        <table className="w-full border-separate border-spacing-0">
                            <thead className="bg-slate-600 text-white sticky top-[0px] z-10">
                                <tr>
                                    <th className="py-1 px-2 min-w-[2.5rem] border sticky left-[0px] bg-slate-600 border-slate-500" rowSpan={2}>No</th>
                                    <th className="py-1 px-2 min-w-[12rem] text-left border sticky left-[40px] bg-slate-600 border-slate-500" rowSpan={2}>Participant</th>
                                    <th className="py-1 px-2 min-w-[4rem] text-center border sticky left-[232px] bg-slate-600 border-slate-500" rowSpan={2}>Age</th>
                                    <th className="py-1 px-2 min-w-[4.5rem] text-center border sticky left-[296px] bg-slate-600 border-slate-500" rowSpan={2}>Gender</th>
                                    {use_mbti &&
                                        <th className="py-1 px-2 text-center border border-slate-500" colSpan={paramMbti.length + 2}>MBTI</th>
                                    }
                                    {use_papi &&
                                        <th className="py-1 px-2 text-center border border-slate-500" colSpan={paramPapi.length + 3}>PAPI</th>
                                    }
                                    {use_epps &&
                                        <th className="py-1 px-2 text-center border border-slate-500" colSpan={paramEpps.length + 2}>EPPS</th>
                                    }
                                    {use_srq20 &&
                                        <th className="py-1 px-2 text-center border border-slate-500" colSpan={paramSrq20.length + srq20_questionaries.length + 2}>SRQ-20</th>
                                    }
                                    {use_mmpi &&
                                        <th className="py-1 px-2 text-center border border-slate-500" colSpan={3}>MMPI</th>
                                    }
                                    {use_brs &&
                                        <th className="py-1 px-2 text-center border border-slate-500" colSpan={brs_questionaries.length + 3}>BRS</th>
                                    }
                                </tr>
                                <tr>
                                    {use_mbti &&
                                        <>
                                            {paramMbti.map((p, i) => (
                                                <th key={i} className="py-1 px-2 min-w-[3.5rem] text-center border border-slate-500 capitalize">{p}</th>
                                            ))}
                                            <th className="py-1 px-2 w-20 border border-slate-500">Progress</th>
                                            <th className="py-1 px-2 w-20 border border-slate-500">Result</th>
                                        </>
                                    }
                                    {use_papi &&
                                        <>
                                            {paramPapi.map((p, i) => (
                                                <th key={i} className="py-1 px-2 border border-slate-500 capitalize">{p}</th>
                                            ))}
                                            <th className="py-1 px-2 border border-slate-500">Progress</th>
                                            <th className="py-1 px-2 border border-slate-500">PA</th>
                                            <th className="py-1 px-2 border border-slate-500">PI</th>
                                        </>
                                    }
                                    {use_epps &&
                                        <>
                                            {paramEpps.map((p, i) => (
                                                <th key={i} className="py-1 px-2 border border-slate-500 capitalize">{p}</th>
                                            ))}
                                            <th className="py-1 px-2 border border-slate-500">Progress</th>
                                            <th className="py-1 px-2 border border-slate-500">Consistency</th>
                                        </>
                                    }
                                    {use_srq20 &&
                                        <>
                                            {srq20_questionaries.map((q, i) => (
                                                <th key={i} className="py-1 px-2 border border-slate-500 capitalize">{q.number}, {q.question}</th>
                                            ))}
                                            {paramSrq20.map((p, i) => (
                                                <th key={i} className="py-1 px-2 border border-slate-500 capitalize">{p}</th>
                                            ))}
                                            <th className="py-1 px-2 border border-slate-500">Progress</th>
                                            <th className="py-1 px-2 border border-slate-500">Result</th>
                                        </>
                                    }
                                    {use_mmpi &&
                                        <>
                                            <th className="py-1 px-2 border border-slate-500">Progress</th>
                                            <th className="py-1 px-2 border border-slate-500">No Answer</th>
                                            <th className="py-1 px-2 border border-slate-500">Validity</th>
                                        </>
                                    }
                                    {use_brs &&
                                        <>
                                            {brs_questionaries.map((q, i) => (
                                                <th key={i} className="py-1 px-2 border border-slate-500 capitalize">{q.number}, {q.question}</th>
                                            ))}
                                            <th className="py-1 px-2 border border-slate-500">Progress</th>
                                            <th className="py-1 px-2 border border-slate-500">Score</th>
                                            <th className="py-1 px-2 border border-slate-500">Interpretasi</th>
                                        </>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {personality_score.map((ps, i) => {
                                    let papi_control = use_mbti ? calculatePapi(ps.papi_score.result) : { pa: 0, pi: 0 }
                                    let epps_consistency = use_epps ? calculateEppsConsistency(ps.epps_score.consistency) : 0
                                    return (
                                        <tr key={`p_score_${i}`}>
                                            <td className="py-1 px-2 border text-center sticky left-[0px] bg-white">{i + 1}</td>
                                            <td className="py-1 px-2 border sticky left-[40px] bg-white">{ps.participant.full_name}</td>
                                            <td className="py-1 px-2 border text-center sticky left-[232px] bg-white">{ps.age}</td>
                                            <td className="py-1 px-2 border text-center sticky left-[296px] bg-white">{ps.participant.gender}</td>
                                            {use_mbti &&
                                                <>
                                                    {paramMbti.map((p, i) => (
                                                        <td key={i} className="py-1 px-2 border text-center">
                                                            {JSON.parse(ps.mbti_score.result)?.[p]
                                                                ? (JSON.parse(ps.mbti_score.result)?.[p] / 15 * 100).toFixed(0) + "%"
                                                                : ""
                                                            }
                                                        </td>
                                                    ))}
                                                    <td className={`
                                                        py-1 px-2 border text-center font-semibold
                                                        ${(JSON.parse(ps.mbti_score.answer) ?? []).filter((a: any) => a.value !== "").length === 60 ? "bg-green-200" : "bg-red-200"}
                                                    `}>
                                                        {(JSON.parse(ps.mbti_score.answer) ?? []).filter((a: any) => a.value !== "").length}/60
                                                    </td>
                                                    <td className="py-1 px-2 border text-center font-semibold">
                                                        {JSON.parse(ps.mbti_score.result)?.i === JSON.parse(ps.mbti_score.result)?.e ? "-" : JSON.parse(ps.mbti_score.result)?.i > JSON.parse(ps.mbti_score.result)?.e ? "I" : "E"}
                                                        {JSON.parse(ps.mbti_score.result)?.s === JSON.parse(ps.mbti_score.result)?.n ? "-" : JSON.parse(ps.mbti_score.result)?.s > JSON.parse(ps.mbti_score.result)?.n ? "S" : "N"}
                                                        {JSON.parse(ps.mbti_score.result)?.t === JSON.parse(ps.mbti_score.result)?.f ? "-" : JSON.parse(ps.mbti_score.result)?.t > JSON.parse(ps.mbti_score.result)?.f ? "T" : "F"}
                                                        {JSON.parse(ps.mbti_score.result)?.j === JSON.parse(ps.mbti_score.result)?.p ? "-" : JSON.parse(ps.mbti_score.result)?.j > JSON.parse(ps.mbti_score.result)?.p ? "J" : "P"}
                                                    </td>
                                                </>
                                            }
                                            {use_papi &&
                                                <>
                                                    {paramPapi.map((p, i) => (
                                                        <td key={i} className="py-1 px-2 border text-center">{JSON.parse(ps.papi_score.result)?.[p]}</td>
                                                    ))}
                                                    <td className={`
                                                        py-1 px-2 border text-center font-semibold
                                                        ${(JSON.parse(ps.papi_score.answer) ?? []).filter((a: any) => a.value !== "").length === 90 ? "bg-green-200" : "bg-red-200"}
                                                    `}>
                                                        {(JSON.parse(ps.papi_score.answer) ?? []).filter((a: any) => a.value !== "").length}/90
                                                    </td>
                                                    <td className="py-1 px-2 border text-center font-semibold">{papi_control.pa}</td>
                                                    <td className="py-1 px-2 border text-center font-semibold">{papi_control.pi}</td>
                                                </>
                                            }
                                            {use_epps &&
                                                <>
                                                    {paramEpps.map((p, i) => {
                                                        const score = JSON.parse(ps.epps_score.result)?.[`${p}_SCORE`]
                                                        const strScore = score < 0
                                                            ? "-".repeat(Math.abs(score))
                                                            : score === 0 ? '0' : "+".repeat(score)
                                                        return (
                                                            <td key={i} className="py-1 px-2 border text-center">
                                                                {score !== null
                                                                    ? <>{strScore}</>
                                                                    : <></>
                                                                }
                                                            </td>
                                                        )
                                                    })}
                                                    <td className={`
                                                        py-1 px-2 border text-center font-semibold
                                                        ${(JSON.parse(ps.epps_score.answer) ?? []).filter((a: any) => a.value !== "").length === 210 ? "bg-green-200" : "bg-red-200"}
                                                    `}>
                                                        {(JSON.parse(ps.epps_score.answer) ?? []).filter((a: any) => a.value !== "").length}/210
                                                    </td>
                                                    <td className={`py-1 px-2 border text-center font-semibold`}>
                                                        {epps_consistency}/15
                                                    </td>
                                                </>
                                            }
                                            {use_srq20 &&
                                                <>
                                                    {srq20_questionaries.map((srq20_q, i) => {
                                                        const ps_answer: { number: number, answer: 'yes' | 'no' }[] = JSON.parse(ps.srq20_score.answer ?? '[]')
                                                        const answer = ps_answer.find(a => a.number === srq20_q.number)?.answer ?? '-'
                                                        return (
                                                            <td key={i} className="py-1 px-2 border text-center">
                                                                {answer}
                                                            </td>
                                                        )
                                                    })}
                                                    {paramSrq20.map((p, i) => {
                                                        const score = JSON.parse(ps.srq20_score.result)?.[p]
                                                        return (
                                                            <td key={i} className="py-1 px-2 border text-center">
                                                                {score}
                                                            </td>
                                                        )
                                                    })}
                                                    <td className={`
                                                        py-1 px-2 border text-center font-semibold
                                                        ${(JSON.parse(ps.srq20_score.answer) ?? []).filter((a: any) => a.answer !== "").length === 20 ? "bg-green-200" : "bg-red-200"}
                                                    `}>
                                                        {(JSON.parse(ps.srq20_score.answer) ?? []).filter((a: any) => a.answer !== "").length}/20
                                                    </td>
                                                    <td className="py-1 px-2 border text-center">
                                                        {(JSON.parse(ps.srq20_score.result)?.['yes'] ?? 20) >= 5
                                                            ? <div className="text-red-700 font-semibold">Need Check</div>
                                                            : <div className="text-green-700">Pass</div>
                                                        }
                                                    </td>
                                                </>
                                            }
                                            {use_mmpi &&
                                                <>
                                                    <td className={`
                                                        py-1 px-2 border text-center font-semibold
                                                        ${(JSON.parse(ps.mmpi_score.answer) ?? []).filter((a: any) => a.answer !== "").length === 567 ? "bg-green-200" : "bg-red-200"}
                                                    `}>
                                                        {(JSON.parse(ps.mmpi_score.answer) ?? []).filter((a: any) => a.answer !== "").length}/567
                                                    </td>
                                                    <td className="py-1 px-2 border text-center">
                                                        {(JSON.parse(ps.mmpi_score.answer) ?? []).filter((a: any) => a.answer === "").length}
                                                    </td>
                                                    <td className="py-1 px-2 border text-center">
                                                        {(JSON.parse(ps.mmpi_score.answer) ?? []).filter((a: any) => a.answer === "").length >= 30
                                                            ? <div className="text-red-600">Mungkin Tidak Valid</div>
                                                            : (JSON.parse(ps.mmpi_score.answer) ?? []).filter((a: any) => a.answer === "").length <= 10
                                                                ? <div className="text-green-700">Kemungkinan Valid</div>
                                                                : <div className="text-yellow-800">Validitas Dipertanyakan</div>}
                                                    </td>
                                                </>
                                            }
                                            {use_brs &&
                                                <>
                                                    {brs_questionaries.map((brs_q, i) => {
                                                        const ps_answer: { number: number, answer: string }[] = JSON.parse(ps.brs_score.answer ?? '[]')
                                                        const answer = ps_answer.find(a => a.number === brs_q.number)?.answer ?? '-'
                                                        return (
                                                            <td key={i} className="py-1 px-2 border text-center">
                                                                {answer}
                                                            </td>
                                                        )
                                                    })}
                                                    <td className={`
                                                        py-1 px-2 border text-center font-semibold
                                                        ${(JSON.parse(ps.brs_score.answer) ?? []).filter((a: any) => a.answer !== "").length === brs_questionaries.length ? "bg-green-200" : "bg-red-200"}
                                                    `}>
                                                        {(JSON.parse(ps.brs_score.answer) ?? []).filter((a: any) => a.answer !== "").length}/{brs_questionaries.length}
                                                    </td>
                                                    <td className="py-1 px-2 border text-center">
                                                        {ps.brs_score.score_result.toFixed(2)}
                                                    </td>
                                                    <td className="py-1 px-2 border text-center">
                                                        {ps.brs_score.interpretation}
                                                    </td>
                                                </>
                                            }
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div >
}

export default ModalPersonalityScore;