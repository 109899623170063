import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import Swal from "sweetalert2";

// Icons
import { ChevronDownIcon, ChevronUpIcon, PlusIcon, ArrowsUpDownIcon, ClipboardDocumentCheckIcon } from "@heroicons/react/24/outline";

// Contexts
import PSAuthContext from "../../../context/psikotest/AuthContext";
import WebSocketContext from "../../../context/WebSocketContext";
import LoaderContext from "../../../context/psikotest/LoaderContext";
import SnackbarContext from "../../../context/psikotest/SnackbarContext";

// Helpers
import errorHandler from "../../../helper/psikotest/errorHandler";

// Controller
import Assessment from "../../../controller/psikotest/psikotest_system/assessment";
import AssessmentCfitSubtest from "../../../controller/psikotest/psikotest_system/assessment_cfit_subtest";
import AssessmentIstSubtest from "../../../controller/psikotest/psikotest_system/assessment_ist_subtest";

// Modals
import ModalIntelligenceScore from "./modal/ModalIntelligenceScore";
import FormCfitSubtest from "./modal/FormCfitSubtest";
import ModalCfitSubtestSession from "./modal/ModalCfitSubtestSession";
import ModalCfitScore from "./modal/ModalCfitScore";
import FormIstSubtest from "./modal/FormIstSubtest";
import ModalIstSubtestSession from "./modal/ModalIstSubtestSession";
import ModalIstSubtestSessionScoring from "./modal/ModalIstSubtestSessionScoring";
import ModalIstScore from "./modal/ModalIstScore";

function ListIntelligence(props: { assessment_id: number; editable: boolean; doc: TPSAssessment; isPsikolog: boolean }) {
    // Contexts
    const { user } = useContext(PSAuthContext);
    const { setMessage } = useContext(LoaderContext);
    const { setNotif } = useContext(SnackbarContext);
    const { sendMessage } = useContext(WebSocketContext)
    // is admin
    const isAdmin = user.roles === "1";
    // is scoring
    const isScoring = props.doc.status === '2';
    // helper state

    // list assessment cfit & ist subtest
    const [assessment_cfit_subtest, setAssessmentCfitSubtest] = useState<TPSAssessmentCfitSubtest[]>([])
    const [assessment_ist_subtest, setAssessmentIstSubtest] = useState<TPSAssessmentIstSubtest[]>([])
    // state modal
    const [isOpenModalIntelligenceScore, setIsOpenModalIntelligenceScore] = useState<boolean>(false);
    const [isOpenFormCfitSubtest, setIsOpenFormCfitSubtest] = useState<boolean>(false);
    const [isOpenModalCfitSubtestSession, setIsOpenModalCfitSubtestSession] = useState<boolean>(false);
    const [isOpenModalCfitScore, setIsOpenModalCfitScore] = useState<boolean>(false);
    const [isOpenFormIstSubtest, setIsOpenFormIstSubtest] = useState<boolean>(false);
    const [isOpenModalIstSubtestSession, setIsOpenModalIstSubtestSession] = useState<boolean>(false);
    const [isOpenModalIstSubtestSessionScoring, setIsOpenModalIstSubtestSessionScoring] = useState<boolean>(false);
    const [isOpenModalIstScore, setIsOpenModalIstScore] = useState<boolean>(false);
    // state for modal
    const [selectedCfitSubtest, setSelectedCfitSubtest] = useState<TPSAssessmentCfitSubtest | undefined>(undefined);
    const [selectedCfitSubtestSession, setSelectedCfitSubtestSession] = useState<TPSAssessmentCfitSubtest | undefined>(undefined);
    const [selectedIstSubtest, setSelectedIstSubtest] = useState<TPSAssessmentIstSubtest | undefined>(undefined);
    const [selectedIstSubtestSession, setSelectedIstSubtestSession] = useState<TPSAssessmentIstSubtest | undefined>(undefined);
    const [selectedIstSubtestSessionScoring, setSelectedIstSubtestSessionScoring] = useState<TPSAssessmentIstSubtest | undefined>(undefined);

    // get initial data
    const getInitialData = useCallback(
        async function getInitialData() {
            try {
                const ca = new Assessment()
                // fetch assessment cfit subtest
                setMessage("Fetch CFIT Subtest")
                let res_cfit_subtest = await ca.cfit_subtest(props.assessment_id)
                const list_cfit_subtest: TPSAssessmentCfitSubtest[] = res_cfit_subtest.data.list
                setAssessmentCfitSubtest(list_cfit_subtest)
                // fetch assessment ist subtest
                setMessage("Fetch IST Subtest")
                const res_ist_subtest = await ca.ist_subtest(props.assessment_id)
                const list_ist_subtest: TPSAssessmentIstSubtest[] = res_ist_subtest.data.list
                setAssessmentIstSubtest(list_ist_subtest)
                setMessage("");
            } catch (error) {
                setMessage("");
                const errorMessage = errorHandler(error);
                setNotif({ type: "error", message: errorMessage });
            }
        }, [setNotif, setMessage, props.assessment_id]
    );

    // use effect initial data
    useEffect(() => {
        getInitialData();
    }, [getInitialData]);

    // sortIndexAssessmentCfitSubtest
    const sortIndexAssessmentCfitSubtest = useCallback(
        async function sortIndexAssessmentCfitSubtest() {
            // check subtest length
            const lengthCfitSubtest = assessment_cfit_subtest.length
            let update = 0;
            for (let index = 0; index < lengthCfitSubtest; index++) {
                const element = assessment_cfit_subtest[index];
                // check if index is not sequentially
                if (element.index_list !== index) {
                    // update
                    const cacs = new AssessmentCfitSubtest()
                    await cacs.update({ ...element, index_list: index })
                    update++
                }
            }
            if (update > 0) {
                // re-fetch data
                getInitialData();
            }
        },
        [assessment_cfit_subtest, getInitialData]
    )

    // always run when any change on assessment_cfit_subtest
    // to sort index list
    useEffect(() => {
        // re-sort index
        sortIndexAssessmentCfitSubtest();
    }, [assessment_cfit_subtest, sortIndexAssessmentCfitSubtest])

    // when open form cfit subtest when cfit subtest selected
    useEffect(() => {
        setIsOpenFormCfitSubtest(selectedCfitSubtest !== undefined)
    }, [selectedCfitSubtest]);

    // handleCloseCfitSubtest
    const handleCloseCfitSubtest = () => {
        // when any selected cfit
        if (selectedCfitSubtest) {
            setSelectedCfitSubtest(undefined)
        } else {
            setIsOpenFormCfitSubtest(false)
        }
        // re-fetch data
        getInitialData()
    }

    // handleToggleStatusCfitSubtest
    const handleToggleStatusCfitSubtest = async (data: TPSAssessmentCfitSubtest) => {
        try {
            // fetch assessment cfit subtest
            setMessage("Change Status Assessment CFIT Subtest")
            const cacs = new AssessmentCfitSubtest()
            const res = await cacs.update({ ...data, status: data.status === "0" ? "1" : "0" })
            setNotif({ type: "success", message: res.data.message });
            setMessage("");
            // re-fetch data
            getInitialData();
            // broadcast message to all client (target: participant)
            sendMessage("psikotest", JSON.stringify({
                target: "participant",
                action: "check open session",
                message: (data.status === "0" ? "1" : "0") === "1" ? "Assessment open by PIC" : "Assessment close by PIC"
            }))
        } catch (error) {
            setMessage("");
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }

    // handleDeleteCfitSubtest
    const handleDeleteCfitSubtest = async (data: TPSAssessmentCfitSubtest) => {
        try {
            const confirm = await Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            });
            if (confirm.isConfirmed) {
                const cacs = new AssessmentCfitSubtest();
                const res = await cacs.delete(data);
                setNotif({ type: "success", message: res.data.message });
                // re-fetch data
                getInitialData();
            }
        } catch (error) {
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }

    // handleMoveCfitSubtest
    const handleMoveCfitSubtest = async (data: TPSAssessmentCfitSubtest, type: "up" | "down") => {
        try {
            setMessage("Change Position Assessment CFIT Subtest")
            const cacs = new AssessmentCfitSubtest()
            // get switched index
            const switched_index = type === "up" ? data.index_list - 1 : data.index_list + 1;
            // get switched data
            const switched_data = assessment_cfit_subtest.find(v => v.index_list === switched_index)
            if (switched_data) {
                // update switched index
                let res = await cacs.update({ ...switched_data, index_list: data.index_list })
                // update data want to swap
                res = await cacs.update({ ...data, index_list: switched_index })
                setNotif({ type: "success", message: res.data.message });
            }
            setMessage("");
            // re-fetch data
            getInitialData();
        } catch (error) {
            setMessage("");
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }

    // sortIndexAssessmentIstSubtest
    const sortIndexAssessmentIstSubtest = useCallback(
        async function sortIndexAssessmentIstSubtest() {
            // check subtest length
            const lengthIstSubtest = assessment_ist_subtest.length
            let update = 0;
            for (let index = 0; index < lengthIstSubtest; index++) {
                const element = assessment_ist_subtest[index];
                // check if index is not sequentially
                if (element.index_list !== index) {
                    // update
                    const aisc = new AssessmentIstSubtest()
                    await aisc.update({ ...element, index_list: index })
                    update++
                }
            }
            if (update > 0) {
                // re-fetch data
                getInitialData();
            }
        },
        [assessment_ist_subtest, getInitialData]
    )

    // when open form cfit subtest session when cfit subtest session selected
    useEffect(() => {
        setIsOpenModalCfitSubtestSession(selectedCfitSubtestSession !== undefined)
    }, [selectedCfitSubtestSession]);

    // handleCloseCfitSubtestSession
    const handleCloseCfitSubtestSession = () => {
        // when any selected cfit
        if (selectedCfitSubtestSession) {
            setSelectedCfitSubtestSession(undefined)
        } else {
            setIsOpenModalCfitSubtestSession(false)
        }
    }

    // always run when any change on assessment_ist_subtest
    // to sort index list
    useEffect(() => {
        // re-sort index
        sortIndexAssessmentIstSubtest();
    }, [assessment_ist_subtest, sortIndexAssessmentIstSubtest])

    // when open form ist subtest when ist subtest selected
    useEffect(() => {
        setIsOpenFormIstSubtest(selectedIstSubtest !== undefined)
    }, [selectedIstSubtest]);

    // handleCloseIstSubtest
    const handleCloseIstSubtest = () => {
        // when any selected ist
        if (selectedIstSubtest) {
            setSelectedIstSubtest(undefined)
        } else {
            setIsOpenFormIstSubtest(false)
        }
        // re-fetch data
        getInitialData()
    }

    // handleToggleStatusIstSubtest
    const handleToggleStatusIstSubtest = async (data: TPSAssessmentIstSubtest) => {
        try {
            // fetch assessment cfit subtest
            setMessage("Change Status Assessment IST Subtest")
            const aisc = new AssessmentIstSubtest()
            const res = await aisc.update({ ...data, status: data.status === "0" ? "1" : "0" })
            setNotif({ type: "success", message: res.data.message });
            setMessage("");
            // re-fetch data
            getInitialData();
            // broadcast message to all client (target: participant)
            sendMessage("psikotest", JSON.stringify({
                target: "participant",
                action: "check open session",
                message: (data.status === "0" ? "1" : "0") === "1" ? "Assessment open by PIC" : "Assessment close by PIC"
            }))
        } catch (error) {
            setMessage("");
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }

    // handleDeleteIstSubtest
    const handleDeleteIstSubtest = async (data: TPSAssessmentIstSubtest) => {
        try {
            const confirm = await Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            });
            if (confirm.isConfirmed) {
                const aisc = new AssessmentIstSubtest();
                const res = await aisc.delete(data);
                setNotif({ type: "success", message: res.data.message });
                // re-fetch data
                getInitialData();
            }
        } catch (error) {
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }

    // handleMoveIstSubtest
    const handleMoveIstSubtest = async (data: TPSAssessmentIstSubtest, type: "up" | "down") => {
        try {
            setMessage("Change Position Assessment IST Subtest")
            const aisc = new AssessmentIstSubtest()
            // get switched index
            const switched_index = type === "up" ? data.index_list - 1 : data.index_list + 1;
            // get switched data
            const switched_data = assessment_ist_subtest.find(v => v.index_list === switched_index)
            if (switched_data) {
                // update switched index
                let res = await aisc.update({ ...switched_data, index_list: data.index_list })
                // update data want to swap
                res = await aisc.update({ ...data, index_list: switched_index })
                setNotif({ type: "success", message: res.data.message });
            }
            setMessage("");
            // re-fetch data
            getInitialData();
        } catch (error) {
            setMessage("");
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }

    // when open form ist subtest session when ist subtest session selected
    useEffect(() => {
        setIsOpenModalIstSubtestSession(selectedIstSubtestSession !== undefined)
    }, [selectedIstSubtestSession]);

    // handleCloseIstubtestSession
    const handleCloseIstSubtestSession = () => {
        // when any selected ist
        if (selectedIstSubtestSession) {
            setSelectedIstSubtestSession(undefined)
        } else {
            setIsOpenModalIstSubtestSession(false)
        }
    }

    // when open form ist subtest session when ist subtest session scoring selected
    useEffect(() => {
        setIsOpenModalIstSubtestSessionScoring(selectedIstSubtestSessionScoring !== undefined)
    }, [selectedIstSubtestSessionScoring]);

    // handleCloseIstubtestSession
    const handleCloseIstSubtestSessionScoring = () => {
        // when any selected ist
        if (selectedIstSubtestSessionScoring) {
            setSelectedIstSubtestSessionScoring(undefined)
        } else {
            setIsOpenModalIstSubtestSessionScoring(false)
        }
    }

    return (
        <>
            {/* Questionary Form */}
            <Transition
                as={Fragment}
                show={isOpenFormCfitSubtest}
                enter="ease-out duration-50"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-50"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div>
                    <FormCfitSubtest
                        data={selectedCfitSubtest}
                        assessment_id={props.assessment_id}
                        handleClose={() => handleCloseCfitSubtest()}
                        assessment_cfit_subtest={assessment_cfit_subtest}
                    />
                </div>
            </Transition>
            <Transition
                as={Fragment}
                show={isOpenFormIstSubtest}
                enter="ease-out duration-50"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-50"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div>
                    <FormIstSubtest
                        data={selectedIstSubtest}
                        assessment_id={props.assessment_id}
                        handleClose={() => handleCloseIstSubtest()}
                        assessment_ist_subtest={assessment_ist_subtest}
                    />
                </div>
            </Transition>
            {/* Session Modal Session */}
            <Transition
                as={Fragment}
                show={isOpenModalCfitSubtestSession}
                enter="ease-out duration-50"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-50"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div>
                    <ModalCfitSubtestSession
                        data={selectedCfitSubtestSession}
                        assessment_id={props.assessment_id}
                        handleClose={() => handleCloseCfitSubtestSession()}
                        assessment_cfit_subtest={assessment_cfit_subtest}
                    />
                </div>
            </Transition>
            <Transition
                as={Fragment}
                show={isOpenModalIstSubtestSession}
                enter="ease-out duration-50"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-50"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div>
                    <ModalIstSubtestSession
                        data={selectedIstSubtestSession}
                        assessment_id={props.assessment_id}
                        handleClose={() => handleCloseIstSubtestSession()}
                        assessment_ist_subtest={assessment_ist_subtest}
                    />
                </div>
            </Transition>
            {/* Session Modal Scoring */}
            <Transition
                as={Fragment}
                show={isOpenModalIstSubtestSessionScoring}
                enter="ease-out duration-50"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-50"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div>
                    <ModalIstSubtestSessionScoring
                        data={selectedIstSubtestSessionScoring}
                        assessment_id={props.assessment_id}
                        handleClose={() => handleCloseIstSubtestSessionScoring()}
                        assessment_ist_subtest={assessment_ist_subtest}
                        isScoring={isScoring}
                    />
                </div>
            </Transition>
            {/* Session Modal Score */}
            <Transition
                as={Fragment}
                show={isOpenModalIntelligenceScore}
                enter="ease-out duration-50"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-50"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div>
                    <ModalIntelligenceScore
                        doc={props.doc}
                        assessment_id={props.assessment_id}
                        handleClose={() => setIsOpenModalIntelligenceScore(false)}
                    />
                </div>
            </Transition>
            <Transition
                as={Fragment}
                show={isOpenModalCfitScore}
                enter="ease-out duration-50"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-50"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div>
                    <ModalCfitScore
                        doc={props.doc}
                        assessment_id={props.assessment_id}
                        handleClose={() => setIsOpenModalCfitScore(false)}
                    />
                </div>
            </Transition>
            <Transition
                as={Fragment}
                show={isOpenModalIstScore}
                enter="ease-out duration-50"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-50"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div>
                    <ModalIstScore
                        doc={props.doc}
                        assessment_id={props.assessment_id}
                        handleClose={() => setIsOpenModalIstScore(false)}
                    />
                </div>
            </Transition>
            <div className="border-2 p-3 border-dashed border-t-0 border-blue-900 rounded-b">
                <div className="flex mb-4">
                    <button
                        type="button"
                        onClick={() => { setIsOpenModalIntelligenceScore(true) }}
                        className="flex items-center bg-blue-600 py-1 px-2 text-white rounded">
                        <ClipboardDocumentCheckIcon className="w-4" />
                        <span className="block pl-1">Compiled Score</span>
                    </button>
                </div>
                {/** CFIT (Culture Fair Intelligence Test) */}
                <h5 className="font-bold flex pb-2">CFIT (Culture Fair Intelligence Test)</h5>
                <div className="flex">
                    {props.editable &&
                        <button
                            type="button"
                            onClick={() => { setIsOpenFormCfitSubtest(true) }}
                            className="flex items-center bg-green-600 py-1 px-2 text-white rounded">
                            <PlusIcon className="w-4" />
                            <span className="block pl-1">Add Subtest</span>
                        </button>
                    }
                    <button
                        type="button"
                        onClick={() => { setIsOpenModalCfitScore(true) }}
                        className="ml-auto flex items-center bg-blue-600 py-1 px-2 text-white rounded">
                        <ClipboardDocumentCheckIcon className="w-4" />
                        <span className="block pl-1">Score</span>
                    </button>
                </div>
                <div className="pt-2 overflow-auto">
                    <table className="w-full border">
                        <thead className="bg-slate-600 text-white">
                            <tr>
                                <th className="py-1 px-2 w-8 border border-slate-500">No</th>
                                <th className="py-1 px-2 text-left border border-slate-500">Subtest</th>
                                {!props.isPsikolog &&
                                    <>
                                        <th className="py-1 px-2 w-20 border border-slate-500">Status</th>
                                        <th className="py-1 px-2 w-20 border border-slate-500">Session</th>
                                    </>
                                }
                                {/** Admin Only - Button Edit & Delete & Move Position */}
                                {isAdmin &&
                                    <>
                                        <th className="py-1 px-2 w-20 border border-slate-500">Edit</th>
                                        <th className="py-1 px-2 w-20 border border-slate-500">Delete</th>
                                        <th className="py-1 px-2 w-10 border border-slate-500">
                                            <div className="flex justify-center"><ArrowsUpDownIcon className="w-4" /></div>
                                        </th>
                                    </>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {assessment_cfit_subtest.map((v, i) => (
                                <tr key={`cfit_subtest_${i}`}>
                                    <td className="py-1 px-2 text-center border">{v.index_list + 1}</td>
                                    <td className="py-1 px-2 text-left border">{v.cfit?.test_name}</td>
                                    {!props.isPsikolog &&
                                        <>
                                            <td className="py-1 px-2 text-center border">
                                                <button
                                                    type="button"
                                                    className={`px-2 rounded text-white ${v.status === "0" ? "bg-gray-500" : "bg-green-500"}`}
                                                    onClick={() => handleToggleStatusCfitSubtest(v)}>
                                                    {v.status === "0" ? "Close" : "Open"}
                                                </button>
                                            </td>
                                            <td className="py-1 px-2 text-center border">
                                                <button
                                                    type="button"
                                                    className="px-2 rounded bg-green-500 text-white"
                                                    onClick={() => setSelectedCfitSubtestSession(v)}>
                                                    Session
                                                </button>
                                            </td>
                                        </>
                                    }
                                    {/** Admin Only - Button Edit & Delete & Move Position */}
                                    {isAdmin &&
                                        <>
                                            <td className="py-1 px-2 text-center border">
                                                <button
                                                    type="button"
                                                    className="px-2 rounded bg-blue-500 text-white"
                                                    onClick={() => setSelectedCfitSubtest(v)}>
                                                    Edit
                                                </button>
                                            </td>
                                            <td className="py-1 px-2 text-center border">
                                                <button
                                                    type="button"
                                                    className="px-2 rounded bg-red-500 text-white"
                                                    onClick={() => handleDeleteCfitSubtest(v)}>
                                                    Delete
                                                </button>
                                            </td>
                                            <td className="py-1 px-2 text-center border">
                                                <div className="flex flex-col space-y-1">
                                                    {v.index_list > 0 &&
                                                        <button
                                                            type="button"
                                                            className="px-2 rounded bg-white-500 hover:bg-slate-100 border flex justify-center"
                                                            onClick={() => handleMoveCfitSubtest(v, "up")}>
                                                            <ChevronUpIcon className="w-3" />
                                                        </button>
                                                    }
                                                    {v.index_list !== (assessment_cfit_subtest.length - 1) &&
                                                        <button
                                                            type="button"
                                                            className="px-2 rounded bg-white-500 hover:bg-slate-100 border justify-cente"
                                                            onClick={() => handleMoveCfitSubtest(v, "down")}>
                                                            <ChevronDownIcon className="w-3" />
                                                        </button>
                                                    }
                                                </div>
                                            </td>
                                        </>
                                    }
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {/** IST (Intelligenz Struktur Test) */}
                <h5 className="font-bold flex pt-4 pb-2">IST (Intelligenz Struktur Test)</h5>
                <div className="flex">
                    {props.editable &&
                        <div className="flex space-x-1">
                            <button
                                type="button"
                                onClick={() => { setIsOpenFormIstSubtest(true) }}
                                className="flex items-center bg-green-600 py-1 px-2 text-white rounded">
                                <PlusIcon className="w-4" />
                                <span className="block pl-1">Add Subtest</span>
                            </button>
                        </div>
                    }
                    <button
                        type="button"
                        onClick={() => { setIsOpenModalIstScore(true) }}
                        className="ml-auto flex items-center bg-blue-600 py-1 px-2 text-white rounded">
                        <ClipboardDocumentCheckIcon className="w-4" />
                        <span className="block pl-1">Score</span>
                    </button>
                </div>
                <div className="pt-2 overflow-auto">
                    <table className="w-full border">
                        <thead className="bg-slate-600 text-white">
                            <tr>
                                <th className="py-1 px-2 w-8 border border-slate-500">No</th>
                                <th className="py-1 px-2 text-left border border-slate-500">Subtest</th>
                                {!props.isPsikolog &&
                                    <>
                                        <th className="py-1 px-2 w-20 border border-slate-500">Status</th>
                                        <th className="py-1 px-2 w-20 border border-slate-500">Session</th>
                                        <th className="py-1 px-2 w-20 border border-slate-500">Scoring</th>
                                    </>
                                }
                                {/** Admin Only - Button Edit & Delete & Move Position */}
                                {isAdmin &&
                                    <>
                                        <th className="py-1 px-2 w-20 border border-slate-500">Edit</th>
                                        <th className="py-1 px-2 w-20 border border-slate-500">Delete</th>
                                        <th className="py-1 px-2 w-10 border border-slate-500">
                                            <div className="flex justify-center"><ArrowsUpDownIcon className="w-4" /></div>
                                        </th>
                                    </>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {assessment_ist_subtest.map((v, i) => (
                                <tr key={`ist_subtest_${i}`}>
                                    <td className="py-1 px-2 text-center border">{v.index_list + 1}</td>
                                    <td className="py-1 px-2 text-left border">{v.ist?.subtest_name}</td>
                                    {!props.isPsikolog &&
                                        <>
                                            <td className="py-1 px-2 text-center border">
                                                <button
                                                    type="button"
                                                    className={`px-2 rounded text-white ${v.status === "0" ? "bg-gray-500" : "bg-green-500"}`}
                                                    onClick={() => handleToggleStatusIstSubtest(v)}>
                                                    {v.status === "0" ? "Close" : "Open"}
                                                </button>
                                            </td>
                                            <td className="py-1 px-2 text-center border">
                                                <button
                                                    type="button"
                                                    className="px-2 rounded bg-green-500 text-white"
                                                    onClick={() => setSelectedIstSubtestSession(v)}>
                                                    Session
                                                </button>
                                            </td>
                                            <td className="py-1 px-2 text-center border">
                                                <button
                                                    type="button"
                                                    className="px-2 rounded bg-yellow-500 text-white"
                                                    onClick={() => setSelectedIstSubtestSessionScoring(v)}>
                                                    Scoring
                                                </button>
                                            </td>
                                        </>
                                    }
                                    {/** Admin Only - Button Edit & Delete & Move Position */}
                                    {isAdmin &&
                                        <>
                                            <td className="py-1 px-2 text-center border">
                                                <button
                                                    type="button"
                                                    className="px-2 rounded bg-blue-500 text-white"
                                                    onClick={() => setSelectedIstSubtest(v)}>
                                                    Edit
                                                </button>
                                            </td>
                                            <td className="py-1 px-2 text-center border">
                                                <button
                                                    type="button"
                                                    className="px-2 rounded bg-red-500 text-white"
                                                    onClick={() => handleDeleteIstSubtest(v)}>
                                                    Delete
                                                </button>
                                            </td>
                                            <td className="py-1 px-2 text-center border">
                                                <div className="flex flex-col space-y-1">
                                                    {v.index_list > 0 &&
                                                        <button
                                                            type="button"
                                                            className="px-2 rounded bg-white-500 hover:bg-slate-100 border flex justify-center"
                                                            onClick={() => handleMoveIstSubtest(v, "up")}>
                                                            <ChevronUpIcon className="w-3" />
                                                        </button>
                                                    }
                                                    {v.index_list !== (assessment_ist_subtest.length - 1) &&
                                                        <button
                                                            type="button"
                                                            className="px-2 rounded bg-white-500 hover:bg-slate-100 border justify-cente"
                                                            onClick={() => handleMoveIstSubtest(v, "down")}>
                                                            <ChevronDownIcon className="w-3" />
                                                        </button>
                                                    }
                                                </div>
                                            </td>
                                        </>
                                    }
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default ListIntelligence;