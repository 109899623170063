import { useCallback, useContext, useEffect, useState } from "react";

// Context
import SnackbarContext from "../../../context/psikotest/SnackbarContext";

// Handler
import errorHandler from "../../../helper/psikotest/errorHandler";

// Controller
import ParticipantSession from "../../../controller/psikotest/psikotest_participant/session";
import { CheckCircleIcon, ClockIcon } from "@heroicons/react/24/outline";

function SubtestMbti(props: { assessment_participant_session: TPPAssessmentParticipantSession, closeSession: () => void }) {
    // Context
    const { setNotif } = useContext(SnackbarContext);

    // state
    const [mbti_questionary, setMbtiQuestionary] = useState<TPSMbtiQuestionary[]>([])
    const [mbti_questionary_answer, setMbtiQuestionaryAnswer] = useState<TPSAssessmentParticipantMbtiSessionAnswer>()
    const [answer_questionary, setAnswerQuestionary] = useState<{ [key: string]: string }[]>([])

    // get doc
    const getDoc = useCallback(async () => {
        try {
            // get all question in subtest id
            const psc = new ParticipantSession();
            let resQuestionaryMbti = await psc.getQuestionaryMbti();
            setMbtiQuestionary(resQuestionaryMbti.data.list)
            let resQuestionaryAnswerMbti = await psc.getQuestionaryAnswerMbti(props.assessment_participant_session.id)
            setMbtiQuestionaryAnswer(resQuestionaryAnswerMbti.data.row)
            // set answer questionary
            if ((JSON.parse(resQuestionaryAnswerMbti.data.row.answer)).length === 0) {
                // create empty object with same length of mbti questionary
                setAnswerQuestionary(resQuestionaryMbti.data.list.map((_: any) => { return { answer: "", value: "" } }))
            } else {
                setAnswerQuestionary(JSON.parse(resQuestionaryAnswerMbti.data.row.answer))
            }
        } catch (error) {
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }, [setNotif, props.assessment_participant_session.id]);

    useEffect(() => {
        getDoc();
    }, [getDoc]);

    // track mbti_questionary
    useEffect(() => {
        //console.log(mbti_questionary)
    }, [mbti_questionary]);

    // track answer_questionary
    useEffect(() => {
        //console.log(answer_questionary)
    }, [answer_questionary]);

    // handleAnswer
    const handleAnswer = async (index: number, answer: string, point: string) => {
        try {
            //console.log(index, answer, point)
            // set answer
            // copy answer
            const c_answer = [...answer_questionary]
            c_answer.splice(index, 1, { answer, value: point })
            // set result point
            const c_result_point = {
                i: c_answer.filter(c => c.value === "(I) Introvert").length,
                e: c_answer.filter(c => c.value === "(E) Extrovert").length,
                s: c_answer.filter(c => c.value === "(S) Sensing").length,
                n: c_answer.filter(c => c.value === "(N) Intuition").length,
                t: c_answer.filter(c => c.value === "(T) Thinking").length,
                f: c_answer.filter(c => c.value === "(F) Feeling").length,
                j: c_answer.filter(c => c.value === "(J) Judging").length,
                p: c_answer.filter(c => c.value === "(P) Perceiving").length
            }
            // save answer
            if (mbti_questionary_answer) {
                const psc = new ParticipantSession();
                await psc.saveQuestionaryAnswerMbti(props.assessment_participant_session.id, {
                    ...mbti_questionary_answer,
                    answer: JSON.stringify(c_answer),
                    result: JSON.stringify(c_result_point)
                });
                setAnswerQuestionary(c_answer)
            }
        } catch (error) {
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }

    return (
        <div className="h-[94%] overflow-auto">
            <div className="flex flex-col items-center">
                <div className="text-sm md:text-base max-w-5xl text-center py-3">
                    Di bawah ini ada 60 nomor. Masing-masing nomor memiliki dua pernyataan yang bertolak belakang <b>(PERNYATAAN A & B)</b>. <br />
                    Pilihlah salah satu pernyataan yang paling sesuai dengan diri anda dengan <b>mengklik</b> pada pilihan anda.<br />
                    Anda <b>HARUS</b> memilih salah satu yang dominan serta mengisi semua nomor.
                </div>
            </div>
            <div className="text-sm md:text-base border-b flex flex-col items-center pb-20">
                <div className="max-w-5xl">
                    <div className="">
                        <div className="flex w-full bg-slate-800 text-white font-semibold">
                            <div className="w-1/12 text-center flex justify-center items-center border p-1">No</div>
                            <div className={`w-5/12 text-center flex justify-center items-center border p-1 cursor-pointer`}>
                                Pernyataan A
                            </div>
                            <div className={`w-5/12 text-center flex justify-center items-center border p-1 cursor-pointer`}>
                                Pernyataan B
                            </div>
                            <div className="w-1/12 text-center flex justify-center items-center border p-1">
                                <CheckCircleIcon className="w-5 text-white" />
                            </div>
                        </div>
                    </div>
                    {mbti_questionary.map((mbti_q, index) => (
                        <div key={index} className="">
                            <div className="flex w-full">
                                <div className="w-1/12 text-center flex justify-center items-center border p-1">{mbti_q.number}</div>
                                <div
                                    className={`
                                        w-5/12 text-center flex justify-center items-center border p-1 cursor-pointer hover:bg-blue-200 hover:font-semibold hover:text-black
                                        ${answer_questionary?.[index]?.answer === "A" ? "bg-green-200" : ""}
                                        ${answer_questionary?.[index]?.answer !== "A" && answer_questionary?.[index]?.answer === "B" ? "bg-slate-100 text-gray-400" : ""}
                                    `}
                                    onClick={() => handleAnswer(index, "A", mbti_q.statement_a_value)}>
                                    {mbti_q.statement_a}
                                </div>
                                <div
                                    className={`
                                        w-5/12 text-center flex justify-center items-center border p-1 cursor-pointer hover:bg-blue-200 hover:font-semibold hover:text-black
                                        ${answer_questionary?.[index]?.answer === "B" ? "bg-green-200" : ""}
                                        ${answer_questionary?.[index]?.answer !== "B" && answer_questionary?.[index]?.answer === "A" ? "bg-slate-100 text-gray-400" : ""}
                                    `}
                                    onClick={() => handleAnswer(index, "B", mbti_q.statement_b_value)}>
                                    {mbti_q.statement_b}
                                </div>
                                <div className={`w-1/12 text-center flex justify-center items-center border p-1 ${answer_questionary?.[index]?.answer !== "" ? "bg-green-100" : ""}`}>
                                    {answer_questionary?.[index]?.answer !== ""
                                        ? <CheckCircleIcon className="w-5 text-green-600" />
                                        : <ClockIcon className="w-5 text-gray-400" />
                                    }
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="max-w-5xl w-full mt-3 flex justify-between items-center">
                    <div className="pl-2 md:pl-6 font-semibold">
                        {answer_questionary.filter(aq => aq.answer !== "").length}/{mbti_questionary.length}
                    </div>
                    {answer_questionary.filter(aq => aq.answer !== "").length !== mbti_questionary.length &&
                        <div className="px-3 text-red-500">Tombol <b>Finish</b> tersedia saat semua pernyataan telah dipilih</div>
                    }
                    <button
                        type="button"
                        className={`mx-3 md:mx-0 py-1 px-6 min-w-[80px] text-center cursor-pointer rounded text-white bg-red-500 hover:bg-red-600 disabled:bg-gray-300`}
                        onClick={() => props.closeSession()}
                        disabled={answer_questionary.filter(aq => aq.answer !== "").length !== mbti_questionary.length}>
                        Finish
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SubtestMbti;
