import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState, useContext, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Disclosure, Transition } from "@headlessui/react";

// Icons
import { ChevronUpIcon, ArrowDownTrayIcon, TrashIcon } from "@heroicons/react/24/outline";

// Contexts
import LoaderContext from "../../../context/assessment/LoaderContext";
import SnackbarContext from "../../../context/assessment/SnackbarContext";

// Helpers
import errorHandler from "../../../helper/assessment/errorHandler";
import logRender from "../../../helper/assessment/logRender";

// Controllers
import Category from "../../../controller/assessment/assessment_system/category";

// Components
import BackButton from "../../../components/assessment/assessment_system/BackButton";
import LevelAssessment from "../../../controller/assessment/assessment_system/level_assessment";
import FormCategory from "./FormCategory";

// validation schema
const schema = yup.object().shape({
  level: yup.string().label("Level").required(),
});

function Form() {
  // call message setter
  const { setMessage } = useContext(LoaderContext);
  // call notif setter
  const { setNotif } = useContext(SnackbarContext);
  // call navigate action
  const navigate = useNavigate();
  // parse param id
  const location = useLocation();
  // remove trailing slash in production when reload/refresh
  const arrPath = location.pathname.replace(/\/$/, "").split("/");
  // get last string in the end array path
  const paramId = arrPath[arrPath.length - 1];
  // state document
  const [doc, setDoc] = useState<TLevelAssessment>({
    id: 0,
    level: "",
  });
  // is create
  const isCreate = paramId === "create";
  // state required value
  const [categoryOptions, setCategoryOptions] = useState<TSelect[]>([]);
  // init useForm
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<TLevelAssessment>({ resolver: yupResolver(schema), mode: "onBlur" });

  useEffect(() => {
    logRender({ type: "page", name: "level_assessment/Form" });
  }, []);

  const getDoc = useCallback(
    async function getDoc() {
      try {
        // show message and loader
        setMessage("Fetch Category");
        // create object constructor Category controller
        const c = new Category();
        const resCategory = await c.list();
        const toSelectCategory: TSelect[] = resCategory.data.list.map((v: TCategory) => {
          return { id: v.id, label: v.category };
        });
        setCategoryOptions(toSelectCategory);
        // if not create form
        if (!isCreate) {
          setMessage("Fetch Level Assessment");
          const la = new LevelAssessment();
          const res = await la.get(paramId);
          const row: TLevelAssessmentObject = res.data.row;
          setDoc(row);
          setValue("id", row.id);
          setValue("level", row.level);
        }
        setMessage("");
      } catch (error) {
        setMessage("");
        const errorMessage = errorHandler(error);
        setNotif({ type: "error", message: errorMessage });
        navigate(`../`, { replace: true });
      }
    },
    [setValue, paramId, isCreate, setNotif, setMessage, navigate]
  );

  useEffect(() => {
    getDoc();
  }, [getDoc]);

  const onSubmit: SubmitHandler<TLevelAssessment> = async (data) => {
    try {
      setMessage("Save Level Assessment");
      const la = new LevelAssessment();
      let res;
      if (isCreate) {
        data.id = 0;
        res = await la.create(data);
      } else {
        res = await la.update(data.id.toString(), data);
      }
      setNotif({ type: "success", message: res.data.message });
      setMessage("");
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  const confirmDelete = async (level_assessment: TLevelAssessment) => {
    try {
      const confirm = await Swal.fire({
        title: "Are you sure?",
        text: "Level assessment will be removed, You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (confirm.isConfirmed) {
        const la = new LevelAssessment();
        await la.delete(level_assessment.id.toString());
        setNotif({ type: "success", message: "level assessment deleted" });
        navigate(`../`, { replace: true });
      }
    } catch (error) {
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  return (
    <div className="w-full flex flex-col space-y-0.5">
      <div className="flex">
        <BackButton />
        <button
          onClick={handleSubmit(onSubmit)}
          className="mx-2 block w-auto px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded font-semibold text-sm"
        >
          <div className="flex">
            <ArrowDownTrayIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
            Save
          </div>
        </button>
        {!isCreate && (
          <button
            onClick={() => confirmDelete(doc)}
            className="ml-auto block w-auto px-4 py-2 bg-red-500 hover:bg-red-700 text-white rounded font-semibold text-sm"
          >
            <div className="flex">
              <TrashIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
              Delete
            </div>
          </button>
        )}
      </div>
      <div className="pt-4">
        <form onBlur={() => handleSubmit(onSubmit)()} className="w-full">
          <div className="flex flex-wrap -mx-3">
            <div className="w-full md:w-full px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Level</label>
              <input
                className={
                  (errors?.level ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                  " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                }
                {...register("level")}
              />
              {errors.level && <p className="text-red-500 text-xs italic">{errors.level.message}</p>}
            </div>
          </div>
        </form>
        {/* Session */}
        {!isCreate && (
          <Disclosure defaultOpen={false} as="div" className="pb-1">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between w-full px-4 py-4 text-sm font-medium text-left text-gray-100 bg-gray-900 rounded hover:bg-gray-800 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                  <span>Category</span>
                  <ChevronUpIcon className={`${open ? "transform rotate-180" : ""} w-5 h-5 text-gray-100`} />
                </Disclosure.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="pt-4 pb-2 text-sm">
                    <FormCategory
                      doc={doc}
                      isCreate={isCreate}
                      level_assessment_id={paramId}
                      categoryOptions={categoryOptions}
                    />
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        )}
      </div>
    </div>
  );
}

export default Form;
