import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { useCallback, useContext, useEffect, useState } from "react";
import Select from "react-select";

// Icons
import { XMarkIcon, ArrowDownOnSquareIcon } from "@heroicons/react/24/outline";

// Contexts
import LoaderContext from "../../../../context/psikotest/LoaderContext";
import SnackbarContext from "../../../../context/psikotest/SnackbarContext";

// Helpers
import errorHandler from "../../../../helper/psikotest/errorHandler";

// Controller
import Ist from "../../../../controller/psikotest/psikotest_system/ist";
import AssessmentIstSubtest from "../../../../controller/psikotest/psikotest_system/assessment_ist_subtest";

const schema = yup.object().shape({
    id: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required(),
    assessment_id: yup
        .number()
        .label("Assessment ID")
        .transform((value) => (isNaN(value) ? undefined : value))
        .required(),
    ist_id: yup
        .number()
        .label("CFIT ID")
        .transform((value) => (isNaN(value) ? undefined : value))
        .required(),
    index_list: yup
        .number()
        .label("Index")
        .transform((value) => (isNaN(value) ? undefined : value))
        .required(),
    status: yup
        .string()
        .label("Status")
        .transform((value) => (value === null ? "" : value))
        .required(),
});

function FormIstSubtest(
    props: {
        data?: TPSAssessmentIstSubtest,
        assessment_id: number,
        handleClose: () => void,
        assessment_ist_subtest: TPSAssessmentIstSubtest[]
    }) {
    const { setMessage } = useContext(LoaderContext);
    const { setNotif } = useContext(SnackbarContext);
    // is create
    const isCreate = props.data === undefined;
    // state required value
    const [istOptions, setIstOptions] = useState<TSelect[]>([]);
    // react hook form
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch
    } = useForm<TPSAssessmentIstSubtest>({ resolver: yupResolver(schema) });
    const form_data = watch()

    const getDoc = useCallback(
        async function getDoc() {
            try {
                // fetch ist subtest
                setMessage("Fetch Subtest");
                const cc = new Ist();
                const resIst = await cc.list();
                const toSelectIst: TSelect[] = resIst.data.list
                    .map((v: TPSIst) => {
                        return {
                            id: v.id,
                            value: v.id,
                            label: v.subtest_name,
                            // check if ist already selected
                            isDisabled: props.assessment_ist_subtest.some((vacs) => vacs.ist_id === v.id)
                        };
                    });
                setIstOptions(toSelectIst);
                // fetch document
                if (isCreate) {
                    setValue("id", 0);
                    // update assessment_id
                    setValue("assessment_id", props.assessment_id);
                    // get last index
                    let next_index = 0
                    if (props.assessment_ist_subtest.length > 0) {
                        next_index = props.assessment_ist_subtest[props.assessment_ist_subtest.length - 1].index_list + 1
                    }
                    setValue("index_list", next_index);
                    setValue("status", "0");
                } else {
                    setValue("id", props.data?.id ?? 0);
                    setValue("assessment_id", props.data?.assessment_id ?? 0);
                    setValue("ist_id", props.data?.ist_id ?? 0);
                    setValue("index_list", props.data?.index_list ?? 0);
                    setValue("status", props.data?.status ?? "0");
                }
                setMessage("");
            } catch (error) {
                setMessage("");
                const errorMessage = errorHandler(error);
                setNotif({ type: "error", message: errorMessage });
            }
        },
        [setValue, isCreate, setNotif, setMessage, props.assessment_id, props.data, props.assessment_ist_subtest]
    );

    useEffect(() => {
        getDoc();
    }, [getDoc]);

    const onSubmit: SubmitHandler<TPSAssessmentIstSubtest> = async (data) => {
        try {
            setMessage("Save CFIT Subtest");
            const cacs = new AssessmentIstSubtest();
            let res
            if (isCreate) {
                res = await cacs.create(data);
            } else {
                res = await cacs.update(data);
            }
            setNotif({ type: "success", message: res.data.message });
            setMessage("");
            props.handleClose();
        } catch (error) {
            setMessage("");
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    };

    return <div>
        <div className="fixed z-20 top-0 left-0 w-screen h-screen bg-black opacity-20"></div>
        <div className="fixed z-30 top-0 left-0 w-screen h-screen bg-transparent flex items-center justify-center">
            <div className="bg-white w-screen md:w-[50%] h-auto min-h-[40%] md:rounded overflow-auto">
                <div className="p-4 bg-green-800 md:rounded-t text-white flex justify-between">
                    <h3>Form CFIT Subtest</h3>
                    <XMarkIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent cursor-pointer" aria-hidden="true" onClick={() => props.handleClose()} />
                </div>
                <div className="p-4 flex flex-col">
                    <div>
                        <form className="w-full">
                            <div className="flex flex-wrap -mx-3">
                                {/* ID */}
                                <input {...register("id")} type="number" className="hidden" />
                                {errors.id && <p className="text-red-500 text-xs italic">{errors.id.message}</p>}
                                {/* Assessment ID */}
                                <input {...register("assessment_id")} type="number" className="hidden" />
                                {errors.assessment_id && <p className="text-red-500 text-xs italic">{errors.assessment_id.message}</p>}
                                {/* Index */}
                                <input {...register("index_list")} type="number" className="hidden" />
                                {errors.index_list && <p className="text-red-500 text-xs italic">{errors.index_list.message}</p>}
                                {/* Status */}
                                <input {...register("status")} type="number" className="hidden" />
                                {errors.status && <p className="text-red-500 text-xs italic">{errors.status.message}</p>}
                                {/* CFIT ID */}
                                <div className="w-full px-3 mb-6">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                        Subtest
                                    </label>
                                    <div>
                                        <Select
                                            placeholder="Select Subtest"
                                            styles={{
                                                control: (provided: Record<string, unknown>, state: any) => {
                                                    return ({
                                                        ...provided,
                                                        borderColor: errors.ist_id ? "rgb(239 68 68 / 1)" : "rgb(229 231 235 / 1)",
                                                        background: state.menuIsOpen ? "white" : "rgb(229 231 235 / 1)"
                                                    })
                                                },
                                                menuList: (base) => ({
                                                    ...base,
                                                    maxHeight: "200px"
                                                })
                                            }}
                                            isDisabled={false}
                                            value={istOptions.find(v => v.value === form_data.ist_id)}
                                            options={istOptions}
                                            onChange={(v, _) => {
                                                if (v?.value !== undefined && typeof v.value === "number") {
                                                    setValue("ist_id", v.value);
                                                }
                                            }}
                                        />
                                        {errors.ist_id && <p className="text-red-500 text-xs italic">{errors.ist_id.message}</p>}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="flex mt-auto">
                        <button
                            onClick={handleSubmit(onSubmit)}
                            className="ml-auto block w-auto px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded font-semibold text-sm"
                        >
                            <div className="flex">
                                <ArrowDownOnSquareIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
                                Save
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default FormIstSubtest;