import { useContext, useEffect, useCallback, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { ArrowDownOnSquareIcon } from "@heroicons/react/24/outline";

// Component
import BreadCrumbNavigation from "../../../components/psikotest/psikotest_system/BreadCrumbNavigation";

// Controller
import IndexPage from "../../../controller/psikotest/psikotest_system/index_page";

// Context
import LoaderContext from "../../../context/psikotest/LoaderContext";
import SnackbarContext from "../../../context/psikotest/SnackbarContext";

// Helper
import logRender from "../../../helper/psikotest/logRender";
import errorHandler from "../../../helper/psikotest/errorHandler";

// validation schema
const schema = yup.object().shape({
  snap_interval_assessment: yup.number().min(60).max(900).required()
});

function Setting() {
  const navigate = useNavigate();
  // call message setter
  const { setMessage } = useContext(LoaderContext);
  // call notif setter
  const { setNotif } = useContext(SnackbarContext);
  // state
  const [disk, setDisk] = useState<TPSDiskPart[]>([]);
  // init useForm
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm<TPSSetting>({ resolver: yupResolver(schema) });

  useEffect(() => {
    logRender({ type: "page", name: "/Setting" });
  }, []);

  const getDoc = useCallback(
    async function getDoc() {
      try {
        // show message and loader
        setMessage("Fetch Setting Detail");
        // create object constructor Category controller
        const ipc = new IndexPage();
        const resIndex = await ipc.get_setting();
        setDisk(resIndex.data.row.disk_info);
        setValue("id", resIndex.data.row.id);
        setValue("snap_interval_assessment", resIndex.data.row.snap_interval_assessment);
        setValue("email_address", resIndex.data.row.email_address);
        setValue("email_password", "");
        setValue("email_server", resIndex.data.row.email_server);
        setValue("email_port", resIndex.data.row.email_port);
        setMessage("");
      } catch (error) {
        setMessage("");
        const errorMessage = errorHandler(error);
        setNotif({ type: "error", message: errorMessage });
      }
    },
    [setNotif, setMessage, setValue]
  );

  useEffect(() => {
    getDoc();
  }, [getDoc]);

  const onSubmit: SubmitHandler<TPSSetting> = async (data) => {
    try {
      setMessage("Save Setting");
      const ipc = new IndexPage();
      const resIndex = await ipc.update_setting(data);
      setNotif({ type: "success", message: resIndex.data.message });
      setMessage("");
      navigate(`../`, { replace: true });
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  return (
    <div className="w-full flex flex-col space-y-0.5">
      <BreadCrumbNavigation />
      <div className="w-full flex flex-col space-y-0.5">
        <div className="flex">
          <button
            onClick={handleSubmit(onSubmit)}
            className="mx-2 block w-auto px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded font-semibold text-sm"
          >
            <div className="flex">
              <ArrowDownOnSquareIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
              Save
            </div>
          </button>
        </div>
        <div className="pt-4">
          <form className="w-full">
            <div className="flex flex-wrap -mx-3">
              <div className="w-full md:w-full px-3 mb-6">
                <label className="text-gray-700 text-xs mb-2">
                  <span className="block uppercase tracking-wide font-bold">Snap Interval Assessment (Participant Photo)</span>
                  <i>In second, min 60s (1 Minute), max 900s  (15 Minute)</i>
                </label>
                <input
                  type="number"
                  className={
                    (errors?.snap_interval_assessment ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                    " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                  }
                  {...register("snap_interval_assessment")}
                />
                {errors.snap_interval_assessment && <p className="text-red-500 text-xs italic">{errors.snap_interval_assessment.message}</p>}
              </div>
            </div>
            <hr />
            <div className="flex flex-wrap -mx-3 mt-4">
              <div className="w-full md:w-full px-3 mb-6">
                <label className="text-gray-700 text-xs mb-2">
                  <span className="block uppercase tracking-wide font-bold">Email Address</span>
                  <i>Email for send invitation by system</i>
                </label>
                <input
                  type="email"
                  className={
                    (errors?.email_address ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                    " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                  }
                  {...register("email_address")}
                />
                {errors.email_address && <p className="text-red-500 text-xs italic">{errors.email_address.message}</p>}
              </div>
              <div className="w-full md:w-full px-3 mb-6">
                <label className="text-gray-700 text-xs mb-2">
                  <span className="block uppercase tracking-wide font-bold">Email Password</span>
                  <span className="block tracking-wide font-bold">Only fill in first time (create) or when your password changed, Otherwise Leave it blank</span>
                  <i>Use application password, contact dev for more info <span className="text-red-500">(this information will be encrypted in server)</span></i>
                </label>
                <input
                  type="password"
                  className={
                    (errors?.email_password ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                    " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                  }
                  {...register("email_password")}
                />
                {errors.email_password && <p className="text-red-500 text-xs italic">{errors.email_password.message}</p>}
              </div>
              <div className="w-full md:w-1/2 px-3 mb-6">
                <label className="text-gray-700 text-xs mb-2">
                  <span className="block uppercase tracking-wide font-bold">Email Server</span>
                  <i>Domain SMTP mail server, contact dev for more info</i>
                </label>
                <input
                  type="text"
                  className={
                    (errors?.email_server ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                    " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                  }
                  {...register("email_server")}
                />
                {errors.email_server && <p className="text-red-500 text-xs italic">{errors.email_server.message}</p>}
              </div>
              <div className="w-full md:w-1/2 px-3 mb-6">
                <label className="text-gray-700 text-xs mb-2">
                  <span className="block uppercase tracking-wide font-bold">Email Port</span>
                  <i>Port mail server, contact dev for more info</i>
                </label>
                <input
                  type="text"
                  className={
                    (errors?.email_port ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                    " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                  }
                  {...register("email_port")}
                />
                {errors.email_port && <p className="text-red-500 text-xs italic">{errors.email_port.message}</p>}
              </div>
            </div>
            <hr />
            <div className="flex flex-wrap mt-4">
              <label className="text-gray-700 text-xs mb-2">
                <span className="block uppercase tracking-wide font-bold">Storage</span>
              </label>
              <div className="w-full">
                {disk.map((d, i) => {
                  return (
                    <div key={`disk_${i}`}>
                      <div className="pt-1 w-full flex space-x-1">
                        <div className="w-1/12 text-center px-2 bg-slate-600 rounded-t text-white text-sm">{d.part}</div>
                        <div className="w-11/12">
                          <div className="w-full h-full flex rounded">
                            <div className="bg-blue-500 h-full" style={{ width: `${Math.round(d.used_percent)}%` }}></div>
                            <div className="bg-gray-200 h-full" style={{ width: `${Math.round(100 - d.used_percent)}%` }}></div>
                          </div>
                        </div>
                      </div>
                      <div className="pb-1 w-full flex space-x-1">
                        <div className="w-1/12 text-center text-sm border border-t-0 border-slate-600 rounded-b">{`${(d.used_percent).toFixed(0)}%`}</div>
                        <div className="w-11/12 px-2">
                          {`${(d.avail / 1073741824).toFixed(0)} GB free of ${(d.size / 1073741824).toFixed(0)} GB`}
                        </div>
                      </div>
                    </div>)
                })}
              </div>
            </div>
          </form>
        </div>
      </div >
    </div >
  );
}

export default Setting;


