import req from "../../../services/psikotest/request";

class AssessmentParticiBehaviouralSession {
  async reset(id: number, subtest_name: string) {
    try {
      const res = await req.get("/api/psikotest_system/assessment_participant_behavioural_session/" + id + "/reset/" + subtest_name);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default AssessmentParticiBehaviouralSession;
