import req from "../../../services/assessment/request";

class LevelAssessment {
  async list() {
    try {
      const res = await req.get("/api/assessment_system/level_assessment/");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async get(id: string) {
    try {
      const res = await req.get("/api/assessment_system/level_assessment/" + id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async create(level_assessment: TLevelAssessment) {
    try {
      const res = await req.post("/api/assessment_system/level_assessment/", level_assessment);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async update(id: string, level_assessment: TLevelAssessment) {
    try {
      const res = await req.put("/api/assessment_system/level_assessment/" + id, level_assessment);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async delete(id: string) {
    try {
      const res = await req.delete("/api/assessment_system/level_assessment/" + id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async listCategory(id: string) {
    try {
      const res = await req.get("/api/assessment_system/level_assessment/" + id + "/category");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async addCategory(id: string, data: TLevelAssessmentCategory) {
    try {
      const res = await req.post("/api/assessment_system/level_assessment/" + id + "/category", data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async updateCategory(data: TLevelAssessmentCategory) {
    try {
      const res = await req.put("/api/assessment_system/level_assessment/category/" + data.id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async deleteCategory(category_id: string) {
    try {
      const res = await req.delete("/api/assessment_system/level_assessment/category/" + category_id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default LevelAssessment;
