import { AxiosRequestConfig } from "axios";
import reqNoAuth from "../../../services/requestNoAuth";

class AssessmentSessionParticipant {
  async getActiveAssessment(assessment_id: string, participant_session_id: string) {
    try {
      const res = await reqNoAuth.get("/api/assessment_participant/session/get_active_session/" + assessment_id + "/session/" + participant_session_id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async getParticipantAssessment(participant_id: string, session_id: string) {
    try {
      const res = await reqNoAuth.get(
        "/api/assessment_participant/session/get_participant_session/" + participant_id + "/" + session_id
      );
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async startAssessment(assessment_participant_session_id: string, data: { time_limit: number }) {
    try {
      const res = await reqNoAuth.post(
        "/api/assessment_participant/session/start_session/" + assessment_participant_session_id,
        data
      );
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async uploadAnswer(
    assessment_participant_session_id: string,
    assessment_id: string,
    data: { file: File },
    config: AxiosRequestConfig<FormData>
  ) {
    try {
      const form_data = new FormData();
      form_data.append("file", data.file);
      const res = await reqNoAuth.post(
        "/api/assessment_participant/session/upload_answer/" + assessment_participant_session_id + "/" + assessment_id,
        form_data,
        config
      );
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async saveWebcam(assessment_participant_session_id: string, assessment_id: string, data: { base64_image: string }) {
    try {
      const res = await reqNoAuth.post(
        "/api/assessment_participant/session/save_webcam/" + assessment_participant_session_id + "/" + assessment_id,
        data
      );
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async getIntrayEmail(assessment_id: string, assessment_participant_id: string) {
    try {
      const res = await reqNoAuth.get(
        "/api/assessment_participant/session/get_active_session/" + assessment_id + "/intray_email/" + assessment_participant_id
      );
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async saveReplyIntrayEmail(assessment_id: string, assessment_participant_id: string, data: TPQuestionaryReplyEmail) {
    try {
      const res = await reqNoAuth.post(
        "/api/assessment_participant/session/get_active_session/" + assessment_id + "/intray_email/" + assessment_participant_id,
        data
      );
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async saveIntrayNewEmail(assessment_id: string, assessment_participant_id: string, data: TPQuestionaryNewEmail) {
    try {
      const res = await reqNoAuth.post(
        "/api/assessment_participant/session/get_active_session/" + assessment_id + "/intray_email/" + assessment_participant_id,
        data
      );
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async getAllReplyIntrayEmail(
    assessment_id: string,
    assessment_participant_id: string,
    assessment_participant_session_id: string
  ) {
    try {
      const res = await reqNoAuth.get(
        "/api/assessment_participant/session/get_active_session/" +
        assessment_id +
        "/intray_email/" +
        assessment_participant_id +
        "/get_reply/" +
        assessment_participant_session_id
      );
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async GetAllEmailAddressList(assessment_id: string, assessment_participant_id: string) {
    try {
      const res = await reqNoAuth.get(
        "/api/assessment_participant/session/get_all_email_address/" + assessment_id + "/" + assessment_participant_id
      );
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default AssessmentSessionParticipant;
