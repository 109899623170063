import { Link } from "react-router-dom";
import { useCallback, useContext, useState, useEffect } from "react";

// Icons

// Context
import SnackbarContext from "../../../context/psikotest/SnackbarContext";
import LoaderContext from "../../../context/psikotest/LoaderContext";

// Helper
import errorHandler from "../../../helper/psikotest/errorHandler";
import logRender from "../../../helper/psikotest/logRender";

// Controller
import Ist from "../../../controller/psikotest/psikotest_system/ist";

// Component

function IstList() {
  const { setMessage } = useContext(LoaderContext);
  const { setNotif } = useContext(SnackbarContext);
  // required state
  const [list, setList] = useState<Array<TPSIst>>([]);

  useEffect(() => {
    logRender({ type: "page", name: "ist_formula/IstList" });
  }, []);

  const getList = useCallback(async () => {
    try {
      setMessage("Fetch Ist");
      const ci = new Ist();
      const res = await ci.list();
      setList(res.data.list);
      setMessage("");
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  }, [setNotif, setMessage]);

  useEffect(() => {
    getList();
  }, [getList]);

  return (
    <div className="w-full flex flex-col space-y-0.5">
      <div className="flex flex-wrap">
        {list.map((l, i) => {
          return (
            <div key={`ist_list_${i}`} className="w-1/4">
              <Link to={`${l.id}`}
                className="flex flex-col items-center m-3 border rounded px-2 py-10 text-center cursor-pointer text-white"
                style={{ backgroundImage: `linear-gradient(${i * 30}deg, #e0c3fc 0%, #8ec6fc 100%)` }}
              >
                <div className="font-semibold text-black text-lg">{l.subtest_name}</div>
                <div className="capitalize text-sm font-light border px-4 rounded bg-transparent text-white">{l.answer_type.replaceAll("_", " ")}</div>
              </Link>
            </div>
          )
        })}
        <div className="w-1/4">
          <Link to={`gesamt`}
            className="flex flex-col items-center m-3 border rounded px-2 py-10 text-center cursor-pointer text-white"
            style={{ backgroundImage: `linear-gradient(125deg, blue 0%, red 100%)` }}
          >
            <div className="font-semibold text-white text-lg">GESAMT</div>
            <div className="capitalize text-sm font-light border px-4 rounded bg-transparent text-white">Total Skor (SW)</div>
          </Link>
        </div>
        <div className="w-1/4">
          <Link to={`gesamt_norma_iq`}
            className="flex flex-col items-center m-3 border rounded px-2 py-10 text-center cursor-pointer text-white"
            style={{ backgroundImage: `linear-gradient(125deg, red 0%, blue 100%)` }}
          >
            <div className="font-semibold text-white text-lg">IQ</div>
            <div className="capitalize text-sm font-light border px-4 rounded bg-transparent text-white">Norma IQ</div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default IstList;
