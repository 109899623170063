import { useContext, useEffect, useCallback, useState } from "react";

// Context
import AuthContext from "../../../context/assessment/AuthContext";
import LoaderContext from "../../../context/assessment/LoaderContext";
import SnackbarContext from "../../../context/assessment/SnackbarContext";

// Component
import BreadCrumbNavigation from "../../../components/assessment/assessment_system/BreadCrumbNavigation";
import FileUpload from "../../../components/assessment/assessment_system/FileUpload";
import PdfViewer from "../../../components/assessment/assessment_system/PdfViewer";

// Controller
import IndexPage from "../../../controller/assessment/assessment_system/index_page";

// Helper
import logRender from "../../../helper/assessment/logRender";
import errorHandler from "../../../helper/assessment/errorHandler";

function Dictionary() {
  // context
  const { user } = useContext(AuthContext);
  // is admin
  const isAdmin = user.roles === "1";
  // call message setter
  const { setMessage } = useContext(LoaderContext);
  // call notif setter
  const { setNotif } = useContext(SnackbarContext);
  // state
  const [id, setId] = useState<number>(0);
  const [filePath, setFilePath] = useState<string>("");

  useEffect(() => {
    logRender({ type: "page", name: "/Setting" });
  }, []);

  const getDoc = useCallback(
    async function getDoc() {
      try {
        // show message and loader
        setMessage("Fetch Setting Detail");
        // create object constructor Category controller
        const ipc = new IndexPage();
        const resIndex = await ipc.get_setting();
        setId(resIndex.data.row.id);
        setFilePath(resIndex.data.row.dictionary === null ? "" : resIndex.data.row.dictionary);
        setMessage("");
      } catch (error) {
        setMessage("");
        const errorMessage = errorHandler(error);
        setNotif({ type: "error", message: errorMessage });
      }
    },
    [setNotif, setMessage]
  );

  useEffect(() => {
    getDoc();
    document.body.style.overflow = "hidden";
  }, [getDoc]);

  return (
    <div className="w-full flex flex-col space-y-0.5">
      <BreadCrumbNavigation />
      <div className="w-full flex flex-col space-y-0.5">
        {isAdmin &&
          <div className="pt-4">
            <div className="w-full md:w-full mb-6">
              <FileUpload
                type="setting"
                editable={isAdmin}
                document_id={id}
                filepath={filePath}
                error_field={undefined}
                setDocumentId={(id) => { }}
                setFilePath={(filepath) => setFilePath(filepath)}
                key={id}
              />
            </div>
          </div>
        }
        <PdfViewer pdfUrl={filePath ?? ""} />
      </div >
    </div >
  );
}

export default Dictionary;


