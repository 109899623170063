import req from "../../../services/psikotest/request";

class IstGesamtNorma {
  async create(data: TPSIstGesamtNorma) {
    try {
      const res = await req.post("/api/psikotest_system/ist_gesamt_norma/", data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async update(data: TPSIstGesamtNorma) {
    try {
      const res = await req.put("/api/psikotest_system/ist_gesamt_norma/" + data.id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async delete(data: TPSIstGesamtNorma) {
    try {
      const res = await req.delete("/api/psikotest_system/ist_gesamt_norma/", { data });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default IstGesamtNorma;
