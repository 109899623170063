import React from "react";

interface WebSocketContextType {
  socket: WebSocket;
  connect: (callback: VoidFunction) => void;
  sendMessage: (scope: "assessment" | "psikotest", message: string) => void;
}

let WebSocketContext = React.createContext<WebSocketContextType>(null!);

export default WebSocketContext;
