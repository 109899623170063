import req from "../../../services/assessment/request";

class IntrayEmail {
  async list() {
    try {
      const res = await req.get("/api/assessment_system/intray_email");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async get(id: string) {
    try {
      const res = await req.get("/api/assessment_system/intray_email/" + id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async create(data: TIntrayEmail) {
    try {
      const res = await req.post("/api/assessment_system/intray_email", data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async update(id: string, data: TIntrayEmail) {
    try {
      const res = await req.put("/api/assessment_system/intray_email/" + id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async delete(id: string) {
    try {
      const res = await req.delete("/api/assessment_system/intray_email/" + id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async copyto_list(id: string) {
    try {
      const res = await req.get("/api/assessment_system/intray_email/copy_to/" + id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async copyto_add(id: string, data: { email_address_id: number }) {
    try {
      const res = await req.post("/api/assessment_system/intray_email/copy_to/" + id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async copyto_delete(id: string, email_address_id: string) {
    try {
      const res = await req.delete("/api/assessment_system/intray_email/copy_to/" + id + "/" + email_address_id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async address_list() {
    try {
      const res = await req.get("/api/assessment_system/intray_email/address");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async address_get(email_address_id: string) {
    try {
      const res = await req.get("/api/assessment_system/intray_email/address/" + email_address_id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async address_create(data: TIntrayEmailAddress) {
    try {
      const res = await req.post("/api/assessment_system/intray_email/address", data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async address_update(email_address_id: string, data: TIntrayEmailAddress) {
    try {
      const res = await req.put("/api/assessment_system/intray_email/address/" + email_address_id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async address_delete(email_address_id: string) {
    try {
      const res = await req.delete("/api/assessment_system/intray_email/address/" + email_address_id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default IntrayEmail;
