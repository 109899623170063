import { useCallback, useContext, useState, useEffect, useMemo, Fragment } from "react";
import { useMediaQuery } from "react-responsive";
import Swal from "sweetalert2";
import { Transition } from "@headlessui/react";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// Icons
import { ClipboardDocumentListIcon, PlusIcon, TrashIcon, } from "@heroicons/react/24/outline";

// Context
import SnackbarContext from "../../../context/psikotest/SnackbarContext";
import LoaderContext from "../../../context/psikotest/LoaderContext";

// Helper
import errorHandler from "../../../helper/psikotest/errorHandler";
import logRender from "../../../helper/psikotest/logRender";

// Controller
import Brs from "../../../controller/psikotest/psikotest_system/brs";
import BrsQuestionary from "../../../controller/psikotest/psikotest_system/brs_questionary";

// Component
import TableFilter from "../../../components/psikotest/psikotest_system/TableFilter";
import Table from "../../../components/psikotest/psikotest_system/Table";

// Modals
import FormQuestionary from "./modal/FormQuestionary";
import FormImportQuestionary from "./modal/FormImportQuestionary";

const schema = yup.object().shape({
  id: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required(),
  mdi: yup
    .string()
    .label("(MDI) Main Question | Description | Instructions")
    .transform((value) => (value === null ? "" : value))
    .required()
});

function List() {
  const { setMessage } = useContext(LoaderContext);
  const { setNotif } = useContext(SnackbarContext);
  // required state
  const [list, setList] = useState<Array<TPSBrsQuestionary>>([]);
  const [search, setSearch] = useState<string>("");
  const [focus_mdi, setFocusMdi] = useState<boolean>(false);
  // state modal
  const [isOpenFormQuestionary, setIsOpenFormQuestionary] = useState<boolean>(false);
  const [isOpenFormImportQuestionary, setIsOpenFormImportQuestionary] = useState<boolean>(false);
  // state other
  const [selected_brs_questionary, setSelectedBrsQuestionary] = useState<TPSBrsQuestionary | undefined>(undefined);

  // use form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<TPSBrs>({ resolver: yupResolver(schema), mode: "onBlur" });


  useEffect(() => {
    logRender({ type: "page", name: "brs_questionary/List" });
  }, []);

  const detData = useCallback(async () => {
    try {
      setMessage("Fetch BRS Questionary");
      const brs_qc = new BrsQuestionary();
      const res_qc = await brs_qc.list();
      setList(res_qc.data.list);
      setMessage("");
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  }, [setNotif, setMessage]);

  const getDoc = useCallback(
    async function getDoc() {
      try {
        // fetch rac document
        setMessage("Fetch Document");
        const cd = new Brs();
        const res = await cd.get();
        const row: TPSBrs = res.data.row;
        setValue("id", row.id);
        setValue("mdi", row.mdi);
        setMessage("");
      } catch (error) {
        setMessage("");
        const errorMessage = errorHandler(error);
        setNotif({ type: "error", message: errorMessage });
      }
    },
    [setValue, setNotif, setMessage]
  );

  useEffect(() => {
    getDoc();
    detData();
  }, [getDoc, detData]);

  const handleDeleteList = (id: number) => {
    const arrFilter = [...list].filter((v) => v.id !== id);
    setList(arrFilter);
  };

  // when selected_brs_questionary changes and not undefined open Cfit Questionary form to update
  useEffect(() => {
    if (selected_brs_questionary !== undefined) {
      setIsOpenFormQuestionary(true)
    }
  }, [selected_brs_questionary]);

  // handleRefreshAfterOpenForm
  const handleRefreshAfterOpenForm = () => {
    // rmove selected value will close form modal
    setSelectedBrsQuestionary(undefined)
    // close modal
    setIsOpenFormQuestionary(false)
    // re-fetch data
    detData();
  }

  // handleRefreshAfterImport
  const handleRefreshAfterImport = () => {
    // close import form modal
    setIsOpenFormImportQuestionary(false)
    // re-fetch data
    detData();
  }

  const onSubmit: SubmitHandler<TPSBrs> = async (data) => {
    try {
      setMessage("Save Brs");
      const cd = new Brs();
      let res = await cd.update(data);
      setNotif({ type: "success", message: res.data.message });
      setMessage("");
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  return (
    <div className="w-full flex flex-col space-y-0.5">
      {/* Import Questionary Form */}
      <Transition
        as={Fragment}
        show={isOpenFormImportQuestionary}
        enter="ease-out duration-50"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-50"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div>
          <FormImportQuestionary
            nextNumber={list.length + 1}
            handleClose={() => setIsOpenFormImportQuestionary(false)}
            handleRefreshAfterImport={handleRefreshAfterImport}
          />
        </div>
      </Transition>
      {/* Questionary Form */}
      <Transition
        as={Fragment}
        show={isOpenFormQuestionary}
        enter="ease-out duration-50"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-50"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div>
          <FormQuestionary data={selected_brs_questionary} nextNumber={list.length + 1} handleClose={() => handleRefreshAfterOpenForm()} />
        </div>
      </Transition>
      {/* Main Form */}
      <form className="w-full" onBlur={() => handleSubmit(onSubmit)()}>
        <div className="flex flex-wrap -mx-3">
          <input {...register("id")} type="number" className="hidden" />
          {/** (MDI) Main Question | Description | Instructions */}
          <div className="w-full md:w-full px-3 mb-6">
            <label className="text-gray-700 text-xs mb-2">
              <span className="block uppercase tracking-wide font-bold">(MDI) Main Question | Description | Instructions</span>
              <i>Main question/description/intructions for sub-test, always show up until test ended.</i>
            </label>
            <textarea
              className={
                (errors?.mdi ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
              }
              rows={4}
              {...register("mdi")}
              onFocus={() => setFocusMdi(true)}
              onBlur={() => setFocusMdi(false)}
            />
            {errors.mdi && <p className="text-red-500 text-xs italic">{errors.mdi.message}</p>}
            <label className="text-xs mb-2">
              {focus_mdi
                ? <span className="text-green-600 block uppercase tracking-wide font-bold">AUTOSAVE ON OUT FOCUS</span>
                : <span className="text-blue-600 block uppercase tracking-wide font-bold">CLICK IN TEXTAREA TO EDIT</span>
              }
            </label>
            <input {...register("id")} type="number" className="hidden" />
          </div>
        </div>
      </form>
      <div className="flex flex-col md:flex-row items-start md:items-center">
        <div className="flex space-x-1">
          <button type="button"
            onClick={() => { setIsOpenFormImportQuestionary(true) }}
            className="block w-auto mb-2 md:m-0 px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded font-semibold text-sm"
          >
            <div className="flex">
              <ClipboardDocumentListIcon className="w-4" />
              <span className="block pl-1">Import</span>
            </div>
          </button>
          <button
            type="button"
            onClick={() => { setIsOpenFormQuestionary(true) }}
            className="block w-auto mb-2 md:m-0 px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded font-semibold text-sm"
          >
            <div className="flex">
              <PlusIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
              Create
            </div>
          </button>
        </div>
        <div className="w-full md:w-auto ml-auto flex">
          <TableFilter search={setSearch} />
        </div>
      </div>
      <div className="py-5">
        <BrsQuestionaryList list={list} handleDeleteList={handleDeleteList} search={search} setSelectedBrsQuestionary={setSelectedBrsQuestionary} />
      </div>
    </div >
  );
}

function BrsQuestionaryList(props: {
  list: Array<TPSBrsQuestionary>;
  handleDeleteList: (id: number) => void;
  search: string,
  setSelectedBrsQuestionary: React.Dispatch<React.SetStateAction<TPSBrsQuestionary | undefined>>
}) {
  const setNotif = useContext(SnackbarContext).setNotif;
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const confirmDelete = useCallback(
    async (brs_q: TPSBrsQuestionary) => {
      try {
        const confirm = await Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        });
        if (confirm.isConfirmed) {
          const brs_qc = new BrsQuestionary();
          await brs_qc.delete(brs_q.id);
          props.handleDeleteList(brs_q.id);
          setNotif({ type: "success", message: "brs questionary deleted" });
        }
      } catch (error) {
        const errorMessage = errorHandler(error);
        setNotif({ type: "error", message: errorMessage });
      }
    },
    [props, setNotif]
  );

  const columns = useMemo(
    () => [
      {
        id: "mobile",
        Header: "Number",
        accessor: "number",
        show: isMobile,
        Cell: (colProps: { row: { original: TPSBrsQuestionary } }) => {
          return (
            <button className="text-blue-500 hover:text-blue-800" onClick={() => props.setSelectedBrsQuestionary(colProps.row.original)}>
              {colProps.row.original.number || "NULL"}
            </button>
          );
        },
      },
      {
        id: "number",
        classHeader: "text-left px-2 w-8",
        Header: <div title="Number">Number</div>,
        classDivHeader: "flex items-center",
        accessor: "number",
        show: !isMobile,
        Cell: (colProps: { row: { original: TPSBrsQuestionary } }) => {
          return (
            <button className="flex items-center text-blue-500 hover:text-blue-800" onClick={() => props.setSelectedBrsQuestionary(colProps.row.original)}>
              {colProps.row.original.number}
            </button>
          );
        },
      },
      {
        id: "question",
        classHeader: "text-left px-2",
        Header: <div title="Statement">Question</div>,
        classDivHeader: "flex items-center",
        accessor: "question",
        show: !isMobile,
        Cell: (props: { row: { original: TPSBrsQuestionary } }) => {
          return props.row.original.question
        },
      },
      {
        id: "point_1_score",
        classHeader: "text-left px-2 w-20",
        Header: <div title="Number">Strongly Disagree</div>,
        classDivHeader: "flex items-center",
        accessor: "number",
        show: !isMobile,
        Cell: (props: { row: { original: TPSBrsQuestionary } }) => {
          return props.row.original.point_1_score
        },
      },
      {
        id: "point_2_score",
        classHeader: "text-left px-2 w-20",
        Header: <div title="Number">Disagree</div>,
        classDivHeader: "flex items-center",
        accessor: "number",
        show: !isMobile,
        Cell: (props: { row: { original: TPSBrsQuestionary } }) => {
          return props.row.original.point_2_score
        },
      },
      {
        id: "point_3_score",
        classHeader: "text-left px-2 w-20",
        Header: <div title="Number">Neutral</div>,
        classDivHeader: "flex items-center",
        accessor: "number",
        show: !isMobile,
        Cell: (props: { row: { original: TPSBrsQuestionary } }) => {
          return props.row.original.point_3_score
        },
      },
      {
        id: "point_4_score",
        classHeader: "text-left px-2 w-20",
        Header: <div title="Number">Agree</div>,
        classDivHeader: "flex items-center",
        accessor: "number",
        show: !isMobile,
        Cell: (props: { row: { original: TPSBrsQuestionary } }) => {
          return props.row.original.point_4_score
        },
      },
      {
        id: "point_5_score",
        classHeader: "text-left px-2 w-20",
        Header: <div title="Number">Strongly Agree</div>,
        classDivHeader: "flex items-center",
        accessor: "number",
        show: !isMobile,
        Cell: (props: { row: { original: TPSBrsQuestionary } }) => {
          return props.row.original.point_5_score
        },
      },
      {
        id: "action_delete",
        classHeader: "w-[30px] text-white",
        Header: () => {
          return <TrashIcon className="h-5 w-auto" />;
        },
        classDivHeader: "flex justify-center",
        accessor: "id",
        show: !isMobile,
        disableSortBy: true,
        className: "text-white bg-red-500 hover:bg-red-800",
        Cell: (props: { row: { original: TPSBrsQuestionary } }) => {
          return (
            <button className="flex justify-center" onClick={() => confirmDelete(props.row.original)}>
              <TrashIcon className="h-5" />
            </button>
          );
        },
      },
    ],
    [isMobile, confirmDelete, props]
  );

  return <Table columns={columns} list={props.list} search={props.search} />;
}

export default List;
