import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import Swal from "sweetalert2";

// Icons
import { ChevronDownIcon, ChevronUpIcon, PlusIcon, ArrowsUpDownIcon, ClipboardDocumentCheckIcon } from "@heroicons/react/24/outline";

// Contexts
import PSAuthContext from "../../../context/psikotest/AuthContext";
import WebSocketContext from "../../../context/WebSocketContext";
import LoaderContext from "../../../context/psikotest/LoaderContext";
import SnackbarContext from "../../../context/psikotest/SnackbarContext";

// Helpers
import errorHandler from "../../../helper/psikotest/errorHandler";

// Controller
import Assessment from "../../../controller/psikotest/psikotest_system/assessment";
import AssessmentPersonalitySubtest from "../../../controller/psikotest/psikotest_system/assessment_personality_subtest";

// Modals
import FormPersonalitySubtest from "./modal/FormPersonalitySubtest";
import ModalPersonalitySubtestSession from "./modal/ModalPersonalitySubtestSession";
import ModalPersonalityScore from "./modal/ModalPersonalityScore";

function ListPersonality(props: { assessment_id: number; editable: boolean; doc: TPSAssessment; isPsikolog: boolean; }) {
    // Contexts
    const { user } = useContext(PSAuthContext);
    const { setMessage } = useContext(LoaderContext);
    const { setNotif } = useContext(SnackbarContext);
    const { sendMessage } = useContext(WebSocketContext)
    // is admin
    const isAdmin = user.roles === "1";

    // helper state
    // list assessment personality 
    const [assessment_personality_subtest, setAssessmentPersonalitySubtest] = useState<TPSAssessmentPersonalitySubtest[]>([])
    // state modal
    const [isOpenFormPersonalitySubtest, setIsOpenFormPersonalitySubtest] = useState<boolean>(false);
    const [isOpenModalPersonalitySubtestSession, setIsOpenModalPersonalitySubtestSession] = useState<boolean>(false);
    const [isOpenModalPersonalityScore, setIsOpenModalPersonalityScore] = useState<boolean>(false);
    // state for modal
    const [selectedPersonalitySubtest, setSelectedPersonalitySubtest] = useState<TPSAssessmentPersonalitySubtest | undefined>(undefined);
    const [selectedPersonalitySubtestSession, setSelectedPersonalitySubtestSession] = useState<TPSAssessmentPersonalitySubtest | undefined>(undefined);

    // get initial data
    const getInitialData = useCallback(
        async function getInitialData() {
            try {
                const ca = new Assessment()
                // fetch assessment psikotest subtest
                setMessage("Fetch Psikotest Subtest")
                let res = await ca.personality_subtest(props.assessment_id)
                const list_subtest: TPSAssessmentPersonalitySubtest[] = res.data.list
                setAssessmentPersonalitySubtest(list_subtest)
                setMessage("");
            } catch (error) {
                setMessage("");
                const errorMessage = errorHandler(error);
                setNotif({ type: "error", message: errorMessage });
            }
        }, [setNotif, setMessage, props.assessment_id]
    );

    // use effect initial data
    useEffect(() => {
        getInitialData();
    }, [getInitialData]);

    // sortIndexAssessmentPersonalitySubtest
    const sortIndexAssessmentPersonalitySubtest = useCallback(
        async function sortIndexAssessmentPersonalitySubtest() {
            // check subtest length
            const lengthPersonalitySubtest = assessment_personality_subtest.length
            let update = 0;
            for (let index = 0; index < lengthPersonalitySubtest; index++) {
                const element = assessment_personality_subtest[index];
                // check if index is not sequentially
                if (element.index_list !== index) {
                    // update
                    const caps = new AssessmentPersonalitySubtest()
                    await caps.update({ ...element, index_list: index })
                    update++
                }
            }
            if (update > 0) {
                // re-fetch data
                getInitialData();
            }
        },
        [assessment_personality_subtest, getInitialData]
    )

    // when open form personality subtest session when personality subtest session selected
    useEffect(() => {
        setIsOpenModalPersonalitySubtestSession(selectedPersonalitySubtestSession !== undefined)
    }, [selectedPersonalitySubtestSession]);

    // handleClosePersonalitySubtestSession
    const handleClosePersonalitySubtestSession = () => {
        // when any selected personality
        if (selectedPersonalitySubtestSession) {
            setSelectedPersonalitySubtestSession(undefined)
        } else {
            setIsOpenModalPersonalitySubtestSession(false)
        }
    }

    // always run when any change on assessment_personality_subtest
    // to sort index list
    useEffect(() => {
        // re-sort index
        sortIndexAssessmentPersonalitySubtest();
    }, [assessment_personality_subtest, sortIndexAssessmentPersonalitySubtest])

    // when open form personality subtest when personality subtest selected
    useEffect(() => {
        setIsOpenFormPersonalitySubtest(selectedPersonalitySubtest !== undefined)
    }, [selectedPersonalitySubtest]);

    // handleClosePersonalitySubtest
    const handleClosePersonalitySubtest = () => {
        // when any selected personality
        if (selectedPersonalitySubtest) {
            setSelectedPersonalitySubtest(undefined)
        } else {
            setIsOpenFormPersonalitySubtest(false)
        }
        // re-fetch data
        getInitialData()
    }

    // handleToggleStatusPersonalitySubtest
    const handleToggleStatusPersonalitySubtest = async (data: TPSAssessmentPersonalitySubtest) => {
        try {
            // fetch assessment personality subtest
            setMessage("Change Status Assessment Personality Subtest")
            const caps = new AssessmentPersonalitySubtest()
            const res = await caps.update({ ...data, status: data.status === "0" ? "1" : "0" })
            setNotif({ type: "success", message: res.data.message });
            setMessage("");
            // re-fetch data
            getInitialData();
            // broadcast message to all client (target: participant)
            sendMessage("psikotest", JSON.stringify({
                target: "participant",
                action: "check open session",
                message: (data.status === "0" ? "1" : "0") === "1" ? "Assessment open by PIC" : "Assessment close by PIC"
            }))
        } catch (error) {
            setMessage("");
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }

    // handleDeletePersonalitySubtest
    const handleDeletePersonalitySubtest = async (data: TPSAssessmentPersonalitySubtest) => {
        try {
            const confirm = await Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            });
            if (confirm.isConfirmed) {
                const cacs = new AssessmentPersonalitySubtest();
                const res = await cacs.delete(data);
                setNotif({ type: "success", message: res.data.message });
                // re-fetch data
                getInitialData();
            }
        } catch (error) {
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }

    // handleMovePersonalitySubtest
    const handleMovePersonalitySubtest = async (data: TPSAssessmentPersonalitySubtest, type: "up" | "down") => {
        try {
            setMessage("Change Position Assessment CFIT Subtest")
            const cacs = new AssessmentPersonalitySubtest()
            // get switched index
            const switched_index = type === "up" ? data.index_list - 1 : data.index_list + 1;
            // get switched data
            const switched_data = assessment_personality_subtest.find(v => v.index_list === switched_index)
            if (switched_data) {
                // update switched index
                let res = await cacs.update({ ...switched_data, index_list: data.index_list })
                // update data want to swap
                res = await cacs.update({ ...data, index_list: switched_index })
                setNotif({ type: "success", message: res.data.message });
            }
            setMessage("");
            // re-fetch data
            getInitialData();
        } catch (error) {
            setMessage("");
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }

    return (
        <>
            {/* Questionary Form */}
            <Transition
                as={Fragment}
                show={isOpenFormPersonalitySubtest}
                enter="ease-out duration-50"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-50"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div>
                    <FormPersonalitySubtest
                        data={selectedPersonalitySubtest}
                        assessment_id={props.assessment_id}
                        handleClose={() => handleClosePersonalitySubtest()}
                        assessment_personality_subtest={assessment_personality_subtest}
                    />
                </div>
            </Transition>
            {/* Session Modal Session */}
            <Transition
                as={Fragment}
                show={isOpenModalPersonalitySubtestSession}
                enter="ease-out duration-50"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-50"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div>
                    <ModalPersonalitySubtestSession
                        data={selectedPersonalitySubtestSession}
                        assessment_id={props.assessment_id}
                        handleClose={() => handleClosePersonalitySubtestSession()}
                        assessment_personality_subtest={assessment_personality_subtest}
                    />
                </div>
            </Transition>
            {/* Session Modal Session Score */}
            <Transition
                as={Fragment}
                show={isOpenModalPersonalityScore}
                enter="ease-out duration-50"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-50"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div>
                    <ModalPersonalityScore
                        doc={props.doc}
                        assessment_id={props.assessment_id}
                        assessment_personality_subtest={assessment_personality_subtest}
                        handleClose={() => setIsOpenModalPersonalityScore(false)}
                    />
                </div>
            </Transition>
            <div className="border-2 p-3 border-dashed border-t-0 border-red-900 rounded-b">
                {/** Personality Test */}
                <h5 className="font-bold flex pb-2">Personality Test</h5>
                <div className="flex">
                    {props.editable &&
                        <button
                            type="button"
                            onClick={() => { setIsOpenFormPersonalitySubtest(true) }}
                            className="flex items-center bg-green-600 py-1 px-2 text-white rounded">
                            <PlusIcon className="w-4" />
                            <span className="block pl-1">Add Subtest</span>
                        </button>
                    }
                    <button
                        type="button"
                        onClick={() => { setIsOpenModalPersonalityScore(true) }}
                        className="ml-auto flex items-center bg-blue-600 py-1 px-2 text-white rounded">
                        <ClipboardDocumentCheckIcon className="w-4" />
                        <span className="block pl-1">Score</span>
                    </button>
                </div>
                <div className="pt-2 overflow-auto">
                    <table className="w-full border">
                        <thead className="bg-slate-600 text-white">
                            <tr>
                                <th className="py-1 px-2 w-8 border border-slate-500">No</th>
                                <th className="py-1 px-2 text-left border border-slate-500">Subtest</th>
                                {!props.isPsikolog &&
                                    <>
                                        <th className="py-1 px-2 w-20 border border-slate-500">Status</th>
                                        <th className="py-1 px-2 w-20 border border-slate-500">Session</th>
                                    </>
                                }
                                {/** Admin Only - Button Edit & Delete & Move Position */}
                                {isAdmin &&
                                    <>
                                        <th className="py-1 px-2 w-20 border border-slate-500">Edit</th>
                                        <th className="py-1 px-2 w-20 border border-slate-500">Delete</th>
                                        <th className="py-1 px-2 w-10 border border-slate-500">
                                            <div className="flex justify-center"><ArrowsUpDownIcon className="w-4" /></div>
                                        </th>
                                    </>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {assessment_personality_subtest.map((v, i) => (
                                <tr key={`personality_subtest_${i}`}>
                                    <td className="py-1 px-2 text-center border">{v.index_list + 1}</td>
                                    <td className="py-1 px-2 text-left border">{v.personality_subtest_name}</td>
                                    {!props.isPsikolog &&
                                        <>
                                            <td className="py-1 px-2 text-center border">
                                                <button
                                                    type="button"
                                                    className={`px-2 rounded text-white ${v.status === "0" ? "bg-gray-500" : "bg-green-500"}`}
                                                    onClick={() => handleToggleStatusPersonalitySubtest(v)}>
                                                    {v.status === "0" ? "Close" : "Open"}
                                                </button>
                                            </td>
                                            <td className="py-1 px-2 text-center border">
                                                <button
                                                    type="button"
                                                    className="px-2 rounded bg-green-500 text-white"
                                                    onClick={() => setSelectedPersonalitySubtestSession(v)}>
                                                    Session
                                                </button>
                                            </td>
                                        </>
                                    }
                                    {/** Admin Only - Button Edit & Delete & Move Position */}
                                    {isAdmin &&
                                        <>
                                            <td className="py-1 px-2 text-center border">
                                                <button
                                                    type="button"
                                                    className="px-2 rounded bg-blue-500 text-white"
                                                    onClick={() => setSelectedPersonalitySubtest(v)}>
                                                    Edit
                                                </button>
                                            </td>
                                            <td className="py-1 px-2 text-center border">
                                                <button
                                                    type="button"
                                                    className="px-2 rounded bg-red-500 text-white"
                                                    onClick={() => handleDeletePersonalitySubtest(v)}>
                                                    Delete
                                                </button>
                                            </td>
                                            <td className="py-1 px-2 text-center border">
                                                <div className="flex flex-col space-y-1">
                                                    {v.index_list > 0 &&
                                                        <button
                                                            type="button"
                                                            className="px-2 rounded bg-white-500 hover:bg-slate-100 border flex justify-center"
                                                            onClick={() => handleMovePersonalitySubtest(v, "up")}>
                                                            <ChevronUpIcon className="w-3" />
                                                        </button>
                                                    }
                                                    {v.index_list !== (assessment_personality_subtest.length - 1) &&
                                                        <button
                                                            type="button"
                                                            className="px-2 rounded bg-white-500 hover:bg-slate-100 border justify-cente"
                                                            onClick={() => handleMovePersonalitySubtest(v, "down")}>
                                                            <ChevronDownIcon className="w-3" />
                                                        </button>
                                                    }
                                                </div>
                                            </td>
                                        </>
                                    }
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default ListPersonality;