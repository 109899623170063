import { useCallback, useContext, useEffect, useState } from "react";
import * as XLSX from 'xlsx';

// Icons
import { PrinterIcon, XMarkIcon } from "@heroicons/react/24/outline";

// Contexts
import LoaderContext from "../../../../context/psikotest/LoaderContext";
import SnackbarContext from "../../../../context/psikotest/SnackbarContext";

// Helpers
import errorHandler from "../../../../helper/psikotest/errorHandler";

// Controller
import Assessment from "../../../../controller/psikotest/psikotest_system/assessment"

// Variables
const disc_keys = ["D", "I", "S", "C", "*"]

function ModalBehaviouralScore(
    props: {
        doc: TPSAssessment;
        assessment_id: number;
        assessment_behavioural_subtest: TPSAssessmentBehaviouralSubtest[];
        handleClose: () => void;
    }) {
    // Contexts
    const { setMessage } = useContext(LoaderContext);
    const { setNotif } = useContext(SnackbarContext);
    // Variables
    const use_rac = props.assessment_behavioural_subtest.some(aps => aps.behavioural_subtest_name === "RAC")
    const use_disc = props.assessment_behavioural_subtest.some(aps => aps.behavioural_subtest_name === "DISC")
    const use_resilience = props.assessment_behavioural_subtest.some(aps => aps.behavioural_subtest_name === "RESILIENCE")
    // state
    const [behavioural_score, setBehaviouralScore] = useState<TPSAssessmentBehaviouralScore[]>([])

    const getDoc = useCallback(
        async function getDoc() {
            try {
                // fetch behavioural score
                setMessage("Fetch Behavioural Score");
                const ca = new Assessment()
                let res = await ca.behavioural_score(props.assessment_id)
                setBehaviouralScore(res.data.list)
                setMessage("");
            } catch (error) {
                setMessage("");
                const errorMessage = errorHandler(error);
                setNotif({ type: "error", message: errorMessage });
            }
        },
        [setNotif, setMessage, props.assessment_id]
    );

    // initial use effect
    useEffect(() => {
        getDoc();
    }, [getDoc]);

    // Function Helper
    const letterFromNumber = (n: number): string => {
        const ordA = 'a'.charCodeAt(0);
        const ordZ = 'z'.charCodeAt(0);
        const len = ordZ - ordA + 1;

        let s = "";
        while (n >= 0) {
            s = String.fromCharCode(n % len + ordA) + s;
            n = Math.floor(n / len) - 1;
        }
        return s.toUpperCase();
    };
    const downloadExcel = () => {
        // start create excel report
        const workbook = XLSX.utils.book_new()

        let alphabet = "A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z"
        // generated alphabet up to 100 column
        for (let index = 0; index <= 100; index++) {
            const column = alphabet.split(",")[index];
            if (column === undefined) {
                alphabet += `,${letterFromNumber(index)}`
            }
        }

        // RAC
        if (use_rac) {
            // start generate data_rac
            let data_rac = []
            // header
            data_rac.push({})
            // actual data_rac
            behavioural_score.forEach((ps, i) => {
                let composedData: { [key: string]: any } = {
                    No: i + 1,
                    Participant: ps.participant.full_name,
                    Age: ps.age,
                    Gender: ps.participant.gender,
                }
                const ps_result = JSON.parse(ps.rac_score.result)
                composedData["Correct"] = ps_result?.correct ?? ''
                composedData["Wrong"] = ps_result?.wrong ?? ''
                composedData["Empty"] = ps_result?.empty ?? ''
                composedData["Progress"] = `${(JSON.parse(ps.rac_score.answer) ?? []).filter((a: any) => a.value !== "").length}/40`
                composedData["Speed"] = ps.rac_score.kecepatan ?? ''
                composedData["Accuracy"] = ps.rac_score.ketelitian ?? ''
                data_rac.push(composedData)
            })

            // generate worksheet
            const worksheet = XLSX.utils.json_to_sheet(data_rac)

            // start generated excel
            XLSX.utils.book_append_sheet(workbook, worksheet, "RAC")

            // fix headers
            // main header
            let header: string[] = ["No", "Participant", "Age", "Gender"];
            let subheader: string[] = header
            const rac_subtest: string[] = []
            const rac_subtest_subheader: string[] = [];
            // score header
            (["Correct", "Wrong", "Empty"]).forEach((score_type: string) => {
                rac_subtest.push("RAC")
                rac_subtest_subheader.push(score_type ?? "-")
            })
            // rac header
            header = [...header, ...rac_subtest]
            subheader = [...subheader, ...rac_subtest_subheader, "Progress", "Speed", "Accuracy"]

            XLSX.utils.sheet_add_aoa(worksheet, [header, subheader], { origin: "A1" });

            // merge column 
            worksheet["!merges"] = []
            // No
            worksheet["!merges"].push(XLSX.utils.decode_range("A1:A2"));
            // Participant
            worksheet["!merges"].push(XLSX.utils.decode_range("B1:B2"));
            // Age
            worksheet["!merges"].push(XLSX.utils.decode_range("C1:C2"));
            // Gender
            worksheet["!merges"].push(XLSX.utils.decode_range("D1:D2"));
            // RAC Row 1 Header 
            let colStart = 3 + 3
            worksheet["!merges"].push(XLSX.utils.decode_range(`E1:${letterFromNumber(colStart)}1`));
            // Progress
            colStart++
            let currAlphabet = alphabet.split(",")[colStart]
            worksheet["!merges"].push(XLSX.utils.decode_range(`${currAlphabet}1:${currAlphabet}2`));
            // Speed
            colStart++
            currAlphabet = alphabet.split(",")[colStart]
            worksheet["!merges"].push(XLSX.utils.decode_range(`${currAlphabet}1:${currAlphabet}2`));
            // Accuracy
            colStart++
            currAlphabet = alphabet.split(",")[colStart]
            worksheet["!merges"].push(XLSX.utils.decode_range(`${currAlphabet}1:${currAlphabet}2`));

            // set width
            let wscols = [
                { width: 10 },
                { width: 30 }
            ];
            worksheet["!cols"] = wscols;
        }

        // DISC
        if (use_disc) {
            // start generate data_disc
            let data_disc = []
            // header
            data_disc.push({})
            data_disc.push({})
            // actual data_disc
            behavioural_score.forEach((ps, i) => {
                let composedData: { [key: string]: any } = {
                    No: i + 1,
                    Participant: ps.participant.full_name,
                    Age: ps.age,
                    Gender: ps.participant.gender,
                }
                const ps_result = JSON.parse(ps.disc_score.result)
                disc_keys.forEach((key, i) => {
                    composedData[`M_${key}`] = ps_result?.actual?.most[key] ?? ''
                })
                disc_keys.forEach((key, i) => {
                    composedData[`L_${key}`] = ps_result?.actual?.least[key] ?? ''
                })
                disc_keys.forEach((key, i) => {
                    composedData[`C_${key}`] = ps_result?.actual?.change[key] ?? ''
                })
                composedData["Control"] = `${sumDisc(ps_result?.actual)}/48`
                data_disc.push(composedData)
            })

            // generate worksheet
            const worksheet = XLSX.utils.json_to_sheet(data_disc)

            // start generated excel
            XLSX.utils.book_append_sheet(workbook, worksheet, "DISC")

            // fix headers
            // main header
            let header: string[] = ["No", "Participant", "Age", "Gender"];
            let subheader: string[] = header
            let subheaderdisc: string[] = header
            const _header: string[] = []
            const disc_subtest: string[] = []
            const disc_subtest_subheader: string[] = [];
            // score header
            disc_keys.forEach((key, i) => {
                _header.push("DISC")
                disc_subtest.push("Most")
                disc_subtest_subheader.push(key)
            })
            disc_keys.forEach((key, i) => {
                _header.push("DISC")
                disc_subtest.push("Least")
                disc_subtest_subheader.push(key)
            })
            disc_keys.forEach((key, i) => {
                _header.push("DISC")
                disc_subtest.push("Change")
                disc_subtest_subheader.push(key)
            })
            // rac header
            header = [...header, ..._header]
            subheader = [...subheader, ...disc_subtest, "Control"]
            subheaderdisc = [...subheaderdisc, ...disc_subtest_subheader, "Control"]

            XLSX.utils.sheet_add_aoa(worksheet, [header, subheader, subheaderdisc], { origin: "A1" });

            // merge column 
            worksheet["!merges"] = []
            // No
            worksheet["!merges"].push(XLSX.utils.decode_range("A1:A3"));
            // Participant
            worksheet["!merges"].push(XLSX.utils.decode_range("B1:B3"));
            // Age
            worksheet["!merges"].push(XLSX.utils.decode_range("C1:C3"));
            // Gender
            worksheet["!merges"].push(XLSX.utils.decode_range("D1:D3"));
            // DISC Row 1 Header 
            let colStart1 = 3 + 15
            worksheet["!merges"].push(XLSX.utils.decode_range(`E1:${letterFromNumber(colStart1)}1`));
            // Control
            colStart1++
            let currAlphabet = alphabet.split(",")[colStart1]
            worksheet["!merges"].push(XLSX.utils.decode_range(`${currAlphabet}1:${currAlphabet}3`));
            // DISC Row 2 Header 
            // Most
            let colStart2 = 3 + 5
            worksheet["!merges"].push(XLSX.utils.decode_range(`E2:${letterFromNumber(colStart2)}2`));
            // Least
            colStart2++
            currAlphabet = alphabet.split(",")[colStart2]
            colStart2 += 4
            let lastCurrAlphabet = alphabet.split(",")[colStart2]
            worksheet["!merges"].push(XLSX.utils.decode_range(`${currAlphabet}2:${lastCurrAlphabet}2`));
            // Change
            colStart2++
            currAlphabet = alphabet.split(",")[colStart2]
            colStart2 += 4
            lastCurrAlphabet = alphabet.split(",")[colStart2]
            worksheet["!merges"].push(XLSX.utils.decode_range(`${currAlphabet}2:${lastCurrAlphabet}2`));

            // set width
            let wscols = [
                { width: 10 },
                { width: 30 }
            ];
            worksheet["!cols"] = wscols;
        }

        // RESILIENCE
        if (use_resilience) {
            // start generate data_resilience
            let data_resilience = []
            // header
            data_resilience.push({})
            data_resilience.push({})
            // actual data_resilience
            behavioural_score.forEach((ps, i) => {
                let composedData: { [key: string]: any } = {
                    No: i + 1,
                    Participant: ps.participant.full_name,
                    Age: ps.age,
                    Gender: ps.participant.gender,
                }
                const ps_result = JSON.parse(ps.resilience_score.result)
                composedData["Total Answer"] = (ps_result?.total_answer ? ps_result.total_answer : 0) as number
                (ps_result?.score_group ?? []).forEach((sg: TPSResilienceScoreGroupResult, i: number) => {
                    composedData['time_' + i.toString()] = sg.total_answer
                })
                composedData["Average"] = parseFloat(ps_result?.average_speed ?? 0).toFixed(1)
                composedData["Incorrect"] = ps_result?.total_incorrect
                composedData["Diff LowHigh-est"] = ps_result?.difference_lowest_highest
                data_resilience.push(composedData)
            })

            // generate worksheet
            const worksheet = XLSX.utils.json_to_sheet(data_resilience)

            // start generated excel
            XLSX.utils.book_append_sheet(workbook, worksheet, "RESILIENCE")

            // fix headers
            // main header
            let header: string[] = ["No", "Participant", "Age", "Gender"];
            let subheader: string[] = header
            let subheaderresilience: string[] = header
            const _header: string[] = []
            const resilience_subtest: string[] = []
            const resilience_subtest_subheader: string[] = [];
            // score header
            Array(40).fill(0).forEach((key, i) => {
                _header.push("RESILIENCE")
                resilience_subtest.push("Total Answer (per 30 Seconds)")
                resilience_subtest_subheader.push(((i + 1) * 30).toString())
            })
            // score header
            // rac header
            header = [...header, ..._header]
            subheader = [...subheader, "Total Answer", ...resilience_subtest, "Average", "Incorrect", "Diff LowHigh-est"]
            subheaderresilience = [...subheaderresilience, "Total Answer", ...resilience_subtest_subheader, "Average", "Incorrect", "Diff LowHigh-est"]

            XLSX.utils.sheet_add_aoa(worksheet, [header, subheader, subheaderresilience], { origin: "A1" });

            // merge column 
            worksheet["!merges"] = []
            // No
            worksheet["!merges"].push(XLSX.utils.decode_range("A1:A3"));
            // Participant
            worksheet["!merges"].push(XLSX.utils.decode_range("B1:B3"));
            // Age
            worksheet["!merges"].push(XLSX.utils.decode_range("C1:C3"));
            // Gender
            worksheet["!merges"].push(XLSX.utils.decode_range("D1:D3"));
            // RESILIENCE Row 1 Header 
            let colStart1 = 3 + 40 + 4
            worksheet["!merges"].push(XLSX.utils.decode_range(`E1:${letterFromNumber(colStart1)}1`));
            // RESILIENCE Row 2 Header 
            // Total Answer
            worksheet["!merges"].push(XLSX.utils.decode_range(`E2:E3`));
            // Group Answer (per 30 Seconds)
            let colStart2 = 4 + 40
            worksheet["!merges"].push(XLSX.utils.decode_range(`F2:${letterFromNumber(colStart2)}2`));
            colStart2++
            // Total Answer
            worksheet["!merges"].push(XLSX.utils.decode_range(`${letterFromNumber(colStart2)}2:${letterFromNumber(colStart2)}3`));
            colStart2++
            // Incorrect
            worksheet["!merges"].push(XLSX.utils.decode_range(`${letterFromNumber(colStart2)}2:${letterFromNumber(colStart2)}3`));
            colStart2++
            // Diff LowHigh-est
            worksheet["!merges"].push(XLSX.utils.decode_range(`${letterFromNumber(colStart2)}2:${letterFromNumber(colStart2)}3`));

            // set width
            let wscols = [
                { width: 10 },
                { width: 30 },
                { width: 10 },
                { width: 10 },
                { width: 15 },
            ];
            // width each question
            Array(40).fill(0).forEach(_ => {
                wscols.push({ width: 6 })
            });
            // width answer
            wscols.push({ width: 10 })
            wscols.push({ width: 10 })
            wscols.push({ width: 15 })

            worksheet["!cols"] = wscols;
        }

        // create an XLSX file and try to save to .xlsx
        XLSX.writeFile(workbook, `${props.doc.assessment_title} - Behavioural Result Report.xlsx`)
    };
    const sumDisc = (disc: any) => {
        const result_most: number[] = Object.values(disc?.most ?? {})
        const sum_most = result_most.reduce((a, b) => a + b, 0)
        const result_least: number[] = Object.values(disc?.least ?? {})
        const sum_least = result_least.reduce((a, b) => a + b, 0)
        return sum_most + sum_least
    }

    return <div>
        <div className="fixed z-20 top-0 left-0 w-screen h-screen bg-black opacity-20"></div>
        <div className="fixed z-30 top-0 left-0 w-screen h-screen bg-transparent flex items-center justify-center">
            <div className="flex flex-col bg-white w-screen md:w-[90%] h-[90%] md:rounded">
                <div className="p-4 bg-blue-800 md:rounded-t text-white flex justify-between">
                    <h3>Behavioural Result Report</h3>
                    <XMarkIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent cursor-pointer" aria-hidden="true" onClick={() => props.handleClose()} />
                </div>
                <div className="flex p-2">
                    <button
                        type="button"
                        onClick={() => downloadExcel()}
                        className="ml-auto flex items-center bg-green-600 py-1 px-2 text-white rounded">
                        <PrinterIcon className="w-4" />
                        <span className="block pl-1">Print</span>
                    </button>
                </div>
                <div className="h-full p-2 pb-10">
                    <div className="h-[87%] overflow-auto">
                        <table className="w-full border-separate border-spacing-0">
                            <thead className="bg-slate-600 text-white whitespace-nowrap sticky top-[0px] z-10">
                                <tr>
                                    <th className="py-1 px-2 min-w-[2.5rem] border sticky left-[0px] bg-slate-600 border-slate-500" rowSpan={3}>No</th>
                                    <th className="py-1 px-2 min-w-[12rem] text-left border sticky left-[40px] bg-slate-600 border-slate-500" rowSpan={3}>Participant</th>
                                    <th className="py-1 px-2 min-w-[4rem] text-center border sticky left-[232px] bg-slate-600 border-slate-500" rowSpan={3}>Age</th>
                                    <th className="py-1 px-2 min-w-[4.5rem] text-center border sticky left-[296px] bg-slate-600 border-slate-500" rowSpan={3}>Gender</th>
                                    {use_rac &&
                                        <th className="py-1 px-2 text-center border border-slate-500" colSpan={6}>RAC</th>
                                    }
                                    {use_disc &&
                                        <th className="py-1 px-2 text-center border border-slate-500" colSpan={16}>DISC</th>
                                    }
                                    {use_resilience &&
                                        <th className="py-1 px-2 text-center border border-slate-500" colSpan={44}>RESILIENCE</th>
                                    }
                                </tr>
                                <tr>
                                    {use_rac &&
                                        <>
                                            <th className="py-1 px-2 w-20 border border-slate-500" rowSpan={2}>Correct</th>
                                            <th className="py-1 px-2 w-20 border border-slate-500" rowSpan={2}>Wrong</th>
                                            <th className="py-1 px-2 w-20 border border-slate-500" rowSpan={2}>Empty</th>
                                            <th className="py-1 px-2 w-20 border border-slate-500" rowSpan={2}>Progress</th>
                                            <th className="py-1 px-2 w-20 border border-slate-500" rowSpan={2}>Speed</th>
                                            <th className="py-1 px-2 w-20 border border-slate-500" rowSpan={2}>Accuracy</th>
                                        </>
                                    }
                                    {use_disc &&
                                        <>
                                            <th className="py-1 px-2 w-20 border border-slate-500" colSpan={5}>Most</th>
                                            <th className="py-1 px-2 w-20 border border-slate-500" colSpan={5}>Least</th>
                                            <th className="py-1 px-2 w-20 border border-slate-500" colSpan={5}>Change</th>
                                            <th className="py-1 px-2 w-20 border border-slate-500" rowSpan={2}>Control</th>
                                        </>
                                    }
                                    {use_resilience &&
                                        <>
                                            <th className="py-1 px-2 w-20 border border-slate-500" rowSpan={2}>Total Answer</th>
                                            <th className="py-1 px-2 w-20 border border-slate-500" colSpan={40}>Total Answer (per 30 Seconds)</th>
                                            <th className="py-1 px-2 w-20 border border-slate-500" rowSpan={2}>Average</th>
                                            <th className="py-1 px-2 w-20 border border-slate-500" rowSpan={2}>Incorrect</th>
                                            <th className="py-1 px-2 w-20 border border-slate-500" rowSpan={2}>Diff LowHigh-est</th>
                                        </>
                                    }
                                </tr>
                                <tr>
                                    {use_disc &&
                                        <>
                                            <th className="py-1 px-2 w-20 border border-slate-500">D</th>
                                            <th className="py-1 px-2 w-20 border border-slate-500">I</th>
                                            <th className="py-1 px-2 w-20 border border-slate-500">S</th>
                                            <th className="py-1 px-2 w-20 border border-slate-500">C</th>
                                            <th className="py-1 px-2 w-20 border border-slate-500">*</th>
                                            <th className="py-1 px-2 w-20 border border-slate-500">D</th>
                                            <th className="py-1 px-2 w-20 border border-slate-500">I</th>
                                            <th className="py-1 px-2 w-20 border border-slate-500">S</th>
                                            <th className="py-1 px-2 w-20 border border-slate-500">C</th>
                                            <th className="py-1 px-2 w-20 border border-slate-500">*</th>
                                            <th className="py-1 px-2 w-20 border border-slate-500">D</th>
                                            <th className="py-1 px-2 w-20 border border-slate-500">I</th>
                                            <th className="py-1 px-2 w-20 border border-slate-500">S</th>
                                            <th className="py-1 px-2 w-20 border border-slate-500">C</th>
                                            <th className="py-1 px-2 w-20 border border-slate-500">*</th>
                                        </>
                                    }
                                    {use_resilience &&
                                        <>
                                            {Array(40).fill(0).map((_, i) => (
                                                <th className="py-1 px-2 w-20 border border-slate-500" key={i}>
                                                    {(i + 1) * 30}
                                                </th>
                                            ))}
                                        </>
                                    }
                                </tr>
                            </thead>
                            <tbody className="">
                                {behavioural_score.map((ps, i) => {
                                    return (
                                        <tr key={`p_score_${i} `}>
                                            <td className="py-1 px-2 border text-center sticky left-[0px] bg-white">{i + 1}</td>
                                            <td className="py-1 px-2 border sticky left-[40px] bg-white">{ps.participant.full_name}</td>
                                            <td className="py-1 px-2 border text-center sticky left-[232px] bg-white">{ps.age}</td>
                                            <td className="py-1 px-2 border text-center sticky left-[296px] bg-white">{ps.participant.gender}</td>
                                            {use_rac &&
                                                <>
                                                    <td className="py-1 px-2 border text-center font-semibold">{JSON.parse(ps.rac_score.result)?.correct}</td>
                                                    <td className="py-1 px-2 border text-center font-semibold">{JSON.parse(ps.rac_score.result)?.wrong}</td>
                                                    <td className="py-1 px-2 border text-center font-semibold">{JSON.parse(ps.rac_score.result)?.empty}</td>
                                                    <td className={
                                                        `py-1 px-2 border text-center font-semibold
                                                        ${(JSON.parse(ps.rac_score.answer) ?? []).filter((a: any) => a.value !== "").length === 40 ? "bg-green-200" : "bg-red-200"}`}>
                                                        {(JSON.parse(ps.rac_score.answer) ?? []).filter((a: any) => a.value !== "").length}/40
                                                    </td>
                                                    <td className="py-1 px-2 border text-center font-semibold">{ps.rac_score.kecepatan}</td>
                                                    <td className="py-1 px-2 border text-center font-semibold">{ps.rac_score.ketelitian}</td>
                                                </>
                                            }
                                            {use_disc &&
                                                <>
                                                    {disc_keys.map((key, i) => (
                                                        <td key={`am_${i}`} className="py-1 px-2 border text-center font-semibold">
                                                            <span>{`${JSON.parse(ps.disc_score.result)?.actual?.most[key] ?? ''}`}</span>
                                                        </td>
                                                    ))}
                                                    {disc_keys.map((key, i) => (
                                                        <td key={`am_${i}`} className="py-1 px-2 border text-center font-semibold bg-slate-100">
                                                            <span>{`${JSON.parse(ps.disc_score.result)?.actual?.least[key] ?? ''}`}</span>
                                                        </td>
                                                    ))}
                                                    {disc_keys.map((key, i) => (
                                                        <td key={`am_${i}`} className="py-1 px-2 border text-center font-semibold">
                                                            <span>{`${JSON.parse(ps.disc_score.result)?.actual?.change[key] ?? ''}`}</span>
                                                        </td>
                                                    ))}
                                                    <td className={`py-1 px-2 border text-center font-semibold ${sumDisc(JSON.parse(ps.disc_score.result)?.actual) === 48 ? "bg-green-200" : "bg-red-200"}`}>
                                                        <span>
                                                            {sumDisc(JSON.parse(ps.disc_score.result)?.actual)}/48
                                                        </span>
                                                    </td>
                                                </>
                                            }
                                            {use_resilience &&
                                                <>
                                                    <td className={`py-1 px-2 border text-center font-semibold`}>
                                                        <span>
                                                            {JSON.parse(ps.resilience_score.result)?.total_answer ?? 0}
                                                        </span>
                                                    </td>
                                                    {(JSON.parse(ps.resilience_score.result)?.score_group ?? Array(40).fill(0)).map((sg: TPSResilienceScoreGroupResult, i: number) => (
                                                        <td className={`py-1 px-2 border text-center font-semibold`} key={i}>
                                                            <span>
                                                                {sg.total_answer}
                                                            </span>
                                                        </td>
                                                    ))}
                                                    <td className={`py-1 px-2 border text-center font-semibold`}>
                                                        <span>
                                                            {parseFloat(JSON.parse(ps.resilience_score.result)?.average_speed ?? 0).toFixed(1)}
                                                        </span>
                                                    </td>
                                                    <td className={`py-1 px-2 border text-center font-semibold`}>
                                                        <span>
                                                            {JSON.parse(ps.resilience_score.result)?.total_incorrect ?? 0}
                                                        </span>
                                                    </td>
                                                    <td className={`py-1 px-2 border text-center font-semibold`}>
                                                        <span>
                                                            {JSON.parse(ps.resilience_score.result)?.difference_lowest_highest ?? 0}
                                                        </span>
                                                    </td>
                                                </>
                                            }
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div >
}

export default ModalBehaviouralScore;