import { useCallback, useContext, useEffect, useState } from "react";

// Icons
import { CheckCircleIcon, ClockIcon } from "@heroicons/react/24/outline";

// Context
import SnackbarContext from "../../../context/psikotest/SnackbarContext";

// Handler
import errorHandler from "../../../helper/psikotest/errorHandler";

// Controller
import ParticipantSession from "../../../controller/psikotest/psikotest_participant/session";

// Helper Value
const valueList = ['ACH', 'DEF', 'ORD', 'EXH', 'AUT', 'AFF', 'INT', 'SUC', 'DOM', 'ABA', 'NUR', 'CHG', 'END', 'HET', 'AGG'];

function SubtestEpps(props: { assessment_participant_session: TPPAssessmentParticipantSession, closeSession: () => void }) {
    // Context
    const { setNotif } = useContext(SnackbarContext);

    // state
    const [epps_questionary, setEppsQuestionary] = useState<TPSEppsQuestionary[]>([])
    const [epps_questionary_answer, setEppsQuestionaryAnswer] = useState<TPSAssessmentParticipantEppsSessionAnswer>()
    const [answer_questionary, setAnswerQuestionary] = useState<{ answer: string; value: string }[]>([])

    // get doc
    const getDoc = useCallback(async () => {
        try {
            // get all question in subtest id
            const psc = new ParticipantSession();
            let resQuestionaryEpps = await psc.getQuestionaryEpps();
            setEppsQuestionary(resQuestionaryEpps.data.list)
            let resQuestionaryAnswerEpps = await psc.getQuestionaryAnswerEpps(props.assessment_participant_session.id)
            setEppsQuestionaryAnswer(resQuestionaryAnswerEpps.data.row)
            // set answer questionary
            if ((JSON.parse(resQuestionaryAnswerEpps.data.row.answer)).length === 0) {
                // create empty object with same length of epps questionary
                setAnswerQuestionary(resQuestionaryEpps.data.list.map((_: any) => { return { answer: "", value: "" } }))
            } else {
                setAnswerQuestionary(JSON.parse(resQuestionaryAnswerEpps.data.row.answer))
            }
        } catch (error) {
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }, [setNotif, props.assessment_participant_session.id]);

    useEffect(() => {
        getDoc();
    }, [getDoc]);

    // track epps_questionary
    useEffect(() => {
        //console.log(epps_questionary)
    }, [epps_questionary]);

    // track answer_questionary
    useEffect(() => {
        //console.log(answer_questionary)
    }, [answer_questionary]);

    // handleAnswer
    const handleAnswer = async (index: number, answer: string, point: string) => {
        try {
            // set answer
            // copy answer
            const c_answer: { answer: string; value: string }[] = [...answer_questionary]
            c_answer.splice(index, 1, { answer, value: point })
            // set result point
            const c_result_point: { [key: string]: number } = {}
            valueList.forEach(v => {
                return c_result_point[v] = c_answer.filter(c => c.value === v).length
            })
            // set consistency point
            const c_consistency_point: { [key: string]: number } = {}
            for (let i = 1; i <= 15; i++) {
                const key = `pair_${i}`
                // get pair questionary
                const pair_questionary = epps_questionary.filter(q => q.pair_key === key)
                // get index each pair
                if (pair_questionary.length === 2) {
                    const pair_index = [
                        epps_questionary.findIndex(cq => cq.id === pair_questionary?.[0]?.id),
                        epps_questionary.findIndex(cq => cq.id === pair_questionary?.[1]?.id)
                    ]
                    c_consistency_point[key] = (c_answer[pair_index[0]].answer !== "" && c_answer[pair_index[1]].answer !== "" && c_answer[pair_index[0]].answer === c_answer[pair_index[1]].answer) ? 1 : 0
                }
            }
            // save answer
            if (epps_questionary_answer) {
                const psc = new ParticipantSession();
                await psc.saveQuestionaryAnswerEpps(props.assessment_participant_session.id, {
                    ...epps_questionary_answer,
                    answer: JSON.stringify(c_answer),
                    result: JSON.stringify(c_result_point),
                    consistency: JSON.stringify(c_consistency_point)
                });
                setAnswerQuestionary(c_answer)
            }
        } catch (error) {
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }

    return (
        <div className="h-[94%] overflow-auto">
            <div className="flex flex-col items-center">
                <div className="text-sm md:text-base max-w-5xl text-center py-3">
                    Pilihkan kalimat yang paling menggambarkan diri anda dengan <b>mengklik</b> pada pilihan anda.<br />
                    Anda <b>HARUS</b> memilih salah satu yang dominan serta mengisi semua nomor.
                </div>
            </div>
            <div className="text-sm md:text-base border-b flex flex-col items-center pb-20">
                <div className="max-w-5xl">
                    <div className="">
                        <div className="flex w-full bg-slate-800 text-white font-semibold">
                            <div className="w-1/12 text-center flex justify-center items-center border p-1">No</div>
                            <div className={`w-5/12 text-center flex justify-center items-center border p-1 cursor-pointer`}>
                                Pernyataan A
                            </div>
                            <div className={`w-5/12 text-center flex justify-center items-center border p-1 cursor-pointer`}>
                                Pernyataan B
                            </div>
                            <div className="w-1/12 text-center flex justify-center items-center border p-1">Status</div>
                        </div>
                    </div>
                    {epps_questionary.map((epps_q, index) => (
                        <div key={index} className="">
                            <div className="flex w-full">
                                <div className="w-1/12 text-center flex justify-center items-center border p-1">{epps_q.number}</div>
                                <div
                                    className={`
                                        w-5/12 text-center flex justify-center items-center border p-1 cursor-pointer hover:bg-blue-200 hover:font-semibold hover:text-black
                                        ${answer_questionary?.[index]?.answer === "A" ? "bg-green-200" : ""}
                                        ${answer_questionary?.[index]?.answer !== "A" && answer_questionary?.[index]?.answer === "B" ? "bg-slate-100 text-gray-400" : ""}
                                    `}
                                    onClick={() => handleAnswer(index, "A", epps_q.statement_a_value)}>
                                    {epps_q.statement_a}
                                </div>
                                <div
                                    className={`
                                        w-5/12 text-center flex justify-center items-center border p-1 cursor-pointer hover:bg-blue-200 hover:font-semibold hover:text-black
                                        ${answer_questionary?.[index]?.answer === "B" ? "bg-green-200" : ""}
                                        ${answer_questionary?.[index]?.answer !== "B" && answer_questionary?.[index]?.answer === "A" ? "bg-slate-100 text-gray-400" : ""}
                                    `}
                                    onClick={() => handleAnswer(index, "B", epps_q.statement_b_value)}>
                                    {epps_q.statement_b}
                                </div>
                                <div className={`w-1/12 text-center flex justify-center items-center border p-1 ${answer_questionary?.[index]?.answer !== "" ? "bg-green-100" : ""}`}>
                                    {answer_questionary?.[index]?.answer !== ""
                                        ? <CheckCircleIcon className="w-5 text-green-600" />
                                        : <ClockIcon className="w-5 text-gray-400" />
                                    }
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="max-w-5xl w-full mt-3 flex justify-between items-center">
                    <div className="pl-2 md:pl-6 font-semibold">
                        {answer_questionary.filter(aq => aq.answer !== "").length}/{epps_questionary.length}
                    </div>
                    {answer_questionary.filter(aq => aq.answer !== "").length !== epps_questionary.length &&
                        <div className="px-3 text-red-500">Tombol <b>Finish</b> tersedia saat semua pernyataan telah dipilih</div>
                    }
                    <button
                        type="button"
                        className={`mx-3 md:mx-0 py-1 px-6 min-w-[80px] text-center cursor-pointer rounded text-white bg-red-500 hover:bg-red-600 disabled:bg-gray-300`}
                        onClick={() => props.closeSession()}
                        disabled={answer_questionary.filter(aq => aq.answer !== "").length !== epps_questionary.length}>
                        Finish
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SubtestEpps;
