import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { useCallback, useContext, useEffect } from "react";

// Icons
import { XMarkIcon, ArrowDownOnSquareIcon } from "@heroicons/react/24/outline";

// Contexts
import LoaderContext from "../../../../context/psikotest/LoaderContext";
import SnackbarContext from "../../../../context/psikotest/SnackbarContext";

// Helpers
import errorHandler from "../../../../helper/psikotest/errorHandler";

// Controller
import RacFormulaNorma from "../../../../controller/psikotest/psikotest_system/rac_formula_norma";

const schema_base = {
    id: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required(),
    value: yup
        .string()
        .label("Value (Norma)")
        .transform((value) => (value === null ? "" : value))
        .required(),
    range_min: yup
        .number()
        .label("Min (Range)")
        .transform((value) => (isNaN(value) ? undefined : value))
        .required(),
    range_max: yup
        .number()
        .label("Max (Range)")
        .transform((value) => (isNaN(value) ? undefined : value))
        .required(),
    rendah_min: yup
        .number()
        .label("Rendah (Min)")
        .transform((value) => (isNaN(value) ? null : value))
        .nullable(),
    rendah_max: yup
        .number()
        .label("Rendah (Max)")
        .transform((value) => (isNaN(value) ? null : value))
        .nullable(),
    kurang_min: yup
        .number()
        .label("Kurang (Min)")
        .transform((value) => (isNaN(value) ? null : value))
        .nullable(),
    kurang_max: yup
        .number()
        .label("Kurang (Max)")
        .transform((value) => (isNaN(value) ? null : value))
        .nullable(),
    cukup_min: yup
        .number()
        .label("Cukup (Min)")
        .transform((value) => (isNaN(value) ? null : value))
        .nullable(),
    cukup_max: yup
        .number()
        .label("Cukup (Max)")
        .transform((value) => (isNaN(value) ? null : value))
        .nullable(),
    baik_min: yup
        .number()
        .label("Baik (Min)")
        .transform((value) => (isNaN(value) ? null : value))
        .nullable(),
    baik_max: yup
        .number()
        .label("Baik (Max)")
        .transform((value) => (isNaN(value) ? null : value))
        .nullable(),
}

function FormQuestionary(props: { data?: TPSRacFormulaNorma, nextNumber?: number, handleClose: () => void }) {
    // Contexts
    const { setMessage } = useContext(LoaderContext)
    const { setNotif } = useContext(SnackbarContext)

    // set schema
    let schema: any = yup.object().shape(schema_base)

    // react hook form
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch
    } = useForm<TPSRacFormulaNorma>({ resolver: yupResolver(schema) });
    // watch all field
    const doc = watch()

    const getDoc = useCallback(
        async function getDoc() {
            try {
                setMessage("Fetch RAC Questionary");
                // fetch document
                if (props.data === undefined) {
                    setValue("id", 0);
                    setValue("value", "");
                    setValue("range_min", 0);
                    setValue("range_max", 0);
                    setValue("rendah_min", null);
                    setValue("rendah_max", null);
                    setValue("kurang_min", null);
                    setValue("kurang_max", null);
                    setValue("cukup_min", null);
                    setValue("cukup_max", null);
                    setValue("baik_min", null);
                    setValue("baik_max", null);
                } else {
                    setValue("id", props.data?.id ?? 0);
                    setValue("value", props.data?.value ?? 0);
                    setValue("range_min", props.data?.range_min ?? 0);
                    setValue("range_max", props.data?.range_max ?? 0);
                    setValue("rendah_min", props.data?.rendah_min ?? null);
                    setValue("rendah_max", props.data?.rendah_max ?? null);
                    setValue("kurang_min", props.data?.kurang_min ?? null);
                    setValue("kurang_max", props.data?.kurang_max ?? null);
                    setValue("cukup_min", props.data?.cukup_min ?? null);
                    setValue("cukup_max", props.data?.cukup_max ?? null);
                    setValue("baik_min", props.data?.baik_min ?? null);
                    setValue("baik_max", props.data?.baik_max ?? null);
                }
                setMessage("");
            } catch (error) {
                setMessage("");
                const errorMessage = errorHandler(error);
                setNotif({ type: "error", message: errorMessage });
            }
        },
        [setValue, setNotif, setMessage, props.data]
    );

    useEffect(() => {
        getDoc();
    }, [getDoc]);

    const onSubmit: SubmitHandler<TPSRacFormulaNorma> = async (data) => {
        try {
            setMessage("Save Rac Questionary");
            const rac_fnc = new RacFormulaNorma();
            let res;
            if (doc.id === 0) {
                res = await rac_fnc.create(data);
            } else {
                res = await rac_fnc.update(data.id, data);
            }
            setNotif({ type: "success", message: res.data.message });
            setMessage("");
            props.handleClose();
        } catch (error) {
            setMessage("");
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    };

    return <div>
        <div className="fixed z-20 top-0 left-0 w-screen h-screen bg-black opacity-20"></div>
        <div className="fixed z-30 top-0 left-0 w-screen h-screen bg-transparent flex items-center justify-center">
            <div className="bg-white h-screen w-screen md:h-auto md:w-[60%] md:rounded overflow-auto">
                <div className="p-4 bg-green-800 md:rounded-t text-white flex justify-between">
                    <h3>Form Questionary</h3>
                    <XMarkIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent cursor-pointer" aria-hidden="true" onClick={() => props.handleClose()} />
                </div>
                <div className="p-4 pb-40">
                    <form className="w-full">
                        <div className="flex flex-wrap -mx-3">
                            {/** ID */}
                            <input {...register("id")} type="number" className="hidden" />
                            {errors.id && (
                                <p className="text-red-500 text-xs italic">{errors.id.message}</p>
                            )}
                            <div className="w-full px-3 mb-3 block text-center italic tracking-wide text-blue-700 font-bold">
                                Speed
                            </div>
                            {/** Value (Norma) */}
                            <div className="w-full px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Value (Norma)</label>
                                <input
                                    className={
                                        (errors?.value ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                                        " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                                    }
                                    {...register("value")}
                                />
                                {errors.value && <p className="text-red-500 text-xs italic">{errors.value.message}</p>}
                            </div>
                            {/** Min (Range) */}
                            <div className="w-1/2 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Min (Range)
                                </label>
                                <input
                                    type="number"
                                    className={
                                        (errors?.value ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                                        " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                                    }
                                    {...register("range_min")}
                                />
                                {errors.range_min && <p className="text-red-500 text-xs italic">{errors.range_min.message}</p>}
                            </div>
                            {/** Max (Range) */}
                            <div className="w-1/2 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Max (Range)
                                </label>
                                <input
                                    type="number"
                                    className={
                                        (errors?.value ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                                        " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                                    }
                                    {...register("range_max")}
                                />
                                {errors.range_max && <p className="text-red-500 text-xs italic">{errors.range_max.message}</p>}
                            </div>
                            <div className="w-full px-3 mb-6 block text-center italic tracking-wide text-blue-700 font-bold">
                                Accuracy
                            </div>
                            {/** Rendah (Min) */}
                            <div className="w-1/2 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Rendah (Min)
                                </label>
                                <input
                                    type="number"
                                    className={
                                        (errors?.value ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                                        " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                                    }
                                    {...register("rendah_min")}
                                />
                                {errors.rendah_min && <p className="text-red-500 text-xs italic">{errors.rendah_min.message}</p>}
                            </div>
                            {/** Rendah (Max) */}
                            <div className="w-1/2 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Rendah (Max)
                                </label>
                                <input
                                    type="number"
                                    className={
                                        (errors?.value ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                                        " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                                    }
                                    {...register("rendah_max")}
                                />
                                {errors.rendah_max && <p className="text-red-500 text-xs italic">{errors.rendah_max.message}</p>}
                            </div>
                            {/** Kurang (Min) */}
                            <div className="w-1/2 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Kurang (Min)
                                </label>
                                <input
                                    type="number"
                                    className={
                                        (errors?.value ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                                        " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                                    }
                                    {...register("kurang_min")}
                                />
                                {errors.kurang_min && <p className="text-red-500 text-xs italic">{errors.kurang_min.message}</p>}
                            </div>
                            {/** Kurang (Max) */}
                            <div className="w-1/2 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Kurang (Max)
                                </label>
                                <input
                                    type="number"
                                    className={
                                        (errors?.value ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                                        " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                                    }
                                    {...register("kurang_max")}
                                />
                                {errors.kurang_max && <p className="text-red-500 text-xs italic">{errors.kurang_max.message}</p>}
                            </div>
                            {/** Cukup (Min) */}
                            <div className="w-1/2 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Cukup (Min)
                                </label>
                                <input
                                    type="number"
                                    className={
                                        (errors?.value ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                                        " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                                    }
                                    {...register("cukup_min")}
                                />
                                {errors.cukup_min && <p className="text-red-500 text-xs italic">{errors.cukup_min.message}</p>}
                            </div>
                            {/** Cukup (Max) */}
                            <div className="w-1/2 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Cukup (Max)
                                </label>
                                <input
                                    type="number"
                                    className={
                                        (errors?.value ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                                        " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                                    }
                                    {...register("cukup_max")}
                                />
                                {errors.cukup_max && <p className="text-red-500 text-xs italic">{errors.cukup_max.message}</p>}
                            </div>
                            {/** Baik (Min) */}
                            <div className="w-1/2 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Baik (Min)
                                </label>
                                <input
                                    type="number"
                                    className={
                                        (errors?.value ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                                        " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                                    }
                                    {...register("baik_min")}
                                />
                                {errors.baik_min && <p className="text-red-500 text-xs italic">{errors.baik_min.message}</p>}
                            </div>
                            {/** Baik (Max) */}
                            <div className="w-1/2 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Baik (Max)
                                </label>
                                <input
                                    type="number"
                                    className={
                                        (errors?.value ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                                        " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                                    }
                                    {...register("baik_max")}
                                />
                                {errors.baik_max && <p className="text-red-500 text-xs italic">{errors.baik_max.message}</p>}
                            </div>
                        </div>
                    </form>
                    <div className="flex">
                        <button
                            onClick={handleSubmit(onSubmit)}
                            className="ml-auto block w-auto px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded font-semibold text-sm"
                        >
                            <div className="flex">
                                <ArrowDownOnSquareIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
                                Save
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div >
    </div >
}


export default FormQuestionary;