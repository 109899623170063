import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { read, utils, writeFile } from 'xlsx';

// Icons
import { ArrowDownTrayIcon, ClipboardDocumentListIcon, PaperAirplaneIcon, XMarkIcon } from "@heroicons/react/24/outline";

// Context
import LoaderContext from "../../../../context/psikotest/LoaderContext";
import SnackbarContext from "../../../../context/psikotest/SnackbarContext";

// Helper
import errorHandler from "../../../../helper/psikotest/errorHandler";

// Controller
import EppsQuestionary from "../../../../controller/psikotest/psikotest_system/epps_questionary";

function FormImportQuestionary(
    props: {
        nextNumber: number,
        handleClose: () => void,
        handleRefreshAfterImport: () => void
    }) {
    // Contexts
    const { setMessage } = useContext(LoaderContext);
    const { setNotif } = useContext(SnackbarContext);

    // Ref
    const uploadInput = useRef<HTMLInputElement>(null);

    // State
    const [data, setData] = useState<TPSEppsQuestionaryImport[]>([])
    const [file, setFile] = useState<File>();
    const [onSubmit, setOnSubmit] = useState<boolean>(false)

    // handle read file import
    const readFileImport = useCallback(async (file: File) => {
        try {
            // check file type, make sure it xlsx
            if (!file.type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
                throw new Error("file type not valid, only accept .xlsx!");
            }
            const fileBuffer = await file.arrayBuffer();
            const wb = read(fileBuffer); // parse the array buffer
            const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
            // generate json ({ defval: "" }) is make sure empty value is set to key value pair
            const result: any = utils.sheet_to_json(ws, { defval: "" });
            // return alert if empty
            if (result.length === 0) {
                throw new Error("not found any data!");
            }
            // column 
            const columnHeader = ["number", "statement_a", "statement_a_value", "pair_key", "statement_b_value", "statement_b"]
            // check key 
            const valid = Object.keys(result?.[0] ?? {}).filter(k => columnHeader.includes(k)).length >= columnHeader.length

            // return error if not valid
            if (!valid) {
                throw new Error("format data not valid, please check if any column missing!");
            }
            // set status
            const dataUpdateStatus: TPSEppsQuestionaryImport[] = result.map((r: TPSEppsQuestionaryImport) => { return { ...r, status: "pending", message: "" } })
            // set data for status
            setData(dataUpdateStatus)
        } catch (error) {
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }, [setNotif])
    // download file template
    const downloadFileTemplate = () => {
        let data = []
        // header
        data.push({})
        // generate worksheet and workbook 
        const worksheet = utils.json_to_sheet(data)
        const workbook = utils.book_new()
        utils.book_append_sheet(workbook, worksheet, "EPPS Questionary")
        // main header
        let header: string[] = ["number", "statement_a", "statement_a_value", "pair_key", "statement_b_value", "statement_b"]
        utils.sheet_add_aoa(worksheet, [header], { origin: "A1" });
        // set width
        var wscols = [
            { width: 10 },
            { width: 50 },
            { width: 20 },
            { width: 20 },
            { width: 20 },
            { width: 50 }
        ];
        worksheet["!cols"] = wscols;
        // create an XLSX file and try to save to .xlsx
        writeFile(workbook, `Psikotest EPPS Questionary Template.xlsx`)
    }
    // import data
    const importData = async () => {
        setMessage("Save EPPS Questionary");
        // set on submit
        setOnSubmit(true)
        const epps_qc = new EppsQuestionary();
        // loop
        for (let i = 0; i < data.length; i++) {
            const dataToProccess = data[i]
            if (dataToProccess.status !== "success") {
                try {
                    await epps_qc.create({
                        id: 0,
                        number: dataToProccess.number,
                        statement_a: dataToProccess.statement_a,
                        statement_a_value: dataToProccess.statement_a_value,
                        pair_key: dataToProccess.pair_key ?? "",
                        statement_b: dataToProccess.statement_b,
                        statement_b_value: dataToProccess.statement_b_value,
                    });
                    setData(updateData => (updateData.map((d, d_i) => {
                        return d_i === i ? { ...d, status: "success", message: "submitted" } : d
                    })))
                } catch (error) {
                    const errorMessage = errorHandler(error);
                    setNotif({ type: "error", message: errorMessage });
                    setData(updateData => (updateData.map((d, d_i) => {
                        return d_i === i ? { ...d, status: "failed", message: errorMessage } : d
                    })))
                }
            }
        }
        setMessage("");
        // set on submit false
        setOnSubmit(false)
    }

    // use effect
    useEffect(() => {
        if (file) {
            readFileImport(file)
        }
    }, [file, readFileImport]);
    // if all data submitted
    useEffect(() => {
        if (data.length > 0 && data.filter(d => d.status === "success").length === data.length) {
            props.handleRefreshAfterImport()
        }
    }, [data, props]);

    return (
        <div>
            <div className="fixed z-20 top-0 left-0 w-screen h-screen bg-black opacity-20"></div>
            <div className="fixed z-30 top-0 left-0 w-screen h-screen bg-transparent flex items-center justify-center">
                <div className="bg-white w-screen md:w-[90%] h-[90%] md:rounded overflow-hidden">
                    <div className="p-4 bg-green-800 md:rounded-t text-white flex justify-between">
                        <div className="flex space-x-2">
                            <ClipboardDocumentListIcon className="w-4" />
                            <h3>Form Import EPPS Questionary</h3>
                        </div>
                        <XMarkIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent cursor-pointer" aria-hidden="true" onClick={() => props.handleClose()} />
                    </div>
                    <div className="h-[93%] overflow-auto">
                        {data.length === 0
                            ?
                            <div className="h-full flex flex-col justify-center items-center">
                                <div className="pt-2">
                                    <button type="button" disabled={onSubmit}
                                        onClick={() => downloadFileTemplate()}
                                        className="flex items-center border border-blue-600 py-1 px-2 text-blue-600 rounded disabled:bg-slate-600">
                                        <ArrowDownTrayIcon className="w-4" />
                                        <span className="block pl-1">Download File Template</span>
                                    </button>
                                </div>
                                <div className="h-full flex-1 flex flex-col justify-center items-center">
                                    <div className="h-60 w-60 flex flex-col justify-center items-center text-red-400 font-bold border border-red-200 bg-red-50 rounded-full">
                                        <ClipboardDocumentListIcon className="w-10" />
                                        <div>No File Selected</div>
                                        <div className="text-sm font-normal">Click or drag file here</div>
                                    </div>
                                    <input
                                        type="file"
                                        className="absolute h-2/6 w-3/4 lg:w-1/4 opacity-0"
                                        accept=".xlsx"
                                        ref={uploadInput}
                                        onChange={(event) => {
                                            if (event.target.files !== null) {
                                                Array.from(event.target.files).forEach((file, i) => {
                                                    if (i === 0) {
                                                        setFile(file);
                                                    }
                                                });
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            : <div className="p-4">
                                <div className="flex justify-between pb-2">
                                    <div>
                                        <button type="button" disabled={onSubmit}
                                            onClick={() => importData()}
                                            className="flex items-center bg-blue-600 py-1 px-2 text-white rounded disabled:bg-slate-600">
                                            <PaperAirplaneIcon className="w-4 -rotate-45" />
                                            <span className="block pl-1">Submit</span>
                                        </button>
                                    </div>
                                    <div className={`text-lg font-semibold ${data.filter(d => d.status === "success").length === data.length ? "text-green-600" : "text-blue-600"}`}>
                                        {data.filter(d => d.status === "success").length}/{data.length}
                                    </div>
                                </div>
                                <div>
                                    <table className="w-full">
                                        <thead>
                                            <tr className="bg-slate-700 text-white">
                                                <th className="border font-medium p-2 text-left w-2">#</th>
                                                <th className="border font-medium p-2 text-left w-2">Number</th>
                                                <th className="border font-medium p-2 text-left min-w-[18rem]">Statement A</th>
                                                <th className="border font-medium p-2 text-left w-28">Statement A Value</th>
                                                <th className="border font-medium p-2 text-left w-28">Pair Key</th>
                                                <th className="border font-medium p-2 text-left w-28">Statement B Value</th>
                                                <th className="border font-medium p-2 text-left min-w-[18rem]">Statement B</th>
                                                <th className="border font-medium p-2 text-left">Status</th>
                                                <th className="border font-medium p-2 text-left min-w-[18rem]">Message</th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white text-sm">
                                            {data.map((d, i) => (
                                                <tr key={`data_${i}`} className={`${d.status === "success" ? "bg-green-100" : d.status === "failed" ? "bg-red-100" : "bg-slate-100"}`}>
                                                    <td className="border border-gray-300 text-center">{i + 1}</td>
                                                    <td className="border border-gray-300 text-slate-500">
                                                        <input
                                                            type="number"
                                                            className={"border-gray-200 focus:border-gray-500 w-full px-2 py-1 first-letter:appearance-none bg-white-200 text-gray-700 border focus:outline-none text-center"}
                                                            value={d.number}
                                                            disabled={d.status === "success"}
                                                            onChange={(e) => {
                                                                // update data
                                                                const copyData: TPSEppsQuestionaryImport[] = data.map((d, data_i) => {
                                                                    return data_i === i ? { ...d, number: parseInt(e.target.value) } : d
                                                                })
                                                                setData(copyData)
                                                            }}
                                                        />
                                                    </td>
                                                    <td className="border border-gray-300 text-slate-500">
                                                        <input
                                                            type="text"
                                                            className={"border-gray-200 focus:border-gray-500 w-full px-2 py-1 first-letter:appearance-none bg-white-200 text-gray-700 border focus:outline-none"}
                                                            value={d.statement_a}
                                                            disabled={d.status === "success"}
                                                            onChange={(e) => {
                                                                // update data
                                                                const copyData: TPSEppsQuestionaryImport[] = data.map((d, data_i) => {
                                                                    return data_i === i ? { ...d, statement_a: e.target.value } : d
                                                                })
                                                                setData(copyData)
                                                            }}
                                                        />
                                                    </td>
                                                    <td className="border border-gray-300 text-slate-500">
                                                        <input
                                                            type="text"
                                                            className={"border-gray-200 focus:border-gray-500 w-full px-2 py-1 first-letter:appearance-none bg-white-200 text-gray-700 border focus:outline-none text-center"}
                                                            value={d.statement_a_value}
                                                            disabled={d.status === "success"}
                                                            onChange={(e) => {
                                                                // update data
                                                                const copyData: TPSEppsQuestionaryImport[] = data.map((d, data_i) => {
                                                                    return data_i === i ? { ...d, statement_a_value: e.target.value } : d
                                                                })
                                                                setData(copyData)
                                                            }}
                                                        />
                                                    </td>
                                                    <td className="border border-gray-300 text-slate-500">
                                                        <input
                                                            type="text"
                                                            className={"border-gray-200 focus:border-gray-500 w-full px-2 py-1 first-letter:appearance-none bg-white-200 text-gray-700 border focus:outline-none text-center"}
                                                            value={d.pair_key}
                                                            disabled={d.status === "success"}
                                                            onChange={(e) => {
                                                                // update data
                                                                const copyData: TPSEppsQuestionaryImport[] = data.map((d, data_i) => {
                                                                    return data_i === i ? { ...d, pair_key: e.target.value } : d
                                                                })
                                                                setData(copyData)
                                                            }}
                                                        />
                                                    </td>
                                                    <td className="border border-gray-300 text-slate-500">
                                                        <input
                                                            type="text"
                                                            className={"border-gray-200 focus:border-gray-500 w-28 px-2 py-1 first-letter:appearance-none bg-white-200 text-gray-700 border focus:outline-none text-center"}
                                                            value={d.statement_b_value}
                                                            disabled={d.status === "success"}
                                                            onChange={(e) => {
                                                                // update data
                                                                const copyData: TPSEppsQuestionaryImport[] = data.map((d, data_i) => {
                                                                    return data_i === i ? { ...d, statement_b_value: e.target.value } : d
                                                                })
                                                                setData(copyData)
                                                            }}
                                                        />
                                                    </td>
                                                    <td className="border border-gray-300 text-slate-500">
                                                        <input
                                                            type="text"
                                                            className={"border-gray-200 focus:border-gray-500 w-full px-2 py-1 first-letter:appearance-none bg-white-200 text-gray-700 border focus:outline-none"}
                                                            value={d.statement_b}
                                                            disabled={d.status === "success"}
                                                            onChange={(e) => {
                                                                // update data
                                                                const copyData: TPSEppsQuestionaryImport[] = data.map((d, data_i) => {
                                                                    return data_i === i ? { ...d, statement_b: e.target.value } : d
                                                                })
                                                                setData(copyData)
                                                            }}
                                                        />
                                                    </td>
                                                    <td className="border border-gray-300 text-slate-500 px-2">
                                                        {d.status ?? "-"}
                                                    </td>
                                                    <td className="border border-gray-300 text-slate-500 px-2">
                                                        {d.message ?? "-"}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}

export default FormImportQuestionary;