import { AxiosRequestConfig } from "axios";
import req from "../../../services/assessment/request";

class Assessment {
  async list() {
    try {
      const res = await req.get("/api/assessment_system/assessment/");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async create(assessment: TAssessment) {
    try {
      const res = await req.post("/api/assessment_system/assessment/", assessment);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async get(id: string) {
    try {
      const res = await req.get("/api/assessment_system/assessment/" + id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async update(id: string, assessment: TAssessment) {
    try {
      const res = await req.put("/api/assessment_system/assessment/" + id, assessment);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async delete(id: string) {
    try {
      const res = await req.delete("/api/assessment_system/assessment/" + id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async getSession(assessment_session_id: string) {
    try {
      const res = await req.get("/api/assessment_system/assessment/session/" + assessment_session_id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async listSession(id: string) {
    try {
      const res = await req.get("/api/assessment_system/assessment/" + id + "/session");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async addSession(id: string, data: TAssessmentSession) {
    try {
      const res = await req.post("/api/assessment_system/assessment/" + id + "/session", data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async updateSession(data: TAssessmentSession) {
    try {
      const res = await req.put("/api/assessment_system/assessment/session/" + data.id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async deleteSession(session_id: string) {
    try {
      const res = await req.delete("/api/assessment_system/assessment/session/" + session_id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async listParticipant(id: string) {
    try {
      const res = await req.get("/api/assessment_system/assessment/" + id + "/participant");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async addParticipant(id: string, data: TAssessmentParticipant) {
    try {
      const res = await req.post("/api/assessment_system/assessment/" + id + "/participant", data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async getParticipant(assessment_participant_id: string) {
    try {
      const res = await req.get("/api/assessment_system/assessment/participant/" + assessment_participant_id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async updateParticipant(participant_id: string, data: TAssessmentParticipant) {
    try {
      const res = await req.put("/api/assessment_system/assessment/participant/" + participant_id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async uploadParticipant(
    participant_id: string,
    data: { file: File; field: string },
    config: AxiosRequestConfig<FormData>
  ) {
    try {
      const form_data = new FormData();
      form_data.append("file", data.file);
      form_data.append("field", data.field);
      const res = await req.put("/api/assessment_system/assessment/participant/upload/" + participant_id, form_data, config);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async deleteParticipant(participant_id: string) {
    try {
      const res = await req.delete("/api/assessment_system/assessment/participant/" + participant_id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async listParticipantSnapshot(assessment_participant_session_id: string, assessment_id: string) {
    try {
      const res = await req.get("/api/assessment_system/assessment/participant_snapshot/" + assessment_participant_session_id + "/" + assessment_id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async listIntrayEmail(questionary_id: string) {
    try {
      const res = await req.get("/api/assessment_system/assessment/participant_intray/email/" + questionary_id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async listIntrayReply(assessment_participant_session_id: string) {
    try {
      const res = await req.get("/api/assessment_system/assessment/participant_intray/reply/" + assessment_participant_session_id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async listScoreName(id: string) {
    try {
      const res = await req.get("/api/assessment_system/assessment/" + id + "/score_name");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async addScoreName(id: string, data: TAssessmentScoreName) {
    try {
      const res = await req.post("/api/assessment_system/assessment/" + id + "/score_name", data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async updateScoreName(data: TAssessmentScoreName) {
    try {
      const res = await req.put("/api/assessment_system/assessment/score_name/" + data.id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async deleteScoreName(score_name_id: string) {
    try {
      const res = await req.delete("/api/assessment_system/assessment/score_name/" + score_name_id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async listParticipantSession(id: string) {
    try {
      const res = await req.get("/api/assessment_system/assessment/" + id + "/participant_session");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async addParticipantSession(data: TAssessmentParticipantSession) {
    try {
      const res = await req.post("/api/assessment_system/assessment/participant_session", data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async updateParticipantSession(data: TAssessmentParticipantSession) {
    try {
      const res = await req.put("/api/assessment_system/assessment/participant_session/" + data.id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async listPerParticipantSession(assessment_participant_id: string) {
    try {
      const res = await req.get("/api/assessment_system/assessment/per_participant_session/" + assessment_participant_id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async listParticipantScore(id: string) {
    try {
      const res = await req.get("/api/assessment_system/assessment/" + id + "/participant_score");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async addParticipantScore(data: TAssessmentParticipantScore) {
    try {
      const res = await req.post("/api/assessment_system/assessment/participant_score", data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async updateParticipantScore(data: TAssessmentParticipantScore) {
    try {
      const res = await req.put("/api/assessment_system/assessment/participant_score/" + data.id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async sendInvitation(data: { participant: TAssessmentParticipant[]; assessment: TAssessment }) {
    try {
      const res = await req.post("/api/assessment_system/assessment/invitation", data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default Assessment;
