import { useContext } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";

// Contexts
import PPAssessmentContext from "../../context/psikotest/PPAssessmentContext";

// Local Components
import PsikotestParticipantLogin from "./PsikotestParticipantLogin";
import Session from "./session/Session";

function PsikotestParticipantRoute() {
  return (
    <Routes>
      <Route element={<LoginPsikotestParticipantRoute />}>
        <Route path={`prep`} element={<PsikotestParticipantLogin />} />
      </Route>
      <Route element={<ProtectedPsikotestParticipantRoute />}>
        <Route path={`session//*`} element={<Session />} />
      </Route>
    </Routes>
  );
}

const LoginPsikotestParticipantRoute = () => {
  const { assessment_participant } = useContext(PPAssessmentContext);
  if (assessment_participant.date_of_birth !== null && assessment_participant.gender !== null && assessment_participant.photo !== null && assessment_participant.manual !== null) {
    return <Navigate to="session" replace />;
  }
  return <Outlet />;
};

const ProtectedPsikotestParticipantRoute = () => {
  const { assessment_participant } = useContext(PPAssessmentContext);
  if (assessment_participant.date_of_birth === null || assessment_participant.gender === null || assessment_participant.photo === null || assessment_participant.manual === null) {
    return <Navigate to={`prep/?k=${sessionStorage.getItem("accessKey")}`} replace />;
  }
  return <Outlet />;
};

export default PsikotestParticipantRoute;
