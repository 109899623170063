import req from "../../../services/psikotest/request";

class IstConversion {
  async create(data: TPSIstConversion) {
    try {
      const res = await req.post("/api/psikotest_system/ist_conversion/", data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async update(data: TPSIstConversion) {
    try {
      const res = await req.put("/api/psikotest_system/ist_conversion/" + data.id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async delete(data: TPSIstConversion) {
    try {
      const res = await req.delete("/api/psikotest_system/ist_conversion/", { data });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default IstConversion;
