import req from "../../../services/psikotest/request";

class IstQuestionary {
  async create(data: TPSIstQuestionary) {
    try {
      const res = await req.post("/api/psikotest_system/ist_questionary/", data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async update(data: TPSIstQuestionary) {
    try {
      const res = await req.put("/api/psikotest_system/ist_questionary/" + data.id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async delete(data: TPSIstQuestionary) {
    try {
      const res = await req.delete("/api/psikotest_system/ist_questionary/", { data });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default IstQuestionary;
