import { useCallback, useContext, useEffect, useState } from "react";

// Context
import SnackbarContext from "../../../context/psikotest/SnackbarContext";

// Handler
import errorHandler from "../../../helper/psikotest/errorHandler";

// Controller
import ParticipantSession from "../../../controller/psikotest/psikotest_participant/session";
import { CheckCircleIcon, ClockIcon } from "@heroicons/react/24/outline";

function SubtestMmpi(props: { assessment_participant_session: TPPAssessmentParticipantSession, closeSession: () => void }) {
    // Context
    const { setNotif } = useContext(SnackbarContext);

    // state
    const [mmpi, setMmpi] = useState<TPSMmpi>()
    const [mmpi_questionary, setMmpiQuestionary] = useState<TPSMmpiQuestionary[]>([])
    const [mmpi_questionary_answer, setMmpiQuestionaryAnswer] = useState<TPSAssessmentParticipantMmpiSessionAnswer>()
    const [answer_questionary, setAnswerQuestionary] = useState<{ number: Number, answer: string }[]>([])

    // get doc
    const getDoc = useCallback(async () => {
        try {
            // get all question in subtest id
            const psc = new ParticipantSession();
            let resMmpi = await psc.getMmpi();
            setMmpi(resMmpi.data.row)
            let resQuestionaryMmpi = await psc.getQuestionaryMmpi();
            setMmpiQuestionary(resQuestionaryMmpi.data.list)
            let resQuestionaryAnswerMmpi = await psc.getQuestionaryAnswerMmpi(props.assessment_participant_session.id)
            setMmpiQuestionaryAnswer(resQuestionaryAnswerMmpi.data.row)
            // set answer questionary
            if ((JSON.parse(resQuestionaryAnswerMmpi.data.row.answer)).length === 0) {
                // create empty object with same length of mmpi questionary
                setAnswerQuestionary(resQuestionaryMmpi.data.list.map((q: TPSMmpiQuestionary) => {
                    return { number: q.number, answer: "" }
                }))
            } else {
                setAnswerQuestionary(JSON.parse(resQuestionaryAnswerMmpi.data.row.answer))
            }
        } catch (error) {
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }, [setNotif, props.assessment_participant_session.id]);

    useEffect(() => {
        getDoc();
    }, [getDoc]);

    // track mmpi_questionary
    useEffect(() => {
        //console.log(mmpi_questionary)
    }, [mmpi_questionary]);

    // track answer_questionary
    useEffect(() => {
        //console.log(answer_questionary)
    }, [answer_questionary]);

    // handleAnswer
    const handleAnswer = async (index: number, answer: string) => {
        try {
            //console.log(index, answer, point)
            // set answer
            // copy answer
            const c_answer = [...answer_questionary]
            c_answer.splice(index, 1, { number: c_answer[index].number, answer: answer })
            // set result point
            const c_result_point = {
                yes: c_answer.filter(c => c.answer === "yes").length,
                no: c_answer.filter(c => c.answer === "no").length,
            }
            // save answer
            if (mmpi_questionary_answer) {
                const psc = new ParticipantSession();
                await psc.saveQuestionaryAnswerMmpi(props.assessment_participant_session.id, {
                    ...mmpi_questionary_answer,
                    answer: JSON.stringify(c_answer),
                    result: JSON.stringify(c_result_point)
                });
                setAnswerQuestionary(c_answer)
            }
        } catch (error) {
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }

    return (
        <div className="h-[94%] overflow-auto">
            <div className="flex flex-col items-center">
                <div className="text-sm md:text-base max-w-5xl text-left py-3 whitespace-pre-line">
                    {mmpi?.mdi}
                </div>
            </div>
            <div className="text-sm md:text-base border-b flex flex-col items-center pb-20 relative">
                <div className="w-full text-center sticky top-0 bg-white">
                    <div className="py-2 md:pl-6 font-bold text-blue-600 text-xl">
                        Dijawab: {answer_questionary.filter(aq => aq.answer !== "").length}/{mmpi_questionary.length}
                    </div>
                </div>
                <div className="max-w-5xl w-full">
                    <div className="">
                        <div className="flex w-full bg-slate-800 text-white font-semibold">
                            <div className="w-1/12 text-center flex justify-center items-center border p-1">No</div>
                            <div className="w-6/12 text-left flex justify-center items-center border p-1">
                                Pertanyaan
                            </div>
                            <div className={`w-2/12 text-center flex justify-center items-center border p-1 cursor-pointer`}>
                                Ya
                            </div>
                            <div className={`w-2/12 text-center flex justify-center items-center border p-1 cursor-pointer`}>
                                Tidak
                            </div>
                            <div className="w-1/12 text-center flex justify-center items-center border p-1">
                                <CheckCircleIcon className="w-5 text-white" />
                            </div>
                        </div>
                    </div>
                    {mmpi_questionary.map((mmpi_q, index) => (
                        <div key={index} className="">
                            <div className="flex w-full">
                                <div className="w-1/12 text-center flex justify-center items-center border p-1">{mmpi_q.number}</div>
                                <div className="w-6/12 flex justify-left items-center border p-1">{mmpi_q.question}</div>
                                <div
                                    className={`
                                        w-2/12 text-center flex justify-center items-center border p-1 cursor-pointer hover:bg-blue-200 hover:font-semibold hover:text-black
                                        ${answer_questionary?.[index]?.answer === "yes" ? "bg-green-200" : ""}
                                        ${answer_questionary?.[index]?.answer !== "yes" && answer_questionary?.[index]?.answer === "no" ? "bg-slate-100 text-gray-400" : ""}
                                    `}
                                    onClick={() => handleAnswer(index, "yes")}>
                                    Ya
                                </div>
                                <div
                                    className={`
                                        w-2/12 text-center flex justify-center items-center border p-1 cursor-pointer hover:bg-blue-200 hover:font-semibold hover:text-black
                                        ${answer_questionary?.[index]?.answer === "no" ? "bg-green-200" : ""}
                                        ${answer_questionary?.[index]?.answer !== "no" && answer_questionary?.[index]?.answer === "yes" ? "bg-slate-100 text-gray-400" : ""}
                                    `}
                                    onClick={() => handleAnswer(index, "no")}>
                                    Tidak
                                </div>
                                <div className={`w-1/12 text-center flex justify-center items-center border p-1 ${answer_questionary?.[index]?.answer !== "" ? "bg-green-100" : ""}`}>
                                    {answer_questionary?.[index]?.answer !== ""
                                        ? <CheckCircleIcon className="w-5 text-green-600" />
                                        : <ClockIcon className="w-5 text-gray-400" />
                                    }
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="max-w-5xl w-full mt-3 flex justify-between items-center">
                    <div className="pl-2 md:pl-6 font-semibold">
                        {answer_questionary.filter(aq => aq.answer !== "").length}/{mmpi_questionary.length}
                    </div>
                    {answer_questionary.filter(aq => aq.answer !== "").length !== mmpi_questionary.length &&
                        <div className="px-3 text-red-500">Klik <b>Finish</b> jika anda ingin menyelesaikan test ini.</div>
                    }
                    <button
                        type="button"
                        className={`mx-3 md:mx-0 py-1 px-6 min-w-[80px] text-center cursor-pointer rounded text-white bg-red-500 hover:bg-red-600 disabled:bg-gray-300`}
                        onClick={() => props.closeSession()}
                        disabled={false}>
                        Finish
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SubtestMmpi;
