import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import * as XLSX from 'xlsx';

// Icons
import { PrinterIcon, XMarkIcon } from "@heroicons/react/24/outline";

// Contexts
import LoaderContext from "../../../../context/psikotest/LoaderContext";
import SnackbarContext from "../../../../context/psikotest/SnackbarContext";

// Helpers
import errorHandler from "../../../../helper/psikotest/errorHandler";

// Controller
import Assessment from "../../../../controller/psikotest/psikotest_system/assessment"

function ModalIntelligenceScore(
    props: {
        doc: TPSAssessment;
        assessment_id: number,
        handleClose: () => void
    }) {
    const { setMessage } = useContext(LoaderContext);
    const { setNotif } = useContext(SnackbarContext);
    // state
    const [cfit, setCfit] = useState<TPSAssessmentCfitSubtest[]>([])
    const [cfit_score, setCfitScore] = useState<TPSAssessmentCfitScore[]>([])
    const [ist, setIst] = useState<TPSAssessmentIstSubtest[]>([])
    const [ist_score, setIstScore] = useState<TPSAssessmentIstScore[]>([])

    const getDoc = useCallback(
        async function getDoc() {
            try {
                // fetch cfit subtest
                setMessage("Fetch Cfit Score");
                setMessage("Fetch Cfit");
                const ca = new Assessment()
                let res = await ca.cfit_subtest(props.assessment_id);
                setCfit(res.data.list)
                // fetch cfit score
                setMessage("Fetch Cfit Score");
                res = await ca.cfit_score(props.assessment_id)
                setCfitScore(res.data.list)

                setMessage("Fetch Ist Score");
                res = await ca.ist_subtest(props.assessment_id);
                setIst(res.data.list)
                // fetch cfit score
                setMessage("Fetch Ist Score");
                res = await ca.ist_score(props.assessment_id)
                setIstScore(res.data.list)
                setMessage("");
            } catch (error) {
                setMessage("");
                const errorMessage = errorHandler(error);
                setNotif({ type: "error", message: errorMessage });
            }
        },
        [setNotif, setMessage, props.assessment_id]
    );

    useEffect(() => {
        getDoc();
    }, [getDoc]);

    const letterFromNumber = (n: number): string => {
        var letter = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.charAt(n % 26)
        var concat = Math.round(n / 26);
        return concat > 0 ? letterFromNumber(concat - 1) + letter : letter;
    };

    const downloadExcel = () => {
        let data = []
        // header
        data.push({})
        // actual data
        cfit_score.forEach((cs, i) => {
            const composedData: { [key: string]: any } = {
                No: i + 1,
                Participant: cs.participant.full_name,
                Age: cs.age,
            }
            cfit.forEach((c, j) => {
                const current_score = cs.participant_score.find(cps => cps.cfit_id === c.cfit_id)
                if (c.cfit?.test_name !== undefined) {
                    composedData[`${c.cfit.test_name}`] = current_score?.score ?? "-"
                }
            })
            composedData[`CFIT Total Score`] = cs.total_score
            composedData[`CFIT IQ`] = cs.iq ?? "-"
            const current_ist_score = ist_score.find(is => is.participant.id === cs.participant.id)
            if (current_ist_score) {
                ist.forEach((ist_value, i) => {
                    const current_score = current_ist_score.participant_score.find(ips => ips.ist_id === ist_value.ist_id)
                    composedData[`${i}_RW`] = current_score?.score_conversion ?? "-"
                    composedData[`${i}_SW`] = current_score?.score_sw ?? "-"
                })
            }
            composedData[`IST Total Score`] = current_ist_score?.total_score ?? "-"
            composedData[`IST SW`] = current_ist_score?.gesamt_sw ?? "-"
            composedData[`IST IQ`] = current_ist_score?.gesamt_iq ?? "-"
            data.push(composedData)
        })

        // generate worksheet and workbook 
        const worksheet = XLSX.utils.json_to_sheet(data)
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, "Intelligence Score")

        // fix headers
        // main header
        let header: string[] = ["No", "Participant", "Age"];
        let subheader: string[] = header
        const cfit_subtest: string[] = []
        // cfit header
        cfit.forEach(c => { cfit_subtest.push(c.cfit?.test_name ?? "-") })
        header = [...header, ...cfit_subtest, "Total Score", "IQ"]
        subheader = header
        const ist_subtest: string[] = []
        const ist_subtest_subheader: string[] = []
        ist.forEach((ist_value, i) => {
            ist_subtest.push(ist_value.ist?.subtest_name ?? "-")
            ist_subtest.push(ist_value.ist?.subtest_name ?? "-")
            ist_subtest_subheader.push("RW")
            ist_subtest_subheader.push("SW")
        })
        header = [...header, ...ist_subtest, "Total Score", "SW", "IQ"]
        subheader = [...subheader, ...ist_subtest_subheader, "Total Score", "SW", "IQ"]
        XLSX.utils.sheet_add_aoa(worksheet, [header, subheader], { origin: "A1" });

        // merge column 
        worksheet["!merges"] = []
        // No
        worksheet["!merges"].push(XLSX.utils.decode_range("A1:A2"));
        // Participant
        worksheet["!merges"].push(XLSX.utils.decode_range("B1:B2"));
        // Age
        worksheet["!merges"].push(XLSX.utils.decode_range("C1:C2"));
        // CFIT [....]
        let alphabet = "A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z"
        // generated alphabet up to 100 column
        for (let index = 0; index <= 100; index++) {
            const column = alphabet.split(",")[index];
            if (column === undefined) {
                alphabet += `,${letterFromNumber(index)}`
            }
        }

        let currAlphabet = alphabet.split(",")[worksheet["!merges"].length]
        // CFIT [....]
        cfit.forEach((_, i) => {
            if (worksheet["!merges"]) {
                worksheet["!merges"].push(XLSX.utils.decode_range(`${currAlphabet}1:${currAlphabet}2`));
                currAlphabet = alphabet.split(",")[worksheet["!merges"].length]
            }
        })
        // CFIT Score
        worksheet["!merges"].push(XLSX.utils.decode_range(`${currAlphabet}1:${currAlphabet}2`));
        // CFIT IQ
        currAlphabet = alphabet.split(",")[worksheet["!merges"].length]
        worksheet["!merges"].push(XLSX.utils.decode_range(`${currAlphabet}1:${currAlphabet}2`));
        // IST [....]
        let colStart = worksheet["!merges"].length
        let colEnd = worksheet["!merges"].length + 1
        ist.forEach((_, i) => {
            if (worksheet["!merges"]) {
                worksheet["!merges"].push(XLSX.utils.decode_range(`${alphabet.split(",")[colStart]}1:${alphabet.split(",")[colEnd]}1`))
                if (i < ist.length) {
                    colStart = colStart + 2
                    colEnd = colEnd + 2
                }
            }
        })
        // IST Score
        currAlphabet = alphabet.split(",")[colStart]
        worksheet["!merges"].push(XLSX.utils.decode_range(`${currAlphabet}1:${currAlphabet}2`));
        // IST SW
        colStart++
        currAlphabet = alphabet.split(",")[colStart]
        worksheet["!merges"].push(XLSX.utils.decode_range(`${currAlphabet}1:${currAlphabet}2`));
        // IST IQ
        colStart++
        currAlphabet = alphabet.split(",")[colStart]
        worksheet["!merges"].push(XLSX.utils.decode_range(`${currAlphabet}1:${currAlphabet}2`));

        // set width
        var wscols = [
            { width: 10 },
            { width: 20 }
        ];
        worksheet["!cols"] = wscols;

        // create an XLSX file and try to save to .xlsx
        XLSX.writeFile(workbook, `${props.doc.assessment_title} - Intelligence Score Report.xlsx`)
    };

    return <div>
        <div className="fixed z-20 top-0 left-0 w-screen h-screen bg-black opacity-20"></div>
        <div className="fixed z-30 top-0 left-0 w-screen h-screen bg-transparent flex items-center justify-center">
            <div className="flex flex-col bg-white w-screen md:w-[90%] h-[90%] md:rounded">
                <div className="p-4 bg-blue-800 md:rounded-t text-white flex justify-between">
                    <h3>CFIT Score</h3>
                    <XMarkIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent cursor-pointer" aria-hidden="true" onClick={() => props.handleClose()} />
                </div>
                <div className="flex p-2">
                    <button
                        type="button"
                        onClick={() => downloadExcel()}
                        className="ml-auto flex items-center bg-green-600 py-1 px-2 text-white rounded"
                    >
                        <PrinterIcon className="w-4" />
                        <span className="block pl-1">Print</span>
                    </button>
                </div>
                <div className="p-2 pb-10 overflow-auto">
                    <table className="w-full border">
                        <thead className="bg-slate-600 text-white">
                            <tr>
                                <th className="py-1 px-2 w-8 border border-slate-500" rowSpan={2}>No</th>
                                <th className="py-1 px-2 text-left border border-slate-500" rowSpan={2}>Participant</th>
                                <th className="py-1 px-2 text-left border border-slate-500" rowSpan={2}>Age</th>
                                {cfit.map((c, i) => {
                                    return (
                                        <React.Fragment key={`cfit_${i}`}>
                                            <th className="py-1 px-2 text-left border border-slate-500" rowSpan={2}>{c.cfit?.test_name}</th>
                                        </React.Fragment>
                                    )
                                })}
                                <th className="py-1 px-2 text-left border border-slate-500" rowSpan={2}>CFIT <br />T. Score</th>
                                <th className="py-1 px-2 border bg-red-600 border-red-500 text-center" rowSpan={2}>IQ</th>
                                {ist.map((ist_value, i) => {
                                    return (
                                        <React.Fragment key={`ist_value_${i}`}>
                                            <th className="py-1 px-2 text-center border border-slate-500" colSpan={2}>{ist_value.ist?.subtest_name}</th>
                                        </React.Fragment>
                                    )
                                })}
                                <th className="py-1 px-2 text-left border border-slate-500" rowSpan={2}>IST <br />T. Score</th>
                                <th className="py-1 px-2 border bg-gray-600 border-gray-500 text-center" rowSpan={2}>SW</th>
                                <th className="py-1 px-2 border bg-red-600 border-red-500 text-center" rowSpan={2}>IQ</th>
                            </tr>
                            <tr>
                                {ist.map((ist_value, i) => {
                                    return (
                                        <React.Fragment key={`ist_value_${i}`}>
                                            <th className="py-1 px-2 text-center border border-slate-500 font-normal">RW</th>
                                            <th className="py-1 px-2 text-center border border-slate-500 font-normal">SW</th>
                                        </React.Fragment>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {cfit_score.map((cs, i) => {
                                const current_ist_score = ist_score.find(is => is.participant.id === cs.participant.id)
                                return (
                                    <tr key={`cfit_score_${i}`}>
                                        <td className="py-1 px-2 border text-center">{i + 1}</td>
                                        <td className="py-1 px-2 border">{cs.participant.full_name}</td>
                                        <td className="py-1 px-2 border">{cs.age}</td>
                                        {cfit.map((c, j) => {
                                            const current_score = cs.participant_score.find(cps => cps.cfit_id === c.cfit_id)
                                            return (
                                                <td key={`current_score_${j}`} className="py-1 px-2 border">
                                                    <div>{current_score?.score ?? "-"}</div>
                                                </td>
                                            )
                                        })}
                                        <td className="py-1 px-2 border">{cs.total_score}</td>
                                        <td className="py-1 px-2 border font-bold text-center border-red-100 bg-red-50 text-red-700">{cs.iq ?? "-"}</td>
                                        {current_ist_score &&
                                            <>
                                                {ist.map((ist_value, j) => {
                                                    const current_score = current_ist_score.participant_score.find(ips => ips.ist_id === ist_value.ist_id)
                                                    return (
                                                        <Fragment key={`current_score_${j}`}>
                                                            <td className="py-1 px-2 border text-center">
                                                                <div className="flex justify-center">
                                                                    {current_score?.score !== undefined && current_score?.score !== null
                                                                        ? <div className="flex space-x-1 items-center">
                                                                            <div className={`px-1 ${current_score.answer_type === "input_text" ? "line-through" : ""}`}>{current_score.score}</div>
                                                                            {current_score.answer_type === "input_text" &&
                                                                                <div className="px-1 text-red-500 border border-red-500 rounded bg-red-50">
                                                                                    {current_score.score_conversion}
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        : "-"}
                                                                </div>
                                                            </td>
                                                            <td className="py-1 px-2 border text-center">
                                                                {current_score?.score_sw ?? "-"}
                                                            </td>
                                                        </Fragment>
                                                    )
                                                })}
                                                <td className="py-1 px-2 border">{current_ist_score.total_score}</td>
                                                <td className="py-1 px-2 border font-bold text-center border-gray-200 bg-gray-50 text-gray-700">{current_ist_score.gesamt_sw ?? "-"}</td>
                                                <td className="py-1 px-2 border font-bold text-center border-red-100 bg-red-50 text-red-700">{current_ist_score.gesamt_iq ?? "-"}</td>
                                            </>
                                        }
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div >
}

export default ModalIntelligenceScore;