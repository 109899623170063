import { useCallback, useContext, useState, useEffect, useMemo, Fragment } from "react";
import { useMediaQuery } from "react-responsive";
import Swal from "sweetalert2";
import { Transition } from "@headlessui/react";

// Icons
import { ClipboardDocumentListIcon, PlusIcon, TrashIcon, } from "@heroicons/react/24/outline";

// Context
import SnackbarContext from "../../../context/psikotest/SnackbarContext";
import LoaderContext from "../../../context/psikotest/LoaderContext";

// Helper
import errorHandler from "../../../helper/psikotest/errorHandler";
import logRender from "../../../helper/psikotest/logRender";

// Controller
import EppsFormulaNorma from "../../../controller/psikotest/psikotest_system/epps_formula_norma";

// Component
import TableFilter from "../../../components/psikotest/psikotest_system/TableFilter";
import Table from "../../../components/psikotest/psikotest_system/Table";

// Modals
import FormFormulaNorma from "./modal/FormFormulaNorma";
import FormImportFormulaNorma from "./modal/FormImportFormulaNorma";

function List() {
  const { setMessage } = useContext(LoaderContext);
  const { setNotif } = useContext(SnackbarContext);
  // required state
  const [list, setList] = useState<Array<TPSEppsFormulaNorma>>([]);
  const [search, setSearch] = useState<string>("");
  // state modal
  const [isOpenFormFormulaNorma, setIsOpenFormFormulaNorma] = useState<boolean>(false);
  const [isOpenFormImportFormulaNorma, setIsOpenFormImportFormulaNorma] = useState<boolean>(false);
  // state other
  const [selected_epps_formula_norma, setSelectedEppsFormulaNorma] = useState<TPSEppsFormulaNorma | undefined>(undefined);

  useEffect(() => {
    logRender({ type: "page", name: "epps_formula_norma/List" });
  }, []);

  const getList = useCallback(async () => {
    try {
      setMessage("Fetch Epps Formula Norma");
      const epps_qc = new EppsFormulaNorma();
      const res = await epps_qc.list();
      setList(res.data.list);
      setMessage("");
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  }, [setNotif, setMessage]);

  useEffect(() => {
    getList();
  }, [getList]);

  const handleDeleteList = (id: number) => {
    const arrFilter = [...list].filter((v) => v.id !== id);
    setList(arrFilter);
  };

  // when selected_epps_formula_norma changes and not undefined open Cfit FormulaNorma form to update
  useEffect(() => {
    if (selected_epps_formula_norma !== undefined) {
      setIsOpenFormFormulaNorma(true)
    }
  }, [selected_epps_formula_norma]);

  // handleRefreshAfterOpenForm
  const handleRefreshAfterOpenForm = () => {
    // rmove selected value will close form modal
    setSelectedEppsFormulaNorma(undefined)
    // close modal
    setIsOpenFormFormulaNorma(false)
    // re-fetch data
    getList();
  }

  // handleRefreshAfterImport
  const handleRefreshAfterImport = () => {
    // close import form modal
    setIsOpenFormImportFormulaNorma(false)
    // re-fetch data
    getList();
  }

  return (
    <div className="w-full flex flex-col space-y-0.5">
      {/* Import FormulaNorma Form */}
      <Transition
        as={Fragment}
        show={isOpenFormImportFormulaNorma}
        enter="ease-out duration-50"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-50"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div>
          <FormImportFormulaNorma
            handleClose={() => setIsOpenFormImportFormulaNorma(false)}
            handleRefreshAfterImport={handleRefreshAfterImport}
          />
        </div>
      </Transition>
      {/* FormulaNorma Form */}
      <Transition
        as={Fragment}
        show={isOpenFormFormulaNorma}
        enter="ease-out duration-50"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-50"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div>
          <FormFormulaNorma data={selected_epps_formula_norma} handleClose={() => handleRefreshAfterOpenForm()} />
        </div>
      </Transition>
      <div className="flex flex-col md:flex-row items-start md:items-center">
        <div className="flex space-x-1">
          <button type="button"
            onClick={() => { setIsOpenFormImportFormulaNorma(true) }}
            className="block w-auto mb-2 md:m-0 px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded font-semibold text-sm"
          >
            <div className="flex">
              <ClipboardDocumentListIcon className="w-4" />
              <span className="block pl-1">Import</span>
            </div>
          </button>
          <button
            type="button"
            onClick={() => { setIsOpenFormFormulaNorma(true) }}
            className="block w-auto mb-2 md:m-0 px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded font-semibold text-sm"
          >
            <div className="flex">
              <PlusIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
              Create
            </div>
          </button>
        </div>
        <div className="w-full md:w-auto ml-auto flex">
          <TableFilter search={setSearch} />
        </div>
      </div>
      <div className="pb-10">
        <EppsFormulaNormaList list={list} handleDeleteList={handleDeleteList} search={search} setSelectedEppsFormulaNorma={setSelectedEppsFormulaNorma} />
      </div>
    </div >
  );
}

function EppsFormulaNormaList(props: {
  list: Array<TPSEppsFormulaNorma>;
  handleDeleteList: (id: number) => void;
  search: string,
  setSelectedEppsFormulaNorma: React.Dispatch<React.SetStateAction<TPSEppsFormulaNorma | undefined>>
}) {
  const setNotif = useContext(SnackbarContext).setNotif;
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const confirmDelete = useCallback(
    async (epps_q: TPSEppsFormulaNorma) => {
      try {
        const confirm = await Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        });
        if (confirm.isConfirmed) {
          const epps_qc = new EppsFormulaNorma();
          await epps_qc.delete(epps_q.id);
          props.handleDeleteList(epps_q.id);
          setNotif({ type: "success", message: "epps formula norma deleted" });
        }
      } catch (error) {
        const errorMessage = errorHandler(error);
        setNotif({ type: "error", message: errorMessage });
      }
    },
    [props, setNotif]
  );

  const columns = useMemo(
    () => [
      {
        id: "mobile",
        Header: "Domain Name",
        accessor: "domain_name",
        show: isMobile,
        Cell: (colProps: { row: { original: TPSEppsFormulaNorma } }) => {
          return (
            <button className="text-blue-500 hover:text-blue-800" onClick={() => props.setSelectedEppsFormulaNorma(colProps.row.original)}>
              {colProps.row.original.domain_name || "NULL"}
            </button>
          );
        },
      },
      {
        id: "domain_name",
        classHeader: "text-left px-2",
        Header: <div title="Domain Name">Domain Name</div>,
        classDivHeader: "flex items-center",
        accessor: "domain_name",
        show: !isMobile,
        Cell: (colProps: { row: { original: TPSEppsFormulaNorma } }) => {
          return (
            <button className="flex items-center text-blue-500 hover:text-blue-800" onClick={() => props.setSelectedEppsFormulaNorma(colProps.row.original)}>
              {colProps.row.original.domain_name}
            </button>
          );
        },
      },
      {
        id: "value",
        classHeader: "text-left px-2",
        Header: <div title="Value">Value</div>,
        classDivHeader: "flex items-center",
        accessor: "value",
        show: !isMobile,
        Cell: (props: { row: { original: TPSEppsFormulaNorma } }) => {
          const strValue = props.row.original.value < 0
            ? "-".repeat(Math.abs(props.row.original.value))
            : props.row.original.value === 0 ? 0 : "+".repeat(props.row.original.value)
          return (
            <div className="flex justify-between mx-4 font-bold">
              <span>{strValue}</span>
              <span>{props.row.original.value}</span>
            </div>
          )
        },
      },
      {
        id: "min_range_m",
        classHeader: "text-left px-2",
        Header: <div title="Min (M)">Min (M)</div>,
        classDivHeader: "flex items-center",
        accessor: "min_range_m",
        show: !isMobile,
        Cell: (props: { row: { original: TPSEppsFormulaNorma } }) => {
          return props.row.original.min_range_m
        },
      },
      {
        id: "max_range_m",
        classHeader: "text-left px-2",
        Header: <div title="Max (M)">Max (M)</div>,
        classDivHeader: "flex items-center",
        accessor: "max_range_m",
        show: !isMobile,
        Cell: (props: { row: { original: TPSEppsFormulaNorma } }) => {
          return props.row.original.max_range_m
        },
      },
      {
        id: "min_range_f",
        classHeader: "text-left px-2",
        Header: <div title="Min (F)">Min (F)</div>,
        classDivHeader: "flex items-center",
        accessor: "min_range_f",
        show: !isMobile,
        Cell: (props: { row: { original: TPSEppsFormulaNorma } }) => {
          return props.row.original.min_range_f
        },
      },
      {
        id: "max_range_f",
        classHeader: "text-left px-2",
        Header: <div title="Max (F)">Max (F)</div>,
        classDivHeader: "flex items-center",
        accessor: "max_range_f",
        show: !isMobile,
        Cell: (props: { row: { original: TPSEppsFormulaNorma } }) => {
          return props.row.original.max_range_f
        },
      },
      {
        id: "action_delete",
        classHeader: "w-[30px] text-white",
        Header: () => {
          return <TrashIcon className="h-5 w-auto" />;
        },
        classDivHeader: "flex justify-center",
        accessor: "id",
        show: !isMobile,
        disableSortBy: true,
        className: "text-white bg-red-500 hover:bg-red-800",
        Cell: (props: { row: { original: TPSEppsFormulaNorma } }) => {
          return (
            <button className="flex justify-center" onClick={() => confirmDelete(props.row.original)}>
              <TrashIcon className="h-5" />
            </button>
          );
        },
      },
    ],
    [isMobile, confirmDelete, props]
  );

  return <Table columns={columns} list={props.list} search={props.search} />;
}

export default List;
