import req from "../../../services/psikotest/request";

class IstFormulaNorma {
  async create(data: TPSIstFormulaNorma) {
    try {
      const res = await req.post("/api/psikotest_system/ist_formula_norma/", data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async update(data: TPSIstFormulaNorma) {
    try {
      const res = await req.put("/api/psikotest_system/ist_formula_norma/" + data.id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async delete(data: TPSIstFormulaNorma) {
    try {
      const res = await req.delete("/api/psikotest_system/ist_formula_norma/", { data });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default IstFormulaNorma;
