import { useCallback, useContext, useState, useEffect, useMemo, Fragment } from "react";
import { useMediaQuery } from "react-responsive";
import Swal from "sweetalert2";
import { Transition } from "@headlessui/react";

// Icons
import { ClipboardDocumentListIcon, PlusIcon, TrashIcon, } from "@heroicons/react/24/outline";

// Context
import SnackbarContext from "../../../context/psikotest/SnackbarContext";
import LoaderContext from "../../../context/psikotest/LoaderContext";

// Helper
import errorHandler from "../../../helper/psikotest/errorHandler";
import logRender from "../../../helper/psikotest/logRender";

// Controller
import BrsFormula from "../../../controller/psikotest/psikotest_system/brs_formula";

// Component
import TableFilter from "../../../components/psikotest/psikotest_system/TableFilter";
import Table from "../../../components/psikotest/psikotest_system/Table";

// Modals
import FormFormula from "./modal/FormFormula";
import FormImportFormula from "./modal/FormImportFormula";

function List() {
  const { setMessage } = useContext(LoaderContext);
  const { setNotif } = useContext(SnackbarContext);
  // required state
  const [list, setList] = useState<Array<TPSBrsFormula>>([]);
  const [search, setSearch] = useState<string>("");
  // state modal
  const [isOpenFormFormula, setIsOpenFormFormula] = useState<boolean>(false);
  const [isOpenFormImportFormula, setIsOpenFormImportFormula] = useState<boolean>(false);
  // state other
  const [selected_brs_formula, setSelectedBrsFormula] = useState<TPSBrsFormula | undefined>(undefined);

  useEffect(() => {
    logRender({ type: "page", name: "brs_formula/List" });
  }, []);

  const getList = useCallback(async () => {
    try {
      setMessage("Fetch Brs Formula ");
      const brs_qc = new BrsFormula();
      const res = await brs_qc.list();
      console.log(res.data)
      setList(res.data.list);
      setMessage("");
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  }, [setNotif, setMessage]);

  useEffect(() => {
    getList();
  }, [getList]);

  const handleDeleteList = (id: number) => {
    const arrFilter = [...list].filter((v) => v.id !== id);
    setList(arrFilter);
  };

  // when selected_brs_formula changes and not undefined open Cfit Formula form to update
  useEffect(() => {
    if (selected_brs_formula !== undefined) {
      setIsOpenFormFormula(true)
    }
  }, [selected_brs_formula]);

  // handleRefreshAfterOpenForm
  const handleRefreshAfterOpenForm = () => {
    // rmove selected value will close form modal
    setSelectedBrsFormula(undefined)
    // close modal
    setIsOpenFormFormula(false)
    // re-fetch data
    getList();
  }

  // handleRefreshAfterImport
  const handleRefreshAfterImport = () => {
    // close import form modal
    setIsOpenFormImportFormula(false)
    // re-fetch data
    getList();
  }

  return (
    <div className="w-full flex flex-col space-y-0.5">
      {/* Import Formula Form */}
      <Transition
        as={Fragment}
        show={isOpenFormImportFormula}
        enter="ease-out duration-50"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-50"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div>
          <FormImportFormula
            handleClose={() => setIsOpenFormImportFormula(false)}
            handleRefreshAfterImport={handleRefreshAfterImport}
          />
        </div>
      </Transition>
      {/* Formula Form */}
      <Transition
        as={Fragment}
        show={isOpenFormFormula}
        enter="ease-out duration-50"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-50"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div>
          <FormFormula data={selected_brs_formula} handleClose={() => handleRefreshAfterOpenForm()} />
        </div>
      </Transition>
      <div className="flex flex-col md:flex-row items-start md:items-center">
        <div className="flex space-x-1">
          <button type="button"
            onClick={() => { setIsOpenFormImportFormula(true) }}
            className="block w-auto mb-2 md:m-0 px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded font-semibold text-sm"
          >
            <div className="flex">
              <ClipboardDocumentListIcon className="w-4" />
              <span className="block pl-1">Import</span>
            </div>
          </button>
          <button
            type="button"
            onClick={() => { setIsOpenFormFormula(true) }}
            className="block w-auto mb-2 md:m-0 px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded font-semibold text-sm"
          >
            <div className="flex">
              <PlusIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
              Create
            </div>
          </button>
        </div>
        <div className="w-full md:w-auto ml-auto flex">
          <TableFilter search={setSearch} />
        </div>
      </div>
      <div className="pb-10">
        <BrsFormulaList list={list} handleDeleteList={handleDeleteList} search={search} setSelectedBrsFormula={setSelectedBrsFormula} />
      </div>
    </div >
  );
}

function BrsFormulaList(props: {
  list: Array<TPSBrsFormula>;
  handleDeleteList: (id: number) => void;
  search: string,
  setSelectedBrsFormula: React.Dispatch<React.SetStateAction<TPSBrsFormula | undefined>>
}) {
  const setNotif = useContext(SnackbarContext).setNotif;
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const confirmDelete = useCallback(
    async (brs_q: TPSBrsFormula) => {
      try {
        const confirm = await Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        });
        if (confirm.isConfirmed) {
          const brs_qc = new BrsFormula();
          await brs_qc.delete(brs_q.id);
          props.handleDeleteList(brs_q.id);
          setNotif({ type: "success", message: "brs formula norma deleted" });
        }
      } catch (error) {
        const errorMessage = errorHandler(error);
        setNotif({ type: "error", message: errorMessage });
      }
    },
    [props, setNotif]
  );

  const columns = useMemo(
    () => [
      {
        id: "mobile",
        Header: "Interpretation",
        accessor: "interpretation",
        show: isMobile,
        Cell: (colProps: { row: { original: TPSBrsFormula } }) => {
          return (
            <button className="text-blue-500 hover:text-blue-800" onClick={() => props.setSelectedBrsFormula(colProps.row.original)}>
              {colProps.row.original.interpretation || "NULL"}
            </button>
          );
        },
      },
      {
        id: "interpretation",
        classHeader: "text-left px-2",
        Header: <div title="Interpretation">Interpretation</div>,
        classDivHeader: "flex items-center",
        accessor: "interpretation",
        show: !isMobile,
        Cell: (colProps: { row: { original: TPSBrsFormula } }) => {
          return (
            <button className="flex items-center text-blue-500 hover:text-blue-800" onClick={() => props.setSelectedBrsFormula(colProps.row.original)}>
              {colProps.row.original.interpretation}
            </button>
          );
        },
      },
      {
        id: "min_score",
        classHeader: "text-left px-2",
        Header: <div title="Min Score">Min Score</div>,
        classDivHeader: "flex items-center",
        accessor: "min_score",
        show: !isMobile,
        Cell: (props: { row: { original: TPSBrsFormula } }) => {
          return props.row.original.min_score
        },
      },
      {
        id: "max_score",
        classHeader: "text-left px-2",
        Header: <div title="Max Score">Max Score</div>,
        classDivHeader: "flex items-center",
        accessor: "max_score",
        show: !isMobile,
        Cell: (props: { row: { original: TPSBrsFormula } }) => {
          return props.row.original.max_score
        },
      },
      {
        id: "action_delete",
        classHeader: "w-[30px] text-white",
        Header: () => {
          return <TrashIcon className="h-5 w-auto" />;
        },
        classDivHeader: "flex justify-center",
        accessor: "id",
        show: !isMobile,
        disableSortBy: true,
        className: "text-white bg-red-500 hover:bg-red-800",
        Cell: (props: { row: { original: TPSBrsFormula } }) => {
          return (
            <button className="flex justify-center" onClick={() => confirmDelete(props.row.original)}>
              <TrashIcon className="h-5" />
            </button>
          );
        },
      },
    ],
    [isMobile, confirmDelete, props]
  );

  return <Table columns={columns} list={props.list} search={props.search} />;
}

export default List;
