import { useCallback, useContext, useEffect, useState } from "react";

// Icons
import { XMarkIcon } from "@heroicons/react/24/outline";

// Context
import SnackbarContext from "../../../context/assessment/SnackbarContext";

// Helper
import errorHandler from "../../../helper/assessment/errorHandler";

// Controller
import Assessment from "../../../controller/assessment/assessment_system/assessment";

declare type SessionFileCam = {
  file: string[];
  assessmentParticipantSession: TAssessmentParticipantSession;
};

function FormParticipantSnapshot(props: {
  selectedParticipantSnapshot: number;
  setSelectedParticipantSnapshot: React.Dispatch<React.SetStateAction<number | undefined>>;
}) {
  // Context
  const { setNotif } = useContext(SnackbarContext);
  // State
  const [assessmentSessionCam, setAssessmentSessionCam] = useState<SessionFileCam[]>([]);

  const getDoc = useCallback(async () => {
    try {
      // get all assessment_participant_session by assessment_participant_id
      const ac = new Assessment();
      const resGetAssessmentParticipantSession = await ac.listPerParticipantSession(
        props.selectedParticipantSnapshot.toString()
      );
      let prepFileCam: SessionFileCam[] = [];
      for (let index = 0; index < resGetAssessmentParticipantSession.data.list.length; index++) {
        const element: TAssessmentParticipantSession = resGetAssessmentParticipantSession.data.list[index];
        if (element.id !== undefined) {
          const resGetListSnapshot = await ac.listParticipantSnapshot(element.id.toString(), element.assessment_id.toString());
          prepFileCam.push({
            file: resGetListSnapshot.data.list,
            assessmentParticipantSession: element,
          });
        }
      }
      setAssessmentSessionCam(prepFileCam);
    } catch (error) {
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  }, [setNotif, props.selectedParticipantSnapshot]);

  useEffect(() => {
    getDoc();
  }, [getDoc]);

  return (
    <div>
      <div className="fixed z-20 top-0 left-0 w-screen h-screen bg-black opacity-20"></div>
      <div className="fixed z-30 top-0 left-0 w-screen h-screen bg-transparent flex items-center justify-center">
        <div className="bg-white w-screen md:w-[96%] h-full md:h-[96%] md:rounded overflow-hidden">
          <div className="p-4 bg-yellow-600 md:rounded-t text-white flex justify-between">
            <h3>Participant Snapshot</h3>
            <button type="button" onClick={() => props.setSelectedParticipantSnapshot(undefined)}>
              <XMarkIcon className="w-6" />
            </button>
          </div>
          <div className="h-[89vh] p-4 overflow-auto">
            {assessmentSessionCam.map((asc, asc_i) => (
              <div key={`asc_${asc_i}`}>
                <div className="py-2 font-semibold text-xl">{asc.assessmentParticipantSession.category}</div>
                <div className="flex flex-wrap justify-left">
                  {asc.file.map((file, file_i) => {
                    let timestamp = parseInt(file.split("\\")[file.split("\\").length - 1].split(".")[0]);
                    let datestamp: string = "";
                    if (isNaN(timestamp) === false) {
                      datestamp = new Date(timestamp * 1000).toString().substring(0, 24);
                    }
                    return (
                      <div className="p-1 relative" key={`file_${file_i}`}>
                        <img
                          className="w-40"
                          alt={`${file.replace(/\\/g, "-")}`}
                          src={`${process.env.REACT_APP_URL}/upload/assessment_system/get_snapshot/${file.replace(/\\/g, "/")}`}
                        />
                        <span className="absolute right-2 bottom-1 text-white text-xs">{datestamp}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormParticipantSnapshot;
