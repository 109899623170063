
import { useCallback, useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";

// Icons
import { PlusIcon } from "@heroicons/react/24/outline";

// Context
import SnackbarContext from "../../../context/psikotest/SnackbarContext";
import LoaderContext from "../../../context/psikotest/LoaderContext";

// Helper
import errorHandler from "../../../helper/psikotest/errorHandler";

// Controller
import CfitFormula from "../../../controller/psikotest/psikotest_system/cfit_formula";
import CfitFormulaPersentil from "../../../controller/psikotest/psikotest_system/cfit_formula_persentil";

// Costant Value
const minIqValue = 73
const minRsValue = 17
const maxFormula = 60

function ListPersentil(props: { isCreate: boolean; cf: TPSCfitFormula; editable: boolean }) {
    const { setMessage } = useContext(LoaderContext)
    const { setNotif } = useContext(SnackbarContext)
    // state list persentil
    const [persentil, setPersentil] = useState<TPSCfitFormulaPersentil[]>([])

    const getDoc = useCallback(
        async function getDoc() {
            try {
                if (!props.isCreate) {
                    if (props.cf.id) {
                        // fetch cfit_persentil list
                        setMessage("Fetch Cfit Formula");
                        const ccf = new CfitFormula();
                        const res = await ccf.persentil(props.cf.id);
                        const list: TPSCfitFormulaPersentil[] = res.data.list;
                        setPersentil(list);
                    }
                }
                setMessage("");
            } catch (error) {
                setMessage("");
                const errorMessage = errorHandler(error);
                setNotif({ type: "error", message: errorMessage });
            }
        },
        [setNotif, setMessage, props.cf.id, props.isCreate]
    );

    useEffect(() => {
        getDoc();
    }, [getDoc]);

    // handle create empty persentil
    const createEmptyPersentil = async () => {
        try {
            let createData: TPSCfitFormulaPersentil = {
                id: 0,
                cfit_formula_id: props.cf.id,
                rs: persentil.length === 0
                    ? minRsValue
                    : persentil[persentil.length - 1]?.rs === null
                        ? null
                        : (persentil[persentil.length - 1]?.rs ?? 0) + 1,
                iq: persentil.length === 0
                    ? minIqValue
                    : persentil[persentil.length - 1]?.iq === null
                        ? null
                        : (persentil[persentil.length - 1]?.iq ?? 0) + 1
            }
            const ccfp = new CfitFormulaPersentil();
            const res = await ccfp.create(createData)
            createData.id = res.data.result.saved_id
            // push new created persentil to persentil array
            setPersentil([...persentil, createData])
        } catch (error) {
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }

    // handle update 
    const updatePersentil = async (e: React.ChangeEvent<HTMLInputElement>, data: TPSCfitFormulaPersentil, index: number) => {
        try {
            let updateData: TPSCfitFormulaPersentil = {
                id: data.id,
                cfit_formula_id: data.cfit_formula_id,
                rs: e.target.name === "rs" ? parseInt(e.target.value) : data.rs,
                iq: e.target.name === "iq" ? parseInt(e.target.value) : data.iq
            }
            // make sure rs not 0
            updateData.rs = updateData.rs === 0 ? null : updateData.rs
            // copy to update state 
            const copyPersentil = [...persentil];
            copyPersentil[index] = updateData
            // update state
            setPersentil(copyPersentil)
            // update to server
            const ccfp = new CfitFormulaPersentil();
            await ccfp.update(updateData)
        } catch (error) {
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
        // re-fetch data
        // make sure run even on error
        getDoc();
    }

    // handle delete
    const confirmDelete = useCallback(
        async (cfp: TPSCfitFormulaPersentil) => {
            try {
                const confirm = await Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                });
                if (confirm.isConfirmed) {
                    const ccfp = new CfitFormulaPersentil();
                    await ccfp.delete(cfp);
                    setNotif({ type: "success", message: "cfit deleted" });
                    // re-fetch data
                    getDoc();
                }
            } catch (error) {
                const errorMessage = errorHandler(error);
                setNotif({ type: "error", message: errorMessage });
            }
        },
        [setNotif, getDoc]
    );

    return (
        <div className="pb-[40rem]">
            {persentil.length < maxFormula && props.editable && (
                <div className="w-full md:w-full mb-2">
                    <button
                        type="button"
                        onClick={() => { createEmptyPersentil() }}
                        className="block w-auto px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded font-semibold text-sm"
                    >
                        <div className="flex">
                            <PlusIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
                            Persentil
                        </div>
                    </button>
                </div>
            )}
            <div className="pt-2">
                <table className="w-full border">
                    <thead className="bg-slate-600 text-white">
                        <tr>
                            <th className="py-1 px-2 text-left border border-slate-500">RS</th>
                            <th className="py-1 px-2 text-left border border-slate-500">IQ</th>
                            <th className="py-1 px-2 w-20 border border-slate-500">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {persentil.map((p, i) => {
                            return (
                                <tr key={`persentil_${i}`}>
                                    <td className="py-1 px-2 border">
                                        <input type="number" name="rs" className="border px-3" value={p.rs ?? 0} onChange={(e) => updatePersentil(e, p, i)} />
                                    </td>
                                    <td className="py-1 px-2 border">
                                        <input type="number" name="iq" className="border px-3" value={p.iq ?? 0} onChange={(e) => updatePersentil(e, p, i)} />
                                    </td>
                                    <td className="py-1 px-2 border text-center">
                                        <button type="button" className="px-2 py-[2px] rounded bg-red-500 text-white" onClick={() => confirmDelete(p)}>
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ListPersentil;
