import req from "../../../services/psikotest/request";

class Resilience {
  async get() {
    try {
      const res = await req.get("/api/psikotest_system/resilience/");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async update(data: TPSResilience) {
    try {
      const res = await req.put("/api/psikotest_system/resilience/", data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default Resilience;
