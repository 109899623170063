import req from "../../../services/psikotest/request";

class AssessmentParticipantIstSessionAnswer {
  async update_score(data: TPSAssessmentParticipantIstSessionAnswer) {
    try {
      const res = await req.put("/api/psikotest_system/assessment_participant_ist_session_answer/" + data.id + "/update_score", data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default AssessmentParticipantIstSessionAnswer;
