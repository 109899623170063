import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { useCallback, useContext, useEffect, useState } from "react";

// Icons
import { XMarkIcon, ArrowDownOnSquareIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

// Contexts
import LoaderContext from "../../../../context/psikotest/LoaderContext";
import SnackbarContext from "../../../../context/psikotest/SnackbarContext";

// Helpers
import errorHandler from "../../../../helper/psikotest/errorHandler";

// Component
import FileUpload from "../../../../components/psikotest/psikotest_system/FileUpload";

// Controller
import Ist from "../../../../controller/psikotest/psikotest_system/ist";
import IstQuestionary from "../../../../controller/psikotest/psikotest_system/ist_questionary";

const schema_base = {
    id: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required(),
    ist_id: yup
        .number()
        .label("IST ID")
        .transform((value) => (isNaN(value) ? undefined : value))
        .required(),
    correct_answer: yup
        .string()
        .label("Correct Answer")
        .transform((value) => (value === null ? "" : value))
        .required(),
};
const schema_question_image_required = {
    ist_questionary_image_id: yup
        .number()
        .label("IST Questionary Image")
        .transform((value) => (isNaN(value) ? null : value))
        .required()
        .nullable(),
};
const schema_question_required = {
    question: yup.string().label("Question").required(),
};
const schema_pilihan_required = {
    answer_a: yup.string().label("Answer A").required(),
    answer_b: yup.string().label("Answer B").required(),
    answer_c: yup.string().label("Answer C").required(),
    answer_d: yup.string().label("Answer D").required(),
    answer_e: yup.string().label("Answer E").required()
}

function FormQuestionary(props: { data?: TPSIstQuestionary, ist: TPSIst, handleClose: () => void }) {
    const { setMessage } = useContext(LoaderContext)
    const { setNotif } = useContext(SnackbarContext)
    // state list questionary
    const [questionaryImage, setQuestionaryImage] = useState<TPSIstQuestionaryImage[]>([])
    // set schema
    let schema: any = schema_base
    if (props.ist.with_question === 1) {
        schema = { ...schema, ...schema_question_required }
    }
    if (props.ist.answer_type === "pilihan") {
        schema = { ...schema, ...schema_pilihan_required }
    }
    if (props.ist.with_mdi_question_image === 1) {
        schema = { ...schema, ...schema_question_image_required }
    }
    schema = yup.object().shape(schema)

    // react hook form
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch
    } = useForm<TPSIstQuestionary>({ resolver: yupResolver(schema) });
    // watch all field
    const doc = watch()

    const getDoc = useCallback(
        async function getDoc() {
            try {
                setMessage("Fetch IST Questionary Image");
                const ic = new Ist();
                const res = await ic.questionary_image(props.ist.id);
                const list: TPSIstQuestionaryImage[] = res.data.list;
                setQuestionaryImage(list);
                // fetch document
                if (props.data === undefined) {
                    // update cfit_id
                    setValue("id", 0);
                    setValue("ist_id", props.ist.id);
                    if (props.ist.with_mdi_question_image === 1) {
                        setValue("answer_a", "Gambar A");
                        setValue("answer_b", "Gambar B");
                        setValue("answer_c", "Gambar C");
                        setValue("answer_d", "Gambar D");
                        setValue("answer_e", "Gambar E");
                    }
                    if (list.length > 0) {
                        setValue("ist_questionary_image_id", list[0].id)
                    }
                } else {
                    setValue("id", props.data?.id ?? 0);
                    setValue("ist_id", props.ist?.id ?? props.ist.id);
                    setValue("ist_questionary_image_id", props.data?.ist_questionary_image_id ?? null);
                    setValue("question", props.data?.question ?? null);
                    setValue("answer_a", props.data?.answer_a ?? null);
                    setValue("answer_b", props.data?.answer_b ?? null);
                    setValue("answer_c", props.data?.answer_c ?? null);
                    setValue("answer_d", props.data?.answer_d ?? null);
                    setValue("answer_e", props.data?.answer_e ?? null);
                    setValue("correct_answer", props.data?.correct_answer ?? "");
                }
                setMessage("");
            } catch (error) {
                setMessage("");
                const errorMessage = errorHandler(error);
                setNotif({ type: "error", message: errorMessage });
            }
        },
        [setValue, setNotif, setMessage, props.ist.id, props.data, props.ist.with_mdi_question_image]
    );

    useEffect(() => {
        getDoc();
    }, [getDoc]);

    const onSubmit: SubmitHandler<TPSIstQuestionary> = async (data) => {
        try {
            setMessage("Save Ist Questionary");
            const iqc = new IstQuestionary();
            let res;
            if (doc.id === 0) {
                res = await iqc.create(data);
            } else {
                res = await iqc.update(data);
            }
            setNotif({ type: "success", message: res.data.message });
            setMessage("");
            props.handleClose();
        } catch (error) {
            setMessage("");
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    };

    return <div>
        <div className="fixed z-20 top-0 left-0 w-screen h-screen bg-black opacity-20"></div>
        <div className="fixed z-30 top-0 left-0 w-screen h-screen bg-transparent flex items-center justify-center">
            <div className="bg-white w-screen md:w-[50%] h-auto md:rounded overflow-hidden">
                <div className="p-4 bg-green-800 md:rounded-t text-white flex justify-between">
                    <h3>Form Questionary</h3>
                    <XMarkIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent cursor-pointer" aria-hidden="true" onClick={() => props.handleClose()} />
                </div>
                <div className="p-4 pb-40">
                    {props.ist.with_mdi_question_image === 1 &&
                        <div className="pb-3">
                            {/** (MDI) Image */}
                            <div className="w-full md:w-full mb-1">
                                <FileUpload
                                    type="ist_questionary"
                                    editable={true}
                                    document_id={doc.id}
                                    filepath={doc.question ?? ""}
                                    error_field={errors.question}
                                    otherRequiredData={{ ist_id: props.ist.id }}
                                    setDocumentId={(id) => {
                                        setValue("id", id)
                                    }}
                                    setFilePath={(filepath) => setValue("question", filepath)}
                                    key={`ist_questionary`}
                                />
                            </div>
                        </div>
                    }
                    {((props.ist.with_mdi_question_image === 1 && doc.id !== 0) || (props.ist.with_mdi_question_image !== 1)) &&
                        <form className="w-full">
                            <div className="flex flex-wrap -mx-3">
                                <input {...register("id")} type="number" className="hidden" />
                                {errors.id && (
                                    <p className="text-red-500 text-xs italic">{errors.id.message}</p>
                                )}
                                <input {...register("ist_id")} type="number" className="hidden" />
                                {errors.ist_id && (
                                    <p className="text-red-500 text-xs italic">{errors.ist_id.message}</p>
                                )}
                                {props.ist.with_mdi_question_image === 1 &&
                                    <div className="w-full md:w-full px-3 mb-6">
                                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Question Image</label>
                                        <div className="flex space-x-2 pb-2">
                                            {questionaryImage.map((qi, i) => {
                                                return (
                                                    <div
                                                        key={`questionary_image_${i}`}
                                                        className={`w-1/2 p-3 rounded border-2 cursor-pointer
                                                            ${doc.ist_questionary_image_id === qi.id ? "border-green-500" : "border-gray-500"}`
                                                        }
                                                        onClick={() => setValue("ist_questionary_image_id", qi.id)}
                                                    >
                                                        <img src={`${process.env.REACT_APP_URL}/upload/psikotest_system/get_ist_questionary_image/${qi.ist_questionary_image}`} alt="" />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        {errors.ist_questionary_image_id && <p className="text-red-500 text-xs italic">{errors.ist_questionary_image_id.message}</p>}
                                    </div>
                                }
                                {/** Question */}
                                {props.ist.with_question === 1 && props.ist.with_mdi_question_image !== 1 &&
                                    <div className="w-full md:w-full px-3 mb-6">
                                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Question</label>
                                        <input
                                            className={
                                                (errors?.question ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                                                " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                                            }
                                            {...register("question")}
                                        />
                                        {errors.question && <p className="text-red-500 text-xs italic">{errors.question.message}</p>}
                                    </div>
                                }
                                {/** Answer List */}
                                {props.ist.answer_type === "pilihan" &&
                                    <div className="w-full mb-6">
                                        <div className="w-full md:w-full px-3 mb-2">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Answer</label>
                                        </div>
                                        <div className="w-full md:w-full px-3 mb-2">
                                            <div className=" flex items-center">
                                                <label className="block uppercase tracking-wide text-gray-700 font-bold p-2 min-w-[30px]">A</label>
                                                <input
                                                    className={
                                                        (errors?.answer_a ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                                                        " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500 " +
                                                        (doc.correct_answer === doc.answer_a ? "bg-green-200" : "bg-gray-200")
                                                    }
                                                    {...register("answer_a")}
                                                />
                                                <div className="p-3 cursor-pointer" onClick={() => { setValue("correct_answer", doc.answer_a ?? "") }}>
                                                    {doc.correct_answer === doc.answer_a
                                                        ? <CheckCircleIcon className="w-5 text-green-600" />
                                                        : <XCircleIcon className="w-5 text-red-600" />}
                                                </div>
                                            </div>
                                            {errors.answer_a && <p className="text-red-500 text-xs italic pl-8">{errors.answer_a.message}</p>}
                                        </div>
                                        <div className="w-full md:w-full px-3 mb-2">
                                            <div className=" flex items-center">
                                                <label className="block uppercase tracking-wide text-gray-700 font-bold p-2 min-w-[30px]">B</label>
                                                <input
                                                    className={
                                                        (errors?.answer_b ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                                                        " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500 " +
                                                        (doc.correct_answer === doc.answer_b ? "bg-green-200" : "bg-gray-200")
                                                    }
                                                    {...register("answer_b")}
                                                />
                                                <div className="p-3 cursor-pointer" onClick={() => { setValue("correct_answer", doc.answer_b ?? "") }}>
                                                    {doc.correct_answer === doc.answer_b
                                                        ? <CheckCircleIcon className="w-5 text-green-600" />
                                                        : <XCircleIcon className="w-5 text-red-600" />}
                                                </div>
                                            </div>
                                            {errors.answer_b && <p className="text-red-500 text-xs italic pl-8">{errors.answer_b.message}</p>}
                                        </div>
                                        <div className="w-full md:w-full px-3 mb-2">
                                            <div className=" flex items-center">
                                                <label className="block uppercase tracking-wide text-gray-700 font-bold p-2 min-w-[30px]">C</label>
                                                <input
                                                    className={
                                                        (errors?.answer_c ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                                                        " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500 " +
                                                        (doc.correct_answer === doc.answer_c ? "bg-green-200" : "bg-gray-200")
                                                    }
                                                    {...register("answer_c")}
                                                />
                                                <div className="p-3 cursor-pointer" onClick={() => { setValue("correct_answer", doc.answer_c ?? "") }}>
                                                    {doc.correct_answer === doc.answer_c
                                                        ? <CheckCircleIcon className="w-5 text-green-600" />
                                                        : <XCircleIcon className="w-5 text-red-600" />}
                                                </div>
                                            </div>
                                            {errors.answer_c && <p className="text-red-500 text-xs italic pl-8">{errors.answer_c.message}</p>}
                                        </div>
                                        <div className="w-full md:w-full px-3 mb-2">
                                            <div className=" flex items-center">
                                                <label className="block uppercase tracking-wide text-gray-700 font-bold p-2 min-w-[30px]">D</label>
                                                <input
                                                    className={
                                                        (errors?.answer_d ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                                                        " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500 " +
                                                        (doc.correct_answer === doc.answer_d ? "bg-green-200" : "bg-gray-200")
                                                    }
                                                    {...register("answer_d")}
                                                />
                                                <div className="p-3 cursor-pointer" onClick={() => { setValue("correct_answer", doc.answer_d ?? "") }}>
                                                    {doc.correct_answer === doc.answer_d
                                                        ? <CheckCircleIcon className="w-5 text-green-600" />
                                                        : <XCircleIcon className="w-5 text-red-600" />}
                                                </div>
                                            </div>
                                            {errors.answer_d && <p className="text-red-500 text-xs italic pl-8">{errors.answer_d.message}</p>}
                                        </div>
                                        <div className="w-full md:w-full px-3 mb-2">
                                            <div className=" flex items-center">
                                                <label className="block uppercase tracking-wide text-gray-700 font-bold p-2 min-w-[30px]">E</label>
                                                <input
                                                    className={
                                                        (errors?.answer_e ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                                                        " first-letter:appearance-none block w-full  text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500 " +
                                                        (doc.correct_answer === doc.answer_e ? "bg-green-200" : "bg-gray-200")
                                                    }
                                                    {...register("answer_e")}
                                                />
                                                <div className="p-3 cursor-pointer" onClick={() => { setValue("correct_answer", doc.answer_e ?? "") }}>
                                                    {doc.correct_answer === doc.answer_e
                                                        ? <CheckCircleIcon className="w-5 text-green-600" />
                                                        : <XCircleIcon className="w-5 text-red-600" />}
                                                </div>
                                            </div>
                                            {errors.answer_e && <p className="text-red-500 text-xs italic pl-8">{errors.answer_e.message}</p>}
                                            {errors.correct_answer && <p className="text-red-500 text-xs italic pl-8">Please select Correct Answer</p>}
                                        </div>
                                    </div>
                                }
                                {/** Correct Answer */}
                                {props.ist.answer_type !== "pilihan" &&
                                    <div className="w-full md:w-full px-3 mb-6">
                                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Correct Answer</label>
                                        <input
                                            type={props.ist.answer_type === "input_number" ? "number" : "text"}
                                            className={
                                                (errors?.correct_answer ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                                                " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                                            }
                                            {...register("correct_answer")}
                                        />
                                        {errors.correct_answer && <p className="text-red-500 text-xs italic">{errors.correct_answer.message}</p>}
                                    </div>
                                }
                            </div>
                        </form>
                    }
                    <div className="flex">
                        <button
                            onClick={handleSubmit(onSubmit)}
                            className="ml-auto block w-auto px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded font-semibold text-sm"
                        >
                            <div className="flex">
                                <ArrowDownOnSquareIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
                                Save
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div >
    </div >
}


export default FormQuestionary;