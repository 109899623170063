import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { useCallback, useContext, useEffect } from "react";

// Icons
import { XMarkIcon, ArrowDownOnSquareIcon } from "@heroicons/react/24/outline";

// Contexts
import LoaderContext from "../../../../context/psikotest/LoaderContext";
import SnackbarContext from "../../../../context/psikotest/SnackbarContext";

// Helpers
import errorHandler from "../../../../helper/psikotest/errorHandler";

// Controller
import MbtiQuestionary from "../../../../controller/psikotest/psikotest_system/mbti_questionary";

// Helper Value
const valueList = ['(I) Introvert', '(E) Extrovert', '(S) Sensing', '(N) Intuition', '(T) Thinking', '(F) Feeling', '(J) Judging', '(P) Perceiving'];
const valueListOption = valueList.map(v => { return { id: v, label: v } })

const schema_base = {
    id: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required(),
    number: yup
        .number()
        .label("Number")
        .transform((value) => (isNaN(value) ? undefined : value))
        .required(),
    statement_a: yup
        .string()
        .label("Statement A")
        .transform((value) => (value === null ? "" : value))
        .required(),
    statement_a_value: yup
        .string()
        .label("Statement A Value")
        .transform((value) => (value === null ? "" : value))
        .required(),
    statement_b: yup
        .string()
        .label("Statement B")
        .transform((value) => (value === null ? "" : value))
        .required(),
    statement_b_value: yup
        .string()
        .label("Statement B Value")
        .transform((value) => (value === null ? "" : value))
        .required(),
};

function FormQuestionary(props: { data?: TPSMbtiQuestionary, nextNumber?: number, handleClose: () => void }) {
    // Contexts
    const { setMessage } = useContext(LoaderContext)
    const { setNotif } = useContext(SnackbarContext)

    // set schema
    let schema: any = yup.object().shape(schema_base)

    // react hook form
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch
    } = useForm<TPSMbtiQuestionary>({ resolver: yupResolver(schema) });
    // watch all field
    const doc = watch()

    const getDoc = useCallback(
        async function getDoc() {
            try {
                setMessage("Fetch MBTI Questionary Image");
                // fetch document
                if (props.data === undefined) {
                    // update cfit_id
                    setValue("id", 0);
                    setValue("number", props?.nextNumber ?? 0);
                    setValue("statement_a", "");
                    setValue("statement_a_value", "");
                    setValue("statement_b", "");
                    setValue("statement_b_value", "");
                } else {
                    setValue("id", props.data?.id ?? 0);
                    setValue("number", props.data?.number ?? 0);
                    setValue("statement_a", props.data?.statement_a ?? "");
                    setValue("statement_a_value", props.data?.statement_a_value ?? "");
                    setValue("statement_b", props.data?.statement_b ?? "");
                    setValue("statement_b_value", props.data?.statement_b_value ?? "");
                }
                setMessage("");
            } catch (error) {
                setMessage("");
                const errorMessage = errorHandler(error);
                setNotif({ type: "error", message: errorMessage });
            }
        },
        [setValue, setNotif, setMessage, props.data, props.nextNumber]
    );

    useEffect(() => {
        getDoc();
    }, [getDoc]);

    const onSubmit: SubmitHandler<TPSMbtiQuestionary> = async (data) => {
        try {
            setMessage("Save Mbti Questionary");
            const mbti_qc = new MbtiQuestionary();
            let res;
            if (doc.id === 0) {
                res = await mbti_qc.create(data);
            } else {
                res = await mbti_qc.update(data.id, data);
            }
            setNotif({ type: "success", message: res.data.message });
            setMessage("");
            props.handleClose();
        } catch (error) {
            setMessage("");
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    };

    return <div>
        <div className="fixed z-20 top-0 left-0 w-screen h-screen bg-black opacity-20"></div>
        <div className="fixed z-30 top-0 left-0 w-screen h-screen bg-transparent flex items-center justify-center">
            <div className="bg-white w-screen md:w-[60%] h-auto md:rounded overflow-hidden">
                <div className="p-4 bg-green-800 md:rounded-t text-white flex justify-between">
                    <h3>Form Questionary</h3>
                    <XMarkIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent cursor-pointer" aria-hidden="true" onClick={() => props.handleClose()} />
                </div>
                <div className="p-4 pb-40">
                    <form className="w-full">
                        <div className="flex flex-wrap -mx-3">
                            {/** ID */}
                            <input {...register("id")} type="number" className="hidden" />
                            {errors.id && (
                                <p className="text-red-500 text-xs italic">{errors.id.message}</p>
                            )}
                            {/** Number */}
                            <div className="w-full md:w-full px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Number (Question Order)<br />
                                    <span className="capitalize font-normal italic">make sure number is unique</span>
                                </label>
                                <input
                                    type="number"
                                    className={
                                        (errors?.number ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                                        " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                                    }
                                    {...register("number")}
                                />
                                {errors.number && <p className="text-red-500 text-xs italic">{errors.number.message}</p>}
                            </div>
                            {/** Statement A */}
                            <div className="w-full md:w-3/4 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Statement A</label>
                                <input
                                    className={
                                        (errors?.statement_a ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                                        " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                                    }
                                    {...register("statement_a")}
                                />
                                {errors.statement_a && <p className="text-red-500 text-xs italic">{errors.statement_a.message}</p>}
                            </div>
                            {/** Statement A Value */}
                            <div className="w-full md:w-1/4 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Statement A Value</label>
                                <div className="relative after:content-['↓'] after:absolute after:right-4 after:top-3 after:pointer-events-none">
                                    <select
                                        className={
                                            (errors?.statement_a_value
                                                ? "border-red-500"
                                                : "border-gray-200 focus:border-gray-500") +
                                            " appearance-none first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-800"
                                        }
                                        disabled={false} defaultValue="" {...register("statement_a_value")}
                                    >
                                        <option value="" disabled>Please Select</option>
                                        {valueListOption.map((v, i) => (
                                            <option key={i} value={v.id}>
                                                {v.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                {errors.statement_a_value && <p className="text-red-500 text-xs italic">{errors.statement_a_value.message}</p>}
                            </div>
                            {/** Statement B */}
                            <div className="w-full md:w-3/4 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Statement B</label>
                                <input
                                    className={
                                        (errors?.statement_b ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                                        " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                                    }
                                    {...register("statement_b")}
                                />
                                {errors.statement_b && <p className="text-red-500 text-xs italic">{errors.statement_b.message}</p>}
                            </div>
                            {/** Statement B Value */}
                            <div className="w-full md:w-1/4 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Statement B Value</label>
                                <div className="relative after:content-['↓'] after:absolute after:right-4 after:top-3 after:pointer-events-none">
                                    <select
                                        className={
                                            (errors?.statement_b_value
                                                ? "border-red-500"
                                                : "border-gray-200 focus:border-gray-500") +
                                            " appearance-none first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-800"
                                        }
                                        disabled={false} defaultValue="" {...register("statement_b_value")}
                                    >
                                        <option value="" disabled>Please Select</option>
                                        {valueListOption.map((v, i) => (
                                            <option key={i} value={v.id}>
                                                {v.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                {errors.statement_b_value && <p className="text-red-500 text-xs italic">{errors.statement_b_value.message}</p>}
                            </div>
                        </div>
                    </form>
                    <div className="flex">
                        <button
                            onClick={handleSubmit(onSubmit)}
                            className="ml-auto block w-auto px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded font-semibold text-sm"
                        >
                            <div className="flex">
                                <ArrowDownOnSquareIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
                                Save
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div >
    </div >
}


export default FormQuestionary;