import React from "react";

interface AssessmentContextType {
  errorValidate: string;
  assessment: TPAssessmentDetail;
  participant: TPAssessmentParticipantDetail;
  timeRemaining: number | null;
  setTimeRemaining: React.Dispatch<React.SetStateAction<number | null>>;
  validateAssessmentParticipant: (accessKey: string) => void;
  updateAssessmentParticipant: (assessment: TPAssessmentDetail, participant: TPAssessmentParticipantDetail) => void;
}

let AssessmentContext = React.createContext<AssessmentContextType>(null!);

export default AssessmentContext;
