import { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Disclosure, Transition } from '@headlessui/react'
import { useDebounce } from 'use-debounce';

// Context
import PPAssessmentContext from "../../../context/psikotest/PPAssessmentContext";
import SnackbarContext from "../../../context/psikotest/SnackbarContext";

// Handler
import errorHandler from "../../../helper/psikotest/errorHandler";

// Controller
import ParticipantSession from "../../../controller/psikotest/psikotest_participant/session";

// Value Helper
const alphabet_index = ["a", "b", "c", "d", "e"];

function SubtestIst(props: { assessment_participant_session: TPPAssessmentParticipantSession, closeSession: () => void }) {
    // Context
    const { timeRemaining } = useContext(PPAssessmentContext);
    const { setNotif } = useContext(SnackbarContext);

    // State
    const [selected_questionary, setSelectedQuestionary] = useState<TPPAssessmentSessionIstQuestionary | undefined>(undefined)
    const [ist_questionary, setIstQuestionary] = useState<TPPAssessmentSessionIstQuestionary[]>([])
    const [showMdiTemporary, setShowMdiTemporary] = useState<boolean>(false)

    // Helper
    const [answer, setAnswer] = useState<null | string>(null);
    const [inputValue] = useDebounce(answer, 500);
    const inputAnswerRef = useRef<HTMLInputElement>(null);

    const getDoc = useCallback(async () => {
        try {
            // get all question in subtest id
            const psc = new ParticipantSession();
            let res = await psc.getQuestionaryIst(props.assessment_participant_session.id);
            // if return empty array
            if (res.data.list.length === 0) {
                // generate questionary
                await psc.createQuestionaryIst(props.assessment_participant_session.id);
            }
            // re-fetch questionary data
            res = await psc.getQuestionaryIst(props.assessment_participant_session.id);
            setIstQuestionary(res.data.list)
            if (res.data.list.length > 0) {
                setSelectedQuestionary(res.data.list[0])
            }
        } catch (error) {
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }, [setNotif, props.assessment_participant_session.id]);

    useEffect(() => {
        getDoc();
    }, [getDoc]);

    /**
     * This effect for check autohide mdi
     * when selected_questionary.ist.hide_mdi_within > 0
     */
    useEffect(() => {
        if (selected_questionary) {
            if (
                (selected_questionary.ist.hide_mdi_within ?? 0) > 0
                && props.assessment_participant_session.close_mdi_time === null
                && timeRemaining !== null
            ) {
                setShowMdiTemporary(timeRemaining > ((selected_questionary.ist.time_limit ?? 0) * 60))
            }
        }
    }, [selected_questionary, props.assessment_participant_session, timeRemaining])

    /**
     * This effect for input text
     */
    // selected_questionary?.answer
    useEffect(() => {
        if (selected_questionary?.answer !== undefined) {
            // setAnswer(selected_questionary.answer)
            if (inputAnswerRef.current) {
                inputAnswerRef.current.value = (selected_questionary?.answer ?? "")
                inputAnswerRef.current.focus()
            }
        }
    }, [selected_questionary]);

    // update quastionary
    const handleUpdateIstQuestionary = useCallback(
        async (new_selected_answer: TPPAssessmentSessionIstQuestionary) => {
            // save new answer to array ist_questionary
            const index_selected_questionary = ist_questionary.findIndex((v) => v.id === new_selected_answer.id)
            // copy ist_questionary
            const copy_ist_questionary = [...ist_questionary]
            copy_ist_questionary.splice(index_selected_questionary, 1, new_selected_answer)
            setIstQuestionary(copy_ist_questionary);
        }, [ist_questionary]
    );

    // handleAnswer
    const handleAnswer = useCallback(
        async (v: string) => {
            try {
                if (selected_questionary) {
                    let answer = ""
                    // check answer
                    if (selected_questionary.ist.answer_type === "pilihan") {
                        answer = v
                    } else if (selected_questionary.ist.answer_type === "input_text") {
                        answer = v
                    } else if (selected_questionary.ist.answer_type === "input_number") {
                        // check answer
                        // filter if answer any duplicate
                        let answer_arr = v.split(",")
                        let unique_answer = [...new Set(answer_arr)]
                        // filter empty string
                        let filtered_answer = unique_answer.filter(function (v) {
                            return v !== "";
                        });
                        answer = filtered_answer.join(",")
                    }
                    const new_selected_answer = { ...selected_questionary, answer }
                    // save answer
                    const psc = new ParticipantSession();
                    await psc.answerQuestionaryIst(new_selected_answer)
                    // update ist_questionary
                    handleUpdateIstQuestionary(new_selected_answer)
                }
            } catch (error) {
                const errorMessage = errorHandler(error);
                setNotif({ type: "error", message: errorMessage });
            }
        }, [selected_questionary, setNotif, handleUpdateIstQuestionary]
    )

    // selected_questionary.answer
    useEffect(() => {
        /**
         * selected_quastionary is not undefined
         * new answer is not null
         * list_selected_answer === selected_answer
         */
        if (
            selected_questionary
            && selected_questionary?.answer !== null
            && ist_questionary.find(v => v.id === selected_questionary.id)?.answer !== selected_questionary.answer
        ) {
            handleAnswer(selected_questionary.answer)
        }
    }, [ist_questionary, selected_questionary, selected_questionary?.answer, handleAnswer]);

    // inputValue
    useEffect(() => {
        const setAnswerInputText = (answer: string) => {
            // set value by self hook to avoid dependency
            setSelectedQuestionary(s => { return s ? ({ ...(s), answer }) : undefined })
        }
        // to make sure not infinite loop create insider func
        if (inputValue !== null) {
            setAnswerInputText(inputValue ?? "")
        }
    }, [inputValue]);

    return (
        <>
            <Transition
                as={Fragment}
                show={showMdiTemporary}
                enter="ease-out duration-50"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-50"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div>
                    <MdiTemporaryModal selected_questionary={selected_questionary} />
                </div>
            </Transition>
            <div className="h-[94%] flex flex-col overflow-y-auto">{/* Questionary Form */}
                <div className="text-left border-b py-3 px-10">
                    <Disclosure defaultOpen={true}>
                        {({ open }) => (
                            <>
                                <Disclosure.Panel className="text-gray-900">
                                    {(selected_questionary?.ist.hide_mdi_within ?? 0) === 0 &&
                                        <div className="font-semibold italic">{selected_questionary?.ist.mdi}</div>
                                    }
                                    {selected_questionary?.ist.with_mdi_question_image === 1 &&
                                        <div className="text-center">
                                            <img className="w-full lg:w-9/12" src={`/upload/psikotest_participant/get_ist_mdi_image/${selected_questionary?.ist.mdi_image}`} alt={`${selected_questionary?.ist.mdi_image}`} />
                                        </div>
                                    }
                                    <div className="whitespace-pre-line">{selected_questionary?.ist.example}</div>
                                </Disclosure.Panel>
                                <Disclosure.Button className="py-2">
                                    {open
                                        ? <div className="text-red-700">Sembunyikan Contoh Soal</div>
                                        : <div className="text-blue-700">Tampilkan Contoh Soal</div>
                                    }
                                </Disclosure.Button>
                            </>
                        )}
                    </Disclosure>
                </div>
                <div className="flex flex-grow flex-col">
                    <div className={`${selected_questionary?.ist_questionary.question !== null ? "flex flex-1 justify-center" : "h-0"}`}>
                        {selected_questionary === undefined
                            ? <div>
                                Please Wait, Loading Questionary
                            </div>
                            : <>
                                {selected_questionary.ist.with_mdi_question_image === 1
                                    ? <div className="flex justify-center">
                                        <div className="flex flex-col justify-center w-8/12 border-r">
                                            <div className="text-center font-semibold pb-4">Pilihan Jawaban</div>
                                            <img className="w-full lg:w-8/12 mx-auto" src={`/upload/psikotest_participant/get_ist_questionary_image/${selected_questionary.ist_questionary_image.ist_questionary_image}`} alt={`${selected_questionary?.ist.mdi_image}`} />
                                        </div>
                                        <div className="flex flex-col justify-center w-4/12">
                                            <div className="text-center font-semibold">Soal</div>
                                            <img className="w-full lg:w-6/12 mx-auto" src={`/upload/psikotest_participant/get_ist_questionary/${selected_questionary?.ist_questionary.question}`} alt={`${selected_questionary?.ist_questionary.question}`} />
                                        </div>
                                    </div>
                                    : <div className="flex items-center text-center font-bold text-normal md:text-xl max-w-3xl">
                                        {selected_questionary.ist_questionary.question}
                                    </div>
                                }
                            </>
                        }
                    </div>
                    <div className={`flex ${selected_questionary?.ist_questionary.question !== null ? "items-center justify-center" : "flex-1 justify-center"}  border-t`}>
                        {selected_questionary === undefined
                            ? <div className="flex justify-center space-x-3 py-6">
                                Please Wait, Loading Questionary
                            </div>
                            : <div className="flex items-center justify-center py-14">
                                {/** answer_type === "pilihan" */}
                                {selected_questionary.ist.answer_type === "pilihan" &&
                                    <div className="flex flex-wrap justify-center gap-2">
                                        {[
                                            selected_questionary.ist_questionary.answer_a,
                                            selected_questionary.ist_questionary.answer_b,
                                            selected_questionary.ist_questionary.answer_c,
                                            selected_questionary.ist_questionary.answer_d,
                                            selected_questionary.ist_questionary.answer_e,
                                        ].map((v, i) =>
                                            <div
                                                key={`ist_answer_${i}`}
                                                className={`
                                                    whitespace-nowrap rounded border border-dashed px-4 cursor-pointer border-gray-400 hover:bg-blue-500 hover:text-white
                                                    ${selected_questionary.answer === v ? "bg-green-200" : "bg-gray-100"}
                                                `}
                                                onClick={() => setSelectedQuestionary({ ...selected_questionary, answer: (v ?? "") })}
                                            >
                                                {alphabet_index[i]}. {v}
                                            </div>
                                        )}
                                    </div>
                                }
                                {/** answer_type === "input_text" */}
                                {selected_questionary.ist.answer_type === "input_text" &&
                                    <div className="w-full">
                                        {/* Answer Type */}
                                        <div>
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs text-center font-bold mb-2">
                                                Answer
                                            </label>
                                            <div>
                                                <input
                                                    type="text"
                                                    className={
                                                        (selected_questionary.answer === "" || selected_questionary.answer === null ? "border-red-500" : "border-green-500 bg-green-50 focus:border-gray-500") +
                                                        " md:min-w-[400px] first-letter:appearance-none block w-full text-gray-700 border rounded py-2 px-3 focus:outline-none"
                                                    }
                                                    autoFocus={true}
                                                    defaultValue={selected_questionary?.answer ?? ""}
                                                    ref={inputAnswerRef}
                                                    onChange={(e) => setAnswer(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                                {/** answer_type === "input_number" */}
                                {selected_questionary.ist.answer_type === "input_number" &&
                                    <div className="flex flex-grow justify-center items-center space-x-3">
                                        <div className="flex flex-wrap justify-center gap-1 max-w-sm md:max-w-3xl">
                                            {["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"].map((v, i) =>
                                                <div key={`ist_answer_${i}`} className="bg-slate-100 px-1 rounded-sm">
                                                    <input
                                                        type="checkbox"
                                                        id={v}
                                                        name={v}
                                                        value={v}
                                                        checked={(selected_questionary.answer ?? "").split(",").includes(v)}
                                                        onChange={(el) => {
                                                            // get answer
                                                            const copy_answer = selected_questionary.answer === null ? [] : [...selected_questionary.answer.split(",")];
                                                            // check type check button
                                                            if (el.target.checked) {
                                                                // add value to answer
                                                                copy_answer.push(el.target.value)
                                                            } else {
                                                                // remove value from answer
                                                                const find_index = copy_answer.findIndex(v => v === el.target.value)
                                                                copy_answer.splice(find_index, 1)
                                                            }
                                                            setSelectedQuestionary({ ...selected_questionary, answer: copy_answer.join(",") })
                                                        }}
                                                    />
                                                    <label htmlFor={v} className={`${(selected_questionary.answer ?? "").split(",").includes(v) ? "bg-green-200" : "bg-gray-200"} ml-2 px-4 rounded`}>{v}</label>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
                <div className="pt-3 mb-8 ms:pt-4 md:mb-3 border-t flex justify-center">
                    <div className="mx-3 md:mx-10 flex flex-wrap items-center justify-center">
                        {/** Questionary Button */}
                        {ist_questionary.map((v, i) =>
                            <div
                                key={`ist_questionary_${i}`}
                                className={`
                                    py-1 px-2 mr-1 mb-1 min-w-[40px] 
                                    md:py-1 md:px-6 md:mr-2 md:mb-2 md:min-w-[80px] text-center cursor-pointer rounded text-white
                                    ${selected_questionary?.id === v.id
                                        ? "bg-blue-500 hover:bg-blue-600"
                                        : v?.answer === null || v?.answer === ""
                                            ? "bg-slate-500 hover:bg-slate-600"
                                            : "bg-green-500 hover:bg-green-600"}
                                `}
                                onClick={() => setSelectedQuestionary(v)}
                            >
                                {i + 1}
                            </div>
                        )}
                        {/** Finish Button */}
                        {(ist_questionary.filter(v => (v.answer !== null && v.answer !== "")).length === ist_questionary.length || selected_questionary?.id === ist_questionary[ist_questionary.length - 1]?.id) &&
                            <div
                                className={`py-1 px-6 min-w-[80px] text-center cursor-pointer rounded text-white mr-2 mb-2 bg-red-500 hover:bg-red-600}`}
                                onClick={() => props.closeSession()}>
                                Finish
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

function MdiTemporaryModal(props: { selected_questionary: TPPAssessmentSessionIstQuestionary | undefined }) {
    // Context
    return <div>
        <div className="fixed z-20 top-0 left-0 w-screen h-screen bg-black opacity-20"> </div>
        <div className="fixed z-30 top-0 left-0 w-screen h-screen bg-transparent flex items-center justify-center">
            <div className="bg-white w-full h-full overflow-auto flex justify-center items-center">
                <div className="whitespace-pre-line text-center font-bold text-lg">
                    {props.selected_questionary?.ist.mdi}
                </div>
            </div>
        </div>
    </div>
}

export default SubtestIst;
