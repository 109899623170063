import req from "../../../services/assessment/request";

class Category {
  async list() {
    try {
      const res = await req.get("/api/assessment_system/category/");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async get(id: string) {
    try {
      const res = await req.get("/api/assessment_system/category/" + id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async create(category: TCategory) {
    try {
      const res = await req.post("/api/assessment_system/category/", category);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async update(category: TCategory) {
    try {
      const res = await req.put("/api/assessment_system/category/", category);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async delete(id: string) {
    try {
      const res = await req.delete("/api/assessment_system/category/" + id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default Category;
