import { useCallback, useContext, useState, useEffect, useMemo, Fragment } from "react";
import { useMediaQuery } from "react-responsive";
import Swal from "sweetalert2";
import { Transition } from "@headlessui/react";

// Icons
import { ClipboardDocumentListIcon, PlusIcon, TrashIcon, } from "@heroicons/react/24/outline";

// Context
import SnackbarContext from "../../../context/psikotest/SnackbarContext";
import LoaderContext from "../../../context/psikotest/LoaderContext";

// Helper
import errorHandler from "../../../helper/psikotest/errorHandler";
import logRender from "../../../helper/psikotest/logRender";

// Controller
import MbtiQuestionary from "../../../controller/psikotest/psikotest_system/mbti_questionary";

// Component
import TableFilter from "../../../components/psikotest/psikotest_system/TableFilter";
import Table from "../../../components/psikotest/psikotest_system/Table";

// Modals
import FormQuestionary from "./modal/FormQuestionary";
import FormImportQuestionary from "./modal/FormImportQuestionary";

function List() {
  const { setMessage } = useContext(LoaderContext);
  const { setNotif } = useContext(SnackbarContext);
  // required state
  const [list, setList] = useState<Array<TPSMbtiQuestionary>>([]);
  const [search, setSearch] = useState<string>("");
  // state modal
  const [isOpenFormQuestionary, setIsOpenFormQuestionary] = useState<boolean>(false);
  const [isOpenFormImportQuestionary, setIsOpenFormImportQuestionary] = useState<boolean>(false);
  // state other
  const [selected_mbti_questionary, setSelectedMbtiQuestionary] = useState<TPSMbtiQuestionary | undefined>(undefined);

  useEffect(() => {
    logRender({ type: "page", name: "mbti_questionary/List" });
  }, []);

  const getList = useCallback(async () => {
    try {
      setMessage("Fetch Mbti Questionary");
      const mbti_qc = new MbtiQuestionary();
      const res = await mbti_qc.list();
      setList(res.data.list);
      setMessage("");
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  }, [setNotif, setMessage]);

  useEffect(() => {
    getList();
  }, [getList]);

  const handleDeleteList = (id: number) => {
    const arrFilter = [...list].filter((v) => v.id !== id);
    setList(arrFilter);
  };

  // when selected_mbti_questionary changes and not undefined open Cfit Questionary form to update
  useEffect(() => {
    if (selected_mbti_questionary !== undefined) {
      setIsOpenFormQuestionary(true)
    }
  }, [selected_mbti_questionary]);

  // handleRefreshAfterOpenForm
  const handleRefreshAfterOpenForm = () => {
    // rmove selected value will close form modal
    setSelectedMbtiQuestionary(undefined)
    // close modal
    setIsOpenFormQuestionary(false)
    // re-fetch data
    getList();
  }

  // handleRefreshAfterImport
  const handleRefreshAfterImport = () => {
    // close import form modal
    setIsOpenFormImportQuestionary(false)
    // re-fetch data
    getList();
  }

  return (
    <div className="w-full flex flex-col space-y-0.5">
      {/* Import Questionary Form */}
      <Transition
        as={Fragment}
        show={isOpenFormImportQuestionary}
        enter="ease-out duration-50"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-50"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div>
          <FormImportQuestionary
            nextNumber={list.length + 1}
            handleClose={() => setIsOpenFormImportQuestionary(false)}
            handleRefreshAfterImport={handleRefreshAfterImport}
          />
        </div>
      </Transition>
      {/* Questionary Form */}
      <Transition
        as={Fragment}
        show={isOpenFormQuestionary}
        enter="ease-out duration-50"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-50"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div>
          <FormQuestionary data={selected_mbti_questionary} nextNumber={list.length + 1} handleClose={() => handleRefreshAfterOpenForm()} />
        </div>
      </Transition>
      <div className="flex flex-col md:flex-row items-start md:items-center">
        <div className="flex space-x-1">
          <button type="button"
            onClick={() => { setIsOpenFormImportQuestionary(true) }}
            className="block w-auto mb-2 md:m-0 px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded font-semibold text-sm"
          >
            <div className="flex">
              <ClipboardDocumentListIcon className="w-4" />
              <span className="block pl-1">Import</span>
            </div>
          </button>
          <button
            type="button"
            onClick={() => { setIsOpenFormQuestionary(true) }}
            className="block w-auto mb-2 md:m-0 px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded font-semibold text-sm"
          >
            <div className="flex">
              <PlusIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
              Create
            </div>
          </button>
        </div>
        <div className="w-full md:w-auto ml-auto flex">
          <TableFilter search={setSearch} />
        </div>
      </div>
      <div>
        <h4 className="font-semibold my-2 text-center">Control value <br />(Total Questionary: {list.length}/60)</h4>
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/4 p-1">
            <div className="flex border border-dashed border-green-400 rounded p-1 bg-green-100">
              <div className="w-3/4">
                <div className="flex w-full">
                  <span className="w-2/4">(I) Introvert</span> :
                  <span className="pl-2">{list.filter(l => l.statement_a_value === "(I) Introvert").length + list.filter(l => l.statement_b_value === "(I) Introvert").length}</span>/15
                </div>
                <div className="flex w-full">
                  <span className="w-2/4">(E) Extrovert</span> :
                  <span className="pl-2">{list.filter(l => l.statement_a_value === "(E) Extrovert").length + list.filter(l => l.statement_b_value === "(E) Extrovert").length}</span>/15
                </div>
              </div>
              <div className="w-1/4 flex justify-center items-center font-bold text-center">
                Soal<br />
                {(list.filter(l => (l.statement_a_value === "(I) Introvert" || l.statement_a_value === "(E) Extrovert")).length + list.filter(l => (l.statement_b_value === "(I) Introvert" || l.statement_b_value === "(E) Extrovert")).length) / 2}
                /15
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/4 p-1">
            <div className="flex border border-dashed border-red-400 rounded p-1 bg-red-100">
              <div className="w-3/4">
                <div className="flex w-full">
                  <span className="w-2/4">(S) Sensing</span> :
                  <span className="pl-2">{list.filter(l => l.statement_a_value === "(S) Sensing").length + list.filter(l => l.statement_b_value === "(S) Sensing").length}</span>/15
                </div>
                <div className="flex w-full">
                  <span className="w-2/4">(N) Intuition</span> :
                  <span className="pl-2">{list.filter(l => l.statement_a_value === "(N) Intuition").length + list.filter(l => l.statement_b_value === "(N) Intuition").length}</span>/15
                </div>
              </div>
              <div className="w-1/4 flex justify-center items-center font-bold text-center">
                Soal<br />
                {(list.filter(l => (l.statement_a_value === "(S) Sensing" || l.statement_a_value === "(N) Intuition")).length + list.filter(l => (l.statement_b_value === "(S) Sensing" || l.statement_b_value === "(N) Intuition")).length) / 2}
                /15
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/4 p-1">
            <div className="flex border border-dashed border-yellow-400 rounded p-1 bg-yellow-100">
              <div className="w-3/4">
                <div className="flex w-full">
                  <span className="w-2/4">(T) Thinking</span> :
                  <span className="pl-2">{list.filter(l => l.statement_a_value === "(T) Thinking").length + list.filter(l => l.statement_b_value === "(T) Thinking").length}</span>/15
                </div>
                <div className="flex w-full">
                  <span className="w-2/4">(F) Feeling</span> :
                  <span className="pl-2">{list.filter(l => l.statement_a_value === "(F) Feeling").length + list.filter(l => l.statement_b_value === "(F) Feeling").length}</span>/15
                </div>
              </div>
              <div className="w-1/4 flex justify-center items-center font-bold text-center">
                Soal<br />
                {(list.filter(l => (l.statement_a_value === "(T) Thinking" || l.statement_a_value === "(F) Feeling")).length + list.filter(l => (l.statement_b_value === "(T) Thinking" || l.statement_b_value === "(F) Feeling")).length) / 2}
                /15
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/4 p-1">
            <div className="flex border border-dashed border-blue-400 rounded p-1 bg-blue-100">
              <div className="w-3/4">
                <div className="flex w-full">
                  <span className="w-2/4">(J) Judging</span> :
                  <span className="pl-2">{list.filter(l => l.statement_a_value === "(J) Judging").length + list.filter(l => l.statement_b_value === "(J) Judging").length}</span>/15
                </div>
                <div className="flex w-full">
                  <span className="w-2/4">(P) Perceiving</span> :
                  <span className="pl-2">{list.filter(l => l.statement_a_value === "(P) Perceiving").length + list.filter(l => l.statement_b_value === "(P) Perceiving").length}</span>/15
                </div>
              </div>
              <div className="w-1/4 flex justify-center items-center font-bold text-center">
                Soal<br />
                {(list.filter(l => (l.statement_a_value === "(J) Judging" || l.statement_a_value === "(P) Perceiving")).length + list.filter(l => (l.statement_b_value === "(J) Judging" || l.statement_b_value === "(P) Perceiving")).length) / 2}
                /15
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <MbtiQuestionaryList list={list} handleDeleteList={handleDeleteList} search={search} setSelectedMbtiQuestionary={setSelectedMbtiQuestionary} />
      </div>
    </div >
  );
}

function MbtiQuestionaryList(props: {
  list: Array<TPSMbtiQuestionary>;
  handleDeleteList: (id: number) => void;
  search: string,
  setSelectedMbtiQuestionary: React.Dispatch<React.SetStateAction<TPSMbtiQuestionary | undefined>>
}) {
  const setNotif = useContext(SnackbarContext).setNotif;
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const confirmDelete = useCallback(
    async (mbti_q: TPSMbtiQuestionary) => {
      try {
        const confirm = await Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        });
        if (confirm.isConfirmed) {
          const mbti_qc = new MbtiQuestionary();
          await mbti_qc.delete(mbti_q.id);
          props.handleDeleteList(mbti_q.id);
          setNotif({ type: "success", message: "mbti questionary deleted" });
        }
      } catch (error) {
        const errorMessage = errorHandler(error);
        setNotif({ type: "error", message: errorMessage });
      }
    },
    [props, setNotif]
  );

  const columns = useMemo(
    () => [
      {
        id: "mobile",
        Header: "Number",
        accessor: "number",
        show: isMobile,
        Cell: (colProps: { row: { original: TPSMbtiQuestionary } }) => {
          return (
            <button className="text-blue-500 hover:text-blue-800" onClick={() => props.setSelectedMbtiQuestionary(colProps.row.original)}>
              {colProps.row.original.number || "NULL"}
            </button>
          );
        },
      },
      {
        id: "number",
        classHeader: "text-left px-2 w-20",
        Header: <div title="Number">Number</div>,
        classDivHeader: "flex items-center",
        accessor: "number",
        show: !isMobile,
        Cell: (colProps: { row: { original: TPSMbtiQuestionary } }) => {
          return (
            <button className="flex items-center text-blue-500 hover:text-blue-800" onClick={() => props.setSelectedMbtiQuestionary(colProps.row.original)}>
              {colProps.row.original.number}
            </button>
          );
        },
      },
      {
        id: "statement_a",
        classHeader: "text-left px-2",
        Header: <div title="Statement A">Statement A</div>,
        classDivHeader: "flex items-center",
        accessor: "statement_a",
        show: !isMobile,
        Cell: (props: { row: { original: TPSMbtiQuestionary } }) => {
          return props.row.original.statement_a
        },
      },
      {
        id: "statement_a_value",
        classHeader: "text-left px-2",
        Header: <div title="Statement A Value">Value</div>,
        classDivHeader: "flex items-center",
        accessor: "statement_a_value",
        show: !isMobile,
        Cell: (props: { row: { original: TPSMbtiQuestionary } }) => {
          return props.row.original.statement_a_value
        },
      },
      {
        id: "statement_b",
        classHeader: "text-left px-2",
        Header: <div title="Statement B">Statement B</div>,
        classDivHeader: "flex items-center",
        accessor: "statement_b",
        show: !isMobile,
        Cell: (props: { row: { original: TPSMbtiQuestionary } }) => {
          return props.row.original.statement_b
        },
      },
      {
        id: "statement_b_value",
        classHeader: "text-left px-2",
        Header: <div title="Statement B Value">Value</div>,
        classDivHeader: "flex items-center",
        accessor: "statement_b_value",
        show: !isMobile,
        Cell: (props: { row: { original: TPSMbtiQuestionary } }) => {
          return props.row.original.statement_b_value
        },
      },
      {
        id: "action_delete",
        classHeader: "w-[30px] text-white",
        Header: () => {
          return <TrashIcon className="h-5 w-auto" />;
        },
        classDivHeader: "flex justify-center",
        accessor: "id",
        show: !isMobile,
        disableSortBy: true,
        className: "text-white bg-red-500 hover:bg-red-800",
        Cell: (props: { row: { original: TPSMbtiQuestionary } }) => {
          return (
            <button className="flex justify-center" onClick={() => confirmDelete(props.row.original)}>
              <TrashIcon className="h-5" />
            </button>
          );
        },
      },
    ],
    [isMobile, confirmDelete, props]
  );

  return <Table columns={columns} list={props.list} search={props.search} />;
}

export default List;
