import { useContext, useEffect, useRef, useState } from "react";
import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

// Context
import SnackbarContext from "../../../context/assessment/SnackbarContext";

// Controller
import Assessment from "../../../controller/assessment/assessment_system/assessment";

// Handler
import errorHandler from "../../../helper/assessment/errorHandler";

function FormUpload(props: {
  assessmentParticipantUpload: TAssessmentParticipantUpload;
  setAssessmentParticipantUpload: React.Dispatch<React.SetStateAction<TAssessmentParticipantUpload | undefined>>;
  assessmentParticipantList: TAssessmentParticipant[];
  setAssessmentParticipantList: React.Dispatch<React.SetStateAction<TAssessmentParticipant[]>>;
}) {
  // Context
  const { setNotif } = useContext(SnackbarContext);
  // Ref
  const loaderDiv = useRef<HTMLDivElement>(null);
  const uploadInput = useRef<HTMLInputElement>(null);
  // State
  const [processUpload, setProcessUpload] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [upPercentage, setUpPercentage] = useState<number>(0);
  // destruc props
  const setAssessmentParticipantUpload = props.setAssessmentParticipantUpload;

  // upload process trigering on processUpload = true
  useEffect(() => {
    const uploadAnswer = async () => {
      // Get active session
      const ac = new Assessment();
      if (file !== undefined) {
        try {
          const config = {
            onUploadProgress: function (progressEvent: any) {
              setUpPercentage(Math.round((progressEvent.loaded * 100) / progressEvent.total));
            },
          };
          const resUploadParticipant = await ac.uploadParticipant(
            props.assessmentParticipantUpload.assessment_participant_id.toString() ?? "",
            { file, field: props.assessmentParticipantUpload.field },
            config
          );
          // refresh data
          const indexUpdateParticipant = props.assessmentParticipantList.findIndex(
            (apl, i) => apl.id === props.assessmentParticipantUpload.assessment_participant_id
          );
          props.assessmentParticipantList.splice(indexUpdateParticipant, 1, {
            ...props.assessmentParticipantList[indexUpdateParticipant],
            [props.assessmentParticipantUpload.field]: resUploadParticipant.data.filepath,
          });
          // hide upload form
          setAssessmentParticipantUpload(undefined);
          setNotif({ type: "success", message: "file uploaded" });
        } catch (error) {
          const errorMessage = errorHandler(error);
          setNotif({ type: "error", message: errorMessage });
        }
      }
    };
    if (processUpload) {
      uploadAnswer();
    }
  }, [
    processUpload,
    file,
    props.assessmentParticipantUpload.assessment_participant_id,
    props.assessmentParticipantUpload.field,
    props.assessmentParticipantList,
    setNotif,
    setAssessmentParticipantUpload,
  ]);

  useEffect(() => {
    // set loader div
    if (loaderDiv !== null && loaderDiv.current !== null) {
      loaderDiv.current.style.width = upPercentage + "%";
    }
  }, [upPercentage, loaderDiv]);

  const CloseButton = () => {
    return (
      <button
        type="button"
        className="absolute right-4 top-5 text-gray-500"
        tabIndex={0}
        onClick={() => props.setAssessmentParticipantUpload(undefined)}
      >
        <XMarkIcon className="w-5" />
      </button>
    );
  };

  const ChoseFile = (props: { label: string }) => {
    return (
      <button
        type="button"
        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
        tabIndex={0}
        onClick={() => uploadInput?.current?.click()}
      >
        {props.label}
      </button>
    );
  };

  return (
    <Dialog
      open={props.assessmentParticipantUpload !== undefined}
      onClose={() => props.setAssessmentParticipantUpload(undefined)}
    >
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-30 z-30" />
      <div className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center z-30">
        {processUpload ? (
          <div className="bg-white p-4 rounded-lg max-w-sm relative">
            <CloseButton />
            <h3 className="text-lg font-medium leading-6 text-gray-900 mb-3 pr-10" id="headlessui-dialog-title-12">
              Proses Upload
            </h3>
            <p>{upPercentage} %</p>
            <div className="w-full">
              <div ref={loaderDiv} className="h-2 bg-blue-500 rounded-lg"></div>
            </div>
            <p className="text-sm text-gray-500 mt-2">Mohon tunggu sampai proses upload selesai</p>
          </div>
        ) : (
          <div className="bg-white p-4 rounded-lg max-w-sm relative">
            <CloseButton />
            <h3
              className="text-lg font-medium leading-6 text-gray-900 mb-3 pr-10 capitalize"
              id="headlessui-dialog-title-12"
            >
              Upload {props.assessmentParticipantUpload.field.replaceAll("_", " ")}
            </h3>
            <p className="text-sm text-gray-500">Silahkan upload file</p>
            <input
              type="file"
              className="hidden"
              ref={uploadInput}
              onChange={(event) => {
                if (event.target.files !== null) {
                  Array.from(event.target.files).forEach((file, i) => {
                    if (i === 0) {
                      setFile(file);
                    }
                  });
                }
              }}
            />
            {file !== undefined ? (
              <div className="mt-3 space-y-2">
                <div>{file.name}</div>
                <ChoseFile label="Ganti File" />
                <button
                  type="button"
                  className="ml-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                  tabIndex={0}
                  onClick={() => setProcessUpload(true)}
                >
                  Upload File
                </button>
              </div>
            ) : (
              <div className="mt-3">
                <ChoseFile label="Pilih File" />
              </div>
            )}
          </div>
        )}
      </div>
    </Dialog>
  );
}

export default FormUpload;
