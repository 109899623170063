import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Disclosure, Transition } from "@headlessui/react";

// Icons
import { ArrowDownOnSquareIcon, ChevronUpIcon } from "@heroicons/react/24/outline";

// Context
import LoaderContext from "../../../context/psikotest/LoaderContext";
import SnackbarContext from "../../../context/psikotest/SnackbarContext";

// Helper
import errorHandler from "../../../helper/psikotest/errorHandler";
import logRender from "../../../helper/psikotest/logRender";

// Controller
import Rac from "../../../controller/psikotest/psikotest_system/rac";

// Component
import BackButton from "../../../components/psikotest/psikotest_system/BackButton";

// Other Section
import ListQuestionary from "./ListQuestionary";

const schema = yup.object().shape({
  id: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required(),
  mdi: yup
    .string()
    .label("(MDI) Main Question | Description | Instructions")
    .transform((value) => (value === null ? "" : value))
    .required(),
  time_limit: yup
    .number()
    .label("Time Limit")
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(1)
    .required(),
});

function Form() {
  const { setMessage } = useContext(LoaderContext);
  const { setNotif } = useContext(SnackbarContext);
  const navigate = useNavigate();
  // state document
  const [doc, setDoc] = useState<TPSRac>({
    id: 0,
    mdi: "",
    time_limit: 0
  });
  // use form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<TPSRac>({ resolver: yupResolver(schema) });

  useEffect(() => {
    logRender({ type: "page", name: "rac/Form" });
  }, []);

  const getDoc = useCallback(
    async function getDoc() {
      try {
        // fetch rac document
        setMessage("Fetch Document");
        const cc = new Rac();
        const res = await cc.get();
        const row: TPSRac = res.data.row;
        setDoc(row);
        setValue("id", row.id);
        setValue("mdi", row.mdi);
        setValue("time_limit", row.time_limit);
        setMessage("");
      } catch (error) {
        setMessage("");
        const errorMessage = errorHandler(error);
        setNotif({ type: "error", message: errorMessage });
        navigate(`../`, { replace: true });
      }
    },
    [setValue, setNotif, setMessage, navigate]
  );

  useEffect(() => {
    getDoc();
  }, [getDoc]);

  const onSubmit: SubmitHandler<TPSRac> = async (data) => {
    try {
      setMessage("Save Rac");
      const cc = new Rac();
      let res = await cc.update(data);
      setNotif({ type: "success", message: res.data.message });
      setMessage("");
      // when create new navigate to result form
      navigate("", { replace: false });
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  return (
    <div className="w-full flex flex-col space-y-0.5">
      <div className="flex">
        <BackButton />
        <button
          onClick={handleSubmit(onSubmit)}
          className="mx-2 block w-auto px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded font-semibold text-sm"
        >
          <div className="flex">
            <ArrowDownOnSquareIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
            Save
          </div>
        </button>
      </div>
      {/* MAIN SECTION */}
      <div className="pt-4">
        {/* Main Form */}
        <form className="w-full">
          <div className="flex flex-wrap -mx-3">
            <input {...register("id")} type="number" className="hidden" />
            {/** (MDI) Main Question | Description | Instructions */}
            <div className="w-full md:w-full px-3 mb-6">
              <label className="text-gray-700 text-xs mb-2">
                <span className="block uppercase tracking-wide font-bold">(MDI) Main Question | Description | Instructions</span>
                <i>Main question/description/intructions for sub-test, always show up until test ended.</i>
              </label>
              <textarea
                className={
                  (errors?.mdi ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                  " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                }
                rows={4}
                {...register("mdi")}
              />
              {errors.mdi && <p className="text-red-500 text-xs italic">{errors.mdi.message}</p>}
              <input {...register("id")} type="number" className="hidden" />
            </div>
            {/** Time Limit (Minute) */}
            <div className="w-full px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Time Limit (Minute)
              </label>
              <input
                type="number"
                className={
                  (errors?.time_limit ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                  " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                }
                {...register("time_limit")}
              />
              {errors.time_limit && (
                <p className="text-red-500 text-xs italic">{errors.time_limit.message}</p>
              )}
            </div>
          </div>
        </form>
        {/** RAC Questionary */}
        <Disclosure defaultOpen={true} as="div" className="pb-1">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex justify-between w-full px-4 py-4 text-sm font-medium text-left text-gray-100 bg-blue-900 rounded hover:bg-blue-800 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                <span>RAC Questionary</span>
                <ChevronUpIcon className={`${open ? "transform rotate-180" : ""} w-5 h-5 text-gray-100`} />
              </Disclosure.Button>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                  <ListQuestionary rac={doc} editable={true} />
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      </div>
    </div >
  );
}

export default Form;
