import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

function PdfViewer(props: { pdfUrl: string }) {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);

  window.addEventListener("contextmenu", (event) => event.preventDefault());

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  return (
    <div className="h-[56%] flex flex-col md:items-center overflow-auto pb-6">
      <div className="sticky top-0 left-0 z-10 px-2 bg-slate-500 text-white rounded-b flex justify-center py-1">
        <ChevronLeftIcon
          className={`w-6 ${pageNumber === 1 ? "text-gray-600" : ""}`}
          onClick={() => {
            if (pageNumber > 1) setPageNumber(pageNumber - 1);
          }}
        />
        <span className="px-2">
          Page {pageNumber} of {numPages}
        </span>
        <ChevronRightIcon
          className={`w-6 ${pageNumber === numPages ? "text-gray-600" : ""}`}
          onClick={() => {
            if (pageNumber < numPages) setPageNumber(pageNumber + 1);
          }}
        />
      </div>
      <Document file={`/upload/assessment_participant/get/${props.pdfUrl}`} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} width={1000} renderMode="canvas" renderTextLayer={false} />
      </Document>
    </div>
  );
}

export default PdfViewer;
