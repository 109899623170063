import { useContext, useEffect } from "react";

import PPAssessmentContext from "../../../context/psikotest/PPAssessmentContext";

function Header() {
  // Context
  const { assessment, assessment_participant, timeRemaining, setTimeRemaining } = useContext(PPAssessmentContext);

  useEffect(() => {
    if (timeRemaining !== null && timeRemaining > 0) {
      const timer = window.setInterval(() => {
        setTimeRemaining(timeRemaining - 1);
      }, 1000);
      return () => {
        window.clearInterval(timer);
      };
    }
  }, [timeRemaining, setTimeRemaining]);

  return (
    <div className="w-full flex flex-col md:flex-row p-2 border-b sticky top-0 z-10 bg-white">
      <div className="mb-2 md:mb-0 flex items-center">
        <img
          src={`${process.env.REACT_APP_URL}/upload/psikotest_participant/get/${assessment.company?.company_logo}`}
          className="h-8"
          alt="company logo"
        />
        <div className="pl-2 font-semibold">
          <h5 className="text-xs md:text-base">
            {assessment.company?.company_name} - {assessment_participant.full_name}
          </h5>
          <h6 className="text-xs">{assessment.assessment_title}</h6>
        </div>
      </div>
    </div>
  );
}

export default Header;
