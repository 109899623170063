import req from "../../../services/psikotest/request";

class IstQuestionaryImage {
  async update(data: TPSIstQuestionaryImage) {
    try {
      const res = await req.put("/api/psikotest_system/ist_questionary_image/" + data.id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async delete(data: TPSIstQuestionaryImage) {
    try {
      const res = await req.delete("/api/psikotest_system/ist_questionary_image/", { data });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default IstQuestionaryImage;
