
import { useCallback, useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";

// Icons
import { PlusIcon } from "@heroicons/react/24/outline";

// Context
import SnackbarContext from "../../../context/psikotest/SnackbarContext";
import LoaderContext from "../../../context/psikotest/LoaderContext";

// Helper
import errorHandler from "../../../helper/psikotest/errorHandler";

// Controller
import IstFormula from "../../../controller/psikotest/psikotest_system/ist_formula";
import IstFormulaNorma from "../../../controller/psikotest/psikotest_system/ist_formula_norma";

// Costant Value
const minRwValue = 0
const minSwValue = 73
const maxNorma = 50

function ListNorma(props: { isCreate: boolean; ifor: TPSIstFormula; editable: boolean }) {
    const { setMessage } = useContext(LoaderContext)
    const { setNotif } = useContext(SnackbarContext)
    // state list norma
    const [norma, setNorma] = useState<TPSIstFormulaNorma[]>([])

    const getDoc = useCallback(
        async function getDoc() {
            try {
                if (!props.isCreate) {
                    if (props.ifor.id && props.ifor.ist_id) {
                        // fetch cfit_persentil list
                        setMessage("Fetch Cfit Formula");
                        const cif = new IstFormula();
                        const res = await cif.norma(props.ifor.ist_id.toString(), props.ifor.id);
                        const list: TPSIstFormulaNorma[] = res.data.list;
                        setNorma(list);
                    }
                }
                setMessage("");
            } catch (error) {
                setMessage("");
                const errorMessage = errorHandler(error);
                setNotif({ type: "error", message: errorMessage });
            }
        },
        [setNotif, setMessage, props.ifor.ist_id, props.ifor.id, props.isCreate]
    );

    useEffect(() => {
        getDoc();
    }, [getDoc]);

    // handle create empty norma
    const createEmptyNorma = async () => {
        try {
            let createData: TPSIstFormulaNorma = {
                id: 0,
                ist_formula_id: props.ifor.id,
                rw: norma.length === 0
                    ? minRwValue
                    : norma[norma.length - 1]?.rw === null
                        ? null
                        : (norma[norma.length - 1]?.rw ?? 0) + 1,
                sw: norma.length === 0
                    ? minSwValue
                    : norma[norma.length - 1]?.sw === null
                        ? null
                        : (norma[norma.length - 1]?.sw ?? 0) + 1
            }
            const cifn = new IstFormulaNorma();
            const res = await cifn.create(createData)
            createData.id = res.data.result.saved_id
            // push new created norma to norma array
            setNorma([...norma, createData])
        } catch (error) {
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }

    // handle update 
    const updateNorma = async (e: React.ChangeEvent<HTMLInputElement>, data: TPSIstFormulaNorma, index: number) => {
        try {
            let updateData: TPSIstFormulaNorma = {
                id: data.id,
                ist_formula_id: data.ist_formula_id,
                rw: e.target.name === "rw" ? parseInt(e.target.value) : data.rw,
                sw: e.target.name === "sw" ? parseInt(e.target.value) : data.sw
            }
            // make sure sw not 0
            updateData.sw = updateData.sw === 0 ? null : updateData.sw
            // copy to update state 
            const copyNorma = [...norma];
            copyNorma[index] = updateData
            // update state
            setNorma(copyNorma)
            // update to server
            const cifn = new IstFormulaNorma();
            await cifn.update(updateData)
        } catch (error) {
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
        // re-fetch data
        // make sure run even on error
        getDoc();
    }

    // handle delete
    const confirmDelete = useCallback(
        async (ifn: TPSIstFormulaNorma) => {
            try {
                const confirm = await Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                });
                if (confirm.isConfirmed) {
                    const cifn = new IstFormulaNorma();
                    await cifn.delete(ifn);
                    setNotif({ type: "success", message: "ist norma deleted" });
                    // re-fetch data
                    getDoc();
                }
            } catch (error) {
                const errorMessage = errorHandler(error);
                setNotif({ type: "error", message: errorMessage });
            }
        },
        [setNotif, getDoc]
    );

    return (
        <div className="pb-[40rem]">
            {norma.length < maxNorma && props.editable && (
                <div className="w-full md:w-full mb-2">
                    <button
                        type="button"
                        onClick={() => { createEmptyNorma() }}
                        className="block w-auto px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded font-semibold text-sm"
                    >
                        <div className="flex">
                            <PlusIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
                            Norma
                        </div>
                    </button>
                </div>
            )}
            <div className="pt-2">
                <table className="w-full border">
                    <thead className="bg-slate-600 text-white">
                        <tr>
                            <th className="py-1 px-2 text-left border border-slate-500">RW</th>
                            <th className="py-1 px-2 text-left border border-slate-500">SW</th>
                            <th className="py-1 px-2 w-20 border border-slate-500">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {norma.map((n, i) => {
                            return (
                                <tr key={`persentil_${i}`}>
                                    <td className="py-1 px-2 border">
                                        <input type="number" name="rw" className="border px-3" value={n.rw ?? 0} onChange={(e) => updateNorma(e, n, i)} />
                                    </td>
                                    <td className="py-1 px-2 border">
                                        <input type="number" name="sw" className="border px-3" value={n.sw ?? 0} onChange={(e) => updateNorma(e, n, i)} />
                                    </td>
                                    <td className="py-1 px-2 border text-center">
                                        <button type="button" className="px-2 py-[2px] rounded bg-red-500 text-white" onClick={() => confirmDelete(n)}>
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ListNorma;
