import req from "../../../services/psikotest/request";

class Psikolog {
  async list() {
    try {
      const res = await req.get("/api/psikotest_system/psikolog/");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async get(id: string) {
    try {
      const res = await req.get("/api/psikotest_system/psikolog/" + id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async create(psikolog: TPSPsikologCreateUpdate) {
    try {
      const res = await req.post("/api/psikotest_system/psikolog/", psikolog);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async update(id: string, psikolog: TPSPsikologCreateUpdate) {
    try {
      const res = await req.put("/api/psikotest_system/psikolog/" + id, psikolog);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async delete(userId: string) {
    try {
      const res = await req.delete("/api/psikotest_system/psikolog/" + userId);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default Psikolog;
