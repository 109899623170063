import { useContext } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";

import AssessmentContext from "../../context/assessment/AssessmentContext";

import AssessmentParticipantLogin from "./AssessmentParticipantLogin";
import Session from "./session/Session";

function AssessmentParticipantRoute() {
  return (
    <Routes>
      <Route element={<LoginAssessmentRoute />}>
        <Route path={`prep`} element={<AssessmentParticipantLogin />} />
      </Route>
      <Route element={<ProtectedAssessmentRoute />}>
        <Route path={`session//*`} element={<Session />} />
      </Route>
    </Routes>
  );
}

const LoginAssessmentRoute = () => {
  const { participant } = useContext(AssessmentContext);
  if (participant.date_of_birth !== null && participant.photo !== null) {
    return <Navigate to="session" replace />;
  }
  return <Outlet />;
};

const ProtectedAssessmentRoute = () => {
  const { participant } = useContext(AssessmentContext);
  if (participant.date_of_birth === null || participant.photo === null) {
    return <Navigate to={`prep/?k=${sessionStorage.getItem("accessKey")}`} replace />;
  }
  return <Outlet />;
};

export default AssessmentParticipantRoute;
