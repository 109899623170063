import { useContext, useEffect } from "react";

// Context
import AssessmentContext from "../../context/assessment/AssessmentContext";

// Helper
import logRender from "../../helper/assessment/logRender";

// Page Component
import ParticipantForm from "./ParticipantForm";

function AssessmentParticipantLogin() {
  // Context
  const { errorValidate, assessment, participant } = useContext(AssessmentContext);

  useEffect(() => {
    logRender({ type: "page", name: "assessment/Login" });
  }, []);

  return (
    <div className="w-screen h-screen flex justify-center bg-cyan-50">
      {errorValidate === "" ? (
        <div className="w-screen min-h-screen flex justify-center">
          <div className="hidden md:flex w-full lg:w-6/12 xl:w-5/12 h-screen bg-white shadow-none lg:shadow-md px-10 flex-col items-center justify-center">
            <img
              className="h-20"
              alt="company logo"
              src={`${process.env.REACT_APP_URL}/upload/assessment_participant/get/${assessment.company_logo}`}
            />
            <h2 className="font-bold text-xl py-5">{assessment.company_name}</h2>
            <h5 className="font-semibold text-lg pb-5">{assessment.title}</h5>
            <p className="text-lg text-center mx-8 mb-8">
              Selamat datang <b>{participant.full_name}</b>.
            </p>
            <p className="text-lg text-center mx-8 mb-8">
              Terima kasih telah bersedia meluangkan waktu anda untuk assesmen ini, sebelumnya mohon isi identitas anda.
            </p>
          </div>
          <div className="w-full lg:w-6/12 xl:w-7/12 h-screen bg-white border-l">
            <ParticipantForm assessmentDetail={assessment} assessmentParticipantDetail={participant} />
          </div>
        </div>
      ) : (
        <div className="w-full lg:w-6/12 xl:w-5/12 h-full bg-white shadow-none lg:shadow-md px-10 flex flex-col items-center justify-center">
          <div className="font-semibold">
            <div>{errorValidate}</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AssessmentParticipantLogin;
