import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Assets
import logo from "../../assets/kamara-logo-white-900.svg";
import { ReactComponent as Loading } from "../../assets/loading.svg";
import { ReactComponent as Lock } from "../../assets/lock.svg";

// Helper
import errorHandler from "../../helper/psikotest/errorHandler";

// Context
import PSAuthContext from "../../context/psikotest/AuthContext";
import SnackbarContext from "../../context/psikotest/SnackbarContext";

function PsikotestSystemLogin() {
  const navigate = useNavigate();
  const { user, signin } = useContext(PSAuthContext);
  const snackbar = useContext(SnackbarContext);
  const [processLogin, setProcessLogin] = useState<boolean>(false);

  // When user logged redirect to apps
  useEffect(() => {
    if (user !== null) {
      // redirect if success
      return navigate("/psikotest_system");
    }
  }, [user, navigate]);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    try {
      event.preventDefault();

      setProcessLogin(true);
      let formData = new FormData(event.currentTarget);
      let username = formData.get("username") as string;
      let password = formData.get("password") as string;
      // login user
      await signin(username, password);
      setProcessLogin(false);
    } catch (error) {
      const errorMessage = errorHandler(error);
      snackbar.setNotif({ type: "error", message: errorMessage });
      setProcessLogin(false);
    }
  }

  return (
    <div className="h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div className="flex flex-col items-center">
          <div className="h-44 w-44">
            <img src={logo} alt="logo" />
          </div>
          <h2 className="mt-4 text-center text-3xl font-extrabold text-gray-900">Sign in</h2>
        </div>
        <form className="space-y-6" onSubmit={handleSubmit}>
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="username" className="sr-only">
                Username
              </label>
              <input
                id="username"
                name="username"
                type="text"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Username"
                autoComplete="current-username"
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Password"
              />
            </div>
          </div>

          <div className="flex items-center justify-end">
            <div className="text-sm">
              <a href="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                Forgot your password?
              </a>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-75"
              disabled={processLogin}
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                {processLogin ? (
                  <Loading className="animate-spin mr-3 h-5 w-5 text-white" />
                ) : (
                  <Lock className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" />
                )}
              </span>
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PsikotestSystemLogin;
