import req from "../../../services/psikotest/request";

class Disc {
  async get() {
    try {
      const res = await req.get("/api/psikotest_system/disc/");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async update(data: TPSDisc) {
    try {
      const res = await req.put("/api/psikotest_system/disc/", data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default Disc;
