import TopNavigation from "../../components/assessment/assessment_system/TopNavigation";
import MenuDesktop from "../../components/assessment/assessment_system/MenuDesktop";
import AssessmentSystemRoute from "./AssessmentSystemRoute";

function AssessmentSystemLayout() {
  return (
    <div className="w-full">
      <TopNavigation />
      <div className="flex max-w-8xl mx-auto px-4 sm:px-6 md:px-8">
        <section className="hidden md:block w-1/6 m-1 p-2">
          <MenuDesktop />
        </section>
        <section className="flex w-full md:w-5/6">
          <AssessmentSystemRoute />
        </section>
      </div>
    </div>
  );
}

export default AssessmentSystemLayout;
