import { useCallback, useContext, useEffect, useState } from "react";

// Icons
import { CheckIcon } from "@heroicons/react/24/outline";

// Context
import SnackbarContext from "../../../context/psikotest/SnackbarContext";

// Handler
import errorHandler from "../../../helper/psikotest/errorHandler";

// Controller
import ParticipantSession from "../../../controller/psikotest/psikotest_participant/session";

// Type
type AnswerT = { number: number, most: string, most_i: number, least: string, least_i: number }
type ValueDiscT = { D: number, I: number, S: number, C: number, '*': number }
type ObjectDiscT = { most: ValueDiscT, least: ValueDiscT, change: ValueDiscT }
type ResultDiscT = { actual: ObjectDiscT, scaled: ObjectDiscT }

function SubtestDisc(props: { assessment_participant_session: TPPAssessmentParticipantSession, closeSession: () => void }) {
    // Context
    const { setNotif } = useContext(SnackbarContext);

    // state
    const [disc, setDisc] = useState<TPSDisc>()
    const [disc_questionary, setDiscQuestionary] = useState<TPSDiscQuestionary[]>([])
    const [disc_questionary_answer, setDiscQuestionaryAnswer] = useState<TPSAssessmentParticipantDiscSessionAnswer>()
    const [answer_questionary, setAnswerQuestionary] = useState<AnswerT[]>([])

    // get doc
    const getDoc = useCallback(async () => {
        try {
            // get all question in subtest id
            const psc = new ParticipantSession();
            let resDisc = await psc.getDisc();
            setDisc(resDisc.data.row)
            let resQuestionaryDisc = await psc.getQuestionaryDisc();
            setDiscQuestionary(resQuestionaryDisc.data.list)
            let resQuestionaryAnswerDisc = await psc.getQuestionaryAnswerDisc(props.assessment_participant_session.id)
            setDiscQuestionaryAnswer(resQuestionaryAnswerDisc.data.row)
            // set answer questionary
            if ((JSON.parse(resQuestionaryAnswerDisc.data.row.answer)).length === 0) {
                // create empty object with same length of disc questionary
                setAnswerQuestionary(Array(24).fill(null).map((_, i) => { return { number: i + 1, most: "", most_i: -1, least: "", least_i: -1 } }))
            } else {
                setAnswerQuestionary(JSON.parse(resQuestionaryAnswerDisc.data.row.answer))
            }
        } catch (error) {
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }, [setNotif, props.assessment_participant_session.id]);

    useEffect(() => {
        getDoc();
    }, [getDoc]);

    // tdisck disc_questionary
    useEffect(() => {
        //console.log(disc_questionary)
    }, [disc_questionary]);

    // tdisck answer_questionary
    useEffect(() => {
        //console.log(answer_questionary)
    }, [answer_questionary]);

    // handleAnswer
    const handleAnswer = async (index: number, field: "most" | "least", field_i: number, value: string, disc_q_number: number) => {
        try {
            // check answer if same index set invalid
            const same_index = field === "most" ? answer_questionary[index].least_i === field_i : answer_questionary[index].most_i === field_i
            if (same_index) {
                // stop here
                setNotif({ type: "error", message: "Jawaban M dan L tidak boleh dalam statement yang sama" });
                return
            }
            // set answer
            // copy answer
            const c_answer = [...answer_questionary]
            c_answer.splice(index, 1, { ...answer_questionary[index], [field]: value, [`${field}_i`]: field_i })

            // calculated result
            let result = calculateResult(c_answer)

            if (disc_questionary_answer) {
                const psc = new ParticipantSession();
                await psc.saveQuestionaryAnswerDisc(props.assessment_participant_session.id, {
                    ...disc_questionary_answer,
                    answer: JSON.stringify(c_answer),
                    result: JSON.stringify(result)
                });
            }
            setAnswerQuestionary(c_answer)
        } catch (error) {
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }
    const calculateResult = (c_answer: AnswerT[]): ResultDiscT => {
        let result: ResultDiscT = {
            actual: {
                most: {
                    D: c_answer.filter(ca => ca.most === "D").length,
                    I: c_answer.filter(ca => ca.most === "I").length,
                    S: c_answer.filter(ca => ca.most === "S").length,
                    C: c_answer.filter(ca => ca.most === "C").length,
                    '*': c_answer.filter(ca => ca.most === "*").length
                },
                least: {
                    D: c_answer.filter(ca => ca.least === "D").length,
                    I: c_answer.filter(ca => ca.least === "I").length,
                    S: c_answer.filter(ca => ca.least === "S").length,
                    C: c_answer.filter(ca => ca.least === "C").length,
                    '*': c_answer.filter(ca => ca.least === "*").length
                },
                change: {
                    D: c_answer.filter(ca => ca.most === "D").length - c_answer.filter(ca => ca.least === "D").length,
                    I: c_answer.filter(ca => ca.most === "I").length - c_answer.filter(ca => ca.least === "I").length,
                    S: c_answer.filter(ca => ca.most === "S").length - c_answer.filter(ca => ca.least === "S").length,
                    C: c_answer.filter(ca => ca.most === "C").length - c_answer.filter(ca => ca.least === "C").length,
                    '*': c_answer.filter(ca => ca.most === "*").length - c_answer.filter(ca => ca.least === "*").length
                }
            },
            scaled: {
                most: { D: 0, I: 0, S: 0, C: 0, '*': 0 },
                least: { D: 0, I: 0, S: 0, C: 0, '*': 0 },
                change: { D: 0, I: 0, S: 0, C: 0, '*': 0 },
            }
        }
        // calculate scale chart most
        Object.keys(result.scaled.most).forEach((sm, i) => {
            let value_most = 0
            if (sm === "D") { value_most = result.actual.most.D }
            else if (sm === "I") { value_most = result.actual.most.I }
            else if (sm === "S") { value_most = result.actual.most.S }
            else if (sm === "C") { value_most = result.actual.most.C }

            if (
                (sm === "D" && value_most === 0) ||
                (sm === "I" && value_most === 0) ||
                (sm === "S" && value_most === 0) ||
                (sm === "C" && value_most === 0)
            ) {
                result.scaled.most[sm] = -8
            } else if (
                (sm === "D" && value_most === 1) ||
                (sm === "I" && value_most === 1) ||
                (sm === "S" && value_most === 1) ||
                (sm === "C" && value_most === 1)
            ) {
                result.scaled.most[sm] = -6
            } else if (
                (sm === "D" && value_most >= 2 && value_most <= 3) ||
                (sm === "I" && value_most === 2) ||
                (sm === "S" && value_most === 2) ||
                (sm === "C" && value_most === 2)
            ) {
                result.scaled.most[sm] = -4
            } else if (
                (sm === "D" && value_most >= 4 && value_most <= 6) ||
                (sm === "I" && value_most === 3) ||
                (sm === "S" && value_most >= 3 && value_most <= 4) ||
                (sm === "C" && value_most === 3)
            ) {
                result.scaled.most[sm] = -2
            } else if (
                (sm === "D" && value_most >= 7 && value_most <= 8) ||
                (sm === "I" && value_most === 4) ||
                (sm === "S" && value_most >= 5 && value_most <= 6) ||
                (sm === "C" && value_most === 4)
            ) {
                result.scaled.most[sm] = 2
            } else if (
                (sm === "D" && value_most >= 9 && value_most <= 12) ||
                (sm === "I" && value_most >= 5 && value_most <= 6) ||
                (sm === "S" && value_most >= 7 && value_most <= 9) ||
                (sm === "C" && value_most >= 5 && value_most <= 6)
            ) {
                result.scaled.most[sm] = 4
            } else if (
                (sm === "D" && value_most >= 13 && value_most <= 14) ||
                (sm === "I" && value_most >= 7 && value_most <= 8) ||
                (sm === "S" && value_most >= 10 && value_most <= 12) ||
                (sm === "C" && value_most >= 7 && value_most <= 9)
            ) {
                result.scaled.most[sm] = 6
            } else if (
                (sm === "D" && value_most >= 15 && value_most <= 21) ||
                (sm === "I" && value_most >= 9 && value_most <= 19) ||
                (sm === "S" && value_most >= 13 && value_most <= 20) ||
                (sm === "C" && value_most >= 10 && value_most <= 17)
            ) {
                result.scaled.most[sm] = 8
            }
        })
        // calculate scale chart least
        Object.keys(result.scaled.least).forEach((sl, i) => {
            let value_least = 0
            if (sl === "D") { value_least = result.actual.least.D }
            else if (sl === "I") { value_least = result.actual.least.I }
            else if (sl === "S") { value_least = result.actual.least.S }
            else if (sl === "C") { value_least = result.actual.least.C }

            if (
                (sl === "D" && value_least >= 0 && value_least <= 1) ||
                (sl === "I" && value_least === 0) ||
                (sl === "S" && value_least >= 0 && value_least <= 1) ||
                (sl === "C" && value_least >= 0 && value_least <= 1)
            ) {
                result.scaled.least[sl] = 8
            } else if (
                (sl === "D" && value_least === 2) ||
                (sl === "I" && value_least === 1) ||
                (sl === "S" && value_least === 2) ||
                (sl === "C" && value_least === 2)
            ) {
                result.scaled.least[sl] = 6
            } else if (
                (sl === "D" && value_least === 3) ||
                (sl === "I" && value_least >= 2 && value_least <= 3) ||
                (sl === "S" && value_least >= 3 && value_least <= 4) ||
                (sl === "C" && value_least >= 3 && value_least <= 4)
            ) {
                result.scaled.least[sl] = 4
            } else if (
                (sl === "D" && value_least === 4) ||
                (sl === "I" && value_least === 4) ||
                (sl === "S" && value_least >= 5 && value_least <= 6) ||
                (sl === "C" && value_least >= 5 && value_least <= 6)
            ) {
                result.scaled.least[sl] = 2
            } else if (
                (sl === "D" && value_least >= 5 && value_least <= 8) ||
                (sl === "I" && value_least === 5) ||
                (sl === "S" && value_least === 7) ||
                (sl === "C" && value_least >= 7 && value_least <= 8)
            ) {
                result.scaled.least[sl] = -2
            } else if (
                (sl === "D" && value_least >= 9 && value_least <= 10) ||
                (sl === "I" && value_least >= 6 && value_least <= 7) ||
                (sl === "S" && value_least >= 8 && value_least <= 9) ||
                (sl === "C" && value_least >= 9 && value_least <= 10)
            ) {
                result.scaled.least[sl] = -4
            } else if (
                (sl === "D" && value_least >= 12 && value_least <= 14) ||
                (sl === "I" && value_least >= 8 && value_least <= 9) ||
                (sl === "S" && value_least >= 10 && value_least <= 11) ||
                (sl === "C" && value_least >= 11 && value_least <= 12)
            ) {
                result.scaled.least[sl] = -6
            } else if (
                (sl === "D" && value_least >= 15 && value_least <= 20) ||
                (sl === "I" && value_least >= 10 && value_least <= 19) ||
                (sl === "S" && value_least >= 12 && value_least <= 19) ||
                (sl === "C" && value_least >= 13 && value_least <= 17)
            ) {
                result.scaled.least[sl] = -8
            }
        })
        // calculate scale chart change
        Object.keys(result.scaled.change).forEach((sc, i) => {
            let value_change = 0
            if (sc === "D") { value_change = result.actual.change.D }
            else if (sc === "I") { value_change = result.actual.change.I }
            else if (sc === "S") { value_change = result.actual.change.S }
            else if (sc === "C") { value_change = result.actual.change.C }

            if (
                (sc === "D" && value_change >= 14 && value_change <= 21) ||
                (sc === "I" && value_change >= 8 && value_change <= 18) ||
                (sc === "S" && value_change >= 10 && value_change <= 10) ||
                (sc === "C" && value_change >= 5 && value_change <= 17)
            ) {
                result.scaled.change[sc] = 8
            } else if (
                (sc === "D" && value_change >= 10 && value_change <= 13) ||
                (sc === "I" && value_change >= 5 && value_change <= 7) ||
                (sc === "S" && value_change >= 6 && value_change <= 9) ||
                (sc === "C" && value_change >= 3 && value_change <= 4)
            ) {
                result.scaled.change[sc] = 6
            } else if (
                (sc === "D" && value_change >= 6 && value_change <= 9) ||
                (sc === "I" && value_change >= 3 && value_change <= 4) ||
                (sc === "S" && value_change >= 2 && value_change <= 5) ||
                (sc === "C" && value_change >= 1 && value_change <= 2)
            ) {
                result.scaled.change[sc] = 4
            } else if (
                (sc === "D" && value_change >= 1 && value_change <= 5) ||
                (sc === "I" && value_change >= 0 && value_change <= 2) ||
                (sc === "S" && value_change >= 0 && value_change <= 1) ||
                (sc === "C" && value_change >= -2 && value_change <= 0)
            ) {
                result.scaled.change[sc] = 2
            } else if (
                (sc === "D" && value_change >= -4 && value_change <= 0) ||
                (sc === "I" && value_change >= -3 && value_change <= -1) ||
                (sc === "S" && value_change >= -5 && value_change <= -1) ||
                (sc === "C" && value_change >= -4 && value_change <= -3)
            ) {
                result.scaled.change[sc] = -2
            } else if (
                (sc === "D" && value_change >= -9 && value_change <= -5) ||
                (sc === "I" && value_change >= -5 && value_change <= -4) ||
                (sc === "S" && value_change >= -7 && value_change <= -6) ||
                (sc === "C" && value_change >= -7 && value_change <= -5)
            ) {
                result.scaled.change[sc] = -4
            } else if (
                (sc === "D" && value_change >= -12 && value_change <= -10) ||
                (sc === "I" && value_change >= -8 && value_change <= -6) ||
                (sc === "S" && value_change >= -9 && value_change <= -8) ||
                (sc === "C" && value_change >= -10 && value_change <= -8)
            ) {
                result.scaled.change[sc] = -6
            } else if (
                (sc === "D" && value_change >= -20 && value_change <= -13) ||
                (sc === "I" && value_change >= -18 && value_change <= -9) ||
                (sc === "S" && value_change >= -18 && value_change <= -10) ||
                (sc === "C" && value_change >= -22 && value_change <= -11)
            ) {
                result.scaled.change[sc] = -8
            }
        })
        return result
    }

    return (
        <div className="h-[94%] overflow-auto">
            <div className="flex flex-col items-center">
                <div className="text-sm md:text-base max-w-5xl text-center py-3">
                    {disc?.mdi}
                </div>
            </div>
            <div className="text-sm md:text-base border-b flex flex-col items-center pb-20">
                <div className="w-full max-w-5xl">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {Array(24).fill(null).map((_, i) => {
                            // filter and group by questionary number
                            const questionary: TPSDiscQuestionary[] = disc_questionary.filter(dq => dq.number === i + 1)
                            return (
                                <div key={i} className="flex">
                                    <span className="w-6">{i + 1}</span>
                                    <table className="w-full text-sm">
                                        <thead className="text-center bg-slate-100">
                                            <tr>
                                                <th className="w-10 border">M</th>
                                                <th className="w-10 border">L</th>
                                                <th className="border text-left px-2">Statement</th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-center">
                                            {questionary.map((q, qi) => {
                                                const answer = answer_questionary.find(aq => aq.number === i + 1)
                                                return (
                                                    <tr key={`questionary_${qi}`}>
                                                        <td
                                                            className={`border cursor-pointer hover:bg-blue-100 ${answer?.most === q.value_most && answer.most_i === qi ? 'bg-green-100' : ''}`}
                                                            onClick={() => handleAnswer(i, "most", qi, q.value_most, q.number)}>
                                                            <div className="flex justify-center">
                                                                {answer?.most === q.value_most && answer.most_i === qi
                                                                    ? <CheckIcon className='w-4' />
                                                                    : ''
                                                                }
                                                            </div>
                                                        </td>
                                                        <td className={`border cursor-pointer hover:bg-blue-100 ${answer?.least === q.value_least && answer.least_i === qi ? 'bg-green-100' : ''}`}
                                                            onClick={() => handleAnswer(i, "least", qi, q.value_least, q.number)}>
                                                            <div className="flex justify-center">
                                                                {answer?.least === q.value_least && answer.least_i === qi
                                                                    ? <CheckIcon className='w-4' />
                                                                    : ''
                                                                }
                                                            </div>
                                                        </td>
                                                        <td className="border text-left px-2">{q.statement}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            )
                        })}
                    </div>
                    <div className="max-w-5xl w-full mt-3 flex justify-end items-center">
                        {answer_questionary.filter(aq => aq.most !== "" && aq.least !== "").length !== 24 &&
                            <div className="px-3 text-red-500">Tombol <b>Finish</b> tersedia saat semua pernyataan telah dipilih</div>
                        }
                        <button
                            type="button"
                            className={`mx-3 md:mx-0 py-1 px-6 min-w-[80px] text-center cursor-pointer rounded text-white bg-red-500 hover:bg-red-600 disabled:bg-gray-300`}
                            onClick={() => props.closeSession()}
                            disabled={answer_questionary.filter(aq => aq.most !== "" && aq.least !== "").length !== 24}>
                            Finish
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SubtestDisc;
