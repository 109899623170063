import { useState } from "react";
import { Tab } from "@headlessui/react";

// Component
import ListEmail from "./ListEmail";
import ListEmailAddress from "./ListEmailAddress";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

function List() {
  const [tabs] = useState<TTabIntrayEmail[]>([
    { title: "Email", component: <ListEmail /> },
    { title: "Email Address", component: <ListEmailAddress /> },
  ]);

  return (
    <div className="w-full px-2 sm:px-0">
      <Tab.Group>
        <Tab.List className="flex p-1 space-x-1 bg-blue-900/20 rounded-xl">
          {tabs.map((tab: TTabIntrayEmail, idx: number) => (
            <Tab
              key={`${idx}_tab`}
              className={({ selected }) =>
                classNames(
                  "w-full py-2.5 text-sm leading-5 font-medium text-blue-700 rounded-lg",
                  "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
                  selected ? "bg-white shadow" : "text-blue-400 hover:bg-white/[0.12] hover:text-blue-500"
                )
              }
            >
              {tab.title}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-2">
          {tabs.map((tab: TTabIntrayEmail, idx: number) => (
            <Tab.Panel
              key={`${idx}_panel`}
              className={classNames(
                "bg-white rounded-xl p-3",
                "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60"
              )}
            >
              {tab.component}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}

export default List;
