import req from "../../../services/psikotest/request";

class Brs {
  async get() {
    try {
      const res = await req.get("/api/psikotest_system/brs/");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async update(data: TPSBrs) {
    try {
      const res = await req.put("/api/psikotest_system/brs/", data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default Brs;
