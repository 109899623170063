import React from "react";

import WebSocketContext from "./WebSocketContext";

function WebSocketProvider({ children }: { children: React.ReactNode }) {
  const socket = new WebSocket(process.env.REACT_APP_WS ?? "");

  const connect = (callback: VoidFunction) => {
    socket.onopen = () => {
      console.log("Successfully Connected");
      callback();
    };

    socket.onmessage = (msg) => {
      console.log("msg");
      console.log(msg);
      callback();
    };

    socket.onclose = (event) => {
      console.log("Socket Closed Connection: ", event);
      console.log("Socket is closed. Reconnect will be attempted in 1 second.", event.reason);

      setTimeout(function () {
        connect(() => { });
      }, 1000);
    };

    socket.onerror = (error) => {
      console.log("Socket Error: ", error);
    };
  };

  const sendMessage = (scope: string, message: string) => {
    const flagMessage = JSON.stringify({ scope, ...JSON.parse(message) })
    console.log("sending msg: ", flagMessage);
    socket.send(flagMessage);
  };

  const value = { socket, connect, sendMessage };

  return <WebSocketContext.Provider value={value}>{children}</WebSocketContext.Provider>;
}

export default WebSocketProvider;
