import req from "../../../services/psikotest/request";

class RacQuestionary {
  async list() {
    try {
      const res = await req.get("/api/psikotest_system/rac_questionary/");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async create(data: TPSRacQuestionary) {
    try {
      const res = await req.post("/api/psikotest_system/rac_questionary/", data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async get(id: string) {
    try {
      const res = await req.get("/api/psikotest_system/rac_questionary/" + id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async update(data: TPSRacQuestionary) {
    try {
      const res = await req.put("/api/psikotest_system/rac_questionary/" + data.id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async delete(data: TPSRacQuestionary) {
    try {
      const res = await req.delete("/api/psikotest_system/rac_questionary/", { data });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default RacQuestionary;
