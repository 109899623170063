import req from "../../../services/psikotest/request";

class IstGesamt {
  async list() {
    try {
      const res = await req.get("/api/psikotest_system/ist_gesamt/");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async create(data: TPSIstGesamt) {
    try {
      const res = await req.post("/api/psikotest_system/ist_gesamt/", data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async get(id: string) {
    try {
      const res = await req.get("/api/psikotest_system/ist_gesamt/" + id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async update(data: TPSIstGesamt) {
    try {
      const res = await req.put("/api/psikotest_system/ist_gesamt/" + data.id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async delete(data: TPSIstGesamt) {
    try {
      const res = await req.delete("/api/psikotest_system/ist_gesamt/", { data });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async norma(id: number) {
    try {
      const res = await req.get("/api/psikotest_system/ist_gesamt/" + id + "/norma");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default IstGesamt;
