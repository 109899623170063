import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { useCallback, useContext, useEffect } from "react";

// Icons
import { XMarkIcon, ArrowDownOnSquareIcon } from "@heroicons/react/24/outline";

// Contexts
import LoaderContext from "../../../../context/psikotest/LoaderContext";
import SnackbarContext from "../../../../context/psikotest/SnackbarContext";

// Helpers
import errorHandler from "../../../../helper/psikotest/errorHandler";

// Controller
import ResilienceDistraction from "../../../../controller/psikotest/psikotest_system/resilience_distraction";

const schema_base = {
    id: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required(),
    distraction: yup
        .string()
        .label("Distraction")
        .transform((value) => (value === null ? "" : value))
        .required(),
};

function FormDistraction(props: { data?: TPSResilienceDistraction, nextNumber?: number, handleClose: () => void }) {
    // Contexts
    const { setMessage } = useContext(LoaderContext)
    const { setNotif } = useContext(SnackbarContext)

    // set schema
    let schema: any = yup.object().shape(schema_base)

    // react hook form
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch
    } = useForm<TPSResilienceDistraction>({ resolver: yupResolver(schema) });
    // watch all field
    const doc = watch()

    const getDoc = useCallback(
        async function getDoc() {
            try {
                setMessage("Fetch Resilience Distraction");
                // fetch document
                if (props.data === undefined) {
                    // update cfit_id
                    setValue("id", 0);
                    setValue("distraction", "");
                } else {
                    setValue("id", props.data?.id ?? 0);
                    setValue("distraction", props.data?.distraction ?? "");
                }
                setMessage("");
            } catch (error) {
                setMessage("");
                const errorMessage = errorHandler(error);
                setNotif({ type: "error", message: errorMessage });
            }
        },
        [setValue, setNotif, setMessage, props.data]
    );

    useEffect(() => {
        getDoc();
    }, [getDoc]);

    const onSubmit: SubmitHandler<TPSResilienceDistraction> = async (data) => {
        try {
            setMessage("Save Resilience Questionary");
            const rd_qc = new ResilienceDistraction();
            let res;
            if (doc.id === 0) {
                res = await rd_qc.create(data);
            } else {
                res = await rd_qc.update(data.id, data);
            }
            setNotif({ type: "success", message: res.data.message });
            setMessage("");
            props.handleClose();
        } catch (error) {
            setMessage("");
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    };

    return <div>
        <div className="fixed z-20 top-0 left-0 w-screen h-screen bg-black opacity-20"></div>
        <div className="fixed z-30 top-0 left-0 w-screen h-screen bg-transparent flex items-center justify-center">
            <div className="bg-white h-screen w-screen md:h-auto md:w-[60%] md:rounded overflow-auto">
                <div className="p-4 bg-green-800 md:rounded-t text-white flex justify-between">
                    <h3>Form Questionary</h3>
                    <XMarkIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent cursor-pointer" aria-hidden="true" onClick={() => props.handleClose()} />
                </div>
                <div className="p-4 pb-40">
                    <form className="w-full">
                        <div className="flex flex-wrap -mx-3">
                            {/** ID */}
                            <input {...register("id")} type="number" className="hidden" />
                            {errors.id && (
                                <p className="text-red-500 text-xs italic">{errors.id.message}</p>
                            )}
                            {/** Distraction */}
                            <div className="w-full md:w-full px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Distraction</label>
                                <input
                                    className={
                                        (errors?.distraction ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                                        " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                                    }
                                    {...register("distraction")}
                                />
                                {errors.distraction && <p className="text-red-500 text-xs italic">{errors.distraction.message}</p>}
                            </div>
                        </div>
                    </form>
                    <div className="flex">
                        <button
                            onClick={handleSubmit(onSubmit)}
                            className="ml-auto block w-auto px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded font-semibold text-sm"
                        >
                            <div className="flex">
                                <ArrowDownOnSquareIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
                                Save
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div >
    </div >
}


export default FormDistraction;