import { useCallback, useContext, useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";

// Icons
import { PrinterIcon, XMarkIcon } from "@heroicons/react/24/outline";

// Context
import SnackbarContext from "../../../context/assessment/SnackbarContext";
import AuthContext from "../../../context/assessment/AuthContext";

// Helper
import errorHandler from "../../../helper/assessment/errorHandler";

// Controller
import Assessment from "../../../controller/assessment/assessment_system/assessment";

declare type SessionFileCam = {
  file: string[];
  assessmentParticipantSession: TAssessmentParticipantSession;
  lastReplyEmail?: TPQuestionaryReplyEmail;
};

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function FormParticipantReport(props: {
  selectedParticipantReport: number;
  setSelectedParticipantReport: React.Dispatch<React.SetStateAction<number | undefined>>;
}) {
  // Context
  const { user } = useContext(AuthContext);
  const { setNotif } = useContext(SnackbarContext);

  // ref
  const divPrint = useRef(null);

  // Variables
  const time = new Date().toString().substring(0, 24)

  // State
  const [participantDetail, setParticipantDetail] = useState<TAssessmentParticipantDetail>();
  const [assessmentSessionCam, setAssessmentSessionCam] = useState<SessionFileCam[]>([]);

  const getDoc = useCallback(async () => {
    try {
      const ac = new Assessment();
      // get participant detail
      const resGetParticipantDetail = await ac.getParticipant(props.selectedParticipantReport.toString());
      setParticipantDetail(resGetParticipantDetail.data.row);

      // get all assessment_participant_session by assessment_participant_id
      const resGetAssessmentParticipantSession = await ac.listPerParticipantSession(
        props.selectedParticipantReport.toString()
      );
      let prepFileCam: SessionFileCam[] = [];
      for (let index = 0; index < resGetAssessmentParticipantSession.data.list.length; index++) {
        const element: TAssessmentParticipantSession = resGetAssessmentParticipantSession.data.list[index];
        if (element.id !== undefined) {
          // get all snapshot
          const resGetListReport = await ac.listParticipantSnapshot(element.id.toString(), element.assessment_id.toString());
          // get last reply
          let lastReply: TPQuestionaryReplyEmail | undefined = undefined;
          if (element.category.includes("In-Tray Simulation")) {
            // get last email
            const resListIntrayReply = await ac.listIntrayReply(element.id.toString());
            lastReply = resListIntrayReply.data.list[0];
          }
          prepFileCam.push({
            file: resGetListReport.data.list,
            assessmentParticipantSession: element,
            lastReplyEmail: lastReply,
          });
        }
      }
      setAssessmentSessionCam(prepFileCam);
    } catch (error) {
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  }, [setNotif, props.selectedParticipantReport, setParticipantDetail]);

  useEffect(() => {
    getDoc();
  }, [getDoc]);

  return (
    <div>
      <div className="fixed z-20 top-0 left-0 w-screen h-screen bg-black opacity-20"></div>
      <div className="fixed z-30 top-0 left-0 w-screen h-screen bg-transparent flex items-center justify-center">
        <div className="bg-white w-screen md:w-[96%] h-full md:h-[96%] md:rounded overflow-hidden">
          <div className="p-4 bg-green-800 md:rounded-t text-white flex justify-between">
            <h3>Participant Report</h3>
            <button type="button" onClick={() => props.setSelectedParticipantReport(undefined)}>
              <XMarkIcon className="w-6" />
            </button>
          </div>
          <div className="h-[89vh] p-4 overflow-auto">
            <div className="pb-2">
              <ReactToPrint
                trigger={() => (
                  <button
                    type="button"
                    className="flex px-4 py-2 border rounded border-green-600 text-green-600 hover:bg-green-100"
                  >
                    <PrinterIcon className="w-5" />
                    <span className="pl-2">Print</span>
                  </button>
                )}
                content={() => divPrint.current}
              />
            </div>
            <div ref={divPrint} className="py-4 px-8 border">
              <div className="pt-2 px-1 font-semibold text-2xl text-center">Assessment Report</div>
              <div className="pb-2 px-1 font-light italic text-sm text-center">
                <span className="capitalize">{user.username}</span> - {time}
              </div>
              <div className="py-2">
                <div className="py-2 px-1 font-semibold text-xl">Detail Peserta</div>
                {participantDetail !== undefined && (
                  <div className="pl-5 flex items-center">
                    <img width={120} src={`${process.env.REACT_APP_URL}/upload/assessment_system/get_photo/${participantDetail.photo}`} alt="Snapshot Awal" />
                    <div className="px-4">
                      <div className="flex">
                        <span className="w-40 block">Nama Lengkap</span>: {participantDetail?.full_name}
                      </div>
                      <div className="flex">
                        <span className="w-40 block">Email</span>: {participantDetail?.email}
                      </div>
                      <div className="flex">
                        <span className="w-40 block">Jenis Kelamin</span>: {participantDetail?.gender}
                      </div>
                      <div className="flex">
                        <span className="w-40 block">Tanggal Lahir</span>:{" "}
                        {`${new Date(participantDetail.date_of_birth).getDay().toString().padStart(2, "0")} ${monthNames[new Date(participantDetail.date_of_birth).getMonth()]
                          } ${new Date(participantDetail.date_of_birth).getFullYear()}`}
                      </div>
                      <div className="flex">
                        <span className="w-40 block">Kabupaten / Kota</span>: {participantDetail?.city}
                      </div>
                      <div className="flex">
                        <span className="w-40 block">Pendidikan Terakhir</span>: {participantDetail?.last_education}
                      </div>
                      <div className="flex">
                        <span className="w-40 block">Jurusan / Fakultas</span>: {participantDetail?.major}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="py-2">
                <div className="px-1 font-semibold text-xl">Sesi Assessment</div>
              </div>
              {assessmentSessionCam.map((asc, asc_i) => {
                let startTime = "N/A", endTime = "N/A", finishTime = "N/A";
                let startDateTime, finishDateTime;

                if (asc.assessmentParticipantSession.start_time !== null) {
                  startTime = new Date(asc.assessmentParticipantSession.start_time).toString();
                  startDateTime = new Date(asc.assessmentParticipantSession.start_time);
                }
                if (asc.assessmentParticipantSession.end_time !== null) {
                  endTime = new Date(asc.assessmentParticipantSession.end_time).toString();
                }
                if (asc.assessmentParticipantSession.category.includes("In-Tray Simulation")) {
                  if (asc.lastReplyEmail !== undefined && asc.lastReplyEmail.date !== undefined) {
                    finishTime = new Date(asc.lastReplyEmail.date).toString();
                    finishDateTime = new Date(asc.lastReplyEmail.date);
                  }
                } else {
                  if (asc.assessmentParticipantSession.file_path !== null) {
                    const finishUnixTime = parseInt(asc.assessmentParticipantSession.file_path.split("_")[2]);
                    if (isNaN(finishUnixTime) === false) {
                      finishTime = new Date(finishUnixTime * 1000).toString();
                      finishDateTime = new Date(finishUnixTime * 1000);
                    }
                  }
                }

                let durationMinutes = 0, durationSeconds = 0;
                if (startDateTime !== undefined && finishDateTime !== undefined) {
                  let diff: number = Math.abs(finishDateTime.getTime() - startDateTime.getTime());
                  // count in second
                  diff = diff / 1000;
                  durationMinutes = Math.floor(diff / 60);
                  durationSeconds = diff % 60;
                }
                return (
                  <div key={`asc_${asc_i}`} className="px-4">
                    <div className="py-1 font-semibold text-lg">
                      {asc_i + 1}. {asc.assessmentParticipantSession.category}
                    </div>
                    <div className="py-2">
                      <div className="flex">
                        <span className="w-40 block">Waktu Assessment</span>: {startTime.substring(0, 24)} -{" "}
                        {endTime.substring(0, 24)}
                      </div>
                      <div className="flex">
                        <span className="w-40 block">Waktu Selesai</span>: {finishTime.substring(0, 24)}
                      </div>
                      <div className="flex">
                        <span className="w-40 block">Selesai Dalam</span>:&nbsp;
                        <span className="font-bold">
                          {durationMinutes} Menit, {durationSeconds} Detik
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-left gap-2">
                      {asc.file.map((file, file_i) => {
                        const alt = process.env.NODE_ENV === 'development' ? `${file.replace(/\\/g, "-")}` : `${file.replace(/\//g, "-")}`;
                        const filepath = process.env.NODE_ENV === 'development' ? file.replace(/\\/g, "/") : file;
                        const timestamp = process.env.NODE_ENV === 'development'
                          ? parseInt(file.split("\\")[file.split("\\").length - 1].split(".")[0])
                          : parseInt(file.split("/")[file.split("/").length - 1].split(".")[0]);
                        let datestamp: string = "";
                        if (isNaN(timestamp) === false) {
                          datestamp = new Date(timestamp * 1000).toString().substring(0, 24);
                        }
                        return (
                          <div key={`file_${file_i}`}>
                            <img
                              className="w-40"
                              alt={alt}
                              src={`${process.env.REACT_APP_URL}/upload/assessment_system/get_snapshot/${filepath}`}
                            />
                            <div className="text-xs text-center border">{datestamp}</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormParticipantReport;
