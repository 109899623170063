import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

// Icons
import { XMarkIcon } from "@heroicons/react/24/outline";

// Context
import AuthContext from "../../../context/assessment/AuthContext";
import LoaderContext from "../../../context/assessment/LoaderContext";
import SnackbarContext from "../../../context/assessment/SnackbarContext";
import WebSocketContext from "../../../context/WebSocketContext";

// Helpers
import errorHandler from "../../../helper/assessment/errorHandler";

// Controller
import Assessment from "../../../controller/assessment/assessment_system/assessment";

function FormParticipantSession(props: {
  doc: TAssessment;
  participantSession: TAssessmentParticipantSession
}) {
  const { user } = useContext(AuthContext);
  const { setMessage } = useContext(LoaderContext);
  const { setNotif } = useContext(SnackbarContext);
  const { sendMessage } = useContext(WebSocketContext)
  const schema = yup.object().shape({
    start_time: yup.string().label("Start Time").nullable().max(255),
    end_time: yup.string().label("End Time").nullable().max(255),
    roleplay_status_open: yup.string().label("Status").required().default("0").oneOf(["0", "1"]),
  });
  // is admin
  const isAdmin = user.roles === "1";
  // is pic assessment
  const isPicAssessment = props.doc.pic_id.toString() === user.assessor_id;
  // if category RP show open button RP
  const isRp = props.participantSession.category === "Role Playing";
  // if allow to update Timer and open close RP session
  const isAllowUpdate = isAdmin || isPicAssessment;
  // react hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch
  } = useForm<TAssessmentParticipantSession>({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const getDoc = useCallback(
    async function getDoc() {
      setValue("start_time", props.participantSession.start_time);
      setValue("end_time", props.participantSession.end_time);
      setValue("roleplay_status_open", props.participantSession.roleplay_status_open);
    },
    [setValue, props.participantSession]
  );

  useEffect(() => {
    getDoc();
  }, [getDoc]);

  const onSubmit: SubmitHandler<TAssessmentParticipantSession> = async (data) => {
    try {
      const ac = new Assessment();
      if (props.participantSession.id) {
        setMessage("Save Participant Session");
        const updateData = { ...props.participantSession, ...data };
        await ac.updateParticipantSession(updateData);
        setMessage("");
        // broadcast message to all client (target: participant)
        sendMessage("assessment", JSON.stringify({
          target: "participant",
          action: "check open session",
          message: "Timer has been adjusted"
        }))
      }
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  const handleToggleOpen = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setValue("roleplay_status_open", e.target.checked ? "1" : "0")
      const ac = new Assessment();
      if (props.participantSession.id) {
        setMessage("Save Participant Session");
        const updateData: TAssessmentParticipantSession = { ...props.participantSession, roleplay_status_open: e.target.checked ? "1" : "0" };
        await ac.updateParticipantSession(updateData);
        setMessage("");
        // broadcast message to all client (target: participant)
        sendMessage("assessment", JSON.stringify({
          target: "participant",
          action: "check open session",
          message: e.target.checked ? "Assessment open by PIC" : "Assessment close by PIC"
        }))
      }
    } catch (error) {
      setValue("roleplay_status_open", "0")
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  }

  return (
    <div>
      <form onBlur={() => handleSubmit(onSubmit)()} className="w-full hidden md:flex">
        <div className="w-full md:w-4/12 px-3 py-2 border rounded bg-slate-600 text-white">
          {props.participantSession.full_name}
        </div>
        <div className="w-full md:w-4/12 border-x border-white">
          <input
            type="datetime"
            className={
              (errors.start_time ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
              " appearance-none first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-800"
            }
            disabled={true}
            {...register("start_time")}
          />
        </div>
        <div className="w-full md:w-4/12 border-x border-white">
          <input
            type="datetime"
            className={
              (errors.end_time ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
              " appearance-none first-letter:appearance-none block w-full disabled:bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-800"
            }
            disabled={(watch("end_time") === null || !isAllowUpdate)}
            {...register("end_time")}
          />
        </div>
        {isRp &&
          <div className="w-full md:w-1/12 border-x border-white relative">
            <div className={(watch("roleplay_status_open") === "1" ? "bg-green-500" : "bg-slate-500") + " px-3 py-2 border rounded  text-white text-center"}>
              {watch("roleplay_status_open") === "1" ? "Open" : "Close"}
            </div>
            <input
              type="checkbox"
              className={
                (errors.roleplay_status_open ? "border-red-500" : "border-gray-200") +
                " h-full absolute top-0 appearance-none first-letter:appearance-none block w-full bg-transparent border rounded py-2 px-3 focus:outline-none disabled:text-gray-800 cursor-pointer disabled:cursor-not-allowed"
              }
              onChange={e => handleToggleOpen(e)}
              checked={watch("roleplay_status_open") === "1"}
              title={isAllowUpdate ? "Open / close session" : "Only admin and PIC have access to open and close session"}
              disabled={!isAllowUpdate}
            />
          </div>
        }
      </form >
    </div >
  )
}

function FormParticipantSessionPanel(props: {
  doc: TAssessment;
  assessorOptions: TSelect[];
  isOpenParticipantSessionPanel: TAssessmentSession | undefined;
  setIsOpenParticipantSessionPanel: (close: undefined) => void;
}) {
  const { message, setMessage } = useContext(LoaderContext);
  const { setNotif } = useContext(SnackbarContext);
  // state
  const [participantSessionList, setParticipantSessionList] = useState<TAssessmentParticipantSession[]>([]);

  const getDoc = useCallback(
    async function getDoc() {
      try {
        // fetch all assessment participant session
        const ac = new Assessment();
        const resListParticipantSession = await ac.listParticipantSession(props.doc.id.toString());
        // filter only selected session
        setParticipantSessionList(resListParticipantSession.data.list.filter((v: TAssessmentParticipantSession) => v.assessment_session_id === props.isOpenParticipantSessionPanel?.id));
      } catch (error) {
        setMessage("");
        const errorMessage = errorHandler(error);
        setNotif({ type: "error", message: errorMessage });
      }
    },
    [setNotif, setMessage, props.doc.id, props.isOpenParticipantSessionPanel?.id]
  );

  useEffect(() => {
    getDoc();
  }, [getDoc]);

  return (
    <div>
      <div className="fixed z-20 top-0 left-0 w-screen h-screen bg-black opacity-20"></div>
      <div className="fixed z-30 top-0 left-0 w-screen h-screen bg-transparent flex items-center justify-center">
        <div className="bg-white w-screen md:w-[96%] h-full md:h-[96%] md:rounded overflow-hidden">
          <div className="p-4 bg-cyan-800 rounded-t text-white flex justify-between">
            <h3>Participant Session ({props.isOpenParticipantSessionPanel?.category} - {props.isOpenParticipantSessionPanel?.description})</h3>
            <button type="button" onClick={() => props.setIsOpenParticipantSessionPanel(undefined)}>
              <XMarkIcon className="w-6" />
            </button>
          </div>
          <div className="w-full h-0.5">
            {message !== "" && (
              <div className="h-full bg-gradient-to-r bg-slate-200 from-cyan-300 to-blue-700 bg-20% bg-repeat-y bg-posloader animate-loader"></div>
            )}
          </div>
          <div className="flex px-4">
            &nbsp;
            {message !== "" && <div className="hidden md:block ml-auto text-gray-600 text-right">{message} . . .</div>}
          </div>
          <div className="w-screen md:w-full h-[93%] overflow-auto p-4 pt-0">
            <div className="w-full hidden md:flex">
              <div className="w-full md:w-4/12 px-3 py-2 border rounded bg-slate-600 text-white">Name</div>
              <div className="w-full md:w-4/12 px-3 py-2 border rounded bg-slate-600 text-white">Start Time</div>
              <div className="w-full md:w-4/12 px-3 py-2 border rounded bg-slate-600 text-white">End Time</div>
              {props.isOpenParticipantSessionPanel?.category === "Role Playing" &&
                <div className="w-full md:w-1/12 px-3 py-2 border rounded bg-slate-500 text-white text-center">Status</div>
              }
            </div>
            {participantSessionList.map((participantSession: TAssessmentParticipantSession, i) => (
              <FormParticipantSession doc={props.doc} participantSession={participantSession} key={`participant_session_${i}`} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormParticipantSessionPanel;
