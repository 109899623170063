import { useCallback, useContext, useState, useEffect, useMemo, Fragment } from "react";
import { useMediaQuery } from "react-responsive";
import Swal from "sweetalert2";
import { Switch, Transition } from "@headlessui/react";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// Icons
import { ClipboardDocumentListIcon, PlusIcon, TrashIcon, } from "@heroicons/react/24/outline";

// Context
import SnackbarContext from "../../../context/psikotest/SnackbarContext";
import LoaderContext from "../../../context/psikotest/LoaderContext";

// Helper
import errorHandler from "../../../helper/psikotest/errorHandler";
import logRender from "../../../helper/psikotest/logRender";

// Controller
import Resilience from "../../../controller/psikotest/psikotest_system/resilience";
import ResilienceDistraction from "../../../controller/psikotest/psikotest_system/resilience_distraction";

// Component
import TableFilter from "../../../components/psikotest/psikotest_system/TableFilter";
import Table from "../../../components/psikotest/psikotest_system/Table";

// Modals
import FormDistraction from "./modal/FormDistraction";
import FormImportDistraction from "./modal/FormImportDistraction";

const schema = yup.object().shape({
  id: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required(),
  mdi: yup
    .string()
    .label("(MDI) Main Question | Description | Instructions")
    .transform((value) => (value === null ? "" : value))
    .required(),
  trial_time: yup
    .number()
    .label("Trial Time (Minute)")
    .transform((value) => (isNaN(value) ? undefined : value))
    .required(),
  duration_time: yup
    .number()
    .label("Duration Time (Minute)")
    .transform((value) => (isNaN(value) ? undefined : value))
    .required(),
  use_keyboard_in_display: yup.number().label("Use Keyboard on Display").required().default(0).oneOf([0, 1]),
});

function List() {
  const { setMessage } = useContext(LoaderContext);
  const { setNotif } = useContext(SnackbarContext);
  // required state
  const [list, setList] = useState<Array<TPSResilienceDistraction>>([]);
  const [search, setSearch] = useState<string>("");
  const [focus_mdi, setFocusMdi] = useState<boolean>(false);
  const [focus_trial_time, setFocusTrialTime] = useState<boolean>(false);
  const [focus_duration_time, setFocusDurationTime] = useState<boolean>(false);
  // state modal
  const [isOpenFormDistraction, setIsOpenFormDistraction] = useState<boolean>(false);
  const [isOpenFormImportDistraction, setIsOpenFormImportDistraction] = useState<boolean>(false);
  // state other
  const [selected_resilience_distraction, setSelectedResilienceDistraction] = useState<TPSResilienceDistraction | undefined>(undefined);
  // helper
  const [isUseKeyboardOnDisplay, setIsUseKeyboardOnDisplay] = useState(false);

  // use form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<TPSResilience>({ resolver: yupResolver(schema), mode: "onBlur" });


  useEffect(() => {
    logRender({ type: "page", name: "resilience_distraction/List" });
  }, []);

  const detData = useCallback(async () => {
    try {
      setMessage("Fetch Resilience Distraction");
      const rd_qc = new ResilienceDistraction();
      const res_qc = await rd_qc.list();
      setList(res_qc.data.list);
      setMessage("");
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  }, [setNotif, setMessage]);

  const getDoc = useCallback(
    async function getDoc() {
      try {
        // fetch rac document
        setMessage("Fetch Document");
        const cd = new Resilience();
        const res = await cd.get();
        const row: TPSResilience = res.data.row;
        setValue("id", row.id);
        setValue("mdi", row.mdi);
        setValue("trial_time", row.trial_time);
        setValue("duration_time", row.duration_time);
        setValue("use_keyboard_in_display", row.use_keyboard_in_display);
        setIsUseKeyboardOnDisplay(row.use_keyboard_in_display === 1);
        setMessage("");
      } catch (error) {
        setMessage("");
        const errorMessage = errorHandler(error);
        setNotif({ type: "error", message: errorMessage });
      }
    },
    [setValue, setNotif, setMessage]
  );

  useEffect(() => {
    getDoc();
    detData();
  }, [getDoc, detData]);

  useEffect(() => {
    setValue("use_keyboard_in_display", isUseKeyboardOnDisplay ? 1 : 0);
  }, [setValue, isUseKeyboardOnDisplay]);

  const handleDeleteList = (id: number) => {
    const arrFilter = [...list].filter((v) => v.id !== id);
    setList(arrFilter);
  };

  // when selected_resilience_distraction changes and not undefined open Cfit Questionary form to update
  useEffect(() => {
    if (selected_resilience_distraction !== undefined) {
      setIsOpenFormDistraction(true)
    }
  }, [selected_resilience_distraction]);

  // handleRefreshAfterOpenForm
  const handleRefreshAfterOpenForm = () => {
    // rmove selected value will close form modal
    setSelectedResilienceDistraction(undefined)
    // close modal
    setIsOpenFormDistraction(false)
    // re-fetch data
    detData();
  }

  // handleRefreshAfterImport
  const handleRefreshAfterImport = () => {
    // close import form modal
    setIsOpenFormImportDistraction(false)
    // re-fetch data
    detData();
  }

  const onSubmit: SubmitHandler<TPSResilience> = async (data) => {
    try {
      setMessage("Save Resilience");
      const cd = new Resilience();
      let res = await cd.update(data);
      setNotif({ type: "success", message: res.data.message });
      setMessage("");
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  return (
    <div className="w-full flex flex-col space-y-0.5">
      {/* Import Questionary Form */}
      <Transition
        as={Fragment}
        show={isOpenFormImportDistraction}
        enter="ease-out duration-50"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-50"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div>
          <FormImportDistraction
            nextNumber={list.length + 1}
            handleClose={() => setIsOpenFormImportDistraction(false)}
            handleRefreshAfterImport={handleRefreshAfterImport}
          />
        </div>
      </Transition>
      {/* Questionary Form */}
      <Transition
        as={Fragment}
        show={isOpenFormDistraction}
        enter="ease-out duration-50"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-50"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div>
          <FormDistraction data={selected_resilience_distraction} nextNumber={list.length + 1} handleClose={() => handleRefreshAfterOpenForm()} />
        </div>
      </Transition>
      {/* Main Form */}
      <form className="w-full" onBlur={() => handleSubmit(onSubmit)()}>
        <div className="flex flex-wrap -mx-3">
          <input {...register("id")} type="number" className="hidden" />
          {/** (MDI) Main Question | Description | Instructions */}
          <div className="w-full md:w-full px-3 mb-3">
            <label className="text-gray-700 text-xs mb-2">
              <span className="block uppercase tracking-wide font-bold">(MDI) Main Question | Description | Instructions</span>
              <i>Main question/description/intructions for sub-test, always show up until test ended.</i>
            </label>
            <textarea
              className={
                (errors?.mdi ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
              }
              rows={4}
              {...register("mdi")}
              onFocus={() => setFocusMdi(true)}
              onBlur={() => setFocusMdi(false)}
            />
            {errors.mdi && <p className="text-red-500 text-xs italic">{errors.mdi.message}</p>}
            <label className="text-xs mb-2">
              {focus_mdi
                ? <span className="text-green-600 block uppercase tracking-wide font-bold">AUTOSAVE ON OUT FOCUS</span>
                : <span className="text-blue-600 block uppercase tracking-wide font-bold">CLICK IN TEXTAREA TO EDIT</span>
              }
            </label>
            <input {...register("id")} type="number" className="hidden" />
          </div>
          {/** Trial Time */}
          <div className="w-full md:w-1/2 px-3 mb-6">
            <label className="text-gray-700 text-xs mb-2">
              <span className="block uppercase tracking-wide font-bold">Trial Time (In Minute)</span>
              <i>Participant can try the test in opening session.</i>
            </label>
            <input
              type="number"
              className={
                (errors?.trial_time ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
              }
              {...register("trial_time")}
              onFocus={() => setFocusTrialTime(true)}
              onBlur={() => setFocusTrialTime(false)}
            />
            {errors.trial_time && <p className="text-red-500 text-xs italic">{errors.trial_time.message}</p>}
            <label className="text-xs mb-2">
              {focus_trial_time
                ? <span className="text-green-600 block uppercase tracking-wide font-bold">AUTOSAVE ON OUT FOCUS</span>
                : <span className="text-blue-600 block uppercase tracking-wide font-bold">CLICK IN INPUT TO EDIT</span>
              }
            </label>
          </div>
          {/** Duration Time */}
          <div className="w-full md:w-1/2 px-3 mb-6">
            <label className="text-gray-700 text-xs mb-2">
              <span className="block uppercase tracking-wide font-bold">Duration Time (In Minute)</span>
              <i>Duration test, auto close when test duration ended.</i>
            </label>
            <input
              type="number"
              className={
                (errors?.duration_time ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
              }
              {...register("duration_time")}
              onFocus={() => setFocusDurationTime(true)}
              onBlur={() => setFocusDurationTime(false)}
            />
            {errors.duration_time && <p className="text-red-500 text-xs italic">{errors.duration_time.message}</p>}
            <label className="text-xs mb-2">
              {focus_duration_time
                ? <span className="text-green-600 block uppercase tracking-wide font-bold">AUTOSAVE ON OUT FOCUS</span>
                : <span className="text-blue-600 block uppercase tracking-wide font-bold">CLICK IN INPUT TO EDIT</span>
              }
            </label>
          </div>
          {/** Use Keyboard on Display */}
          <div className="w-full md:w-1/2 px-3 mb-6">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Use keyboard on display
              <i className={`ml-2 font-medium normal-case ${isUseKeyboardOnDisplay ? "text-blue-500" : "text-red-500"}`}>
                ({isUseKeyboardOnDisplay ? "Yes" : "No"})
              </i>
            </label>
            <div className="flex">
              <Switch
                checked={isUseKeyboardOnDisplay}
                onChange={setIsUseKeyboardOnDisplay}
                className={`${isUseKeyboardOnDisplay ? "bg-blue-500" : "bg-red-500"
                  } relative inline-flex flex-shrink-0 h-[25px] w-[60px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={`${isUseKeyboardOnDisplay ? "translate-x-7" : "translate-x-0"
                    } pointer-events-none inline-block h-[21px] w-[28px] rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
                />
              </Switch>
            </div>
            {errors.use_keyboard_in_display && <p className="text-red-500 text-xs italic">{errors.use_keyboard_in_display.message}</p>}
          </div>
        </div>
      </form>
      <div className="flex flex-col md:flex-row items-start md:items-center">
        <div className="flex space-x-1">
          <button type="button"
            onClick={() => { setIsOpenFormImportDistraction(true) }}
            className="block w-auto mb-2 md:m-0 px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded font-semibold text-sm"
          >
            <div className="flex">
              <ClipboardDocumentListIcon className="w-4" />
              <span className="block pl-1">Import</span>
            </div>
          </button>
          <button
            type="button"
            onClick={() => { setIsOpenFormDistraction(true) }}
            className="block w-auto mb-2 md:m-0 px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded font-semibold text-sm"
          >
            <div className="flex">
              <PlusIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
              Create
            </div>
          </button>
        </div>
        <div className="w-full md:w-auto ml-auto flex">
          <TableFilter search={setSearch} />
        </div>
      </div>
      <div className="py-5">
        <ResilienceDistractionList list={list} handleDeleteList={handleDeleteList} search={search} setSelectedResilienceDistraction={setSelectedResilienceDistraction} />
      </div>
    </div >
  );
}

function ResilienceDistractionList(props: {
  list: Array<TPSResilienceDistraction>;
  handleDeleteList: (id: number) => void;
  search: string,
  setSelectedResilienceDistraction: React.Dispatch<React.SetStateAction<TPSResilienceDistraction | undefined>>
}) {
  const setNotif = useContext(SnackbarContext).setNotif;
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const confirmDelete = useCallback(
    async (rd_q: TPSResilienceDistraction) => {
      try {
        const confirm = await Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        });
        if (confirm.isConfirmed) {
          const rd_qc = new ResilienceDistraction();
          await rd_qc.delete(rd_q.id);
          props.handleDeleteList(rd_q.id);
          setNotif({ type: "success", message: "resilience distraction deleted" });
        }
      } catch (error) {
        const errorMessage = errorHandler(error);
        setNotif({ type: "error", message: errorMessage });
      }
    },
    [props, setNotif]
  );

  const columns = useMemo(
    () => [
      {
        id: "mobile",
        Header: "Distraction",
        accessor: "distraction",
        show: isMobile,
        Cell: (colProps: { row: { original: TPSResilienceDistraction } }) => {
          return (
            <button className="text-blue-500 hover:text-blue-800" onClick={() => props.setSelectedResilienceDistraction(colProps.row.original)}>
              {colProps.row.original.distraction || "NULL"}
            </button>
          );
        },
      },
      {
        id: "distraction",
        classHeader: "text-left px-2",
        Header: <div title="distraction">Distraction</div>,
        classDivHeader: "flex items-center",
        accessor: "distraction",
        show: !isMobile,
        Cell: (colProps: { row: { original: TPSResilienceDistraction } }) => {
          return (
            <button className="flex items-center text-blue-500 hover:text-blue-800" onClick={() => props.setSelectedResilienceDistraction(colProps.row.original)}>
              {colProps.row.original.distraction}
            </button>
          );
        },
      },
      {
        id: "action_delete",
        classHeader: "w-[30px] text-white",
        Header: () => {
          return <TrashIcon className="h-5 w-auto" />;
        },
        classDivHeader: "flex justify-center",
        accessor: "id",
        show: !isMobile,
        disableSortBy: true,
        className: "text-white bg-red-500 hover:bg-red-800",
        Cell: (props: { row: { original: TPSResilienceDistraction } }) => {
          return (
            <button className="flex justify-center" onClick={() => confirmDelete(props.row.original)}>
              <TrashIcon className="h-5" />
            </button>
          );
        },
      },
    ],
    [isMobile, confirmDelete, props]
  );

  return <Table columns={columns} list={props.list} search={props.search} />;
}

export default List;
