import req from "../../../services/psikotest/request";

class AssessmentIstSubtest {
  async create(data: TPSAssessmentIstSubtest) {
    try {
      const res = await req.post("/api/psikotest_system/assessment_ist_subtest/", data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async update(data: TPSAssessmentIstSubtest) {
    try {
      const res = await req.put("/api/psikotest_system/assessment_ist_subtest/" + data.id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async delete(data: TPSAssessmentIstSubtest) {
    try {
      const res = await req.delete("/api/psikotest_system/assessment_ist_subtest/", { data });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async participant_session(data: TPSAssessmentIstSubtest) {
    try {
      const res = await req.get("/api/psikotest_system/assessment_ist_subtest/" + data.id + "/participant_session");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async ist_answer_session(data: TPSAssessmentIstSubtest) {
    try {
      const res = await req.get("/api/psikotest_system/assessment_ist_subtest/" + data.id + "/ist_answer_session");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default AssessmentIstSubtest;
