import req from "../../../services/psikotest/request";

class Assessment {
  async list() {
    try {
      const res = await req.get("/api/psikotest_system/assessment/");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async create(data: TPSAssessment) {
    try {
      const res = await req.post("/api/psikotest_system/assessment/", data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async get(id: string) {
    try {
      const res = await req.get("/api/psikotest_system/assessment/" + id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async update(data: TPSAssessment) {
    try {
      const res = await req.put("/api/psikotest_system/assessment/" + data.id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async delete(data: TPSAssessment) {
    try {
      const res = await req.delete("/api/psikotest_system/assessment/", { data });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async participant(id: number) {
    try {
      const res = await req.get("/api/psikotest_system/assessment/" + id + "/participant");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async send_invitation(data: { participant: TPSAssessmentParticipant[]; assessment: TPSAssessment }) {
    try {
      const res = await req.post("/api/psikotest_system/assessment/send_invitation", data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async participant_snapshot(id: number, participant_id: number) {
    try {
      const res = await req.get("/api/psikotest_system/assessment/" + id + "/participant_snapshot/" + participant_id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async cfit_subtest(id: number) {
    try {
      const res = await req.get("/api/psikotest_system/assessment/" + id + "/cfit_subtest");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async ist_subtest(id: number) {
    try {
      const res = await req.get("/api/psikotest_system/assessment/" + id + "/ist_subtest");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async cfit_score(id: number) {
    try {
      const res = await req.get("/api/psikotest_system/assessment/" + id + "/cfit_score");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async ist_score(id: number) {
    try {
      const res = await req.get("/api/psikotest_system/assessment/" + id + "/ist_score");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async personality_subtest(id: number) {
    try {
      const res = await req.get("/api/psikotest_system/assessment/" + id + "/personality_subtest");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async personality_score(id: number) {
    try {
      const res = await req.get("/api/psikotest_system/assessment/" + id + "/personality_score");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async behavioural_subtest(id: number) {
    try {
      const res = await req.get("/api/psikotest_system/assessment/" + id + "/behavioural_subtest");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async behavioural_score(id: number) {
    try {
      const res = await req.get("/api/psikotest_system/assessment/" + id + "/behavioural_score");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default Assessment;
