
import { useCallback, useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";

// Icons
import { PlusIcon } from "@heroicons/react/24/outline";

// Context
import SnackbarContext from "../../../context/psikotest/SnackbarContext";
import LoaderContext from "../../../context/psikotest/LoaderContext";

// Helper
import errorHandler from "../../../helper/psikotest/errorHandler";

// Controller
import IstGesamt from "../../../controller/psikotest/psikotest_system/ist_gesamt";
import IstGesamtNorma from "../../../controller/psikotest/psikotest_system/ist_gesamt_norma";

// Costant Value
const minRsMinValue = 1
const minRsMaxValue = 10
const minSwValue = 63
const maxGesampNorma = 50

function ListPersentil(props: { isCreate: boolean; ig: TPSIstGesamt; editable: boolean }) {
    const { setMessage } = useContext(LoaderContext)
    const { setNotif } = useContext(SnackbarContext)
    // state list norma
    const [norma, setNorma] = useState<TPSIstGesamtNorma[]>([])

    const getDoc = useCallback(
        async function getDoc() {
            try {
                if (!props.isCreate) {
                    if (props.ig.id) {
                        // fetch ist_gesamt list
                        setMessage("Fetch Ist Gesamt");
                        const cig = new IstGesamt();
                        const res = await cig.norma(props.ig.id);
                        const list: TPSIstGesamtNorma[] = res.data.list;
                        setNorma(list);
                    }
                }
                setMessage("");
            } catch (error) {
                setMessage("");
                const errorMessage = errorHandler(error);
                setNotif({ type: "error", message: errorMessage });
            }
        },
        [setNotif, setMessage, props.ig.id, props.isCreate]
    );

    useEffect(() => {
        getDoc();
    }, [getDoc]);

    // handle create empty norma
    const createEmptyNorma = async () => {
        try {
            let createData: TPSIstGesamtNorma = {
                id: 0,
                ist_gesamt_id: props.ig.id,
                rs_min: norma.length === 0
                    ? minRsMinValue
                    : (norma[norma.length - 1]?.rs_min ?? 0) + 10,
                rs_max: norma.length === 0
                    ? minRsMaxValue
                    : (norma[norma.length - 1]?.rs_max ?? 0) + 10,
                sw: norma.length === 0
                    ? minSwValue
                    : (norma[norma.length - 1]?.sw ?? 0) + 1,
            }
            const cign = new IstGesamtNorma();
            const res = await cign.create(createData)
            createData.id = res.data.result.saved_id
            // push new created persentil to persentil array
            setNorma([...norma, createData])
        } catch (error) {
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }

    // handle update 
    const updateNorma = async (e: React.ChangeEvent<HTMLInputElement>, data: TPSIstGesamtNorma, index: number) => {
        try {
            let updateData: TPSIstGesamtNorma = {
                id: data.id,
                ist_gesamt_id: data.ist_gesamt_id,
                rs_min: e.target.name === "rs_min" ? parseInt(e.target.value) : data.rs_min,
                rs_max: e.target.name === "rs_max" ? parseInt(e.target.value) : data.rs_max,
                sw: e.target.name === "sw" ? parseInt(e.target.value) : data.sw
            }
            // copy to update state 
            const copyNorma = [...norma];
            copyNorma[index] = updateData
            // update state
            setNorma(copyNorma)
            // update to server
            const cign = new IstGesamtNorma();
            await cign.update(updateData)
            return
        } catch (error) {
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
        // re-fetch data
        // make sure run even on error
        getDoc();
    }

    // handle delete
    const confirmDelete = useCallback(
        async (ign: TPSIstGesamtNorma) => {
            try {
                const confirm = await Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                });
                if (confirm.isConfirmed) {
                    const cign = new IstGesamtNorma();
                    await cign.delete(ign);
                    setNotif({ type: "success", message: "ist gesamt norma deleted" });
                    // re-fetch data
                    getDoc();
                }
            } catch (error) {
                const errorMessage = errorHandler(error);
                setNotif({ type: "error", message: errorMessage });
            }
        },
        [setNotif, getDoc]
    );

    return (
        <div className="pb-[40rem]">
            {norma.length < maxGesampNorma && props.editable && (
                <div className="w-full md:w-full mb-2">
                    <button
                        type="button"
                        onClick={() => { createEmptyNorma() }}
                        className="block w-auto px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded font-semibold text-sm"
                    >
                        <div className="flex">
                            <PlusIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
                            Norma
                        </div>
                    </button>
                </div>
            )}
            <div className="pt-2">
                <table className="w-full border">
                    <thead className="bg-slate-600 text-white">
                        <tr>
                            <th className="py-1 px-2 text-center border border-slate-500" colSpan={2}>RS Range</th>
                            <th className="py-1 px-2 text-left border border-slate-500" rowSpan={2}>SW</th>
                            <th className="py-1 px-2 w-20 border border-slate-500" rowSpan={2}>Delete</th>
                        </tr>
                        <tr>
                            <th className="py-1 px-2 border border-slate-500">Min</th>
                            <th className="py-1 px-2 border border-slate-500">Max</th>
                        </tr>
                    </thead>
                    <tbody>
                        {norma.map((n, i) => {
                            return (
                                <tr key={`norma_${i}`}>
                                    <td className="py-1 px-2 border">
                                        <input type="number" name="rs_min" className="border pl-3" value={n.rs_min} onChange={(e) => updateNorma(e, n, i)} />
                                    </td>
                                    <td className="py-1 px-2 border">
                                        <input type="number" name="rs_max" className="border pl-3" value={n.rs_max} onChange={(e) => updateNorma(e, n, i)} />
                                    </td>
                                    <td className="py-1 px-2 border">
                                        <input type="number" name="sw" className="border pl-3" value={n.sw} onChange={(e) => updateNorma(e, n, i)} />
                                    </td>
                                    <td className="py-1 px-2 border text-center">
                                        <button type="button" className="px-2 py-[2px] rounded bg-red-500 text-white" onClick={() => confirmDelete(n)}>
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ListPersentil;
