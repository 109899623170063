import React, { useCallback, useContext, useEffect, useState } from "react";
import * as XLSX from 'xlsx';

// Icons
import { PrinterIcon, XMarkIcon } from "@heroicons/react/24/outline";

// Contexts
import LoaderContext from "../../../../context/psikotest/LoaderContext";
import SnackbarContext from "../../../../context/psikotest/SnackbarContext";

// Helpers
import errorHandler from "../../../../helper/psikotest/errorHandler";

// Controller
import Assessment from "../../../../controller/psikotest/psikotest_system/assessment"

function ModalCfitScore(
    props: {
        doc: TPSAssessment;
        assessment_id: number,
        handleClose: () => void,
    }) {
    const { setMessage } = useContext(LoaderContext);
    const { setNotif } = useContext(SnackbarContext);
    // state
    const [cfit, setCfit] = useState<TPSAssessmentCfitSubtest[]>([])
    const [cfit_score, setCfitScore] = useState<TPSAssessmentCfitScore[]>([])

    const getDoc = useCallback(
        async function getDoc() {
            try {
                // fetch cfit subtest
                setMessage("Fetch Cfit Score");
                setMessage("Fetch Cfit");
                const ca = new Assessment()
                let res = await ca.cfit_subtest(props.assessment_id);
                setCfit(res.data.list)
                // fetch cfit score
                setMessage("Fetch Cfit Score");
                res = await ca.cfit_score(props.assessment_id)
                setCfitScore(res.data.list)
                setMessage("");
            } catch (error) {
                setMessage("");
                const errorMessage = errorHandler(error);
                setNotif({ type: "error", message: errorMessage });
            }
        },
        [setNotif, setMessage, props.assessment_id]
    );

    useEffect(() => {
        getDoc();
    }, [getDoc]);

    const letterFromNumber = (n: number): string => {
        var letter = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.charAt(n % 26)
        var concat = Math.round(n / 26);
        return concat > 0 ? letterFromNumber(concat - 1) + letter : letter;
    };

    const downloadExcel = () => {
        let data = []
        //
        data.push({})
        // actual data
        cfit_score.forEach((cs, i) => {
            const composedData: { [key: string]: any } = {
                No: i + 1,
                Participant: cs.participant.full_name,
                Age: cs.age,
                Gender: cs.participant.gender,
            }
            cfit.forEach((c, j) => {
                const current_score = cs.participant_score.find(cps => cps.cfit_id === c.cfit_id)
                if (c.cfit?.test_name !== undefined) {
                    composedData[`${c.cfit.test_name}`] = current_score?.score ?? "-"
                }
            })
            composedData[`CFIT Total Score`] = cs.total_score
            composedData[`CFIT IQ`] = cs.iq ?? "-"
            data.push(composedData)
        })

        // generate worksheet and workbook 
        const worksheet = XLSX.utils.json_to_sheet(data)
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, "CFIT Score")

        // fix headers
        // main header
        let header: string[] = ["No", "Participant", "Age", "Gender"];
        let subheader: string[] = header
        const cfit_subtest: string[] = []
        // cfit header
        cfit.forEach(c => { cfit_subtest.push(c.cfit?.test_name ?? "-") })
        header = [...header, ...cfit_subtest, "Total Score", "IQ"]
        subheader = header
        XLSX.utils.sheet_add_aoa(worksheet, [header, subheader], { origin: "A1" });

        // merge column 
        worksheet["!merges"] = []
        // No
        worksheet["!merges"].push(XLSX.utils.decode_range("A1:A2"));
        // Participant
        worksheet["!merges"].push(XLSX.utils.decode_range("B1:B2"));
        // Age
        worksheet["!merges"].push(XLSX.utils.decode_range("C1:C2"));
        // Gender
        worksheet["!merges"].push(XLSX.utils.decode_range("D1:D2"));
        let alphabet = "A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z"
        // generated alphabet up to 100 column
        for (let index = 0; index <= 100; index++) {
            const column = alphabet.split(",")[index];
            if (column === undefined) {
                alphabet += `,${letterFromNumber(index)}`
            }
        }

        let currAlphabet = alphabet.split(",")[worksheet["!merges"].length]
        // CFIT [....]
        cfit.forEach((_, i) => {
            if (worksheet["!merges"]) {
                worksheet["!merges"].push(XLSX.utils.decode_range(`${currAlphabet}1:${currAlphabet}2`));
                currAlphabet = alphabet.split(",")[worksheet["!merges"].length]
            }
        })
        // CFIT Score
        worksheet["!merges"].push(XLSX.utils.decode_range(`${currAlphabet}1:${currAlphabet}2`));
        // CFIT IQ
        currAlphabet = alphabet.split(",")[worksheet["!merges"].length]
        worksheet["!merges"].push(XLSX.utils.decode_range(`${currAlphabet}1:${currAlphabet}2`));

        // set width
        var wscols = [
            { width: 10 },
            { width: 30 }
        ];
        worksheet["!cols"] = wscols;

        // create an XLSX file and try to save to .xlsx
        XLSX.writeFile(workbook, `${props.doc.assessment_title} - CFIT Score Report.xlsx`)
    };

    return <div>
        <div className="fixed z-20 top-0 left-0 w-screen h-screen bg-black opacity-20"></div>
        <div className="fixed z-30 top-0 left-0 w-screen h-screen bg-transparent flex items-center justify-center">
            <div className="flex flex-col bg-white w-screen md:w-[90%] h-[90%] md:rounded">
                <div className="p-4 bg-blue-800 md:rounded-t text-white flex justify-between">
                    <h3>CFIT Score</h3>
                    <XMarkIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent cursor-pointer" aria-hidden="true" onClick={() => props.handleClose()} />
                </div>
                <div className="flex p-2">
                    <button
                        type="button"
                        onClick={() => downloadExcel()}
                        className="ml-auto flex items-center bg-green-600 py-1 px-2 text-white rounded"
                    >
                        <PrinterIcon className="w-4" />
                        <span className="block pl-1">Print</span>
                    </button>
                </div>
                <div className="w-full h-[90%] px-2 pb-10">
                    <div className="w-full h-full overflow-auto">
                        <table className="w-full border-separate border-spacing-0 table-fixed">
                            <thead className="bg-slate-600 text-white sticky top-[0px] z-10">
                                <tr>
                                    <th className="py-1 px-2 w-8 border border-slate-500 md:sticky md:left-[0px] bg-slate-600">No</th>
                                    <th className="py-1 px-2 w-64 text-left border border-slate-500 md:sticky md:left-[32px] bg-slate-600">Participant</th>
                                    <th className="py-1 px-2 w-14 text-center border border-slate-500 md:sticky md:left-[288px] bg-slate-600">Age</th>
                                    <th className="py-1 px-2 w-24 text-center border border-slate-500 md:sticky md:left-[344px] bg-slate-600">Gender</th>
                                    {cfit.map((c, i) => {
                                        return (
                                            <React.Fragment key={`cfit_${i}`}>
                                                <th className="py-1 px-2 w-28 text-center border border-slate-500">{c.cfit?.test_name}</th>
                                            </React.Fragment>
                                        )
                                    })}
                                    <th className="py-1 px-2 w-28 text-center border border-slate-500">Total Score</th>
                                    <th className="py-1 px-2 w-28 border bg-red-600 border-red-500 text-center">IQ</th>
                                </tr>
                            </thead>
                            <tbody>
                                {cfit_score.map((cs, i) => {
                                    return (
                                        <tr key={`cfit_score_${i}`}>
                                            <td className="py-1 px-2 border text-center md:sticky md:left-0 bg-blue-50">{i + 1}</td>
                                            <td className="py-1 px-2 border text-left md:sticky md:left-[32px] bg-blue-50">{cs.participant.full_name}</td>
                                            <td className="py-1 px-2 border text-center md:sticky md:left-[288px] bg-white">{cs.age}</td>
                                            <td className="py-1 px-2 border text-center md:sticky md:left-[344px] bg-white">{cs.participant.gender}</td>
                                            {cfit.map((c, j) => {
                                                const current_score = cs.participant_score.find(cps => cps.cfit_id === c.cfit_id)
                                                return (
                                                    <td key={`current_score_${j}`} className="py-1 px-2 border text-center">
                                                        <div>{current_score?.score ?? "-"}</div>
                                                    </td>
                                                )
                                            })}
                                            <td className="py-1 px-2 border text-center font-semibold">{cs.total_score}</td>
                                            <td className="py-1 px-2 border font-bold text-center border-red-100 bg-red-50 text-red-700">{cs.iq ?? "-"}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ModalCfitScore;