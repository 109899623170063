import React, { useState } from "react";

// Contexts
import PPAssessmentContext from "./PPAssessmentContext";

// Controllers
import PsikotestParticipant from "../../controller/psikotest/psikotest_participant";

// Helpers
import errorHandler from "../../helper/psikotest/errorHandler";

function AssessmentProvider({ children }: { children: React.ReactNode }) {
  const [errorValidate, setErrorValidate] = useState<string>("");
  const [assessment, setAssessment] = useState<TPSAssessment>({
    id: 0,
    assessment_title: "",
    company_id: 0,
    date: "",
    start_time: "",
    pic_id: null,
    psikolog_id: null,
    link_zoom: "",
    wa_number: "",
    status: "",
    company: {
      id: 0,
      company_logo: "",
      company_name: ""
    },
    psikologs: []
  });
  const [assessment_participant, setAssessmentParticipant] = useState<TPSAssessmentParticipant>({
    id: 0,
    assessment_id: 0,
    full_name: "",
    email: "",
    access_key: "",
    date_of_birth: null,
    last_education: null,
    gender: null,
    photo: null,
    manual: null,
  });
  const [timeRemaining, setTimeRemaining] = useState<number | null>(null);

  const validateAssessmentParticipant = async (accessKey: string) => {
    try {
      if (accessKey === "" || accessKey === null) {
        throw new Error("access key not found");
      }
      // Get assessment
      const ppc = new PsikotestParticipant();
      const assessment = await ppc.getAssessment(accessKey);
      setAssessment(assessment.data.row);
      // Get participant
      const participant = await ppc.getAssessmentParticipant(accessKey);
      setAssessmentParticipant(participant.data.row);
      // save key to local storage
      sessionStorage.setItem("accessKey", accessKey);
      setErrorValidate("");
    } catch (error) {
      const errMessage = errorHandler(error);
      setErrorValidate(errMessage);
    }
  };

  const updateAssessmentParticipant = async (
    assessment: TPSAssessment,
    assessment_participant: TPSAssessmentParticipant
  ) => {
    setAssessment(assessment);
    setAssessmentParticipant(assessment_participant);
  };

  const value = {
    errorValidate,
    assessment,
    assessment_participant,
    timeRemaining,
    setTimeRemaining,
    validateAssessmentParticipant,
    updateAssessmentParticipant,
  };

  return <PPAssessmentContext.Provider value={value}>{children}</PPAssessmentContext.Provider>;
}

export default AssessmentProvider;
