import { useContext, useEffect } from "react";

// Context
import PPAssessmentContext from "../../context/psikotest/PPAssessmentContext";

// Helper
import logRender from "../../helper/psikotest/logRender";

// Page Component
import PsikotestParticipantForm from "./PsikotestParticipantForm";

function PsikotestParticipantLogin() {
  // Context
  const { errorValidate, assessment, assessment_participant } = useContext(PPAssessmentContext);

  useEffect(() => {
    logRender({ type: "page", name: "psikotest_participant/Login" });
  }, []);

  return (
    <div className="w-screen h-screen flex justify-center bg-cyan-50">
      {assessment_participant.id !== 0 &&
        <>
          {errorValidate === "" ? (
            <div className="w-screen min-h-screen flex justify-center">
              <div className="hidden md:flex w-full lg:w-6/12 xl:w-5/12 h-screen bg-white shadow-none lg:shadow-md px-10 flex-col items-center justify-center">
                <img
                  className="h-20"
                  alt="company logo"
                  src={`${process.env.REACT_APP_URL}/upload/psikotest_participant/get/${assessment.company?.company_logo}`}
                />
                <h2 className="font-bold text-xl py-5">{assessment.company?.company_name}</h2>
                <h5 className="font-semibold text-lg pb-5">{assessment.assessment_title}</h5>
                <p className="text-lg text-center mx-8 mb-8">
                  Selamat datang <b>{assessment_participant.full_name}</b>.
                </p>
                <p className="text-lg text-center mx-8 mb-8">
                  Terima kasih telah bersedia meluangkan waktu anda untuk psikotest ini, sebelumnya mohon isi identitas anda.
                </p>
              </div>
              <div className="w-full lg:w-6/12 xl:w-7/12 h-screen bg-white border-l">
                <PsikotestParticipantForm assessment={assessment} assessment_participant={assessment_participant} />
              </div>
            </div>
          ) : (
            <div className="w-full lg:w-6/12 xl:w-5/12 h-full bg-white shadow-none lg:shadow-md px-10 flex flex-col items-center justify-center">
              <div className="font-semibold">
                <div>{errorValidate}</div>
              </div>
            </div>
          )}
        </>
      }
    </div>
  );
}

export default PsikotestParticipantLogin;
