import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useCallback, useContext, useEffect, useState } from "react";
import Select from "react-select";
import Swal from "sweetalert2";

// Icons
import { PlusIcon, ArrowDownTrayIcon } from "@heroicons/react/24/outline";

// Context
import SnackbarContext from "../../../context/assessment/SnackbarContext";
import LoaderContext from "../../../context/assessment/LoaderContext";

// Controller
import Questionary from "../../../controller/assessment/assessment_system/questionary";
import IntrayEmail from "../../../controller/assessment/assessment_system/intray_email";

// Helper
import errorHandler from "../../../helper/assessment/errorHandler";

// Type
type FormValues = {
  email: TQuestionaryIntrayEmail[];
};

// Variables
const maxEmail = 50;
const schema = yup.object().shape({
  email: yup.array().of(
    yup.object().shape({
      id: yup.number().transform((currValue, oldValue) => {
        return oldValue === "" ? undefined : currValue;
      }),
      questionary_id: yup.number().transform((currValue, oldValue) => {
        return oldValue === "" ? undefined : currValue;
      }),
      intray_simulation_email_id: yup.number().transform((currValue, oldValue) => {
        return oldValue === "" ? undefined : currValue;
      }),
    })
  ),
});

function FormIntrayEmail(props: { doc: TQuestionary }) {
  // context
  const { setMessage } = useContext(LoaderContext);
  const { setNotif } = useContext(SnackbarContext);
  // state
  const [editing, setEditing] = useState<boolean>(false);
  const [emailOptions, setEmailOptions] = useState<TSelect[]>([]);
  // react hook form
  const { control, handleSubmit, setValue, reset, watch } = useForm<FormValues>({
    defaultValues: { email: [] },
    mode: "onBlur",
    resolver: yupResolver(schema),
  });
  const { fields, append, remove } = useFieldArray({
    name: "email",
    control,
    keyName: "ids",
  });
  const watchFieldArray = watch("email");
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const getDoc = useCallback(
    async function getDoc() {
      try {
        // fetch cc list
        setMessage("Fetch All Intray Email");
        const iec = new IntrayEmail();
        const resIntrayEmailList = await iec.list();
        const intrayEmailSelect: TSelect[] = resIntrayEmailList.data.list.map((v: TIntrayEmail) => {
          return { id: v.id, value: v.id, label: v.subject };
        });
        setEmailOptions(intrayEmailSelect);
        // fetch current questionary email
        const qc = new Questionary();
        const resQuestionaryIntrayEmailList = await qc.intrayEmailList(props.doc.id.toString());
        // setValue not work in here
        if (resQuestionaryIntrayEmailList.data.list.length > 0) {
          reset({ email: resQuestionaryIntrayEmailList.data.list });
        }
        setMessage("");
      } catch (error) {
        setMessage("");
        const errorMessage = errorHandler(error);
        setNotif({ type: "error", message: errorMessage });
      }
    },
    [setNotif, setMessage, reset, props.doc.id]
  );

  useEffect(() => {
    getDoc();
  }, [getDoc]);

  const onSubmit = (data: FormValues) => {
    setEditing(false);
    handleChange(data.email);
  };

  const handleChange = (intray_emails: TQuestionaryIntrayEmail[]) => {
    intray_emails.forEach(async (intray_email, i) => {
      const emailPrev = fields[i];
      const emailCurr = intray_email;
      // make sure changed occured
      const isChanged = emailPrev?.intray_simulation_email_id !== emailCurr?.intray_simulation_email_id;
      if (isChanged) {
        // saving in update with empty questionary
        try {
          setMessage("Save Intray Email");
          const qc = new Questionary();
          // check if have id
          if (intray_email.id) {
            await qc.intrayEmaiUpdate(intray_email.id.toString(), intray_email);
          } else {
            // create new intray email
            const resCreate = await qc.intrayEmailAdd(props.doc.id.toString(), intray_email);
            let newEmails = [...intray_emails];
            newEmails[i] = {
              ...newEmails[i],
              id: resCreate.data.saved_id,
            };
            setValue("email", newEmails);
          }
          setMessage("");
        } catch (error) {
          setMessage("");
          const errorMessage = errorHandler(error);
          setNotif({ type: "error", message: errorMessage });
        }
      }
    });
  };

  const handleRemove = async (i: number) => {
    try {
      const confirm = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (confirm.isConfirmed) {
        // to reset button save if remove new item without submit
        setEditing(false);
        // start remove
        setMessage("Remove Intray Email");
        const qc = new Questionary();
        if (fields[i].id) {
          const removeIntrayEmailQuestionaryId = fields[i].id.toString();
          if (removeIntrayEmailQuestionaryId) {
            await qc.intrayEmaiDelete(removeIntrayEmailQuestionaryId);
          }
        }
        remove(i);
        setMessage("");
      }
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  return (
    <div>
      {fields.length < maxEmail && (
        <div className="flex flex-col md:flex-row w-full md:w-full mb-2 space-y-0.5 md:space-y-0 md:space-x-0.5">
          {/* Add Email */}
          {!editing && (
            <button
              type="button"
              onClick={() =>
                append({
                  id: 0,
                  questionary_id: props.doc.id,
                  intray_simulation_email_id: 0,
                })
              }
              className="block w-auto px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded font-semibold text-sm"
            >
              <div className="flex">
                <PlusIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
                Intray Email
              </div>
            </button>
          )}
          {/* Save */}
          {editing && (
            <button
              type="button"
              className="block w-auto px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded font-semibold text-sm"
            >
              <div className="flex">
                <ArrowDownTrayIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
                Save
              </div>
            </button>
          )}
        </div>
      )}
      <div className="w-full mb-1 hidden md:flex">
        <div className="w-full md:w-1/12 px-3 py-2 border rounded bg-slate-600 text-white text-center">No</div>
        <div className="w-full md:w-10/12 px-3 py-2 border rounded bg-slate-600 text-white">Email (Subject)</div>
        <div className="w-full md:w-1/12 px-3 py-2 border rounded bg-red-500 text-white text-center">Remove</div>
      </div>
      <form onBlur={() => handleSubmit(onSubmit)()}>
        {controlledFields.map((fieldEmail, i) => {
          return (
            <div key={`participant_${i}`} className="w-full mb-0.5 md:flex">
              <div className="w-full md:w-1/12 px-3 py-2 border rounded bg-slate-600 text-white md:text-center">
                {i + 1}
              </div>
              <div className={"w-full md:w-10/12 border-x border-white"}>
                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => {
                    return (
                      <Select
                        placeholder="Select Email"
                        ref={ref}
                        onBlur={onBlur}
                        onChange={(newValue) => {
                          if (newValue?.value !== undefined) {
                            onChange(newValue.value);
                          }
                        }}
                        value={emailOptions.find((option) => {
                          return option.value !== undefined && parseInt(option.value.toString()) === value;
                        })}
                        options={emailOptions}
                      />
                    );
                  }}
                  name={`email.${i}.intray_simulation_email_id`}
                  defaultValue={fieldEmail.intray_simulation_email_id}
                  control={control}
                />
              </div>
              <button
                type="button"
                className="w-full md:w-1/12 px-3 py-2 border rounded bg-red-500 hover:bg-red-700 text-white text-center cursor-pointer"
                onClick={() => handleRemove(i)}
              >
                Remove
              </button>
            </div>
          );
        })}
      </form>
    </div>
  );
}

export default FormIntrayEmail;
