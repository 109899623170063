import req from "../../../services/assessment/request";

class Assessor {
  async list() {
    try {
      const res = await req.get("/api/assessment_system/assessor/");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async get(id: string) {
    try {
      const res = await req.get("/api/assessment_system/assessor/" + id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async create(assessor: TAssessorCreateUpdate) {
    try {
      const res = await req.post("/api/assessment_system/assessor/", assessor);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async update(id: string, assessor: TAssessorCreateUpdate) {
    try {
      const res = await req.put("/api/assessment_system/assessor/" + id, assessor);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async delete(userId: string) {
    try {
      const res = await req.delete("/api/assessment_system/assessor/" + userId);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default Assessor;
