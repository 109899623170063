import { useCallback, useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";

// Icons
import { XMarkIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon, ClockIcon, ExclamationCircleIcon } from "@heroicons/react/24/solid";

// Contexts
import LoaderContext from "../../../../context/psikotest/LoaderContext";
import SnackbarContext from "../../../../context/psikotest/SnackbarContext";

// Helpers
import errorHandler from "../../../../helper/psikotest/errorHandler";

// Controller
import AssessmentCfitSubtest from "../../../../controller/psikotest/psikotest_system/assessment_cfit_subtest"
import AssessmentParticipantCfitSession from "../../../../controller/psikotest/psikotest_system/assessment_participant_cfit_session";

function ModalCfitSubtestSession(
    props: {
        data?: TPSAssessmentCfitSubtest,
        assessment_id: number,
        handleClose: () => void,
        assessment_cfit_subtest: TPSAssessmentCfitSubtest[]
    }) {
    const { setMessage } = useContext(LoaderContext);
    const { setNotif } = useContext(SnackbarContext);
    // state
    const [cfit_subtest_session, setCfitSubtestSession] = useState<TPSAssessmentCfitSubtestParticipantSession[]>([])

    const getDoc = useCallback(
        async function getDoc() {
            try {
                // fetch cfit subtest
                setMessage("Fetch Subtest Participant Session");
                if (props.data) {
                    const cacs = new AssessmentCfitSubtest()
                    const res = await cacs.participant_session(props.data)
                    setCfitSubtestSession(res.data.list)
                }
                setMessage("");
            } catch (error) {
                setMessage("");
                const errorMessage = errorHandler(error);
                setNotif({ type: "error", message: errorMessage });
            }
        },
        [setNotif, setMessage, props.data]
    );

    useEffect(() => {
        getDoc();
    }, [getDoc]);

    const resetCfitSession = async (acsps: TPSAssessmentCfitSubtestParticipantSession) => {
        try {
            const confirm = await Swal.fire({
                title: "Are you sure?",
                text: `${acsps.participant.full_name} session for ${props.data?.cfit?.test_name} will be reset!`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, reset it!",
            });
            if (confirm.isConfirmed) {
                // reset ist subtest
                setMessage("Reset Participant CFIT Session");
                const capcs = new AssessmentParticipantCfitSession()
                const res = await capcs.reset(acsps.cfit_session.id)
                // re-fetch
                getDoc()
                setNotif({ type: "success", message: res.data.message })
                setMessage("");
            }
        } catch (error) {
            setMessage("");
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }

    return <div>
        <div className="fixed z-20 top-0 left-0 w-screen h-screen bg-black opacity-20"></div>
        <div className="fixed z-30 top-0 left-0 w-screen h-screen bg-transparent flex items-center justify-center">
            <div className="flex flex-col bg-white w-screen md:w-[90%] h-[90%] md:rounded">
                <div className="p-4 bg-green-800 md:rounded-t text-white flex justify-between">
                    <h3>{props.data?.cfit?.test_name} - Subtest Session </h3>
                    <XMarkIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent cursor-pointer" aria-hidden="true" onClick={() => props.handleClose()} />
                </div>
                <div className="px-2 py-10 overflow-auto">
                    <table className="w-full border">
                        <thead className="bg-slate-600 text-white">
                            <tr>
                                <th className="py-1 px-2 w-8 border border-slate-500">No</th>
                                <th className="py-1 px-2 text-left border border-slate-500">Participant</th>
                                <th className="py-1 px-2 w-20 border border-slate-500">Status</th>
                                <th className="py-1 px-2 w-20 border border-slate-500">Reset</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cfit_subtest_session.map((css, i) => {
                                return (
                                    <tr key={`cfit_subtest_session_${i}`}>
                                        <td className="py-1 px-2 text-center border">{i + 1}</td>
                                        <td className="py-1 px-2 border">{css.participant.full_name}</td>
                                        <td className="py-1 px-2 border whitespace-nowrap">
                                            {css.cfit_session.start_time === null && css.cfit_session.close_by_user === null
                                                ? <div className="flex text-red-500"><ExclamationCircleIcon className="w-5 mr-1" /> Not Open Yet</div>
                                                : css.remaining_time > 0
                                                    ? <div className="flex text-black-500"><ClockIcon className="w-5 mr-1" /> On Progress</div>
                                                    : <div className="flex text-green-500"><CheckCircleIcon className="w-5 mr-1" /> Completed</div>
                                            }
                                        </td>
                                        <td className="py-1 px-2 text-center border">
                                            <button
                                                type="button"
                                                className="px-2 rounded bg-red-500 text-white disabled:bg-gray-300 disabled:cursor-not-allowed"
                                                onClick={() => resetCfitSession(css)}
                                                disabled={css.cfit_session.start_time === null && css.cfit_session.close_by_user === null}
                                            >
                                                Reset
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
}

export default ModalCfitSubtestSession;