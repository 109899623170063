import req from "../../../services/psikotest/request";

class AssessmentParticipant {
  async create(data: TPSAssessmentParticipant) {
    try {
      const res = await req.post("/api/psikotest_system/assessment_participant/", data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async update(data: TPSAssessmentParticipant) {
    try {
      const res = await req.put("/api/psikotest_system/assessment_participant/" + data.id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async delete(data: TPSAssessmentParticipant) {
    try {
      const res = await req.delete("/api/psikotest_system/assessment_participant/", { data });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default AssessmentParticipant;
