import req from "../../../services/psikotest/request";

class MmpiQuestionary {
  async list() {
    try {
      const res = await req.get("/api/psikotest_system/mmpi_questionary/");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async create(data: TPSMmpiQuestionary) {
    try {
      const res = await req.post("/api/psikotest_system/mmpi_questionary/", data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async update(id: number, data: TPSMmpiQuestionary) {
    try {
      const res = await req.put("/api/psikotest_system/mmpi_questionary/" + id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async delete(id: number) {
    try {
      const res = await req.delete("/api/psikotest_system/mmpi_questionary/" + id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default MmpiQuestionary;
