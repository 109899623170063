
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import Swal from "sweetalert2";

// Icons
import { PlusIcon } from "@heroicons/react/24/outline";

// Context
import SnackbarContext from "../../../context/psikotest/SnackbarContext";
import LoaderContext from "../../../context/psikotest/LoaderContext";

// Helper
import errorHandler from "../../../helper/psikotest/errorHandler";

// Controller
import Ist from "../../../controller/psikotest/psikotest_system/ist";
import IstQuestionaryImage from "../../../controller/psikotest/psikotest_system/ist_questionary_image";

// Modal
import FormQuestionaryImage from "./modal/FormQuestionaryImage";

const maxQuestionary = 20;

function ListQuestionary(props: { isCreate: boolean; ist: TPSIst; editable: boolean }) {
    const { setMessage } = useContext(LoaderContext);
    const { setNotif } = useContext(SnackbarContext);
    // state list questionary
    const [questionaryImage, setQuestionaryImage] = useState<TPSIstQuestionaryImage[]>([])
    // state modal
    const [isOpenFormQuestionaryImage, setIsOpenFormQuestionaryImage] = useState<boolean>(false);
    // state other
    const [selected_ist_questionary_image, setSelectedIstQuestionaryImage] = useState<TPSIstQuestionaryImage | undefined>(undefined);

    const getDoc = useCallback(
        async function getDoc() {
            try {
                if (!props.isCreate) {
                    if (props.ist.id) {
                        // fetch ist_qestionary_image list
                        setMessage("Fetch IST Questionary Image");
                        const ic = new Ist();
                        const res = await ic.questionary_image(props.ist.id);
                        const list: TPSIstQuestionaryImage[] = res.data.list;
                        setQuestionaryImage(list);
                    }
                }
                setMessage("");
            } catch (error) {
                setMessage("");
                const errorMessage = errorHandler(error);
                setNotif({ type: "error", message: errorMessage });
            }
        },
        [setNotif, setMessage, props.ist.id, props.isCreate]
    );

    // init effect get all questionary
    useEffect(() => {
        getDoc();
    }, [getDoc]);

    // when selected_ist_questionary_image changes and not undefined open Cfit Questionary form to update
    useEffect(() => {
        if (selected_ist_questionary_image !== undefined) {
            setIsOpenFormQuestionaryImage(true)
        }
    }, [selected_ist_questionary_image]);

    // when isOpenFormQuestionaryImage changes and false reset setSelectedIstQuestionaryImage
    useEffect(() => {
        if (!isOpenFormQuestionaryImage) {
            setSelectedIstQuestionaryImage(undefined)
            // re-fetch data
            getDoc();
        }
    }, [isOpenFormQuestionaryImage, getDoc]);

    // handle delete
    const confirmDelete = useCallback(
        async (ist_questionary_image: TPSIstQuestionaryImage) => {
            try {
                const confirm = await Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                });
                if (confirm.isConfirmed) {
                    const iqic = new IstQuestionaryImage();
                    await iqic.delete(ist_questionary_image);
                    setNotif({ type: "success", message: "ist questionary image deleted" });
                    // re-fetch data
                    getDoc();
                }
            } catch (error) {
                const errorMessage = errorHandler(error);
                setNotif({ type: "error", message: errorMessage });
            }
        },
        [setNotif, getDoc]
    );

    return (
        <div className="pb-4">
            {/* Questionary Form */}
            <Transition
                as={Fragment}
                show={isOpenFormQuestionaryImage}
                enter="ease-out duration-50"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-50"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div>
                    <FormQuestionaryImage data={selected_ist_questionary_image} ist={props.ist} handleClose={() => setIsOpenFormQuestionaryImage(false)} />
                </div>
            </Transition>
            {questionaryImage.length < maxQuestionary && props.editable && (
                <div className="w-full md:w-full mb-2">
                    <button
                        type="button"
                        onClick={() => { setIsOpenFormQuestionaryImage(true) }}
                        className="block w-auto px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded font-semibold text-sm"
                    >
                        <div className="flex">
                            <PlusIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
                            Questionary Image
                        </div>
                    </button>
                </div>
            )}
            <div className="w-full flex flex-wrap flex-row justify-evenly">
                {questionaryImage.map((q, i) => {
                    return (
                        <div key={`questionary_${i}`}
                            className="w-full lg:w-1/3 flex flex-col justify-between m-2 border"
                        >
                            <div className="relative flex flex-grow items-center">
                                <div className="absolute top-1 right-1 bg-green-600 py-1 px-2 rounded text-white opacity-90">
                                    Question {i + 1}
                                </div>
                                <img className="w-full p-2" src={`${process.env.REACT_APP_URL}/upload/psikotest_system/get_ist_questionary_image/${q.ist_questionary_image}`} alt={q.ist_questionary_image ?? "-"}></img>
                            </div>
                            <div className="px-2 py-4 text-center bg-slate-50 border-t">
                                <span className="block text-xs mb-1">Action</span>
                                <div className="space-x-1">
                                    <button type="button" className="px-2 py-1 rounded bg-blue-500 text-white" onClick={() => setSelectedIstQuestionaryImage(q)}>
                                        Edit
                                    </button>
                                    <button type="button" className="px-2 py-1 rounded bg-red-500 text-white" onClick={() => confirmDelete(q)}>
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default ListQuestionary;
