import axios from "axios";
import { useForm, SubmitHandler, FieldError } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useCallback, useState, useContext } from "react";
import { DocumentTextIcon } from "@heroicons/react/24/outline";

import FileUploadController from "../../../controller/assessment/file_upload";

import LoaderContext from "../../../context/assessment/LoaderContext";

type TFileUpload = {
  file: FileList;
};

const schema = (filter_type: string) => {
  return yup.object().shape({
    file: yup
      .mixed()
      .test("fileFormat", "Required & PDF only", (value) => {
        return value.length > 0 && value && value[0] && filter_type.includes(value[0].type);
      })
      .test("fileSize", "File too large", (value) => {
        return value.length > 0 && value && value[0] && value[0].size <= 10000000; // 10MB
      }),
  });
};

function FileUpload(props: {
  type: string;
  editable: boolean;
  document_id: number;
  filepath: string;
  error_field: FieldError | undefined;
  setDocumentId: (id: number) => void;
  setFilePath: (filepath: string) => void;
}) {
  const { setMessage } = useContext(LoaderContext);
  const [upPercentage, setUpPercentage] = useState<number>(100);
  // additional for file label & filter
  let label: string;
  let additional_label: string;
  let filter_type: string;
  if (props.type === "questionary") {
    label = "FILE";
    additional_label = "(PDF, MAX 10MB)";
    filter_type = "application/pdf";
  } else if (props.type === "company") {
    label = "COMPANY LOGO";
    additional_label = "(PNG | JPEG, MAX 10MB)";
    filter_type = "image/png, image/jpeg";
  } else if (props.type === "setting") {
    label = "DICTIONARY";
    additional_label = "(PDF, MAX 10MB)";
    filter_type = "application/pdf";
  } else {
    label = "FILE";
    additional_label = "";
    filter_type = "";
  }
  // react hook form
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<TFileUpload>({ resolver: yupResolver(schema(filter_type)) });

  // watch field file
  watch(["file"]);

  const onSubmit: SubmitHandler<TFileUpload> = useCallback(
    async (data) => {
      const fuc = new FileUploadController();
      try {
        setMessage("Upload File");
        let config = {
          onUploadProgress: function (progressEvent: any) {
            setUpPercentage(Math.round((progressEvent.loaded * 100) / progressEvent.total));
          },
        };
        if (props.document_id === 0) {
          const res = await fuc.create({ file: data.file[0], type: props.type }, config);
          props.setDocumentId(res.data.result.saved_id);
          props.setFilePath(res.data.result.filepath);
        } else {
          const res = await fuc.update({ file: data.file[0], type: props.type, id: props.document_id }, config);
          props.setFilePath(res.data.filepath);
        }
        setMessage("");
      } catch (error) {
        setMessage("");
        if (axios.isAxiosError(error) && error.response) {
          console.log(error.response.data.message || error.response.data || "Unknown Error");
        } else {
          console.log(error);
        }
      }
    },
    [props, setMessage]
  );

  useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  }, [watch, handleSubmit, onSubmit]);

  return (
    <form>
      <div className="flex flex-row items-center -mx-3 px-3">
        {filter_type.includes("image") && props.document_id > 0 && (
          <div className="p-1 mr-2 h-14 border rounded">
            <img className="h-full w-full" alt=""
              src={`${process.env.REACT_APP_URL}/upload/assessment_system/get/${props.filepath}`}
            />
          </div>
        )}
        {!filter_type.includes("image") && props.document_id > 0 && (
          <div className="flex items-center p-1 mr-2 h-14 border rounded bg-blue-400 hover:bg-blue-600">
            <a
              target="_blank"
              rel="noreferrer"
              href={`${process.env.REACT_APP_URL}/upload/assessment_system/get/${props.filepath}`}
              className="text-white flex flex-col px-2"
            >
              <DocumentTextIcon className="h-5" />
              Download
            </a>
          </div>
        )}
        <div className="w-full">
          <label className="block tracking-wide uppercase text-gray-700 text-xs font-bold mb-2">
            {label}{" "}
            {props.editable && (
              <i className="font-semibold">
                {additional_label} {upPercentage < 100 && <b className="text-red-500">Uploading : {upPercentage}%</b>}
              </i>
            )}
          </label>
          <div className="relative">
            <div
              className={
                (errors.file || props?.error_field ? "border-red-500" : "border-blue-200") +
                " h-9 first-letter:appearance-none w-full bg-gray-200 text-gray-700 py-1 px-3 border border-dashed rounded overflow-hidden " +
                (!props.editable ? "text-gray-500" : "")
              }
            >
              <i className="font-semibold text-sm">{props.filepath || "Select File"}</i>
            </div>
            <input
              id="file"
              type="file"
              className="absolute top-0 h-full w-full opacity-0"
              disabled={!props.editable}
              accept={filter_type}
              {...register("file")}
            />
          </div>
          {(errors.file || props?.error_field) && (
            <p className="text-red-500 text-xs italic">{errors.file?.message || props?.error_field?.message}</p>
          )}
        </div>
      </div>
    </form>
  );
}

export default FileUpload;
