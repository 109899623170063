import { useContext, useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";

// Component
import BreadCrumbNavigation from "../../../components/assessment/assessment_system/BreadCrumbNavigation";

// Controller
import IndexPage from "../../../controller/assessment/assessment_system/index_page";

// Context
import LoaderContext from "../../../context/assessment/LoaderContext";
import SnackbarContext from "../../../context/assessment/SnackbarContext";

// Helper
import logRender from "../../../helper/assessment/logRender";
import errorHandler from "../../../helper/assessment/errorHandler";

// validation schema
const schema = yup.object().shape({
  old_password: yup.string().label("Old Password").required(),
  password: yup.string().label("New Password").min(5).max(255).required(),
  confirm_password: yup
    .string()
    .label("Confirm Password")
    .min(5)
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    })
    .required(),
});

function ChangePassword() {
  const navigate = useNavigate();
  // call message setter
  const { setMessage } = useContext(LoaderContext);
  // call notif setter
  const { setNotif } = useContext(SnackbarContext);
  // init useForm
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TUpdatePassword>({ resolver: yupResolver(schema) });

  useEffect(() => {
    logRender({ type: "page", name: "/ChangePassword" });
  }, []);

  const onSubmit: SubmitHandler<TUpdatePassword> = async (data) => {
    try {
      setMessage("Save Level Assessment");
      const ipc = new IndexPage();
      const res = await ipc.update_password(data);
      setNotif({ type: "success", message: res.data.message });
      setMessage("");
      navigate(`../`, { replace: true });
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  return (
    <div className="w-full flex flex-col space-y-0.5">
      <BreadCrumbNavigation />
      <div className="w-full flex flex-col space-y-0.5">
        <div className="flex">
          <button
            onClick={handleSubmit(onSubmit)}
            className="mx-2 block w-auto px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded font-semibold text-sm"
          >
            <div className="flex">
              <ArrowDownTrayIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
              Save
            </div>
          </button>
        </div>
        <div className="pt-4">
          <form className="w-full">
            <div className="flex flex-wrap -mx-3">
              <div className="w-full md:w-full px-3 mb-6">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Old Password
                </label>
                <input
                  type="password"
                  className={
                    (errors?.old_password ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                    " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                  }
                  {...register("old_password")}
                />
                {errors.old_password && <p className="text-red-500 text-xs italic">{errors.old_password.message}</p>}
              </div>
              <div className="w-full md:w-full px-3 mb-6">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  New Password
                </label>
                <input
                  type="password"
                  className={
                    (errors?.password ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                    " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                  }
                  {...register("password")}
                />
                {errors.password && <p className="text-red-500 text-xs italic">{errors.password.message}</p>}
              </div>
              <div className="w-full md:w-full px-3 mb-6">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Confirm Password
                </label>
                <input
                  type="password"
                  className={
                    (errors?.confirm_password ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                    " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                  }
                  {...register("confirm_password")}
                />
                {errors.confirm_password && (
                  <p className="text-red-500 text-xs italic">{errors.confirm_password.message}</p>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
