import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import Swal from "sweetalert2";

// Icons
import { CheckIcon, ClipboardDocumentListIcon, EnvelopeIcon, UserPlusIcon, XMarkIcon } from "@heroicons/react/24/outline";

// Contexts
import PSAuthContext from "../../../context/psikotest/AuthContext";
import LoaderContext from "../../../context/psikotest/LoaderContext";
import SnackbarContext from "../../../context/psikotest/SnackbarContext";

// Helpers
import errorHandler from "../../../helper/psikotest/errorHandler";

// Controller
import Assessment from "../../../controller/psikotest/psikotest_system/assessment";
import AssessmentParticipant from "../../../controller/psikotest/psikotest_system/assessment_participant";

// Modals
import FormParticipant from "./modal/FormParticipant";
import ModalParticipantReport from "./modal/ModalParticipantReport";
import FormImportParticipant from "./modal/FormImportParticipant";

function ListParticipant(props: { assessment: TPSAssessment; isPic: boolean; }) {
    const { user } = useContext(PSAuthContext);
    const { setMessage } = useContext(LoaderContext);
    const { setNotif } = useContext(SnackbarContext);
    // is admin
    const isAdmin = user.roles === "1";
    // helper state
    const [first_load, setFirstLoad] = useState<boolean>(true)
    // list assessment participant
    const [assessment_participant, setAssessmentParticipant] = useState<TPSAssessmentParticipant[]>([])
    // state modal
    const [isOpenFormParticipant, setIsOpenFormParticipant] = useState<boolean>(false);
    const [isOpenParticipantReport, setIsOpenParticipantReport] = useState<boolean>(false);
    const [isOpenFormImportParticipant, setIsOpenFormImportParticipant] = useState<boolean>(false);
    // state other
    const [selectedParticipant, setSelectedParticipant] = useState<TPSAssessmentParticipant | undefined>(undefined);
    const [selectedParticipantReport, setSelectedParticipantReport] = useState<TPSAssessmentParticipant | undefined>(undefined);
    const [isSelectableSendInvitation, setIsSelectableSendInvitation] = useState<boolean>(false);
    const [selectedSendInvitationParticipant, setSelectedSendInvitationParticipant] = useState<TPSAssessmentParticipant[]>([]);

    // get initial data
    const getAssessmentParticipant = useCallback(
        async function getAssessmentParticipant() {
            try {
                // fetch assessment cfit subtest
                setMessage("Fetch Assessment Participant")
                const ca = new Assessment()
                const res = await ca.participant(props.assessment.id)
                const list: TPSAssessmentParticipant[] = res.data.list
                setAssessmentParticipant(list)
                setMessage("");
            } catch (error) {
                setMessage("");
                const errorMessage = errorHandler(error);
                setNotif({ type: "error", message: errorMessage });
            }
        },
        [setNotif, setMessage, props.assessment.id]
    );

    // use effect initial data
    useEffect(() => {
        getAssessmentParticipant();
    }, [getAssessmentParticipant]);

    // when isOpenFormParticipant changes and false reset setSelectedParticipant
    useEffect(() => {
        if (!isOpenFormParticipant) {
            setSelectedParticipant(undefined)
            // first_load make sure not load when component loaded
            if (first_load) {
                // re-fetch data
                getAssessmentParticipant();
            } else {
                // set first load to false
                setFirstLoad(false)
            }
        }
    }, [isOpenFormParticipant, getAssessmentParticipant, first_load, setFirstLoad]);

    // when selectedParticipant changes and not undefined open Participant form to update
    useEffect(() => {
        if (selectedParticipant !== undefined) {
            setIsOpenFormParticipant(true)
        }
    }, [selectedParticipant]);

    // when isOpenParticipantReport changes and false reset setSelectedParticipantReport
    useEffect(() => {
        if (!isOpenParticipantReport) {
            setSelectedParticipantReport(undefined)
            // reset from file name after print
            document.title = "Psikotest System - Assessment"
        }
    }, [isOpenParticipantReport]);

    // when selectedParticipantReport changes and not undefined open Participant Report
    useEffect(() => {
        if (selectedParticipantReport !== undefined) {
            setIsOpenParticipantReport(true)
            // set for file name when print
            document.title = `Psikotest - Participant Report - ${selectedParticipantReport.full_name}`
        }
    }, [selectedParticipantReport]);

    // handleDeleteParticipant
    const handleDeleteParticipant = async (data: TPSAssessmentParticipant) => {
        try {
            const confirm = await Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            });
            if (confirm.isConfirmed) {
                const cap = new AssessmentParticipant();
                const res = await cap.delete(data);
                setNotif({ type: "success", message: res.data.message });
                // re-fetch data
                getAssessmentParticipant();
            }
        } catch (error) {
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }

    // use effect isSelectableSendInvitation when false reset selected participant invitation
    useEffect(() => {
        if (isSelectableSendInvitation !== false) {
            setSelectedSendInvitationParticipant([]);
        }
    }, [isSelectableSendInvitation]);

    // handleSelectAllSendInvitation
    const handleSelectAllSendInvitation = (select_all: boolean) => {
        setSelectedSendInvitationParticipant(select_all ? assessment_participant : []);
    }

    // handleSelectedSendInvitation
    const handleSelectedSendInvitation = (participant: TPSAssessmentParticipant, is_selected: boolean) => {
        // copy to make sure not reference
        const copySelectedParticipant = [...selectedSendInvitationParticipant];
        if (is_selected) {
            const indexSelected = copySelectedParticipant.findIndex((v) => v.id === participant.id);
            copySelectedParticipant.splice(indexSelected, 1);
        } else {
            copySelectedParticipant.push(participant);
        }
        setSelectedSendInvitationParticipant(copySelectedParticipant);
    }

    // handleSendInvitation
    const handleSendInvitation = async () => {
        try {
            // start send invitation
            setMessage("Send Invitation");
            const ac = new Assessment();
            const submitData = { participant: selectedSendInvitationParticipant, assessment: props.assessment };
            await ac.send_invitation(submitData);
            setMessage("");
            // reset selectable participant
            setIsSelectableSendInvitation(false);
        } catch (error) {
            setMessage("");
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }

    // handleRefreshAfterImport
    const handleRefreshAfterImport = () => {
        // close import form modal
        setIsOpenFormImportParticipant(false)
        // refresh data participant
        getAssessmentParticipant()
    }

    return (
        <>
            {/* Import Participant Form */}
            <Transition
                as={Fragment}
                show={isOpenFormImportParticipant}
                enter="ease-out duration-50"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-50"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div>
                    <FormImportParticipant
                        assessment_id={props.assessment.id}
                        handleClose={() => setIsOpenFormImportParticipant(false)}
                        handleRefreshAfterImport={handleRefreshAfterImport}
                    />
                </div>
            </Transition>
            {/* Participant Form */}
            <Transition
                as={Fragment}
                show={isOpenFormParticipant}
                enter="ease-out duration-50"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-50"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div>
                    <FormParticipant
                        data={selectedParticipant}
                        assessment_id={props.assessment.id}
                        handleClose={() => setIsOpenFormParticipant(false)}
                    />
                </div>
            </Transition>
            {/* Report Form */}
            <Transition
                as={Fragment}
                show={isOpenParticipantReport}
                enter="ease-out duration-50"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-50"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div>
                    <ModalParticipantReport
                        data={selectedParticipantReport}
                        assessment_id={props.assessment.id}
                        handleClose={() => setIsOpenParticipantReport(false)}
                    />
                </div>
            </Transition>
            <div className="border-2 p-3 border-dashed border-t-0 border-gray-700 rounded-b">
                {(isAdmin || props.isPic) &&
                    <div className="flex">
                        {/** if isSelectableSendInvitation is true hide left group button */}
                        {isSelectableSendInvitation === false &&
                            <div className="flex space-x-1">
                                <button type="button"
                                    onClick={() => { setIsOpenFormImportParticipant(true) }}
                                    className="flex items-center bg-green-600 py-1 px-2 text-white rounded">
                                    <ClipboardDocumentListIcon className="w-4" />
                                    <span className="block pl-1">Import</span>
                                </button>
                                <button type="button"
                                    onClick={() => { setIsOpenFormParticipant(true) }}
                                    className="flex items-center bg-green-600 py-1 px-2 text-white rounded">
                                    <UserPlusIcon className="w-4" />
                                    <span className="block pl-1">Add Participant</span>
                                </button>
                            </div>
                        }
                        <div className="ml-auto">
                            {isSelectableSendInvitation ?
                                <div className="flex space-x-1">
                                    <button
                                        type="button"
                                        onClick={() => { setIsSelectableSendInvitation(false) }}
                                        className="flex items-center bg-gray-600 py-1 px-2 text-white rounded">
                                        <XMarkIcon className="w-4" />
                                        <span className="block pl-1">Cancel</span>
                                    </button>
                                    {selectedSendInvitationParticipant.length !== assessment_participant.length ?
                                        <button
                                            type="button"
                                            onClick={() => { handleSelectAllSendInvitation(true) }}
                                            className="flex items-center bg-blue-600 py-1 px-2 text-white rounded">
                                            <CheckIcon className="w-4" />
                                            <span className="block pl-1">Select All</span>
                                        </button>
                                        :
                                        <button
                                            type="button"
                                            onClick={() => { handleSelectAllSendInvitation(false) }}
                                            className="flex items-center bg-gray-800 py-1 px-2 text-white rounded">
                                            <XMarkIcon className="w-4" />
                                            <span className="block pl-1">Unselect</span>
                                        </button>
                                    }
                                    {selectedSendInvitationParticipant.length > 0 &&
                                        <button
                                            type="button"
                                            onClick={() => { handleSendInvitation() }}
                                            className="flex items-center bg-green-600 py-1 px-2 text-white rounded">
                                            <EnvelopeIcon className="w-4" />
                                            <span className="block pl-1">Send</span>
                                        </button>
                                    }
                                </div>
                                :
                                <button
                                    type="button"
                                    onClick={() => { setIsSelectableSendInvitation(true) }}
                                    className="flex items-center bg-blue-600 py-1 px-2 text-white rounded">
                                    <EnvelopeIcon className="w-4" />
                                    <span className="hidden md:block pl-1">Invitation</span>
                                </button>
                            }
                        </div>
                    </div>
                }
                <div className="pt-2 overflow-auto">
                    <table className="w-full border">
                        <thead className="bg-slate-600 text-white">
                            <tr>
                                {isSelectableSendInvitation &&
                                    <th className="py-1 px-2 w-8 border border-slate-500">Select</th>
                                }
                                <th className="py-1 px-2 w-8 border border-slate-500">No</th>
                                <th className="py-1 px-2 border border-slate-500">Full Name</th>
                                <th className="py-1 px-2 border border-slate-500">Email</th>
                                <th className="py-1 px-2 border border-slate-500">Date of Birth</th>
                                <th className="py-1 px-2 w-24 border border-slate-500">Report</th>
                                {/** Admin Only - Button Edit & Delete */}
                                {(isAdmin || props.isPic) &&
                                    <th className="py-1 px-2 w-20 border border-slate-500">Edit</th>
                                }
                                {(isAdmin) &&
                                    <th className="py-1 px-2 w-20 border border-slate-500">Delete</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {assessment_participant.map((v, i) => (
                                <tr key={`cfit_subtest_${i}`}>
                                    {isSelectableSendInvitation &&
                                        <td className="py-1 px-2 text-center border">
                                            <button
                                                type="button"
                                                className={`px-2 rounded text-white ${selectedSendInvitationParticipant.some((selected_participant) => selected_participant.id === v.id) ? "bg-green-500" : "bg-gray-500"}`}
                                                onClick={() => handleSelectedSendInvitation(v, selectedSendInvitationParticipant.some((selected_participant) => selected_participant.id === v.id))}>
                                                {selectedSendInvitationParticipant.some((selected_participant) => selected_participant.id === v.id) ?
                                                    <CheckIcon className="w-4" /> : <XMarkIcon className="w-4" />
                                                }
                                            </button>
                                        </td>
                                    }
                                    <td className="py-1 px-2 text-center border">{i + 1}</td>
                                    <td className="py-1 px-2 text-left border whitespace-nowrap">{v.full_name}</td>
                                    <td className="py-1 px-2 text-left border">{v.email}</td>
                                    <td className="py-1 px-2 text-center border">{v.date_of_birth}</td>
                                    <td className="py-1 px-2 text-center border">
                                        <button
                                            type="button"
                                            className="px-2 rounded bg-green-600 text-white"
                                            onClick={() => setSelectedParticipantReport(v)}>
                                            Report
                                        </button>
                                    </td>
                                    {/** Admin Only - Button Edit & Delete */}
                                    {(isAdmin || props.isPic) &&
                                        <td className="py-1 px-2 text-center border">
                                            <button
                                                type="button"
                                                className="px-2 rounded bg-blue-500 text-white"
                                                onClick={() => setSelectedParticipant(v)}>
                                                Edit
                                            </button>
                                        </td>
                                    }
                                    {(isAdmin) &&
                                        <td className="py-1 px-2 text-center border">
                                            <button
                                                type="button"
                                                className="px-2 rounded bg-red-500 text-white"
                                                onClick={() => handleDeleteParticipant(v)}>
                                                Delete
                                            </button>
                                        </td>
                                    }
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default ListParticipant;