import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState, useContext, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Disclosure, Switch, Transition } from "@headlessui/react";
import Select from "react-select";

// Icons
import { ArrowDownOnSquareIcon, ChevronUpIcon, TrashIcon } from "@heroicons/react/24/outline";

// Contexts
import LoaderContext from "../../../context/psikotest/LoaderContext";
import SnackbarContext from "../../../context/psikotest/SnackbarContext";

// Helpers
import errorHandler from "../../../helper/psikotest/errorHandler";
import logRender from "../../../helper/psikotest/logRender";

// Controlllers
import Ist from "../../../controller/psikotest/psikotest_system/ist";

// Components
import BackButton from "../../../components/psikotest/psikotest_system/BackButton";
import FileUpload from "../../../components/psikotest/psikotest_system/FileUpload";

// Local Components
import ListQuestionary from "./ListQuestionary";
import ListQuestionaryImage from "./ListQuestionaryImage";
import ListConversion from "./ListConversion";

const schema = yup.object().shape({
  subtest_name: yup.string().label("Subtest Name").required(),
  example: yup.string().label("(MDI) Main Question | Description | Instructions").required(),
  mdi: yup.string().label("(MDI) Main Question | Description | Instructions").required(),
  hide_mdi_within: yup.number().label("With Question"),
  with_question: yup.number().label("With Question"),
  answer_type: yup.string().label("Answer Type").required(),
  time_limit: yup
    .number()
    .label("Time Limit")
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(1)
    .required(),
});

function Form() {
  const { setMessage } = useContext(LoaderContext);
  const { setNotif } = useContext(SnackbarContext);
  const navigate = useNavigate();
  // parse param id
  const location = useLocation();
  // remove trailing slash in production when reload/refresh
  const arrPath = location.pathname.replace(/\/$/, "").split("/");
  const paramId = arrPath[arrPath.length - 1];
  // state helper
  const [isAutoHideMdi, setIsAutoHideMdi] = useState<boolean>(false)
  const [isWithMdiQuestionImage, setIsWithMdiQuestionImage] = useState<boolean>(false)
  // is create
  const isCreate = paramId === "create"
  // answer type option
  const answerTypeOptions: TSelect[] = [
    { id: 0, label: "Pilihan A-E", value: "pilihan" },
    { id: 1, label: "Input Text", value: "input_text" },
    { id: 2, label: "Input Number 1 - 9, 0", value: "input_number" },
  ]

  // react hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch
  } = useForm<TPSIst>({ resolver: yupResolver(schema) });
  // watch all field
  const doc = watch()

  useEffect(() => {
    logRender({ type: "page", name: "ist/Form" });
  }, []);

  const getDoc = useCallback(
    async function getDoc() {
      try {
        if (isCreate) {
          setValue("hide_mdi_within", 0)
          setValue("with_question", 0)
          setValue("time_limit", 0)
        } else {
          setMessage("Fetch IST")
          const ic = new Ist()
          const res = await ic.get(paramId)
          const row: TPSIst = res.data.row
          setValue("id", row.id)
          setValue("subtest_name", row.subtest_name)
          setValue("example", row.example)
          setValue("mdi", row.mdi)
          setValue("hide_mdi_within", row.hide_mdi_within)
          setValue("with_question", row.with_question)
          setValue("answer_type", row.answer_type)
          setValue("time_limit", row.time_limit)
          setValue("with_mdi_question_image", row.with_mdi_question_image)
          setValue("mdi_image", row.mdi_image)
          setIsAutoHideMdi((row.hide_mdi_within ?? 0) > 0)
          setIsWithMdiQuestionImage((row.with_mdi_question_image ?? 0) > 0)
          setMessage("")
        }
      } catch (error) {
        setMessage("");
        const errorMessage = errorHandler(error);
        setNotif({ type: "error", message: errorMessage });
        navigate(`../`, { replace: true });
      }
    },
    [setValue, paramId, isCreate, setNotif, setMessage, navigate]
  );

  useEffect(() => {
    getDoc();
  }, [getDoc]);

  useEffect(() => {
    if (isAutoHideMdi && doc.hide_mdi_within === null) {
      setValue("hide_mdi_within", 0);
    }
  }, [setValue, isAutoHideMdi, doc.hide_mdi_within]);

  const updateWithMdiQuestionImage = async () => {
    try {
      const valueWithMdiQuestionImage = doc.with_mdi_question_image === 0 ? 1 : 0
      setMessage(`${valueWithMdiQuestionImage ? "Enable" : "Disable"} with MDI & Question Image`);
      const ic = new Ist();
      await ic.update({
        ...doc,
        with_mdi_question_image: valueWithMdiQuestionImage
      });
      setIsWithMdiQuestionImage(valueWithMdiQuestionImage === 1)
      setValue("with_mdi_question_image", valueWithMdiQuestionImage)
      setMessage("");
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage })
    }
  }

  const onSubmit: SubmitHandler<TPSIst> = async (data) => {
    try {
      setMessage("Save Ist");
      const ic = new Ist();
      let res;
      if (isCreate) {
        data.id = 0;
        res = await ic.create(data);
      } else {
        res = await ic.update(data);
      }
      setNotif({ type: "success", message: res.data.message });
      setMessage("");
      navigate(`../${isCreate ? res.data.result.saved_id : doc.id}`, { replace: true });
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  const confirmDelete = async (ist: TPSIst) => {
    try {
      const confirm = await Swal.fire({
        title: "Are you sure?",
        text: "All question with this ist will also be removed, You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (confirm.isConfirmed) {
        const ic = new Ist();
        await ic.delete(ist);
        setNotif({ type: "success", message: "ist deleted" });
        navigate(`../`, { replace: true });
      }
    } catch (error) {
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  return (
    <div className="w-full flex flex-col space-y-0.5 pb-20">
      <div className="flex">
        <BackButton />
        <button
          onClick={handleSubmit(onSubmit)}
          className="mx-2 block w-auto px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded font-semibold text-sm"
        >
          <div className="flex">
            <ArrowDownOnSquareIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
            Save
          </div>
        </button>
        {!isCreate && (
          <button
            onClick={() => confirmDelete(doc)}
            className="ml-auto block w-auto px-4 py-2 bg-red-500 hover:bg-red-700 text-white rounded font-semibold text-sm"
          >
            <div className="flex">
              <TrashIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
              Delete
            </div>
          </button>
        )}
      </div>
      <div className="pt-4">
        <form className="w-full">
          <div className="flex flex-wrap -mx-3">
            {/** Subtest Name */}
            <div className="w-full md:w-full px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Subtest Name</label>
              <input
                className={
                  (errors?.subtest_name ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                  " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                }
                {...register("subtest_name")}
              />
              {errors.subtest_name && <p className="text-red-500 text-xs italic">{errors.subtest_name.message}</p>}
            </div>
            {/** (MDI) Main Question | Description | Instructions */}
            <div className="w-full md:w-full px-3 mb-6">
              <label className="text-gray-700 text-xs mb-2">
                <span className="block uppercase tracking-wide font-bold">(MDI) Main Question | Description | Instructions</span>
                <i>Main question/description/intructions for sub-test, can set only show at given time duration or always show up until test ended.</i>
              </label>
              <textarea
                className={
                  (errors?.mdi ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                  " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                }
                rows={4}
                {...register("mdi")}
              />
              {errors.mdi && <p className="text-red-500 text-xs italic">{errors.mdi.message}</p>}
            </div>
            {/** Example Question */}
            <div className="w-full md:w-full px-3 mb-6">
              <label className="text-gray-700 text-xs mb-2">
                <span className="block uppercase tracking-wide font-bold">Example Question</span>
              </label>
              <textarea
                className={
                  (errors?.example ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                  " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                }
                rows={6}
                {...register("example")}
              />
              {errors.example && <p className="text-red-500 text-xs italic">{errors.example.message}</p>}
            </div>
            {/** (MDI) Autohide */}
            <div className="flex flex-col w-full md:w-3/12 px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                (MDI) Autohide
              </label>
              <div className="flex flex-grow items-center">
                <Switch
                  checked={isAutoHideMdi}
                  onChange={setIsAutoHideMdi}
                  className={`${isAutoHideMdi ? "bg-red-500" : "bg-gray-700"
                    } relative inline-flex flex-shrink-0 h-[25px] w-[60px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={`${isAutoHideMdi ? "translate-x-7" : "translate-x-0"
                      } pointer-events-none inline-block h-[21px] w-[28px] rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
                  />
                </Switch>
              </div>
            </div>
            {/** (MDI) Timeout */}
            <div className="w-full md:w-9/12 px-3 mb-6">
              <label className="text-gray-700 text-xs mb-2">
                <span className="block uppercase tracking-wide font-bold">(MDI) Timeout</span>
                <i>This will hide MDI in given time (in seconds).</i>
              </label>
              <input
                type="number"
                className={
                  (errors?.hide_mdi_within ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                  " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:bg-gray-300 disabled:text-gray-500"
                }
                {...register("hide_mdi_within")}
                disabled={!isAutoHideMdi}
              />
              {errors.hide_mdi_within && <p className="text-red-500 text-xs italic">{errors.hide_mdi_within.message}</p>}
            </div>
            {/** With Question */}
            <div className="flex flex-col w-full md:w-3/12 px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                With Question
              </label>
              <div className="flex flex-grow items-center">
                <Switch
                  checked={doc.with_question === 1}
                  onChange={() => {
                    setValue("with_question", doc.with_question === 1 ? 0 : 1)
                  }}
                  className={`${doc.with_question === 1 ? "bg-green-500" : "bg-gray-700"
                    } relative inline-flex flex-shrink-0 h-[25px] w-[60px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={`${doc.with_question === 1 ? "translate-x-7" : "translate-x-0"
                      } pointer-events-none inline-block h-[21px] w-[28px] rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
                  />
                </Switch>
              </div>
              {errors.with_question && <p className="text-red-500 text-xs italic">{errors.with_question.message}</p>}
            </div>
            {/* Answer Type */}
            <div className="w-full md:w-9/12 px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Answer Type
              </label>
              <div>
                <Select
                  placeholder="Select Answer Type"
                  styles={{
                    control: (provided: Record<string, unknown>, state: any) => {
                      return ({
                        ...provided,
                        borderColor: errors.answer_type ? "rgb(239 68 68 / 1)" : "rgb(229 231 235 / 1)",
                        background: state.menuIsOpen ? "white" : "rgb(229 231 235 / 1)"
                      })
                    },
                  }}
                  value={answerTypeOptions.find(v => v.value === doc.answer_type)}
                  options={answerTypeOptions}
                  onChange={(v, _) => {
                    if (v?.value !== undefined && typeof v.value === "string") {
                      setValue("answer_type", v.value);
                    }
                  }}
                />
                {errors.answer_type && <p className="text-red-500 text-xs italic">{errors.answer_type.message}</p>}
              </div>
            </div>
            {/** Time Limit (Minute) */}
            <div className="w-full px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Time Limit (Minute)
              </label>
              <input
                type="number"
                className={
                  (errors?.time_limit ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                  " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                }
                {...register("time_limit")}
              />
              {errors.time_limit && (
                <p className="text-red-500 text-xs italic">{errors.time_limit.message}</p>
              )}
            </div>
          </div>
        </form>
        <hr className="py-3" />
        {/** With (MDI) & Question Image */}
        {isCreate ?
          <div className="px-4 py-2 border rounded bg-yellow-50 border-yellow-200 text-yellow-900">
            Save document to continue upload MDI Image and Question Image
          </div> :
          <div className="flex flex-col w-full mb-6">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              (MDI) & Question with Image
            </label>
            <div className="flex flex-grow items-center">
              <Switch
                checked={isWithMdiQuestionImage}
                onChange={() => updateWithMdiQuestionImage()}
                className={`${isWithMdiQuestionImage ? "bg-red-500" : "bg-gray-700"
                  } relative inline-flex flex-shrink-0 h-[25px] w-[60px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={`${isWithMdiQuestionImage ? "translate-x-7" : "translate-x-0"
                    } pointer-events-none inline-block h-[21px] w-[28px] rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
                />
              </Switch>
            </div>
          </div>
        }
        {doc.with_mdi_question_image === 1 &&
          <div>
            {/** (MDI) Image */}
            <div className="w-full md:w-full mb-6">
              <FileUpload
                type="mdi_image"
                editable={true}
                document_id={doc.id}
                filepath={doc.mdi_image ?? ""}
                error_field={errors.mdi_image}
                setDocumentId={(id) => { }}
                setFilePath={(filepath) => setValue("mdi_image", filepath)}
                key={`mdi_image`}
              />
            </div>
            {/** IST Question Image */}
            <div>
              <Disclosure defaultOpen={false} as="div" className="pb-1">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full px-4 py-4 text-sm font-medium text-left text-gray-100 bg-gray-900 rounded hover:bg-gray-800 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                      <span>Question Image</span>
                      <ChevronUpIcon className={`${open ? "transform rotate-180" : ""} w-5 h-5 text-gray-100`} />
                    </Disclosure.Button>
                    <Transition
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                        <ListQuestionaryImage isCreate={isCreate} ist={doc} editable={true} />
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            </div>
          </div>
        }
        <hr className="py-3" />
        {/** IST Questionary */}
        {!isCreate &&
          <Disclosure defaultOpen={false} as="div" className="pb-1">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between w-full px-4 py-4 text-sm font-medium text-left text-gray-100 bg-blue-900 rounded hover:bg-blue-800 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                  <span>IST Questionary</span>
                  <ChevronUpIcon className={`${open ? "transform rotate-180" : ""} w-5 h-5 text-gray-100`} />
                </Disclosure.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                    <ListQuestionary isCreate={isCreate} ist={doc} editable={true} />
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        }
        {/** Conversion GE */}
        {doc.answer_type === "input_text" &&
          <Disclosure defaultOpen={false} as="div" className="pb-1">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between w-full px-4 py-4 text-sm font-medium text-left text-gray-100 bg-green-900 rounded hover:bg-green-800 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                  <span>Conversion GE</span>
                  <ChevronUpIcon className={`${open ? "transform rotate-180" : ""} w-5 h-5 text-gray-100`} />
                </Disclosure.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                    <ListConversion isCreate={isCreate} ist={doc} editable={true} />
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        }
      </div>
    </div >
  );
}

export default Form;
