import React from "react";

interface AssessmentContextType {
  errorValidate: string;
  assessment: TPSAssessment;
  assessment_participant: TPSAssessmentParticipant;
  timeRemaining: number | null;
  setTimeRemaining: React.Dispatch<React.SetStateAction<number | null>>;
  validateAssessmentParticipant: (accessKey: string) => void;
  updateAssessmentParticipant: (assessment: TPSAssessment, participant: TPSAssessmentParticipant) => void;
}

let PPAssessmentContext = React.createContext<AssessmentContextType>(null!);

export default PPAssessmentContext;
