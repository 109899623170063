import req from "../../../services/psikotest/request";

class CfitFormula {
  async list() {
    try {
      const res = await req.get("/api/psikotest_system/cfit_formula/");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async create(data: TPSCfitFormula) {
    try {
      const res = await req.post("/api/psikotest_system/cfit_formula/", data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async get(id: string) {
    try {
      const res = await req.get("/api/psikotest_system/cfit_formula/" + id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async update(data: TPSCfitFormula) {
    try {
      const res = await req.put("/api/psikotest_system/cfit_formula/" + data.id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async delete(data: TPSCfitFormula) {
    try {
      const res = await req.delete("/api/psikotest_system/cfit_formula/", { data });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async persentil(id: number) {
    try {
      const res = await req.get("/api/psikotest_system/cfit_formula/" + id + "/persentil");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default CfitFormula;
