import req from "../../../services/assessment/request";

class Questionary {
  async list() {
    try {
      const res = await req.get("/api/assessment_system/questionary/");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async create(questionary: TQuestionary) {
    try {
      const res = await req.post("/api/assessment_system/questionary/", questionary);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async save(questionary: TQuestionary) {
    try {
      const res = await req.put("/api/assessment_system/questionary/", questionary);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async get(id: string) {
    try {
      const res = await req.get("/api/assessment_system/questionary/" + id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async delete(questionary: TQuestionary) {
    try {
      const res = await req.delete("/api/assessment_system/questionary/", { data: questionary });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async intrayEmailList(id: string) {
    try {
      const res = await req.get("/api/assessment_system/questionary/" + id + "/intray_email");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async intrayEmailAdd(id: string, data: { intray_simulation_email_id: number }) {
    try {
      const res = await req.post("/api/assessment_system/questionary/" + id + "/intray_email", data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async intrayEmaiUpdate(
    questionary_intray_simulation_email_id: string,
    data: { questionary_id: number; intray_simulation_email_id: number }
  ) {
    try {
      const res = await req.put("/api/assessment_system/questionary/intray_email/" + questionary_intray_simulation_email_id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async intrayEmaiDelete(questionary_intray_simulation_email_id: string) {
    try {
      const res = await req.delete("/api/assessment_system/questionary/intray_email/" + questionary_intray_simulation_email_id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default Questionary;
