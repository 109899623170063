import req from "../../../services/psikotest/request";

class IstFormula {
  async list(ist_id: string) {
    try {
      const res = await req.get("/api/psikotest_system/ist_formula/" + ist_id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async create(ist_id: string, data: TPSIstFormula) {
    try {
      const res = await req.post("/api/psikotest_system/ist_formula/" + ist_id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async get(ist_id: string, id: string) {
    try {
      const res = await req.get("/api/psikotest_system/ist_formula/" + ist_id + "/" + id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async update(ist_id: string, data: TPSIstFormula) {
    try {
      const res = await req.put("/api/psikotest_system/ist_formula/" + ist_id + "/" + data.id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async delete(ist_id: string, data: TPSIstFormula) {
    try {
      const res = await req.delete("/api/psikotest_system/ist_formula/" + ist_id, { data });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async norma(ist_id: string, id: number) {
    try {
      const res = await req.get("/api/psikotest_system/ist_formula/" + ist_id + "/" + id + "/norma");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default IstFormula;
