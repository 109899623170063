import { useCallback, useContext, useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS, ChartData, ChartOptions, registerables } from 'chart.js';
import dayjs from "dayjs";

// Icons
import { XMarkIcon, ClipboardDocumentCheckIcon, PrinterIcon } from "@heroicons/react/24/outline";

// Contexts
import AuthContext from "../../../../context/psikotest/AuthContext";
import LoaderContext from "../../../../context/psikotest/LoaderContext";
import SnackbarContext from "../../../../context/psikotest/SnackbarContext";

// Helpers
import errorHandler from "../../../../helper/psikotest/errorHandler";

// Controller
import Assessment from "../../../../controller/psikotest/psikotest_system/assessment";

// Variables
const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December",];
const paramMbti = ["i", "e", "s", "n", "t", "f", "j", "p"]
const paramPapi = ['N', 'G', 'A', 'L', 'P', 'I', 'T', 'V', 'X', 'S', 'B', 'O', 'R', 'D', 'C', 'Z', 'E', 'K', 'F', 'W']
const paramEpps = ['ACH', 'DEF', 'ORD', 'EXH', 'AUT', 'AFF', 'INT', 'SUC', 'DOM', 'ABA', 'NUR', 'CHG', 'END', 'HET', 'AGG']
const paramSrq20 = ['yes', 'no']
const paramDisc = ['D', 'I', 'S', 'C']
const paramResilience = Array(40).fill(0).map((_, i) => { return (i + 1) * 30 })
// chart disc options
const optionsDisc: ChartOptions = {
    maintainAspectRatio: false,
    plugins: {
        legend: { display: false }
    },
    scales: {
        y: {
            offset: true,
            min: -8,
            max: 8,
            ticks: {
                autoSkip: false,
                stepSize: 2,
            }
        },
        x: {
            position: 'top',
            offset: true
        }
    }
}
// chart resilience options
const optionsResilience: ChartOptions = {
    maintainAspectRatio: false,
    plugins: {
        legend: { display: false },
        title: {
            display: true,
            text: 'Performance - Total Answer (per 30 Second)'
        }
    },
    scales: {
        y: {
            offset: true,
            ticks: {
                autoSkip: false,
                stepSize: 2,
            }
        },
        x: {
            type: 'linear',
            offset: false,
            ticks: {
                autoSkip: false,
                stepSize: 30,
            }
        }
    }
}

// Chart default value
const defaultValuePapiChart: ChartData = {
    labels: paramPapi,
    datasets: [
        {
            label: 'PAPI Domain',
            data: [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
            borderWidth: 1,
            backgroundColor: 'rgba(0, 157, 32, 0.2)',
            pointBackgroundColor: '#fff',
            borderColor: 'rgba(0, 157, 32, 1)',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(255, 99, 132)',
            fill: true,
        },
    ],
};
const defaultValueDiscChart: ChartData = {
    labels: paramDisc,
    datasets: [
        {
            label: 'DISC',
            data: [0, 0, 0, 0],
            borderWidth: 1,
            backgroundColor: 'rgba(0, 157, 32, 0.2)',
            pointBackgroundColor: '#fff',
            borderColor: 'rgba(0, 157, 32, 1)',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(255, 99, 132)',
            fill: false,
        },
    ],
}
const defaultValueResilienceChart: ChartData = {
    labels: paramResilience,
    datasets: [
        {
            label: `Performance - Total Answer (per 30 Second)`,
            data: Array(40).fill(0),
            borderWidth: 1,
            backgroundColor: 'rgba(0, 157, 32, 0.2)',
            pointBackgroundColor: '#fff',
            borderColor: 'rgba(0, 157, 32, 1)',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(255, 99, 132)',
            fill: false,
        },
    ],
}

// ChartJS
ChartJS.register(...registerables);

function ModalParticipantReport(
    props: {
        data?: TPSAssessmentParticipant,
        assessment_id: number,
        handleClose: () => void
    }) {
    // contexts
    const { user } = useContext(AuthContext);
    const { setMessage } = useContext(LoaderContext);
    const { setNotif } = useContext(SnackbarContext);

    // ref
    const divPrint = useRef(null);

    // Variables
    const time = new Date().toString().substring(0, 24)

    // States
    const [participant_report, setParticipantReport] = useState<TPSAssessmentParticipantReport>()
    const [show_snapshot, setShowSnapshot] = useState<boolean>(false)
    const [show_time, setShowTime] = useState<boolean>(false)
    const [chartPapiData, setChartPapiData] = useState<ChartData>(defaultValuePapiChart)
    const [chartPapiDataset, setChartPapiDataset] = useState<number[]>([])
    const [chartDiscMostData, setChartDiscMostData] = useState<ChartData>(defaultValueDiscChart)
    const [chartDiscMostDataset, setChartDiscMostDataset] = useState<number[]>([])
    const [chartDiscLeastData, setChartDiscLeastData] = useState<ChartData>(defaultValueDiscChart)
    const [chartDiscLeastDataset, setChartDiscLeastDataset] = useState<number[]>([])
    const [chartDiscChangeData, setChartDiscChangeData] = useState<ChartData>(defaultValueDiscChart)
    const [chartDiscChangeDataset, setChartDiscChangeDataset] = useState<number[]>([])
    const [chartResilienceChangeData, setChartResilienceChangeData] = useState<ChartData>(defaultValueResilienceChart)
    const [chartResilienceChangeDataset, setChartResilienceChangeDataset] = useState<number[]>([])

    // get initial data
    const getDoc = useCallback(
        async function getDoc() {
            try {
                if (props.data?.id) {
                    const ca = new Assessment()
                    const res = await ca.participant_snapshot(props.assessment_id, props.data.id)
                    setParticipantReport(res.data.row)
                    setMessage("");
                }
            } catch (error) {
                setMessage("");
                const errorMessage = errorHandler(error);
                setNotif({ type: "error", message: errorMessage });
            }
        },
        [setNotif, setMessage, props.assessment_id, props.data]
    );

    useEffect(() => {
        getDoc();
    }, [getDoc]);

    // when participant_report fetched set chart
    useEffect(() => {
        // not undefined
        if (participant_report !== undefined) {
            // chart papi
            if (participant_report.personality.papi_score.id) {
                const datasetPapi: number[] = []
                paramPapi.forEach((p, _) => {
                    datasetPapi.push(JSON.parse(participant_report.personality.papi_score.result)?.[p])
                })
                setChartPapiDataset(datasetPapi)
            }
            // chart disc
            if (participant_report.behavioural.disc.disc_score.id) {
                const datasetDiscMost: number[] = Object.values(JSON.parse(participant_report.behavioural.disc.disc_score.result)?.scaled?.most)
                setChartDiscMostDataset(datasetDiscMost)
                const datasetDiscLeast: number[] = Object.values(JSON.parse(participant_report.behavioural.disc.disc_score.result)?.scaled?.least)
                setChartDiscLeastDataset(datasetDiscLeast)
                const datasetDiscChange: number[] = Object.values(JSON.parse(participant_report.behavioural.disc.disc_score.result)?.scaled?.change)
                setChartDiscChangeDataset(datasetDiscChange)
            }
            // chart resilience
            if (participant_report.behavioural.resilience.resilience_score.id) {
                const datasetResilienceChange: number[] = ((JSON.parse(participant_report.behavioural.resilience.resilience_score.result)).score_group ?? Array(40).fill(0)).map((sg: TPSResilienceScoreGroupResult, i: number) => {
                    return sg?.total_answer ?? 0
                })
                setChartResilienceChangeDataset(datasetResilienceChange)
            }
        }
    }, [participant_report])

    // when chartPapiDataset updated
    useEffect(() => {
        setChartPapiData({
            ...defaultValuePapiChart,
            datasets: [
                { ...defaultValuePapiChart.datasets[0], data: chartPapiDataset }
            ]
        })
    }, [chartPapiDataset])
    // when chartDiscMostDataset updated
    useEffect(() => {
        setChartDiscMostData({
            ...defaultValueDiscChart,
            datasets: [
                { ...defaultValueDiscChart.datasets[0], data: chartDiscMostDataset }
            ]
        })
    }, [chartDiscMostDataset])
    // when chartDiscLeastDataset updated
    useEffect(() => {
        setChartDiscLeastData({
            ...defaultValueDiscChart,
            datasets: [
                { ...defaultValueDiscChart.datasets[0], data: chartDiscLeastDataset }
            ]
        })
    }, [chartDiscLeastDataset])
    // when chartDiscChangeDataset updated
    useEffect(() => {
        setChartDiscChangeData({
            ...defaultValueDiscChart,
            datasets: [
                { ...defaultValueDiscChart.datasets[0], data: chartDiscChangeDataset }
            ]
        })
    }, [chartDiscChangeDataset])
    // when chartResilienceChangeDataset updated
    useEffect(() => {
        setChartResilienceChangeData({
            ...defaultValueResilienceChart,
            datasets: [
                { ...defaultValueResilienceChart.datasets[0], data: chartResilienceChangeDataset }
            ]
        })
    }, [chartResilienceChangeDataset])

    // Functions
    const calculateEppsConsistency = (epps_consistency_json: string): number => {
        let epps_consistency: { [key: string]: number } = JSON.parse(epps_consistency_json)
        let result = 0
        Object.keys(epps_consistency ?? []).forEach(ec_k => { result += epps_consistency[ec_k] })
        return result
    }
    const sumDisc = (disc: any) => {
        const result: number[] = Object.values(disc ?? {})
        const sum = result.reduce((a, b) => a + b, 0)
        return sum
    }
    const calculateDiffTime = (start_time: string | null, end_time: string | null, close_by_user: string | null): string => {
        let result = "-"
        if (dayjs(start_time).isValid()) {
            const start = dayjs(start_time)
            let end = null
            if (dayjs(close_by_user).isValid()) {
                end = dayjs(close_by_user)
            } else {
                if (dayjs(end_time).isValid()) {
                    end = dayjs(end_time)
                }
            }
            if (end !== null) {
                result = !isNaN(end.diff(start, 's')) ? `${(end.diff(start, 's') / 60).toFixed(1)}  Minutes` : 'N/A'
            }
        }
        return result
    }

    return <div>
        <div className="fixed z-20 top-0 left-0 w-screen h-screen bg-black opacity-20"></div>
        <div className="fixed z-30 top-0 left-0 w-screen h-screen bg-transparent flex items-center justify-center">
            <div className="bg-white w-screen md:w-[84%] h-auto md:rounded overflow-auto">
                <div className="p-4 bg-green-800 md:rounded-t text-white flex justify-between">
                    <div className="flex space-x-2">
                        <ClipboardDocumentCheckIcon className="w-4" />
                        <h3>Participant Report</h3>
                    </div>
                    <XMarkIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent cursor-pointer" aria-hidden="true" onClick={() => props.handleClose()} />
                </div>
                <div className="h-[89vh] p-4 overflow-auto">
                    {/** Action Report Button */}
                    <div className="flex pb-2">
                        <div className="flex space-x-2">
                            <button
                                type="button"
                                className={`flex px-2 py-1 border rounded ${show_snapshot ? "border-red-600 text-red-600 hover:bg-red-100" : "border-blue-600 text-blue-600 hover:bg-blue-100"}`}
                                onClick={() => setShowSnapshot(!show_snapshot)}>
                                {show_snapshot ? "Hide" : "Show"} Snapshot
                            </button>
                            <button
                                type="button"
                                className={`flex px-2 py-1 border rounded ${show_time ? "border-red-600 text-red-600 hover:bg-red-100" : "border-blue-600 text-blue-600 hover:bg-blue-100"}`}
                                onClick={() => setShowTime(!show_time)}>
                                {show_time ? "Hide" : "Show"} Time
                            </button>
                        </div>
                        <ReactToPrint
                            trigger={() => (
                                <button
                                    type="button"
                                    className="flex ml-auto px-2 py-1 border rounded border-green-600 text-green-600 hover:bg-green-100"
                                >
                                    <PrinterIcon className="w-5" />
                                    <span className="pl-2">Print</span>
                                </button>
                            )}
                            pageStyle={`@page { margin: 20 10 !important; }`}
                            content={() => divPrint.current}
                        />
                    </div>
                    {/** Report */}
                    {participant_report &&
                        <div ref={divPrint} className="py-4 px-8 border min-w-[1024px] w-full" style={{ WebkitPrintColorAdjust: "exact" }}>
                            <div className="pt-2 px-1 font-semibold text-2xl text-center">Psikotest Report</div>
                            <div className="pb-2 px-1 font-light italic text-sm text-center">
                                <span className="capitalize">{user.username}</span> - {time}
                            </div>
                            <div className="py-2">
                                <div className="py-2 px-1 font-semibold text-xl">Detail Peserta</div>
                                <div className="pl-5 flex items-center">
                                    <img width={120} src={`${process.env.REACT_APP_URL}/upload/psikotest_system/get_photo/${participant_report?.participant.photo}`} alt="Snapshot Awal" />
                                    <div className="px-4">
                                        <div className="flex">
                                            <span className="w-40 block">Nama Lengkap</span>: {participant_report.participant.full_name}
                                        </div>
                                        <div className="flex">
                                            <span className="w-40 block">Email</span>: {participant_report.participant.email}
                                        </div>
                                        <div className="flex">
                                            <span className="w-40 block">Jenis Kelamin</span>: {participant_report.participant.gender}
                                        </div>
                                        <div className="flex">
                                            <span className="w-40 block">Tanggal Lahir</span>:{" "}
                                            {
                                                `${new Date(participant_report.participant.date_of_birth ?? "").getDate().toString().padStart(2, "0")} 
                                                ${monthNames[new Date(participant_report.participant.date_of_birth ?? "").getMonth()]} ${new Date(participant_report.participant.date_of_birth ?? "").getFullYear()}`
                                            }
                                        </div>
                                        <div className="flex">
                                            <span className="w-40 block">Usia</span>:{" "}
                                            {participant_report.age} Tahun
                                        </div>
                                        <div className="flex">
                                            <span className="w-40 block">Pendidikan Terakhir</span>: {participant_report.participant?.last_education}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="py-2">
                                <div className="px-1 font-semibold text-xl">Hasil Psikotest</div>
                            </div>
                            {(
                                participant_report.cfit.score_snapshot.filter(cfi => cfi.cfit_id !== null).length > 0
                                || participant_report.ist.score_snapshot.filter(ist => ist.ist_id !== null).length > 0
                            ) &&
                                <div className="px-4">
                                    <div className="py-1 font-semibold text-lg">Intelligence</div>
                                </div>
                            }
                            {participant_report.cfit.score_snapshot.filter(c => c.cfit_id !== null).length > 0 &&
                                <div className="px-4 border">
                                    <div className="py-1 font-semibold text-lg">CFIT</div>
                                    <div className="px-4">
                                        <div className="py-2">
                                            <div className="flex">
                                                <span className="w-40 block">Total Score</span>:&nbsp;
                                                <span className="">{participant_report.cfit.total_score}</span>
                                            </div>
                                            <div className="flex">
                                                <span className="w-40 block font-semibold">IQ</span>:&nbsp;
                                                <span className="font-bold">{participant_report.cfit.iq}</span>
                                            </div>
                                            {show_time &&
                                                <div className="flex">
                                                    <span className="w-40 block">Waktu</span>:&nbsp;
                                                    <div>
                                                        {participant_report.cfit.score_snapshot.map((cfit_ss, i) => (
                                                            <div key={i} className="flex">
                                                                <span className="w-32 block">{cfit_ss.test_name}</span>:&nbsp;
                                                                <span className="">{calculateDiffTime(cfit_ss.start_time, cfit_ss.end_time, cfit_ss.close_by_user)}</span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        {show_snapshot &&
                                            <div className="py-2">
                                                {participant_report.cfit.score_snapshot.map((ss, i) => (
                                                    <div key={i}>
                                                        <div className="flex pt-2">
                                                            <span className="w-40 block">Screencap</span>: {ss.test_name}&nbsp;
                                                        </div>
                                                        <div className="flex flex-wrap justify-left gap-2">
                                                            {ss.files.map((file, file_i) => {
                                                                const alt = process.env.NODE_ENV === 'development' ? `${file.replace(/\\/g, "-")}` : `${file.replace(/\//g, "-")}`;
                                                                const filepath = process.env.NODE_ENV === 'development' ? file.replace(/\\/g, "/") : file;
                                                                const timestamp = process.env.NODE_ENV === 'development'
                                                                    ? parseInt(file.split("\\")[file.split("\\").length - 1].split(".")[0])
                                                                    : parseInt(file.split("/")[file.split("/").length - 1].split(".")[0]);
                                                                let datestamp: string = "";
                                                                if (isNaN(timestamp) === false) {
                                                                    datestamp = new Date(timestamp * 1000).toString().substring(0, 24);
                                                                }
                                                                return (
                                                                    <div key={`file_${file_i}`}>
                                                                        <img
                                                                            className="w-40"
                                                                            alt={alt}
                                                                            src={`${process.env.REACT_APP_URL}/upload/psikotest_system/get_snapshot/${filepath}`}
                                                                        />
                                                                        <div className="text-xs text-center border">{datestamp}</div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                            {participant_report.ist.score_snapshot.filter(ist => ist.ist_id !== null).length > 0 &&
                                <div className="px-4 border">
                                    <div className="py-1 font-semibold text-lg">IST</div>
                                    <div className="px-4">
                                        <div className="py-2">
                                            <div className="flex">
                                                <span className="w-40 block">Total Score</span>:&nbsp;
                                                <span className="">{participant_report.ist.total_score}</span>
                                            </div>
                                            <div className="flex">
                                                <span className="w-40 block">SW</span>:&nbsp;
                                                <span className="">{participant_report.ist.gesamt_sw ?? "-"}</span>
                                            </div>
                                            <div className="flex">
                                                <span className="w-40 block font-semibold">IQ</span>:&nbsp;
                                                <span className="font-bold">{participant_report.ist.gesamt_iq ?? "-"}</span>
                                            </div>
                                            {show_time &&
                                                <div className="flex">
                                                    <span className="w-40 block">Waktu</span>:&nbsp;
                                                    <div>
                                                        {participant_report.ist.score_snapshot.map((ist_ss, i) => (
                                                            <div key={i} className="flex">
                                                                <span className="w-32 block">{ist_ss.subtest_name}</span>:&nbsp;
                                                                <span className="">{calculateDiffTime(ist_ss.start_time, ist_ss.end_time, ist_ss.close_by_user)}</span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            }
                                            <div className="flex">
                                                <span className="w-40 block font-semibold">Score SW</span>:&nbsp;
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            {participant_report.ist.score_snapshot.map((ss, i) => (
                                                                <td key={i} className="capitalize py-1 px-2 border text-center bg-slate-300 font-semibold">
                                                                    {ss.subtest_name}
                                                                </td>
                                                            ))}
                                                        </tr>
                                                        <tr>
                                                            {participant_report.ist.score_snapshot.map((ss, i) => {
                                                                return <td key={i} className={`py-1 px-2 border text-center`}>
                                                                    {ss.score_sw}
                                                                </td>
                                                            })}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {show_snapshot &&
                                            <div className="py-2">
                                                {participant_report.ist.score_snapshot.map((ss, i) => (
                                                    <div key={i}>
                                                        <div className="flex pt-2">
                                                            <span className="w-40 block">Screencap</span>: {ss.subtest_name}&nbsp;
                                                        </div>
                                                        <div className="flex flex-wrap justify-left gap-2">
                                                            {ss.files.map((file, file_i) => {
                                                                const alt = process.env.NODE_ENV === 'development' ? `${file.replace(/\\/g, "-")}` : `${file.replace(/\//g, "-")}`;
                                                                const filepath = process.env.NODE_ENV === 'development' ? file.replace(/\\/g, "/") : file;
                                                                const timestamp = process.env.NODE_ENV === 'development'
                                                                    ? parseInt(file.split("\\")[file.split("\\").length - 1].split(".")[0])
                                                                    : parseInt(file.split("/")[file.split("/").length - 1].split(".")[0]);
                                                                let datestamp: string = "";
                                                                if (isNaN(timestamp) === false) {
                                                                    datestamp = new Date(timestamp * 1000).toString().substring(0, 24);
                                                                }
                                                                return (
                                                                    <div key={`file_${file_i}`}>
                                                                        <img
                                                                            className="w-40"
                                                                            alt={alt}
                                                                            src={`${process.env.REACT_APP_URL}/upload/psikotest_system/get_snapshot/${filepath}`}
                                                                        />
                                                                        <div className="text-xs text-center border">{datestamp}</div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                            {(
                                participant_report.personality.mbti_score.id ||
                                participant_report.personality.papi_score.id ||
                                participant_report.personality.epps_score.id ||
                                participant_report.personality.srq20_score.id ||
                                participant_report.personality.mmpi_score.id ||
                                participant_report.personality.brs_score.id
                            ) &&
                                <div className="px-4" style={{ pageBreakBefore: "always" }}>
                                    <div className="py-1 font-semibold text-lg">Personality</div>
                                </div>
                            }
                            {participant_report.personality.mbti_score.id &&
                                <div className="px-4 border">
                                    <div className="py-1 font-semibold text-lg">MBTI</div>
                                    <div className="px-4">
                                        <div className="py-2">
                                            <div className="flex">
                                                <div className="w-40">Result</div>:&nbsp;
                                                <div className="px-2">
                                                    <div className="font-bold text-xl px-1 pb-3 text-green-700">
                                                        {JSON.parse(participant_report.personality.mbti_score.result)?.i === JSON.parse(participant_report.personality.mbti_score.result)?.e ? "-" : JSON.parse(participant_report.personality.mbti_score.result)?.i > JSON.parse(participant_report.personality.mbti_score.result)?.e ? "I" : "E"}
                                                        {JSON.parse(participant_report.personality.mbti_score.result)?.s === JSON.parse(participant_report.personality.mbti_score.result)?.n ? "-" : JSON.parse(participant_report.personality.mbti_score.result)?.s > JSON.parse(participant_report.personality.mbti_score.result)?.n ? "S" : "N"}
                                                        {JSON.parse(participant_report.personality.mbti_score.result)?.t === JSON.parse(participant_report.personality.mbti_score.result)?.f ? "-" : JSON.parse(participant_report.personality.mbti_score.result)?.t > JSON.parse(participant_report.personality.mbti_score.result)?.f ? "T" : "F"}
                                                        {JSON.parse(participant_report.personality.mbti_score.result)?.j === JSON.parse(participant_report.personality.mbti_score.result)?.p ? "-" : JSON.parse(participant_report.personality.mbti_score.result)?.j > JSON.parse(participant_report.personality.mbti_score.result)?.p ? "J" : "P"}
                                                    </div>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                {paramMbti.map((p, i) => {
                                                                    const percentScore = JSON.parse(participant_report.personality.mbti_score.result)?.[p] / 15 * 100;
                                                                    return (
                                                                        <td key={i} className={`capitalize py-1 px-2 border text-center font-semibold ${percentScore > 50 ? 'bg-green-300' : 'bg-slate-300'}`}>
                                                                            {p}
                                                                        </td>
                                                                    )
                                                                })}
                                                            </tr>
                                                            <tr>
                                                                {paramMbti.map((p, i) => {
                                                                    const percentScore = JSON.parse(participant_report.personality.mbti_score.result)?.[p] / 15 * 100;
                                                                    return (
                                                                        <td key={i} className={`py-1 px-2 border text-center ${percentScore > 50 ? 'bg-green-200' : ''}`}>
                                                                            {`${percentScore.toFixed(0)}%`}
                                                                        </td>
                                                                    )
                                                                }
                                                                )}
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {show_time &&
                                                <div className="flex py-2">
                                                    <span className="w-40 block">Waktu</span>:&nbsp;
                                                    <div className="px-2">
                                                        {calculateDiffTime(participant_report.personality.mbti_session.start_time, participant_report.personality.mbti_session.end_time, participant_report.personality.mbti_session.close_by_user)}
                                                    </div>
                                                </div>
                                            }
                                            {show_snapshot &&
                                                <>
                                                    <div className="flex pt-2">
                                                        <span className="w-40 block">Screencap</span>:&nbsp;
                                                    </div>
                                                    <div className="py-2">
                                                        <div className="flex flex-wrap justify-left gap-2">
                                                            {participant_report.personality.mbti_files.map((file, file_i) => {
                                                                const alt = process.env.NODE_ENV === 'development' ? `${file.replace(/\\/g, "-")}` : `${file.replace(/\//g, "-")}`;
                                                                const filepath = process.env.NODE_ENV === 'development' ? file.replace(/\\/g, "/") : file;
                                                                const timestamp = process.env.NODE_ENV === 'development'
                                                                    ? parseInt(file.split("\\")[file.split("\\").length - 1].split(".")[0])
                                                                    : parseInt(file.split("/")[file.split("/").length - 1].split(".")[0]);
                                                                let datestamp: string = "";
                                                                if (isNaN(timestamp) === false) {
                                                                    datestamp = new Date(timestamp * 1000).toString().substring(0, 24);
                                                                }
                                                                return (
                                                                    <div key={`file_${file_i}`}>
                                                                        <img
                                                                            className="w-40"
                                                                            alt={alt}
                                                                            src={`${process.env.REACT_APP_URL}/upload/psikotest_system/get_snapshot/${filepath}`}
                                                                        />
                                                                        <div className="text-xs text-center border">{datestamp}</div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {participant_report.personality.papi_score.id &&
                                <div className="px-4 border">
                                    <div className="py-1 font-semibold text-lg">PAPI</div>
                                    <div className="px-4">
                                        <div className="py-2">
                                            <div className="flex">
                                                <span className="w-40 block">Result</span>:&nbsp;
                                                <div className="px-2">
                                                    <div className="w-80 pb-4">
                                                        <Chart type='radar' data={chartPapiData}
                                                            options={{ plugins: { legend: { display: false } } }}
                                                        />
                                                    </div>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                {paramPapi.map((p, i) => (
                                                                    <td key={i} className="capitalize py-1 px-2 border text-center bg-slate-300 font-semibold">{p}</td>
                                                                ))}
                                                            </tr>
                                                            <tr>
                                                                {paramPapi.map((p, i) => {
                                                                    const score = JSON.parse(participant_report.personality.papi_score.result)?.[p];
                                                                    return <td key={i} className={`py-1 px-2 border text-center`}>{score}</td>
                                                                })}
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {show_time &&
                                                <div className="flex py-2">
                                                    <span className="w-40 block">Waktu</span>:&nbsp;
                                                    <div className="px-2">
                                                        {calculateDiffTime(participant_report.personality.papi_session.start_time, participant_report.personality.papi_session.end_time, participant_report.personality.papi_session.close_by_user)}
                                                    </div>
                                                </div>
                                            }
                                            {show_snapshot &&
                                                <>
                                                    <div className="flex pt-2">
                                                        <span className="w-40 block">Screencap</span>:&nbsp;
                                                    </div>
                                                    <div className="py-2">
                                                        <div className="flex flex-wrap justify-left gap-2">
                                                            {participant_report.personality.papi_files.map((file, file_i) => {
                                                                const alt = process.env.NODE_ENV === 'development' ? `${file.replace(/\\/g, "-")}` : `${file.replace(/\//g, "-")}`;
                                                                const filepath = process.env.NODE_ENV === 'development' ? file.replace(/\\/g, "/") : file;
                                                                const timestamp = process.env.NODE_ENV === 'development'
                                                                    ? parseInt(file.split("\\")[file.split("\\").length - 1].split(".")[0])
                                                                    : parseInt(file.split("/")[file.split("/").length - 1].split(".")[0]);
                                                                let datestamp: string = "";
                                                                if (isNaN(timestamp) === false) {
                                                                    datestamp = new Date(timestamp * 1000).toString().substring(0, 24);
                                                                }
                                                                return (
                                                                    <div key={`file_${file_i}`}>
                                                                        <img
                                                                            className="w-40"
                                                                            alt={alt}
                                                                            src={`${process.env.REACT_APP_URL}/upload/psikotest_system/get_snapshot/${filepath}`}
                                                                        />
                                                                        <div className="text-xs text-center border">{datestamp}</div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {participant_report.personality.epps_score.id &&
                                <div className="px-4 border">
                                    <div className="py-1 font-semibold text-lg">EPPS</div>
                                    <div className="px-4">
                                        <div className="py-2">
                                            <div className="flex">
                                                <span className="w-40 block">Result</span>:&nbsp;
                                            </div>
                                            <div className="pt-3">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            {paramEpps.map((p, i) => (
                                                                <td key={i} className="capitalize py-1 px-2 border text-center bg-slate-300 font-semibold">{p}</td>
                                                            ))}
                                                            <td className="capitalize py-1 px-2 border text-center bg-green-300 font-semibold">Consistency</td>
                                                        </tr>
                                                        <tr>
                                                            {paramEpps.map((p, i) => {
                                                                const score = JSON.parse(participant_report.personality.epps_score.result)?.[`${p}_SCORE`]
                                                                const strScore = score < 0
                                                                    ? "-".repeat(Math.abs(score))
                                                                    : score === 0 ? '0' : "+".repeat(score)
                                                                return <td key={i} className={`py-1 px-2 border text-center`}>{strScore}</td>
                                                            })}
                                                            <td className={`py-1 px-2 border text-center bg-green-50`}>{calculateEppsConsistency(participant_report.personality.epps_score.consistency)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            {show_time &&
                                                <div className="flex py-2">
                                                    <span className="w-40 block">Waktu</span>:&nbsp;
                                                    <div className="px-2">
                                                        {calculateDiffTime(participant_report.personality.epps_session.start_time, participant_report.personality.epps_session.end_time, participant_report.personality.epps_session.close_by_user)}
                                                    </div>
                                                </div>
                                            }
                                            {show_snapshot &&
                                                <>
                                                    <div className="flex pt-2">
                                                        <span className="w-40 block">Screencap</span>:&nbsp;
                                                    </div>
                                                    <div className="py-2">
                                                        <div className="flex flex-wrap justify-left gap-2">
                                                            {participant_report.personality.epps_files.map((file, file_i) => {
                                                                const alt = process.env.NODE_ENV === 'development' ? `${file.replace(/\\/g, "-")}` : `${file.replace(/\//g, "-")}`;
                                                                const filepath = process.env.NODE_ENV === 'development' ? file.replace(/\\/g, "/") : file;
                                                                const timestamp = process.env.NODE_ENV === 'development'
                                                                    ? parseInt(file.split("\\")[file.split("\\").length - 1].split(".")[0])
                                                                    : parseInt(file.split("/")[file.split("/").length - 1].split(".")[0]);
                                                                let datestamp: string = "";
                                                                if (isNaN(timestamp) === false) {
                                                                    datestamp = new Date(timestamp * 1000).toString().substring(0, 24);
                                                                }
                                                                return (
                                                                    <div key={`file_${file_i}`}>
                                                                        <img
                                                                            className="w-40"
                                                                            alt={alt}
                                                                            src={`${process.env.REACT_APP_URL}/upload/psikotest_system/get_snapshot/${filepath}`}
                                                                        />
                                                                        <div className="text-xs text-center border">{datestamp}</div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {participant_report.personality.srq20_score.id &&
                                <div className="px-4 border">
                                    <div className="py-1 font-semibold text-lg">SRQ-20</div>
                                    <div className="px-4">
                                        <div className="py-2">
                                            <div className="flex">
                                                <div className="w-40">Result</div>:&nbsp;
                                                <div className="px-2">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                {paramSrq20.map((p, i) => (
                                                                    <td key={i} className="capitalize py-1 px-2 border text-center bg-slate-300 font-semibold">{p}</td>
                                                                ))}
                                                                <td className="capitalize py-1 px-2 border text-center bg-green-300 font-semibold">Total</td>
                                                                <td className="capitalize py-1 px-2 border text-center bg-green-300 font-semibold">Result</td>
                                                            </tr>
                                                            <tr>
                                                                {paramSrq20.map((p, i) => {
                                                                    const score = JSON.parse(participant_report.personality.srq20_score.result)?.[p] ?? 0
                                                                    return <td key={i} className={`py-1 px-2 border text-center`}>{score}</td>
                                                                })}
                                                                <td className={`py-1 px-2 border text-center bg-green-50`}>
                                                                    {JSON.parse(participant_report.personality.srq20_score?.answer ?? '[]').filter((a: { answer: string }) => a.answer !== '').length}
                                                                </td>
                                                                <td className={`py-1 px-2 border text-center ${(JSON.parse(participant_report.personality.srq20_score.result)?.['yes'] ?? 0) >= 5 ? 'bg-red-50' : 'bg-green-50'}`}>
                                                                    {(JSON.parse(participant_report.personality.srq20_score.result)?.['yes'] ?? 20) >= 5
                                                                        ? <div className="text-red-700 font-semibold">Need Check</div>
                                                                        : <div className="text-green-700">Pass</div>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {show_time &&
                                                <div className="flex py-2">
                                                    <span className="w-40 block">Waktu</span>:&nbsp;
                                                    <div className="px-2">
                                                        {calculateDiffTime(participant_report.personality.srq20_session.start_time, participant_report.personality.srq20_session.end_time, participant_report.personality.srq20_session.close_by_user)}
                                                    </div>
                                                </div>
                                            }
                                            {show_snapshot &&
                                                <>
                                                    <div className="flex pt-2">
                                                        <span className="w-40 block">Screencap</span>:&nbsp;
                                                    </div>
                                                    <div className="py-2">
                                                        <div className="flex flex-wrap justify-left gap-2">
                                                            {participant_report.personality.srq20_files.map((file, file_i) => {
                                                                const alt = process.env.NODE_ENV === 'development' ? `${file.replace(/\\/g, "-")}` : `${file.replace(/\//g, "-")}`;
                                                                const filepath = process.env.NODE_ENV === 'development' ? file.replace(/\\/g, "/") : file;
                                                                const timestamp = process.env.NODE_ENV === 'development'
                                                                    ? parseInt(file.split("\\")[file.split("\\").length - 1].split(".")[0])
                                                                    : parseInt(file.split("/")[file.split("/").length - 1].split(".")[0]);
                                                                let datestamp: string = "";
                                                                if (isNaN(timestamp) === false) {
                                                                    datestamp = new Date(timestamp * 1000).toString().substring(0, 24);
                                                                }
                                                                return (
                                                                    <div key={`file_${file_i}`}>
                                                                        <img
                                                                            className="w-40"
                                                                            alt={alt}
                                                                            src={`${process.env.REACT_APP_URL}/upload/psikotest_system/get_snapshot/${filepath}`}
                                                                        />
                                                                        <div className="text-xs text-center border">{datestamp}</div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {participant_report.personality.mmpi_score.id &&
                                <div className="px-4 border">
                                    <div className="py-1 font-semibold text-lg">MMPI</div>
                                    <div className="px-4">
                                        <div className="py-2">
                                            <div className="flex">
                                                <div className="w-40">Result</div>:&nbsp;
                                                <div className="px-2">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td className="capitalize py-1 px-2 border text-center bg-slate-300 font-semibold">Total Answer</td>
                                                                <td className="capitalize py-1 px-2 border text-center bg-slate-300 font-semibold">Answer (Empty)</td>
                                                                <td className={`capitalize py-1 px-2 border text-center font-semibold ${(JSON.parse(participant_report.personality.mmpi_score.answer) ?? []).filter((a: any) => a.answer === "").length >= 30
                                                                    ? 'bg-red-300'
                                                                    : (JSON.parse(participant_report.personality.mmpi_score.answer) ?? []).filter((a: any) => a.answer === "").length <= 10
                                                                        ? 'bg-green-300'
                                                                        : 'bg-yellow-300'}`}>Validity</td>
                                                            </tr>
                                                            <tr>
                                                                <td className={`py-1 px-2 border text-center bg-slate-50`}>
                                                                    {(JSON.parse(participant_report.personality.mmpi_score?.answer) ?? []).filter((a: any) => a.answer !== "").length}/567
                                                                </td>
                                                                <td className={`py-1 px-2 border text-center bg-green-50`}>
                                                                    {(JSON.parse(participant_report.personality.mmpi_score?.answer) ?? []).filter((a: any) => a.answer === "").length}
                                                                </td>
                                                                <td className={`py-1 px-2 border text-center ${(JSON.parse(participant_report.personality.mmpi_score.answer) ?? []).filter((a: any) => a.answer === "").length >= 30
                                                                    ? 'bg-red-100'
                                                                    : (JSON.parse(participant_report.personality.mmpi_score.answer) ?? []).filter((a: any) => a.answer === "").length <= 10
                                                                        ? 'bg-ghreen-100'
                                                                        : 'bg-yellow-100'}`}>
                                                                    {(JSON.parse(participant_report.personality.mmpi_score.answer) ?? []).filter((a: any) => a.answer === "").length >= 30
                                                                        ? <div className="text-red-600">Mungkin Tidak Valid</div>
                                                                        : (JSON.parse(participant_report.personality.mmpi_score.answer) ?? []).filter((a: any) => a.answer === "").length <= 10
                                                                            ? <div className="text-green-700">Kemungkinan Valid</div>
                                                                            : <div className="text-yellow-800">Validitas Dipertanyakan</div>}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {show_time &&
                                                <div className="flex py-2">
                                                    <span className="w-40 block">Waktu</span>:&nbsp;
                                                    <div className="px-2">
                                                        {calculateDiffTime(participant_report.personality.mmpi_session.start_time, participant_report.personality.mmpi_session.end_time, participant_report.personality.mmpi_session.close_by_user)}
                                                    </div>
                                                </div>
                                            }
                                            {show_snapshot &&
                                                <>
                                                    <div className="flex pt-2">
                                                        <span className="w-40 block">Screencap</span>:&nbsp;
                                                    </div>
                                                    <div className="py-2">
                                                        <div className="flex flex-wrap justify-left gap-2">
                                                            {participant_report.personality.mmpi_files.map((file, file_i) => {
                                                                const alt = process.env.NODE_ENV === 'development' ? `${file.replace(/\\/g, "-")}` : `${file.replace(/\//g, "-")}`;
                                                                const filepath = process.env.NODE_ENV === 'development' ? file.replace(/\\/g, "/") : file;
                                                                const timestamp = process.env.NODE_ENV === 'development'
                                                                    ? parseInt(file.split("\\")[file.split("\\").length - 1].split(".")[0])
                                                                    : parseInt(file.split("/")[file.split("/").length - 1].split(".")[0]);
                                                                let datestamp: string = "";
                                                                if (isNaN(timestamp) === false) {
                                                                    datestamp = new Date(timestamp * 1000).toString().substring(0, 24);
                                                                }
                                                                return (
                                                                    <div key={`file_${file_i}`}>
                                                                        <img
                                                                            className="w-40"
                                                                            alt={alt}
                                                                            src={`${process.env.REACT_APP_URL}/upload/psikotest_system/get_snapshot/${filepath}`}
                                                                        />
                                                                        <div className="text-xs text-center border">{datestamp}</div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {participant_report.personality.brs_score.id &&
                                <div className="px-4 border">
                                    <div className="py-1 font-semibold text-lg">BRS</div>
                                    <div className="px-4">
                                        <div className="py-2">
                                            <div className="flex">
                                                <div className="w-40">Result</div>:&nbsp;
                                                <div className="px-2">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td className="capitalize py-1 px-2 border text-center bg-slate-300 font-semibold">Total Answer</td>
                                                                <td className="capitalize py-1 px-2 border text-center bg-slate-300 font-semibold">Score</td>
                                                                <td className={`capitalize py-1 px-2 border text-center font-semibold ${participant_report.personality.brs_score.interpretation === "Resiliensi rendah"
                                                                    ? 'bg-red-300'
                                                                    : participant_report.personality.brs_score.interpretation === "Resiliensi tinggi"
                                                                        ? 'bg-green-300' : 'bg-yellow-300'}`}
                                                                >
                                                                    Validity
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className={`py-1 px-2 border text-center bg-slate-50`}>
                                                                    {(JSON.parse(participant_report.personality.brs_score?.answer) ?? []).filter((a: any) => a.answer !== "").length}/{JSON.parse(participant_report.personality.brs_score?.answer ?? "[]").length}
                                                                </td>
                                                                <td className={`py-1 px-2 border text-center bg-slate-50`}>
                                                                    {participant_report.personality.brs_score.score_result.toFixed(2)}
                                                                </td>
                                                                <td className={`py-1 px-2 border text-center ${participant_report.personality.brs_score.interpretation === "Resiliensi rendah"
                                                                    ? 'bg-red-100'
                                                                    : participant_report.personality.brs_score.interpretation === "Resiliensi tinggi"
                                                                        ? 'bg-green-100' : 'bg-yellow-100'}`}>
                                                                    <div className={participant_report.personality.brs_score.interpretation === "Resiliensi rendah"
                                                                        ? 'text-red-600'
                                                                        : participant_report.personality.brs_score.interpretation === "Resiliensi tinggi"
                                                                            ? 'text-green-700' : 'text-yellow-800'}>
                                                                        {participant_report.personality.brs_score.interpretation}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {show_time &&
                                                <div className="flex py-2">
                                                    <span className="w-40 block">Waktu</span>:&nbsp;
                                                    <div className="px-2">
                                                        {calculateDiffTime(participant_report.personality.brs_session.start_time, participant_report.personality.brs_session.end_time, participant_report.personality.brs_session.close_by_user)}
                                                    </div>
                                                </div>
                                            }
                                            {show_snapshot &&
                                                <>
                                                    <div className="flex pt-2">
                                                        <span className="w-40 block">Screencap</span>:&nbsp;
                                                    </div>
                                                    <div className="py-2">
                                                        <div className="flex flex-wrap justify-left gap-2">
                                                            {participant_report.personality.brs_files.map((file, file_i) => {
                                                                const alt = process.env.NODE_ENV === 'development' ? `${file.replace(/\\/g, "-")}` : `${file.replace(/\//g, "-")}`;
                                                                const filepath = process.env.NODE_ENV === 'development' ? file.replace(/\\/g, "/") : file;
                                                                const timestamp = process.env.NODE_ENV === 'development'
                                                                    ? parseInt(file.split("\\")[file.split("\\").length - 1].split(".")[0])
                                                                    : parseInt(file.split("/")[file.split("/").length - 1].split(".")[0]);
                                                                let datestamp: string = "";
                                                                if (isNaN(timestamp) === false) {
                                                                    datestamp = new Date(timestamp * 1000).toString().substring(0, 24);
                                                                }
                                                                return (
                                                                    <div key={`file_${file_i}`}>
                                                                        <img
                                                                            className="w-40"
                                                                            alt={alt}
                                                                            src={`${process.env.REACT_APP_URL}/upload/psikotest_system/get_snapshot/${filepath}`}
                                                                        />
                                                                        <div className="text-xs text-center border">{datestamp}</div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {(
                                participant_report.behavioural.rac.rac_score.id ||
                                participant_report.behavioural.disc.disc_score.id ||
                                participant_report.behavioural.resilience.resilience_score.id
                            ) &&
                                <div className="px-4" style={{ pageBreakBefore: "always" }}>
                                    <div className="py-1 font-semibold text-lg">Behavioural</div>
                                </div>
                            }
                            {participant_report.behavioural.rac.rac_score.id &&
                                <div className="px-4 border">
                                    <div className="py-1 font-semibold text-lg">RAC</div>
                                    <div className="px-4">
                                        <div className="py-2">
                                            <div className="flex">
                                                <span className="w-40 block">Result</span>:&nbsp;
                                                <div className="pl-2 font-semibold space-y-2">
                                                    <div className="space-x-2">
                                                        <span>Speed</span>
                                                        <span>({participant_report.behavioural.rac.rac_score.kecepatan})</span>
                                                        <span className="">(Correct {JSON.parse(participant_report.behavioural.rac.rac_score.result)?.correct})</span>
                                                    </div>
                                                    <div className="space-x-2">
                                                        <span>Accuracy</span>
                                                        <span>({participant_report.behavioural.rac.rac_score.ketelitian})</span>
                                                        <span className="">(Wrong {JSON.parse(participant_report.behavioural.rac.rac_score.result)?.wrong})</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {show_time &&
                                                <div className="flex py-2">
                                                    <span className="w-40 block">Waktu</span>:&nbsp;
                                                    <div className="px-2">
                                                        {calculateDiffTime(participant_report.behavioural.rac_session.start_time, participant_report.behavioural.rac_session.end_time, participant_report.behavioural.rac_session.close_by_user)}
                                                    </div>
                                                </div>
                                            }
                                            {show_snapshot &&
                                                <>
                                                    <div className="flex pt-2">
                                                        <span className="w-40 block">Screencap</span>:&nbsp;
                                                    </div>
                                                    <div className="py-2">
                                                        <div className="flex flex-wrap justify-left gap-2">
                                                            {participant_report.behavioural.rac_files.map((file, file_i) => {
                                                                const alt = process.env.NODE_ENV === 'development' ? `${file.replace(/\\/g, "-")}` : `${file.replace(/\//g, "-")}`;
                                                                const filepath = process.env.NODE_ENV === 'development' ? file.replace(/\\/g, "/") : file;
                                                                const timestamp = process.env.NODE_ENV === 'development'
                                                                    ? parseInt(file.split("\\")[file.split("\\").length - 1].split(".")[0])
                                                                    : parseInt(file.split("/")[file.split("/").length - 1].split(".")[0]);
                                                                let datestamp: string = "";
                                                                if (isNaN(timestamp) === false) {
                                                                    datestamp = new Date(timestamp * 1000).toString().substring(0, 24);
                                                                }
                                                                return (
                                                                    <div key={`file_${file_i}`}>
                                                                        <img
                                                                            className="w-40"
                                                                            alt={alt}
                                                                            src={`${process.env.REACT_APP_URL}/upload/psikotest_system/get_snapshot/${filepath}`}
                                                                        />
                                                                        <div className="text-xs text-center border">{datestamp}</div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {participant_report.behavioural.disc.disc_score.id &&
                                <div className="px-4 border">
                                    <div className="py-1 font-semibold text-lg">DISC</div>
                                    <div className="px-4">
                                        <div className="py-2">
                                            {/** Table Result */}
                                            <div className="flex">
                                                <div className="w-40">Result</div>:&nbsp;
                                                <div className="px-2">
                                                    <table>
                                                        <thead className="bg-slate-300">
                                                            <tr>
                                                                <th className={`capitalize py-1 px-2 border text-center font-semibold bg-slate-400`}></th>
                                                                <th className={`capitalize py-1 px-2 border text-center font-semibold w-8`}>D</th>
                                                                <th className={`capitalize py-1 px-2 border text-center font-semibold w-8`}>I</th>
                                                                <th className={`capitalize py-1 px-2 border text-center font-semibold w-8`}>S</th>
                                                                <th className={`capitalize py-1 px-2 border text-center font-semibold w-8`}>C</th>
                                                                <th className={`capitalize py-1 px-2 border text-center font-semibold w-8`}>*</th>
                                                                <th className={`capitalize py-1 px-2 border text-center font-semibold bg-green-300`}>Control</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td className={`capitalize py-1 px-2 border text-left font-semibold bg-slate-300`}>
                                                                    Most
                                                                </td>
                                                                {Object.values(JSON.parse(participant_report.behavioural.disc.disc_score.result)?.actual?.most).map((am, am_i) => (
                                                                    <td key={am_i} className={`capitalize py-1 px-2 border text-center font-semibold`}>{`${am}`}</td>
                                                                ))}
                                                                <td className={`capitalize py-1 px-2 border text-center font-semibold bg-green-50`}>
                                                                    {sumDisc(JSON.parse(participant_report.behavioural.disc.disc_score.result)?.actual?.most)}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className={`capitalize py-1 px-2 border text-left font-semibold bg-slate-300`}>
                                                                    Least
                                                                </td>
                                                                {Object.values(JSON.parse(participant_report.behavioural.disc.disc_score.result)?.actual?.least).map((al, al_i) => (
                                                                    <td key={al_i} className={`capitalize py-1 px-2 border text-center font-semibold`}>{`${al}`}</td>
                                                                ))}
                                                                <td className={`capitalize py-1 px-2 border text-center font-semibold bg-green-50`}>
                                                                    {sumDisc(JSON.parse(participant_report.behavioural.disc.disc_score.result)?.actual?.least)}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className={`capitalize py-1 px-2 border text-left font-semibold bg-slate-300`}>
                                                                    Change
                                                                </td>
                                                                {Object.values(JSON.parse(participant_report.behavioural.disc.disc_score.result)?.actual?.change).map((ac, ac_i) => (
                                                                    <td key={ac_i} className={`capitalize py-1 px-2 border text-center font-semibold`}>{`${ac}`}</td>
                                                                ))}
                                                                <td className={`capitalize py-1 px-2 border text-center font-semibold bg-green-50`}>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {/** Chart */}
                                            <div className="w-full py-4 flex space-x-20">
                                                <div className="w-3/12">
                                                    <div className="flex justify-center text-sm font-semibold text-center">
                                                        Graph 1 (Most) <br /> Most Mask
                                                    </div>
                                                    <div className="h-[500px]">
                                                        <Chart type='line' data={chartDiscMostData} options={optionsDisc} />
                                                    </div>
                                                </div>
                                                <div className="w-3/12">
                                                    <div className="flex justify-center text-sm font-semibold text-center">
                                                        Graph 2 (Least) <br /> Least Private
                                                    </div>
                                                    <div className="h-[500px]">
                                                        <Chart type='line' data={chartDiscLeastData} options={optionsDisc} />
                                                    </div>
                                                </div>
                                                <div className="w-3/12">
                                                    <div className="flex justify-center text-sm font-semibold text-center">
                                                        Graph 3 (Change) <br /> Perceived Self
                                                    </div>
                                                    <div className="h-[500px]">
                                                        <Chart type='line' data={chartDiscChangeData} options={optionsDisc} />
                                                    </div>
                                                </div>
                                            </div>
                                            {show_time &&
                                                <div className="flex py-2">
                                                    <span className="w-40 block">Waktu</span>:&nbsp;
                                                    <div className="px-2">
                                                        {calculateDiffTime(participant_report.behavioural.disc_session.start_time, participant_report.behavioural.disc_session.end_time, participant_report.behavioural.disc_session.close_by_user)}
                                                    </div>
                                                </div>
                                            }
                                            {/** Snapshot */}
                                            {show_snapshot &&
                                                <>
                                                    <div className="flex pt-2">
                                                        <span className="w-40 block">Screencap</span>:&nbsp;
                                                    </div>
                                                    <div className="py-2">
                                                        <div className="flex flex-wrap justify-left gap-2">
                                                            {participant_report.behavioural.disc_files.map((file, file_i) => {
                                                                const alt = process.env.NODE_ENV === 'development' ? `${file.replace(/\\/g, "-")}` : `${file.replace(/\//g, "-")}`;
                                                                const filepath = process.env.NODE_ENV === 'development' ? file.replace(/\\/g, "/") : file;
                                                                const timestamp = process.env.NODE_ENV === 'development'
                                                                    ? parseInt(file.split("\\")[file.split("\\").length - 1].split(".")[0])
                                                                    : parseInt(file.split("/")[file.split("/").length - 1].split(".")[0]);
                                                                let datestamp: string = "";
                                                                if (isNaN(timestamp) === false) {
                                                                    datestamp = new Date(timestamp * 1000).toString().substring(0, 24);
                                                                }
                                                                return (
                                                                    <div key={`file_${file_i}`}>
                                                                        <img
                                                                            className="w-40"
                                                                            alt={alt}
                                                                            src={`${process.env.REACT_APP_URL}/upload/psikotest_system/get_snapshot/${filepath}`}
                                                                        />
                                                                        <div className="text-xs text-center border">{datestamp}</div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {participant_report.behavioural.resilience.resilience_score.id &&
                                <div className="px-4 border">
                                    <div className="py-1 font-semibold text-lg">RESILIENCE</div>
                                    <div className="px-4">
                                        <div className="py-2">
                                            {/** Table Result */}
                                            <div className="flex">
                                                <div className="w-40">Result</div>:&nbsp;
                                                <div className="px-2">
                                                    <ul>
                                                        <li className="space-x-2">
                                                            <span>Total Answer:</span>
                                                            <span className="font-semibold">{JSON.parse(participant_report.behavioural.resilience.resilience_score.result)?.total_answer ?? 0}</span>
                                                        </li>
                                                        <li className="space-x-2">
                                                            <span>Average:</span>
                                                            <span className="font-semibold">{parseFloat(JSON.parse(participant_report.behavioural.resilience.resilience_score.result).average_speed ?? 0).toFixed(1)}</span>
                                                        </li>
                                                        <li className="space-x-2">
                                                            <span>Incorrect:</span>
                                                            <span className="font-semibold">{JSON.parse(participant_report.behavioural.resilience.resilience_score.result).total_incorrect}</span>
                                                        </li>
                                                        <li className="space-x-2">
                                                            <span>Diff LowHigh-est:</span>
                                                            <span className="font-semibold">{JSON.parse(participant_report.behavioural.resilience.resilience_score.result).difference_lowest_highest}</span>
                                                        </li>
                                                        <li className="space-x-2">
                                                            <span>Answer (per 30 Second):</span>
                                                            <div className="flex flex-wrap pt-2">
                                                                {(JSON.parse(participant_report.behavioural.resilience.resilience_score.result).score_group ?? Array(40).fill(0)).map((sg: TPSResilienceScoreGroupResult, i: number) => (
                                                                    <div key={i} className="min-w-[70px] pr-1 pb-1 text-center">
                                                                        <div className="border bg-slate-300 px-2 py-1 font-semibold">{(i + 1) * 30}</div>
                                                                        <div className="border px-2 py-1">{sg?.total_answer ?? 0}</div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </li>
                                                        <li className="space-x-2">
                                                            <span>Performance Chart :</span>
                                                            <div>
                                                                <div className="h-[300px] w-[60%]">
                                                                    <Chart type='line' data={chartResilienceChangeData} options={optionsResilience} />
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            {show_time &&
                                                <div className="flex py-2">
                                                    <span className="w-40 block">Waktu</span>:&nbsp;
                                                    <div className="px-2">
                                                        {calculateDiffTime(participant_report.behavioural.disc_session.start_time, participant_report.behavioural.disc_session.end_time, participant_report.behavioural.disc_session.close_by_user)}
                                                    </div>
                                                </div>
                                            }
                                            {/** Snapshot */}
                                            {show_snapshot &&
                                                <>
                                                    <div className="flex pt-2">
                                                        <span className="w-40 block">Screencap</span>:&nbsp;
                                                    </div>
                                                    <div className="py-2">
                                                        <div className="flex flex-wrap justify-left gap-2">
                                                            {participant_report.behavioural.disc_files.map((file, file_i) => {
                                                                const alt = process.env.NODE_ENV === 'development' ? `${file.replace(/\\/g, "-")}` : `${file.replace(/\//g, "-")}`;
                                                                const filepath = process.env.NODE_ENV === 'development' ? file.replace(/\\/g, "/") : file;
                                                                const timestamp = process.env.NODE_ENV === 'development'
                                                                    ? parseInt(file.split("\\")[file.split("\\").length - 1].split(".")[0])
                                                                    : parseInt(file.split("/")[file.split("/").length - 1].split(".")[0]);
                                                                let datestamp: string = "";
                                                                if (isNaN(timestamp) === false) {
                                                                    datestamp = new Date(timestamp * 1000).toString().substring(0, 24);
                                                                }
                                                                return (
                                                                    <div key={`file_${file_i}`}>
                                                                        <img
                                                                            className="w-40"
                                                                            alt={alt}
                                                                            src={`${process.env.REACT_APP_URL}/upload/psikotest_system/get_snapshot/${filepath}`}
                                                                        />
                                                                        <div className="text-xs text-center border">{datestamp}</div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    </div >
}

export default ModalParticipantReport;