import reqNoAuth from "../../../services/requestNoAuth";

class AssessmentParticipant {
  async getAssessment(access_key: string) {
    try {
      const res = await reqNoAuth.get("/api/assessment_participant/get_assessment/" + access_key);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async checkKey(access_key: string) {
    try {
      const res = await reqNoAuth.get("/api/assessment_participant/check_key/" + access_key);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async getKabKota() {
    try {
      const res = await reqNoAuth.get("/api/assessment_participant/get_kab_kota");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async getJurusan() {
    try {
      const res = await reqNoAuth.get("/api/assessment_participant/get_jurusan");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async updateParticipant(data: TPParticipantForm) {
    try {
      const res = await reqNoAuth.put("/api/assessment_participant/update_participant/" + data.id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async uploadImage(assessment_participant_id: string, assessment_id: string, data: { base64_image: string }) {
    try {
      const res = await reqNoAuth.post("/api/assessment_participant/upload_image/" + assessment_participant_id + "/" + assessment_id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default AssessmentParticipant;
