import reqNoAuth from "../../../services/requestNoAuth";

class PsikotestParticipant {
  async getAssessment(access_key: string) {
    try {
      const res = await reqNoAuth.get("/api/psikotest_participant/get_assessment/" + access_key);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async getAssessmentParticipant(access_key: string) {
    try {
      const res = await reqNoAuth.get("/api/psikotest_participant/get_assessment_participant/" + access_key);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async updateAssessmentParticipant(data: TPSAssessmentParticipant) {
    try {
      const res = await reqNoAuth.put("/api/psikotest_participant/update_assessment_participant/" + data.access_key, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async uploadAssessmentParticipantPhoto(data: TPSAssessmentParticipant, photo: { base64_image: string }) {
    try {
      const res = await reqNoAuth.post("/api/psikotest_participant/upload_assessment_participant_photo/" + data.access_key, photo);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async updateAssessmentParticipantManual(data: TPSAssessmentParticipant) {
    try {
      const res = await reqNoAuth.post("/api/psikotest_participant/update_assessment_participant_manual/" + data.access_key, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default PsikotestParticipant;
