import { Fragment, useCallback, useContext, useEffect, useState } from "react";

// Icons
import { XMarkIcon } from "@heroicons/react/24/outline";

// Contexts
import LoaderContext from "../../../../context/psikotest/LoaderContext";
import SnackbarContext from "../../../../context/psikotest/SnackbarContext";

// Helpers
import errorHandler from "../../../../helper/psikotest/errorHandler";

// Controller
import AssessmentIstSubtest from "../../../../controller/psikotest/psikotest_system/assessment_ist_subtest"
import Ist from "../../../../controller/psikotest/psikotest_system/ist";
import AssessmentParticipantIstSessionAnswer from "../../../../controller/psikotest/psikotest_system/assessment_participant_ist_session_answer";

function ModalIstSubtestSession(
    props: {
        data?: TPSAssessmentIstSubtest,
        assessment_id: number,
        handleClose: () => void,
        assessment_ist_subtest: TPSAssessmentIstSubtest[],
        isScoring: boolean;
    }) {
    const { setMessage } = useContext(LoaderContext);
    const { setNotif } = useContext(SnackbarContext);
    // state
    const [ist_questionary, setIstQuestionary] = useState<TPSIstQuestionary[]>([])
    const [ist_subtest_session_answer, setIstSubtestSessionAnswer] = useState<TPSAssessmentIstSubtestParticipantAnswerSession[]>([])

    const getDoc = useCallback(
        async function getDoc() {
            try {
                if (props.data) {
                    // fetch list ist_questionary
                    setMessage("Fetch Ist Questionary");
                    const ci = new Ist()
                    let res = await ci.questionary(props.data?.ist_id ?? 0)
                    setIstQuestionary(res.data.list)
                    // fetch ist subtest participant session answer
                    setMessage("Fetch Subtest Participant Session Answer");
                    const cacs = new AssessmentIstSubtest()
                    res = await cacs.ist_answer_session(props.data)
                    setIstSubtestSessionAnswer(res.data.list)
                }
                setMessage("");
            } catch (error) {
                setMessage("");
                const errorMessage = errorHandler(error);
                setNotif({ type: "error", message: errorMessage });
            }
        },
        [setNotif, setMessage, props.data]
    );

    useEffect(() => {
        getDoc();
    }, [getDoc]);


    const handleSaveScore = async (e: React.ChangeEvent<HTMLInputElement>, issa_i: number, issa: TPSAssessmentParticipantIstSessionAnswer) => {
        try {
            // save score
            setMessage("Save Score");
            const capisa = new AssessmentParticipantIstSessionAnswer()
            const updateData = { ...issa, correct: parseInt(e.target.value) }
            await capisa.update_score(updateData)
            const participantAnswer = ist_subtest_session_answer[issa_i].answer
            // get index participant answer
            const indexParticipantAnswer = participantAnswer.findIndex(ca => ca.assessment_participant_ist_session_answer.id === issa.id)
            // update participant answer
            participantAnswer[indexParticipantAnswer].assessment_participant_ist_session_answer = updateData
            // update in user array
            const copyParticipantAnswer = [...ist_subtest_session_answer];
            // update answer 
            copyParticipantAnswer[issa_i].answer = participantAnswer
            setIstSubtestSessionAnswer(copyParticipantAnswer)
            setMessage("");
        } catch (error) {
            setMessage("");
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }

    return <div>
        <div className="fixed z-20 top-0 left-0 w-screen h-screen bg-black opacity-20"></div>
        <div className="fixed z-30 top-0 left-0 w-screen h-screen bg-transparent flex items-center justify-center">
            <div className="flex flex-col bg-white w-screen md:w-[90%] h-[90%] md:rounded">
                <div className="p-4 bg-yellow-800 md:rounded-t text-white flex justify-between">
                    <h3>IST - {props.data?.ist?.subtest_name} - Subtest Session Scoring </h3>
                    <XMarkIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent cursor-pointer" aria-hidden="true" onClick={() => props.handleClose()} />
                </div>
                <div className="w-full h-[94%] px-2 py-5 overflow-hidden">
                    {!props.isScoring &&
                        <div className="my-2 text-center font-semibold text-red-600 bg-red-100 py-4">Score editable only if assessment is scoring process</div>
                    }
                    <div className="w-full h-[92%] overflow-auto">
                        <table className="w-full border-separate border-spacing-0 table-fixed">
                            <thead className="bg-slate-600 text-white sticky top-[0px] z-10">
                                <tr>
                                    <th className="py-3 px-2 w-8 border border-slate-500 sticky left-[0px] bg-slate-600">No</th>
                                    <th className="py-3 px-2 w-64 text-left border border-slate-500 sticky left-[32px] bg-slate-600" colSpan={2}>Participant</th>
                                    {ist_questionary.map((iq, i) => {
                                        return (
                                            <Fragment key={`iq_header_${i}`}>
                                                <th className="py-3 px-2 w-28 text-center border border-slate-500">{`Soal ${i + 1}`}</th>
                                            </Fragment>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {/** Row 1 "Correct Answer" */}
                                <tr className="font-semibold bg-blue-100 sticky top-[46px] z-10">
                                    <td className="py-1 px-2 text-center border border-slate-500 sticky left-0 bg-blue-100">#</td>
                                    <td className="py-1 px-2 text-left border border-slate-500 sticky left-[32px] bg-blue-100" colSpan={2}>Kunci Jawaban</td>
                                    {ist_questionary.map((iq, i) => {
                                        return (
                                            <Fragment key={`iq_header_${i}`}>
                                                <td className="py-1 px-2 text-center border border-slate-500">{iq.correct_answer}</td>
                                            </Fragment>
                                        )
                                    })}
                                </tr>
                                {/** Next Row "Answer & Score" */}
                                {ist_subtest_session_answer.map((issa, issa_i) => {
                                    return (
                                        <Fragment key={`participant_${issa_i}`}>
                                            <tr>
                                                <td className="py-1 px-2 text-center border border-slate-500 sticky left-0 bg-blue-50" rowSpan={2}>{issa_i + 1}</td>
                                                <td className="py-1 px-2 text-left border border-slate-500 sticky left-[32px]  bg-blue-50" rowSpan={2}>{issa.participant.full_name}</td>
                                                <td className="py-1 px-2 text-right border border-slate-500 sticky left-[160px] bg-blue-50">Jawaban</td>
                                                {ist_questionary.map((iq, i) => {
                                                    const participant_answer = issa.answer.find(a => a.ist_questionary.id === iq.id)
                                                    return (
                                                        <Fragment key={`answer_n_score_${i}`}>
                                                            <td className="py-1 px-2 text-center border border-slate-500">{
                                                                participant_answer
                                                                    ? participant_answer?.assessment_participant_ist_session_answer.answer
                                                                    : "N/A"
                                                            }
                                                            </td>
                                                        </Fragment>
                                                    )
                                                })}
                                            </tr>
                                            <tr>
                                                <td className="py-1 px-2 text-right border border-slate-500 sticky left-[160px] bg-blue-50">Nilai</td>
                                                {ist_questionary.map((iq, i) => {
                                                    const participant_answer = issa.answer.find(a => a.ist_questionary.id === iq.id)
                                                    return (
                                                        <Fragment key={`answer_n_score_${i}`}>
                                                            <td className="text-center border border-slate-500">
                                                                {participant_answer
                                                                    ? <input type="number" className="w-16 border pl-2 text-center" min={0} max={props.data?.ist?.answer_type === "input_text" ? 2 : 1}
                                                                        value={participant_answer.assessment_participant_ist_session_answer.correct ?? 0}
                                                                        onChange={(e) => handleSaveScore(e, issa_i, participant_answer.assessment_participant_ist_session_answer)}
                                                                        disabled={!props.isScoring}
                                                                    />
                                                                    : "N/A"}
                                                            </td>
                                                        </Fragment>
                                                    )
                                                })}
                                            </tr>
                                        </Fragment>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ModalIstSubtestSession;