import req from "../../../services/psikotest/request";

class Ist {
  async list() {
    try {
      const res = await req.get("/api/psikotest_system/ist/");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async get(id: string) {
    try {
      const res = await req.get("/api/psikotest_system/ist/" + id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async create(data: TPSIst) {
    try {
      const res = await req.post("/api/psikotest_system/ist/", data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async update(data: TPSIst) {
    try {
      const res = await req.put("/api/psikotest_system/ist/" + data.id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async delete(data: TPSIst) {
    try {
      const res = await req.delete("/api/psikotest_system/ist/", { data });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async questionary(id: number) {
    try {
      const res = await req.get("/api/psikotest_system/ist/" + id + "/questionary");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async questionary_image(id: number) {
    try {
      const res = await req.get("/api/psikotest_system/ist/" + id + "/questionary_image");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async conversion(id: number) {
    try {
      const res = await req.get("/api/psikotest_system/ist/" + id + "/conversion");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default Ist;
