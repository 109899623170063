import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useFieldArray } from "react-hook-form";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Transition } from "@headlessui/react";

// Icons
import {
    PlusIcon,
    EnvelopeIcon,
    UserGroupIcon,
    ArrowUpTrayIcon,
    ClipboardDocumentListIcon,
    XMarkIcon,
    PlusCircleIcon,
    MinusCircleIcon,
    CheckCircleIcon,
    ArrowsPointingOutIcon,
    ArrowDownTrayIcon
} from "@heroicons/react/24/outline";

// Context
import AuthContext from "../../../context/assessment/AuthContext";
import SnackbarContext from "../../../context/assessment/SnackbarContext";
import LoaderContext from "../../../context/assessment/LoaderContext";
import WebSocketContext from "../../../context/WebSocketContext";

// Controller
import Assessment from "../../../controller/assessment/assessment_system/assessment";

// Helper
import errorHandler from "../../../helper/assessment/errorHandler";

// Page
import FormParticipantSnapshot from "./FormParticipantSnapshot";
import FormParticipantReport from "./FormParticipantReport";
import FormParticipantIntray from "./FormParticipantIntray";

// Modals
import FormImportParticipant from "./modal/FormImportParticipant";

type FormValues = {
    participant: TAssessmentParticipant[];
};

const maxParticipant = 200;

const schema = yup.object().shape({
    participant: yup.array().of(
        yup.object().shape({
            id: yup.number().transform((currValue, oldValue) => {
                return oldValue === "" ? undefined : currValue;
            }),
            full_name: yup.string().required(),
            email: yup.string().email().required(),
        })
    ),
});

function FormSession(props: {
    doc: TAssessment;
    isCreate: boolean;
    assessment_id: string;
    editable: boolean;
    isOpenParticipantSession: boolean;
    setIsOpenParticipantSession: (open: boolean) => void;
    setIsOpenParticipantScore: (open: boolean) => void;
}) {
    // context
    const { user } = useContext(AuthContext);
    const { setMessage } = useContext(LoaderContext);
    const { setNotif } = useContext(SnackbarContext);
    const { socket } = useContext(WebSocketContext)
    // is admin
    const isAdmin = user.roles === "1";
    // is qc assessment
    const isQcAssessment = props.doc.qc_id.toString() === user.assessor_id;
    // is pic score
    const [isPicScore, setIsPicScore] = useState<boolean>(false);
    // state assessment session list
    const [assessmentParticipantSessionList, setAssessmentParticipantSessionList] = useState<TAssessmentParticipantSession[]>([]);
    // state modal
    const [isOpenFormImportParticipant, setIsOpenFormImportParticipant] = useState<boolean>(false);
    // helper state
    const [editing, setEditing] = useState<boolean>(false);
    const [isSelectableParticipant, setSelectableParticipant] = useState<boolean>(false);
    const [selectedParticipant, setSelectedParticipant] = useState<number[]>([]);
    const [selectedParticipantSnapshot, setSelectedParticipantSnapshot] = useState<number>();
    const [selectedParticipantReport, setSelectedParticipantReport] = useState<number>();
    const [selectedAssessmentParticipantSession, setSelectedAssessmentParticipantSession] = useState<number>();
    // status
    const isAllowChange = props.doc.status === "0" || props.doc.status === "1";
    const isInProgress = props.doc.status === "1";
    const isScoring = props.doc.status === "2";
    const isCheckScore = props.doc.status === "3" && (isAdmin || isQcAssessment);
    const isCompleted = props.doc.status === "99" && (isAdmin || isQcAssessment);
    // react hook form
    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        reset,
    } = useForm<FormValues>({
        defaultValues: { participant: [] },
        mode: "onBlur",
        resolver: yupResolver(schema),
    });
    const { fields, append, remove } = useFieldArray({
        name: "participant",
        control,
        keyName: "ids",
    });

    // socket event
    socket.onmessage = (event) => {
        const wsdata = JSON.parse(JSON.parse(event.data)?.body ?? "");
        const scope = wsdata?.scope;
        const target = wsdata?.target;
        const action = wsdata?.action;
        if (scope === "assessment" && target === "assessment_system") {
            if (action === "new answer uploaded") {
                getActiveSession();
            }
        }
    };

    const getActiveSession = useCallback(async () => {
        try {
            const ac = new Assessment();
            // fetch participant session list
            const resParticipantSessionList = await ac.listParticipantSession(props.assessment_id);
            const participantSessionList: TAssessmentParticipantSession[] = resParticipantSessionList.data.list;
            setAssessmentParticipantSessionList(participantSessionList);
        } catch (error) {
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }, [props.assessment_id, setNotif]);

    // hide overflow body when modal open
    useEffect(() => {
        document.body.style.overflow = selectedParticipantSnapshot !== undefined || selectedParticipantReport !== undefined ? "hidden" : "auto";
    }, [selectedParticipantSnapshot, selectedParticipantReport]);

    const getDoc = useCallback(
        async function getDoc() {
            try {
                // and make sure when update a participant session && close we re-fetch data
                if (!props.isOpenParticipantSession) {
                    // is not create
                    if (!props.isCreate) {
                        // fetch participant list
                        const ac = new Assessment();
                        setMessage("Fetch Assessment Participant");
                        const resParticipantList = await ac.listParticipant(props.assessment_id);

                        // fetch participant session list
                        setMessage("Fetch Assessment Participant Session");
                        getActiveSession()

                        // setValue not work in here
                        if (resParticipantList.data.list.length > 0) {
                            reset({ participant: resParticipantList.data.list });
                        }
                        // check for pic score
                        if (isAdmin) {
                            setIsPicScore(true);
                        } else {
                            const isIncluded = resParticipantList.data.list.some(
                                (v: TAssessmentParticipant) => v.pic_id?.toString() === user.assessor_id
                            );
                            setIsPicScore(isIncluded);
                        }
                        setMessage("");
                    }
                }
            } catch (error) {
                setMessage("");
                const errorMessage = errorHandler(error);
                setNotif({ type: "error", message: errorMessage });
            }
        },
        [
            setNotif,
            setMessage,
            props.assessment_id,
            props.isCreate,
            reset,
            props.isOpenParticipantSession,
            isAdmin,
            user.assessor_id,
            getActiveSession
        ]
    );

    useEffect(() => {
        getDoc();
    }, [getDoc]);

    const onSubmit = (data: FormValues) => {
        setEditing(false);
        handleChange(data.participant);
    };

    const handleChange = (participants: TAssessmentParticipant[]) => {
        participants.forEach(async (participant, i) => {
            const participantPrev = fields[i];
            const participantCurr = participant;
            // make sure changed occured
            const isChanged =
                participantPrev.full_name !== participantCurr.full_name || participantPrev.email !== participantCurr.email;
            if (isChanged) {
                try {
                    setMessage("Save Participant");
                    const ac = new Assessment();
                    // check if have id
                    if (participant.id) {
                        await ac.updateParticipant(participant.id.toString(), participant);
                    } else {
                        const resCreate = await ac.addParticipant(props.assessment_id, participant);
                        let newParticipants = [...participants];
                        newParticipants[i] = {
                            ...newParticipants[i],
                            id: resCreate.data.saved_id,
                        };
                        setValue("participant", newParticipants);
                    }
                    setMessage("");
                } catch (error) {
                    setMessage("");
                    const errorMessage = errorHandler(error);
                    setNotif({ type: "error", message: errorMessage });
                }
            }
        });
    };

    const handleOpenParticipantSession = () => {
        props.setIsOpenParticipantSession(true);
    };

    const handleRemove = async (i: number) => {
        try {
            const confirm = await Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            });
            if (confirm.isConfirmed) {
                // to reset button save if remove new item without submit
                setEditing(false);
                // start remove
                setMessage("Remove Participant");
                const ac = new Assessment();
                const removeParticipantId = fields[i].id?.toString();
                if (removeParticipantId) {
                    await ac.deleteParticipant(removeParticipantId);
                }
                remove(i);
                setMessage("");
            }
        } catch (error) {
            setMessage("");
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    };

    const handleSelectAll = () => {
        const selectAllParticipant: number[] = [];
        fields.forEach((field) => selectAllParticipant.push(field?.id ?? 0));
        setSelectedParticipant(selectAllParticipant);
    };

    const handleUnselectAll = () => {
        setSelectedParticipant([]);
    };

    const handleSelectParticipant = (participant_id: number, isSelected: boolean) => {
        const copySelectedParticipant = [...selectedParticipant];
        if (isSelected) {
            const indexSelected = copySelectedParticipant.findIndex((v) => v === participant_id);
            copySelectedParticipant.splice(indexSelected, 1);
        } else {
            copySelectedParticipant.push(participant_id);
        }
        setSelectedParticipant(copySelectedParticipant);
    };

    const handleSendInvitation = async () => {
        try {
            // start send invitation
            setMessage("Send Invitation");
            const ac = new Assessment();
            const participantData: (TAssessmentParticipant | undefined)[] = selectedParticipant.map((participant_id) => {
                return getValues("participant").find((field) => field.id === participant_id);
            });
            // use foreach filter not compatible with type check value undefined
            const filterParticipantData: TAssessmentParticipant[] = [];
            participantData.forEach((v) => {
                if (v !== undefined) {
                    filterParticipantData.push(v);
                }
            });
            const submitData = { participant: filterParticipantData, assessment: props.doc };
            await ac.sendInvitation(submitData);
            setMessage("");
            // reset selectable participant
            setSelectableParticipant(false);
        } catch (error) {
            setMessage("");
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    };


    // handleRefreshAfterImport
    const handleRefreshAfterImport = () => {
        // close import form modal
        setIsOpenFormImportParticipant(false)
        // refresh data participant
        getDoc()
    }

    return (
        <div>
            {/* Import Participant Form */}
            <Transition
                as={Fragment}
                show={isOpenFormImportParticipant}
                enter="ease-out duration-50"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-50"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div>
                    <FormImportParticipant
                        assessment_id={props.assessment_id}
                        handleClose={() => setIsOpenFormImportParticipant(false)}
                        handleRefreshAfterImport={handleRefreshAfterImport}
                    />
                </div>
            </Transition>
            {fields.length < maxParticipant && props.editable && (
                <div className="flex flex-col md:flex-row w-full md:w-full mb-2 space-y-0.5 md:space-y-0 md:space-x-0.5">
                    {/* Add Participant */}
                    {!editing && isAllowChange && !isSelectableParticipant && isAdmin && (
                        <div className="flex space-x-1">
                            <button type="button"
                                onClick={() => { setIsOpenFormImportParticipant(true) }}
                                className="flex items-center bg-green-600 hover:bg-green-700 px-4 py-2 text-white rounded">
                                <ClipboardDocumentListIcon className="w-4" />
                                <span className="block pl-1">Import</span>
                            </button>
                            <button
                                type="button"
                                onClick={() =>
                                    append({
                                        full_name: "",
                                        email: "",
                                    })
                                }
                                className="block w-auto px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded font-semibold text-sm"
                            >
                                <div className="flex">
                                    <PlusIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
                                    Participant
                                </div>
                            </button>
                        </div>
                    )}
                    {/* Save */}
                    {editing && isAdmin && (
                        <button
                            type="button"
                            className="block w-auto px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded font-semibold text-sm"
                        >
                            <div className="flex">
                                <ArrowUpTrayIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
                                Save
                            </div>
                        </button>
                    )}
                    {/* Open Form Participant Session */}
                    {!editing && (props.doc.status === "0" || props.doc.status === "1") && !isSelectableParticipant && isAdmin && (
                        <button
                            type="button"
                            onClick={() => handleOpenParticipantSession()}
                            className="block w-auto px-4 py-2 bg-cyan-600 hover:bg-cyan-800 text-white rounded font-semibold text-sm"
                        >
                            <div className="flex">
                                <UserGroupIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
                                Session
                            </div>
                        </button>
                    )}
                    {/* Send Invitation */}
                    {!editing && isInProgress && !isSelectableParticipant && isAdmin && (
                        <button
                            type="button"
                            onClick={() => setSelectableParticipant(true)}
                            className="block w-auto px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded font-semibold text-sm"
                        >
                            <div className="flex">
                                <EnvelopeIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
                                Send Invitation
                            </div>
                        </button>
                    )}
                    {isInProgress && isSelectableParticipant && isAdmin && (
                        <>
                            <button
                                type="button"
                                onClick={() => setSelectableParticipant(false)}
                                className="block w-auto px-4 py-2 bg-gray-500 hover:bg-gray-700 text-white rounded font-semibold text-sm"
                            >
                                <div className="flex">
                                    <XMarkIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
                                    Cancel Select
                                </div>
                            </button>
                            {selectedParticipant.length !== fields.length && (
                                <button
                                    type="button"
                                    onClick={() => handleSelectAll()}
                                    className="block w-auto px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded font-semibold text-sm"
                                >
                                    <div className="flex">
                                        <CheckCircleIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
                                        Select All
                                    </div>
                                </button>
                            )}
                            {selectedParticipant.length > 0 && (
                                <button
                                    type="button"
                                    onClick={() => handleUnselectAll()}
                                    className="block w-auto px-4 py-2 bg-slate-500 hover:bg-slate-700 text-white rounded font-semibold text-sm"
                                >
                                    <div className="flex">
                                        <MinusCircleIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
                                        Unselect All
                                    </div>
                                </button>
                            )}
                            {selectedParticipant.length > 0 && (
                                <button
                                    type="button"
                                    onClick={() => handleSendInvitation()}
                                    className="block w-auto px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded font-semibold text-sm"
                                >
                                    <div className="flex">
                                        <EnvelopeIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
                                        Send Invitation
                                    </div>
                                </button>
                            )}
                        </>
                    )}
                    {((isScoring && isPicScore) || isCheckScore || isCompleted) && (
                        <button
                            type="button"
                            onClick={() => props.setIsOpenParticipantScore(true)}
                            className="block w-auto px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded font-semibold text-sm"
                        >
                            <div className="flex">
                                <ClipboardDocumentListIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
                                Scoring
                            </div>
                        </button>
                    )}
                </div>
            )}
            <div className="w-full mb-1 hidden md:flex">
                <div className="w-full md:w-1/12 px-3 py-2 border rounded bg-slate-600 text-white text-center">No</div>
                {isSelectableParticipant && (
                    <div className="w-full md:w-1/12 px-3 py-2 border rounded bg-slate-600 text-white text-center">Select</div>
                )}
                <div className="w-full md:w-3/12 px-3 py-2 border rounded bg-slate-600 text-white">Full Name</div>
                <div className="w-full md:w-3/12 px-3 py-2 border rounded bg-slate-600 text-white">Email</div>
                <div className="w-full md:w-2/12 px-3 py-2 border rounded bg-slate-600 text-white">PIC</div>
                <div className="w-full md:w-3/12 px-3 py-2 border rounded bg-slate-600 text-white">Status Assessment</div>
                {parseInt(props.doc.status) > 1 && (
                    <div className="w-full md:w-1/12 px-3 py-2 border rounded bg-yellow-600 text-white text-center">Snapshot</div>
                )}
                {parseInt(props.doc.status) > 1 && (isAdmin || isQcAssessment) && (
                    <div className="w-full md:w-1/12 px-3 py-2 border rounded bg-green-600 text-white text-center">Report</div>
                )}
                {props.editable && isAdmin && (
                    <div className="w-full md:w-1/12 px-3 py-2 border rounded bg-red-500 text-white text-center">Remove</div>
                )}
            </div>
            <form onBlur={() => handleSubmit(onSubmit)()}>
                {fields.map((field, i) => {
                    const isSelected = selectedParticipant.some((s) => s === field.id);
                    const sessionList = assessmentParticipantSessionList.filter(
                        (aps) => field.id === aps.assessment_participant_id
                    )
                    // sort by level index in assessment level category
                    sessionList.sort((a: TAssessmentParticipantSession, b: TAssessmentParticipantSession) => a?.level_index - b?.level_index);
                    return (
                        <div key={`participant_${i}`} className="w-full mb-0.5 md:flex">
                            <div className="w-full flex items-center md:w-1/12 px-3 py-2 border rounded bg-slate-600 text-white md:justify-center">
                                {i + 1}
                            </div>
                            {isSelectableParticipant && isAdmin && (
                                <button
                                    type="button"
                                    className={
                                        (isSelected ? "bg-green-200" : "bg-slate-200") +
                                        " w-full md:w-1/12 px-3 py-2 border rounded bg-white flex justify-center items-center"
                                    }
                                    onClick={() => handleSelectParticipant(field?.id ?? 0, isSelected)}
                                >
                                    {isSelected ? (
                                        <MinusCircleIcon className="w-5 text-green-900" />
                                    ) : (
                                        <PlusCircleIcon className="w-5 text-slate-900" />
                                    )}
                                </button>
                            )}
                            <div className="w-full md:w-3/12 border-x border-white">
                                <div className="w-full h-full flex items-center bg-gray-200 text-gray-700 border rounded py-2 px-3">
                                    <input
                                        className={
                                            (errors?.participant?.[i]?.full_name ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                                            " appearance-none first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-800"
                                        }
                                        onFocus={() => setEditing(true)}
                                        disabled={!isAdmin}
                                        {...register(`participant.${i}.full_name`)}
                                    />
                                </div>
                            </div>
                            <div className={"w-full md:w-3/12 border-x border-white"}>
                                <div className="w-full h-full flex items-center bg-gray-200 text-gray-700 border rounded py-2 px-3">
                                    <input
                                        type="email"
                                        className={
                                            (errors?.participant?.[i]?.email ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                                            " appearance-none first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-800"
                                        }
                                        onFocus={() => setEditing(true)}
                                        disabled={!isAdmin}
                                        {...register(`participant.${i}.email`)}
                                    />
                                </div>
                            </div>
                            <div className={"w-full md:w-2/12 border-x border-white"}>
                                <div className="w-full h-full flex items-center bg-gray-200 text-gray-700 border rounded py-2 px-6">
                                    {field.pic_full_name ?? "-"}
                                </div>
                            </div>
                            <div className={"w-full md:w-3/12 border-x border-white"}>
                                <div className="w-full h-full flex flex-col space-y-1 bg-gray-50 text-gray-700 border rounded py-1 px-6">
                                    {sessionList.map((sl, i_sl) => (
                                        <div key={`sl_${i_sl}`} >
                                            {sl.category.includes("In-Tray Simulation") ? (<button
                                                type="button"
                                                className="w-full flex items-center rounded px-2 hover:bg-blue-50 text-blue-500 hover:text-blue-800 border border-blue-300"
                                                onClick={() => setSelectedAssessmentParticipantSession(sl.id)}
                                            >
                                                {sl.category}
                                                <ArrowsPointingOutIcon className="w-4 ml-auto" />
                                            </button>) : (
                                                <>
                                                    {sl.file_path === null ? (
                                                        <div className={`flex items-center rounded px-1 border border-gray-300`}>
                                                            <ArrowDownTrayIcon className="w-4 mr-1" />
                                                            {sl.category}
                                                        </div>
                                                    ) : (
                                                        <Link
                                                            to={`/upload/assessment_system/get_answer/${sl.file_path}`}
                                                            target="_blank"
                                                            className={`flex items-center rounded px-1 hover:bg-green-100 text-green-600 hover:text-green-800 border border-green-600`}
                                                        >
                                                            <ArrowDownTrayIcon className="w-4 mr-1" />
                                                            {sl.category}
                                                        </Link>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {parseInt(props.doc.status) > 1 && (
                                <button
                                    type="button"
                                    className="w-full md:w-1/12 px-3 py-2 border rounded bg-yellow-600 hover:bg-yellow-700 text-white text-center cursor-pointer"
                                    onClick={() => setSelectedParticipantSnapshot(field.id)}
                                >
                                    Snapshot
                                </button>
                            )}
                            {parseInt(props.doc.status) > 1 && (isAdmin || isQcAssessment) && (
                                <button
                                    type="button"
                                    className="w-full md:w-1/12 px-3 py-2 border rounded bg-green-600 hover:bg-green-700 text-white text-center cursor-pointer"
                                    onClick={() => setSelectedParticipantReport(field.id)}
                                >
                                    Report
                                </button>
                            )}
                            {props.editable && isAdmin && (
                                <button
                                    type="button"
                                    className="w-full md:w-1/12 px-3 py-2 border rounded bg-red-500 hover:bg-red-700 text-white text-center cursor-pointer"
                                    onClick={() => handleRemove(i)}
                                >
                                    Remove
                                </button>
                            )}
                        </div>
                    );
                })}
            </form>
            {selectedParticipantSnapshot !== undefined && (
                <FormParticipantSnapshot
                    selectedParticipantSnapshot={selectedParticipantSnapshot}
                    setSelectedParticipantSnapshot={setSelectedParticipantSnapshot}
                />
            )}
            {selectedParticipantReport !== undefined && (
                <FormParticipantReport
                    selectedParticipantReport={selectedParticipantReport}
                    setSelectedParticipantReport={setSelectedParticipantReport}
                />
            )}
            {selectedAssessmentParticipantSession !== undefined && (
                <FormParticipantIntray
                    selectedAssessmentParticipantSession={selectedAssessmentParticipantSession}
                    setSelectedAssessmentParticipantSession={setSelectedAssessmentParticipantSession}
                    assessmentParticipantSessionList={assessmentParticipantSessionList}
                />
            )}
        </div>
    );
}

export default FormSession;
