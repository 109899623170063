
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import Swal from "sweetalert2";

// Icons
import { PlusIcon } from "@heroicons/react/24/outline";

// Context
import SnackbarContext from "../../../context/psikotest/SnackbarContext";
import LoaderContext from "../../../context/psikotest/LoaderContext";

// Helper
import errorHandler from "../../../helper/psikotest/errorHandler";

// Controller
import Rac from "../../../controller/psikotest/psikotest_system/rac";
import RacQuestionary from "../../../controller/psikotest/psikotest_system/rac_questionary";

// Modal
import FormQuestionary from "./modal/FormQuestionary";

const maxQuestionary = 40;

function ListQuestionary(props: { rac: TPSRac; editable: boolean }) {
    const { setMessage } = useContext(LoaderContext);
    const { setNotif } = useContext(SnackbarContext);
    // state list questionary
    const [questionary, setQuestionary] = useState<TPSRacQuestionary[]>([])
    // state modal
    const [isOpenFormQuestionary, setIsOpenFormQuestionary] = useState<boolean>(false);
    // state other
    const [selectedRacQuestionary, setSelectedRacQuestionary] = useState<TPSRacQuestionary | undefined>(undefined);

    const getDoc = useCallback(
        async function getDoc() {
            try {
                // fetch rac_qestionary list
                setMessage("Fetch Rac Questionary");
                const cc = new Rac();
                const res = await cc.questionary();
                const list: TPSRacQuestionary[] = res.data.list;
                setQuestionary(list);

                setMessage("");
            } catch (error) {
                setMessage("");
                const errorMessage = errorHandler(error);
                setNotif({ type: "error", message: errorMessage });
            }
        },
        [setNotif, setMessage]
    );

    useEffect(() => {
        getDoc();
    }, [getDoc]);

    // when selectedRacQuestionary changes and not undefined open Rac Questionary form to update
    useEffect(() => {
        if (selectedRacQuestionary !== undefined) {
            setIsOpenFormQuestionary(true)
        }
    }, [selectedRacQuestionary]);

    // when isOpenFormQuestionary changes and false reset selectedRacQuestionary
    useEffect(() => {
        if (!isOpenFormQuestionary) {
            setSelectedRacQuestionary(undefined)
            // re-fetch data
            getDoc();
        }
    }, [isOpenFormQuestionary, getDoc]);

    // handle delete
    const confirmDelete = useCallback(
        async (rac_questionary: TPSRacQuestionary) => {
            try {
                const confirm = await Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                });
                if (confirm.isConfirmed) {
                    const ccq = new RacQuestionary();
                    await ccq.delete(rac_questionary);
                    setNotif({ type: "success", message: "rac deleted" });
                    // re-fetch data
                    getDoc();
                }
            } catch (error) {
                const errorMessage = errorHandler(error);
                setNotif({ type: "error", message: errorMessage });
            }
        },
        [setNotif, getDoc]
    );

    return (
        <div className="pb-[40rem]">
            {/* Questionary Form */}
            <Transition
                as={Fragment}
                show={isOpenFormQuestionary}
                enter="ease-out duration-50"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-50"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div>
                    <FormQuestionary nextNumber={questionary[questionary.length - 1]?.number} data={selectedRacQuestionary} rac={props.rac} handleClose={() => setIsOpenFormQuestionary(false)} />
                </div>
            </Transition>
            {questionary.length < maxQuestionary && props.editable && (
                <div className="w-full md:w-full mb-2">
                    <button
                        type="button"
                        onClick={() => { setIsOpenFormQuestionary(true) }}
                        className="block w-auto px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded font-semibold text-sm"
                    >
                        <div className="flex">
                            <PlusIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
                            Questionary
                        </div>
                    </button>
                </div>
            )}
            <div className="w-full flex flex-wrap flex-row justify-evenly">
                {questionary.map((q, i) => {
                    return (
                        <div key={`questionary_${i}`}
                            className="w-full lg:w-52 flex flex-col justify-between m-2 border"
                        >
                            <div className="relative flex flex-grow items-center">
                                <div className="absolute text-xs top-1 right-1 bg-green-600 p-1 rounded text-white opacity-90">
                                    Q. Number {q.number}
                                </div>
                                <img className="w-full pt-6" src={`${process.env.REACT_APP_URL}/upload/psikotest_system/get_rac_questionary/${q.question_image}`} alt={q.question_image}></img>
                            </div>
                            <div className="px-2 py-4 text-center bg-slate-50 border-t">
                                <span className="block text-xs mb-2">Options</span>
                                <div className="font-bold mb-2 space-x-1">
                                    {q.answer_list.split(",").map((v, i) =>
                                        <span key={`option_${i}`}>
                                            {v !== "" ? <span className={`py-1 px-2 rounded text-white ${q.correct_answer.split(",").includes(v) ? "bg-green-700" : "bg-slate-600"}`}>{v}</span> : "N/A"}
                                        </span>
                                    )}
                                </div>
                                <span className="block text-xs mb-1">Action</span>
                                <div className="space-x-1">
                                    <button type="button" className="px-2 py-1 rounded bg-blue-500 text-white" onClick={() => setSelectedRacQuestionary(q)}>
                                        Edit
                                    </button>
                                    <button type="button" className="px-2 py-1 rounded bg-red-500 text-white" onClick={() => confirmDelete(q)}>
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default ListQuestionary;
