import { useCallback, useContext, useState, useEffect, useMemo, Fragment } from "react";
import { useMediaQuery } from "react-responsive";
import Swal from "sweetalert2";
import { Transition } from "@headlessui/react";

// Icons
import { ClipboardDocumentListIcon, PlusIcon, TrashIcon, } from "@heroicons/react/24/outline";

// Context
import SnackbarContext from "../../../context/psikotest/SnackbarContext";
import LoaderContext from "../../../context/psikotest/LoaderContext";

// Helper
import errorHandler from "../../../helper/psikotest/errorHandler";
import logRender from "../../../helper/psikotest/logRender";

// Controller
import PapiQuestionary from "../../../controller/psikotest/psikotest_system/papi_questionary";

// Component
import TableFilter from "../../../components/psikotest/psikotest_system/TableFilter";
import Table from "../../../components/psikotest/psikotest_system/Table";

// Modals
import FormQuestionary from "./modal/FormQuestionary";
import FormImportQuestionary from "./modal/FormImportQuestionary";

// Helper Value
const valueList = ['N', 'G', 'A', 'L', 'P', 'I', 'T', 'V', 'X', 'S', 'B', 'O', 'R', 'D', 'C', 'Z', 'E', 'K', 'F', 'W'];

function List() {
  const { setMessage } = useContext(LoaderContext);
  const { setNotif } = useContext(SnackbarContext);
  // required state
  const [list, setList] = useState<Array<TPSPapiQuestionary>>([]);
  const [search, setSearch] = useState<string>("");
  // state modal
  const [isOpenFormQuestionary, setIsOpenFormQuestionary] = useState<boolean>(false);
  const [isOpenFormImportQuestionary, setIsOpenFormImportQuestionary] = useState<boolean>(false);
  // state other
  const [selected_papi_questionary, setSelectedPapiQuestionary] = useState<TPSPapiQuestionary | undefined>(undefined);

  useEffect(() => {
    logRender({ type: "page", name: "papi_questionary/List" });
  }, []);

  const getList = useCallback(async () => {
    try {
      setMessage("Fetch Papi Questionary");
      const papi_qc = new PapiQuestionary();
      const res = await papi_qc.list();
      setList(res.data.list);
      setMessage("");
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  }, [setNotif, setMessage]);

  useEffect(() => {
    getList();
  }, [getList]);

  const handleDeleteList = (id: number) => {
    const arrFilter = [...list].filter((v) => v.id !== id);
    setList(arrFilter);
  };

  // when selected_papi_questionary changes and not undefined open Cfit Questionary form to update
  useEffect(() => {
    if (selected_papi_questionary !== undefined) {
      setIsOpenFormQuestionary(true)
    }
  }, [selected_papi_questionary]);

  // handleRefreshAfterOpenForm
  const handleRefreshAfterOpenForm = () => {
    // rmove selected value will close form modal
    setSelectedPapiQuestionary(undefined)
    // close modal
    setIsOpenFormQuestionary(false)
    // re-fetch data
    getList();
  }

  // handleRefreshAfterImport
  const handleRefreshAfterImport = () => {
    // close import form modal
    setIsOpenFormImportQuestionary(false)
    // re-fetch data
    getList();
  }

  return (
    <div className="w-full flex flex-col space-y-0.5">
      {/* Import Questionary Form */}
      <Transition
        as={Fragment}
        show={isOpenFormImportQuestionary}
        enter="ease-out duration-50"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-50"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div>
          <FormImportQuestionary
            nextNumber={list.length + 1}
            handleClose={() => setIsOpenFormImportQuestionary(false)}
            handleRefreshAfterImport={handleRefreshAfterImport}
          />
        </div>
      </Transition>
      {/* Questionary Form */}
      <Transition
        as={Fragment}
        show={isOpenFormQuestionary}
        enter="ease-out duration-50"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-50"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div>
          <FormQuestionary data={selected_papi_questionary} nextNumber={list.length + 1} handleClose={() => handleRefreshAfterOpenForm()} />
        </div>
      </Transition>
      <div className="flex flex-col md:flex-row items-start md:items-center">
        <div className="flex space-x-1">
          <button type="button"
            onClick={() => { setIsOpenFormImportQuestionary(true) }}
            className="block w-auto mb-2 md:m-0 px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded font-semibold text-sm"
          >
            <div className="flex">
              <ClipboardDocumentListIcon className="w-4" />
              <span className="block pl-1">Import</span>
            </div>
          </button>
          <button
            type="button"
            onClick={() => { setIsOpenFormQuestionary(true) }}
            className="block w-auto mb-2 md:m-0 px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded font-semibold text-sm"
          >
            <div className="flex">
              <PlusIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
              Create
            </div>
          </button>
        </div>
        <div className="w-full md:w-auto ml-auto flex">
          <TableFilter search={setSearch} />
        </div>
      </div>
      <div>
        <h4 className="font-semibold my-2 text-center">Control value <br />(Total Questionary: {list.length}/90)</h4>
        <div className="flex flex-wrap">
          {valueList.map((v, i) => (
            <div key={i} className="w-1/2 md:w-1/5 p-1">
              <div
                className={`
                  flex border border-dashed font-bold rounded p-1 px-2 
                  ${(list.filter(l => l.statement_a_value === v).length + list.filter(l => l.statement_b_value === v).length) === 9
                    ? "border-green-400 bg-green-100"
                    : "border-slate-400 bg-slate-100"
                  }
                `}
              >
                <div className="w-2/4">{v}</div>
                <div className="w-2/4 flex justify-end">
                  {list.filter(l => l.statement_a_value === v).length + list.filter(l => l.statement_b_value === v).length}/9
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div>
        <PapiQuestionaryList list={list} handleDeleteList={handleDeleteList} search={search} setSelectedPapiQuestionary={setSelectedPapiQuestionary} />
      </div>
    </div >
  );
}

function PapiQuestionaryList(props: {
  list: Array<TPSPapiQuestionary>;
  handleDeleteList: (id: number) => void;
  search: string,
  setSelectedPapiQuestionary: React.Dispatch<React.SetStateAction<TPSPapiQuestionary | undefined>>
}) {
  const setNotif = useContext(SnackbarContext).setNotif;
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const confirmDelete = useCallback(
    async (papi_q: TPSPapiQuestionary) => {
      try {
        const confirm = await Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        });
        if (confirm.isConfirmed) {
          const papi_qc = new PapiQuestionary();
          await papi_qc.delete(papi_q.id);
          props.handleDeleteList(papi_q.id);
          setNotif({ type: "success", message: "papi questionary deleted" });
        }
      } catch (error) {
        const errorMessage = errorHandler(error);
        setNotif({ type: "error", message: errorMessage });
      }
    },
    [props, setNotif]
  );

  const columns = useMemo(
    () => [
      {
        id: "mobile",
        Header: "Number",
        accessor: "number",
        show: isMobile,
        Cell: (colProps: { row: { original: TPSPapiQuestionary } }) => {
          return (
            <button className="text-blue-500 hover:text-blue-800" onClick={() => props.setSelectedPapiQuestionary(colProps.row.original)}>
              {colProps.row.original.number || "NULL"}
            </button>
          );
        },
      },
      {
        id: "number",
        classHeader: "text-left px-2 w-20",
        Header: <div title="Number">Number</div>,
        classDivHeader: "flex items-center",
        accessor: "number",
        show: !isMobile,
        Cell: (colProps: { row: { original: TPSPapiQuestionary } }) => {
          return (
            <button className="flex items-center text-blue-500 hover:text-blue-800" onClick={() => props.setSelectedPapiQuestionary(colProps.row.original)}>
              {colProps.row.original.number}
            </button>
          );
        },
      },
      {
        id: "statement_a",
        classHeader: "text-left px-2",
        Header: <div title="Statement A">Statement A</div>,
        classDivHeader: "flex items-center",
        accessor: "statement_a",
        show: !isMobile,
        Cell: (props: { row: { original: TPSPapiQuestionary } }) => {
          return props.row.original.statement_a
        },
      },
      {
        id: "statement_a_value",
        classHeader: "text-left px-2",
        Header: <div title="Statement A Value">Value</div>,
        classDivHeader: "flex items-center",
        accessor: "statement_a_value",
        show: !isMobile,
        Cell: (props: { row: { original: TPSPapiQuestionary } }) => {
          return props.row.original.statement_a_value
        },
      },
      {
        id: "statement_b",
        classHeader: "text-left px-2",
        Header: <div title="Statement B">Statement B</div>,
        classDivHeader: "flex items-center",
        accessor: "statement_b",
        show: !isMobile,
        Cell: (props: { row: { original: TPSPapiQuestionary } }) => {
          return props.row.original.statement_b
        },
      },
      {
        id: "statement_b_value",
        classHeader: "text-left px-2",
        Header: <div title="Statement B Value">Value</div>,
        classDivHeader: "flex items-center",
        accessor: "statement_b_value",
        show: !isMobile,
        Cell: (props: { row: { original: TPSPapiQuestionary } }) => {
          return props.row.original.statement_b_value
        },
      },
      {
        id: "action_delete",
        classHeader: "w-[30px] text-white",
        Header: () => {
          return <TrashIcon className="h-5 w-auto" />;
        },
        classDivHeader: "flex justify-center",
        accessor: "id",
        show: !isMobile,
        disableSortBy: true,
        className: "text-white bg-red-500 hover:bg-red-800",
        Cell: (props: { row: { original: TPSPapiQuestionary } }) => {
          return (
            <button className="flex justify-center" onClick={() => confirmDelete(props.row.original)}>
              <TrashIcon className="h-5" />
            </button>
          );
        },
      },
    ],
    [isMobile, confirmDelete, props]
  );

  return <Table columns={columns} list={props.list} search={props.search} />;
}

export default List;
