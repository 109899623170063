function Loading() {
  return (
    <div className="h-screen flex flex-col items-center justify-center">
      <svg className="animate-spin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48pt" height="48pt">
        <defs>
          <clipPath id="_clipPath_WRIDVTZ4GwuhUCb4UVH0ImFW9W2oYFcM">
            <rect width="48" height="48" />
          </clipPath>
        </defs>
        <g clipPath="url(#_clipPath_WRIDVTZ4GwuhUCb4UVH0ImFW9W2oYFcM)">
          <circle
            vectorEffect="non-scaling-stroke"
            cx="24"
            cy="23.999999999999996"
            r="21"
            fill="none"
            strokeWidth="4"
            stroke="rgb(59 130 246)"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            className="opacity-25"
          />
          <path
            d=" M 3 24 C 2.998 12.409 12.409 3 24 3 C 28.894 3 33.399 4.677 36.971 7.488"
            fill="none"
            vectorEffect="non-scaling-stroke"
            strokeWidth="3"
            stroke="rgb(59 130 246)"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            className="opacity-100"
          />
        </g>
      </svg>
      <label className="text-blue-900 mt-1">
        <span>Checking your session . . .</span>
      </label>
    </div>
  );
}

export default Loading;
