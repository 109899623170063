import req from "../../../services/psikotest/request";

class Srq20 {
  async get() {
    try {
      const res = await req.get("/api/psikotest_system/mmpi/");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async update(data: TPSSrq20) {
    try {
      const res = await req.put("/api/psikotest_system/mmpi/", data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default Srq20;
