import TopNavigation from "../../components/psikotest/psikotest_system/TopNavigation";
import MenuDesktop from "../../components/psikotest/psikotest_system/MenuDesktop";
import PsikotestSystemRoute from "./PsikotestSystemRoute";

function PsikotestSystemLayout() {
  return (
    <div className="w-full overflow-hidden">
      <TopNavigation />
      <div className="flex max-w-8xl mx-auto px-4 sm:px-6 md:px-8" style={{ height: "calc(100vh - 65px)" }}>
        <section className="hidden md:block w-1/6 m-1 p-2 h-full overflow-auto">
          <MenuDesktop />
        </section>
        <section className="flex w-full md:w-5/6 h-full overflow-x-hidden overflow-auto px-2">
          <PsikotestSystemRoute />
        </section>
      </div>
    </div>
  );
}

export default PsikotestSystemLayout;
