import req from "../../../services/assessment/request";

class Company {
  async list() {
    try {
      const res = await req.get("/api/assessment_system/company/");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async get(id: string) {
    try {
      const res = await req.get("/api/assessment_system/company/" + id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async save(data: TCompany) {
    try {
      const res = await req.put("/api/assessment_system/company/" + data.id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async delete(data: TCompany) {
    try {
      const res = await req.delete("/api/assessment_system/company/", { data });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async listCompetency(id: string) {
    try {
      const res = await req.get("/api/assessment_system/company/" + id + "/competency");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async addCompetency(id: string, data: TCompanyCompetency) {
    try {
      const res = await req.post("/api/assessment_system/company/" + id + "/competency", data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async updateCompetency(data: TCompanyCompetency) {
    try {
      const res = await req.put("/api/assessment_system/company/competency/" + data.id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async deleteCompetency(company_competency_id: string) {
    try {
      const res = await req.delete("/api/assessment_system/company/competency/" + company_competency_id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default Company;
