import { useCallback, useContext, useEffect, useState } from "react";

// Context
import SnackbarContext from "../../../context/psikotest/SnackbarContext";

// Handler
import errorHandler from "../../../helper/psikotest/errorHandler";

// Controller
import ParticipantSession from "../../../controller/psikotest/psikotest_participant/session";
import { CheckCircleIcon, ClockIcon } from "@heroicons/react/24/outline";

function SubtestBrs(props: { assessment_participant_session: TPPAssessmentParticipantSession, closeSession: () => void }) {
    // Context
    const { setNotif } = useContext(SnackbarContext);

    // state
    const [brs, setBrs] = useState<TPSBrs>()
    const [brs_questionary, setBrsQuestionary] = useState<TPSBrsQuestionary[]>([])
    const [brs_questionary_answer, setBrsQuestionaryAnswer] = useState<TPSAssessmentParticipantBrsSessionAnswer>()
    const [answer_questionary, setAnswerQuestionary] = useState<{ number: Number, answer: string }[]>([])

    // get doc
    const getDoc = useCallback(async () => {
        try {
            // get all question in subtest id
            const psc = new ParticipantSession();
            let resBrs = await psc.getBrs();
            setBrs(resBrs.data.row)
            let resQuestionaryBrs = await psc.getQuestionaryBrs();
            setBrsQuestionary(resQuestionaryBrs.data.list)
            let resQuestionaryAnswerBrs = await psc.getQuestionaryAnswerBrs(props.assessment_participant_session.id)
            setBrsQuestionaryAnswer(resQuestionaryAnswerBrs.data.row)
            // set answer questionary
            if ((JSON.parse(resQuestionaryAnswerBrs.data.row.answer)).length === 0) {
                // create empty object with same length of brs questionary
                setAnswerQuestionary(resQuestionaryBrs.data.list.map((q: TPSBrsQuestionary) => {
                    return { number: q.number, answer: "" }
                }))
            } else {
                setAnswerQuestionary(JSON.parse(resQuestionaryAnswerBrs.data.row.answer))
            }
        } catch (error) {
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }, [setNotif, props.assessment_participant_session.id]);

    useEffect(() => {
        getDoc();
    }, [getDoc]);

    // track brs_questionary
    useEffect(() => {
        //console.log(brs_questionary)
    }, [brs_questionary]);

    // track answer_questionary
    useEffect(() => {
        //console.log(answer_questionary)
    }, [answer_questionary]);

    // handleAnswer
    const handleAnswer = async (index: number, answer: string) => {
        try {
            //console.log(index, answer, point)
            // set answer
            // copy answer
            const c_answer = [...answer_questionary]
            c_answer.splice(index, 1, { number: c_answer[index].number, answer: answer })
            // set result point
            const c_result_point = {}
            // save answer
            if (brs_questionary_answer) {
                const psc = new ParticipantSession();
                await psc.saveQuestionaryAnswerBrs(props.assessment_participant_session.id, {
                    ...brs_questionary_answer,
                    answer: JSON.stringify(c_answer),
                    result: JSON.stringify(c_result_point)
                });
                setAnswerQuestionary(c_answer)
            }
        } catch (error) {
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }

    return (
        <div className="h-[94%] w-screen overflow-auto">
            <div className="flex flex-col items-center w-full">
                <div className="text-sm md:text-base max-w-5xl text-left pl-2 md:pl-0 py-3 whitespace-pre-line">
                    {brs?.mdi}
                </div>
            </div>
            <div className="text-sm md:text-base border-b flex flex-col items-center pb-20 relative w-full">
                <div className="w-full text-center sticky top-0 bg-white overflow-hidden">
                    <div className="py-2 md:pl-6 font-bold text-blue-600 text-xl">
                        Dijawab: {answer_questionary.filter(aq => aq.answer !== "").length}/{brs_questionary.length}
                    </div>
                </div>
                <div className="w-full overflow-scroll">
                    <div className="max-w-5xl w-full">
                        <div className="">
                            <div className="flex w-full bg-slate-800 text-white font-semibold">
                                <div className="w-1/12 text-center flex justify-center items-center border p-1">No</div>
                                <div className="w-10/12 lg:w-5/12 text-left flex justify-center items-center border p-1">
                                    Pertanyaan
                                </div>
                                <div className={`hidden lg:flex w-1/12 text-center justify-center items-center border p-1 cursor-pointer`}>
                                    Sangat Tidak Setuju
                                </div>
                                <div className={`hidden lg:flex w-1/12 text-center justify-center items-center border p-1 cursor-pointer`}>
                                    Tidak Setuju
                                </div>
                                <div className={`hidden lg:flex w-1/12 text-center justify-center items-center border p-1 cursor-pointer`}>
                                    Netral
                                </div>
                                <div className={`hidden lg:flex w-1/12 text-center justify-center items-center border p-1 cursor-pointer`}>
                                    Setuju
                                </div>
                                <div className={`hidden lg:flex w-1/12 text-center justify-center items-center border p-1 cursor-pointer`}>
                                    Sangat Setuju
                                </div>
                                <div className="w-1/12 text-center flex justify-center items-center border p-1">
                                    <CheckCircleIcon className="w-5 text-white" />
                                </div>
                            </div>
                        </div>
                        {brs_questionary.map((brs_q, index) => (
                            <div key={index} className="">
                                <div className="flex w-full">
                                    <div className="w-1/12 text-center flex justify-center items-center border p-1">{brs_q.number}</div>
                                    <div className="w-10/12 lg:w-5/12 border p-1">
                                        <div className="flex justify-left items-center pb-1">
                                            {brs_q.question}
                                        </div>
                                        <div className="flex lg:hidden">
                                            <div
                                                className={`
                                                    flex w-[20%] text-center justify-center items-center p-1 cursor-pointer hover:bg-blue-200 hover:font-semibold hover:text-black
                                                    ${answer_questionary?.[index]?.answer === "sangat tidak setuju" ? "bg-green-200" : ""}
                                                    ${answer_questionary?.[index]?.answer !== "sangat tidak setuju" && answer_questionary?.[index]?.answer !== "" ? "bg-slate-100 text-gray-400" : ""}
                                                `}
                                                onClick={() => handleAnswer(index, "sangat tidak setuju")}>
                                                Sangat Tidak Setuju
                                            </div>
                                            <div
                                                className={`
                                                    flex w-[20%] text-center justify-center items-center p-1 cursor-pointer hover:bg-blue-200 hover:font-semibold hover:text-black
                                                    ${answer_questionary?.[index]?.answer === "tidak setuju" ? "bg-green-200" : ""}
                                                    ${answer_questionary?.[index]?.answer !== "tidak setuju" && answer_questionary?.[index]?.answer !== "" ? "bg-slate-100 text-gray-400" : ""}
                                                `}
                                                onClick={() => handleAnswer(index, "tidak setuju")}>
                                                Tidak Setuju
                                            </div>
                                            <div
                                                className={`
                                                    flex w-[20%] text-center justify-center items-center border p-1 cursor-pointer hover:bg-blue-200 hover:font-semibold hover:text-black
                                                    ${answer_questionary?.[index]?.answer === "netral" ? "bg-green-200" : ""}
                                                    ${answer_questionary?.[index]?.answer !== "netral" && answer_questionary?.[index]?.answer !== "" ? "bg-slate-100 text-gray-400" : ""}
                                                `}
                                                onClick={() => handleAnswer(index, "netral")}>
                                                Netral
                                            </div>
                                            <div
                                                className={`
                                                    flex w-[20%] text-center justify-center items-center border p-1 cursor-pointer hover:bg-blue-200 hover:font-semibold hover:text-black
                                                    ${answer_questionary?.[index]?.answer === "setuju" ? "bg-green-200" : ""}
                                                    ${answer_questionary?.[index]?.answer !== "setuju" && answer_questionary?.[index]?.answer !== "" ? "bg-slate-100 text-gray-400" : ""}
                                                `}
                                                onClick={() => handleAnswer(index, "setuju")}>
                                                Setuju
                                            </div>
                                            <div
                                                className={`
                                                    flex w-[20%] text-center justify-center items-center border p-1 cursor-pointer hover:bg-blue-200 hover:font-semibold hover:text-black
                                                    ${answer_questionary?.[index]?.answer === "sangat setuju" ? "bg-green-200" : ""}
                                                    ${answer_questionary?.[index]?.answer !== "sangat setuju" && answer_questionary?.[index]?.answer !== "" ? "bg-slate-100 text-gray-400" : ""}
                                                `}
                                                onClick={() => handleAnswer(index, "sangat setuju")}>
                                                Sangat Setuju
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`
                                        hidden lg:flex w-1/12 text-center justify-center items-center border p-1 cursor-pointer hover:bg-blue-200 hover:font-semibold hover:text-black
                                        ${answer_questionary?.[index]?.answer === "sangat tidak setuju" ? "bg-green-200" : ""}
                                        ${answer_questionary?.[index]?.answer !== "sangat tidak setuju" && answer_questionary?.[index]?.answer !== "" ? "bg-slate-100 text-gray-400" : ""}
                                    `}
                                        onClick={() => handleAnswer(index, "sangat tidak setuju")}>
                                        Sangat Tidak Setuju
                                    </div>
                                    <div
                                        className={`
                                        hidden lg:flex w-1/12 text-center justify-center items-center border p-1 cursor-pointer hover:bg-blue-200 hover:font-semibold hover:text-black
                                        ${answer_questionary?.[index]?.answer === "tidak setuju" ? "bg-green-200" : ""}
                                        ${answer_questionary?.[index]?.answer !== "tidak setuju" && answer_questionary?.[index]?.answer !== "" ? "bg-slate-100 text-gray-400" : ""}
                                    `}
                                        onClick={() => handleAnswer(index, "tidak setuju")}>
                                        Tidak Setuju
                                    </div>
                                    <div
                                        className={`
                                        hidden lg:flex w-1/12 text-center justify-center items-center border p-1 cursor-pointer hover:bg-blue-200 hover:font-semibold hover:text-black
                                        ${answer_questionary?.[index]?.answer === "netral" ? "bg-green-200" : ""}
                                        ${answer_questionary?.[index]?.answer !== "netral" && answer_questionary?.[index]?.answer !== "" ? "bg-slate-100 text-gray-400" : ""}
                                    `}
                                        onClick={() => handleAnswer(index, "netral")}>
                                        Netral
                                    </div>
                                    <div
                                        className={`
                                        hidden lg:flex w-1/12 text-center justify-center items-center border p-1 cursor-pointer hover:bg-blue-200 hover:font-semibold hover:text-black
                                        ${answer_questionary?.[index]?.answer === "setuju" ? "bg-green-200" : ""}
                                        ${answer_questionary?.[index]?.answer !== "setuju" && answer_questionary?.[index]?.answer !== "" ? "bg-slate-100 text-gray-400" : ""}
                                    `}
                                        onClick={() => handleAnswer(index, "setuju")}>
                                        Setuju
                                    </div>
                                    <div
                                        className={`
                                        hidden lg:flex w-1/12 text-center justify-center items-center border p-1 cursor-pointer hover:bg-blue-200 hover:font-semibold hover:text-black
                                        ${answer_questionary?.[index]?.answer === "sangat setuju" ? "bg-green-200" : ""}
                                        ${answer_questionary?.[index]?.answer !== "sangat setuju" && answer_questionary?.[index]?.answer !== "" ? "bg-slate-100 text-gray-400" : ""}
                                    `}
                                        onClick={() => handleAnswer(index, "sangat setuju")}>
                                        Sangat Setuju
                                    </div>
                                    <div className={`w-1/12 text-center flex justify-center items-center border p-1 ${answer_questionary?.[index]?.answer !== "" ? "bg-green-100" : ""}`}>
                                        {answer_questionary?.[index]?.answer !== ""
                                            ? <CheckCircleIcon className="w-5 text-green-600" />
                                            : <ClockIcon className="w-5 text-gray-400" />
                                        }
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="max-w-5xl w-full mt-3 flex justify-between items-center">
                    <div className="pl-2 md:pl-6 font-semibold">
                        {answer_questionary.filter(aq => aq.answer !== "").length}/{brs_questionary.length}
                    </div>
                    {answer_questionary.filter(aq => aq.answer !== "").length !== brs_questionary.length &&
                        <div className="px-3 text-red-500">Tombol <b>Finish</b> tersedia saat semua pernyataan telah dipilih</div>
                    }
                    <button
                        type="button"
                        className={`mx-3 md:mx-0 py-1 px-6 min-w-[80px] text-center cursor-pointer rounded text-white bg-red-500 hover:bg-red-600 disabled:bg-gray-300`}
                        onClick={() => props.closeSession()}
                        disabled={answer_questionary.filter(aq => aq.answer !== "").length !== brs_questionary.length}>
                        Finish
                    </button>
                </div>
            </div>
        </div >
    );
}

export default SubtestBrs;
