import { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Icons
import { ArrowsPointingOutIcon, ArrowDownTrayIcon, ArrowUpTrayIcon, XMarkIcon } from "@heroicons/react/24/outline";

// Context
import AuthContext from "../../../context/assessment/AuthContext";
import SnackbarContext from "../../../context/assessment/SnackbarContext";
import LoaderContext from "../../../context/assessment/LoaderContext";

// Controller
import Assessment from "../../../controller/assessment/assessment_system/assessment";

// Helper
import errorHandler from "../../../helper/assessment/errorHandler";

// Components
import FormUpload from "../../../components/assessment/assessment_system/FormUpload";

// Page
import FormParticipantIntray from "./FormParticipantIntray";

function FormParticipantScore(props: { doc: TAssessment; setIsOpenParticipantScore: (close: boolean) => void }) {
  // context
  const { user } = useContext(AuthContext);
  const { message, setMessage } = useContext(LoaderContext);
  const { setNotif } = useContext(SnackbarContext);
  // is admin
  const isAdmin = user.roles === "1";
  // is qc assessment
  const isQcAssessment = props.doc.qc_id.toString() === user.assessor_id;
  // status
  const isScoring = props.doc.status === "2";
  const isCheckScore = props.doc.status === "3";
  const isCompleted = props.doc.status === "99";
  // state
  const [assessmentParticipantSessionList, setAssessmentParticipantSessionList] = useState<
    TAssessmentParticipantSession[]
  >([]);
  const [assessmentScoreNameList, setAssessmentScoreNameList] = useState<TAssessmentScoreName[]>([]);
  const [assessmentParticipantList, setAssessmentParticipantList] = useState<TAssessmentParticipant[]>([]);
  const [assessmentParticipantUpload, setAssessmentParticipantUpload] = useState<TAssessmentParticipantUpload>();
  const [selectedAssessmentParticipantSession, setSelectedAssessmentParticipantSession] = useState<number>();
  // participant score data
  const [participantScoreData, setParticipantScoreData] = useState<TAssessmentParticipantScore[]>([]);
  // helper
  let index = -1;

  const getDoc = useCallback(
    async function getDoc() {
      try {
        // fetch session list
        const ac = new Assessment();

        setMessage("Fetch Assessment Participant Session");
        const resParticipantSessionList = await ac.listParticipantSession(props.doc.id.toString());
        const participantSessionList: TAssessmentParticipantSession[] = resParticipantSessionList.data.list;
        setAssessmentParticipantSessionList(participantSessionList);

        setMessage("Fetch Assessment Score Name");
        const resScoreName = await ac.listScoreName(props.doc.id.toString());
        setAssessmentScoreNameList(resScoreName.data.list);
        const scoreNameList: TAssessmentScoreName[] = resScoreName.data.list;

        setMessage("Fetch Assessment Participant");
        const resParticipantList = await ac.listParticipant(props.doc.id.toString());
        let assessmentParticipantList: TAssessmentParticipant[] = [];
        // filter only get participant with pic id for current user
        // return all if current user is admin or in checking score status
        if (isAdmin || isCheckScore) {
          assessmentParticipantList = resParticipantList.data.list;
        } else {
          assessmentParticipantList = resParticipantList.data.list.filter(
            (v: TAssessmentParticipant) => v.pic_id?.toString() === user.assessor_id
          );
        }
        // set to state
        setAssessmentParticipantList(assessmentParticipantList);

        setMessage("Fetch Assessment Participant Score");
        const resParticipantScoreList = await ac.listParticipantScore(props.doc.id.toString());
        const participantScoreList: TAssessmentParticipantScore[] = resParticipantScoreList.data.list;

        /**
         * initialize empty score
         * use this array as base so we don't messed-up with the index
         */
        const newParticipantScoreDataMap = scoreNameList.map((snl, i) => {
          return assessmentParticipantList.map((apl, _) => {
            return {
              assessment_id: props.doc.id,
              assessment_participant_id: apl.id,
              score_name: snl.score_name,
              score_value: 0,
            };
          });
        });
        // parse to single array
        let newParticipantScoreData: any[] = [];
        newParticipantScoreDataMap.forEach((v: any) => {
          newParticipantScoreData = [...newParticipantScoreData, ...v];
        });
        // if participant score is not empty map to participant score data
        if (participantScoreList.length > 0) {
          newParticipantScoreData = newParticipantScoreData.map((nps: TAssessmentParticipantScore) => {
            // find in the participant score list
            const findParticipantScore = participantScoreList.find(
              (ps) =>
                ps.assessment_id === nps.assessment_id &&
                ps.assessment_participant_id === nps.assessment_participant_id &&
                ps.score_name === nps.score_name
            );
            const result = findParticipantScore ? { ...nps, ...findParticipantScore } : nps;
            return result;
          });
        }
        setParticipantScoreData(newParticipantScoreData);

        setMessage("");
      } catch (error) {
        setMessage("");
        const errorMessage = errorHandler(error);
        setNotif({ type: "error", message: errorMessage });
      }
    },
    [setNotif, setMessage, props.doc.id, isAdmin, isCheckScore, user.assessor_id]
  );

  useEffect(() => {
    getDoc();
  }, [getDoc]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    let value = isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value);
    if (value < 0) {
      value = 0;
    } else if (value > 100) {
      value = 100;
    }
    const copyParticipantScoreData = [...participantScoreData];
    const updateParticipantScore: TAssessmentParticipantScore = {
      ...participantScoreData[index],
      score_value: value,
    };
    copyParticipantScoreData.splice(index, 1, updateParticipantScore);
    setParticipantScoreData(copyParticipantScoreData);
  };

  const handleBlur = async (index: number) => {
    try {
      const dataSubmit: TAssessmentParticipantScore = {
        ...participantScoreData[index],
        updated_by_id: parseInt(user.assessor_id),
      };
      const ac = new Assessment();
      setMessage("Save Assessment Participant Score");
      if (dataSubmit.id) {
        // update scoring
        await ac.updateParticipantScore(dataSubmit);
      } else {
        // create scoring
        const resCrateParticipantScore = await ac.addParticipantScore(dataSubmit);
        const copyParticipantScoreData = [...participantScoreData];
        const updateParticipantScore: TAssessmentParticipantScore = {
          ...participantScoreData[index],
          id: resCrateParticipantScore.data.saved_id,
        };
        copyParticipantScoreData.splice(index, 1, updateParticipantScore);
        setParticipantScoreData(copyParticipantScoreData);
      }
      setMessage("");
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  return (
    <div>
      <div className="fixed z-20 top-0 left-0 w-screen h-screen bg-black opacity-20"></div>
      <div className="fixed z-30 top-0 left-0 w-screen h-screen bg-transparent flex items-center justify-center">
        <div className="bg-white w-screen md:w-[96%] h-full md:h-[96%] md:rounded overflow-hidden">
          <div className="p-4 bg-green-800 md:rounded-t text-white flex justify-between">
            <h3>Participant Score</h3>
            <button type="button" onClick={() => props.setIsOpenParticipantScore(false)}>
              <XMarkIcon className="w-6" />
            </button>
          </div>
          <div className="w-full h-0.5">
            {message !== "" && (
              <div className="h-full bg-gradient-to-r bg-slate-200 from-cyan-300 to-blue-700 bg-20% bg-repeat-y bg-posloader animate-loader"></div>
            )}
          </div>
          <div className="flex px-4">
            &nbsp;
            {message !== "" && <div className="hidden md:block ml-auto text-gray-600 text-right">{message} . . .</div>}
          </div>
          <div className="w-full h-[92%] overflow-auto">
            <div className="w-[98%] h-[98%] flex">
              <div className="w-full mx-4 shadow-sm overflow-auto my-8">
                <table className="border-collapse table-auto w-full text-xs">
                  {/** TABLE PARTICIPANT */}
                  <thead className="bg-gray-200 sticky top-0 z-10">
                    <tr>
                      <th className="border font-semibold p-2 pl-8 py-2 text-slate-600 text-left min-w-[18rem] bg-gray-200 sticky left-0">Participant</th>
                      {assessmentParticipantList.map((pl, i) => (
                        <th
                          key={`participant_header_${i}`}
                          className="border font-semibold p-2 pl-8 py-2 text-slate-600 text-left"
                        >
                          {pl.full_name}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {/* Answer */}
                    <tr>
                      <td className="border p-2 before:pl-8 text-slate-500 bg-gray-50 sticky left-0">JAWABAN</td>
                      {assessmentParticipantList.map((ap, i_ap) => {
                        const sessionList = assessmentParticipantSessionList.filter(
                          (aps) => ap.id === aps.assessment_participant_id
                        );
                        return (
                          <td className="border p-2 pl-8 text-slate-500" key={`apl_${i_ap}`}>
                            <div className="flex flex-col items-start space-y-1">
                              {sessionList.map((sl, i_sl) => (
                                <div key={`sl_${i_sl}`}>
                                  {sl.category.includes("In-Tray Simulation") ? (
                                    <button
                                      type="button"
                                      className="flex items-center rounded px-2 hover:bg-blue-50 text-blue-500 hover:text-blue-800 border border-blue-300"
                                      onClick={() => setSelectedAssessmentParticipantSession(sl.id)}
                                    >
                                      <ArrowsPointingOutIcon className="w-4 mr-1" />
                                      {sl.category}
                                    </button>
                                  ) : (
                                    <>
                                      {sl.file_path === null ? (
                                        <div className={`flex items-center rounded px-2 border`}>
                                          <ArrowDownTrayIcon className="w-4 mr-1" />
                                          {sl.category}
                                        </div>
                                      ) : (
                                        <Link
                                          to={`/upload/assessment_system/get_answer/${sl.file_path}`}
                                          target="_blank"
                                          className={`flex items-center rounded px-2 hover:bg-blue-50 text-blue-500 hover:text-blue-800 border border-blue-300 whitespace-nowrap`}
                                        >
                                          <ArrowDownTrayIcon className="w-4 mr-1" />
                                          {sl.category}
                                        </Link>
                                      )}
                                    </>
                                  )}
                                </div>
                              ))}
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                    {/* Score */}
                    {assessmentScoreNameList.map((competency, i) => (
                      <tr key={`participant_${i}`}>
                        <td className="border p-2 pl-8 text-slate-500 bg-gray-50 sticky left-0">
                          {competency.score_name}
                        </td>
                        {assessmentParticipantList.map((_, ii) => {
                          index++;
                          const set_index = index;
                          return (
                            <td
                              key={`score_name_header_${ii}`}
                              className="border p-2 pl-8 text-slate-500"
                            >
                              {isCompleted
                                ? <>{participantScoreData?.[index]?.score_value ?? 0}</>
                                : <input
                                  type="number"
                                  className={
                                    ((participantScoreData?.[index]?.score_value ?? 0) === 0
                                      ? "border-red-500"
                                      : "border-gray-200 focus:border-gray-500") +
                                    " w-40 px-3 py-2 text-center first-letter:appearance-none bg-white-200 text-gray-700 border focus:outline-none disabled:text-gray-200"
                                  }
                                  disabled={!(isScoring || isQcAssessment) && !isAdmin}
                                  value={participantScoreData?.[index]?.score_value ?? 0}
                                  onChange={(e) => handleChange(e, set_index)}
                                  onBlur={() => handleBlur(set_index)}
                                />
                              }
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                    {/* Score */}
                    <tr>
                      <td className="border-b p-2 pl-8 text-slate-500 bg-gray-50 sticky left-0">DATA ASMET</td>
                      {assessmentParticipantList.map((ap, i_ap) => (
                        <td className="border p-2 pl-8 text-slate-500" key={`apl_asmet_${i_ap}`}>
                          <FileParticipant
                            assessment_participant={ap}
                            field="data_asmet"
                            setAssessmentParticipantUpload={setAssessmentParticipantUpload}
                            isCompleted={isCompleted}
                          />
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="border p-2 pl-8 text-slate-500 bg-gray-50 sticky left-0">LAPORAN ASSESSOR</td>
                      {assessmentParticipantList.map((ap, i_ap) => (
                        <td className="border p-2 pl-8 text-slate-500" key={`apl_la_${i_ap}`}>
                          <FileParticipant
                            assessment_participant={ap}
                            field="laporan_assessor"
                            setAssessmentParticipantUpload={setAssessmentParticipantUpload}
                            isCompleted={isCompleted}
                          />
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="border p-2 pl-8 text-slate-500 bg-gray-50 sticky left-0">LAPORAN FINAL</td>
                      {assessmentParticipantList.map((ap, i_ap) => (
                        <td className="border p-2 pl-8 text-slate-500" key={`apl_lf_${i_ap}`}>
                          {((isQcAssessment && isCheckScore) || isAdmin) && (
                            <FileParticipant
                              assessment_participant={ap}
                              field="laporan_final"
                              setAssessmentParticipantUpload={setAssessmentParticipantUpload}
                              isCompleted={isCompleted}
                            />
                          )}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {assessmentParticipantUpload !== undefined && (
        <FormUpload
          assessmentParticipantUpload={assessmentParticipantUpload}
          setAssessmentParticipantUpload={setAssessmentParticipantUpload}
          assessmentParticipantList={assessmentParticipantList}
          setAssessmentParticipantList={setAssessmentParticipantList}
        />
      )}
      {selectedAssessmentParticipantSession !== undefined && (
        <FormParticipantIntray
          selectedAssessmentParticipantSession={selectedAssessmentParticipantSession}
          setSelectedAssessmentParticipantSession={setSelectedAssessmentParticipantSession}
          assessmentParticipantSessionList={assessmentParticipantSessionList}
        />
      )}
    </div>
  );
}

function FileParticipant(props: {
  assessment_participant: TAssessmentParticipant | any;
  field: "data_asmet" | "laporan_assessor" | "laporan_final";
  setAssessmentParticipantUpload: React.Dispatch<React.SetStateAction<TAssessmentParticipantUpload | undefined>>;
  isCompleted: boolean;
}) {
  const setUpload = (data: TAssessmentParticipantUpload) => {
    props.setAssessmentParticipantUpload(data);
  };

  return (
    <div className="flex flex-col items-start space-y-1">
      {props.assessment_participant[props.field] !== undefined && props.assessment_participant[props.field] !== null && (
        <Link
          to={`/upload/assessment_system/get_file_participant/${props.assessment_participant[props.field]}`}
          target="_blank"
          className="flex items-center border border-blue-300 rounded px-2 hover:bg-blue-50 text-blue-500 hover:text-blue-800"
        >
          <ArrowDownTrayIcon className="w-4 mr-1" />
          download
        </Link>
      )}
      {!props.isCompleted &&
        <button
          type="button"
          className="flex items-center border border-green-300 rounded px-2 hover:bg-green-50 text-green-500 hover:text-green-800"
          onClick={() => setUpload({ assessment_participant_id: props.assessment_participant.id, field: props.field })}
        >
          <ArrowUpTrayIcon className="w-4 mr-1" />
          {props.assessment_participant[props.field] !== undefined && props.assessment_participant[props.field] !== null
            ? "re-upload"
            : "upload"}
        </button>
      }
    </div>
  );
}

export default FormParticipantScore;
