import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useFieldArray } from "react-hook-form";
import { useCallback, useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";

// Icons
import { PlusIcon, ArrowDownTrayIcon } from "@heroicons/react/24/outline";

// Context
import AuthContext from "../../../context/assessment/AuthContext";
import SnackbarContext from "../../../context/assessment/SnackbarContext";
import LoaderContext from "../../../context/assessment/LoaderContext";

// Controller
import Assessment from "../../../controller/assessment/assessment_system/assessment";
import Company from "../../../controller/assessment/assessment_system/company";

// Helper
import errorHandler from "../../../helper/assessment/errorHandler";

type FormValues = {
  score_name: TAssessmentScoreName[];
};

const maxScoreName = 20;

const schema = yup.object().shape({
  score_name: yup.array().of(
    yup.object().shape({
      score_name: yup.string().label("Competency").required(),
    })
  ),
});

function FormScoreName(props: { doc: TAssessment; isCreate: boolean; assessment_id: string; editable: boolean }) {
  // context
  const { user } = useContext(AuthContext);
  const { setMessage } = useContext(LoaderContext);
  const { setNotif } = useContext(SnackbarContext);
  // is admin
  const isAdmin = user.roles === "1";
  // in allow edit (draft | progress)
  const isAllowEdit = props.doc.status === "0" || props.doc.status === "1";
  // helper state
  const [editing, setEditing] = useState<boolean>(false);
  const [companyCompetency, setCompanyCompetency] = useState<TCompanyCompetency[]>([]);
  // react hook form
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm<FormValues>({
    defaultValues: { score_name: [] },
    mode: "onBlur",
    resolver: yupResolver(schema),
  });
  const { fields, append, remove } = useFieldArray({
    name: "score_name",
    control,
    keyName: "ids",
  });

  const getDoc = useCallback(
    async function getDoc() {
      try {
        if (!props.isCreate) {
          // fetch score_name list
          const ac = new Assessment();
          setMessage("Fetch Assessment Competency");
          const resScoreNameList = await ac.listScoreName(props.assessment_id);
          // setValue not work in here
          // if avaible we don't need get data from company setup
          if (resScoreNameList.data.list.length > 0) {
            reset({ score_name: resScoreNameList.data.list });
          } else {
            // if not set let's get data from company setup
            // get company setup
            setMessage("Fetch Company Detail");
            const cc = new Company();
            const resCompanyCompetencyList = await cc.listCompetency(props.doc.company_id);
            const companyCompetencyList: TCompanyCompetency[] = resCompanyCompetencyList.data.list;
            setCompanyCompetency(companyCompetencyList);
          }
        }
        setMessage("");
      } catch (error) {
        setMessage("");
        const errorMessage = errorHandler(error);
        setNotif({ type: "error", message: errorMessage });
      }
    },
    [setNotif, setMessage, props.assessment_id, props.isCreate, reset, props.doc.company_id]
  );

  useEffect(() => {
    getDoc();
  }, [getDoc]);

  useEffect(() => {
    // update if company session setup is avaible
    if (companyCompetency.length > 0) {
      companyCompetency.forEach((competency) => {
        append({
          assessment_id: parseInt(props.assessment_id),
          score_name: competency.competency,
        });
      });
    }
  }, [companyCompetency, props.assessment_id, append]);

  const onSubmit = (data: FormValues) => {
    setEditing(false);
    handleChange(data.score_name);
  };

  const handleChange = (score_names: TAssessmentScoreName[]) => {
    score_names.forEach(async (score_name, i) => {
      try {
        // make sure changed occured
        const isChanged = true;
        if (isChanged) {
          setMessage("Save Competency");
          const ac = new Assessment();
          // check if have id
          if (score_name.id) {
            await ac.updateScoreName(score_name);
          } else {
            // create new score_name
            const resCreate = await ac.addScoreName(props.assessment_id, score_name);
            let newScoreNames = [...score_names];
            newScoreNames[i] = {
              ...newScoreNames[i],
              id: resCreate.data.saved_id,
            };
            setValue("score_name", newScoreNames);
          }
          setMessage("");
        }
      } catch (error) {
        setMessage("");
        const errorMessage = errorHandler(error);
        setNotif({ type: "error", message: errorMessage });
      }
    });
  };

  const handleRemove = async (i: number) => {
    try {
      const confirm = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (confirm.isConfirmed) {
        // to reset button save if remove new item without submit
        setEditing(false);
        // start remove
        setMessage("Remove Competency");
        const ac = new Assessment();
        const removeScoreNameId = fields[i].id;
        if (removeScoreNameId) {
          await ac.deleteScoreName(removeScoreNameId.toString());
        }
        remove(i);
        setMessage("");
      }
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  return (
    <div className="pb-[40rem]">
      {fields.length < maxScoreName && props.editable && isAdmin && isAllowEdit && (
        <div className="w-full md:w-full mb-2">
          {!editing && (
            <button
              type="button"
              onClick={() => {
                append({
                  assessment_id: 0,
                  //
                  score_name: "",
                });
              }}
              className="block w-auto px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded font-semibold text-sm"
            >
              <div className="flex">
                <PlusIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
                Competency
              </div>
            </button>
          )}
          {editing && (
            <button
              type="button"
              className="block w-auto px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded font-semibold text-sm"
            >
              <div className="flex">
                <ArrowDownTrayIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
                Save
              </div>
            </button>
          )}
        </div>
      )}
      <div className="w-full mb-1 hidden md:flex">
        <div className="w-full md:w-1/12 px-3 py-2 border rounded bg-slate-600 text-white text-center">No</div>
        <div className="w-full md:w-11/12 px-3 py-2 border rounded bg-slate-600 text-white">Competency</div>
        {props.editable && isAdmin && isAllowEdit && (
          <div className="w-full md:w-1/12 px-3 py-2 border rounded bg-red-500 text-white text-center">Remove</div>
        )}
      </div>
      <form onBlur={() => handleSubmit(onSubmit)()}>
        {fields.map((_, i) => {
          return (
            <div key={`score_name_${i}`} className="w-full mb-1 md:flex">
              <div className="w-full md:w-1/12 px-3 py-2 border rounded bg-slate-600 text-white md:text-center">
                {i + 1}
              </div>
              <div className="w-full md:w-11/12 border-x border-white">
                <input
                  className={
                    (errors?.score_name?.[i]?.score_name ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                    " appearance-none first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-800"
                  }
                  onFocus={() => setEditing(true)}
                  disabled={!(isAdmin && isAllowEdit)}
                  {...register(`score_name.${i}.score_name`)}
                />
              </div>
              {props.editable && isAdmin && isAllowEdit && (
                <button
                  type="button"
                  className="w-full md:w-1/12 px-3 py-2 border rounded bg-red-500 hover:bg-red-700 text-white text-center cursor-pointer disabled:bg-slate-500"
                  onClick={() => handleRemove(i)}
                  disabled={fields.length === 1}
                >
                  Remove
                </button>
              )}
            </div>
          );
        })}
      </form>
    </div>
  );
}

export default FormScoreName;
