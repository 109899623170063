import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState, useContext, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Disclosure, Transition } from "@headlessui/react";

// Icon
import { ChevronUpIcon, ArrowDownTrayIcon, TrashIcon } from "@heroicons/react/24/outline";

// Helper
import errorHandler from "../../../helper/assessment/errorHandler";
import logRender from "../../../helper/assessment/logRender";

// Context
import LoaderContext from "../../../context/assessment/LoaderContext";
import SnackbarContext from "../../../context/assessment/SnackbarContext";

// Controller
import Category from "../../../controller/assessment/assessment_system/category";
import Questionary from "../../../controller/assessment/assessment_system/questionary";

// Component
import FileUpload from "../../../components/assessment/assessment_system/FileUpload";
import BackButton from "../../../components/assessment/assessment_system/BackButton";
import FormIntrayEmail from "./FormIntrayEmail";

const schema = yup.object().shape({
  id: yup.number().transform((value) => (isNaN(value) ? undefined : value)),
  file_path: yup.string().label("File").required(),
  description: yup.string().label("Description").transform((value) => (value === null ? "" : value)),
  category_id: yup
    .number()
    .label("Category")
    .transform((value) => (isNaN(value) ? undefined : value)),
  time_limit: yup
    .number()
    .label("Time Limit")
    .required()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(1)
    .max(720),
});

function Form() {
  const { setMessage } = useContext(LoaderContext);
  const { setNotif } = useContext(SnackbarContext);
  const navigate = useNavigate();
  // parse param id
  const location = useLocation();
  // remove trailing slash in production when reload/refresh
  const arrPath = location.pathname.replace(/\/$/, "").split("/");
  const paramId = arrPath[arrPath.length - 1];
  // required value
  const [categoryOptions, setCategoryOptions] = useState<TSelect[]>([]);
  // state document
  const [doc, setDoc] = useState<TQuestionary>({
    id: 0,
    description: "",
    category_id: 0,
    time_limit: 0,
    file_path: "",
  });
  // is create
  const isCreate = paramId === "create";
  // check editable
  const editable = true; // # DON'T FORGET UPDATE THIS
  const [id, setId] = useState<number>(0);
  const [file_path, setFilePath] = useState<string>("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    getValues,
  } = useForm<TQuestionary>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });
  watch(["category_id", "category"]);

  useEffect(() => {
    logRender({ type: "page", name: "questionary/Form" });
  }, []);

  const getDoc = useCallback(
    async function getDoc() {
      try {
        setMessage("Fetch Category");
        // fetch option category
        const cc = new Category();
        const res = await cc.list();
        const toSelect: TSelect[] = res.data.list.map((v: TCategory) => {
          return { id: v.id, label: v.category };
        });
        setCategoryOptions(toSelect);
        // fetch document
        if (!isCreate) {
          setMessage("Fetch Document");
          const qc = new Questionary();
          const res = await qc.get(paramId);
          const row: TQuestionary = res.data.row;
          setDoc(row);
          setValue("id", row.id);
          setValue("file_path", row.file_path);
          setValue("description", row.description);
          setValue("category_id", row.category_id);
          setValue("time_limit", row.time_limit);
          // make sure file upload components updated current document
          setId(row.id);
          setFilePath(row.file_path);
        }
        setMessage("");
      } catch (error) {
        setMessage("");
        const errorMessage = errorHandler(error);
        setNotif({ type: "error", message: errorMessage });
        navigate(`../`, { replace: true });
      }
    },
    [setValue, paramId, isCreate, setNotif, setMessage, navigate]
  );

  useEffect(() => {
    getDoc();
  }, [getDoc]);

  const onSubmit: SubmitHandler<TQuestionary> = async (data) => {
    try {
      setMessage("Save Questionary");
      const qc = new Questionary();
      let res;
      if (isCreate) {
        res = await qc.create(data);
        navigate(`../${res.data.saved_id}`, { replace: true });
      } else {
        res = await qc.save(data);
      }
      setNotif({ type: "success", message: res.data.message });
      setMessage("");
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  useEffect(() => {
    setValue("id", id);
  }, [setValue, id]);

  useEffect(() => {
    setValue("file_path", file_path);
  }, [setValue, file_path]);

  const confirmDelete = async (questionary: TQuestionary) => {
    try {
      const confirm = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (confirm.isConfirmed) {
        const qc = new Questionary();
        await qc.delete(questionary);
        setNotif({ type: "success", message: "questionary deleted" });
        navigate(`../`, { replace: true });
      }
    } catch (error) {
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "category_id") {
        if (value[name] !== null && value[name] !== undefined) {
          const findCategory = categoryOptions.find((v) => v.id.toString() === value[name]?.toString());
          setValue("category", findCategory?.label);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, categoryOptions, setValue]);

  return (
    <div className="w-full flex flex-col space-y-0.5">
      <div className="flex">
        <BackButton />
        {editable && (
          <button
            onClick={handleSubmit(onSubmit)}
            className="mx-2 block w-auto px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded font-semibold text-sm"
          >
            <div className="flex">
              <ArrowDownTrayIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
              Save
            </div>
          </button>
        )}
        {editable && !isCreate && (
          <button
            onClick={() => confirmDelete(doc)}
            className="ml-auto block w-auto px-4 py-2 bg-red-500 hover:bg-red-700 text-white rounded font-semibold text-sm"
          >
            <div className="flex">
              <TrashIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
              Delete
            </div>
          </button>
        )}
      </div>
      <div className="pt-4">
        <div className="w-full md:w-full mb-6">
          <div className="w-full md:w-full mb-6">
            <FileUpload
              type="questionary"
              editable={editable}
              document_id={id}
              filepath={file_path}
              error_field={errors?.file_path}
              setDocumentId={(id) => {
                setId(id);
                navigate(`../${id}`, { replace: true });
              }}
              setFilePath={(filepath) => setFilePath(filepath)}
              key={id}
            />
          </div>
          <form className="w-full" onBlur={() => handleSubmit(onSubmit)()}>
            <div className="flex flex-wrap -mx-3">
              <input {...register("id")} type="number" className="hidden" />
              <input {...register("file_path")} type="text" className="hidden" />
              <div className="w-full md:w-1/2 px-3 mb-6">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Category</label>
                <div className="relative after:content-['↓'] after:absolute after:right-4 after:top-3 after:pointer-events-none">
                  <select
                    className={
                      (errors?.category_id ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                      " appearance-none first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-800"
                    }
                    disabled={!editable}
                    defaultValue=""
                    {...register("category_id")}
                  >
                    <option value="" disabled>
                      Please Select
                    </option>
                    {categoryOptions.map((v, i) => (
                      <option key={i} value={v.id}>
                        {v.label}
                      </option>
                    ))}
                  </select>
                </div>
                {errors.category_id && <p className="text-red-500 text-xs italic">{errors.category_id.message}</p>}
              </div>
              <div className="w-full md:w-1/2 px-3 mb-6">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Time Limit <i className="font-semibold">(Minute)</i>
                </label>
                <input
                  type="number"
                  className={
                    (errors.time_limit ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                    " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                  }
                  disabled={!editable}
                  {...register("time_limit")}
                />
                {errors.time_limit && <p className="text-red-500 text-xs italic">{errors.time_limit.message}</p>}
              </div>
              <div className="w-full md:w-full px-3 mb-6">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Description
                </label>
                <input
                  className={
                    (errors?.description ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                    " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                  }
                  disabled={!editable}
                  {...register("description")}
                />
                {errors.description && <p className="text-red-500 text-xs italic">{errors.description.message}</p>}
              </div>
            </div>
          </form>
        </div>
        {/* Intray Email */}
        {!isCreate && getValues("category") === "In-Tray Simulation" && (
          <Disclosure defaultOpen={false} as="div" className="pb-1">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between w-full px-4 py-4 text-sm font-medium text-left text-gray-100 bg-gray-900 rounded hover:bg-gray-800 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                  <span>Intray Email</span>
                  <ChevronUpIcon className={`${open ? "transform rotate-180" : ""} w-5 h-5 text-gray-100`} />
                </Disclosure.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="pt-4 pb-2 text-sm">
                    <FormIntrayEmail doc={doc} />
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        )}
      </div>
    </div>
  );
}

export default Form;
