import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState, useContext, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
import ReactQuill from "react-quill";
import "quill/dist/quill.snow.css";

import { ArrowDownTrayIcon, TrashIcon } from "@heroicons/react/24/outline";

// Comntext
import LoaderContext from "../../../context/assessment/LoaderContext";
import SnackbarContext from "../../../context/assessment/SnackbarContext";

// Helper
import errorHandler from "../../../helper/assessment/errorHandler";
import logRender from "../../../helper/assessment/logRender";

// Controller
import IntrayEmail from "../../../controller/assessment/assessment_system/intray_email";

// Component
import BackButton from "../../../components/assessment/assessment_system/BackButton";

const schema = yup.object().shape({
  subject: yup.string().label("Subject").required().max(100),
  from_id: yup
    .number()
    .label("From")
    .transform((value) => (isNaN(value) ? undefined : value))
    .required(),
  date: yup.string().label("Date").required(),
  copyto: yup.array().label("Copy To (CC)").nullable(),
  content: yup.string().label("Content").required(),
});

function FormEmail() {
  const navigate = useNavigate();
  // Context
  const { setMessage } = useContext(LoaderContext);
  const { setNotif } = useContext(SnackbarContext);
  // state
  const [initCopyto, setInitCopyto] = useState<TSelect[]>([]);
  const [copyto, setCopyto] = useState<TSelect[]>([]);
  const [content, setContent] = useState<string>("");
  // state required value
  const [emailAddressOptions, setEmailAddressOptions] = useState<TSelect[]>([]);
  // parse param id
  const location = useLocation();
  // remove trailing slash in production when reload/refresh
  const arrPath = location.pathname.replace(/\/$/, "").split("/");
  const paramId = arrPath[arrPath.length - 1];
  // state document
  const [doc, setDoc] = useState<TIntrayEmail>({
    id: 0,
    subject: "",
    from_id: 0,
    copyto: [],
    date: "",
    content: "",
  });
  // is create
  const isCreate = paramId === "create";
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<TIntrayEmail>({ resolver: yupResolver(schema) });

  useEffect(() => {
    logRender({ type: "page", name: "intray_email/FormEmail" });
  }, []);

  const getDoc = useCallback(
    async function getDoc() {
      try {
        // fetch email address
        setMessage("Fetch Email Address");
        const iec = new IntrayEmail();
        const resCompany = await iec.address_list();
        const emailAddressSelect: TSelect[] = resCompany.data.list
          .filter((v: TIntrayEmailAddress) => v.email !== null && v.name !== null)
          .map((v: TIntrayEmailAddress) => {
            return { id: v.id, value: v.id, label: `${v.email}<${v.name}>` };
          });
        setEmailAddressOptions(emailAddressSelect);
        if (!isCreate) {
          setMessage("Fetch Intray Email");
          const iec = new IntrayEmail();
          const res = await iec.get(paramId);
          const row: TIntrayEmail = res.data.row;
          setDoc(row);
          setValue("id", row.id);
          setValue("subject", row.subject);
          setValue("from_id", row.from_id);
          // we need update this to fullified datetime-local format
          const date = row.date !== null ? row.date.replace(" ", "T") : row.date;
          setValue("date", date);
          setValue("content", row.content);
          setContent(row.content);
          // fetch cc list
          const resCopytoList = await iec.copyto_list(row.id.toString());
          const filterValueCopytoList: TSelect[] = resCopytoList.data.list.map((v: TCopyToIntrayEmail) => {
            return { id: v.email_address_id, value: v.email_address_id, label: `${v.email}<${v.name}>` };
          });
          setInitCopyto(filterValueCopytoList);
          setCopyto(filterValueCopytoList);
        }
        setMessage("");
      } catch (error) {
        setMessage("");
        const errorMessage = errorHandler(error);
        setNotif({ type: "error", message: errorMessage });
        navigate(`../`, { replace: true });
      }
    },
    [setValue, paramId, isCreate, setNotif, setMessage, navigate]
  );

  useEffect(() => {
    getDoc();
  }, [getDoc]);

  const onSubmit: SubmitHandler<TIntrayEmail> = async (data) => {
    try {
      setMessage("Save Intray Email");
      const iec = new IntrayEmail();
      let res;
      if (isCreate) {
        data.id = 0;
        res = await iec.create(data);
        data.id = res.data.saved_id;
        // save cc
        if (data.copyto !== undefined && data.copyto.length > 0) {
          setMessage("Save CC Intray Email");
          for (let index = 0; index < data.copyto.length; index++) {
            const element = data.copyto[index];
            await iec.copyto_add(data.id.toString(), { email_address_id: element });
          }
        }
      } else {
        res = await iec.update(data.id.toString(), data);
        // update cc
        // get removed from cc
        // check init cc item not avaible in new cc item list
        const removedItem: TSelect[] = [];
        initCopyto.forEach((init_cc) => {
          if (copyto.findIndex((cc) => cc.id === init_cc.id) === -1) {
            removedItem.push(init_cc);
          }
        });
        // get added to cc
        // check new cc item not avaible in old cc item list
        const addedItem: TSelect[] = [];
        copyto.forEach((cc) => {
          if (initCopyto.findIndex((init_cc) => cc.id === init_cc.id) === -1) {
            addedItem.push(cc);
          }
        });
        // remove item cc
        if (removedItem.length > 0) {
          for (let index = 0; index < removedItem.length; index++) {
            const element = removedItem[index];
            await iec.copyto_delete(data.id.toString(), element.id.toString());
          }
        }
        // add item cc
        if (addedItem.length > 0) {
          for (let index = 0; index < addedItem.length; index++) {
            const element = addedItem[index];
            if (element.id !== "" && typeof element.id === "number") {
              await iec.copyto_add(data.id.toString(), { email_address_id: element.id });
            }
          }
        }
      }
      setNotif({ type: "success", message: res.data.message });
      setMessage("");
      navigate(`../`, { replace: true });
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  const confirmDelete = async (email: TIntrayEmail) => {
    try {
      const confirm = await Swal.fire({
        title: "Are you sure?",
        text: "Email will be removed, You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (confirm.isConfirmed) {
        const iec = new IntrayEmail();
        await iec.delete(email.id.toString());
        setNotif({ type: "success", message: "email deleted" });
        navigate(`../`, { replace: true });
      }
    } catch (error) {
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  // update react hook state content
  useEffect(() => {
    setValue("content", content);
  }, [content, setValue]);

  return (
    <div className="w-full flex flex-col space-y-0.5">
      <div className="flex">
        <BackButton />
        <button
          onClick={handleSubmit(onSubmit)}
          className="mx-2 block w-auto px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded font-semibold text-sm"
        >
          <div className="flex">
            <ArrowDownTrayIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
            Save
          </div>
        </button>
        {!isCreate && (
          <button
            onClick={() => confirmDelete(doc)}
            className="ml-auto block w-auto px-4 py-2 bg-red-500 hover:bg-red-700 text-white rounded font-semibold text-sm"
          >
            <div className="flex">
              <TrashIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
              Delete
            </div>
          </button>
        )}
      </div>
      <div className="pt-4">
        <form className="w-full">
          <div className="flex flex-wrap -mx-3">
            {/* Subject */}
            <div className="w-full md:w-full px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Subject</label>
              <input
                className={
                  (errors?.subject ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                  " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                }
                {...register("subject")}
              />
              {errors.subject && <p className="text-red-500 text-xs italic">{errors.subject.message}</p>}
            </div>
            {/* From ID */}
            <div className="w-full md:w-1/2 px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">From</label>
              <div className="relative after:content-['↓'] after:absolute after:right-4 after:top-3 after:pointer-events-none">
                <select
                  className={
                    (errors?.from_id ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                    " appearance-none first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-800"
                  }
                  defaultValue=""
                  {...register("from_id")}
                >
                  <option value="" disabled>
                    Please Select
                  </option>
                  {emailAddressOptions.map((v, i) => (
                    <option key={i} value={v.id}>
                      {v.label}
                    </option>
                  ))}
                </select>
              </div>
              {errors.from_id && <p className="text-red-500 text-xs italic">{errors.from_id.message}</p>}
            </div>
            {/* Date */}
            <div className="w-full md:w-1/2 px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Date Sent</label>
              <input
                type="datetime-local"
                className={
                  (errors?.date ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                  " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                }
                {...register("date")}
              />
              {errors.date && <p className="text-red-500 text-xs italic">{errors.date.message}</p>}
            </div>
            {/* Copy To */}
            <div className="w-full px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Copy To (CC)</label>
              <div>
                <Select
                  isMulti={true}
                  placeholder="Select Copy To (CC)"
                  value={copyto}
                  options={emailAddressOptions}
                  onChange={(value, _) => {
                    let copytoValue: TSelect[] = [];
                    let copytoValueNumber: number[] = [];
                    value.forEach((v) => {
                      if (v.value !== undefined) {
                        if (!isNaN(parseInt(v.value.toString()))) {
                          copytoValue.push(v);
                          copytoValueNumber.push(parseInt(v.value.toString()));
                        }
                      }
                    });
                    setCopyto(copytoValue);
                    setValue("copyto", copytoValueNumber);
                  }}
                />
              </div>
            </div>
            {/* Content */}
            <div className="w-full px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Email Content
              </label>
              <ReactQuill
                modules={{
                  toolbar: [
                    [{ header: [1, 2, false] }],
                    ["bold", "italic", "underline", { color: [] }, "strike", "blockquote"],
                    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }, { align: [] }],
                  ],
                }}
                theme="snow"
                value={content}
                onChange={(contents: string) => setContent(contents)}
              />
              {errors.content && <p className="text-red-500 text-xs italic">{errors.content.message}</p>}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default FormEmail;
