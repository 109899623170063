// Assets
import logo from "../assets/kamara-logo-white-900.svg"

function Landing() {
    return (
        <div id="hero" className="h-auto min-h-screen md:h-screen bg-gray-50 overflow-hidden bg-hero-background bg-no-repeat bg-[length:160%] md:bg-[length:140%] lg:bg-[length:122%] xl:bg-[length:86%] 2xl:bg-[length:82%] bg-[left_-32vw_top_10vh] md:bg-[left_-20vw_top_-14vh] lg:bg-[left_-28vw_top_-26vh] xl:bg-[left_-20vw_top_-26vh]">
            <main className="h-screen max-w-7xl mx-auto flex flex-col justify-center items-center text-center">
                <a href="#hero">
                    <span className="sr-only">Kamara Logo</span>
                    <img
                        alt="kamara logo"
                        className="h-32 md:h-60"
                        src={logo}
                    />
                </a>
                <h1 className="tracking-tight font-extrabold text-gray-900 sm:text-5xl flex justify-center flex-col space-y-2">
                    <span className="text-4xl md:text-6xl block xl:inline">Selamat Datang</span>
                    <span className="text-3xl md:text-4xl block text-[#192758] xl:inline">Platform Assessment & Psikotest Kamara Consulting</span>
                </h1>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:mx-auto md:mt-5 md:text-xl lg:mx-36"></p>
            </main>
        </div>
    )
}

export default Landing