import req from "../../../services/psikotest/request";

class AssessmentParticipantIstSession {
  async reset(id: number) {
    try {
      const res = await req.get("/api/psikotest_system/assessment_participant_ist_session/" + id + "/reset");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default AssessmentParticipantIstSession;
