import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState, useContext, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { ArrowDownTrayIcon, TrashIcon } from "@heroicons/react/24/outline";

// Comntext
import LoaderContext from "../../../context/assessment/LoaderContext";
import SnackbarContext from "../../../context/assessment/SnackbarContext";

// Helper
import errorHandler from "../../../helper/assessment/errorHandler";
import logRender from "../../../helper/assessment/logRender";

// Controller
import IntrayEmail from "../../../controller/assessment/assessment_system/intray_email";

// Component
import BackButton from "../../../components/assessment/assessment_system/BackButton";

const schema = yup.object().shape({
  email: yup.string().email().label("Email").required(),
  name: yup.string().label("Name").required(),
});

function FormEmailAddress() {
  const { setMessage } = useContext(LoaderContext);
  const { setNotif } = useContext(SnackbarContext);
  const navigate = useNavigate();
  // parse param id
  const location = useLocation();
  // remove trailing slash in production when reload/refresh
  const arrPath = location.pathname.replace(/\/$/, "").split("/");
  const paramId = arrPath[arrPath.length - 1];
  // state document
  const [doc, setDoc] = useState<TIntrayEmailAddress>({
    id: 0,
    email: "",
    name: "",
  });
  // is create
  const isCreate = paramId === "create";
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<TIntrayEmailAddress>({ resolver: yupResolver(schema) });

  useEffect(() => {
    logRender({ type: "page", name: "category/Form" });
  }, []);

  const getDoc = useCallback(
    async function getDoc() {
      try {
        if (!isCreate) {
          setMessage("Fetch Intray Email");
          const iec = new IntrayEmail();
          const res = await iec.address_get(paramId);
          const row: TIntrayEmailAddress = res.data.row;
          setDoc(row);
          setValue("id", row.id);
          setValue("name", row.name);
          setValue("email", row.email);
          setMessage("");
        }
      } catch (error) {
        setMessage("");
        const errorMessage = errorHandler(error);
        setNotif({ type: "error", message: errorMessage });
        navigate(`../`, { replace: true });
      }
    },
    [setValue, paramId, isCreate, setNotif, setMessage, navigate]
  );

  useEffect(() => {
    getDoc();
  }, [getDoc]);

  const onSubmit: SubmitHandler<TIntrayEmailAddress> = async (data) => {
    try {
      setMessage("Save Intray Email");
      const iec = new IntrayEmail();
      let res;
      if (isCreate) {
        data.id = 0;
        res = await iec.address_create(data);
      } else {
        res = await iec.address_update(data.id.toString(), data);
      }
      setNotif({ type: "success", message: res.data.message });
      setMessage("");
      navigate(`../`, { replace: true });
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  const confirmDelete = async (emailAddress: TIntrayEmailAddress) => {
    try {
      const confirm = await Swal.fire({
        title: "Are you sure?",
        text: "Email address will be removed, You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (confirm.isConfirmed) {
        const iec = new IntrayEmail();
        await iec.address_delete(emailAddress.id.toString());
        setNotif({ type: "success", message: "email address deleted" });
        navigate(`../`, { replace: true });
      }
    } catch (error) {
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  return (
    <div className="w-full flex flex-col space-y-0.5">
      <div className="flex">
        <BackButton />
        <button
          onClick={handleSubmit(onSubmit)}
          className="mx-2 block w-auto px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded font-semibold text-sm"
        >
          <div className="flex">
            <ArrowDownTrayIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
            Save
          </div>
        </button>
        {!isCreate && (
          <button
            onClick={() => confirmDelete(doc)}
            className="ml-auto block w-auto px-4 py-2 bg-red-500 hover:bg-red-700 text-white rounded font-semibold text-sm"
          >
            <div className="flex">
              <TrashIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
              Delete
            </div>
          </button>
        )}
      </div>
      <div className="pt-4">
        <form className="w-full">
          <div className="flex flex-wrap -mx-3">
            <div className="w-full md:w-full px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Email</label>
              <input
                type="email"
                className={
                  (errors?.email ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                  " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                }
                {...register("email")}
              />
              {errors.email && <p className="text-red-500 text-xs italic">{errors.email.message}</p>}
            </div>
            <div className="w-full md:w-full px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Name</label>
              <input
                type="text"
                className={
                  (errors?.name ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                  " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                }
                {...register("name")}
              />
              {errors.name && <p className="text-red-500 text-xs italic">{errors.name.message}</p>}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default FormEmailAddress;
