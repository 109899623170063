import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useCallback, useContext, useEffect } from "react";

// Icons
import { XMarkIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";

// Contexts
import LoaderContext from "../../../../context/psikotest/LoaderContext";
import SnackbarContext from "../../../../context/psikotest/SnackbarContext";

// Helpers
import errorHandler from "../../../../helper/psikotest/errorHandler";

// Component
import FileUpload from "../../../../components/psikotest/psikotest_system/FileUpload";

const schema_base = {
    id: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required(),
    ist_id: yup
        .number()
        .label("IST ID")
        .transform((value) => (isNaN(value) ? undefined : value))
        .required(),
    ist_questionary_image: yup.string().label("Question").required(),
};

function FormQuestionary(props: { data?: TPSIstQuestionaryImage, ist: TPSIst, handleClose: () => void }) {
    const { setMessage } = useContext(LoaderContext)
    const { setNotif } = useContext(SnackbarContext)
    // set schema
    let schema: any = schema_base
    schema = yup.object().shape(schema)

    // react hook form
    const {
        register,
        formState: { errors },
        setValue,
        watch
    } = useForm<TPSIstQuestionaryImage>({ resolver: yupResolver(schema) });
    // watch all field
    const doc = watch()

    const getDoc = useCallback(
        async function getDoc() {
            try {
                // fetch document
                if (props.data === undefined) {
                    // update cfit_id
                    setValue("id", 0);
                    setValue("ist_id", props.ist.id);
                } else {
                    setValue("id", props.data?.id ?? 0);
                    setValue("ist_id", props.ist?.id ?? props.ist.id);
                    setValue("ist_questionary_image", props.data?.ist_questionary_image ?? "");
                }
                setMessage("");
            } catch (error) {
                setMessage("");
                const errorMessage = errorHandler(error);
                setNotif({ type: "error", message: errorMessage });
            }
        },
        [setValue, setNotif, setMessage, props.ist.id, props.data]
    );

    useEffect(() => {
        getDoc();
    }, [getDoc]);

    return <div>
        <div className="fixed z-20 top-0 left-0 w-screen h-screen bg-black opacity-20"></div>
        <div className="fixed z-30 top-0 left-0 w-screen h-screen bg-transparent flex items-center justify-center">
            <div className="bg-white w-screen md:w-[50%] h-auto md:rounded overflow-hidden">
                <div className="p-4 bg-green-800 md:rounded-t text-white flex justify-between">
                    <h3>Form Questionary</h3>
                    <XMarkIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent cursor-pointer" aria-hidden="true" onClick={() => props.handleClose()} />
                </div>
                <div className="p-4 pb-40">
                    <div className="pb-3">
                        {/** (MDI) Image */}
                        <div className="w-full md:w-full mb-1">
                            <FileUpload
                                type="ist_questionary_image"
                                editable={true}
                                document_id={doc.id}
                                filepath={doc.ist_questionary_image ?? ""}
                                error_field={errors.ist_questionary_image}
                                otherRequiredData={{ ist_id: props.ist.id }}
                                setDocumentId={(id) => {
                                    setValue("id", id)
                                }}
                                setFilePath={(filepath) => setValue("ist_questionary_image", filepath)}
                                key={`ist_questionary_image`}
                            />
                        </div>
                    </div>
                    <form className="w-full">
                        <div className="flex flex-wrap -mx-3">
                            <input {...register("id")} type="number" className="hidden" />
                            {errors.id && (
                                <p className="text-red-500 text-xs italic">{errors.id.message}</p>
                            )}
                            <input {...register("ist_id")} type="number" className="hidden" />
                            {errors.ist_id && (
                                <p className="text-red-500 text-xs italic">{errors.ist_id.message}</p>
                            )}
                        </div>
                    </form>
                    <div className="flex">
                        <div className="ml-auto block w-auto px-4 py-2 rounded font-semibold text-sm border border-green-300 text-green-500">
                            <div className="flex">
                                Auto save data after file selected
                                <ExclamationCircleIcon className="w-5 h-5 ml-2 stroke-green-500 fill-tranparent" aria-hidden="true"
                                    title="Auto save data after file selected" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    </div >
}


export default FormQuestionary;