import { useCallback, useContext, useEffect, useState } from "react";
import Select from "react-select";
import ReactQuill from "react-quill";
import { CheckCircleIcon, ChevronRightIcon, EnvelopeIcon, PencilIcon, PaperAirplaneIcon, DocumentTextIcon } from "@heroicons/react/24/outline";

// Context
import AssessmentContext from "../../../context/assessment/AssessmentContext";
import SnackbarContext from "../../../context/assessment/SnackbarContext";

// Controller
import AssessmentSessionParticipant from "../../../controller/assessment/assessment_participant/session";

// Handler
import errorHandler from "../../../helper/assessment/errorHandler";

// Component
import PdfViewer from "./PdfViewer";

function IntrayViewer(props: { participantSession: TPParticipantSession | undefined, activeSession: TPActiveSession }) {
  // Context
  const { participant } = useContext(AssessmentContext);
  const { setNotif } = useContext(SnackbarContext);
  // State
  const [intrayEmailList, setIntrayEmailList] = useState<TPQuestionaryIntrayEmail[]>([]);
  const [selectedEmail, setSelectedEmail] = useState<TPQuestionaryIntrayEmail>();
  const [intrayEmailReplyList, setIntrayEmailReplyList] = useState<TPQuestionaryReplyEmail[]>([]);
  const [intrayNewEmailList, setIntrayNewEmailList] = useState<TPQuestionaryNewEmail[]>([]);
  const [selectedNewEmail, setSelectedNewEmail] = useState<TPQuestionaryNewEmail>();
  const [openReply, setOpenReply] = useState<boolean>(false);
  const [openNewEmail, setOpenNewEmail] = useState<boolean>(false);
  const [selectedForwardEmail, setSelectedForwardEmail] = useState<TPQuestionaryIntrayEmail>();
  const [emailAddress, setEmailAddress] = useState<TReactSelect[]>([]);
  const [openInbox, setOpenInbox] = useState<boolean>(true);
  const [openSent, setOpenSent] = useState<boolean>(false);
  const [openFile, setOpenFile] = useState<boolean>(false);
  // Doc State
  const [sendTo, setSendTo] = useState<string[]>([]);
  const [copyTo, setCopyTo] = useState<string[]>([]);
  const [subject, setSubject] = useState<string>("");
  const [content, setContent] = useState<string>("");

  const getReplyEmail = async () => {
    try {
      // get all email reply
      if (props.participantSession !== undefined) {
        const aspc = new AssessmentSessionParticipant();
        const resIntrayEmailReplyList = await aspc.getAllReplyIntrayEmail(
          participant.assessment_id.toString(),
          participant.id.toString(),
          props.participantSession.id.toString()
        );
        // get reply
        let replyList: TPQuestionaryReplyEmail[] = resIntrayEmailReplyList.data.list.filter(
          (email: TPQuestionaryReplyEmail) => {
            return email.intray_simulation_email_id !== null;
          }
        );
        setIntrayEmailReplyList(replyList);
        // get new email with source email
        let newEmailList: TPQuestionaryNewEmail[] = resIntrayEmailReplyList.data.list.filter(
          (email: TPQuestionaryNewEmail) => {
            return email.intray_simulation_email_id === null;
          }
        );
        setIntrayNewEmailList(newEmailList);
      }
    } catch (error) {
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  const getDoc = useCallback(async () => {
    try {
      // Get all email
      const aspc = new AssessmentSessionParticipant();
      const resIntrayEmailList = await aspc.getIntrayEmail(
        participant.assessment_id.toString(),
        participant.id.toString()
      );
      setIntrayEmailList(resIntrayEmailList.data.row);
      // get reply & new email
      if (props.participantSession !== undefined) {
        const resIntrayEmailReplyList = await aspc.getAllReplyIntrayEmail(
          participant.assessment_id.toString(),
          participant.id.toString(),
          props.participantSession.id.toString()
        );
        // get reply
        let replyList: TPQuestionaryReplyEmail[] = resIntrayEmailReplyList.data.list.filter(
          (email: TPQuestionaryReplyEmail) => {
            return email.intray_simulation_email_id !== null;
          }
        );
        setIntrayEmailReplyList(replyList);
        // get new email with source email
        let newEmailList: TPQuestionaryNewEmail[] = resIntrayEmailReplyList.data.list.filter(
          (email: TPQuestionaryNewEmail) => {
            return email.intray_simulation_email_id === null;
          }
        );
        setIntrayNewEmailList(newEmailList);
      }
      // get all email address
      const resAllEmailAddress = await aspc.GetAllEmailAddressList(
        participant.assessment_id.toString(),
        participant.id.toString()
      );
      let allEmailAdress: TReactSelect[] = [];
      resAllEmailAddress.data.list.forEach((email: { id: number; email: string; name: string }) => {
        allEmailAdress.push({
          value: `${email.name}<${email.email}>`,
          label: `${email.name}<${email.email}>`,
        });
      });
      setEmailAddress(allEmailAdress);
    } catch (error) {
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  }, [setNotif, participant.assessment_id, participant.id, props.participantSession]);

  useEffect(() => {
    getDoc();
  }, [getDoc]);

  // calculate needed state for selected email && new email
  useEffect(() => {
    if (openNewEmail) {
      // set send to
      setSendTo([]);
      // set copy to
      setCopyTo([]);
      // set subject
      setSubject("");
      // set email
      setContent("<div><br/><br/><br/></div>");
    }
    if (selectedEmail) {
      // calculate select for sent to and copy to
      let emailList: TReactSelect[] = [];
      // push from to address list (for select to & cc)
      emailList.push({
        value: `${selectedEmail?.from_name}<${selectedEmail?.from_email}>`,
        label: `${selectedEmail?.from_name}<${selectedEmail?.from_email}>`,
      });
      let ccList: TReactSelect[] = [];
      selectedEmail?.copyto.forEach((cc, _) => {
        ccList.push({
          value: `${cc.name}<${cc.email}>`,
          label: `${cc.name}<${cc.email}>`,
        });
      });
      // can send to any address set it from getDoc
      // setEmailAddress([...emailList, ...ccList]);
      // set send to
      setSendTo([`${selectedEmail?.from_name}<${selectedEmail?.from_email}>`]);
      // set copy to
      setCopyTo(
        selectedEmail?.copyto.map((cc) => {
          return `${cc.name}<${cc.email}>`;
        }) ?? []
      );
      // set subject
      setSubject(`Re : ${selectedEmail?.subject}`);
      // set content reply
      setContent(`
        <div>
            <br/>
            <br/>
            <br/>
            ______________________________________
            <br/>
            <p>${selectedEmail?.from_name}</p>
            <p>Date : ${selectedEmail?.date}</p>
            ${selectedEmail?.copyto !== undefined && selectedEmail?.copyto.length > 0
          ? `<p className="mb-0 text-sm">
                    Cc :
                    ${selectedEmail?.copyto.map((cc, cci) => {
            return `<span>${cc.name}&lt;${cc.email}&gt;</span>`;
          })}   
                </p>`
          : ``
        }
            <br/>
            ${selectedEmail?.content}
        </div>`);
    }
    if (selectedNewEmail) {
      //console.log(selectedNewEmail);
    }
    if (selectedForwardEmail) {
      // set send to
      setSendTo([]);
      // set copy to
      setCopyTo([]);
      // set subject
      setSubject(`Fwd : ${selectedForwardEmail?.subject}`);
      // set content reply
      setContent(`
        <div>
            <br/>
            <br/>
            <br/>
            ______________________________________
            <br/>
            <p>${selectedForwardEmail?.from_name}</p>
            <p>Date : ${selectedForwardEmail?.date}</p>
            ${selectedForwardEmail?.copyto !== undefined && selectedForwardEmail?.copyto.length > 0
          ? `<p className="mb-0 text-sm">
                    Cc :
                    ${selectedForwardEmail?.copyto.map((cc, cci) => {
            return `<span>${cc.name}&lt;${cc.email}&gt;</span>`;
          })}   
                </p>`
          : ``
        }
            <br/>
            ${selectedForwardEmail?.content}
        </div>`);
    }
  }, [selectedEmail, openNewEmail, selectedNewEmail, selectedForwardEmail]);

  const sendReply = async (data: TPQuestionaryReplyEmail) => {
    try {
      // Get active session
      const aspc = new AssessmentSessionParticipant();
      await aspc.saveReplyIntrayEmail(participant.assessment_id.toString(), participant.id.toString(), data);
      // refetch reply
      await getReplyEmail();
      // close reply box
      setOpenReply(false);
    } catch (error) {
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  const sendEmail = async (data: TPQuestionaryNewEmail) => {
    try {
      // Get active session
      const aspc = new AssessmentSessionParticipant();
      await aspc.saveIntrayNewEmail(participant.assessment_id.toString(), participant.id.toString(), data);
      // refetch reply
      await getReplyEmail();
      // close new email if for new email
      if (openNewEmail) {
        setOpenNewEmail(false);
      }
      // close forward if for forward email
      if (selectedForwardEmail !== undefined) {
        setSelectedForwardEmail(undefined);
      }
      // close new email
      setOpenNewEmail(false);
    } catch (error) {
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  return (
    <div className="h-[94%] flex overflow-hidden">
      <div className="w-1/6 border-r overflow-auto">
        <div
          onClick={() => {
            setSelectedEmail(undefined);
            setSelectedNewEmail(undefined);
            setSelectedForwardEmail(undefined);
            setOpenNewEmail(false);
            setOpenFile(true);
          }}
          className="px-4 py-2 border-b flex space-x-2 bg-gray-300 hover:bg-gray-500 hover:text-white hover:cursor-pointer"
        >
          <DocumentTextIcon className="w-5" />
          <span className="font-bold">Panduan Simulasi</span>
        </div>
        <div
          onClick={() => {
            setSelectedEmail(undefined);
            setSelectedNewEmail(undefined);
            setSelectedForwardEmail(undefined);
            setOpenNewEmail(true);
            setOpenFile(false);
          }}
          className="px-4 py-2 border-b flex space-x-2 bg-blue-300 hover:bg-blue-500 hover:text-white hover:cursor-pointer"
        >
          <PencilIcon className="w-5" />
          <span className="font-bold">Compose</span>
        </div>
        {/* Mail List Select (Inbox) */}
        <div
          className="px-4 py-2 border-b flex bg-green-300 hover:bg-green-500 hover:text-white hover:cursor-pointer"
          onClick={() => setOpenInbox(!openInbox)}
        >
          <EnvelopeIcon className="w-5" />
          <span className="font-bold pl-2">Inbox</span>
          {intrayEmailList.length > 0 && (
            <span className="ml-auto px-2 bg-green-700 font-bold text-white rounded text-sm flex items-center">
              {intrayEmailList.length}
            </span>
          )}
        </div>
        {openInbox && (
          <>
            {intrayEmailList.map((email, i) => (
              <div
                key={`email_${i}`}
                className={`px-4 py-2 max-h-20 overflow-hidden border-b hover:border-l-2 hover:border-l-blue-500 hover:bg-slate-200 hover:text-gray-600 cursor-pointer ${email.id === selectedEmail?.id
                  ? "bg-blue-900 text-white border-l-2 border-l-blue-500"
                  : "text-gray-600"
                  }`}
                onClick={() => {
                  setSelectedEmail(email);
                  setSelectedNewEmail(undefined);
                  setSelectedForwardEmail(undefined);
                  setOpenNewEmail(false);
                  setOpenFile(false);
                }}
              >
                <div className="font-semibold text-sm flex space-x-1">
                  {intrayEmailReplyList.filter(
                    (replyAll) => replyAll.intray_simulation_email_id === email.intray_simulation_email_id
                  ).length > 0 && <CheckCircleIcon className="w-5 text-green-400" />}
                  <span>{email.from_name}</span>
                </div>
                <div className="text-sm flex">
                  <ChevronRightIcon className="w-4" />
                  {email.subject}
                </div>
                <div className="font-light text-sm">
                  {email.content.replace(/<(?:.|\n)*?>/gm, "").substring(0, 30)}...
                </div>
              </div>
            ))}
          </>
        )}
        {/* Mail List Select (New Email - Compose / Forward) */}
        <div
          className="px-4 py-2 border-b flex bg-red-300 hover:cursor-pointer hover:bg-red-400"
          onClick={() => setOpenSent(!openSent)}
        >
          <PaperAirplaneIcon className="w-5" />
          <span className="font-bold pl-2">Sent</span>
          {intrayNewEmailList.length > 0 && (
            <span className="ml-auto px-2 bg-blue-500 font-bold text-white rounded text-sm flex items-center">
              {intrayNewEmailList.length}
            </span>
          )}
        </div>
        {openSent && (
          <>
            {intrayNewEmailList.map((email, i) => (
              <div
                key={`email_${i}`}
                className={`px-4 py-2 max-h-20 overflow-hidden border-b hover:border-l-2 hover:border-l-blue-500 hover:bg-slate-200 hover:text-gray-600 cursor-pointer ${email.id === selectedNewEmail?.id
                  ? "bg-blue-900 text-white border-l-2 border-l-blue-500"
                  : "text-gray-600"
                  }`}
                onClick={() => {
                  setSelectedEmail(undefined);
                  setSelectedNewEmail(email);
                  setSelectedForwardEmail(undefined);
                  setOpenNewEmail(false);
                  setOpenFile(false);
                }}
              >
                <div className="text-sm flex">
                  <ChevronRightIcon className="w-4" />
                  {email.subject}
                </div>
                <div className="font-light text-sm">
                  {email.content.replace(/<(?:.|\n)*?>/gm, "").substring(0, 30)}...
                </div>
              </div>
            ))}
          </>
        )}
      </div>

      {/* Mail Display */}
      <div className="w-5/6 overflow-auto">
        {/* Selected Email - Inbox Reply */}
        {selectedEmail && (
          <div className="h-full flex flex-col">
            <div className="flex items-center px-4 py-2 font-semibold sticky top-0 bg-white z-10 border-b">
              {selectedEmail.subject}
              {openReply === false && (
                <div className="ml-auto space-x-2">
                  <button type="button" className="font-semibold text-blue-700" onClick={() => setOpenReply(true)}>
                    Reply
                  </button>
                  <button
                    type="button"
                    className="font-semibold text-blue-700"
                    onClick={() => {
                      setSelectedEmail(undefined);
                      setSelectedNewEmail(undefined);
                      setSelectedForwardEmail(selectedEmail);
                      setOpenNewEmail(false);
                      setOpenFile(false);
                    }}
                  >
                    Forward
                  </button>
                </div>
              )}
            </div>
            {openReply && (
              <div className="border p-4 space-y-1">
                {/* To */}
                <div className="flex items-center">
                  <div className="pr-4">To</div>
                  <Select
                    isMulti
                    className="w-full"
                    placeholder=""
                    onChange={(value, _) => {
                      setSendTo(
                        value.map((v) => {
                          return v.value;
                        })
                      );
                    }}
                    value={sendTo.map((cc, _) => {
                      return { label: cc, value: cc };
                    })}
                    options={emailAddress}
                  />
                </div>
                {/* Cc */}
                <div className="flex items-center">
                  <div className="pr-4">Cc</div>
                  <Select
                    isMulti
                    className="w-full"
                    placeholder=""
                    onChange={(value, _) => {
                      setCopyTo(
                        value.map((v) => {
                          return v.value;
                        })
                      );
                    }}
                    value={copyTo.map((cc, _) => {
                      return { label: cc, value: cc };
                    })}
                    options={emailAddress}
                  />
                </div>
                {/* Subject */}
                <div className="flex items-center">
                  <div className="pr-4">Subject</div>
                  <input
                    type="text"
                    className="w-full border px-2 py-1 rounded border-gray-300 focus:outline-2 focus:outline-blue-500"
                    value={subject}
                    onChange={(e) => setSubject(e.currentTarget.value)}
                  />
                </div>
                {/* Content */}
                <ReactQuill
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, false] }],
                      ["bold", "italic", "underline", { color: [] }, "strike", "blockquote"],
                      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }, { align: [] }],
                    ],
                  }}
                  theme="snow"
                  value={content}
                  onChange={(content: string) => setContent(content)}
                />
                {/* Email */}
                <div className="pt-4 space-x-2">
                  <button
                    type="button"
                    className="font-semibold border py-1 px-4 rounded bg-blue-500 text-white hover:bg-blue-600 disabled:bg-gray-300"
                    onClick={() => {
                      if (props.participantSession?.id !== undefined) {
                        sendReply({
                          assessment_participant_session_id: props.participantSession?.id,
                          intray_simulation_email_id: selectedEmail.intray_simulation_email_id,
                          sendto: sendTo.join(","),
                          copyto: copyTo.join(","),
                          subject,
                          content,
                        });
                      }
                    }}
                    disabled={!(sendTo.length > 0 && subject.trim() !== "")}
                  >
                    Send
                  </button>
                  <button
                    type="button"
                    className="font-semibold border py-1 px-4 rounded text-blue-500 border-blue-500"
                    onClick={() => setOpenReply(false)}
                  >
                    Discard
                  </button>
                </div>
              </div>
            )}
            {/* Open if reply avaible */}
            {selectedEmail && intrayEmailReplyList.length > 0 && (
              <div className="w-full">
                {intrayEmailReplyList
                  .filter(
                    (replyUnfilter) =>
                      replyUnfilter.intray_simulation_email_id === selectedEmail.intray_simulation_email_id
                  )
                  .map((reply, iReply) => (
                    <div key={`reply_${iReply}`} className="border p-4">
                      <div className="flex">
                        <CheckCircleIcon className="w-5 text-green-500" />
                        <span className="pl-2">{reply.subject}</span>
                      </div>
                      <p className="mb-0 text-sm">Date : {reply.date}</p>
                      <p className="mb-0 text-sm">To : {reply.sendto}</p>
                      <p className="mb-0 text-sm">Cc : {reply.copyto}</p>
                      <div className="py-4 text-sm" dangerouslySetInnerHTML={{ __html: reply.content }}></div>
                    </div>
                  ))}
              </div>
            )}
            {/* Open Email Selected */}
            <div className="border p-4">
              <p className="mb-0">{selectedEmail.from_name}</p>
              <p className="mb-0 text-sm">Date : {selectedEmail.date}</p>
              {selectedEmail.copyto.length > 0 && (
                <p className="mb-0 text-sm">
                  Cc :{" "}
                  {selectedEmail.copyto.map((cc, cci) => (
                    <span key={`cc_${cci}`}>{`${cc.name}<${cc.email}>`}</span>
                  ))}
                </p>
              )}
              <div className="py-4 text-sm" dangerouslySetInnerHTML={{ __html: selectedEmail.content }}></div>
              {openReply === false && (
                <div>
                  <button
                    type="button"
                    className="font-semibold border py-1 px-4 rounded bg-blue-500 text-white hover:bg-blue-600"
                    onClick={() => setOpenReply(true)}
                  >
                    Reply
                  </button>
                  <button
                    type="button"
                    className="font-semibold border py-1 px-4 ml-1 rounded border-blue-500 text-blue-500"
                    onClick={() => {
                      setSelectedEmail(undefined);
                      setSelectedNewEmail(undefined);
                      setSelectedForwardEmail(selectedEmail);
                      setOpenNewEmail(false);
                      setOpenFile(false);
                    }}
                  >
                    Forward
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
        {/* Selected Email - Compose */}
        {selectedNewEmail && (
          <div className="h-full flex flex-col">
            <div className="flex items-center px-4 py-2 font-semibold sticky top-0 bg-white z-10 border-b">
              {selectedNewEmail.subject}
            </div>
            <div className="border p-4">
              <p className="mb-0">{selectedNewEmail.sendto}</p>
              <p className="mb-0 text-sm">Date : {selectedNewEmail.date}</p>
              <p className="mb-0 text-sm">CC : {selectedNewEmail.copyto}</p>
              <div className="py-4 text-sm" dangerouslySetInnerHTML={{ __html: selectedNewEmail.content }}></div>
            </div>
          </div>
        )}
        {/* Forward Email */}
        {selectedForwardEmail && (
          <div className="h-full flex flex-col">
            <div className="border p-4 space-y-1">
              {/* To */}
              <div className="flex items-center">
                <div className="pr-4">To</div>
                <Select
                  isMulti
                  className="w-full"
                  placeholder=""
                  onChange={(value, _) => {
                    setSendTo(
                      value.map((v) => {
                        return v.value;
                      })
                    );
                  }}
                  value={sendTo.map((cc, _) => {
                    return { label: cc, value: cc };
                  })}
                  options={emailAddress}
                />
              </div>
              {/* Cc */}
              <div className="flex items-center">
                <div className="pr-4">Cc</div>
                <Select
                  isMulti
                  className="w-full"
                  placeholder=""
                  onChange={(value, _) => {
                    setCopyTo(
                      value.map((v) => {
                        return v.value;
                      })
                    );
                  }}
                  value={copyTo.map((cc, _) => {
                    return { label: cc, value: cc };
                  })}
                  options={emailAddress}
                />
              </div>
              {/* Subject */}
              <div className="flex items-center">
                <div className="pr-4">Subject</div>
                <input
                  type="text"
                  className="w-full border px-2 py-1 rounded border-gray-300 focus:outline-2 focus:outline-blue-500"
                  value={subject}
                  onChange={(e) => setSubject(e.currentTarget.value)}
                />
              </div>
              {/* Content */}
              <ReactQuill
                modules={{
                  toolbar: [
                    [{ header: [1, 2, false] }],
                    ["bold", "italic", "underline", { color: [] }, "strike", "blockquote"],
                    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }, { align: [] }],
                  ],
                }}
                theme="snow"
                value={content}
                onChange={(content: string) => setContent(content)}
              />
              {/* Email */}
              <div className="pt-4 space-x-2">
                <button
                  type="button"
                  className="font-semibold border py-1 px-4 rounded bg-blue-500 text-white hover:bg-blue-600 disabled:bg-gray-300"
                  onClick={() => {
                    if (props.participantSession?.id !== undefined) {
                      sendEmail({
                        assessment_participant_session_id: props.participantSession?.id,
                        intray_simulation_email_id: null,
                        sendto: sendTo.join(","),
                        copyto: copyTo.join(","),
                        subject,
                        content,
                      });
                    }
                  }}
                  disabled={!(sendTo.length > 0 && subject.trim() !== "")}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        )}
        {/* New Email */}
        {openNewEmail && (
          <div className="h-full flex flex-col">
            <div className="border p-4 space-y-1">
              {/* To */}
              <div className="flex items-center">
                <div className="pr-4">To</div>
                <Select
                  isMulti
                  className="w-full"
                  placeholder=""
                  onChange={(value, _) => {
                    setSendTo(
                      value.map((v) => {
                        return v.value;
                      })
                    );
                  }}
                  value={sendTo.map((cc, _) => {
                    return { label: cc, value: cc };
                  })}
                  options={emailAddress}
                />
              </div>
              {/* Cc */}
              <div className="flex items-center">
                <div className="pr-4">Cc</div>
                <Select
                  isMulti
                  className="w-full"
                  placeholder=""
                  onChange={(value, _) => {
                    setCopyTo(
                      value.map((v) => {
                        return v.value;
                      })
                    );
                  }}
                  value={copyTo.map((cc, _) => {
                    return { label: cc, value: cc };
                  })}
                  options={emailAddress}
                />
              </div>
              {/* Subject */}
              <div className="flex items-center">
                <div className="pr-4">Subject</div>
                <input
                  type="text"
                  className="w-full border px-2 py-1 rounded border-gray-300 focus:outline-2 focus:outline-blue-500"
                  value={subject}
                  onChange={(e) => setSubject(e.currentTarget.value)}
                />
              </div>
              {/* Content */}
              <ReactQuill
                modules={{
                  toolbar: [
                    [{ header: [1, 2, false] }],
                    ["bold", "italic", "underline", { color: [] }, "strike", "blockquote"],
                    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }, { align: [] }],
                  ],
                }}
                theme="snow"
                value={content}
                onChange={(content: string) => setContent(content)}
              />
              {/* Email */}
              <div className="pt-4 space-x-2">
                <button
                  type="button"
                  className="font-semibold border py-1 px-4 rounded bg-blue-500 text-white hover:bg-blue-600 disabled:bg-gray-300"
                  onClick={() => {
                    if (props.participantSession?.id !== undefined) {
                      sendEmail({
                        assessment_participant_session_id: props.participantSession?.id,
                        intray_simulation_email_id: null,
                        sendto: sendTo.join(","),
                        copyto: copyTo.join(","),
                        subject,
                        content,
                      });
                    }
                  }}
                  disabled={!(sendTo.length > 0 && subject.trim() !== "")}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        )}
        {/* No Create and Open Email */}
        {selectedEmail === undefined &&
          selectedNewEmail === undefined &&
          selectedForwardEmail === undefined &&
          openNewEmail === false && (
            <>
              {openFile ? <PdfViewer pdfUrl={props.activeSession?.file_path ?? ""} /> :
                <div className="h-full flex justify-center items-center font-bold text-gray-400">
                  <div className="h-40 w-40 flex flex-col justify-center items-center border border-blue-200 bg-blue-50 rounded-full">
                    <EnvelopeIcon className="w-10 text-blue-300" />
                    No Open Email
                  </div>
                </div>}
            </>
          )}
      </div>
    </div>
  );
}

export default IntrayViewer;
