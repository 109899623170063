import { useContext } from "react";
import { NavLink } from "react-router-dom";

// Icons
import { BuildingOffice2Icon, DocumentTextIcon, ShieldCheckIcon, HomeIcon, EnvelopeIcon, BookmarkIcon, ArrowTopRightOnSquareIcon, ClipboardDocumentListIcon } from "@heroicons/react/24/outline";

// Context
import AuthContext from "../../../context/assessment/AuthContext";

function MenuDesktop() {
  const { user } = useContext(AuthContext);

  return (
    <nav className="block sticky top-[4.5rem]">
      <ul className="space-y-0.5">
        <li className="flex flex-col">
          <div className="px-2 py-2 text-xs font-semibold">MENU</div>
        </li>
        <li className="group flex flex-col">
          <NavLink
            to={``}
            end
            className={({ isActive }) =>
              `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
              } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
            }
            children={({ isActive }) => (
              <div className="flex">
                <HomeIcon
                  className={`${isActive ? "stroke-white" : "stroke-gray-700 group-hover:stroke-white"
                    } w-5 h-5 mr-2 fill-tranparent`}
                  aria-hidden="true"
                />
                Dashboard
              </div>
            )}
          />
        </li>
        <li className="group flex flex-col">
          <NavLink
            to={`assessment`}
            className={({ isActive }) =>
              `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
              } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
            }
            children={({ isActive }) => (
              <div className="flex">
                <ShieldCheckIcon
                  className={`${isActive ? "stroke-white" : "stroke-gray-700 group-hover:stroke-white"
                    } w-5 h-5 mr-2 fill-tranparent`}
                  aria-hidden="true"
                />
                Assessment
              </div>
            )}
          />
        </li>
        {user.roles === "1" && (
          <li className="group flex flex-col">
            <NavLink
              to={`company`}
              className={({ isActive }) =>
                `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
              }
              children={({ isActive }) => (
                <div className="flex">
                  <BuildingOffice2Icon
                    className={`${isActive ? "stroke-white" : "stroke-gray-700 group-hover:stroke-white"
                      } w-5 h-5 mr-2 fill-tranparent`}
                    aria-hidden="true"
                  />
                  Company
                </div>
              )}
            />
          </li>
        )}
        {user.roles === "1" && (
          <>
            {/** Questionary Sub Menu */}
            < li className="flex flex-col items-center">
              <div className="flex px-2 pt-2 text-xs font-semibold text-center">
                <ClipboardDocumentListIcon
                  className={`stroke-gray-700 w-4 mr-2 fill-tranparent`}
                  aria-hidden="true"
                />
                Questionary
              </div>
            </li>
            <li className="group flex flex-col">
              <NavLink
                to={`intray_email`}
                className={({ isActive }) =>
                  `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                  } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
                }
                children={({ isActive }) => (
                  <div className="flex">
                    <EnvelopeIcon
                      className={`${isActive ? "stroke-white" : "stroke-gray-700 group-hover:stroke-white"
                        } w-5 h-5 mr-2 fill-tranparent`}
                      aria-hidden="true"
                    />
                    Intray Email
                  </div>
                )}
              />
            </li>
            <li className="group flex flex-col">
              <NavLink
                to={`questionary`}
                className={({ isActive }) =>
                  `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                  } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
                }
                children={({ isActive }) => (
                  <div className="flex">
                    <DocumentTextIcon
                      className={`${isActive ? "stroke-white" : "stroke-gray-700 group-hover:stroke-white"
                        } w-5 h-5 mr-2 fill-tranparent`}
                      aria-hidden="true"
                    />
                    Questionary
                  </div>
                )}
              />
            </li>
          </>
        )}
        <hr />
        <li className="group flex flex-col">
          <NavLink
            to={`dictionary`}
            className={({ isActive }) =>
              `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
              } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
            }
            children={({ isActive }) => (
              <div className="flex">
                <BookmarkIcon
                  className={`${isActive ? "stroke-white" : "stroke-gray-700 group-hover:stroke-white"
                    } w-5 h-5 mr-2 fill-tranparent`}
                  aria-hidden="true"
                />
                Dictionary
              </div>
            )}
          />
        </li>
        {/** Other Links */}
        <li className="flex flex-col">
          <div className="flex px-2 pt-2 text-xs font-semibold text-center uppercase mt-10 mb-2">
            Other Links
          </div>
        </li>
        <li className="group flex flex-col">
          <NavLink
            to={`/psikotest_system`}
            target="_blank"
            className={`text-gray-900 flex items-center font-semibold w-full px-2 py-2 text-sm border rounded bg-green-50 group-hover:bg-green-500 group-hover:text-white`}
            children={({ isActive }) => (
              <div className="flex">
                <ArrowTopRightOnSquareIcon
                  className={`${isActive ? "stroke-white" : "stroke-gray-700 group-hover:stroke-white"
                    } w-5 h-5 mr-2 fill-tranparent`}
                  aria-hidden="true"
                />
                Psikotest System
              </div>
            )}
          />
        </li>
      </ul>
    </nav>
  );
}

export default MenuDesktop;
