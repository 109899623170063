import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState, useContext, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Disclosure, Transition } from "@headlessui/react";
import Swal from "sweetalert2";

// Icons
import { ArrowDownOnSquareIcon, TrashIcon, ChevronUpIcon } from "@heroicons/react/24/outline";

// Context
import LoaderContext from "../../../context/psikotest/LoaderContext";
import SnackbarContext from "../../../context/psikotest/SnackbarContext";

// Helper
import errorHandler from "../../../helper/psikotest/errorHandler";
import logRender from "../../../helper/psikotest/logRender";

// Controller
import Cfit from "../../../controller/psikotest/psikotest_system/cfit";

// Component
import BackButton from "../../../components/psikotest/psikotest_system/BackButton";

// Other Section
import ListQuestionary from "./ListQuestionary";

const schema = yup.object().shape({
  id: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required(),
  test_name: yup
    .string()
    .label("Test Name")
    .transform((value) => (value === null ? "" : value))
    .required(),
  mdi: yup
    .string()
    .label("(MDI) Main Question | Description | Instructions")
    .transform((value) => (value === null ? "" : value))
    .required(),
  time_limit: yup
    .number()
    .label("Time Limit")
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(1)
    .required(),
  minimum_selected_answer: yup
    .number()
    .label("Minimum of Selected Answer")
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(1)
    .required(),
});

function Form() {
  const { setMessage } = useContext(LoaderContext);
  const { setNotif } = useContext(SnackbarContext);
  const navigate = useNavigate();
  // parse param id
  const location = useLocation();
  // remove trailing slash in production when reload/refresh
  const arrPath = location.pathname.replace(/\/$/, "").split("/");
  const paramId = arrPath[arrPath.length - 1];
  // state document
  const [doc, setDoc] = useState<TPSCfit>({
    id: 0,
    test_name: "",
    mdi: "",
    time_limit: 0,
    minimum_selected_answer: 0,
  });
  // is create
  const isCreate = paramId === "create";
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<TPSCfit>({ resolver: yupResolver(schema) });

  useEffect(() => {
    logRender({ type: "page", name: "cfit/Form" });
  }, []);

  const getDoc = useCallback(
    async function getDoc() {
      try {
        // fetch document
        if (!isCreate) {
          // fetch cfit document
          setMessage("Fetch Document");
          const cc = new Cfit();
          const res = await cc.get(paramId);
          const row: TPSCfit = res.data.row;
          setDoc(row);
          setValue("id", row.id);
          setValue("test_name", row.test_name);
          setValue("mdi", row.mdi);
          setValue("time_limit", row.time_limit);
          setValue("minimum_selected_answer", row.minimum_selected_answer);
        }
        setMessage("");
      } catch (error) {
        setMessage("");
        const errorMessage = errorHandler(error);
        setNotif({ type: "error", message: errorMessage });
        navigate(`../`, { replace: true });
      }
    },
    [setValue, paramId, isCreate, setNotif, setMessage, navigate]
  );

  useEffect(() => {
    getDoc();
  }, [getDoc]);

  const onSubmit: SubmitHandler<TPSCfit> = async (data) => {
    try {
      setMessage("Save Cfit");
      const cc = new Cfit();
      let res;
      if (isCreate) {
        data.id = 0;
        res = await cc.create(data);
      } else {
        res = await cc.update(data);
      }
      setNotif({ type: "success", message: res.data.message });
      setMessage("");
      // when create new navigate to result form
      if (isCreate) {
        navigate(`../${res.data.result.saved_id}`, { replace: true });
      } else {
        navigate(`../`, { replace: true });
      }
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  const confirmDelete = async (cfit: TPSCfit) => {
    try {
      const confirm = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (confirm.isConfirmed) {
        const cc = new Cfit();
        await cc.delete(cfit);
        setNotif({ type: "success", message: "cfit deleted" });
        navigate(`../`, { replace: true });
      }
    } catch (error) {
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  return (
    <div className="w-full flex flex-col space-y-0.5">
      <div className="flex">
        <BackButton />
        <button
          onClick={handleSubmit(onSubmit)}
          className="mx-2 block w-auto px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded font-semibold text-sm"
        >
          <div className="flex">
            <ArrowDownOnSquareIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
            Save
          </div>
        </button>
        {!isCreate && (
          <button
            onClick={() => confirmDelete(doc)}
            className="ml-auto block w-auto px-4 py-2 bg-red-500 hover:bg-red-700 text-white rounded font-semibold text-sm"
          >
            <div className="flex">
              <TrashIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
              Delete
            </div>
          </button>
        )}
      </div>
      {/* MAIN SECTION */}
      <div className="pt-4">
        {/* Main Form */}
        <form className="w-full">
          <div className="flex flex-wrap -mx-3">
            <input {...register("id")} type="number" className="hidden" />
            {/** Subtest */}
            <div className="w-full md:w-full px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Subtest Name
              </label>
              <input
                className={
                  (errors?.test_name ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                  " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                }
                {...register("test_name")}
              />
              {errors.test_name && (
                <p className="text-red-500 text-xs italic">{errors.test_name.message}</p>
              )}
            </div>
            {/** (MDI) Main Question | Description | Instructions */}
            <div className="w-full md:w-full px-3 mb-6">
              <label className="text-gray-700 text-xs mb-2">
                <span className="block uppercase tracking-wide font-bold">(MDI) Main Question | Description | Instructions</span>
                <i>Main question/description/intructions for sub-test, always show up until test ended.</i>
              </label>
              <textarea
                className={
                  (errors?.mdi ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                  " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                }
                rows={4}
                {...register("mdi")}
              />
              {errors.mdi && <p className="text-red-500 text-xs italic">{errors.mdi.message}</p>}
              <input {...register("id")} type="number" className="hidden" />
            </div>
            {/** Time Limit (Minute) */}
            <div className="w-full md:w-1/2 px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Time Limit (Minute)
              </label>
              <input
                type="number"
                className={
                  (errors?.time_limit ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                  " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                }
                {...register("time_limit")}
              />
              {errors.time_limit && (
                <p className="text-red-500 text-xs italic">{errors.time_limit.message}</p>
              )}
            </div>
            {/** Minimum of Selected Answer */}
            <div className="w-full md:w-1/2 px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Minimum of Selected Answer
              </label>
              <input
                type="number"
                className={
                  (errors?.minimum_selected_answer ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                  " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                }
                {...register("minimum_selected_answer")}
              />
              {errors.minimum_selected_answer && (
                <p className="text-red-500 text-xs italic">{errors.minimum_selected_answer.message}</p>
              )}
            </div>
          </div>
        </form>
        {/** CFIT Questionary */}
        {!isCreate &&
          <Disclosure defaultOpen={true} as="div" className="pb-1">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between w-full px-4 py-4 text-sm font-medium text-left text-gray-100 bg-blue-900 rounded hover:bg-blue-800 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                  <span>CFIT Questionary</span>
                  <ChevronUpIcon className={`${open ? "transform rotate-180" : ""} w-5 h-5 text-gray-100`} />
                </Disclosure.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                    <ListQuestionary isCreate={isCreate} cfit={doc} editable={true} />
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        }
      </div>
    </div >
  );
}

export default Form;
