import { useCallback, useContext, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { CheckCircleIcon, ArrowUpTrayIcon } from "@heroicons/react/24/outline";

// Context
import AssessmentContext from "../../../context/assessment/AssessmentContext";
import SnackbarContext from "../../../context/assessment/SnackbarContext";
import WebSocketContext from "../../../context/WebSocketContext";

// Controller
import AssessmentSessionParticipant from "../../../controller/assessment/assessment_participant/session";

// Helper
import logRender from "../../../helper/assessment/logRender";
import errorHandler from "../../../helper/assessment/errorHandler";

// Component
import Header from "../../../components/assessment/assessment_participant/Header";
import PdfViewer from "../../../components/assessment/assessment_participant/PdfViewer";
import IntrayViewer from "../../../components/assessment/assessment_participant/IntrayViewer";
import FormUpload from "../../../components/assessment/assessment_participant/FormUpload";

function Session() {
  // Context
  const { assessment, participant, timeRemaining, setTimeRemaining } = useContext(AssessmentContext);
  const { setNotif } = useContext(SnackbarContext);
  const { socket } = useContext(WebSocketContext)
  // Ref
  const webcamRef = useRef<Webcam>(null);
  // State
  const [printScreen, setPrintScreen] = useState<boolean>(false);
  const [controlKeyPressed, setControlKeyPressed] = useState<boolean>(false);
  const [isCheckActiveSession, setIsCheckActiveSession] = useState<boolean>(false);
  const [activeSession, setActiveSession] = useState<TPActiveSession>();
  const [participantSession, setParticipantSession] = useState<TPParticipantSession>();
  const [showFormUpload, setShowFormUpload] = useState<boolean>(false);
  const [isCheckCamera, setIsCheckCamera] = useState<boolean>(false);
  const [cameraAllowed, setCameraAllowed] = useState<boolean>(false);
  const [captureImage, setCaptureImage] = useState<string>();
  // screencap every x second = default 5 minutes
  const [intervalGetImage, setIntervalGetImage] = useState<number>(60);
  // interval check timer x second = default 105 second
  const intervalCheckTimer = 105;
  // helper detect combo print screen in mac os
  // UNTESTED
  const [shiftPressed, setShiftPressed] = useState<boolean>(false);
  const [metaPressed, setMetaPressed] = useState<boolean>(false);

  // socket event
  socket.onmessage = (event) => {
    const wsdata = JSON.parse(JSON.parse(event.data)?.body ?? "");
    const scope = wsdata?.scope;
    const target = wsdata?.target;
    const action = wsdata?.action;
    const message = wsdata?.message;
    if (scope === "assessment" && target === "participant") {
      if (action === "check open session") {
        getActiveSession();
      }
      if (message) {
        setNotif({ type: "info", message: message });
      }
    }
  };

  // disable print screen
  window.addEventListener("keydown", (event) => {
    if (event.key === "PrintScreen") {
      event.preventDefault()
      setPrintScreen(true);
      setTimeout(() => {
        setPrintScreen(false);
      }, 1000);
    }
    if (event.key === "Control") {
      event.preventDefault()
      setControlKeyPressed(true);
      setTimeout(() => {
        setControlKeyPressed(false);
      }, 1000);
    }
  });
  // UNTESTED
  // disable print screen in mac os
  // track shifkey
  window.addEventListener("keydown", (event) => {
    if (event.key === "shiftKey") {
      setShiftPressed(true)
    }
  });
  window.addEventListener("keyup", (event) => {
    if (event.key === "shiftKey") {
      setShiftPressed(false)
    }
  });
  // track metakey (command)
  window.addEventListener("keydown", (event) => {
    if (event.metaKey) {
      setMetaPressed(true)
    }
  });
  window.addEventListener("keyup", (event) => {
    if (event.metaKey) {
      setMetaPressed(false)
    }
  });
  // detect print screen 
  window.addEventListener("keyup", (event) => {
    // console.log(event.key, event.key === "3" && shiftPressed && metaPressed)
    if (event.key === "3" && shiftPressed && metaPressed) {
      setPrintScreen(true);
      setTimeout(() => {
        setPrintScreen(false);
      }, 1000);
    }
  });
  // UNTESTED

  useEffect(() => {
    logRender({ type: "page", name: "assessment/Session" });
  }, []);

  const getActiveSession = useCallback(async () => {
    try {
      // Get active session
      const aspc = new AssessmentSessionParticipant();
      const resActiveSession = await aspc.getActiveAssessment(assessment.assessment_id.toString(), participant.id.toString());
      setActiveSession(resActiveSession.data.row);
      if (resActiveSession.data.row?.setting?.snap_interval_assessment) {
        const interval = parseInt(resActiveSession.data.row?.setting?.snap_interval_assessment);
        if (!isNaN(interval)) {
          setIntervalGetImage(interval)
        }
      }
    } catch (error) {
      // no notification here we avoid conflict message from websocket
      // const errorMessage = errorHandler(error);
      // setNotif({ type: "error", message: errorMessage });
      // reset when assessment closed
      setActiveSession(undefined);
    }
    setIsCheckActiveSession(true);
  }, [assessment.assessment_id, participant.id]);

  // get open session
  useEffect(() => {
    getActiveSession();
  }, [getActiveSession]);

  const getParticipantSession = useCallback(async () => {
    try {
      // Get Participant Session Progress (time and file answer)
      const aspc = new AssessmentSessionParticipant();
      const resParticipantSession = await aspc.getParticipantAssessment(
        participant.id.toString(),
        activeSession?.id.toString() ?? ""
      );
      setParticipantSession(resParticipantSession.data.row);
    } catch (error) {
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  }, [activeSession?.id, setNotif, participant.id]);

  useEffect(() => {
    if (activeSession !== undefined) {
      getParticipantSession();
    }
  }, [activeSession, getParticipantSession]);

  // to make sure remaining time is correct
  // set remaining time context
  useEffect(() => {
    if (timeRemaining !== null && timeRemaining > 0) {
      if (Math.floor(timeRemaining % intervalCheckTimer) === 0) {
        console.log(new Date() + " check timer");
        getParticipantSession();
      }
    }
  }, [timeRemaining, getParticipantSession]);

  // set remaining time context
  useEffect(() => {
    if (participantSession?.remaining_time !== undefined && participantSession?.remaining_time !== null) {
      setTimeRemaining(participantSession.remaining_time);
    }
  }, [participantSession?.remaining_time, setTimeRemaining]);

  const startAssessment = async () => {
    try {
      // Get Participant Session Progress (time and file answer)
      const aspc = new AssessmentSessionParticipant();
      await aspc.startAssessment(participantSession?.id.toString() ?? "", {
        time_limit: activeSession?.time_limit ?? 0,
      });
      getParticipantSession();
    } catch (error) {
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  const getCamera = useCallback(async () => {
    if (timeRemaining !== null && timeRemaining > 0) {
      try {
        await navigator.mediaDevices.getUserMedia({ video: true });
        setCameraAllowed(true);
      } catch (error) {
        setCameraAllowed(false);
      }
      setIsCheckCamera(true);
      // console.log(timeRemaining, intervalGetImage)
      if (Math.floor(timeRemaining % intervalGetImage) === 0) {
        if (webcamRef !== null && webcamRef.current !== null) {
          const image = webcamRef.current.getScreenshot();
          if (image !== null) {
            console.log(new Date() + " capt image");
            setCaptureImage(image);
          }
        }
      }
    }
  }, [timeRemaining, intervalGetImage]);

  // check camera is open when remaining time set
  useEffect(() => {
    getCamera();
  }, [getCamera]);

  // upload capture image
  useEffect(() => {
    const uploadCapturedImage = async () => {
      if (captureImage !== undefined) {
        try {
          // Get Participant Session Progress (time and file answer)
          const aspc = new AssessmentSessionParticipant();
          await aspc.saveWebcam(participantSession?.id.toString() ?? "", participantSession?.assessment_id.toString() ?? "", {
            base64_image: captureImage,
          });
        } catch (error) {
          const errorMessage = errorHandler(error);
          setNotif({ type: "error", message: errorMessage });
        }
      }
    };
    uploadCapturedImage();
  }, [captureImage, setNotif, participantSession?.id, participantSession?.assessment_id]);

  return (
    <div className="print:hidden w-screen h-screen flex flex-col overflow-hidden">
      <FormUpload
        showFormUpload={showFormUpload}
        setShowFormUpload={setShowFormUpload}
        participantSession={participantSession}
        getParticipantSession={getParticipantSession}
      />
      {printScreen && (
        <div className="w-screen h-screen fixed top-0 left-0 bg-white flex justify-center items-center z-50">
          Print Screen not Allowed
        </div>
      )}
      {controlKeyPressed && (
        <div className="w-screen h-screen fixed top-0 left-0 bg-white flex justify-center items-center z-50">
          Control Key not Allowed
        </div>
      )}
      <Header activeSession={activeSession} />
      {/* check active session */}
      {isCheckActiveSession === false ? (
        <div className="h-full flex flex-col justify-center items-center">
          <div className="font-light text-lg">Cek Sesi Aktif</div>
        </div>
      ) : (
        <>
          {/* If no session active (open) */}
          {activeSession === undefined ? (
            <div className="h-full flex flex-col justify-center items-center">
              <div className="font-semibold text-xl">Belum ada sesi dibuka</div>
              <div className="font-light text-lg">Mohon tunggu dan ikuti arahan assessor</div>
            </div>
          ) : (
            <>
              {/* if session active (open) */}
              {/* check if user not start the session (start_time === null)*/}
              {participantSession !== undefined && participantSession.start_time === null ? (
                <div className="h-full flex flex-col justify-center items-center">
                  <div className="font-semibold text-xl">{activeSession?.description}</div>
                  <div className="font-light text-lg">
                    Waktu Assessment : <b>{activeSession?.time_limit} Menit</b>
                  </div>
                  <button
                    type="button"
                    className="mt-4 first-letter:items-center px-4 py-2 border border-blue-500 text-base leading-6 font-medium rounded-full text-blue-600 bg-white hover:bg-blue-600 hover:text-white focus:border-blue-400"
                    onClick={() => startAssessment()}
                  >
                    <span>Mulai</span>
                  </button>
                </div>
              ) : (
                <div className="bg-white">
                  {/* here the user has been start the assessment session */}
                  {/* check if times up */}
                  {timeRemaining !== null && timeRemaining > 0 && (
                    <>
                      <Webcam
                        height={200}
                        width={200}
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        className="-z-10 absolute"
                        videoConstraints={{ facingMode: "user" }}
                      />
                      {/* loading for checking camera */}
                      {isCheckCamera ? (
                        <>
                          {/* Check if camera on */}
                          {cameraAllowed ? (
                            <div className="h-screen">
                              {activeSession.category.includes("In-Tray Simulation") ? (
                                <IntrayViewer participantSession={participantSession} activeSession={activeSession} />
                              ) : (
                                <PdfViewer pdfUrl={activeSession?.file_path ?? ""} />
                              )}
                            </div>
                          ) : (
                            <div className="h-screen flex flex-col justify-center items-center">
                              <div className="font-semibold text-xl">Akses kamera tidak di izinkan</div>
                              <div className="font-light text-lg text-center">
                                Akses kamera diperlukan untuk melanjutkan proses assessment, mohon berikan izin akses
                                kamera kepada halaman ini.
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="h-screen flex flex-col justify-center items-center">
                          <div className="font-light text-lg text-center">Check Kamera</div>
                        </div>
                      )}
                    </>
                  )}
                  {timeRemaining !== null && timeRemaining <= 0 && (
                    <div className="h-screen flex flex-col justify-center items-center">
                      <div className="font-semibold text-xl">Waktu anda habis</div>
                      <div className="font-light text-lg">Mohon tunggu dan ikuti arahan assessor</div>
                      {activeSession.category.includes("In-Tray Simulation") === false && (
                        <>
                          {/* if file answer has been uploaded yet (null) */}
                          {participantSession !== undefined && participantSession.file_path !== null ? (
                            <div className="font-semibold text-lg mt-2 text-green-700">
                              File jawaban <b>telah</b> diupload
                            </div>
                          ) : (
                            <div className="font-semibold text-lg mt-2 text-red-500">
                              File jawaban <b>belum</b> diupload
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  )}
                  {/* if file answer not uploaded yet (null) */}
                  {activeSession.category.includes("In-Tray Simulation") === false && (
                    <>
                      {participantSession?.file_path === null ? (
                        <button
                          onClick={() => setShowFormUpload(true)}
                          className="flex fixed justify-center items-center w-full md:w-auto bottom-0 md:bottom-6 md:right-10 md:rounded-full border border-red-500 px-4 py-1 text-white md:text-red-500 bg-red-500 md:bg-transparent hover:bg-red-500 hover:text-white"
                        >
                          <ArrowUpTrayIcon className="w-5 mr-2" /> Upload Answer
                        </button>
                      ) : (
                        <div className="flex fixed justify-center items-center w-full md:w-auto bottom-0 md:bottom-6 md:right-10 md:rounded-full border border-green-500 px-4 py-1 text-white bg-green-500">
                          <CheckCircleIcon className="w-5 mr-2" /> Answer File Submitted
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Session;
