import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState, useContext, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Switch } from "@headlessui/react";
import Swal from "sweetalert2";

// Icons
import { ArrowDownOnSquareIcon, TrashIcon } from "@heroicons/react/24/outline";

// Contexts
import LoaderContext from "../../../context/psikotest/LoaderContext";
import SnackbarContext from "../../../context/psikotest/SnackbarContext";

// Helpers
import errorHandler from "../../../helper/psikotest/errorHandler";
import logRender from "../../../helper/psikotest/logRender";

// Controllers
import Psikolog from "../../../controller/psikotest/psikotest_system/psikolog";

// Components
import BackButton from "../../../components/psikotest/psikotest_system/BackButton";

const schema = {
  nonPassword: {
    full_name: yup.string().label("Full Name").required().max(255),
    username: yup.string().label("Username").required().max(255),
    locked: yup.string().label("Roles").required().default("0").oneOf(["0", "1"]).max(1),
  },
  requiredPassword: {
    password: yup.string().label("Password").min(5).max(255),
    confirm_password: yup
      .string()
      .label("Confirm Password")
      .min(5)
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.password === value;
      }),
  },
  nonRequiredPassword: {
    password: yup
      .string()
      .label("Password")
      .matches(/.{5,}/, {
        excludeEmptyString: true,
        message: "Must be 5 or more characters",
      })
      .max(255),
    confirm_password: yup
      .string()
      .matches(/.{5,}/, {
        excludeEmptyString: true,
        message: "Must be 5 or more characters",
      })
      .label("Confirm Password")
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.password === value;
      }),
  },
};

function Form() {
  const { setMessage } = useContext(LoaderContext);
  const { setNotif } = useContext(SnackbarContext);
  const navigate = useNavigate();
  // parse param id
  const location = useLocation();
  // remove trailing slash in production when reload/refresh
  const arrPath = location.pathname.replace(/\/$/, "").split("/");
  const paramId = arrPath[arrPath.length - 1];
  // state document
  const [doc, setDoc] = useState<TPSPsikologDetail>({
    id: 0,
    full_name: "",
    user_id: 0,
    username: "",
    locked: "0",
  });
  // helper
  const [isLocked, setIsLocked] = useState(false);
  // is create
  const isCreate = paramId === "create";
  // schema
  const useSchema = yup
    .object()
    .shape(
      isCreate
        ? { ...schema.nonPassword, ...schema.requiredPassword }
        : { ...schema.nonPassword, ...schema.nonRequiredPassword }
    );
  // react hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<TPSPsikologCreateUpdate>({ resolver: yupResolver(useSchema) });

  useEffect(() => {
    logRender({ type: "page", name: "psikolog/Form" });
  }, []);

  useEffect(() => {
    setValue("locked", isLocked ? "1" : "0");
  }, [setValue, isLocked]);

  const getDoc = useCallback(
    async function getDoc() {
      try {
        if (!isCreate) {
          setMessage("Fetch Psikolog");
          const ac = new Psikolog();
          const res = await ac.get(paramId);
          const row: TPSPsikologDetail = res.data.row;
          setDoc(row);
          setValue("full_name", row.full_name);
          setValue("user_id", row.user_id);
          setValue("username", row.username);
          setValue("locked", row.locked);
          setIsLocked(row.locked === "1");
          setMessage("");
        }
      } catch (error) {
        setMessage("");
        const errorMessage = errorHandler(error);
        setNotif({ type: "error", message: errorMessage });
        navigate(`../`, { replace: true });
      }
    },
    [setValue, paramId, isCreate, setNotif, setMessage, navigate]
  );

  useEffect(() => {
    getDoc();
  }, [getDoc]);

  const onSubmit: SubmitHandler<TPSPsikologCreateUpdate> = async (data) => {
    try {
      setMessage("Save Psikolog");
      const ac = new Psikolog();
      let res;
      if (isCreate) {
        res = await ac.create(data);
      } else {
        res = await ac.update(paramId, data);
      }
      setNotif({ type: "success", message: res.data.message });
      setMessage("");
      navigate(`../`, { replace: true });
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  const confirmDelete = async (psikolog: TPSPsikologDetail) => {
    try {
      const confirm = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (confirm.isConfirmed) {
        const ac = new Psikolog();
        await ac.delete(psikolog.user_id.toString());
        setNotif({ type: "success", message: "psikolog deleted" });
        navigate(`../`, { replace: true });
      }
    } catch (error) {
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  return (
    <div className="w-full flex flex-col space-y-0.5">
      <div className="flex">
        <BackButton />
        <button
          onClick={handleSubmit(onSubmit)}
          className="mx-2 block w-auto px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded font-semibold text-sm"
        >
          <div className="flex">
            <ArrowDownOnSquareIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
            Save
          </div>
        </button>
        {!isCreate && (
          <button
            onClick={() => confirmDelete(doc)}
            className="ml-auto block w-auto px-4 py-2 bg-red-500 hover:bg-red-700 text-white rounded font-semibold text-sm"
          >
            <div className="flex">
              <TrashIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
              Delete
            </div>
          </button>
        )}
      </div>
      <div className="pt-4">
        <form className="w-full">
          <div className="flex flex-wrap -mx-3">
            <div className="w-full md:w-1/2 px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Full Name</label>
              <input
                className={
                  (errors?.full_name ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                  " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                }
                {...register("full_name")}
              />
              {errors.full_name && <p className="text-red-500 text-xs italic">{errors.full_name.message}</p>}
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Username <i className="font-medium normal-case">(for login)</i>
              </label>
              <input
                className={
                  (errors?.username ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                  " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                }
                {...register("username")}
              />
              {errors.username && <p className="text-red-500 text-xs italic">{errors.username.message}</p>}
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Status Account
                <i className={`ml-2 font-medium normal-case ${isLocked ? "text-red-500" : ""}`}>
                  ({isLocked ? "Locked - can't login" : "Unlocked"})
                </i>
              </label>
              <div className="flex">
                <Switch
                  checked={isLocked}
                  onChange={setIsLocked}
                  className={`${isLocked ? "bg-red-500" : "bg-gray-700"
                    } relative inline-flex flex-shrink-0 h-[25px] w-[60px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={`${isLocked ? "translate-x-7" : "translate-x-0"
                      } pointer-events-none inline-block h-[21px] w-[28px] rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
                  />
                </Switch>
              </div>
              {errors.locked && <p className="text-red-500 text-xs italic">{errors.locked.message}</p>}
            </div>
            <div className="w-full mt-4 mb-8 mx-3 h-[1px] bg-gray-300"></div>
            <div className="w-full md:w-1/2 px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Password</label>
              <input
                type="password"
                className={
                  (errors?.password ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                  " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                }
                {...register("password")}
              />
              {errors.password && <p className="text-red-500 text-xs italic">{errors.password.message}</p>}
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Confirm Password
              </label>
              <input
                type="password"
                className={
                  (errors?.confirm_password ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                  " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                }
                {...register("confirm_password")}
              />
              {errors.confirm_password && (
                <p className="text-red-500 text-xs italic">{errors.confirm_password.message}</p>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Form;
