import { Routes, Route, Link } from "react-router-dom";

// Icons
import { HomeIcon } from "@heroicons/react/24/outline";

// Main Menu
import Dashboard from "./index_page/Dashboard";
// Company
import CompanyIndex from "./company/Index";
import CompanyList from "./company/List";
import CompanyForm from "./company/Form";
// Assessment
import AssessmentIndex from "./assessment/Index";
import AssessmentList from "./assessment/List";
import AssessmentForm from "./assessment/Form";
// QUESTIONARY ITELEGENSI
// CFIT
import CfitIndex from "./cfit/Index";
import CfitList from "./cfit/List";
import CfitForm from "./cfit/Form";
// IST
import IstIndex from "./ist/Index";
import IstList from "./ist/List";
import IstForm from "./ist/Form";
// QUESTIONARY PERSONALITY
// MBTI
import MbtiQuestionaryIndex from "./mbti_questionary/Index";
import MbtiQuestionaryList from "./mbti_questionary/List";
// PAPI
import PapiQuestionaryIndex from "./papi_questionary/Index";
import PapiQuestionaryList from "./papi_questionary/List";
// EPPS
import EppsQuestionaryIndex from "./epps_questionary/Index";
import EppsQuestionaryList from "./epps_questionary/List";
// SRQ-20
import Srq20QuestionaryIndex from "./srq20_questionary/Index";
import Srq20QuestionaryList from "./srq20_questionary/List";
// MMPI
import MmpiQuestionaryIndex from "./mmpi_questionary/Index";
import MmpiQuestionaryList from "./mmpi_questionary/List";
// BRS
import BrsQuestionaryIndex from "./brs_questionary/Index";
import BrsQuestionaryList from "./brs_questionary/List";
// QUESTIONARY BEHAVIOURAL
// RAC
import RacQuestionaryIndex from "./rac_questionary/Index";
import RacQuestionaryForm from "./rac_questionary/Form";
// DISC
import DiscQuestionaryIndex from "./disc_questionary/Index";
import DiscQuestionaryList from "./disc_questionary/List";
// RESILIENCE
import ResilienceQuestionaryIndex from "./resilience_questionary/Index";
import ResilienceQuestionaryList from "./resilience_questionary/List";

// FORMULA ITELEGENSI
// CFIT FORMULA
import CfitFormulaIndex from "./cfit_formula/Index";
import CfitFormulaList from "./cfit_formula/List";
import CfitFormulaForm from "./cfit_formula/Form";
// IST FORMULA
import IstFormulaIndex from "./ist_formula/Index";
import IstFormulaList from "./ist_formula/IstList";
import IstFormulaIstDetailList from "./ist_formula/ListDetail";
import IstFormulaForm from "./ist_formula/Form";
import IstFormulaListGesamt from "./ist_formula/ListGesamt";
import IstFormulaFormGesamt from "./ist_formula/FormGesamt";
import IstFormulaGesamtNormaIq from "./ist_formula/ListGesamtNormaIq";
// FORMULA PERSONALITY
// EPPS FORMULA NORMA
import EppsFormulaNormaIndex from "./epps_formula_norma/Index";
import EppsFormulaNormaList from "./epps_formula_norma/List";
// EPPS FORMULA NORMA
import BrsFormulaIndex from "./brs_formula/Index";
import BrsFormulaList from "./brs_formula/List";
// FORMULA BEHAVIOURAL
// RAC FORMULA NORMA
import RacFormulaNormaIndex from "./rac_formula_norma/Index";
import RacFormulaNormaList from "./rac_formula_norma/List";

// NAV MENU
// PSIKOLOG
import PsikologIndex from "./psikolog/Index";
import PsikologList from "./psikolog/List";
import PsikologForm from "./psikolog/Form";
// ASSESSOR
import AssessorIndex from "./assessor/Index";
import AssessorList from "./assessor/List";
import AssessorForm from "./assessor/Form";
// USER
import UserProfile from "./index_page/UserProfile";
import ChangePassword from "./index_page/ChangePassword";
import Setting from "./index_page/Setting";

function PsikotestSystemRoute() {
  return (
    <Routes>
      <Route path={`/`} element={<Dashboard />} />
      <Route path={`/me`} element={<UserProfile />} />
      <Route path={`/change_password`} element={<ChangePassword />} />
      <Route path={`/setting`} element={<Setting />} />
      {/* Company */}
      <Route path={`/company`} element={<CompanyIndex />}>
        <Route index element={<CompanyList />} />
        <Route path={`/company/create`} element={<CompanyForm />} />
        <Route path={`/company/:id`} element={<CompanyForm />} />
      </Route>
      {/* Assessment */}
      <Route path={`/assessment`} element={<AssessmentIndex />}>
        <Route index element={<AssessmentList />} />
        <Route path={`/assessment/create`} element={<AssessmentForm />} />
        <Route path={`/assessment/:id`} element={<AssessmentForm />} />
      </Route>
      {/** QUESTIONARY ITELEGENSI */}
      {/* CFIT */}
      <Route path={`/cfit`} element={<CfitIndex />}>
        <Route index element={<CfitList />} />
        <Route path={`/cfit/create`} element={<CfitForm />} />
        <Route path={`/cfit/:id`} element={<CfitForm />} />
      </Route>
      {/* IST */}
      <Route path={`/ist`} element={<IstIndex />}>
        <Route index element={<IstList />} />
        <Route path={`/ist/create`} element={<IstForm />} />
        <Route path={`/ist/:id`} element={<IstForm />} />
      </Route>
      {/** QUESTIONARY PERSONALITY */}
      {/* MBTI Questionary */}
      <Route path={`/mbti_questionary`} element={<MbtiQuestionaryIndex />}>
        <Route index element={<MbtiQuestionaryList />} />
      </Route>
      {/* PAPI Questionary */}
      <Route path={`/papi_questionary`} element={<PapiQuestionaryIndex />}>
        <Route index element={<PapiQuestionaryList />} />
      </Route>
      {/* EPPS Questionary */}
      <Route path={`/epps_questionary`} element={<EppsQuestionaryIndex />}>
        <Route index element={<EppsQuestionaryList />} />
      </Route>
      {/* SRQ-20 Questionary */}
      <Route path={`/srq20_questionary`} element={<Srq20QuestionaryIndex />}>
        <Route index element={<Srq20QuestionaryList />} />
      </Route>
      {/* MMPI Questionary */}
      <Route path={`/mmpi_questionary`} element={<MmpiQuestionaryIndex />}>
        <Route index element={<MmpiQuestionaryList />} />
      </Route>
      {/* BRS Questionary */}
      <Route path={`/brs_questionary`} element={<BrsQuestionaryIndex />}>
        <Route index element={<BrsQuestionaryList />} />
      </Route>
      {/** QUESTIONARY BEHAVIOURAL */}
      {/* RAC Questionary */}
      <Route path={`/rac_questionary`} element={<RacQuestionaryIndex />}>
        <Route index element={<RacQuestionaryForm />} />
      </Route>
      {/* EPPS Questionary */}
      <Route path={`/disc_questionary`} element={<DiscQuestionaryIndex />}>
        <Route index element={<DiscQuestionaryList />} />
      </Route>
      {/* Resilience Questionary */}
      <Route path={`/resilience_questionary`} element={<ResilienceQuestionaryIndex />}>
        <Route index element={<ResilienceQuestionaryList />} />
      </Route>

      {/** FORMULA ITELEGENSI */}
      {/* CFIT FORMULA */}
      <Route path={`/cfit_formula`} element={<CfitFormulaIndex />}>
        <Route index element={<CfitFormulaList />} />
        <Route path={`/cfit_formula/create`} element={<CfitFormulaForm />} />
        <Route path={`/cfit_formula/:id`} element={<CfitFormulaForm />} />
      </Route>
      {/* IST FORMULA */}
      <Route path={`/ist_formula`} element={<IstFormulaIndex />}>
        <Route index element={<IstFormulaList />} />
        <Route path={`/ist_formula/:ist_id`} element={<IstFormulaIstDetailList />} />
        <Route path={`/ist_formula/:ist_id/create`} element={<IstFormulaForm />} />
        <Route path={`/ist_formula/:ist_id/:id`} element={<IstFormulaForm />} />
        <Route path={`/ist_formula/gesamt`} element={<IstFormulaListGesamt />} />
        <Route path={`/ist_formula/gesamt/create`} element={<IstFormulaFormGesamt />} />
        <Route path={`/ist_formula/gesamt/:id`} element={<IstFormulaFormGesamt />} />
        <Route path={`/ist_formula/gesamt_norma_iq`} element={<IstFormulaGesamtNormaIq />} />
      </Route>
      {/** FORMULA PERSONALITY */}
      {/* EPPS Formula Norma */}
      <Route path={`/epps_formula_norma`} element={<EppsFormulaNormaIndex />}>
        <Route index element={<EppsFormulaNormaList />} />
      </Route>
      {/* BRS Formula */}
      <Route path={`/brs_formula`} element={<BrsFormulaIndex />}>
        <Route index element={<BrsFormulaList />} />
      </Route>
      {/** FORMULA BEHAVIOURAL */}
      {/* RAC Formula Norma */}
      <Route path={`/rac_formula_norma`} element={<RacFormulaNormaIndex />}>
        <Route index element={<RacFormulaNormaList />} />
      </Route>
      {/* PSIKOLOG */}
      <Route path={`/psikolog`} element={<PsikologIndex />}>
        <Route index element={<PsikologList />} />
        <Route path={`/psikolog/create`} element={<PsikologForm />} />
        <Route path={`/psikolog/:id`} element={<PsikologForm />} />
      </Route>
      {/* ASSESSOR */}
      <Route path={`/assessor`} element={<AssessorIndex />}>
        <Route index element={<AssessorList />} />
        <Route path={`/assessor/create`} element={<AssessorForm />} />
        <Route path={`/assessor/:id`} element={<AssessorForm />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

function NotFound() {
  return (
    <div className="w-full flex justify-center items-center">
      <div className="text-center">
        <div className="text-9xl font-extrabold text-slate-700">
          <span>4</span>
          <span className="text-slate-400">0</span>
          <span>4</span>
        </div>
        <div className="text-center uppercase font-extrabold tracking-widest">
          - Page not found -
        </div>
        <div className="pt-3">Hello, It seems you are lost.</div>
        <div className="pt-1">
          <Link to={`/psikotest_system`}>
            <div className="underline group flex justify-center items-center space-x-1 text-blue-500 hover:text-blue-900">
              <HomeIcon
                className="w-4 h-4 stroke-blue-500 group-hover:stroke-blue-900 fill-tranparent"
                aria-hidden="true"
              />
              <span>Back to homepage</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PsikotestSystemRoute;
