import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import Webcam from "react-webcam";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useContext, useState, useRef } from "react";
import { ArrowRightIcon } from "@heroicons/react/24/outline";

// Context
import SnackbarContext from "../../context/psikotest/SnackbarContext";

// Controller
import PsikotestParticipant from "../../controller/psikotest/psikotest_participant";

// Handler
import errorHandler from "../../helper/psikotest/errorHandler";

// Value
const educationList: string[] = ["SMA/SMK", "D3", "D4", "S1", "S2", "S3"];

const schema = {
  id: yup.number(),
  full_name: yup.string().label("Full Name").required().max(100),
  email: yup.string().email().label("Email").required().max(50),
  date_of_birth: yup.string().label("Date of Birth").required().max(10),
  last_education: yup.string().label("Last Education").required().max(100),
  gender: yup
    .string()
    .label("Gender")
    .transform((v) => (v === null ? "" : v))
    .required()
    .max(100),
  photo: yup.string().label("Photo"),
};

function PsikotestParticipantForm(props: {
  assessment: TPSAssessment;
  assessment_participant: TPSAssessmentParticipant;
}) {
  // Context
  const { setNotif } = useContext(SnackbarContext);
  // state
  const [progress, setProgress] = useState<number>(1);
  const [captureImage, setCaptureImage] = useState<string>();
  // state - for progress (2)
  const [startCamera, setStartCamera] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  // Ref
  const webcamRef = useRef<Webcam>(null);

  const useSchema = yup.object().shape(schema);
  // react hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<TPSAssessmentParticipant>({ resolver: yupResolver(useSchema) });

  useEffect(() => {
    // set progress
    if (props.assessment_participant.date_of_birth === null) {
      setProgress(1);
    } else if (props.assessment_participant.photo === null) {
      setProgress(2);
    } else if (props.assessment_participant.manual === null) {
      setProgress(3);
    }
  }, [props.assessment_participant]);

  useEffect(() => {
    if (progress === 1) {
      const getProfile = async () => {
        try {
          // nothing to do
        } catch (error) {
          const errorMessage = errorHandler(error);
          setNotif({ type: "error", message: errorMessage });
        }
      };
      getProfile();
    }
  }, [progress, setNotif]);

  useEffect(() => {
    setValue("id", props.assessment_participant.id);
    setValue("full_name", props.assessment_participant.full_name);
    setValue("email", props.assessment_participant.email);
    setValue("access_key", props.assessment_participant.access_key);
  }, [
    setValue,
    props.assessment_participant.id,
    props.assessment_participant.full_name,
    props.assessment_participant.email,
    props.assessment_participant.access_key,
  ]);

  const onSubmit: SubmitHandler<TPSAssessmentParticipant> = async (data) => {
    try {
      // format date, somehow the date format is have different format
      // example : console.log("15 01 2005")
      // check if date invalid
      if (data?.date_of_birth === null || data?.date_of_birth === undefined || data?.date_of_birth === "") {
        throw new Error("Invalid date of birth values")
      } else {
        const checkDate = new Date(data?.date_of_birth)
        if (checkDate.toString() === "Invalid Date") {
          throw new Error("Invalid date of birth values")
        }
      }
      const ppc = new PsikotestParticipant();
      await ppc.updateAssessmentParticipant(data);
      setProgress(2);
    } catch (error) {
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  // capture Assessment Participant Photo
  const captureCamera = () => {
    try {
      if (webcamRef !== null && webcamRef.current !== null) {
        const image = webcamRef.current.getScreenshot();
        if (image !== null) {
          setCaptureImage(image);
        }
      }
    } catch (error) {
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  // upload Assessment Participant Photo
  const uploadImage = async () => {
    try {
      if (captureImage) {
        setUploading(true);
        const apc = new PsikotestParticipant();
        await apc.uploadAssessmentParticipantPhoto(props.assessment_participant, {
          base64_image: captureImage,
        });
        setUploading(false);
        setProgress(3);
      }
    } catch (error) {
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  const handleMarkManual = async () => {
    try {
      const ppc = new PsikotestParticipant();
      await ppc.updateAssessmentParticipantManual(props.assessment_participant);
      window.location.reload()
    } catch (error) {
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  return (
    <div className="w-full md:h-screen md:overflow-auto flex flex-col space-y-0.5 p-4 md:px-20">
      {progress === 1 && (
        <>
          <div className="pt-20">
            <form className="w-full">
              <h5 className="pb-8 text-center font-bold text-xl">Identitas</h5>
              <div className="flex flex-wrap -mx-3">
                {/* Full Name */}
                <div className="w-full px-3 mb-6">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Nama Lengkap
                  </label>
                  <input
                    disabled={true}
                    className={
                      (errors?.full_name ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                      " first-letter:appearance-none block w-full text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                    }
                    {...register("full_name")}
                  />
                  {errors.full_name && <p className="text-red-500 text-xs italic">{errors.full_name.message}</p>}
                </div>
                {/* Date of Birth */}
                <div className="w-full md:w-1/2 px-3 mb-6">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Tanggal Lahir
                  </label>
                  <input
                    type="date"
                    pattern="\d{4}-\d{2}-\d{2}"
                    className={
                      (errors?.date_of_birth ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                      " first-letter:appearance-none block w-full text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                    }
                    {...register("date_of_birth")}
                  />
                  {errors.date_of_birth && (
                    <p className="text-red-500 text-xs italic">{errors.date_of_birth.message}</p>
                  )}
                </div>
                {/* Gender */}
                <div className="w-full md:w-1/2 px-3 mb-6">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Jenis Kelamin
                  </label>
                  <div className="flex space-x-3 h-11">
                    <div className="flex space-x-2 items-center">
                      <input {...register("gender")} type="radio" value="M" id="gender-m" />
                      <label htmlFor="gender-m">Laki-Laki</label>
                    </div>
                    <div className="flex space-x-2 items-center">
                      <input {...register("gender")} type="radio" value="F" id="gender-f" />
                      <label htmlFor="gender-f">Perempuan</label>
                    </div>
                  </div>
                  {errors.gender && <p className="text-red-500 text-xs italic">{errors.gender.message}</p>}
                </div>
                {/* Last Education */}
                <div className="w-full md:w-1/2 px-3 mb-6">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Pendidikan Terakhir
                  </label>
                  <div className="relative after:content-['↓'] after:absolute after:right-4 after:top-3 after:pointer-events-none">
                    <select
                      className={
                        (errors?.last_education ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                        " appearance-none first-letter:appearance-none block w-full text-gray-700 border rounded py-2 px-3"
                      }
                      defaultValue=""
                      {...register("last_education")}
                    >
                      <option value="" disabled>
                        Please Select
                      </option>
                      {educationList.map((v, i) => (
                        <option key={i} value={v}>
                          {v}
                        </option>
                      ))}
                    </select>
                  </div>
                  {errors.last_education && (
                    <p className="text-red-500 text-xs italic">{errors.last_education.message}</p>
                  )}
                </div>
              </div>
            </form>
          </div>
          <div className="flex">
            <button
              onClick={handleSubmit(onSubmit)}
              className="ml-auto block w-auto px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded font-semibold text-sm"
            >
              <div className="flex">
                Submit
                <ArrowRightIcon className="w-5 h-5 ml-2 stroke-white fill-tranparent" aria-hidden="true" />
              </div>
            </button>
          </div>
        </>
      )}
      {progress === 2 && (
        <div>
          <div className="text-center pt-4 text-2xl font-bold">Foto Diri</div>
          {startCamera ? (
            <div className="flex justify-center">
              {captureImage === "" || captureImage === undefined ? (
                <div className="w-3/6 flex flex-col justify-center p-4">
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    className=""
                    videoConstraints={{ facingMode: "user", height: 660, width: 450 }}
                  />
                  <div className="text-center text-red-500 font-bold">Pastikan seluruh wajah Anda terlihat (jangan pakai masker)</div>
                  <button
                    type="button"
                    className="mt-2 p-2 bg-blue-500 text-white rounded"
                    onClick={() => captureCamera()}
                  >
                    Ambil Gambar
                  </button>
                </div>
              ) : (
                <div className="flex flex-col justify-center p-4">
                  <img src={captureImage} alt="captured camera" height="660" width="450" />
                  <div className="text-center text-red-500 font-bold">Pastikan seluruh wajah Anda terlihat (jangan pakai masker)</div>
                  <div className="flex flex-row mt-2 space-x-2">
                    <button
                      type="button"
                      className={"p-2 flex-1 bg-gray-500 text-white rounded disabled:bg-gray-300"}
                      onClick={() => setCaptureImage("")}
                      disabled={uploading}
                    >
                      Ulang
                    </button>
                    <button
                      type="button"
                      className={"p-2 flex-1 bg-green-500 text-white rounded disabled:bg-green-300"}
                      onClick={() => uploadImage()}
                      disabled={uploading}
                    >
                      Gunakan
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="py-40 flex flex-col items-center">
              <span className="text-center">Kami membutuhkan foto wajah anda untuk memastikan identitas anda.</span>
              <button
                type="button"
                className="p-2 mt-8 border rounded border-blue-500 text-blue-500 cursor-pointer hover:bg-blue-100"
                onClick={() => setStartCamera(true)}
              >
                Hidupkan Kamera
              </button>
            </div>
          )}
        </div>
      )
      }
      {progress === 3 && <Manual handleMarkManual={handleMarkManual} />}
    </div>
  );
}

function Manual(props: { handleMarkManual: () => Promise<void> }) {
  return (
    <div className="">
      <div className="pt-10">
        <h5 className="text-xl font-bold">Hal yang perlu diperhatikan selama asesmen: </h5>
        <ol className="list-decimal p-4 space-y-4">
          <li>Datang ke zoom meeting tepat waktu (masuk ZOOM minimal 5 menit sebelum jadwal).</li>
          <li>Mengikuti seluruh tata tertib Asesmen</li>
          <li>Menyiapkan seluruh alat pendukung yang diperlukan untuk mengikuti tes termasuk 2 buah perangkat/device/gadget yang berfungsi dengan optimal (mikrofon & kamera)</li>
          <li>Mengikuti seluruh instruksi yang diberikan admin / tester.</li>
          <li>Yakinlah dengan jawaban Anda. Tidak diperbolehkan untuk dibantu oleh pihak lain. Bila ada kendala, sampaikan ke pihak admin.</li>
          <li>Tidak diperbolehkan melakukan perekaman terhadap proses asesmen (Screenshot ataupun Recording dalam bentuk apapun).</li>
        </ol>
      </div>
      <h5 className="text-xl font-bold">Agar asesmen berjalan lancar: </h5>
      <ol className="list-decimal p-4 space-y-4">
        <li>Datang ke zoom meeting tepat waktu (masuk ZOOM minimal 5 menit sebelum jadwal).</li>
        <li>Fokus pada kegiatan asesmen sepanjang proses asesmen berjalan (tidak terganggu oleh hal-hal terkait pekerjaan sehari-hari maupun keperluan personal)</li>
        <li>Pastikan internet baik dan lancar sepanjang proses asesmen. Usahakan agar koneksi internet Anda tidak terputus. Bila perlu, ganti provider internet, misalnya dengan tethering ke data mobile bila Anda memakai sambungan WiFi ataupun sebaliknya.</li>
        <li>Siapkan kondisi fisik dan lingkungan yang kondusif (tenang dan minim gangguan).</li>
        <li>Tutup / matikan aplikasi/situs/website (termasuk Youtube, Instagram, WA) yang tidak berhubungan dengan asesmen sehingga komputer/gadget yang anda gunakan dapat bekerja optimal dan sambungan internet berjalan lancar.</li>
        <li>Asesmen terdiri dari beberapa sesi. Ikuti semua tahapan sesi sesuai instruksi dan petunjuk yang diberikan.</li>
        <li>Apabila koneksi ke website asesmen terputus, Anda hanya perlu menekan kembali link asesmen yang sudah diberikan melalui email. Anda akan ditampilkan tahap Asesmen yang sedang dijalani sesuai waktu yang tersisa. Jangan lupa pula sampaikan kendala Anda tersebut ke pihak Admin/Tester.</li>
      </ol>
      <div className="flex pb-8">
        <button
          onClick={() => props.handleMarkManual()}
          className="ml-auto block w-auto px-4 py-2 mr-4 bg-blue-500 hover:bg-blue-700 text-white rounded font-semibold text-sm"
        >
          <div className="flex">
            Lanjutkan
            <ArrowRightIcon className="w-5 h-5 ml-2 stroke-white fill-tranparent" aria-hidden="true" />
          </div>
        </button>
      </div>
    </div>
  );
}

export default PsikotestParticipantForm;
