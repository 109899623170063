import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useContext, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Disclosure, Transition } from "@headlessui/react";
import Swal from "sweetalert2";

// Icons
import { ArrowDownOnSquareIcon, TrashIcon, ChevronUpIcon } from "@heroicons/react/24/outline";

// Context
import LoaderContext from "../../../context/psikotest/LoaderContext";
import SnackbarContext from "../../../context/psikotest/SnackbarContext";

// Helper
import errorHandler from "../../../helper/psikotest/errorHandler";
import logRender from "../../../helper/psikotest/logRender";

// Controller
import CfitFormula from "../../../controller/psikotest/psikotest_system/cfit_formula";

// Component
import BackButton from "../../../components/psikotest/psikotest_system/BackButton";

// Other Section
import ListPersentil from "./ListPersentil";

const schema = yup.object().shape({
  id: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required(),
  age_min: yup
    .number()
    .label("Minimum Age")
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(1)
    .required(),
  age_max: yup
    .number()
    .label("Maximum Age")
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(yup.ref("age_min"))
    .required(),
});

function Form() {
  const { setMessage } = useContext(LoaderContext);
  const { setNotif } = useContext(SnackbarContext);
  const navigate = useNavigate();
  // parse param id
  const location = useLocation();
  // remove trailing slash in production when reload/refresh
  const arrPath = location.pathname.replace(/\/$/, "").split("/");
  const paramId = arrPath[arrPath.length - 1];
  // is create
  const isCreate = paramId === "create";
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch
  } = useForm<TPSCfitFormula>({ resolver: yupResolver(schema) });
  // state document
  const doc = watch();

  useEffect(() => {
    logRender({ type: "page", name: "cfit_formula/Form" });
  }, []);

  const getDoc = useCallback(
    async function getDoc() {
      try {
        // fetch document
        if (isCreate) {
          setValue("id", 0);
        } else {
          // fetch cfit_formula document
          setMessage("Fetch Document");
          const ccf = new CfitFormula();
          const res = await ccf.get(paramId);
          const row: TPSCfitFormula = res.data.row;
          setValue("id", row.id);
          setValue("age_min", row.age_min);
          setValue("age_max", row.age_max);
        }
        setMessage("");
      } catch (error) {
        setMessage("");
        const errorMessage = errorHandler(error);
        setNotif({ type: "error", message: errorMessage });
        navigate(`../`, { replace: true });
      }
    },
    [setValue, paramId, isCreate, setNotif, setMessage, navigate]
  );

  useEffect(() => {
    getDoc();
  }, [getDoc]);

  const onSubmit: SubmitHandler<TPSCfitFormula> = async (data) => {
    try {
      setMessage("Save Cfit Formula");
      const ccf = new CfitFormula();
      let res;
      if (isCreate) {
        data.id = 0;
        res = await ccf.create(data);
      } else {
        res = await ccf.update(data);
      }
      setNotif({ type: "success", message: res.data.message });
      setMessage("");
      // when create new navigate to result form
      if (isCreate) {
        navigate(`../${res.data.result.saved_id}`, { replace: true });
      } else {
        navigate(`../`, { replace: true });
      }
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  const confirmDelete = async (cf: TPSCfitFormula) => {
    try {
      const confirm = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (confirm.isConfirmed) {
        const ccf = new CfitFormula();
        await ccf.delete(cf);
        setNotif({ type: "success", message: "cfit formula deleted" });
        navigate(`../`, { replace: true });
      }
    } catch (error) {
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  return (
    <div className="w-full flex flex-col space-y-0.5">
      <div className="flex">
        <BackButton />
        <button
          onClick={handleSubmit(onSubmit)}
          className="mx-2 block w-auto px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded font-semibold text-sm"
        >
          <div className="flex">
            <ArrowDownOnSquareIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
            Save
          </div>
        </button>
        {!isCreate && (
          <button
            onClick={() => confirmDelete(doc)}
            className="ml-auto block w-auto px-4 py-2 bg-red-500 hover:bg-red-700 text-white rounded font-semibold text-sm"
          >
            <div className="flex">
              <TrashIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
              Delete
            </div>
          </button>
        )}
      </div>
      {/* MAIN SECTION */}
      <div className="pt-4">
        {/* Main Form */}
        <form className="w-full">
          <div className="flex flex-wrap -mx-3">
            <input {...register("id")} type="number" className="hidden" />
            {/** Minimum Age */}
            <div className="w-full md:w-1/2 px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Minimum Age
              </label>
              <input
                type="number"
                className={
                  (errors?.age_min ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                  " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                }
                {...register("age_min")}
              />
              {errors.age_min && (
                <p className="text-red-500 text-xs italic">{errors.age_min.message}</p>
              )}
            </div>
            {/** Maximum Age */}
            <div className="w-full md:w-1/2 px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Maximum Age
              </label>
              <input
                type="number"
                className={
                  (errors?.age_max ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                  " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                }
                {...register("age_max")}
              />
              {errors.age_max && (
                <p className="text-red-500 text-xs italic">{errors.age_max.message}</p>
              )}
            </div>
          </div>
        </form>
        {/** Persentil */}
        {!isCreate &&
          <Disclosure defaultOpen={true} as="div" className="pb-1">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between w-full px-4 py-4 text-sm font-medium text-left text-gray-100 bg-blue-900 rounded hover:bg-blue-800 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                  <span>Persentil</span>
                  <ChevronUpIcon className={`${open ? "transform rotate-180" : ""} w-5 h-5 text-gray-100`} />
                </Disclosure.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                    <ListPersentil isCreate={isCreate} cf={doc} editable={true} />
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        }
      </div>
    </div >
  );
}

export default Form;
