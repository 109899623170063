import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState, useContext, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Disclosure, Transition } from "@headlessui/react";
import Swal from "sweetalert2";

// Icons
import { ArrowDownOnSquareIcon, TrashIcon, ChevronUpIcon } from "@heroicons/react/24/outline";

// Context
import LoaderContext from "../../../context/psikotest/LoaderContext";
import SnackbarContext from "../../../context/psikotest/SnackbarContext";

// Helper
import errorHandler from "../../../helper/psikotest/errorHandler";
import logRender from "../../../helper/psikotest/logRender";

// Component
import FileUpload from "../../../components/psikotest/psikotest_system/FileUpload";
import BackButton from "../../../components/psikotest/psikotest_system/BackButton";

// Controllers
import Company from "../../../controller/psikotest/psikotest_system/company";

const schema = yup.object().shape({
  id: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required(),
  company_logo: yup.string().label("File").required(),
  company_name: yup
    .string()
    .label("Company Name")
    .transform((value) => (value === null ? "" : value))
    .required(),
});

function Form() {
  const { setMessage } = useContext(LoaderContext);
  const { setNotif } = useContext(SnackbarContext);
  const navigate = useNavigate();
  // parse param id
  const location = useLocation();
  // remove trailing slash in production when reload/refresh
  const arrPath = location.pathname.replace(/\/$/, "").split("/");
  const paramId = arrPath[arrPath.length - 1];
  // state document
  const [doc, setDoc] = useState<TPSCompany>({
    id: 0,
    company_logo: "",
    company_name: "",
  });
  // is create
  const isCreate = paramId === "create";
  const [id, setId] = useState<number>(0);
  const [file_path, setFilePath] = useState<string>("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<TPSCompany>({ resolver: yupResolver(schema) });

  useEffect(() => {
    logRender({ type: "page", name: "company/Form" });
  }, []);

  const getDoc = useCallback(
    async function getDoc() {
      try {
        setMessage("Fetch Level Assessment");
        // fetch document
        if (!isCreate) {
          setMessage("Fetch Document");
          const cc = new Company();
          const res = await cc.get(paramId);
          const row: TPSCompany = res.data.row;
          setDoc(row);
          setValue("id", row.id);
          setValue("company_name", row.company_name);
          setValue("company_logo", row.company_logo);
          // make sure file upload components updated current document
          setId(row.id);
          setFilePath(row.company_logo);
        }
        setMessage("");
      } catch (error) {
        setMessage("");
        const errorMessage = errorHandler(error);
        setNotif({ type: "error", message: errorMessage });
        navigate(`../`, { replace: true });
      }
    },
    [setValue, paramId, isCreate, setNotif, setMessage, navigate]
  );

  useEffect(() => {
    getDoc();
  }, [getDoc]);

  const onSubmit: SubmitHandler<TPSCompany> = async (data) => {
    try {
      setMessage("Save Company");
      const cc = new Company();
      const res = await cc.save(data);
      setNotif({ type: "success", message: res.data.message });
      setMessage("");
      navigate(`../`, { replace: true });
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  useEffect(() => {
    setValue("id", id);
  }, [setValue, id]);

  useEffect(() => {
    setValue("company_logo", file_path);
  }, [setValue, file_path]);

  const confirmDelete = async (company: TPSCompany) => {
    try {
      const confirm = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (confirm.isConfirmed) {
        const cc = new Company();
        await cc.delete(company);
        setNotif({ type: "success", message: "company deleted" });
        navigate(`../`, { replace: true });
      }
    } catch (error) {
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  return (
    <div className="w-full flex flex-col space-y-0.5">
      <div className="flex">
        <BackButton />
        <button
          onClick={handleSubmit(onSubmit)}
          className="mx-2 block w-auto px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded font-semibold text-sm"
        >
          <div className="flex">
            <ArrowDownOnSquareIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
            Save
          </div>
        </button>
        {!isCreate && (
          <button
            onClick={() => confirmDelete(doc)}
            className="ml-auto block w-auto px-4 py-2 bg-red-500 hover:bg-red-700 text-white rounded font-semibold text-sm"
          >
            <div className="flex">
              <TrashIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
              Delete
            </div>
          </button>
        )}
      </div>
      {/* MAIN SECTION */}
      <div className="pt-4">
        {/* Main Form */}
        <Disclosure defaultOpen={true} as="div" className="pb-1">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex justify-between w-full px-4 py-4 text-sm font-medium text-left text-gray-100 bg-gray-900 rounded hover:bg-gray-800 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                <span>Company Form</span>
                <ChevronUpIcon className={`${open ? "transform rotate-180" : ""} w-5 h-5 text-gray-100`} />
              </Disclosure.Button>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                  <div className="w-full md:w-full mb-6">
                    <FileUpload
                      type="company"
                      editable={true}
                      document_id={id}
                      filepath={file_path}
                      error_field={errors?.company_logo}
                      setDocumentId={(id) => {
                        setId(id);
                        navigate(`../${id}`, { replace: true });
                      }}
                      setFilePath={(filepath) => setFilePath(filepath)}
                      key={id}
                    />
                  </div>
                  <form className="w-full">
                    <div className="flex flex-wrap -mx-3">
                      <input {...register("id")} type="number" className="hidden" />
                      <input {...register("company_logo")} type="text" className="hidden" />
                      <div className="w-full md:w-full px-3 mb-6">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                          Company Name
                        </label>
                        <input
                          className={
                            (errors?.company_name ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                            " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                          }
                          {...register("company_name")}
                        />
                        {errors.company_name && (
                          <p className="text-red-500 text-xs italic">{errors.company_name.message}</p>
                        )}
                      </div>
                    </div>
                  </form>
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
}

export default Form;
