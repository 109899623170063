import { ChangeEvent, FocusEvent, useCallback, useContext, useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";

// Icons

// Context
import SnackbarContext from "../../../context/psikotest/SnackbarContext";
import PPAssessmentContext from "../../../context/psikotest/PPAssessmentContext";

// Handler
import errorHandler from "../../../helper/psikotest/errorHandler";
import { getMin, getMax } from "../../../helper/psikotest/getMinMaxArrayObject";

// Controller
import ParticipantSession from "../../../controller/psikotest/psikotest_participant/session";

function SubtestResilience(props: { assessment_participant_session: TPPAssessmentParticipantSession, closeSession: () => void }) {
    // Context
    const { setNotif } = useContext(SnackbarContext);
    const { timeRemaining } = useContext(PPAssessmentContext);

    // ref
    const trialAnswerRef = useRef<HTMLInputElement>(null)
    const answerRef = useRef<HTMLInputElement>(null)

    // state
    const [resilience, setResilience] = useState<TPSResilience>()
    const [resilience_distraction, setResilienceDistraction] = useState<TPSResilienceDistraction[]>([])
    const [resilience_questionary_answer, setResilienceQuestionaryAnswer] = useState<TPSAssessmentParticipantResilienceSessionAnswer>()
    const [questionary_answer, setQuestionaryAnswer] = useState<TPSResilienceQustionaryAnswer[]>([])
    const [trial_status, setTrialStatus] = useState<boolean>(false)
    const [remaining_trial_time, setRemainingTrialTime] = useState<number>(0)
    const [test_status, setTestStatus] = useState<boolean>(false)
    const [show_distraction, setShowDistraction] = useState<boolean>(false)
    const [int1, setInt1] = useState<number>(0)
    const [int2, setInt2] = useState<number>(0)
    const [correct_answer, setCorrectAnswer] = useState<number>(0)

    // variable

    // Functions
    // randomly generate number for questionary test
    const setNewQuestionary = useCallback(() => {
        // computed questionary
        const random1 = randomIntFromInterval(1, 9)
        const random2 = randomIntFromInterval(1, 9)
        const sum_value = parseInt((random1 + random2).toString().slice(-1))
        setInt1(random1)
        setInt2(random2)
        setCorrectAnswer(sum_value)
        // reset field input
        if (trialAnswerRef.current) trialAnswerRef.current.value = ''
        if (answerRef.current) answerRef.current.value = ''
    }, [])
    // get doc
    const getDoc = useCallback(async () => {
        try {
            // get all question in subtest id
            const psc = new ParticipantSession();
            let resResilience = await psc.getResilience();
            setResilience(resResilience.data.row)
            let resDistractionResilience = await psc.getDistractionResilience();
            setResilienceDistraction(resDistractionResilience.data.list)
            let resQuestionaryAnswerResilience = await psc.getQuestionaryAnswerResilience(props.assessment_participant_session.id)
            setResilienceQuestionaryAnswer(resQuestionaryAnswerResilience.data.row)
            // set trial duration
            const trialDuration = (resResilience.data.row.trial_time ?? 1) * 60 * 1000
            setRemainingTrialTime(trialDuration)
            // check if text is ongoing
            if (resQuestionaryAnswerResilience.data.row.test_status === 1) {
                // set available answer
                setQuestionaryAnswer(JSON.parse(resQuestionaryAnswerResilience.data.row.answer))
                // generate new questionary
                setNewQuestionary()
                // start test
                setTestStatus(true)
                // set focus
                if (answerRef) answerRef.current?.focus()
            }
        } catch (error) {
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }, [setNotif, props.assessment_participant_session.id, setNewQuestionary]);
    // use Effect
    useEffect(() => {
        getDoc();
    }, [getDoc]);
    // upload answer
    const uploadAnswer = useCallback(async (answer: TPSResilienceQustionaryAnswer[]) => {
        try {
            if (resilience_questionary_answer) {
                // prepare for result variable
                let calculated_answer: TPSResilienceQustionaryAnswer[] = []
                const score_group = []
                if (answer.length > 0) {
                    // set valid time test
                    const duration = (resilience?.duration_time ? resilience.duration_time : 20) * 60 * 1000
                    const group_time = 30 * 1000 // per 30 seconds
                    const start_time = answer[0].t
                    const end_time = start_time + duration

                    // count valid time answer
                    calculated_answer = answer.filter(a => a.t >= start_time && a.t <= end_time)

                    // grouping the answer
                    console.log('time : ', start_time, '-', end_time)
                    let group_index = start_time
                    for (let index = 0; group_index < end_time; index++) {
                        const start_group_time = group_index + (index === 0 ? 0 : 1)
                        const end_group_time = group_index + group_time
                        const member_answer = answer.filter(a => a.t >= start_group_time && a.t <= end_group_time)
                        const group: TPSResilienceScoreGroupResult = {
                            start_time: start_group_time,
                            end_time: end_group_time,
                            answer: member_answer,
                            total_answer: member_answer.length,
                            correct_answer: member_answer.filter(a => a.r).length,
                            incorrect_answer: member_answer.filter(a => !a.r).length
                        }
                        score_group.push(group)
                        group_index = group_index + group_time
                    }
                }
                // compile result
                const result = {
                    total_answer: calculated_answer.length,
                    score_group,
                    average_speed: calculated_answer.length / 40,
                    total_incorrect: calculated_answer.filter(a => a.r === false).length,
                    difference_lowest_highest: getMin(score_group, 'total_answer').total_answer - getMax(score_group, 'total_answer').total_answer,
                    // chart using score_group
                }
                // update status trial
                const updateStatus = { ...resilience_questionary_answer, answer: JSON.stringify(answer), result: JSON.stringify(result) }
                // update status trial end to server
                const psc = new ParticipantSession();
                await psc.saveQuestionaryAnswerResilience(props.assessment_participant_session.id, updateStatus);
                // update state
                setResilienceQuestionaryAnswer(updateStatus)
            }

        } catch (error) {
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }, [props.assessment_participant_session.id, resilience_questionary_answer, setNotif, resilience])
    // use Effect in timeRemaining
    useEffect(() => {
        if (timeRemaining !== null && timeRemaining <= 1) {
            uploadAnswer(questionary_answer)
        }
        if (timeRemaining !== null && timeRemaining > 0) {
            // show ditraction every 60 seconds
            // console.log(Math.floor(timeRemaining % 60))
            // console.log(Math.floor(Date.now() / 1000) % 60)
            if (Math.floor(Date.now() / 1000) % 60 === 0) {
                setShowDistraction(true)
                // for 1 second
                setTimeout(function () {
                    setShowDistraction(false)
                    // set focus on answer field
                    answerRef.current?.focus()
                }, 1000);
            }
        }
    }, [timeRemaining, questionary_answer, uploadAnswer]);

    // confirm start trial test
    const startTrial = async () => {
        const confirm = await Swal.fire({
            title: "Mulai Tes Percobaan?",
            text: "Anda memiliki waktu 1 menit dalam mengerjakan test percobaan!",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#909090",
            confirmButtonText: "Ya, Mulai!",
        });
        if (confirm.isConfirmed) {
            // trial status
            setTrialStatus(true)
            // generate new questionary
            setNewQuestionary()
            // set focus on trial answer field
            trialAnswerRef.current?.focus()
            // trial duration
            const trialDuration = (resilience?.trial_time ?? 1) * 60 * 1000

            // start counter
            const counter = setInterval(() => {
                setRemainingTrialTime(prevRemaining => prevRemaining - 1000)
            }, 1000)

            // set timeout trial time
            setTimeout(() => {
                clearInterval(counter);
                // handle end trial duration
                endTrial()
            }, trialDuration);
        }
    }
    // end trial
    const endTrial = async () => {
        try {
            if (resilience_questionary_answer) {
                // update status trial
                const updateTrialStatus = { ...resilience_questionary_answer, trial_status: 1 }
                // update status trial end to server
                const psc = new ParticipantSession();
                await psc.saveQuestionaryAnswerResilience(props.assessment_participant_session.id, {
                    ...updateTrialStatus
                });
                // update state
                setResilienceQuestionaryAnswer(updateTrialStatus)
                // trial status
                setTrialStatus(true)
            }
        } catch (error) {
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }
    // create random value between given min and max value
    function randomIntFromInterval(min: number, max: number) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
    }
    // handle blur on trial answer field
    const handleOnBlurTrial = (e: FocusEvent<HTMLInputElement, Element>) => {
        if (e) {
            const relatedTarget = e.relatedTarget;
            if (relatedTarget === null) {
                e.currentTarget.focus();
            }
        }
    }
    // handle change on trial answer field
    const handleOnChangeTrial = (e: ChangeEvent<HTMLInputElement>) => {
        if (e) {
            const value = e.currentTarget.value;
            if (value !== '') {
                setTimeout(function () {
                    setNewQuestionary()
                }, 100);
            }
        }
    }
    // confirm start test
    const startTest = async () => {
        const confirm = await Swal.fire({
            title: "Mulai Tes?",
            text: "Apakah anda ingin memulai tes?",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#909090",
            confirmButtonText: "Ya, Mulai!",
        });
        if (confirm.isConfirmed) {
            try {
                if (resilience_questionary_answer) {
                    // update test status
                    const updateTestStatus = { ...resilience_questionary_answer, test_status: 1 }
                    // update test status end to server
                    const psc = new ParticipantSession();
                    await psc.saveQuestionaryAnswerResilience(props.assessment_participant_session.id, {
                        ...updateTestStatus
                    });
                    // update resilience session time
                    await psc.updateResilienceSession(props.assessment_participant_session.id);
                    // update state
                    setResilienceQuestionaryAnswer(updateTestStatus)
                    // generate new questionary
                    setNewQuestionary()
                    // start status
                    setTestStatus(true)
                    // set focus on answer field
                    answerRef.current?.focus()
                }
            } catch (error) {
                const errorMessage = errorHandler(error);
                setNotif({ type: "error", message: errorMessage });
            }
        }
    }
    // handle on change keyboard on display
    const handleOnChangeButton = (value: number) => {
        if (answerRef.current) answerRef.current.value = value.toString();
        handleOnChange(value);
    }
    // handle blur on answer field
    const handleOnBlur = (e: FocusEvent<HTMLInputElement, Element>) => {
        if (e) {
            const relatedTarget = e.relatedTarget;
            if (relatedTarget === null) {
                e.currentTarget.focus();
            }
        }
    }
    // handle change on answer field
    const handleOnChange = async (e: ChangeEvent<HTMLInputElement> | number) => {
        if (e !== undefined) {
            const value = typeof e === "number" ? e.toString() : e.currentTarget.value;
            if (resilience_questionary_answer && timeRemaining && value !== '') {
                // set answer
                const new_answer: TPSResilienceQustionaryAnswer = {
                    q: `${int1} + ${int2}`, // question
                    ca: correct_answer, //correct answer
                    a: parseInt(value), // answer
                    r: parseInt(value) === correct_answer, // result, correct = true, incorrect = false
                    t: Date.now() // time, seconds elapsed
                }
                const updateQA = [...questionary_answer, new_answer]
                setQuestionaryAnswer(updateQA)
                // update questionary
                setTimeout(function () {
                    setNewQuestionary()
                }, 100);
                // upload answer to server
                // check total answer upload on every 15 answer
                if (updateQA.length % 15 === 0) {
                    uploadAnswer(updateQA)
                }
            }
        }
    }

    return (
        <div className="h-[94%] overflow-auto">
            <div className="h-full flex flex-col items-center justify-center">
                {resilience_questionary_answer?.trial_status === 0 &&
                    <>
                        {!trial_status
                            ? <div className="text-sm md:text-base max-w-5xl text-center py-3 mb-28">
                                <div className="text-base lg:text-2xl font-semibold">Instruksi:</div>
                                <div className="text-base lg:text-xl px-5 lg:px-0">
                                    {resilience?.mdi}
                                </div>
                                <button
                                    type="button"
                                    onClick={startTrial}
                                    className="mt-10 text-base px-4 py-2 rounded-sm bg-blue-100 border border-blue-500 text-blue-700 font-semibold"
                                >
                                    Mulai Tes Percobaan
                                </button>
                            </div>
                            : <div>
                                <div className="hidden">{remaining_trial_time}</div>
                                <div className="flex items-center space-x-7 text-7xl font-bold h-56 text-slate-700">
                                    <div className="">{`${int1} + ${int2}`} = </div>
                                    <div>
                                        <input
                                            ref={trialAnswerRef}
                                            id="trial_answer"
                                            type="number"
                                            placeholder="?"
                                            className={"w-32 text-center outline-none border-b-4 focus:border-b-blue-700 focus:bg-slate-100 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"}
                                            onBlur={(e) => handleOnBlurTrial(e)}
                                            onChange={(e) => handleOnChangeTrial(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                }
                {resilience_questionary_answer?.trial_status === 1 &&
                    <>
                        {!test_status
                            ? <div className="text-sm md:text-base max-w-5xl text-center py-3 mb-28">
                                <div className="text-base lg:text-xl px-4 italic text-gray-600">
                                    Waktu test percobaan telah habis, <br />
                                    silahkan tekan tombol "Mulai Tes" dibawah untuk memulai tes.
                                </div>
                                <button
                                    type="button"
                                    onClick={startTest}
                                    className="mt-10 text-base px-4 py-2 rounded-sm bg-red-100 border border-red-500 text-red-700 font-semibold"
                                >
                                    Mulai Tes
                                </button>
                            </div>
                            : <>
                                {show_distraction
                                    ? <div className="text-4xl font-semibold">
                                        {resilience_distraction[Math.floor(Math.random() * resilience_distraction.length)].distraction}
                                    </div>
                                    : <div className="flex flex-col items-center md:flex-row space-x-0 md:space-x-20">
                                        <div>
                                            <div className="hidden">{timeRemaining}</div>
                                            <div className="flex items-center space-x-7 text-4xl md:text-7xl font-bold h-56 text-slate-700">
                                                <div className="">{`${int1} + ${int2}`} = </div>
                                                <div>
                                                    <input
                                                        ref={answerRef}
                                                        id="answer"
                                                        type="number"
                                                        placeholder="?"
                                                        className={"w-24 md:w-32 text-center outline-none border-b-4 focus:border-b-blue-700 focus:bg-slate-100 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"}
                                                        onBlur={(e) => handleOnBlur(e)}
                                                        onChange={(e) => handleOnChange(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {resilience?.use_keyboard_in_display === 1 &&
                                            <div className="grid grid-cols-3 text-2xl">
                                                <div onClick={() => handleOnChangeButton(1)} className="h-14 w-14 bg-blue-500 hover:bg-blue-600 text-white m-1 flex items-center justify-center cursor-pointer">1</div>
                                                <div onClick={() => handleOnChangeButton(2)} className="h-14 w-14 bg-blue-500 hover:bg-blue-600 text-white m-1 flex items-center justify-center cursor-pointer">2</div>
                                                <div onClick={() => handleOnChangeButton(3)} className="h-14 w-14 bg-blue-500 hover:bg-blue-600 text-white m-1 flex items-center justify-center cursor-pointer">3</div>
                                                <div onClick={() => handleOnChangeButton(4)} className="h-14 w-14 bg-blue-500 hover:bg-blue-600 text-white m-1 flex items-center justify-center cursor-pointer">4</div>
                                                <div onClick={() => handleOnChangeButton(5)} className="h-14 w-14 bg-blue-500 hover:bg-blue-600 text-white m-1 flex items-center justify-center cursor-pointer">5</div>
                                                <div onClick={() => handleOnChangeButton(6)} className="h-14 w-14 bg-blue-500 hover:bg-blue-600 text-white m-1 flex items-center justify-center cursor-pointer">6</div>
                                                <div onClick={() => handleOnChangeButton(7)} className="h-14 w-14 bg-blue-500 hover:bg-blue-600 text-white m-1 flex items-center justify-center cursor-pointer">7</div>
                                                <div onClick={() => handleOnChangeButton(8)} className="h-14 w-14 bg-blue-500 hover:bg-blue-600 text-white m-1 flex items-center justify-center cursor-pointer">8</div>
                                                <div onClick={() => handleOnChangeButton(9)} className="h-14 w-14 bg-blue-500 hover:bg-blue-600 text-white m-1 flex items-center justify-center cursor-pointer">9</div>
                                                <div></div>
                                                <div onClick={() => handleOnChangeButton(0)} className="h-14 w-14 bg-blue-500 hover:bg-blue-600 text-white m-1 flex items-center justify-center cursor-pointer">0</div>
                                            </div>
                                        }
                                    </div>
                                }
                            </>
                        }
                    </>

                }
                {false &&
                    <div className="text-xs">
                        <div>{`${int1} + ${int2} = ${correct_answer}`}</div>
                    </div>
                }
            </div>
        </div>
    );
}

export default SubtestResilience;
