import { useCallback, useContext, useState, useEffect, useMemo, Fragment } from "react";
import { useMediaQuery } from "react-responsive";
import Swal from "sweetalert2";
import { Transition } from "@headlessui/react";

// Icons
import { PlusIcon, TrashIcon, } from "@heroicons/react/24/outline";

// Context
import SnackbarContext from "../../../context/psikotest/SnackbarContext";
import LoaderContext from "../../../context/psikotest/LoaderContext";

// Helper
import errorHandler from "../../../helper/psikotest/errorHandler";
import logRender from "../../../helper/psikotest/logRender";

// Controller
import RacFormulaNorma from "../../../controller/psikotest/psikotest_system/rac_formula_norma";

// Component
import TableFilter from "../../../components/psikotest/psikotest_system/TableFilter";
import Table from "../../../components/psikotest/psikotest_system/Table";

// Modals
import FormFormulaNorma from "./modal/FormFormulaNorma";

function List() {
  const { setMessage } = useContext(LoaderContext);
  const { setNotif } = useContext(SnackbarContext);
  // required state
  const [list, setList] = useState<Array<TPSRacFormulaNorma>>([]);
  const [search, setSearch] = useState<string>("");
  // state modal
  const [isOpenFormFormulaNorma, setIsOpenFormFormulaNorma] = useState<boolean>(false);
  // state other
  const [selected_rac_formula_norma, setSelectedRacFormulaNorma] = useState<TPSRacFormulaNorma | undefined>(undefined);

  useEffect(() => {
    logRender({ type: "page", name: "rac_formula_norma/List" });
  }, []);

  const getList = useCallback(async () => {
    try {
      setMessage("Fetch Rac Formula Norma");
      const rac_qc = new RacFormulaNorma();
      const res = await rac_qc.list();
      setList(res.data.list);
      setMessage("");
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  }, [setNotif, setMessage]);

  useEffect(() => {
    getList();
  }, [getList]);

  const handleDeleteList = (id: number) => {
    const arrFilter = [...list].filter((v) => v.id !== id);
    setList(arrFilter);
  };

  // when selected_rac_formula_norma changes and not undefined open Cfit FormulaNorma form to update
  useEffect(() => {
    if (selected_rac_formula_norma !== undefined) {
      setIsOpenFormFormulaNorma(true)
    }
  }, [selected_rac_formula_norma]);

  // handleRefreshAfterOpenForm
  const handleRefreshAfterOpenForm = () => {
    // rmove selected value will close form modal
    setSelectedRacFormulaNorma(undefined)
    // close modal
    setIsOpenFormFormulaNorma(false)
    // re-fetch data
    getList();
  }

  return (
    <div className="w-full flex flex-col space-y-0.5">
      {/* FormulaNorma Form */}
      <Transition
        as={Fragment}
        show={isOpenFormFormulaNorma}
        enter="ease-out duration-50"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-50"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div>
          <FormFormulaNorma data={selected_rac_formula_norma} handleClose={() => handleRefreshAfterOpenForm()} />
        </div>
      </Transition>
      <div className="flex flex-col md:flex-row items-start md:items-center">
        <div className="flex space-x-1">
          <button
            type="button"
            onClick={() => { setIsOpenFormFormulaNorma(true) }}
            className="block w-auto mb-2 md:m-0 px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded font-semibold text-sm"
          >
            <div className="flex">
              <PlusIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
              Create
            </div>
          </button>
        </div>
        <div className="w-full md:w-auto ml-auto flex">
          <TableFilter search={setSearch} />
        </div>
      </div>
      <div className="pb-10">
        <RacFormulaNormaList list={list} handleDeleteList={handleDeleteList} search={search} setSelectedRacFormulaNorma={setSelectedRacFormulaNorma} />
      </div>
    </div >
  );
}

function RacFormulaNormaList(props: {
  list: Array<TPSRacFormulaNorma>;
  handleDeleteList: (id: number) => void;
  search: string,
  setSelectedRacFormulaNorma: React.Dispatch<React.SetStateAction<TPSRacFormulaNorma | undefined>>
}) {
  const setNotif = useContext(SnackbarContext).setNotif;
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const confirmDelete = useCallback(
    async (rac_q: TPSRacFormulaNorma) => {
      try {
        const confirm = await Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        });
        if (confirm.isConfirmed) {
          const rac_qc = new RacFormulaNorma();
          await rac_qc.delete(rac_q.id);
          props.handleDeleteList(rac_q.id);
          setNotif({ type: "success", message: "rac questionary deleted" });
        }
      } catch (error) {
        const errorMessage = errorHandler(error);
        setNotif({ type: "error", message: errorMessage });
      }
    },
    [props, setNotif]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Speed',
        disableSortBy: true,
        columns: [
          {
            id: "mobile",
            classHeader: "text-left px-2",
            Header: "Norma (Value)",
            classDivHeader: "flex items-center",
            accessor: "value",
            show: isMobile,
            Cell: (colProps: { row: { original: TPSRacFormulaNorma } }) => {
              return (
                <button className="text-blue-500 hover:text-blue-800" onClick={() => props.setSelectedRacFormulaNorma(colProps.row.original)}>
                  {colProps.row.original.value || "NULL"}
                </button>
              );
            },
          },
        ]
      },
      {
        id: "kecepatan",
        Header: 'Speed',
        disableSortBy: true,
        show: false,
        columns: [
          {
            id: "value",
            classHeader: "text-left px-2",
            Header: <div title="Norma (Value)">Norma (Value)</div>,
            classDivHeader: "flex items-center",
            accessor: "value",
            show: !isMobile,
            Cell: (colProps: { row: { original: TPSRacFormulaNorma } }) => {
              return (
                <button className="flex items-center text-blue-500 hover:text-blue-800" onClick={() => props.setSelectedRacFormulaNorma(colProps.row.original)}>
                  {colProps.row.original.value}
                </button>
              );
            },
          },
          {
            id: "range_min",
            classHeader: "text-left px-2",
            Header: <div title="Min (Range)">Min (Range)</div>,
            classDivHeader: "flex items-center",
            accessor: "range_min",
            show: !isMobile,
            Cell: (props: { row: { original: TPSRacFormulaNorma } }) => {
              return props.row.original.range_min
            },
          },
          {
            id: "range_max",
            classHeader: "text-left px-2",
            Header: <div title="Max (Range)">Max (Range)</div>,
            classDivHeader: "flex items-center",
            accessor: "range_max",
            show: !isMobile,
            Cell: (props: { row: { original: TPSRacFormulaNorma } }) => {
              return props.row.original.range_max
            },
          },
        ]
      },
      {
        Header: 'Accuracy',
        disableSortBy: true,
        columns: [
          {
            id: "rendah_min",
            classHeader: "text-left px-2",
            Header: <div title="Rendah (Min)">Rendah (Min)</div>,
            classDivHeader: "flex items-center",
            accessor: "rendah_min",
            show: !isMobile,
            Cell: (props: { row: { original: TPSRacFormulaNorma } }) => {
              return props.row.original.rendah_min
            },
          },
          {
            id: "rendah_max",
            classHeader: "text-left px-2",
            Header: <div title="Rendah (Max)">Rendah (Max)</div>,
            classDivHeader: "flex items-center",
            accessor: "rendah_max",
            show: !isMobile,
            Cell: (props: { row: { original: TPSRacFormulaNorma } }) => {
              return props.row.original.rendah_max
            },
          },
          {
            id: "kurang_min",
            classHeader: "text-left px-2",
            Header: <div title="Kurang (Min)">Kurang (Min)</div>,
            classDivHeader: "flex items-center",
            accessor: "kurang_min",
            show: !isMobile,
            Cell: (props: { row: { original: TPSRacFormulaNorma } }) => {
              return props.row.original.kurang_min
            },
          },
          {
            id: "kurang_max",
            classHeader: "text-left px-2",
            Header: <div title="Kurang (Max)">Kurang (Max)</div>,
            classDivHeader: "flex items-center",
            accessor: "kurang_max",
            show: !isMobile,
            Cell: (props: { row: { original: TPSRacFormulaNorma } }) => {
              return props.row.original.kurang_max
            },
          },
          {
            id: "cukup_min",
            classHeader: "text-left px-2",
            Header: <div title="Cukup (Min)">Cukup (Min)</div>,
            classDivHeader: "flex items-center",
            accessor: "cukup_min",
            show: !isMobile,
            Cell: (props: { row: { original: TPSRacFormulaNorma } }) => {
              return props.row.original.cukup_min
            },
          },
          {
            id: "cukup_max",
            classHeader: "text-left px-2",
            Header: <div title="Cukup (Max)">Cukup (Max)</div>,
            classDivHeader: "flex items-center",
            accessor: "cukup_max",
            show: !isMobile,
            Cell: (props: { row: { original: TPSRacFormulaNorma } }) => {
              return props.row.original.cukup_max
            },
          },
          {
            id: "baik_min",
            classHeader: "text-left px-2",
            Header: <div title="Baik (Min)">Baik (Min)</div>,
            classDivHeader: "flex items-center",
            accessor: "baik_min",
            show: !isMobile,
            Cell: (props: { row: { original: TPSRacFormulaNorma } }) => {
              return props.row.original.baik_min
            },
          },
          {
            id: "baik_max",
            classHeader: "text-left px-2",
            Header: <div title="Baik (Max)">Baik (Max)</div>,
            classDivHeader: "flex items-center",
            accessor: "baik_max",
            show: !isMobile,
            Cell: (props: { row: { original: TPSRacFormulaNorma } }) => {
              return props.row.original.baik_max
            },
          },
        ],
      },
      {
        Header: ' ',
        disableSortBy: true,
        columns: [
          {
            id: "action_delete",
            classHeader: "w-[30px] text-white",
            Header: () => {
              return <TrashIcon className="h-5 w-auto" />;
            },
            classDivHeader: "flex justify-center",
            accessor: "id",
            show: !isMobile,
            disableSortBy: true,
            className: "text-white bg-red-500 hover:bg-red-800",
            Cell: (props: { row: { original: TPSRacFormulaNorma } }) => {
              return (
                <button className="flex justify-center" onClick={() => confirmDelete(props.row.original)}>
                  <TrashIcon className="h-5" />
                </button>
              );
            },
          },
        ]
      },
    ],
    [isMobile, confirmDelete, props]
  );

  return <Table columns={columns} list={props.list} search={props.search} />;
}

export default List;
