import { useContext } from "react";
import { Routes, Route, useLocation, Navigate, Outlet } from "react-router-dom";

// Context
import PSAuthContext from "./context/psikotest/AuthContext";
import ASAuthContext from "./context/assessment/AuthContext";

// Pages
// Psikotest
import PsikotestParticipantApp from "./pages/psikotest_participant/PsikotestParticipantApp";
import PsikotestSystemLogin from "./pages/psikotest_system/PsikotestSystemLogin";
import PsikotestSystemApp from "./pages/psikotest_system/PsikotestSystemApp";
// Assessment
import AssessmentParticipantApp from "./pages/assessment_participant/AssessmentParticipantApp";
import AssessmentSystemLogin from "./pages/assessment_system/AssessmentSystemLogin";
import AssessmentSystemApp from "./pages/assessment_system/AssessmentSystemApp";
// Others
import Landing from "./pages/Landing";
function AppRoutes() {
  return (
    <Routes>
      <Route path={`/`} element={<Landing />} />
      <Route path={`psikotest_participant//*`} element={<PsikotestParticipantApp />} />
      <Route path={`psikotest_system/login`} element={<PsikotestSystemLogin />} />
      <Route element={<ProtectedPSRoute />}>
        <Route path={`psikotest_system//*`} element={<PsikotestSystemApp />} />
      </Route>
      <Route path={`assessment_participant//*`} element={<AssessmentParticipantApp />} />
      <Route path={`assessment_system/login`} element={<AssessmentSystemLogin />} />
      <Route element={<ProtectedASRoute />}>
        <Route path={`assessment_system//*`} element={<AssessmentSystemApp />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

const ProtectedPSRoute = () => {
  const { user } = useContext(PSAuthContext);
  if (user === null) {
    return <Navigate to="/psikotest_system/login" replace />;
  }
  return <Outlet />;
};

const ProtectedASRoute = () => {
  const { user } = useContext(ASAuthContext);
  if (user === null) {
    return <Navigate to="/assessment_system/login" replace />;
  }
  return <Outlet />;
};

function NotFound() {
  const location = useLocation();
  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

export default AppRoutes;
