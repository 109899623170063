
import { useCallback, useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";

// Icons
import { PlusIcon } from "@heroicons/react/24/outline";

// Context
import SnackbarContext from "../../../context/psikotest/SnackbarContext";
import LoaderContext from "../../../context/psikotest/LoaderContext";

// Helper
import errorHandler from "../../../helper/psikotest/errorHandler";

// Controller
import Ist from "../../../controller/psikotest/psikotest_system/ist";
import IstConversion from "../../../controller/psikotest/psikotest_system/ist_conversion";

// Costant Value
const minScoreMinValue = 0
const minScoreMaxValue = 1
const minScoreConversionValue = 1
const maxConversion = 50

function ListConversion(props: { isCreate: boolean; ist: TPSIst; editable: boolean }) {
    const { setMessage } = useContext(LoaderContext)
    const { setNotif } = useContext(SnackbarContext)
    // state list conversion
    const [conversion, setConversion] = useState<TPSIstConversion[]>([])

    const getDoc = useCallback(
        async function getDoc() {
            try {
                if (!props.isCreate) {
                    if (props.ist.id) {
                        // fetch ist_conversion list
                        setMessage("Fetch Ist Conversion");
                        const ci = new Ist();
                        const res = await ci.conversion(props.ist.id);
                        const list: TPSIstConversion[] = res.data.list;
                        setConversion(list);
                    }
                }
                setMessage("");
            } catch (error) {
                setMessage("");
                const errorMessage = errorHandler(error);
                setNotif({ type: "error", message: errorMessage });
            }
        },
        [setNotif, setMessage, props.ist.id, props.isCreate]
    );

    useEffect(() => {
        getDoc();
    }, [getDoc]);

    // handle create empty conversion
    const createEmptyConversion = async () => {
        try {
            let createData: TPSIstConversion = {
                id: 0,
                ist_id: props.ist.id,
                score_min: conversion.length === 0
                    ? minScoreMinValue
                    : (conversion[conversion.length - 1]?.score_max ?? 0) + 1,
                score_max: conversion.length === 0
                    ? minScoreMaxValue
                    : (conversion[conversion.length - 1]?.score_max ?? 0) + 2,
                score_conversion: conversion.length === 0
                    ? minScoreConversionValue
                    : (conversion[conversion.length - 1]?.score_conversion ?? 0) + 1,
            }
            const cic = new IstConversion();
            const res = await cic.create(createData)
            createData.id = res.data.result.saved_id
            // push new created conversion to conversion array
            setConversion([...conversion, createData])
        } catch (error) {
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }

    // handle update 
    const updateConversion = async (e: React.ChangeEvent<HTMLInputElement>, data: TPSIstConversion, index: number) => {
        try {
            let updateData: TPSIstConversion = {
                id: data.id,
                ist_id: data.ist_id,
                score_min: e.target.name === "score_min" ? parseInt(e.target.value) : data.score_min,
                score_max: e.target.name === "score_max" ? parseInt(e.target.value) : data.score_max,
                score_conversion: e.target.name === "score_conversion" ? parseInt(e.target.value) : data.score_conversion
            }
            // copy to update state 
            const copyConversion = [...conversion];
            copyConversion[index] = updateData
            // update state
            setConversion(copyConversion)
            // update to server
            const cic = new IstConversion();
            await cic.update(updateData)
        } catch (error) {
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
        // re-fetch data
        // make sure run even on error
        getDoc();
    }

    // handle delete
    const confirmDelete = useCallback(
        async (ic: TPSIstConversion) => {
            try {
                const confirm = await Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                });
                if (confirm.isConfirmed) {
                    const cic = new IstConversion();
                    await cic.delete(ic);
                    setNotif({ type: "success", message: "ist conversion deleted" });
                    // re-fetch data
                    getDoc();
                }
            } catch (error) {
                const errorMessage = errorHandler(error);
                setNotif({ type: "error", message: errorMessage });
            }
        },
        [setNotif, getDoc]
    );

    return (
        <div className="pb-[40rem]">
            {conversion.length < maxConversion && props.editable && (
                <div className="w-full md:w-full mb-2">
                    <button
                        type="button"
                        onClick={() => { createEmptyConversion() }}
                        className="block w-auto px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded font-semibold text-sm"
                    >
                        <div className="flex">
                            <PlusIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
                            Conversion
                        </div>
                    </button>
                </div>
            )}
            <div className="pt-2">
                <table className="w-full border">
                    <thead className="bg-slate-600 text-white">
                        <tr>
                            <th className="py-1 px-2 text-center border border-slate-500" colSpan={3}>Score Range</th>
                            <th className="py-1 px-2 text-center border border-slate-500">Score Conversion</th>
                            <th className="py-1 px-2 w-20 border border-slate-500">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {conversion.map((c, i) => {
                            return (
                                <tr key={`conversion_${i}`}>
                                    <td className="py-1 px-2 border">
                                        <input type="number" name="score_min" className="border px-3 text-center" value={c.score_min} onChange={(e) => updateConversion(e, c, i)} />
                                    </td>
                                    <td className="py-1 px-2 border"> - </td>
                                    <td className="py-1 px-2 border">
                                        <input type="number" name="score_max" className="border px-3 text-center" value={c.score_max} onChange={(e) => updateConversion(e, c, i)} />
                                    </td>
                                    <td className="py-1 px-2 border">
                                        <input type="number" name="score_conversion" className="border px-3 text-center" value={c.score_conversion} onChange={(e) => updateConversion(e, c, i)} />
                                    </td>
                                    <td className="py-1 px-2 border text-center">
                                        <button type="button" className="px-2 py-[2px] rounded bg-red-500 text-white" onClick={() => confirmDelete(c)}>
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ListConversion;
