import { Link } from "react-router-dom";
import { useCallback, useContext, useState, useEffect, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import Swal from "sweetalert2";

// Icons
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";

// Context
import AuthContext from "../../../context/assessment/AuthContext";
import SnackbarContext from "../../../context/assessment/SnackbarContext";
import LoaderContext from "../../../context/assessment/LoaderContext";

// Controller
import Assessment from "../../../controller/assessment/assessment_system/assessment";

// Helper
import errorHandler from "../../../helper/assessment/errorHandler";
import logRender from "../../../helper/assessment/logRender";

// Component
import TableFilter from "../../../components/assessment/assessment_system/TableFilter";
import Table from "../../../components/assessment/assessment_system/Table";

// Variables
const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

function List() {
  const { user } = useContext(AuthContext);
  const { setMessage } = useContext(LoaderContext);
  const { setNotif } = useContext(SnackbarContext);
  const [list, setList] = useState<Array<TAssessment>>([]);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    logRender({ type: "page", name: "assessment_system/List" });
  }, []);

  const getList = useCallback(async () => {
    try {
      setMessage("Fetch Assessment");
      const ac = new Assessment();
      const res = await ac.list();
      setList(res.data.list);
      setMessage("");
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  }, [setNotif, setMessage]);

  useEffect(() => {
    getList();
  }, [getList]);

  const handleDeleteList = (id: number) => {
    const arrFilter = [...list].filter((v) => v.id !== id);
    setList(arrFilter);
  };

  return (
    <div className="w-full flex flex-col space-y-0.5">
      <div className="flex flex-col md:flex-row items-start md:items-center">
        {user.roles === "1" && (
          <Link
            to={`create`}
            className="block w-auto mb-2 md:m-0 px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded font-semibold text-sm"
          >
            <div className="flex">
              <PencilIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
              Create
            </div>
          </Link>
        )}
        <div className="w-full md:w-auto ml-auto flex">
          <TableFilter search={setSearch} />
        </div>
      </div>
      <div>
        <AssessmentList list={list} handleDeleteList={handleDeleteList} search={search} />
      </div>
    </div>
  );
}

function AssessmentList(props: { list: Array<TAssessment>; handleDeleteList: (id: number) => void; search: string }) {
  const { user } = useContext(AuthContext);
  const setNotif = useContext(SnackbarContext).setNotif;
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const confirmDelete = useCallback(
    async (assessment: TAssessment) => {
      try {
        const confirm = await Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        });
        if (confirm.isConfirmed) {
          const ac = new Assessment();
          await ac.delete(assessment.id.toString());
          props.handleDeleteList(assessment.id);
          setNotif({ type: "success", message: "assessment deleted" });
        }
      } catch (error) {
        const errorMessage = errorHandler(error);
        setNotif({ type: "error", message: errorMessage });
      }
    },
    [props, setNotif]
  );

  const columns = useMemo(
    () => [
      {
        id: "mobile",
        Header: "Assessment",
        accessor: "id",
        show: isMobile,
        Cell: (props: { row: { original: TAssessment } }) => {
          return (
            <Link to={`${props.row.original.id}`} className="text-blue-500 hover:text-blue-800">
              {props.row.original.title || "NULL"}
            </Link>
          );
        },
      },
      {
        id: "title",
        classHeader: "text-left px-2",
        Header: "Title",
        classDivHeader: "flex items-center",
        accessor: "title",
        show: !isMobile,
        Cell: (props: { row: { original: TAssessment } }) => {
          return (
            <Link to={`${props.row.original.id}`} className="text-blue-500 hover:text-blue-800">
              {props.row.original.title || "NULL"}
            </Link>
          );
        },
      },
      {
        id: "company_name",
        classHeader: "text-left px-2",
        Header: "Company",
        classDivHeader: "flex items-center",
        accessor: "company_name",
        show: !isMobile,
        Cell: (props: { row: { original: TAssessment } }) => {
          return <span>{props.row.original.company_name || "NULL"}</span>;
        },
      },
      {
        id: "date",
        classHeader: "text-left px-2",
        Header: "Date",
        classDivHeader: "flex items-center",
        accessor: "date",
        show: !isMobile,
        Cell: (props: { row: { original: TAssessment } }) => {
          const d = new Date(props.row.original.date)
          const dText = `${d.getDate().toString().padStart(2, "0")} ${monthNames[d.getMonth()]} ${d.getFullYear()}`
          return <span className="whitespace-nowrap">{dText || "NULL"}</span>;
        },
      },
      {
        id: "start_time",
        classHeader: "text-left px-2",
        Header: "Start Time",
        classDivHeader: "flex items-center",
        accessor: "start_time",
        show: !isMobile,
        Cell: (props: { row: { original: TAssessment } }) => {
          return <span>{props.row.original.start_time.slice(0, -3) || "NULL"}</span>;
        },
      },
      {
        id: "pic",
        classHeader: "text-left px-2",
        Header: "PIC",
        classDivHeader: "flex items-center",
        accessor: "pic",
        show: !isMobile,
        Cell: (props: { row: { original: TAssessment } }) => {
          return <span>{props.row.original.pic || "NULL"}</span>;
        },
      },
      {
        id: "qc",
        classHeader: "text-left px-2",
        Header: "QC",
        classDivHeader: "flex items-center",
        accessor: "qc",
        show: !isMobile,
        Cell: (props: { row: { original: TAssessment } }) => {
          return <span>{props.row.original.qc || "NULL"}</span>;
        },
      },
      {
        id: "status",
        classHeader: "text-left px-2",
        Header: "Status",
        classDivHeader: "flex items-center",
        accessor: "status",
        show: !isMobile,
        Cell: (props: { row: { original: TAssessment } }) => {
          switch (props.row.original.status) {
            case null:
              return <span className="px-2 rounded text-sm bg-slate-300">draft</span>;
            case "0":
              return <span className="px-2 rounded text-sm bg-slate-300">draft</span>;
            case "1":
              return <span className="px-2 rounded text-sm bg-blue-600 text-white">progress</span>;
            case "2":
              return <span className="px-2 rounded text-sm bg-yellow-600 text-white">scoring</span>;
            case "3":
              return <span className="px-2 rounded text-sm bg-orange-600 text-white">checking</span>;
            case "99":
              return <span className="px-2 rounded text-sm bg-green-600 text-white">completed</span>;
            default:
              return <span className="px-2 rounded text-sm bg-gray-300">n/a</span>;
          }
        },
      },
      {
        id: "action_delete",
        classHeader: "w-[30px] text-white",
        Header: () => {
          return <TrashIcon className="h-5" />;
        },
        classDivHeader: "flex justify-center",
        accessor: "id",
        show: !isMobile && user.roles === "1",
        disableSortBy: true,
        className: "text-white bg-red-500 hover:bg-red-800",
        Cell: (props: { row: { original: TAssessment } }) => {
          return (
            <button className="flex justify-center" onClick={() => confirmDelete(props.row.original)}>
              <TrashIcon className="h-5" />
            </button>
          );
        },
      },
    ],
    [isMobile, confirmDelete, user.roles]
  );

  return <Table columns={columns} list={props.list} search={props.search} />
}

export default List;
