import req from "../../../services/psikotest/request";

class Cfit {
  async list() {
    try {
      const res = await req.get("/api/psikotest_system/cfit/");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async create(data: TPSCfit) {
    try {
      const res = await req.post("/api/psikotest_system/cfit/", data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async get(id: string) {
    try {
      const res = await req.get("/api/psikotest_system/cfit/" + id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async update(data: TPSCfit) {
    try {
      const res = await req.put("/api/psikotest_system/cfit/" + data.id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async delete(data: TPSCfit) {
    try {
      const res = await req.delete("/api/psikotest_system/cfit/", { data });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async questionary(id: number) {
    try {
      const res = await req.get("/api/psikotest_system/cfit/" + id + "/questionary");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default Cfit;
