import { Routes, Route, Link } from "react-router-dom";

// Icons
import { HomeIcon } from "@heroicons/react/24/outline";

// Main Menu
import Dashboard from "./index_page/Dashboard";
import UserProfile from "./index_page/UserProfile";
import ChangePassword from "./index_page/ChangePassword";
import Setting from "./index_page/Setting";
import Dictionary from "./index_page/Dictionary";
import CompanyIndex from "./company/Index";
import CompanyList from "./company/List";
import CompanyForm from "./company/Form";
import InTrayEmailIndex from "./intray_email/Index";
import InTrayListEmail from "./intray_email/List";
import InTrayFormEmail from "./intray_email/FormEmail";
import InTrayFormEmailAddress from "./intray_email/FormEmailAddress";
import QuestionaryIndex from "./questionary/Index";
import QuestionaryList from "./questionary/List";
import QuestionaryForm from "./questionary/Form";
import AssessmentIndex from "./assessment/Index";
import AssessmentList from "./assessment/List";
import AssessmentForm from "./assessment/Form";
// Nav Menu
import AssessorIndex from "./assessor/Index";
import AssessorList from "./assessor/List";
import AssessorForm from "./assessor/Form";
import CategoryIndex from "./category/Index";
import CategoryList from "./category/List";
import CategoryForm from "./category/Form";
import LevelAssessmentIndex from "./level_assessment/Index";
import LevelAssessmentList from "./level_assessment/List";
import LevelAssessmentForm from "./level_assessment/Form";

function AssessmentSystemRoute() {
  return (
    <Routes>
      <Route path={`/`} element={<Dashboard />} />
      <Route path={`/me`} element={<UserProfile />} />
      <Route path={`/change_password`} element={<ChangePassword />} />
      <Route path={`/setting`} element={<Setting />} />
      {/* Main Menu */}
      <Route path={`/company`} element={<CompanyIndex />}>
        <Route index element={<CompanyList />} />
        <Route path={`/company/create`} element={<CompanyForm />} />
        <Route path={`/company/:id`} element={<CompanyForm />} />
      </Route>
      <Route path={`/intray_email`} element={<InTrayEmailIndex />}>
        <Route index element={<InTrayListEmail />} />
        <Route path={`/intray_email/create`} element={<InTrayFormEmail />} />
        <Route path={`/intray_email/:id`} element={<InTrayFormEmail />} />
        <Route path={`/intray_email/email_address/create`} element={<InTrayFormEmailAddress />} />
        <Route path={`/intray_email/email_address/:id`} element={<InTrayFormEmailAddress />} />
      </Route>
      <Route path={`/questionary`} element={<QuestionaryIndex />}>
        <Route index element={<QuestionaryList />} />
        <Route path={`/questionary/create`} element={<QuestionaryForm />} />
        <Route path={`/questionary/:id`} element={<QuestionaryForm />} />
      </Route>
      <Route path={`/assessment`} element={<AssessmentIndex />}>
        <Route index element={<AssessmentList />} />
        <Route path={`/assessment/create`} element={<AssessmentForm />} />
        <Route path={`/assessment/:id`} element={<AssessmentForm />} />
      </Route>
      <Route path={`/dictionary`} element={<Dictionary />} />
      {/* Admin Menu */}
      <Route path={`/assessor`} element={<AssessorIndex />}>
        <Route index element={<AssessorList />} />
        <Route path={`/assessor/create`} element={<AssessorForm />} />
        <Route path={`/assessor/:id`} element={<AssessorForm />} />
      </Route>
      <Route path={`/category`} element={<CategoryIndex />}>
        <Route index element={<CategoryList />} />
        <Route path={`/category/create`} element={<CategoryForm />} />
        <Route path={`/category/:id`} element={<CategoryForm />} />
      </Route>
      <Route path={`/level_assessment`} element={<LevelAssessmentIndex />}>
        <Route index element={<LevelAssessmentList />} />
        <Route path={`/level_assessment/create`} element={<LevelAssessmentForm />} />
        <Route path={`/level_assessment/:id`} element={<LevelAssessmentForm />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

function NotFound() {
  return (
    <div className="w-full flex justify-center items-center">
      <div className="text-center">
        <div className="text-9xl font-extrabold text-slate-700">
          <span>4</span>
          <span className="text-slate-400">0</span>
          <span>4</span>
        </div>
        <div className="text-center uppercase font-extrabold tracking-widest">
          - Page not found -
        </div>
        <div className="pt-3">Hello, It seems you are lost.</div>
        <div className="pt-1">
          <Link to={`/assessment_system`}>
            <div className="underline group flex justify-center items-center space-x-1 text-blue-500 hover:text-blue-900">
              <HomeIcon
                className="w-4 h-4 stroke-blue-500 group-hover:stroke-blue-900 fill-tranparent"
                aria-hidden="true"
              />
              <span>Back to homepage</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AssessmentSystemRoute;
