import { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Icons
import { BuildingOffice2Icon, ClipboardDocumentCheckIcon, QuestionMarkCircleIcon, UserGroupIcon } from "@heroicons/react/24/outline";

// Component
import BreadCrumbNavigation from "../../../components/psikotest/psikotest_system/BreadCrumbNavigation";

// Context
import SnackbarContext from "../../../context/psikotest/SnackbarContext";
import LoaderContext from "../../../context/psikotest/LoaderContext";

// Helper
import errorHandler from "../../../helper/psikotest/errorHandler";
import logRender from "../../../helper/psikotest/logRender";

// Controllers
import IndexPage from "../../../controller/psikotest/psikotest_system/index_page";

// Variables
const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

function Dashboard() {
  // Context
  const { setMessage } = useContext(LoaderContext);
  const { setNotif } = useContext(SnackbarContext);
  // States
  const [prevAssessment, setPrevAssessment] = useState<TPSDashboardAssessment[]>([]);
  const [currAssessment, setCurrAssessment] = useState<TPSDashboardAssessment[]>([]);
  const [nextAssessment, setNextAssessment] = useState<TPSDashboardAssessment[]>([]);
  const [statistic, setStatistic] = useState<{
    company: number;
    cfit_subtest: number;
    ist_subtest: number;
    assessment: number;
    assessor: number;
  }>({ company: 0, cfit_subtest: 0, ist_subtest: 0, assessment: 0, assessor: 0 });

  useEffect(() => {
    logRender({ type: "page", name: "Dashboard" });
  }, []);

  const getDashboardData = useCallback(async () => {
    try {
      setMessage("Fetch dashboard data");
      const ipc = new IndexPage();
      const resDashboard = await ipc.dashboard();
      // filter prev, current, next assessment
      const currDateTime = new Date();
      // remove time
      currDateTime.setHours(0, 0, 0, 0);
      const prev: TPSDashboardAssessment[] = [];
      const current: TPSDashboardAssessment[] = [];
      const next: TPSDashboardAssessment[] = [];
      resDashboard.data.detail.assessment_list.forEach((assessment: TPSDashboardAssessment) => {
        const dateTime = `${assessment.assessment.date}`;
        const assessmentDateTime = new Date(dateTime);
        // remove time
        assessmentDateTime.setHours(0, 0, 0, 0);
        if (currDateTime > assessmentDateTime) {
          // prev
          prev.push(assessment);
        } else if (currDateTime.getTime() === assessmentDateTime.getTime()) {
          // current (today)
          current.push(assessment);
        } else if (currDateTime < assessmentDateTime) {
          // next
          next.push(assessment);
        }
      });
      setPrevAssessment(prev);
      setCurrAssessment(current);
      setNextAssessment(next);
      setStatistic({
        company: resDashboard.data.detail.total_company,
        cfit_subtest: resDashboard.data.detail.total_cfit_subtest,
        ist_subtest: resDashboard.data.detail.total_ist_subtest,
        assessment: resDashboard.data.detail.total_assessment,
        assessor: resDashboard.data.detail.total_assessor,
      });
      setMessage("");
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  }, [setNotif, setMessage]);

  useEffect(() => {
    getDashboardData();
  }, [getDashboardData]);

  const Status = (props: { status: string }) => {
    let label;
    switch (props.status) {
      case "0":
        label = <div className="px-3 py-1 bg-gray-300 text-gray-900 text-center">Draft</div>;
        break;
      case "1":
        label = <div className="px-3 py-1 bg-blue-500 text-white text-center">On Progress</div>;
        break;
      case "2":
        label = <div className="px-3 py-1 bg-yellow-600 text-white text-center">Scoring</div>;
        break;
      case "3":
        label = <div className="px-3 py-1 bg-green-600 text-white text-center">Completed</div>;
        break;
      default:
        label = <div className="px-3 py-1 bg-gray-500 text-white text-center">Unknown</div>;
        break;
    }
    return label;
  };

  return (
    <div className="w-full flex flex-col">
      <BreadCrumbNavigation />
      {/* Today & Upcoming Assessment */}
      <div className="mt-1 p-1 md:p-4 border border-dashed border-gray-400 rounded">
        <h4 className="font-bold mb-3">Today & Upcoming Assessment</h4>
        {/* Desktop */}
        <div className="hidden md:block w-full">
          {currAssessment.length === 0 && nextAssessment.length === 0 ? (
            <div className="font-thin">&mdash; No Upcoming Assessment Avaible</div>
          ) : (
            <table className="table-fixed w-full">
              <thead className="bg-gray-500 text-white">
                <tr className="border text-left">
                  <th className="px-2 border font-normal w-4/12">Title</th>
                  <th className="px-2 border font-normal">Schedule</th>
                  <th className="px-2 border font-normal">Company</th>
                  <th className="px-2 border font-normal">Total Participant</th>
                  <th className="px-2 border font-normal">Status</th>
                </tr>
              </thead>
              <tbody>
                {currAssessment.map((assessment, i) => (
                  <tr key={`today_assessment_${i}`} className="border text-sm bg-red-100">
                    <td className="px-2 border">
                      <Link to={`assessment/${assessment.assessment.id}`} className="text-blue-500 hover:text-blue-800">
                        {assessment.assessment.assessment_title}
                      </Link>
                    </td>
                    <td className="px-2 border">
                      <div className="flex flex-row items-center space-x-1">
                        <div>{`${new Date(assessment.assessment.date).getDate().toString().padStart(2, "0")} ${monthNames[new Date(assessment.assessment.date).getMonth()]
                          } '${new Date(assessment.assessment.date).getFullYear() - 2000}`}</div>
                        <span>{assessment.assessment.start_time.slice(0, -3)}</span>
                      </div>
                    </td>
                    <td className="px-2 border">{assessment.company.company_name}</td>
                    <td className="px-2 border text-center">{assessment.total_participant}</td>
                    <td className="border text-xs">
                      <Status status={assessment.assessment.status} />
                    </td>
                  </tr>
                ))}
                {nextAssessment.map((assessment, i) => (
                  <tr key={`next_assessment_${i}`} className="border text-sm bg-blue-100">
                    <td className="px-2 border">
                      <Link to={`assessment/${assessment.assessment.id}`} className="text-blue-500 hover:text-blue-800">
                        {assessment.assessment.assessment_title}
                      </Link>
                    </td>
                    <td className="px-2 border">
                      <div className="flex flex-row items-center space-x-1">
                        <div>{`${new Date(assessment.assessment.date).getDate().toString().padStart(2, "0")} ${monthNames[new Date(assessment.assessment.date).getMonth()]
                          } '${new Date(assessment.assessment.date).getFullYear() - 2000}`}</div>
                        <span>{assessment.assessment.start_time.slice(0, -3)}</span>
                      </div>
                    </td>
                    <td className="px-2 border">{assessment.company.company_name}</td>
                    <td className="px-2 border text-center">{assessment.total_participant}</td>
                    <td className="border text-xs">
                      <Status status={assessment.assessment.status} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        {/* Mobile */}
        <div className="block md:hidden w-full">
          {currAssessment.length === 0 && nextAssessment.length === 0 ? (
            <div className="font-thin">&mdash; No Upcoming Assessment Avaible</div>
          ) : (
            <table className="table-fixed w-full">
              <tbody>
                {currAssessment.map((assessment, i) => (
                  <tr key={`today_assessment_${i}`} className="border text-sm bg-red-100">
                    <td className="p-2">
                      <div className="flex items-center font-semibold">
                        <Link to={`assessment/${assessment.assessment.id}`} className="text-blue-500 hover:text-blue-800">
                          {assessment.assessment.assessment_title}
                        </Link>
                      </div>
                      <div className="flex items-center">
                        <div className="w-20 font-semibold">Schedule</div>
                        <div className="flex flex-row items-center space-x-1">
                          <div>Today</div>
                          <span>{assessment.assessment.start_time.slice(0, -3)}</span>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="w-20 font-semibold">Company</div>
                        <div>{assessment.company.company_name}</div>
                      </div>
                      <div className="flex items-center">
                        <div className="w-20 font-semibold">Participant</div>
                        <div>{assessment.total_participant}</div>
                      </div>
                      <div className="flex items-center">
                        <div className="w-20 font-semibold">Status</div>
                        <div>
                          <Status status={assessment.assessment.status} />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
                {nextAssessment.map((assessment, i) => (
                  <tr key={`today_assessment_${i}`} className="border text-sm bg-blue-100">
                    <td className="p-2">
                      <div className="flex items-center font-semibold">
                        <Link to={`assessment/${assessment.assessment.id}`} className="text-blue-500 hover:text-blue-800">
                          {assessment.assessment.assessment_title}
                        </Link>
                      </div>
                      <div className="flex items-center">
                        <div className="w-20 font-semibold">Schedule</div>
                        <div className="flex flex-row items-center space-x-1">
                          <div>
                            {`
                              ${new Date(assessment.assessment.date).getDate().toString().padStart(2, "0")} 
                              ${monthNames[new Date(assessment.assessment.date).getMonth()]} 
                              '${new Date(assessment.assessment.date).getFullYear() - 2000}
                            `}
                          </div>
                          <span>{assessment.assessment.start_time.slice(0, -3)}</span>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="w-20 font-semibold">Company</div>
                        <div>{assessment.company.company_name}</div>
                      </div>
                      <div className="flex items-center">
                        <div className="w-20 font-semibold">Participant</div>
                        <div>{assessment.total_participant}</div>
                      </div>
                      <div className="flex items-center">
                        <div className="w-20 font-semibold">Status</div>
                        <div>
                          <Status status={assessment.assessment.status} />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {/* Statistic */}
      <div className="mt-1 flex flex-col md:flex-row space-y-1 md:space-y-0 md:space-x-3">
        {/* Company */}
        <div className="w-full md:w-1/4 p-1 border border-dashed border-blue-600 rounded">
          <div className="bg-blue-600 text-white h-20 rounded">
            <div className="flex flex-row p-2 justify-between">
              <div className="flex justify-end">
                <BuildingOffice2Icon className="w-14" />
              </div>
              <div className="flex flex-col items-end pr-2">
                <div className="text-3xl">{statistic.company}</div>
                <div>Company</div>
              </div>
            </div>
          </div>
        </div>
        {/* Subtest */}
        <div className="w-full md:w-1/4 p-1 border border-dashed border-green-600 rounded">
          <div className="bg-green-600 text-white h-20 rounded">
            <div className="flex flex-row p-2 justify-between">
              <div className="flex justify-end">
                <QuestionMarkCircleIcon className="w-14" />
              </div>
              <div className="flex flex-col items-end pr-2">
                <div className="text-3xl">{statistic.cfit_subtest + statistic.ist_subtest}</div>
                <div>Subtest</div>
              </div>
            </div>
          </div>
        </div>
        {/* Assessment */}
        <div className="w-full md:w-1/4 p-1 border border-dashed border-red-600 rounded">
          <div className="bg-red-600 text-white h-20 rounded">
            <div className="flex flex-row p-2 justify-between">
              <div className="flex justify-end">
                <ClipboardDocumentCheckIcon className="w-14" />
              </div>
              <div className="flex flex-col items-end pr-2">
                <div className="text-3xl">{statistic.assessment}</div>
                <div>Assessment</div>
              </div>
            </div>
          </div>
        </div>
        {/* Assessor */}
        <div className="w-full md:w-1/4 p-1 border border-dashed border-yellow-600 rounded">
          <div className="bg-yellow-600 text-white h-20 rounded">
            <div className="flex flex-row p-2 justify-between">
              <div className="flex justify-end">
                <UserGroupIcon className="w-14" />
              </div>
              <div className="flex flex-col items-end pr-2">
                <div className="text-3xl">{statistic.assessor}</div>
                <div>Assessor</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Previous Assessment */}
      <div className="mt-1 p-1 md:p-4 border border-dashed border-gray-400 rounded">
        <h4 className="font-bold mb-3">Previous Assessment</h4>
        {/* Desktop */}
        <div className="hidden md:block w-full">
          {prevAssessment.length === 0 ? (
            <div className="font-thin">&mdash; No Upcoming Assessment Avaible</div>
          ) : (
            <table className="table-fixed w-full">
              <thead className="bg-gray-500 text-white">
                <tr className="border text-left">
                  <th className="px-2 border font-normal w-4/12">Title</th>
                  <th className="px-2 border font-normal">Schedule</th>
                  <th className="px-2 border font-normal">Company</th>
                  <th className="px-2 border font-normal">Total Participant</th>
                  <th className="px-2 border font-normal">Status</th>
                </tr>
              </thead>
              <tbody>
                {prevAssessment.map((assessment, i) => (
                  <tr key={`prev_assessment_${i}`} className="border text-sm">
                    <td className="px-2 border">
                      <Link to={`assessment/${assessment.assessment.id}`} className="text-blue-500 hover:text-blue-800">
                        {assessment.assessment.assessment_title}
                      </Link>
                    </td>
                    <td className="px-2 border">
                      <div className="flex flex-row items-center space-x-1">
                        <div>{`${new Date(assessment.assessment.date).getDate().toString().padStart(2, "0")} ${monthNames[new Date(assessment.assessment.date).getMonth()]
                          } '${new Date(assessment.assessment.date).getFullYear() - 2000}`}</div>
                        <span>{assessment.assessment.start_time.slice(0, -3)}</span>
                      </div>
                    </td>
                    <td className="px-2 border">{assessment.company.company_name}</td>
                    <td className="px-2 border text-center">{assessment.total_participant}</td>
                    <td className="border text-xs">
                      <Status status={assessment.assessment.status} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        {/* Mobile */}
        <div className="block md:hidden w-full">
          {prevAssessment.length === 0 ? (
            <div className="font-thin">&mdash; No Previous Assessment Avaible</div>
          ) : (
            <table className="table-fixed w-full">
              <tbody>
                {prevAssessment.map((assessment, i) => (
                  <tr key={`prev_assessment_${i}`} className="border text-sm">
                    <td className="p-2">
                      <div className="flex items-center">
                        <div className="font-semibold">
                          <Link to={`assessment/${assessment.assessment.id}`} className="text-blue-500 hover:text-blue-800">{assessment.assessment.assessment_title}</Link>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="w-20 font-semibold">Schedule</div>
                        <div className="flex flex-row items-center space-x-1">
                          <div>
                            {`
                              ${new Date(assessment.assessment.date).getDate().toString().padStart(2, "0")} 
                              ${monthNames[new Date(assessment.assessment.date).getMonth()]} 
                              '${new Date(assessment.assessment.date).getFullYear() - 2000}
                            `}
                          </div>
                          <span>{assessment.assessment.start_time.slice(0, -3)}</span>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="w-20 font-semibold">Company</div>
                        <div>{assessment.company.company_name}</div>
                      </div>
                      <div className="flex items-center">
                        <div className="w-20 font-semibold">Participant</div>
                        <div>{assessment.total_participant}</div>
                      </div>
                      <div className="flex items-center">
                        <div className="w-20 font-semibold">Status</div>
                        <div>
                          <Status status={assessment.assessment.status} />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div >
  );
}

export default Dashboard;
