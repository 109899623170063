import { useCallback, useContext, useEffect, useState } from "react";

// Icons
import { CheckCircleIcon, ChevronRightIcon, EnvelopeIcon, XMarkIcon } from "@heroicons/react/24/outline";

// Context
import SnackbarContext from "../../../context/assessment/SnackbarContext";
import LoaderContext from "../../../context/assessment/LoaderContext";

// Helper
import errorHandler from "../../../helper/assessment/errorHandler";

// Controller
import Assessment from "../../../controller/assessment/assessment_system/assessment";

function FormParticipantIntray(props: {
  selectedAssessmentParticipantSession: number;
  setSelectedAssessmentParticipantSession: React.Dispatch<React.SetStateAction<number | undefined>>;
  assessmentParticipantSessionList: TAssessmentParticipantSession[];
}) {
  // Context
  const { message, setMessage } = useContext(LoaderContext);
  const { setNotif } = useContext(SnackbarContext);
  // state
  const [selectedParticipant, setSelectedParticipant] = useState<TAssessmentParticipantSession>();
  const [intrayEmailList, setIntrayEmailList] = useState<TPQuestionaryIntrayEmail[]>([]);
  const [intrayEmailReplyList, setIntrayEmailReplyList] = useState<TPQuestionaryReplyEmail[]>([]);
  const [intrayNewEmailList, setIntrayNewEmailList] = useState<TPQuestionaryNewEmail[]>([]);
  const [selectedEmail, setSelectedEmail] = useState<TPQuestionaryIntrayEmail>();
  const [selectedNewEmail, setSelectedNewEmail] = useState<TPQuestionaryNewEmail>();
  const [openSentReply, setOpenSentReply] = useState<boolean>(false);
  const [openNewEmail, setOpenNewEmail] = useState<boolean>(false);
  // desctruct props
  const selectedAssessmentParticipantSession = props.selectedAssessmentParticipantSession;
  const assessmentParticipantSessionList = props.assessmentParticipantSessionList;

  // find participant detail
  useEffect(() => {
    const findParticipantSession = assessmentParticipantSessionList.find(
      (p) => p.id === selectedAssessmentParticipantSession
    );
    setSelectedParticipant(findParticipantSession);
  }, [selectedAssessmentParticipantSession, assessmentParticipantSessionList]);

  const getDoc = useCallback(async () => {
    try {
      if (selectedParticipant !== undefined) {
        setMessage("Fetch Assessment Session");
        // get questionary_id by assessment_session_id
        const ac = new Assessment();
        const resGetAssessmentSession = await ac.getSession(selectedParticipant.assessment_session_id.toString());
        const questionary_id: number = resGetAssessmentSession.data.row.questionary_id;
        // get all email by questionary_id
        const resListIntrayReply = await ac.listIntrayEmail(questionary_id.toString());
        setIntrayEmailList(resListIntrayReply.data.list);
        // get all reply email by assessment_participant_session_id
        if (selectedParticipant.id !== undefined) {
          const resListIntrayReply = await ac.listIntrayReply(selectedParticipant.id?.toString());
          // get reply
          let replyList: TPQuestionaryReplyEmail[] = resListIntrayReply.data.list.filter(
            (email: TPQuestionaryReplyEmail) => {
              return email.intray_simulation_email_id !== null;
            }
          );
          setIntrayEmailReplyList(replyList);
          // get new email with source email
          let newEmailList: TPQuestionaryNewEmail[] = resListIntrayReply.data.list.filter(
            (email: TPQuestionaryNewEmail) => {
              return email.intray_simulation_email_id === null;
            }
          );
          setIntrayNewEmailList(newEmailList);
        }
      }
      setMessage("");
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  }, [selectedParticipant, setMessage, setNotif]);

  useEffect(() => {
    getDoc();
  }, [getDoc]);

  return (
    <div>
      <div className="fixed z-20 top-0 left-0 w-screen h-screen bg-black opacity-20"></div>
      <div className="fixed z-30 top-0 left-0 w-screen h-screen bg-transparent flex items-center justify-center">
        <div className="bg-white w-screen md:w-[96%] h-full md:h-[96%] md:rounded overflow-hidden">
          <div className="p-4 bg-blue-800 md:rounded-t text-white flex justify-between">
            <h3 className="flex">
              <EnvelopeIcon className="w-6 mr-2" />
              Participant Intray ({selectedParticipant?.full_name})
            </h3>
            <button type="button" onClick={() => props.setSelectedAssessmentParticipantSession(undefined)}>
              <XMarkIcon className="w-6" />
            </button>
          </div>
          <div className="w-full h-0.5">
            {message !== "" && (
              <div className="h-full bg-gradient-to-r bg-slate-200 from-cyan-300 to-blue-700 bg-20% bg-repeat-y bg-posloader animate-loader"></div>
            )}
          </div>
          <div className="h-[87vh] flex px-4 flex-col">
            &nbsp;
            {message !== "" && <div className="hidden md:block ml-auto text-gray-600 text-right">{message} . . .</div>}
            <div className="w-full h-full flex">
              <div className="w-3/12 border overflow-auto">
                <div className="px-4 py-2 border-b flex space-x-2 bg-slate-300">
                  <EnvelopeIcon className="w-5" />
                  <span className="font-bold">Inbox</span>
                </div>
                {/* List Email (New Email / Forward) */}
                <div
                  className="px-4 py-2 border-b flex bg-red-300 hover:cursor-pointer hover:bg-red-400"
                  onClick={() => setOpenNewEmail(!openNewEmail)}
                >
                  <span className="font-bold">New Email / Forward</span>
                  {intrayNewEmailList.length > 0 && (
                    <span className="ml-auto px-2 bg-blue-500 font-bold text-white rounded text-sm flex items-center">
                      {intrayNewEmailList.length}
                    </span>
                  )}
                </div>
                {openNewEmail && (
                  <>
                    {intrayNewEmailList.map((email, i) => (
                      <div
                        key={`email_${i}`}
                        className={`px-4 py-2 max-h-20 overflow-hidden border-b hover:border-l-2 hover:border-l-blue-500 hover:bg-slate-200 hover:text-gray-600 cursor-pointer ${email.id === selectedNewEmail?.id
                          ? "bg-blue-900 text-white border-l-2 border-l-blue-500"
                          : "text-gray-600"
                          }`}
                        onClick={() => {
                          setSelectedEmail(undefined);
                          setSelectedNewEmail(email);
                        }}
                      >
                        <div className="text-sm flex">
                          <ChevronRightIcon className="w-4" />
                          {email.subject}
                        </div>
                        <div className="font-light text-sm">
                          {email.content.replace(/<(?:.|\n)*?>/gm, "").substring(0, 30)}...
                        </div>
                      </div>
                    ))}
                  </>
                )}

                {/* List Email (Inbox Sent - Reply) */}
                <div
                  className="px-4 py-2 border-b flex bg-blue-300 hover:cursor-pointer hover:bg-blue-400"
                  onClick={() => setOpenSentReply(!openSentReply)}
                >
                  <span className="font-bold">Sent - Reply</span>
                  {intrayEmailList.length > 0 && (
                    <span className="ml-auto px-2 bg-blue-500 font-bold text-white rounded text-sm flex items-center">
                      {intrayEmailList.length}
                    </span>
                  )}
                </div>
                {openSentReply && (
                  <>
                    {intrayEmailList.map((email, i) => (
                      <div
                        key={`email_${i}`}
                        className={`px-4 py-2 max-h-20 overflow-hidden border-b hover:border-l-2 hover:border-l-blue-500 hover:bg-slate-200 hover:text-gray-600 cursor-pointer ${email.id === selectedEmail?.id
                          ? "bg-blue-900 text-white border-l-2 border-l-blue-500"
                          : "text-gray-600"
                          }`}
                        onClick={() => {
                          setSelectedEmail(email);
                          setSelectedNewEmail(undefined);
                        }}
                      >
                        <div className="font-semibold text-sm flex space-x-1">
                          {intrayEmailReplyList.filter(
                            (replyAll) => replyAll.intray_simulation_email_id === email.intray_simulation_email_id
                          ).length > 0 && <CheckCircleIcon className="w-5 text-green-400" />}
                          <span>{email.from_name}</span>
                        </div>
                        <div className="text-sm flex">
                          <ChevronRightIcon className="w-4" />
                          {email.subject}
                        </div>
                        <div className="font-light text-sm">
                          {email.content.replace(/<(?:.|\n)*?>/gm, "").substring(0, 30)}...
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
              <div className="w-9/12 overflow-auto">
                {/* Selected Email - Compose */}
                {selectedNewEmail && (
                  <div className="h-full flex flex-col">
                    <div className="flex items-center px-4 py-2 font-semibold sticky top-0 bg-white z-10 border">
                      {selectedNewEmail.subject}
                    </div>
                    <div className="border p-4">
                      <p className="mb-0">{selectedNewEmail.sendto}</p>
                      <p className="mb-0 text-sm">Date : {selectedNewEmail.date}</p>
                      <p className="mb-0 text-sm">CC : {selectedNewEmail.copyto}</p>
                      <div
                        className="py-4 text-sm"
                        dangerouslySetInnerHTML={{ __html: selectedNewEmail.content }}
                      ></div>
                    </div>
                  </div>
                )}
                {/* Open Email (Inbox Sent - Reply) */}
                {selectedEmail && (
                  <div className="h-full flex flex-col">
                    <div className="flex items-center px-4 py-2 font-semibold sticky top-0 bg-white z-10 border">
                      {selectedEmail.subject}
                    </div>
                    {/* Open if reply avaible */}
                    {selectedEmail && intrayEmailReplyList.length > 0 && (
                      <div className="w-full">
                        {intrayEmailReplyList
                          .filter(
                            (replyUnfilter) =>
                              replyUnfilter.intray_simulation_email_id === selectedEmail.intray_simulation_email_id
                          )
                          .map((reply, iReply) => (
                            <div key={`reply_${iReply}`} className="border p-4">
                              <div className="flex">
                                <CheckCircleIcon className="w-5 text-green-500" />
                                <span className="pl-2">{reply.subject}</span>
                              </div>
                              <p className="mb-0 text-sm">Date : {reply.date}</p>
                              <p className="mb-0 text-sm">To : {reply.sendto}</p>
                              <p className="mb-0 text-sm">Cc : {reply.copyto}</p>
                              <div className="py-4 text-sm" dangerouslySetInnerHTML={{ __html: reply.content }}></div>
                            </div>
                          ))}
                      </div>
                    )}
                    {/* Open Email Selected */}
                    <div className="border p-4">
                      <p className="mb-0">{selectedEmail.from_name}</p>
                      <p className="mb-0 text-sm">Date : {selectedEmail.date}</p>
                      {selectedEmail.copyto.length > 0 && (
                        <p className="mb-0 text-sm">
                          Cc :{" "}
                          {selectedEmail.copyto.map((cc, cci) => (
                            <span key={`cc_${cci}`}>{`${cc.name}<${cc.email}>`}</span>
                          ))}
                        </p>
                      )}
                      <div className="py-4 text-sm" dangerouslySetInnerHTML={{ __html: selectedEmail.content }}></div>
                    </div>
                  </div>
                )}
                {selectedEmail === undefined && (
                  <div className="h-full flex justify-center items-center font-bold text-gray-400">
                    <div className="h-40 w-40 flex flex-col justify-center items-center border border-blue-200 bg-blue-50 rounded-full">
                      <EnvelopeIcon className="w-10 text-blue-300" />
                      No Open Email
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormParticipantIntray;
