import { useCallback, useContext, useEffect, useState } from "react";

import { XMarkIcon } from "@heroicons/react/24/outline";

// Context
import LoaderContext from "../../../context/assessment/LoaderContext";
import SnackbarContext from "../../../context/assessment/SnackbarContext";

// Helper
import errorHandler from "../../../helper/assessment/errorHandler";

// Controller
import Assessment from "../../../controller/assessment/assessment_system/assessment";

function FormParticipantSession(props: {
  doc: TAssessment;
  assessorOptions: TSelect[];
  setIsOpenParticipantSession: (close: boolean) => void;
}) {
  const { message, setMessage } = useContext(LoaderContext);
  const { setNotif } = useContext(SnackbarContext);
  // status
  const isAllowChange = props.doc.status === "0" || props.doc.status === "1";
  // state
  const [sessionIdPic, setSessionIdPic] = useState<number | undefined>(undefined);
  const [assessmentSessionList, setAssessmentSessionList] = useState<TAssessmentSession[]>([]);
  const [assessmentParticipantList, setAssessmentParticipantList] = useState<TAssessmentParticipant[]>([]);
  // participant session data
  const [participantSessionData, setParticipantSessionData] = useState<TAssessmentParticipantSession[]>([]);
  // helper
  let index = -1;

  const getDoc = useCallback(
    async function getDoc() {
      try {
        // fetch session list
        const ac = new Assessment();

        setMessage("Fetch Assessment Session");
        const resSessionList = await ac.listSession(props.doc.id.toString());
        // sort by level index in assessment level category
        resSessionList.data.list.sort((a: TAssessmentSession, b: TAssessmentSession) => a?.level_index - b?.level_index);
        setAssessmentSessionList(resSessionList.data.list);
        const sessionList: TAssessmentSession[] = resSessionList.data.list;
        // participant pic is selected from session category = "Problem Analysis"
        setSessionIdPic(sessionList.find(v => v.category === "Problem Analysis")?.id);

        setMessage("Fetch Assessment Participant");
        const resParticipantList = await ac.listParticipant(props.doc.id.toString());
        setAssessmentParticipantList(resParticipantList.data.list);
        const assessmentParticipantList: TAssessmentParticipant[] = resParticipantList.data.list;

        setMessage("Fetch Assessment Participant Session");
        const resParticipantSessionList = await ac.listParticipantSession(props.doc.id.toString());
        const participantSessionList: TAssessmentParticipantSession[] = resParticipantSessionList.data.list;

        /**
         * initialize empty session
         * use this array as base so we don't messed-up with the index
         */
        const newParticipantSessionDataMap = assessmentParticipantList.map((apl, i) => {
          return sessionList.map((sl, sl_index) => {
            return {
              assessment_id: props.doc.id,
              assessment_participant_id: apl.id,
              assessment_session_id: sl.id,
              assessor_id: 0,
            };
          });
        });
        // parse to single array
        let newParticipantSessionData: any[] = [];
        newParticipantSessionDataMap.forEach((v: any) => {
          newParticipantSessionData = [...newParticipantSessionData, ...v];
        });

        // if participant session is not empty map to participant session data
        if (participantSessionList.length > 0) {
          newParticipantSessionData = newParticipantSessionData.map((nps: TAssessmentParticipantSession) => {
            // find in the participant session list
            const findParticipantSession = participantSessionList.find(
              (ps) =>
                ps.assessment_id === nps.assessment_id &&
                ps.assessment_participant_id === nps.assessment_participant_id &&
                ps.assessment_session_id === nps.assessment_session_id
            );
            const result = findParticipantSession ? { ...nps, ...findParticipantSession } : nps;
            return result;
          });
        }

        setParticipantSessionData(newParticipantSessionData);

        setMessage("");
      } catch (error) {
        setMessage("");
        const errorMessage = errorHandler(error);
        setNotif({ type: "error", message: errorMessage });
      }
    },
    [setNotif, setMessage, props.doc.id]
  );

  useEffect(() => {
    getDoc();
  }, [getDoc]);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>, index: number) => {
    let value = isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value);
    const copyParticipantSessionData = [...participantSessionData];
    const updateScore: TAssessmentParticipantSession = {
      ...participantSessionData[index],
      assessor_id: value,
    };
    copyParticipantSessionData.splice(index, 1, updateScore);
    setParticipantSessionData(copyParticipantSessionData);
  };

  const handleBlur = async (index: number) => {
    try {
      const ac = new Assessment();
      setMessage("Save Assessment Participant Session");
      const dataSubmit: TAssessmentParticipantSession = {
        ...participantSessionData[index],
      };
      // update participant id (pic_id)
      if (dataSubmit.assessment_session_id === sessionIdPic) {
        const updateParticipant: TAssessmentParticipant = {
          pic_id: dataSubmit.assessor_id,
        };
        await ac.updateParticipant(dataSubmit.assessment_participant_id.toString(), updateParticipant);
      }
      if (dataSubmit.id) {
        // update participant session
        await ac.updateParticipantSession(dataSubmit);
      } else {
        // create participant session
        const resCrateParticipantSession = await ac.addParticipantSession(dataSubmit);
        const copyParticipantSessionData = [...participantSessionData];
        const updateParticipantSession: TAssessmentParticipantSession = {
          ...participantSessionData[index],
          id: resCrateParticipantSession.data.saved_id,
        };
        copyParticipantSessionData.splice(index, 1, updateParticipantSession);
        setParticipantSessionData(copyParticipantSessionData);
      }
      setMessage("");
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  return (
    <div>
      <div className="fixed z-20 top-0 left-0 w-screen h-screen bg-black opacity-20"></div>
      <div className="fixed z-30 top-0 left-0 w-screen h-screen bg-transparent flex items-center justify-center">
        <div className="bg-white w-screen md:w-[96%] h-full md:h-[96%] md:rounded overflow-hidden">
          <div className="p-4 bg-cyan-800 rounded-t text-white flex justify-between">
            <h3>Participant Session</h3>
            <button type="button" onClick={() => props.setIsOpenParticipantSession(false)}>
              <XMarkIcon className="w-6" />
            </button>
          </div>
          <div className="w-full h-0.5">
            {message !== "" && (
              <div className="h-full bg-gradient-to-r bg-slate-200 from-cyan-300 to-blue-700 bg-20% bg-repeat-y bg-posloader animate-loader"></div>
            )}
          </div>
          <div className="flex px-4">
            &nbsp;
            {message !== "" && <div className="hidden md:block ml-auto text-gray-600 text-right">{message} . . .</div>}
          </div>
          <div className="w-screen md:w-full h-[91%] overflow-auto pb-10">
            <div className="min-w-[60rem] flex pt-4">
              <div className="w-full">
                <div className="sticky top-0 bg-white z-10">
                  <div className="w-full flex px-4">
                    <div className="w-full md:w-3/12 px-3 py-2 border bg-slate-600 text-white text-center">
                      Participant
                    </div>
                    {assessmentSessionList.map((v, i) => (
                      <div
                        key={`assessment_session_${i}`}
                        className="w-full md:w-3/12 px-3 py-2 border bg-slate-500 text-white text-center"
                      >
                        {v.category ?? "In-Tray Simulation"}
                      </div>
                    ))}
                  </div>
                </div>
                {assessmentParticipantList.map((participant, i) => (
                  <div key={`assessment_participant_${i}`} className="flex px-4">
                    <div className="w-full md:w-3/12 px-3 py-2 border bg-slate-600 text-white text-center">
                      {participant.full_name}
                    </div>
                    {assessmentSessionList.map((session, session_index) => {
                      index++;
                      const set_index = index;
                      return (
                        <div
                          key={`assessment_participant_session_${set_index}`}
                          className="w-full md:w-3/12 relative after:content-['↓'] after:absolute after:right-4 after:top-3 after:pointer-events-none"
                        >
                          <select
                            className={
                              ((participantSessionData?.[index]?.assessor_id ?? 0) === 0
                                ? "border-red-500"
                                : "border-gray-200 focus:border-gray-500") +
                              " appearance-none first-letter:appearance-none block w-full bg-white-200 text-gray-700 border py-2 px-3 focus:outline-none disabled:bg-gray-200 disabled:text-gray-800"
                            }
                            disabled={!isAllowChange}
                            value={participantSessionData?.[index]?.assessor_id ?? 0}
                            onChange={(e) => handleChange(e, set_index)}
                            onBlur={() => handleBlur(set_index)}
                          >
                            <option value="0" disabled>
                              Please Select
                            </option>
                            {props.assessorOptions.map((v, i) => (
                              <option key={i} value={v.id}>
                                {v.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormParticipantSession;
