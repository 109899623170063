import { useContext, useEffect, useState } from "react";

import AssessmentContext from "../../../context/assessment/AssessmentContext";

function Header(props: { activeSession: TPActiveSession | undefined }) {
  // Context
  const { assessment, participant, timeRemaining, setTimeRemaining } = useContext(AssessmentContext);

  // State
  const [hour, setHour] = useState<number>(0);
  const [minute, setMinute] = useState<number>(0);
  const [second, setSecond] = useState<number>(0);

  useEffect(() => {
    if (timeRemaining !== null && timeRemaining > 0) {
      const timer = window.setInterval(() => {
        setTimeRemaining(timeRemaining - 1);
      }, 1000);
      return () => {
        window.clearInterval(timer);
      };
    }
  }, [timeRemaining, setTimeRemaining]);

  useEffect(() => {
    if (timeRemaining !== null && timeRemaining > -1) {
      const time = Math.round(timeRemaining);
      const rHour = Math.floor(time / (60 * 60));
      const rMinute = Math.floor(time / 60) - rHour * 60;
      // calc remaining hour
      setHour(rHour);
      // calc remaining minute
      setMinute(rMinute);
      // calc remaining second
      setSecond(Math.round(time % 60 !== 0 ? time % 60 : 0));
    }
  }, [timeRemaining]);

  return (
    <div className="w-full flex flex-col md:flex-row p-2 border-b sticky top-0 z-10 bg-white">
      <div className="mb-2 md:mb-0 flex items-center">
        <img
          src={`${process.env.REACT_APP_URL}/upload/assessment_participant/get/${assessment.company_logo}`}
          className="h-8"
          alt="company logo"
        />
        <div className="pl-2 font-semibold">
          <h5 className="text-xs md:text-base">
            {assessment.company_name} - {participant.full_name}
          </h5>
          <h6 className="text-xs">{assessment.title} Assessment</h6>
        </div>
      </div>
      {timeRemaining !== null && timeRemaining > 0 && props.activeSession !== undefined && (
        <div className="w-full md:w-auto ml-auto flex space-x-1">
          <div
            className={`grow flex flex-col items-center text-white px-4 rounded-l ${hour === 0 ? "bg-slate-500" : "bg-blue-500"
              }`}
          >
            <span className="font-bold">{hour}</span>
            <span className="text-xs">Hours</span>
          </div>
          <div
            className={`grow flex flex-col items-center text-white px-4 ${hour === 0 && minute === 0 ? "bg-slate-500" : hour === 0 && minute < 3 ? "bg-red-500" : "bg-blue-500"
              }`}
          >
            <span className="font-bold">{minute}</span>
            <span className="text-xs">Minutes</span>
          </div>
          <div
            className={`grow flex flex-col items-center text-white px-4 rounded-r ${hour === 0 && minute === 0 && second === 0
              ? "bg-slate-500"
              : hour === 0 && minute < 3
                ? "bg-red-500"
                : "bg-blue-500"
              }`}
          >
            <span className="font-bold">{second}</span>
            <span className="text-xs">Seconds</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
