import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// Contexts
import ASAuthContext from "./context/assessment/AuthContext";
import APAssessmentContext from "./context/assessment/AssessmentContext";
import PSAuthContext from "./context/psikotest/AuthContext";
import PPAssessmentContext from "./context/psikotest/PPAssessmentContext";

// Routes
import AppRoutes from "./AppRoutes";

// Components
import Loading from "./pages/Loading";

// helper function
function toTitleCase(str: string) {
  return str.replace(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

function App() {
  const location = useLocation();
  // context
  const { validate: as_validate } = useContext(ASAuthContext);
  const { validateAssessmentParticipant: AS_validateAssessmentParticipant } = useContext(APAssessmentContext);
  const { validate: ps_validate } = useContext(PSAuthContext);
  const { validateAssessmentParticipant: PS_validateAssessmentParticipant } = useContext(PPAssessmentContext);
  // state
  const [hasCheckingSession, setHasCheckingSession] = useState<Boolean>(false);
  const [isLoading, setIsLoading] = useState<Boolean>(true);
  // check subpath web
  const firstPath = location.pathname.split("/").filter((v) => v !== "")?.[0] ?? "";
  // check subpath web secondPath
  const secondPath = location.pathname.split("/").filter((v) => v !== "")?.[1] ?? null;

  // check if firstPath not empty but not included in one of system route
  const systemPath = ["assessment_system", "assessment_participant", "psikotest_system", "psikotest_participant"];
  if (firstPath !== "" && !systemPath.includes(firstPath)) {
    //console.log(document.location)
    document.location = document.location.origin
  }

  useEffect(() => {
    // change title
    document.title = toTitleCase(firstPath.replaceAll("_", " ")) + (secondPath ? " - " + toTitleCase(secondPath.replaceAll("_", " ")) : "")

    const getQueryVariable = (variable: string) => {
      // remove "?" on front of query
      const query = location.search.substring(1);
      const vars = query.split("&");
      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split("=");
        if (pair[0] === variable) {
          return pair[1];
        }
      }
      return "";
    };

    async function checkSystemSession() {
      try {
        if (firstPath === "assessment_system") {
          await as_validate();
        } else if (firstPath === "psikotest_system") {
          await ps_validate();
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    }

    async function checkParticipantSession() {
      try {
        // check query access key
        let accessKey = getQueryVariable("k");
        if (accessKey === "") {
          accessKey = sessionStorage.getItem("accessKey") ?? "";
        }
        if (firstPath === "assessment_participant") {
          AS_validateAssessmentParticipant(accessKey);
        } else if (firstPath === "psikotest_participant") {
          PS_validateAssessmentParticipant(accessKey);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    }

    // only check when flag has been checked is false and firstPath is psikotest_system
    if (hasCheckingSession === false && (firstPath === "assessment_system" || firstPath === "psikotest_system")) {
      // check session
      checkSystemSession();
      // set false so we only need check session once
      setHasCheckingSession(true);
    }

    // only check when flag has been checked is false and firstPath is psikotest_participant
    if (hasCheckingSession === false && (firstPath === "assessment_participant" || firstPath === "psikotest_participant")) {
      // check psikotest_participant session
      checkParticipantSession();
      // set false so we only need check session once
      setHasCheckingSession(true);
    }
  }, [
    hasCheckingSession, firstPath, secondPath,
    as_validate, AS_validateAssessmentParticipant,
    ps_validate, PS_validateAssessmentParticipant,
    location.search
  ]);

  return <>
    {
      (firstPath !== "") && isLoading ?
        <Loading /> :
        <AppRoutes />
    }
  </>;
}

export default App;
