import { useCallback, useContext, useEffect, useState } from "react";

// Context
import SnackbarContext from "../../../context/psikotest/SnackbarContext";

// Handler
import errorHandler from "../../../helper/psikotest/errorHandler";

// Controller
import ParticipantSession from "../../../controller/psikotest/psikotest_participant/session";


function SubtestCfit(props: { assessment_participant_session: TPPAssessmentParticipantSession, closeSession: () => void }) {
    // Context
    const { setNotif } = useContext(SnackbarContext);

    // state
    const [selected_questionary, setSelectedQuestionary] = useState<TPPAssessmentSessionCfitQuestionary | undefined>(undefined)
    const [cfit_questionary, setCfitQuestionary] = useState<TPPAssessmentSessionCfitQuestionary[]>([])

    const getDoc = useCallback(async () => {
        try {
            // get all question in subtest id
            const psc = new ParticipantSession();
            let res = await psc.getQuestionaryCfit(props.assessment_participant_session.id);
            // if return empty array
            if (res.data.list.length === 0) {
                // generate questionary
                await psc.createQuestionaryCfit(props.assessment_participant_session.id);
            }
            // re-fetch questionary data
            res = await psc.getQuestionaryCfit(props.assessment_participant_session.id);
            setCfitQuestionary(res.data.list)
            if (res.data.list.length > 0) {
                setSelectedQuestionary(res.data.list[0])
            }
        } catch (error) {
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }, [setNotif, props.assessment_participant_session.id]);

    useEffect(() => {
        getDoc();
    }, [getDoc]);

    // track cfit_questionary
    useEffect(() => {
        console.log(cfit_questionary)
    }, [cfit_questionary]);

    // handleAnswer
    const handleAnswer = async (selected_questionary: TPPAssessmentSessionCfitQuestionary, v: string) => {
        try {
            // check answer
            // answer to array
            let answer_arr = selected_questionary?.answer ? selected_questionary?.answer.split(",") : []
            answer_arr.push(v)
            // filter if answer clicked twice
            let unique_answer = [...new Set(answer_arr)]
            // limit answer by minimum of selected answer
            // if more than minimum of selected answer remove first answer
            if (unique_answer.length > selected_questionary.cfit.minimum_selected_answer) {
                unique_answer.shift();
            }
            selected_questionary.answer = unique_answer.join(",")
            // save answer
            const psc = new ParticipantSession();
            await psc.answerQuestionaryCfit(selected_questionary)
            // save new answer to array cfit_questionary
            const index_selected_questionary = cfit_questionary.findIndex((v) => v.id === selected_questionary.id)
            // copy cfit_questionary
            const copy_cfit_questionary = [...cfit_questionary]
            copy_cfit_questionary.splice(index_selected_questionary, 1, selected_questionary)
            // update cfit_questionary
            setCfitQuestionary(copy_cfit_questionary)
        } catch (error) {
            const errorMessage = errorHandler(error);
            setNotif({ type: "error", message: errorMessage });
        }
    }

    return (
        <div className="h-[94%] flex flex-col overflow-hidden">
            <div className="text-center border-b py-3">{selected_questionary?.cfit.mdi}</div>
            <div className="flex flex-grow flex-col">
                <div className="w-full flex-1">
                    {selected_questionary === undefined
                        ? <div>
                            Please Wait, Loading Questionary
                        </div>
                        : <div className="flex flex-grow justify-center items-center h-full">
                            <img className="w-11/12 md:w-6/12" src={`/upload/psikotest_participant/get_cfit/${selected_questionary.cfit_questionary.question_image}`} alt={`${selected_questionary.cfit_questionary.id}`} />
                        </div>
                    }
                </div>
                <div className="w-full border-t py-10">
                    {selected_questionary === undefined
                        ? <div>
                            Please Wait, Loading Questionary
                        </div>
                        : <div className="flex flex-col flex-grow justify-center h-full">
                            {selected_questionary.answer?.split(",").length !== selected_questionary.cfit.minimum_selected_answer &&
                                <div className={`text-center font-bold text-red-600 pb-3`}>
                                    Pilih {selected_questionary.cfit.minimum_selected_answer} Jawaban
                                </div>
                            }
                            <div className="flex justify-center space-x-3">
                                {selected_questionary.cfit_questionary.answer_list.split(",").map((v, i) =>
                                    <div
                                        key={`cfit_answer_${i}`}
                                        className={`
                                            px-4 md:px-12 rounded border border-dashed text-center cursor-pointer border-gray-400  hover:bg-blue-500 hover:text-white
                                            ${selected_questionary.answer?.split(",").includes(v) ? "bg-green-200" : "bg-gray-100"}
                                        `}
                                        onClick={() => handleAnswer(selected_questionary, v)}
                                    >
                                        {v}
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="pt-3 mb-8 ms:pt-4 md:mb-3 border-t flex justify-center">
                <div className="mx-3 md:mx-10 flex flex-wrap items-center justify-center">
                    {/** Questionary Button */}
                    {cfit_questionary.map((v, i) =>
                        <div
                            key={`cfit_questionary_${i}`}
                            className={
                                `py-1 px-2 mr-1 mb-1 min-w-[40px] md:py-1 md:px-6 md:mr-2 md:mb-2 md:min-w-[80px] text-center cursor-pointer rounded text-white
                                ${selected_questionary?.id === v.id
                                    ? "bg-blue-500 hover:bg-blue-600"
                                    : (v?.answer?.split(",") ?? []).length !== selected_questionary?.cfit.minimum_selected_answer
                                        ? (v?.answer?.split(",") ?? []).length !== 0
                                            ? "bg-red-500 hover:bg-red-600" :
                                            "bg-slate-500 hover:bg-slate-600"
                                        : "bg-green-500 hover:bg-green-600"}`
                            }
                            onClick={() => setSelectedQuestionary(v)}
                        >
                            {i + 1}
                        </div>
                    )}
                    {/**
                     * if all questionary selected
                     * cfit_questionary.filter(v => v.answer !== null && (v?.answer?.split(",") ?? []).length === v.cfit.minimum_selected_answer).length === cfit_questionary.length
                     */}
                    {(cfit_questionary.filter(v => v.answer !== null && (v?.answer?.split(",") ?? []).length === v.cfit.minimum_selected_answer).length === cfit_questionary.length || selected_questionary?.id === cfit_questionary[cfit_questionary.length - 1]?.id) &&
                        <div
                            className={`py-1 px-2 mr-1 mb-1 min-w-[40px] md:py-1 md:px-6 md:mr-2 md:mb-2 md:min-w-[80px] text-center cursor-pointer rounded text-white bg-red-500 hover:bg-red-600}`}
                            onClick={() => props.closeSession()}>
                            Finish
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default SubtestCfit;
