import React, { useState } from "react";

import AssessmentContext from "./AssessmentContext";

import AssessmentParticipant from "../../controller/assessment/assessment_participant";

import errorHandler from "../../helper/assessment/errorHandler";

function AssessmentProvider({ children }: { children: React.ReactNode }) {
  const [errorValidate, setErrorValidate] = useState<string>("");
  const [assessment, setAssessment] = useState<TPAssessmentDetail>({
    id: 0,
    assessment_id: 0,
    company_id: 0,
    title: "",
    date: "",
    start_time: "",
    link_zoom: "",
    wa_number: "",
    company_logo: "",
    company_name: "",
  });
  const [participant, setParticipant] = useState<TPAssessmentParticipantDetail>({
    id: 0,
    assessment_id: 0,
    access_key: "",
    pic_id: 0,
    participant_id: 0,
    full_name: "",
    email: "",
    date_of_birth: null,
    gender: null,
    city: "",
    last_education: "",
    major: "",
    photo: "",
    manual: null,
    pic_full_name: "",
  });
  const [timeRemaining, setTimeRemaining] = useState<number | null>(null);

  const validateAssessmentParticipant = async (accessKey: string) => {
    try {
      if (accessKey === "") {
        throw new Error("access key not found");
      }
      // Get assessment detail
      const apc = new AssessmentParticipant();
      const assessmentDetail = await apc.getAssessment(accessKey);
      setAssessment(assessmentDetail.data.row);
      // Get participant detail
      const participantDetail = await apc.checkKey(accessKey);
      setParticipant(participantDetail.data.row);
      sessionStorage.setItem("accessKey", accessKey);
      setErrorValidate("");
    } catch (error) {
      const errMessage = errorHandler(error);
      setErrorValidate(errMessage);
    }
  };

  const updateAssessmentParticipant = async (
    assessment: TPAssessmentDetail,
    participant: TPAssessmentParticipantDetail
  ) => {
    setAssessment(assessment);
    setParticipant(participant);
  };

  const value = {
    errorValidate,
    assessment,
    participant,
    timeRemaining,
    setTimeRemaining,
    validateAssessmentParticipant,
    updateAssessmentParticipant,
  };

  return <AssessmentContext.Provider value={value}>{children}</AssessmentContext.Provider>;
}

export default AssessmentProvider;
