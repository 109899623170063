import req from "../../../services/assessment/request";

class IndexPage {
  async dashboard() {
    try {
      const res = await req.get("/api/assessment_system/dashboard");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async user_profile() {
    try {
      const res = await req.get("/api/assessment_system/me");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async update_password(data: TUpdatePassword) {
    try {
      const res = await req.put("/api/assessment_system/change_password", data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async get_setting() {
    try {
      const res = await req.get("/api/assessment_system/get_setting");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async update_setting(data: TSetting) {
    try {
      const res = await req.post("/api/assessment_system/update_setting", data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default IndexPage;
