import req from "../../../services/psikotest/request";

class CfitFormulaPersentil {
  async create(data: TPSCfitFormulaPersentil) {
    try {
      const res = await req.post("/api/psikotest_system/cfit_formula_persentil/", data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async update(data: TPSCfitFormulaPersentil) {
    try {
      const res = await req.put("/api/psikotest_system/cfit_formula_persentil/" + data.id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async delete(data: TPSCfitFormulaPersentil) {
    try {
      const res = await req.delete("/api/psikotest_system/cfit_formula_persentil/", { data });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default CfitFormulaPersentil;
