import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { Disclosure } from "@headlessui/react";

// Icons
import { HomeIcon, BuildingOffice2Icon, ClipboardDocumentListIcon, CheckBadgeIcon, BeakerIcon, ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon } from '@heroicons/react/20/solid'

// Context
import PSAuthContext from "../../../context/psikotest/AuthContext";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

function MenuDesktop() {
  const { user } = useContext(PSAuthContext);


  const navigation = [
    { name: 'Dashboard', href: '', icon: HomeIcon, current: true, show: true },
    { name: 'Assessment', href: 'assessment', icon: CheckBadgeIcon, current: false, show: true },
    { name: 'Company', href: 'company', icon: BuildingOffice2Icon, current: false, show: user.roles === "1" },
    { name: 'delimiter', href: '', icon: HomeIcon, current: false, show: true },
    {
      name: 'Questionary',
      icon: ClipboardDocumentListIcon,
      current: false,
      show: user.roles === "1",
      children: [
        { name: 'Itelligence' },
        { name: 'CFIT Subtest', href: 'cfit' },
        { name: 'IST Subtest', href: 'ist' },
        { name: 'Personality' },
        { name: 'MBTI Questionary', href: 'mbti_questionary' },
        { name: 'PAPI Questionary', href: 'papi_questionary' },
        { name: 'EPPS Questionary', href: 'epps_questionary' },
        { name: 'SRQ-20 Questionary', href: 'srq20_questionary' },
        { name: 'MMPI Questionary', href: 'mmpi_questionary' },
        { name: 'BRS Questionary', href: 'brs_questionary' },
        { name: 'Behavioural' },
        { name: 'RAC Questionary', href: 'rac_questionary' },
        { name: 'DISC Questionary', href: 'disc_questionary' },
        { name: 'Resilience Questionary', href: 'resilience_questionary' },
      ],
    },
    {
      name: 'Formula',
      icon: BeakerIcon,
      current: false,
      show: user.roles === "1",
      children: [
        { name: 'Itelligence' },
        { name: 'CFIT Formula', href: 'cfit_formula' },
        { name: 'IST Formula', href: 'ist_formula' },
        { name: 'Personality' },
        { name: 'EPPS Formula Norma', href: 'epps_formula_norma' },
        { name: 'BRS Formula', href: 'brs_formula' },
        { name: 'Behavioural' },
        { name: 'RAC Formula Norma', href: 'rac_formula_norma' },
      ],
    },
    { name: 'delimiter', href: '', icon: HomeIcon, current: false, show: true },
  ]

  return (
    <nav className="block">
      <ul className="space-y-0.5">
        <li className="flex flex-col">
          <div className="px-2 py-2 text-xs font-semibold">MENU</div>
        </li>
        {navigation.map((item, item_i) =>
          item.show ? (
            <li key={item_i}>
              {!item.children ? (
                <>
                  {item.name === "delimiter"
                    ? <div className="border-b border-gray-200 px-2 my-2"></div>
                    : <NavLink
                      to={item.href} end
                      className={({ isActive }) =>
                        `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                        } group flex items-center font-semibold w-full px-2 py-2 text-sm rounded hover:bg-blue-300 hover:text-white`
                      }
                      children={({ isActive }) => (
                        <div className={`group flex gap-x-3 rounded-md text-sm/6 font-semibold ${isActive ? "text-white" : "text-gray-700"} group-hover:text-white`}>
                          <item.icon aria-hidden="true" className={`size-6 w-6 shrink-0 ${isActive ? "text-gray-200" : "text-gray-400"} group-hover:text-white`} />
                          {item.name}
                        </div>
                      )}
                    />
                  }
                </>
              ) : (
                <Disclosure as="div" defaultOpen={false}>
                  <Disclosure.Button
                    className={classNames(
                      item.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                      'group flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm/6 font-semibold text-gray-700',
                    )}
                  >
                    {({ open }) => (
                      <>
                        <item.icon aria-hidden="true" className="size-6 w-6 shrink-0 text-gray-400" />
                        {item.name}
                        <ChevronRightIcon
                          aria-hidden="true"
                          className={`ml-auto size-5 w-5 shrink-0 text-gray-400 ${open ? "rotate-90 text-gray-500" : ""}`}
                        />
                      </>
                    )}
                  </Disclosure.Button>
                  <Disclosure.Panel as="ul" className="mt-1 px-2 space-y-1 rounded">
                    {item.children.map((subItem) => (
                      <li key={subItem.name} className="group">
                        {/* 44px */}
                        {subItem.href
                          ? <NavLink
                            to={subItem.href}
                            className={({ isActive }) =>
                              `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                              } flex items-center font-semibold w-full px-2 py-2 text-sm rounded group-hover:bg-blue-300 group-hover:text-white`
                            }
                            children={({ isActive }) => (
                              <div className={`group flex pl-2 gap-x-3 rounded-md text-sm/6 font-semibold ${isActive ? "text-white" : "text-gray-700"} group-hover:text-white`}>
                                {subItem.name}
                              </div>
                            )}
                          />
                          : <div className="px-2 mb-1 text-xs font-normal text-gray-600">{subItem.name}</div>
                        }
                      </li>
                    ))}
                  </Disclosure.Panel>
                </Disclosure>
              )}
            </li>
          ) : null
        )}
        {false && (
          <>
            {/** Dashboard */}
            <li className="group flex flex-col">
              <NavLink
                to={``}
                end
                className={({ isActive }) =>
                  `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                  } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
                }
                children={({ isActive }) => (
                  <div className="flex">
                    <HomeIcon
                      className={`${isActive ? "stroke-white" : "stroke-gray-700 group-hover:stroke-white"
                        } w-5 h-5 mr-2 fill-tranparent`}
                      aria-hidden="true"
                    />
                    Dashboard
                  </div>
                )}
              />
            </li>
            {/** Assessment */}
            <li className="group flex flex-col">
              <NavLink
                to={`assessment`}
                className={({ isActive }) =>
                  `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                  } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
                }
                children={({ isActive }) => (
                  <div className="flex">
                    <CheckBadgeIcon
                      className={`${isActive ? "stroke-white" : "stroke-gray-700 group-hover:stroke-white"
                        } w-5 h-5 mr-2 fill-tranparent`}
                      aria-hidden="true"
                    />
                    Assessment
                  </div>
                )}
              />
            </li>
            {/** Company - Admin Only */}
            {user.roles === "1" && (
              <li className="group flex flex-col">
                <NavLink
                  to={`company`}
                  className={({ isActive }) =>
                    `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                    } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
                  }
                  children={({ isActive }) => (
                    <div className="flex">
                      <BuildingOffice2Icon
                        className={`${isActive ? "stroke-white" : "stroke-gray-700 group-hover:stroke-white"
                          } w-5 h-5 mr-2 fill-tranparent`}
                        aria-hidden="true"
                      />
                      Company
                    </div>
                  )}
                />
              </li>
            )}
            {/** Questionary - Admin Only */}
            {user.roles === "1" && (
              <>
                {/** Questionary Sub Menu */}
                <li className="flex flex-col items-center">
                  <div className="flex px-2 pt-2 text-xs font-semibold text-center">
                    <ClipboardDocumentListIcon
                      className={`stroke-gray-700 w-4 mr-2 fill-tranparent`}
                      aria-hidden="true"
                    />
                    Questionary
                  </div>
                </li>
                {/** Intelligence Sub Menu */}
                <li className="flex flex-col">
                  <div className="px-2 text-xs font-normal">Intelligence</div>
                </li>
                {/** Intelligence - CFIT */}
                <li className="group flex flex-col">
                  <NavLink
                    to={`cfit`}
                    className={({ isActive }) =>
                      `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                      } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
                    }
                    children={({ isActive }) => (
                      <div className="flex">
                        CFIT Subtest
                      </div>
                    )}
                  />
                </li>
                {/** Intelligence - IST */}
                <li className="group flex flex-col">
                  <NavLink
                    to={`ist`}
                    className={({ isActive }) =>
                      `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                      } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
                    }
                    children={({ isActive }) => (
                      <div className="flex">
                        IST Subtest
                      </div>
                    )}
                  />
                </li>
                {/** Personality Sub Menu */}
                <li className="flex flex-col">
                  <div className="px-2 text-xs font-normal">Personality</div>
                </li>
                {/** Personality - MBTI */}
                <li className="group flex flex-col">
                  <NavLink
                    to={`mbti_questionary`}
                    className={({ isActive }) =>
                      `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                      } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
                    }
                    children={({ isActive }) => (
                      <div className="flex">
                        MBTI Questionary
                      </div>
                    )}
                  />
                </li>
                {/** Personality - PAPI */}
                <li className="group flex flex-col">
                  <NavLink
                    to={`papi_questionary`}
                    className={({ isActive }) =>
                      `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                      } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
                    }
                    children={({ isActive }) => (
                      <div className="flex">
                        PAPI Questionary
                      </div>
                    )}
                  />
                </li>
                {/** Personality - EPPS */}
                <li className="group flex flex-col">
                  <NavLink
                    to={`epps_questionary`}
                    className={({ isActive }) =>
                      `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                      } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
                    }
                    children={({ isActive }) => (
                      <div className="flex">
                        EPPS Questionary
                      </div>
                    )}
                  />
                </li>
                {/** Personality - SRQ-20 */}
                <li className="group flex flex-col">
                  <NavLink
                    to={`srq20_questionary`}
                    className={({ isActive }) =>
                      `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                      } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
                    }
                    children={({ isActive }) => (
                      <div className="flex">
                        SRQ-20 Questionary
                      </div>
                    )}
                  />
                </li>
                {/** Personality - MMPI */}
                <li className="group flex flex-col">
                  <NavLink
                    to={`mmpi_questionary`}
                    className={({ isActive }) =>
                      `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                      } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
                    }
                    children={({ isActive }) => (
                      <div className="flex">
                        MMPI Questionary
                      </div>
                    )}
                  />
                </li>
                {/** Personality - BRS */}
                <li className="group flex flex-col">
                  <NavLink
                    to={`brs_questionary`}
                    className={({ isActive }) =>
                      `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                      } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
                    }
                    children={({ isActive }) => (
                      <div className="flex">
                        BRS Questionary
                      </div>
                    )}
                  />
                </li>
                {/** Behavioural Sub Menu */}
                <li className="flex flex-col">
                  <div className="px-2 text-xs font-normal">Behavioural</div>
                </li>
                {/** Behavioural - RAC */}
                <li className="group flex flex-col">
                  <NavLink
                    to={`rac_questionary`}
                    className={({ isActive }) =>
                      `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                      } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
                    }
                    children={({ isActive }) => (
                      <div className="flex">
                        RAC Questionary
                      </div>
                    )}
                  />
                </li>
                {/** Behavioural - DISC */}
                <li className="group flex flex-col">
                  <NavLink
                    to={`disc_questionary`}
                    className={({ isActive }) =>
                      `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                      } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
                    }
                    children={({ isActive }) => (
                      <div className="flex">
                        DISC Questionary
                      </div>
                    )}
                  />
                </li>
                {/** Behavioural - RESILIENCE */}
                <li className="group flex flex-col">
                  <NavLink
                    to={`resilience_questionary`}
                    className={({ isActive }) =>
                      `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                      } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
                    }
                    children={({ isActive }) => (
                      <div className="flex">
                        RESILIENCE Questionary
                      </div>
                    )}
                  />
                </li>
              </>
            )}
            {/** Formula - Admin Only */}
            {user.roles === "1" && (
              <>
                {/** Formula Sub Menu */}
                <li className="flex flex-col items-center">
                  <div className="flex px-2 pt-2 text-xs font-semibold text-center">
                    <BeakerIcon
                      className={`stroke-gray-700 w-4 mr-2 fill-tranparent`}
                      aria-hidden="true"
                    />
                    Formula
                  </div>
                </li>
                {/** Intelligence Sub Menu */}
                <li className="flex flex-col">
                  <div className="px-2 text-xs font-normal">Intelligence</div>
                </li>
                {/** Formula - CFIT Formula */}
                <li className="group flex flex-col">
                  <NavLink
                    to={`cfit_formula`}
                    className={({ isActive }) =>
                      `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                      } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
                    }
                    children={({ isActive }) => (
                      <div className="flex">
                        CFIT Formula
                      </div>
                    )}
                  />
                </li>
                {/** Formula - IST Formula */}
                <li className="group flex flex-col">
                  <NavLink
                    to={`ist_formula`}
                    className={({ isActive }) =>
                      `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                      } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
                    }
                    children={({ isActive }) => (
                      <div className="flex">
                        IST Formula
                      </div>
                    )}
                  />
                </li>
                {/** Personality Sub Menu */}
                <li className="flex flex-col">
                  <div className="px-2 text-xs font-normal">Personalty</div>
                </li>
                {/** Formula - EPPS Formula Norma */}
                <li className="group flex flex-col">
                  <NavLink
                    to={`epps_formula_norma`}
                    className={({ isActive }) =>
                      `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                      } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
                    }
                    children={({ isActive }) => (
                      <div className="flex">
                        EPPS Formula Norma
                      </div>
                    )}
                  />
                </li>
                {/** Behavioural Sub Menu */}
                <li className="flex flex-col">
                  <div className="px-2 text-xs font-normal">Behavioural</div>
                </li>
                {/** Formula - RAC Formula Norma */}
                <li className="group flex flex-col">
                  <NavLink
                    to={`rac_formula_norma`}
                    className={({ isActive }) =>
                      `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                      } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
                    }
                    children={({ isActive }) => (
                      <div className="flex">
                        RAC Formula Norma
                      </div>
                    )}
                  />
                </li>
              </>
            )}
          </>
        )}
        {/** Other Links */}
        <li className="flex flex-col">
          <div className="flex px-2 pt-2 text-xs font-semibold text-center uppercase mb-2">
            Other Links
          </div>
        </li>
        <li className="group flex flex-col">
          <NavLink
            to={`/assessment_system`}
            target="_blank"
            className={`text-gray-900 flex items-center font-semibold w-full px-2 py-2 text-sm border rounded bg-red-50 group-hover:bg-red-500 group-hover:text-white`}
            children={({ isActive }) => (
              <div className="flex">
                <ArrowTopRightOnSquareIcon
                  className={`${isActive ? "stroke-white" : "stroke-gray-700 group-hover:stroke-white"
                    } w-5 h-5 mr-2 fill-tranparent`}
                  aria-hidden="true"
                />
                Assessment System
              </div>
            )}
          />
        </li>
      </ul>
    </nav>
  );
}

export default MenuDesktop;
