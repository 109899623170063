import req from "../../../services/psikotest/request";

class AssessmentPersonalitySubtest {
  async create(data: TPSAssessmentPersonalitySubtest) {
    try {
      const res = await req.post("/api/psikotest_system/assessment_personality_subtest/", data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async update(data: TPSAssessmentPersonalitySubtest) {
    try {
      const res = await req.put("/api/psikotest_system/assessment_personality_subtest/" + data.id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async delete(data: TPSAssessmentPersonalitySubtest) {
    try {
      const res = await req.delete("/api/psikotest_system/assessment_personality_subtest/", { data });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async participant_session(data: TPSAssessmentPersonalitySubtest) {
    try {
      const res = await req.get("/api/psikotest_system/assessment_personality_subtest/" + data.id + "/participant_session");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default AssessmentPersonalitySubtest;
